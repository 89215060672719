// @flow

import { useDispatch, useSelector } from 'react-redux';
import type { TDispatch } from '../../store/store';

import React from 'react';
import { setModal } from '../../store/slices/uxSlice';
import HowToPlayModal from './HowToPlayModal';
import AboutModal from './AboutModal';
import StatsModal from './StatsModal';
import LoveModal from './LoveModal';
import InterstitialAdModal from '../InterstitialAdModal';
import type { State } from '../../store/types';

const Modals = (): React$Element<React$FragmentType> => {
    const dispatch: TDispatch = useDispatch();
    const currentModal = useSelector((state: State) => state.ux.modal);

    const closeModal = () => {
        dispatch(setModal('none'));
        if (typeof window.globalThis.interAdNextFunc == 'function') {
            window.globalThis.interAdNextFunc();
            window.globalThis.interAdNextFunc = undefined;
        }
    };

    return (
        <>
            {currentModal !== 'none' && <div className="modal-bg" onClick={closeModal} />}
            {currentModal === 'love' && <LoveModal />}

            {currentModal === 'stats' && <StatsModal />}

            {currentModal === 'about' && <AboutModal />}

            {currentModal === 'howtoplay' && <HowToPlayModal />}

            {currentModal === 'inter-ad' && <InterstitialAdModal />}
        </>
    );
};

export default Modals;
