// @flow
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { TGTGAnswer } from '../puzzles/gtg_puzzles';
import type { AnswerUnion, GameSettings } from './game_settings';

import { GTG_COMMUNITY_SUBMISSION_URL, PUZZLE_TYPES } from '../constants';
import { answers } from '../puzzles/gtgc_puzzles';
import { GTGSettings } from './gtg_settings';

export class GTGCSettings extends GTGSettings {
    title: string = 'Guess The Game Cover';
    puzzle_start_date: Date = new Date('6/8/2024');

    clipboard_tag: string = '#GuessTheCover';

    max_guesses: number = 5;
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTGC;
    root_url: string = '/' + this.puzzle_type;
    storage_keys: { guess: string, game_state: string } = {
        guess: this.puzzle_type + '_guess',
        game_state: this.puzzle_type + '_gamestate',
    };
    answers: AnswerUnion = answers;
    game_url(puzzleId: string): string {
        return `https://${this.website_url}${GTGCSettings.PATHS.guess_the_cover.replace(':puzzleId?', puzzleId)}`;
    }
    num_days_from_start_date(): number {
        const today = new Date();
        // $FlowIgnore
        const diffTime = Math.abs(today - this.puzzle_start_date);
        return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }

    get_hint_text(imageIndex: number, puzzle: TGTGAnswer, settings: GameSettings): string {
        return '';
    }

    progress_ranks: {} = {};

    puzzle_url(): string {
        return GTGCSettings.PATHS.guess_the_cover;
    }

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} cover is selected and your job is to guess what it is.`,
                    'We try to have a selection of different games from different genres, eras, and platforms. We also try to have a mix of games that are well known and games that are more obscure. Generally harder games are on the weekends, and easier games are on weekdays.',
                    {
                        beforeLink: 'Want your favorite game to be featured as a daily puzzle?',
                        url: GTG_COMMUNITY_SUBMISSION_URL,
                        linkText: ' Suggest A Game',
                        afterLink: '',
                    },
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    'You are presented with a cover of a videogame. Try to guess what it is!',
                    'If you get it wrong or click skip, we will reveal an additional snippet of the cover.',
                    `You get ${this.max_guesses} guesses in total.`,
                ],
            },
        },
    };
}
