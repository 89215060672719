// @flow
import React, { useEffect } from 'react';
import { BrowserRouter, Routes } from 'react-router-dom';

import Modals from './components/Modal/Modals';
import HeaderRow from './components/HeaderRow';
import DevTools from './components/DevTools';
import KofiButton from './components/Kofi';

import { DEV_MODE } from './globals';

import './App.css';
import './easterEgg';
import { loadUserThunk } from './store/slices/userSlice';
import { useDispatch } from 'react-redux';
import type { TDispatch } from './store/store';

import { useSettings } from './settings/useSettings';

function App(): React$Element<'div'> {
    const dispatch: TDispatch = useDispatch();
    // create hook for snowflake count

    useEffect(() => {
        dispatch(loadUserThunk(null));
    }, [dispatch]);

    const settings = useSettings();

    return (
        <div className="App">
            <Modals />
            <BrowserRouter>
                <HeaderRow />
                {DEV_MODE && <DevTools />}
                <Routes>{settings.routes()}</Routes>
            </BrowserRouter>

            <KofiButton label="Support" preset="kufi-right" />
        </div>
    );
}

export default App;
