// @flow

import type { GameSettings } from './settings/game_settings';

import { ADMIN_URL } from './constants.js';

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;
declare var __GTM_BUILD__: boolean;

let todaysDate = new Date();

console.log('todays date: ', todaysDate);

export const DEV_MODE: boolean =
    (window.location.href.startsWith('http://localhost:3000') || window.location.href.includes(ADMIN_URL)) &&
    !window.location.href.includes('ci-mode');

export function calculatePuzzleDate(puzzleID: string, settings: GameSettings): string {
    // by adding puzzleID to PUZZLE_START_DATE, we can calculate the date of the puzzle
    const puzzleDate = new Date(settings.puzzle_start_date);
    puzzleDate.setDate(puzzleDate.getDate() + parseInt(puzzleID) - 1);
    return puzzleDate.toDateString();
}

export const isAnswerCorrect = (currentGuess: string, answers_: Array<string>): boolean => {
    if (__GTB_BUILD__ || __GTA_BUILD__) {
        if (currentGuess.includes('-')) {
            currentGuess = currentGuess.split('-')[0].trim();
        }
    }
    // Utility function to normalize strings
    // const normalizeString = (str: string) => {
    //     return str
    //         .normalize('NFD')
    //         .replace(/[\u0300-\u036f]/g, '')
    //         .toLowerCase();
    // };

    function normalizeString(str: string) {
        const accentsMap = {
            Á: 'A',
            Ă: 'A',
            Ắ: 'A',
            Ặ: 'A',
            Ằ: 'A',
            Ẳ: 'A',
            Ẵ: 'A',
            Ǎ: 'A',
            Â: 'A',
            Ấ: 'A',
            Ậ: 'A',
            Ầ: 'A',
            Ẩ: 'A',
            Ẫ: 'A',
            Ä: 'A',
            Ǟ: 'A',
            Ȧ: 'A',
            Ǡ: 'A',
            Ạ: 'A',
            Ȁ: 'A',
            À: 'A',
            Ả: 'A',
            Ȃ: 'A',
            Ā: 'A',
            Ą: 'A',
            Å: 'A',
            Ǻ: 'A',
            Ḁ: 'A',
            Ⱥ: 'A',
            Ã: 'A',
            Ꜳ: 'AA',
            Æ: 'AE',
            Ǽ: 'AE',
            Ǣ: 'AE',
            Ꜵ: 'AO',
            Ꜷ: 'AU',
            Ꜹ: 'AV',
            Ꜻ: 'AV',
            Ꜽ: 'AY',
            Ḃ: 'B',
            Ḅ: 'B',
            Ɓ: 'B',
            Ḇ: 'B',
            Ƀ: 'B',
            Ƃ: 'B',
            Ć: 'C',
            Č: 'C',
            Ç: 'C',
            Ḉ: 'C',
            Ĉ: 'C',
            Ċ: 'C',
            Ƈ: 'C',
            Ȼ: 'C',
            Ď: 'D',
            Ḑ: 'D',
            Ḓ: 'D',
            Ḋ: 'D',
            Ḍ: 'D',
            Ɗ: 'D',
            Ḏ: 'D',
            ǲ: 'D',
            ǅ: 'D',
            Đ: 'D',
            Ƌ: 'D',
            Ǳ: 'DZ',
            Ǆ: 'DZ',
            É: 'E',
            Ĕ: 'E',
            Ě: 'E',
            Ȩ: 'E',
            Ḝ: 'E',
            Ê: 'E',
            Ế: 'E',
            Ệ: 'E',
            Ề: 'E',
            Ể: 'E',
            Ễ: 'E',
            Ḙ: 'E',
            Ë: 'E',
            Ė: 'E',
            Ẹ: 'E',
            Ȅ: 'E',
            È: 'E',
            Ẻ: 'E',
            Ȇ: 'E',
            Ē: 'E',
            Ḗ: 'E',
            Ḕ: 'E',
            Ę: 'E',
            Ɇ: 'E',
            Ẽ: 'E',
            Ḛ: 'E',
            Ꝫ: 'ET',
            Ḟ: 'F',
            Ƒ: 'F',
            Ǵ: 'G',
            Ğ: 'G',
            Ǧ: 'G',
            Ģ: 'G',
            Ĝ: 'G',
            Ġ: 'G',
            Ɠ: 'G',
            Ḡ: 'G',
            Ǥ: 'G',
            Ḫ: 'H',
            Ȟ: 'H',
            Ḩ: 'H',
            Ĥ: 'H',
            Ⱨ: 'H',
            Ḧ: 'H',
            Ḣ: 'H',
            Ḥ: 'H',
            Ħ: 'H',
            Í: 'I',
            Ĭ: 'I',
            Ǐ: 'I',
            Î: 'I',
            Ï: 'I',
            Ḯ: 'I',
            İ: 'I',
            Ị: 'I',
            Ȉ: 'I',
            Ì: 'I',
            Ỉ: 'I',
            Ȋ: 'I',
            Ī: 'I',
            Į: 'I',
            Ɨ: 'I',
            Ĩ: 'I',
            Ḭ: 'I',
            Ꝺ: 'D',
            Ꝼ: 'F',
            Ᵹ: 'G',
            Ꞃ: 'R',
            Ꞅ: 'S',
            Ꞇ: 'T',
            Ꝭ: 'IS',
            Ĵ: 'J',
            Ɉ: 'J',
            Ḱ: 'K',
            Ǩ: 'K',
            Ķ: 'K',
            Ⱪ: 'K',
            Ꝃ: 'K',
            Ḳ: 'K',
            Ƙ: 'K',
            Ḵ: 'K',
            Ꝁ: 'K',
            Ꝅ: 'K',
            Ĺ: 'L',
            Ƚ: 'L',
            Ľ: 'L',
            Ļ: 'L',
            Ḽ: 'L',
            Ḷ: 'L',
            Ḹ: 'L',
            Ⱡ: 'L',
            Ꝉ: 'L',
            Ḻ: 'L',
            Ŀ: 'L',
            Ɫ: 'L',
            ǈ: 'L',
            Ł: 'L',
            Ǉ: 'LJ',
            Ḿ: 'M',
            Ṁ: 'M',
            Ṃ: 'M',
            Ɱ: 'M',
            Ń: 'N',
            Ň: 'N',
            Ņ: 'N',
            Ṋ: 'N',
            Ṅ: 'N',
            Ṇ: 'N',
            Ǹ: 'N',
            Ɲ: 'N',
            Ṉ: 'N',
            Ƞ: 'N',
            ǋ: 'N',
            Ñ: 'N',
            Ǌ: 'NJ',
            Ó: 'O',
            Ŏ: 'O',
            Ǒ: 'O',
            Ô: 'O',
            Ố: 'O',
            Ộ: 'O',
            Ồ: 'O',
            Ổ: 'O',
            Ỗ: 'O',
            Ö: 'O',
            Ȫ: 'O',
            Ȯ: 'O',
            Ȱ: 'O',
            Ọ: 'O',
            Ő: 'O',
            Ȍ: 'O',
            Ò: 'O',
            Ỏ: 'O',
            Ơ: 'O',
            Ớ: 'O',
            Ợ: 'O',
            Ờ: 'O',
            Ở: 'O',
            Ỡ: 'O',
            Ȏ: 'O',
            Ꝋ: 'O',
            Ꝍ: 'O',
            Ō: 'O',
            Ṓ: 'O',
            Ṑ: 'O',
            Ɵ: 'O',
            Ǫ: 'O',
            Ǭ: 'O',
            Ø: 'O',
            Ǿ: 'O',
            Õ: 'O',
            Ṍ: 'O',
            Ṏ: 'O',
            Ȭ: 'O',
            Ƣ: 'OI',
            Ꝏ: 'OO',
            Ɛ: 'E',
            Ɔ: 'O',
            Ȣ: 'OU',
            Ṕ: 'P',
            Ṗ: 'P',
            Ꝓ: 'P',
            Ƥ: 'P',
            Ꝕ: 'P',
            Ᵽ: 'P',
            Ꝑ: 'P',
            Ꝙ: 'Q',
            Ꝗ: 'Q',
            Ŕ: 'R',
            Ř: 'R',
            Ŗ: 'R',
            Ṙ: 'R',
            Ṛ: 'R',
            Ṝ: 'R',
            Ȑ: 'R',
            Ȓ: 'R',
            Ṟ: 'R',
            Ɍ: 'R',
            Ɽ: 'R',
            Ꜿ: 'C',
            Ǝ: 'E',
            Ś: 'S',
            Ṥ: 'S',
            Š: 'S',
            Ṧ: 'S',
            Ş: 'S',
            Ŝ: 'S',
            Ș: 'S',
            Ṡ: 'S',
            Ṣ: 'S',
            Ṩ: 'S',
            ẞ: 'SS',
            Ť: 'T',
            Ţ: 'T',
            Ṱ: 'T',
            Ț: 'T',
            Ⱦ: 'T',
            Ṫ: 'T',
            Ṭ: 'T',
            Ƭ: 'T',
            Ṯ: 'T',
            Ʈ: 'T',
            Ŧ: 'T',
            Ɐ: 'A',
            Ꞁ: 'L',
            Ɯ: 'M',
            Ʌ: 'V',
            Ú: 'U',
            Ŭ: 'U',
            Ǔ: 'U',
            Û: 'U',
            Ṷ: 'U',
            Ü: 'U',
            Ǘ: 'U',
            Ǚ: 'U',
            Ǜ: 'U',
            Ǖ: 'U',
            Ṳ: 'U',
            Ụ: 'U',
            Ű: 'U',
            Ȕ: 'U',
            Ù: 'U',
            Ủ: 'U',
            Ư: 'U',
            Ứ: 'U',
            Ự: 'U',
            Ừ: 'U',
            Ử: 'U',
            Ữ: 'U',
            Ȗ: 'U',
            Ū: 'U',
            Ṻ: 'U',
            Ų: 'U',
            Ů: 'U',
            Ũ: 'U',
            Ṹ: 'U',
            Ṵ: 'U',
            Ꝟ: 'V',
            Ṿ: 'V',
            Ʋ: 'V',
            Ṽ: 'V',
            Ẃ: 'W',
            Ŵ: 'W',
            Ẅ: 'W',
            Ẇ: 'W',
            Ẉ: 'W',
            Ẁ: 'W',
            Ⱳ: 'W',
            Ẍ: 'X',
            Ẋ: 'X',
            Ý: 'Y',
            Ŷ: 'Y',
            Ÿ: 'Y',
            Ẏ: 'Y',
            Ỵ: 'Y',
            Ỳ: 'Y',
            Ƴ: 'Y',
            Ỷ: 'Y',
            Ỿ: 'Y',
            Ȳ: 'Y',
            Ɏ: 'Y',
            Ỹ: 'Y',
            Ź: 'Z',
            Ž: 'Z',
            Ẑ: 'Z',
            á: 'a',
            ä: 'a',
            â: 'a',
            à: 'a',
            å: 'a',
            ã: 'a',
            é: 'e',
            ë: 'e',
            ê: 'e',
            è: 'e',
            í: 'i',
            ï: 'i',
            î: 'i',
            ì: 'i',
            ó: 'o',
            ö: 'o',
            ô: 'o',
            ò: 'o',
            õ: 'o',
            ø: 'o',
            ú: 'u',
            ü: 'u',
            û: 'u',
            ù: 'u',
            ñ: 'n',
            ç: 'c',
            ÿ: 'y',
        };
        // console.log(str);
        return str
            .split('')
            .map((char) => accentsMap[char] || char)
            .join('')
            .toLowerCase();
    }

    // Process the current guess
    // console.log('Guess: ', currentGuess);
    const processedGuess = normalizeString(
        // Remove punctuation, white spaces, and the word 'the' from the guess
        currentGuess
            // eslint-disable-next-line
            .replace(/[\!\(\)\[\]\{\}\-\\\.\/\?\$\^\*;:'"’,<>@#%&_~]/g, '')
            .replace(/\s+/g, ' ')
            .replace(/\bthe\b/gi, '')
            .replace('&', 'and')
            .trim(),
    );
    // console.log('processedGuess', processedGuess);

    for (let i = 0; i < answers_.length; i++) {
        // Process each answer
        const processedAnswer = normalizeString(
            // Remove punctuation, white spaces, and the word 'the' from the guess
            answers_[i]
                // eslint-disable-next-line
                .replace(/[\!\(\)\[\]\{\}\-\\\.\/\?\$\^\*;:'"’,<>@#%&_~]/g, '')
                .replace(/\s+/g, ' ')
                .replace(/\bthe\b/gi, '')
                .replace('&', 'and')
                .trim(),
        );
        // console.log('processedAnswer', processedAnswer);

        if (processedGuess === processedAnswer) {
            return true;
        }
    }

    return false;
};

export const API_BASE: string = window.location.href.startsWith('http://localhost:3000')
    ? 'http://localhost:8000'
    : 'https://api.guessthe.game';

export const $NotNull = <T>(val: T): $NonMaybeType<T> => val;
