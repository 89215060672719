// @flow
import { setModal } from '../../store/slices/uxSlice';

import { useDispatch, useSelector } from 'react-redux';

import React, { useState } from 'react';
import type { TDispatch } from '../../store/store';
import type { State } from '../../store/types';
import { AnimatePresence, motion } from 'framer-motion';
import { $NotNull } from '../../globals';

import { useTranslation } from 'react-i18next';
import KofiButton from './../Kofi';
import { useSettings } from '../../settings/useSettings';

const getStatsString = (
    translator,
    title,
    played,
    won,
    winPercentage,
    currentStreak,
    maxStreak,
    maxGuesses,
    numWinsAtGuess,
    currentRank,
) => {
    const t = translator;
    let string = `${t('Stats')} - ${title} \n`;
    string += `${t('Played')}: ${played}\n`;
    string += `${t('Won')}: ${won}\n`;
    string += `${t('Win')}: ${isNaN(winPercentage) ? 0 : winPercentage} %\n`;
    string += `${t('Current Streak')}: ${currentStreak}\n`;
    string += `${t('Max Streak')}: ${maxStreak}\n\n`;
    string += `${t('Win Distribution')}\n`;

    const MAX_DISTRIBUTION_LENGTH = 6;
    let largestValue = Math.max(...Object.values(numWinsAtGuess));
    largestValue = largestValue <= 0 ? 1 : largestValue;
    Array.from(Array(maxGuesses)).forEach((_, index) => {
        const numOfWins = numWinsAtGuess[(index + 1).toString()];
        const displayLength = Math.ceil((numOfWins / largestValue) * (MAX_DISTRIBUTION_LENGTH - 1));
        string += '🟩'.repeat(1 + displayLength) + ` ${numOfWins}\n`;
    });
    string += `\n\nRank: ${currentRank} 🏆`;
    return string;
};

const StatsModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();

    const settings = useSettings();
    const { t } = useTranslation();

    const [showRankToggle, toggleShowRankToggle] = useState(false);

    const { puzzleType } = useSelector((state: State) => state.ux);
    console.log('stats puzzletype: ', puzzleType);

    const { played, won, currentStreak, maxStreak, winPercentage, currentRank, numWinsAtGuess } = useSelector(
        (state: State) => state.stats[puzzleType],
    );

    const maxGuesses = settings.max_guesses;
    console.log('max guesses', maxGuesses);

    const percentageDenominator = Math.max(...Object.values(numWinsAtGuess));
    console.log('numwins at guess: ', numWinsAtGuess);
    console.log('precentage demno: ', percentageDenominator);

    let winsToNextRank: number | string = 0;

    for (let key of Object.keys(settings.progress_ranks)) {
        // console.log('key', key, 'won', won);
        if (parseInt(key) > parseInt(won)) {
            winsToNextRank = parseInt(key) - parseInt(won);
            console.log('winsToNextRank', winsToNextRank);
            break; // exit the loop as soon as we find a match
        }
        winsToNextRank = 'You have reached the highest rank!';
    }

    const [copiedClipboardStatus, setCopiedClipboardStatus] = useState(false);

    const copyStatsToClipboard = async () => {
        let status;
        try {
            const text = getStatsString(
                t,
                settings.title,
                played,
                won,
                winPercentage,
                currentStreak,
                maxStreak,
                maxGuesses,
                numWinsAtGuess,
                currentRank,
            );
            await navigator.clipboard.writeText(text);
            status = true;
        } catch (err) {
            console.error('Failed to copy to clipboard: ', err);
            status = 'failed';
        } finally {
            setCopiedClipboardStatus(status);
            setTimeout(() => {
                setCopiedClipboardStatus(false);
            }, 1500);
        }
    };

    return (
        <div className="modal stats-modal">
            <div className="stats-modal-inner">
                <button
                    type="button"
                    className={'close-modal'}
                    onClick={() => {
                        dispatch(setModal('none'));
                    }}
                >
                    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
                            fill="currentColor"
                            fillRule="evenodd"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </button>

                <h3>
                    {settings.title} {t('Stats')}
                </h3>

                <div className="stats-section">
                    <div className="stat">
                        <div className="stat-value total-games-played">{played}</div>
                        <div className="stat-label">{t('Played')}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value total-games-won">{won}</div>
                        <div className="stat-label">{t('Won')}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value">{isNaN(winPercentage) ? 0 : winPercentage}</div>
                        <div className="stat-label">{t('Win')} %</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value current-streak">{currentStreak}</div>
                        <div className="stat-label">{t('Current Streak')}</div>
                    </div>

                    <div className="stat">
                        <div className="stat-value max-streak">{maxStreak}</div>
                        <div className="stat-label">{t('Max Streak')}</div>
                    </div>
                </div>

                <h3 style={{ textAlign: 'center', marginTop: '10px' }}>{t('Win Distribution')}</h3>

                <div className="guess-distribution">
                    {Array.from({ length: maxGuesses }, (_, i) => i).map((idx) => (
                        <div key={idx + 1} className="guess-distribution-row">
                            <p>{idx + 1}</p>
                            <div
                                className="guess-row-bar"
                                style={{
                                    width: `${(numWinsAtGuess[(idx + 1).toString()] / percentageDenominator) * 100}%`,
                                }}
                            >
                                {numWinsAtGuess[(idx + 1).toString()]}
                            </div>
                        </div>
                    ))}
                </div>

                <div className={'Rank'}>
                    <h3 style={{ textAlign: 'center', marginTop: '10px' }}>
                        Rank
                        <>
                            <svg
                                className={`dropdown-icon ${showRankToggle ? 'rotated' : ''}`}
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                    toggleShowRankToggle(!showRankToggle);
                                }}
                                style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                            >
                                <path
                                    d="M7.41 7.84L12 12.42L16.59 7.84L18 9.25L12 15.25L6 9.25L7.41 7.84Z"
                                    fill="currentColor"
                                />
                            </svg>

                            <AnimatePresence>
                                {showRankToggle && (
                                    <motion.div
                                        initial={{ opacity: 0, height: 0 }}
                                        animate={{ opacity: 1, height: 'auto' }}
                                        exit={{ opacity: 0, height: 0 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <span
                                            style={{
                                                fontSize: '12px',
                                                fontWeight: '400',
                                                alignItems: 'center',
                                            }}
                                        >
                                            {t('modals.statsModal.paragraph 1')}
                                            <br />
                                            <div style={{ display: 'block' }}>
                                                {/* iterate through progress_ranksand display the rank name and the number of wins required to reach that rank but dont show any past your current rank */}
                                                {Object.keys(settings.progress_ranks).map((numWins, idx) => {
                                                    // $FlowIgnore keys are ints
                                                    if (numWins > won) {
                                                        return null;
                                                    }
                                                    return (
                                                        <div
                                                            key={idx}
                                                            style={{
                                                                margin: '0 10px',
                                                            }}
                                                        >
                                                            <div
                                                                style={{
                                                                    fontSize: '12px',
                                                                    fontWeight: '400',
                                                                }}
                                                            >
                                                                {settings.progress_ranks[numWins]}: {numWins}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </span>
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </>
                    </h3>

                    <div>
                        <p className={'rank'} style={{ textAlign: 'center' }}>
                            🏆 You are{' '}
                            {['a', 'e', 'i', 'o', 'u'].includes($NotNull(currentRank)[0].toLowerCase()) ? 'an' : 'a'}{' '}
                            {currentRank}! 🏆
                            <br></br>
                            <span style={{ fontSize: '13px', fontStyle: 'italic' }}>
                                ({winsToNextRank} more wins to rank up)
                            </span>
                        </p>
                    </div>
                </div>
                <div className="buttons-group">
                    <button type="button" className="mainButton clipboardButton" onClick={copyStatsToClipboard}>
                        {copiedClipboardStatus === true && '✅ Copied!'}
                        {copiedClipboardStatus === 'failed' && '❌ Failed to copy!'}
                        {copiedClipboardStatus === false && '📋 Copy to clipboard'}
                    </button>
                    <div style={{ textAlign: 'center' }}>
                        <KofiButton label="Don't lose your progress!" preset="kufi-center" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StatsModal;
