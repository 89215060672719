// @flow
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { motion } from 'framer-motion';
import { Blurhash } from 'react-blurhash';
import { useNavigate, useParams } from 'react-router-dom';

import type { TDispatch } from '../../store/store';

import { answers } from '../../puzzles/gtg_puzzles';
import {
    IMAGE_EXTENSION,
    PUZZLE_TYPES,
    lastPlayedKey,
    lastShownInterAdKey,
    timeToInvalidateGamesPlayed,
    numGamesBeforeInterAd,
    minimumTimeBetweenInterAd,
} from '../../constants';
import { DEV_MODE, isAnswerCorrect } from '../../globals';
import { setPreviousPuzzles, setStats } from '../../store/slices/statSlice';
import AutoComplete from '../AutoComplete';
import blurhashStrings from '../../blurred_hashes.json';
import { autocompleteSearchThunk, submitGuessThunk } from '../../store/slices/puzzleSlice';
import ImageSelectorButtons from '../ImageSelectorButtons';
import ShareResult from '../ShareResult';
import ShowGuesses from '../ShowGuesses';
import { useHotkeys } from 'react-hotkeys-hook';

import {
    calcStats,
    loadLocalStorageItem,
    saveLocalStorageItem,
    loadDateFromLocalStorage,
    saveDateToLocalStorage,
    loadGamesPlayedCount,
    saveGamesPlayedCount,
} from '../../localStateUtils';
import { calcPreviousPuzzleResults } from '../../prevPuzzleUtils';
import { populateGameList } from '../../puzzleUtils';
import AffiliateLink from '../AffiliateLink';
import { setPuzzleType, setShowInterAd } from '../../store/slices/uxSlice';
import { debounce } from 'lodash';
import KofiButton from '../Kofi';
import { useTranslation } from 'react-i18next';
import type { State } from '../../store/types';
import { handleCustomPuzzleLogic } from '../../easterEgg';
import NavigateButton from '../NavigateButton';
import Footer from '../Footer';
import { Helmet } from 'react-helmet';
import ImageMagnifier from '../ImageMagnifier';
import { goToDay } from '../../clientUtils';
import { useSettings } from '../../settings/useSettings';
import Hint from '../Hint';
import ChatGPT from '../ChatGPT';
import { GTGCSettings } from '../../settings/gtgc_settings';
import {
    getInitialGameState,
    getInitialGuesses,
    getInitialGuessList,
    idxToLocalstateNameMapping,
    shakeInput,
} from './PuzzleUtils';
import FinalAnswer from '../FinalAnswer';
import FinalMessage from '../FinalMessage';

const getInitialFranchiseMatchList = (originalSaveState: ?string, puzzleId: string) => {
    return originalSaveState
        ? [1, 2, 3, 4, 5, 6].map((val) => loadLocalStorageItem(puzzleId, `guess${val}_f`) === 'true')
        : Array(6).fill(false);
};

const GTGPuzzle = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();
    const settings = useSettings();
    const puzzleType = PUZZLE_TYPES.GTG;

    const navigate = useNavigate();

    let { puzzleId } = useParams();
    puzzleId = puzzleId ?? settings.num_days_from_start_date().toString();
    console.log('Num of days from puzzle start date: ', settings.num_days_from_start_date().toString());
    console.log('puzzleId', puzzleId);

    useHotkeys('ArrowRight', () => goToDay(Number(puzzleId) + 1, 'puzzle', settings), [puzzleId]);
    useHotkeys('ArrowLeft', () => goToDay(Number(puzzleId) - 1, 'puzzle', settings), [puzzleId]);

    const { t } = useTranslation();

    const savedState = loadLocalStorageItem(puzzleId, settings.storage_keys.game_state);
    if (savedState == null) {
        // Populate new state
        saveLocalStorageItem(puzzleId, settings.storage_keys.game_state, 'playing');
    }

    const [gameState, setGameState] = useState(() => getInitialGameState(savedState));
    const [guessNum, setGuessNum] = useState(() => getInitialGuesses(savedState, puzzleId, settings));
    const [guessList, setGuessList] = useState(() => getInitialGuessList(savedState, puzzleId, settings));
    const [franchiseMatch, setFranchiseMatch] = useState(() => getInitialFranchiseMatchList(savedState, puzzleId));

    const [selectedImage, selectImage] = useState(1);
    const [guessInputValue, setGuessInputValue] = useState('');
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [filteredSuggestionsMetadata, setFilteredSuggestionsMetadata] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [previousGuesses, setPreviousGuesses] = useState(Object.create(null));
    const [ACloading, setACloading] = useState(false);

    const [imageLoading, setLoading] = useState(true);

    const showChatGPT = true;

    const userState = useSelector((state: State) => state.user);

    const stateGuesses = useSelector((state: State) => state.stats[puzzleType].guesses);
    const numWinsAtGuess = useSelector((state: State) => state.stats[puzzleType].numWinsAtGuess);

    useEffect(() => handleCustomPuzzleLogic(gameState, puzzleId));
    useEffect(() => {
        dispatch(setPuzzleType(puzzleType));
    });

    useEffect(() => {
        // if (userState.user && puzzleId === '688' && showChatGPT) {
        if (puzzleId === '688' && showChatGPT) {
            const imageSelector = document.querySelector('.image-selector');
            // hide all the imageSelectors
            if (imageSelector) {
                imageSelector.style.display = 'none';
            }
            // delete all the divs with class='game-image
            const gameImages = document.querySelectorAll('.game-image');
            if (gameImages) {
                gameImages.forEach((gameImage) => {
                    gameImage.style.display = 'none';
                });
            }
            // } else if (userState.user && puzzleId === '688' && !showChatGPT) {
        } else if (puzzleId === '688' && !showChatGPT) {
            const imageSelector = document.querySelector('.image-selector');
            // hide all the imageSelectors
            if (imageSelector) {
                imageSelector.style.display = 'flex';
            }

            const gameImages = document.querySelectorAll('.game-image');
            if (gameImages) {
                gameImages.forEach((gameImage) => {
                    gameImage.style.display = 'block';
                });
            }
        }
        // }, [userState.user, puzzleId, showChatGPT]);
    }, [puzzleId, showChatGPT]);

    useEffect(() => {
        const savedState = loadLocalStorageItem(puzzleId, 'gamestate');
        setGameState(getInitialGameState(savedState));
        setGuessNum(getInitialGuesses(savedState, puzzleId, settings));
        setGuessList(getInitialGuessList(savedState, puzzleId, settings));
        setFranchiseMatch(getInitialFranchiseMatchList(savedState, puzzleId));
    }, [stateGuesses, puzzleId, settings]);

    const answer = answers[puzzleId].answers;
    const game_content = answers[puzzleId].content;
    const console_platform = answers[puzzleId].console_platform;
    const affiliate_links = answers[puzzleId].affiliate_links || [];
    const submitted_by = answers[puzzleId].submitted_by;
    const franchise = answers[puzzleId].franchise;

    const debouncedSave = useMemo(
        (userInput, previousGuesses) =>
            debounce((userInput, previousGuesses) => {
                const delayTimer = setTimeout(() => {
                    const unLinked = populateGameList(answers).filter(
                        (suggestion) => suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1,
                    );
                    setFilteredSuggestions(unLinked);
                    setActiveSuggestionIndex(0);
                    setShowSuggestions(true);
                    setACloading(false);
                }, 3000);

                dispatch(
                    autocompleteSearchThunk({
                        userInput: userInput,
                        puzzleId: puzzleId,
                        itemType: settings.guess_item.toLowerCase(),
                        puzzleType: puzzleType,
                    }),
                )
                    .unwrap()
                    .then((response) => {
                        clearTimeout(delayTimer); // Cancel the timeout
                        const unLinked = response.map((result) => result.title);
                        setFilteredSuggestions(unLinked);
                        setFilteredSuggestionsMetadata(response);
                        setActiveSuggestionIndex(0);
                        setShowSuggestions(true);

                        // store unlinked in previous guesses
                        setPreviousGuesses({
                            ...previousGuesses,
                            [userInput]: { suggestions: unLinked, metadata: response },
                        });
                        setACloading(false);
                    })
                    .catch((error) => {
                        console.error('Error fetching autocomplete results:', error);
                        setACloading(false);
                    });
            }, 290),
        [dispatch, puzzleId, settings, puzzleType],
    );

    const onLoaded = () => {
        setLoading(false);
    };

    const changeImage = (newImageNum: number) => {
        if (guessNum < newImageNum - 1 && gameState === 'playing') return;
        selectImage(newImageNum);
    };
    useHotkeys('1', () => changeImage(1), [guessNum]);
    useHotkeys('2', () => changeImage(2), [guessNum]);
    useHotkeys('3', () => changeImage(3), [guessNum]);
    useHotkeys('4', () => changeImage(4), [guessNum]);
    useHotkeys('5', () => changeImage(5), [guessNum]);
    useHotkeys('6', () => changeImage(6), [guessNum]);

    const skipGuess = () => {
        submitGuess(null, true);
    };

    const submitGuess = (e: Event | null, isSkipGuess: boolean) => {
        let currentInputValue;

        // console.log("is it a skip guess?", isSkipGuess);
        // console.log("Current input value:", currentInputValue)
        // console.log("Guess input value:", guessInputValue);

        if (isSkipGuess !== undefined) {
            currentInputValue = 'Skipped!';
        } else {
            currentInputValue = guessInputValue;
        }

        if (guessInputValue === '' && isSkipGuess !== true) return;

        const guessFranchise = filteredSuggestionsMetadata.filter(
            (suggestion) => suggestion.title.toLowerCase() === currentInputValue.toLowerCase(),
        )[0]?.franchise;
        const guessFranchiseMatch = guessFranchise === franchise;

        let guessNum = 0;
        const numWinsAtGuessArr: Array<number> = [
            numWinsAtGuess['1'],
            numWinsAtGuess['2'],
            numWinsAtGuess['3'],
            numWinsAtGuess['4'],
            numWinsAtGuess['5'],
            numWinsAtGuess['6'],
        ];
        let newNumWinsAtGuess = [...numWinsAtGuessArr];
        for (let i = 0; i < guessList.length; i++) {
            if (guessList[i] === '') {
                let newArr = [...guessList];
                newArr[i] = currentInputValue;
                setGuessList(newArr);

                // franchise
                let newFranchiseMatch = [...franchiseMatch];
                newFranchiseMatch[i] = guessFranchiseMatch;
                setFranchiseMatch(newFranchiseMatch);
                saveLocalStorageItem(puzzleId, `guess${i + 1}_f`, guessFranchiseMatch.toString());
                // end franchise

                saveLocalStorageItem(puzzleId, `guess${i + 1}`, currentInputValue);
                guessNum = i + 1;
                setGuessNum(i + 1);
                if (isAnswerCorrect(currentInputValue, answer)) {
                    setGameState('win');
                    saveLocalStorageItem(puzzleId, 'gamestate', 'win');

                    newNumWinsAtGuess[i] += 1;
                    // $FlowIgnore storing num in state
                    localStorage.setItem(idxToLocalstateNameMapping[i], newNumWinsAtGuess[i]);

                    // Interstitial ad - win-scenario
                    if (!userState.user) {
                        const now = new Date();
                        const lastShownInterAd = loadDateFromLocalStorage(lastShownInterAdKey);
                        // $FlowIgnore
                        if (lastShownInterAd && now - lastShownInterAd <= minimumTimeBetweenInterAd) {
                            console.log('Inter-ad: Already shown ad. Wait for the next ad window');
                        } else {
                            const lastPlayed = loadDateFromLocalStorage(lastPlayedKey);
                            // $FlowIgnore
                            if (lastPlayed == null || now - lastPlayed > timeToInvalidateGamesPlayed) {
                                saveGamesPlayedCount(1);
                                console.log(
                                    'Inter-ad: Reset games played, but start at 1 to account for current finished game',
                                );
                            } else {
                                const gamesPlayed = loadGamesPlayedCount();
                                if (gamesPlayed >= numGamesBeforeInterAd - 1) {
                                    // We're in the last game before showing interstitial ad
                                    console.log('Inter-ad: showInterstitialAd and reset games played to 0');
                                    dispatch(setShowInterAd(true));
                                    saveGamesPlayedCount(0); // reset games played
                                    saveDateToLocalStorage(lastShownInterAdKey); // Record the time the inter-ad is shown
                                } else {
                                    console.log('Inter-ad: increment games played');
                                    saveGamesPlayedCount(gamesPlayed + 1);
                                }
                            }
                        }
                        saveDateToLocalStorage(lastPlayedKey);
                    }
                } else {
                    // If we're on guess 1-5, then shake and go to the next image
                    if (i < guessList.length - 1) {
                        shakeInput();
                        selectImage(i + 2);
                    } else {
                        // If we're on guess 6, then they lost
                        setGameState('lose');
                        saveLocalStorageItem(puzzleId, 'gamestate', 'lose');

                        // Interstitial ad - lost-scenario
                        if (!userState.user) {
                            const now = new Date();
                            const lastPlayed = loadDateFromLocalStorage(lastPlayedKey);
                            // $FlowIgnore Dates can be subtracted
                            if (lastPlayed == null || now - lastPlayed > timeToInvalidateGamesPlayed) {
                                saveGamesPlayedCount(1);
                                console.log(
                                    'Inter-ad: Reset games played, but start at 1 to account for current finished game',
                                );
                            } else {
                                const gamesPlayed = loadGamesPlayedCount();
                                console.log('Inter-ad: increment games played');
                                saveGamesPlayedCount(gamesPlayed + 1);
                            }
                            saveDateToLocalStorage(lastPlayedKey);
                        }
                    }
                }
                break;
            }
        }
        const initialStats = calcStats(settings);
        dispatch(
            setStats({
                won: initialStats.totalWon,
                played: initialStats.totalPlayed,
                currentStreak: initialStats.currentStreak,
                maxStreak: initialStats.maxStreak,
                puzzleType: puzzleType,
            }),
        );
        dispatch(
            setPreviousPuzzles({
                previousPuzzles: calcPreviousPuzzleResults(settings).previousPuzzles,
                puzzleType: puzzleType,
            }),
        );

        setGuessInputValue('');

        dispatch(
            submitGuessThunk({
                puzzleId: parseInt(puzzleId),
                guessNum: guessNum,
                guess: currentInputValue,
                puzzleType: puzzleType,
            }),
        );
    };

    const onChange = (e: Event) => {
        // $FlowIgnore target has value
        const userInput = e.target.value;
        setGuessInputValue(userInput);
        if (userInput.length <= 2) {
            setFilteredSuggestions([]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
            return;
        }
        // check if userInput is in previous guesses
        if (previousGuesses[userInput]) {
            debouncedSave.cancel();
            console.log('already searched this');
            setFilteredSuggestions(previousGuesses[userInput].suggestions);
            setFilteredSuggestionsMetadata(previousGuesses[userInput].metadata);
            setActiveSuggestionIndex(0);
            setShowSuggestions(true);
            setACloading(false);
            return;
        }
        setACloading(true);
        debouncedSave(userInput, previousGuesses);
    };

    const onKeyDown = (key: KeyboardEvent) => {
        if (key.keyCode === 13 || key.keyCode === 9) {
            if (filteredSuggestions.length > 0) {
                setGuessInputValue(filteredSuggestions[activeSuggestionIndex]);
            }
            setShowSuggestions(false);
        }
    };

    const onClick = (e: Event) => {
        setFilteredSuggestions([]);
        // $FlowIgnore target has innertext
        setGuessInputValue(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    const viewSourceImage = () => {
        navigate(settings.paths.original_images.replace(':puzzleId', puzzleId), {
            state: {
                selectedImage: selectedImage,
            },
        });
    };

    const currentPuzzleUrl = settings.game_url(puzzleId);
    const description = 'Try to ' + settings.website_title + '? Daily Puzzle #' + puzzleId;
    const puzzleTitle = settings.website_title + ' Daily Puzzle #' + puzzleId;

    return (
        <div className="current-game">
            <Helmet>
                <title>
                    {settings.website_title} #{puzzleId}
                </title>

                <link rel="canonical" href={currentPuzzleUrl} />
                <meta property="og:url" content={currentPuzzleUrl} />
                <meta property="twitter:url" content={currentPuzzleUrl} />

                <meta name="description" content={description} />
                <meta property="og:description" content={description} />
                <meta property="twitter:description" content={description} />

                <meta property="og:title" content={puzzleTitle} />
                <meta property="twitter:title" content={puzzleTitle} />

                <meta property="og:type" content="website" />

                <meta property="og:site_name" content={settings.website_url} />

                <meta property="twitter:image" content={`/games/${puzzleId}/1.${IMAGE_EXTENSION}`} />
                <meta property="og:image" content={`/games/${puzzleId}/1.${IMAGE_EXTENSION}`} />

                <meta property="twitter:card" content="summary_large_image" />
            </Helmet>
            <div
                className={'current-game-number'}
                style={{
                    textAlign: 'center',
                    color: '#eee',
                    opacity: '50%',
                    paddingBottom: '10px',
                    fontStyle: 'oblique',
                }}
            >
                {settings.guess_item} #{puzzleId}
            </div>
            <div className="Screenshots">
                <div className="image-area">
                    {/*{showChatGPT && userState.user ? <ChatGPT /> : null}*/}
                    {showChatGPT && puzzleId === '688' ? <ChatGPT /> : null}

                    <div
                        className="game-image game-image-1"
                        style={{
                            visibility: selectedImage === 1 ? 'visible' : 'hidden',
                            position: 'relative',
                            overflow: 'hidden',
                        }}
                    >
                        {imageLoading && blurhashStrings[puzzleId] ? (
                            <Blurhash
                                hash={blurhashStrings[puzzleId]}
                                width="100%"
                                height="100%"
                                resolutionX={32}
                                resolutionY={32}
                                punch={1}
                            />
                        ) : null}

                        <ImageMagnifier
                            src={`/games/${puzzleId}/1.${IMAGE_EXTENSION}`}
                            component={
                                <motion.img
                                    initial={{ opacity: 0 }}
                                    animate={{
                                        opacity: imageLoading ? 0 : 1,
                                    }}
                                    transition={{ opacity: { delay: 0.0, duration: 0.1 } }}
                                    onLoad={onLoaded}
                                    src={`/games/${puzzleId}/1.${IMAGE_EXTENSION}`}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    width="100%"
                                    height="100%"
                                />
                            }
                            width="100%"
                            height="100%"
                            magnifierHeight={100}
                            magnifierWidth={100}
                            zoomLevel={1.8}
                            alt={`Screenshot 1`}
                        />

                        {gameState !== 'playing' && (
                            <span className={'hint-source-link'} onClick={viewSourceImage}>
                                View Source Image
                            </span>
                        )}
                    </div>
                    {[2, 3, 4, 5].map((imageIndex) => (
                        <div
                            key={imageIndex}
                            className="game-image"
                            style={{
                                visibility: selectedImage === imageIndex ? 'visible' : 'hidden',
                            }}
                        >
                            <ImageMagnifier
                                src={`/games/${puzzleId}/${imageIndex}.${IMAGE_EXTENSION}`}
                                width="100%"
                                height="100%"
                                magnifierHeight={100}
                                magnifierWidth={100}
                                zoomLevel={1.8}
                                alt={`Screenshot ${imageIndex}`}
                            />
                            {selectedImage === imageIndex && (
                                <Hint hintText={settings.get_hint_text(imageIndex, answers[puzzleId], settings)} />
                            )}
                            {gameState !== 'playing' && (
                                <span className={'hint-source-link'} onClick={viewSourceImage}>
                                    View Source Image
                                </span>
                            )}
                        </div>
                    ))}
                    <div
                        className="game-image game-image-6"
                        style={{
                            visibility: selectedImage === 6 ? 'visible' : 'hidden',
                            overflow: 'hidden',
                        }}
                    >
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: '1',
                            }}
                            poster={`/games/${puzzleId}/6.${IMAGE_EXTENSION}`}
                            key={`video-${puzzleId}`}
                        >
                            <source src={`/games/${puzzleId}/video/6.webm`} type="video/webm" />
                            <source src={`/games/${puzzleId}/video/6.mp4`} type="video/mp4" />
                        </video>
                        {selectedImage === 6 && (
                            <Hint hintText={settings.get_hint_text(6, answers[puzzleId], settings)} />
                            // <div className={`guess-hint`}>{getHintText(imageIndex, answers[puzzleId])}</div>
                        )}
                        {gameState !== 'playing' && (
                            <span className={'hint-source-link'} onClick={viewSourceImage}>
                                View Source Image
                            </span>
                        )}
                    </div>
                </div>
                <div className="image-selector">
                    <ImageSelectorButtons
                        selectedImage={selectedImage}
                        changeImage={changeImage}
                        gameState={gameState}
                        guesses={guessNum}
                        franchiseMatch={franchiseMatch}
                    />
                    {gameState === 'playing' && (
                        <button type="button" className="mainButton iconButton skipButton" onClick={skipGuess}>
                            Skip <span aria-hidden="true" className="icon-forward3"></span>
                        </button>
                    )}
                </div>
            </div>
            {gameState !== 'playing' && (
                <div className="result">
                    <FinalMessage gameState={gameState} num_guesses={guessNum} />
                    <FinalAnswer answer={answer} />

                    <ShareResult
                        guesses={guessNum}
                        gameState={gameState}
                        puzzleId={puzzleId}
                        franchiseMatch={franchiseMatch}
                        maxGuesses={settings.max_guesses}
                    />
                    <ShowGuesses guessList={guessList} answer={answer} puzzleId={puzzleId} />
                    <KofiButton preset="kufi-center" />

                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            justifyContent: 'center',
                            marginBottom: '10px',
                        }}
                    >
                        <NavigateButton
                            text={t('Play Previous Days')}
                            path={settings.paths.previous_games}
                            hash={puzzleId}
                        />
                        <NavigateButton
                            text={t('Play Guess The Cover')}
                            path={settings.paths.guess_the_cover.replace(
                                ':puzzleId?',
                                new GTGCSettings().num_days_from_start_date().toString(),
                            )}
                        />
                        <NavigateButton
                            text={t('Screenshot Sources')}
                            path={settings.paths.original_images.replace(':puzzleId', puzzleId)}
                        />
                        <NavigateButton
                            text={t('See Game Stats')}
                            path={settings.paths.stats.replace(':puzzleId', puzzleId)}
                        />
                    </div>
                    <div className={'game-info'}>
                        <AffiliateLink
                            affiliate_links={affiliate_links}
                            game_name={answer[0]}
                            console_platform={console_platform}
                        />
                        <p className="game_content">{game_content}</p>
                        <br />
                        {submitted_by && (
                            <p className={'submitted-by'}>
                                {t('Submitted By')}: <a href={submitted_by}>{submitted_by}</a>
                            </p>
                        )}
                    </div>
                </div>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum < settings.max_guesses - 1 && (
                <p className="guesses-remaining">
                    {settings.max_guesses - guessNum} {settings.max_guesses - guessNum === 1 ? 'guess' : 'guesses'}{' '}
                    {t('remaining')}!
                </p>
            )}
            {gameState !== 'win' && gameState !== 'lose' && guessNum >= settings.max_guesses - 1 && (
                <p className="guesses-remaining">{t('Last guess')}!</p>
            )}
            {gameState === 'playing' && (
                <div className="PlayArea">
                    {gameState === 'playing' && (
                        <div className="input-area">
                            <AutoComplete
                                onChangeFn={onChange}
                                onKeyDownFn={onKeyDown}
                                onClickFn={onClick}
                                activeSuggestionIndex={activeSuggestionIndex}
                                filteredSuggestionsList={filteredSuggestions}
                                inputValue={guessInputValue}
                                shouldShowSuggestions={showSuggestions}
                                loading={ACloading}
                            />
                        </div>
                    )}
                    <button type="button" className="mainButton submitButton" onClick={submitGuess}>
                        {t('Submit')}
                    </button>
                    {guessList.map((guess, idx) => {
                        let emoji = null;
                        if (isAnswerCorrect(guess, answer)) {
                            emoji = '✅';
                        } else if (franchiseMatch[idx]) {
                            emoji = '🟡';
                        } else if (!isAnswerCorrect(guess, answer)) {
                            emoji = '❌';
                        }
                        return guess === '' ? null : (
                            <div key={'gl-' + idx} className="guess-result">
                                {emoji}
                                {guess}
                                <span className="guess-result-franchise">
                                    {franchiseMatch[idx] && ` (Franchise: ${franchise})`}
                                </span>
                            </div>
                        );
                    })}
                </div>
            )}
            <Footer puzzleId={puzzleId} currentPage={'puzzle'} puzzleType={puzzleType} />
        </div>
    );
};

export default GTGPuzzle;
