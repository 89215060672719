// @flow
import { setModal, setShowInterAd } from '../store/slices/uxSlice';
import { DEV_MODE } from '../globals';

import { useDispatch } from 'react-redux';

import React, { useEffect, useRef, useState } from 'react';
import type { TDispatch } from '../store/store';

import { KOFI_URL } from '../constants';

declare var __GTG_BUILD__: boolean;
declare var __GTA_BUILD__: boolean;
declare var __GTB_BUILD__: boolean;

const CloseButtonIcon = () => (
    <svg width="25" height="25" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M12.8536 2.85355C13.0488 2.65829 13.0488 2.34171 12.8536 2.14645C12.6583 1.95118 12.3417 1.95118 12.1464 2.14645L7.5 6.79289L2.85355 2.14645C2.65829 1.95118 2.34171 1.95118 2.14645 2.14645C1.95118 2.34171 1.95118 2.65829 2.14645 2.85355L6.79289 7.5L2.14645 12.1464C1.95118 12.3417 1.95118 12.6583 2.14645 12.8536C2.34171 13.0488 2.65829 13.0488 2.85355 12.8536L7.5 8.20711L12.1464 12.8536C12.3417 13.0488 12.6583 13.0488 12.8536 12.8536C13.0488 12.6583 13.0488 12.3417 12.8536 12.1464L8.20711 7.5L12.8536 2.85355Z"
            fill="currentColor"
            fillRule="evenodd"
            clipRule="evenodd"
        ></path>
    </svg>
);

const InterstitialAdModal = (): React$Element<'div'> => {
    const dispatch: TDispatch = useDispatch();

    const [wasBannerSetup, setWasBannerSetup] = useState(false);

    const modalRef = useRef<?HTMLDivElement>(null);

    useEffect(() => {
        if (modalRef.current) {
            modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    });

    useEffect(() => {
        if (!wasBannerSetup) {
            const slotId = 'guessthe_custom_interstitial';
            const builds = [
                { condition: __GTG_BUILD__, placementName: 'guessthe-game_custom_interstitial' },
                { condition: __GTA_BUILD__, placementName: 'guesstheaudio_interstitial' },
                { condition: __GTB_BUILD__, placementName: 'guessthebook_interstitial' },
            ];
            builds.forEach((build) => {
                if (build.condition) {
                    window.freestar.config.enabled_slots.push({
                        placementName: build.placementName,
                        slotId: slotId,
                    });
                }
            });
            setWasBannerSetup(true);
        }
    }, [wasBannerSetup]);

    const closeModal = () => {
        dispatch(setModal('none'));
        if (typeof window.globalThis.interAdNextFunc == 'function') {
            window.globalThis.interAdNextFunc();
            window.globalThis.interAdNextFunc = undefined;
        }
        dispatch(setShowInterAd(false));
    };

    return (
        <div ref={modalRef} className="modal inter-ad-modal">
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                }}
            >
                <p>
                    Congrats on knowing the last game! Sorry for this short intrusion, but it helps keep the site going!
                    Don't want to see this again? Consider supporting us on Ko-fi! <a href={KOFI_URL}>{KOFI_URL}</a>
                </p>
                <>
                    <div
                        align="center"
                        style={{ minWidth: 512, minHeight: 512 }}
                        data-freestar-ad="__336x280 __300x600"
                        id="guessthe_custom_interstitial"
                    />
                </>

                <div
                    style={{
                        position: 'absolute',
                        right: 10,
                        top: 10,
                    }}
                >
                    <button type="button" className={'close-modal'} onClick={closeModal}>
                        <CloseButtonIcon />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default InterstitialAdModal;
