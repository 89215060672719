import { useState } from 'react';

function MagnifierGlass({ x, y, imgWidth, imgHeight, magnifierHeight, magnifierWidth, zoomLevel, src }) {
    return (
        <div
            style={{
                position: 'absolute',

                // prevent magnifier blocks the mousemove event of img
                pointerEvents: 'none',
                // set size of magnifier
                height: `${magnifierHeight}px`,
                width: `${magnifierWidth}px`,
                // move element center to cursor pos
                top: `${y - magnifierHeight / 2}px`,
                left: `${x - magnifierWidth / 2}px`,
                opacity: '1', // reduce opacity so you can verify position
                border: '1px solid lightgray',
                borderRadius: '50%',
                backgroundColor: 'white',
                backgroundImage: `url('${src}')`,
                backgroundRepeat: 'no-repeat',

                //calculate zoomed image size
                backgroundSize: `${imgWidth * zoomLevel}px ${imgHeight * zoomLevel}px`,

                //calculate position of zoomed image.
                backgroundPositionX: `${-x * zoomLevel + magnifierWidth / 2}px`,
                backgroundPositionY: `${-y * zoomLevel + magnifierHeight / 2}px`,
            }}
        />
    );
}

export default function ImageMagnifier({
    src,
    component,
    width,
    height,
    magnifierHeight = 100,
    magnifierWidth = 100,
    zoomLevel = 1.5,
    alt,
}: {
    src: string,
    component?: HTMLElement,
    width?: string,
    height?: string,
    magnifierHeight?: number,
    magnifierWidth?: number,
    zoomLevel?: number,
    alt: string,
}) {
    const [[x, y], setXY] = useState([0, 0]);
    const [[imgWidth, imgHeight], setSize] = useState([0, 0]);
    const [showMagnifier, setShowMagnifier] = useState(false);

    return (
        <div
            style={{
                position: 'relative',
                height: height,
                width: width,
            }}
        >
            <div
                onMouseEnter={(e) => {
                    // update image size and turn-on magnifier
                    const elem = e.currentTarget;
                    const { width, height } = elem.getBoundingClientRect();
                    setSize([width, height]);
                    setShowMagnifier(true);
                }}
                onMouseMove={(e) => {
                    // update cursor position
                    const elem = e.currentTarget;
                    const { top, left } = elem.getBoundingClientRect();

                    // calculate cursor position on the image
                    const x = e.pageX - left - window.pageXOffset;
                    const y = e.pageY - top - window.pageYOffset;
                    setXY([x, y]);
                }}
                onMouseLeave={() => {
                    // close magnifier
                    setShowMagnifier(false);
                }}
                style={{
                    position: 'relative',
                    height: height,
                    width: width,
                }}
            >
                {component ? (
                    component
                ) : (
                    <img src={src} loading={'lazy'} style={{ height: height, width: width }} alt={alt} />
                )}

                {showMagnifier ? (
                    <MagnifierGlass
                        x={x}
                        y={y}
                        imgWidth={imgWidth}
                        imgHeight={imgHeight}
                        magnifierHeight={magnifierHeight}
                        magnifierWidth={magnifierWidth}
                        zoomLevel={zoomLevel}
                        src={src}
                    />
                ) : null}
            </div>
        </div>
    );
}
