// @flow

import GTCPuzzle from '../components/Puzzle/GTCPuzzle';
import OriginalImages from '../components/OriginalImages';
import PreviousPuzzles from '../components/PreviousPuzzles';
import GTGPuzzle from '../components/Puzzle/GTGPuzzle';
import Setting from '../components/Setting';
import Stats from '../components/Stats';
import { type GameSettings } from './game_settings';
import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import ResetPuzzle from '../components/ResetPuzzle';
import { GTG_COMMUNITY_SUBMISSION_URL, PUZZLE_TYPES } from '../constants';
import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { AnswerUnion } from './game_settings';
import { answers } from '../puzzles/gtg_puzzles';
import type { TGTGAnswer } from '../puzzles/gtg_puzzles';
import { BaseSettings } from './BaseSettings';
import AffiliateLink from '../components/AffiliateLink';

export class GTGSettings extends BaseSettings {
    static PATHS: {
        email_callback: string,
        guess_the_cover: string,
        original_images: string,
        previous_games: string,
        puzzle: string,
        reset: string,
        setting: string,
        stats: string,
        test_affiliate: string,
        ...
    } = {
        puzzle: '/p/:puzzleId',
        previous_games: '/previous-games',
        reset: '/reset/:puzzleId/:puzzleType', // reset/4/gtg or /reset/4/gtgc
        stats: '/stats/:puzzleId',
        email_callback: '/email_callback/',
        original_images: '/ogi/:puzzleId',
        guess_the_cover: '/gtgc/:puzzleId?',
        setting: '/setting',
        test_affiliate: '/test_affiliate',
    };

    routes(): React$Element<React$FragmentType> {
        return (
            <>
                <Route element={<GTGPuzzle />} path="/" />
                <Route path={GTGSettings.PATHS.puzzle} element={<GTGPuzzle />} />
                <Route path={GTGSettings.PATHS.original_images} element={<OriginalImages />} />
                <Route path={GTGSettings.PATHS.stats} element={<Stats />} />
                <Route path={GTGSettings.PATHS.previous_games} element={<PreviousPuzzles />} />
                <Route
                    path={GTGSettings.PATHS.guess_the_cover}
                    element={<GTCPuzzle puzzleType={PUZZLE_TYPES.GTGC} answers={this.answers} />}
                />
                <Route path={GTGSettings.PATHS.reset} element={<ResetPuzzle />} />
                <Route path={GTGSettings.PATHS.setting} element={<Setting />} />
                <Route path={GTGSettings.PATHS.email_callback} element={<Setting fromEmailCallback={true} />} />
                <Route
                    path={GTGSettings.PATHS.test_affiliate}
                    element={
                        <AffiliateLink
                            affiliate_links={['https://butts.com']}
                            game_name={'Jade Empire'}
                            console_platform={'playstation'}
                        />
                    }
                />
                <Route path="*" element={<Navigate to="/" />} />
            </>
        );
    }

    // original date: may 15, 2022
    puzzle_start_date: Date = new Date('5/15/2022');

    website_url: string = 'GuessThe.Game';
    title: string = 'Guess The Game';
    website_title: string = 'Guess The.Game';
    parent_abbr: string = 'gtg'; // used for submitlogin (so gtgc doesnt mess it up)

    max_guesses: number = 6;
    root_url: string = '/';
    puzzle_type: PUZZLE_TYPES_TYPE = PUZZLE_TYPES.GTG;
    clipboard_tag: string = '#GuessTheGame';
    storage_keys: { guess: string, game_state: string } = { guess: 'guess', game_state: 'gamestate' };
    answers: AnswerUnion = answers;
    website_emoji: string = '🎮'; // Video game
    guess_item: string = 'Game';

    progress_ranks: { [string]: string } = {
        '0': 'Rookie Guesser',
        '5': 'Gamer',
        '10': 'Game Navigator',
        '20': 'Screenshot Sleuth',
        '40': 'Pro Gamer',
        '80': 'Insightful Guesser',
        '150': 'Visual Virtuoso',
        '200': 'Master Gamer',
        '250': 'Game Guessing Guru',
        '300': 'Distinguished Game Detective',
        '350': 'Elite Guesser',
        '400': 'Exalted Game Guru',
        '430': 'Extraordinary Guesser',
        '470': 'Champion of the Six Screens',
        '500': 'Prodigious Game Guesser',
        '530': 'Masterful Mind of the Six',
        '560': 'Superior Screenshot Strategist',
        '600': 'Grandmaster Guesser',
        '630': 'Supreme Screenshot Savant',
        '700': 'Sovereign of Guess the Game',
        '750': 'Grand Game Guesser',
        '800': 'Ultimate Game Connoisseur',
        '900': 'Eternal Expert of Gaming',
        '1000': 'Omni-Gamer',
        '1100': 'Galactic Guru of Gaming',
        '1300': 'Legendary Game Oracle',
    };

    // $FlowIgnore
    get_hint_text(imageIndex: number, puzzle: TGTGAnswer, settings: GameSettings): string {
        const HINT_2 = 'Metacritic Score: ';
        const HINT_3 = 'Original Platform: ';
        const HINT_4 = 'Genre: ';
        const HINT_5 = 'Original Release: ';
        const HINT_6 = 'Developer: ';
        switch (imageIndex) {
            case 2:
                return `${HINT_2} ${puzzle.metacritic_score}`;
            case 3:
                return `${HINT_3} ${puzzle.console_platform}`;
            case 4:
                return `${HINT_4} ${puzzle.genre}`;
            case 5:
                return `${HINT_5} ${puzzle.release_year}`;
            case 6:
                return `${HINT_6} ${puzzle.developer}`;
            default:
                return '';
        }
    }

    TEXTS: any = {
        modal: {
            AboutModal: {
                modalTexts: [
                    `Every day a new ${this.guess_item.toLowerCase()} is selected and 6 screenshots from the game (some gameplay, some UI, some cutscenes - not guaranteed to strictly be gameplay!) are presented to you one at a time.`,
                    'We try to have a selection of different games from different genres, eras, and platforms. We also try to have a mix of games that are well known and games that are more obscure. Generally harder games are on the weekends, and easier games are on weekdays.',
                    {
                        beforeLink: 'Want your favorite game to be featured as a daily puzzle?',
                        url: GTG_COMMUNITY_SUBMISSION_URL,
                        linkText: ' Suggest A Game',
                        afterLink: '',
                    },
                ],
            },
            HowToPlayModal: {
                modalTexts: [
                    'Search for the game that you think the shown screenshot belongs to.',
                    'All 6 screenshots are from the same game.',
                    'If you get it wrong or click skip, we will reveal an additional screenshot of the game.',
                    `You get ${this.max_guesses} guesses in total.`,
                    'Helpful things to know about the hints:',
                    '1. The Metacritic score is the score the game received on Metacritic usually from the platform at the time of release or an average of all scores.',
                    '2. The original platform is the platform the game was originally released on and any platform it was released on within the first 6 months.',
                ],
            },
        },
    };
}
