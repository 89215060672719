// @flow

import type { State } from '../store/types';
import type { GameSettings } from './game_settings';

import { useSelector } from 'react-redux';
import { settingsFactory } from './settingsFactory';

export function useSettings(): GameSettings {
    const { puzzleType } = useSelector((state: State) => state.ux);
    return settingsFactory(puzzleType);
}
