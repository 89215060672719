/* eslint-disable no-useless-escape */
// @flow

export type TGTGAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    twitter_ids: Array<string>,
    release_year: string,
    metacritic_score: string,
    genre: string,
    console_platform: string,
    developer: string,
    backloggd_link: string,
};

type TGTGAnswers = {
    [string]: TGTGAnswer
};

export const answers: TGTGAnswers = {
    '1': {
        content: '',
        submitted_by: 'https://twitter.com/OppositeInvict2',
        answers: ["Mass Effect 3", "Mass Effect III"],
        affiliate_links: ["https://www.g2a.com/mass-effect-3-n7-digital-deluxe-edition-pc-steam-gift-global-i10000043516005?gtag=391e4ef696", "https://www.amazon.com/Mass-Effect-Trilogy-Playstation-sony-playstation3/dp/B083NCDFJM?crid=NC54EESW8ESV&keywords=Mass+Effect+3&qid=1685750942&sprefix=the+legend+of+zelda+ocarina+of+time%2Caps%2C403&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=eed972ce91138da4eb887e8ff2ce4486&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mass Effect',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '89%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'Bioware',
        backloggd_link: '',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: Ocarina of Time"],
        affiliate_links: ["https://www.nintendo.com/store/products/the-legend-of-zelda-ocarina-of-time-3d-3ds/", "https://www.amazon.com/Legend-Zelda-Ocarina-Collectors-nintendo-64/dp/B081HWD676?crid=LBN7OB1SKYKH&keywords=The+Legend+of+Zelda%3A+Ocarina+of+Time&qid=1685750781&sprefix=call+of+duty+modern+warfare+2%2Caps%2C432&sr=8-15&linkCode=ll1&tag=guessthegam06-20&linkId=cf4f3838621b21839d49e79fd2f7ebd7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '99%',
        genre: 'Action, Adventure',
        console_platform: 'N64',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Outer Wilds"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Outer%20Wilds%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fouter-wilds-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/outer-wilds-pc-steam-gift-global-i10000196164003?gtag=391e4ef696", "https://www.amazon.com/Outer-Worlds-Nintendo-Switch/dp/B084H5J2TP?crid=9RDT7MEJKON&keywords=Outer+Wilds&qid=1685750176&sprefix=horizon+zero+dawn%2Caps%2C1065&sr=8-30&linkCode=ll1&tag=guessthegam06-20&linkId=88fae0b3e631b19d089aa7b33b62a494&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '85%',
        genre: 'Open World, Puzzle',
        console_platform: 'PC, PS4, XONE, Switch',
        developer: 'Mobius Digital',
        backloggd_link: '',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Horizon Zero Dawn"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Horizon%20Zero%20Dawn%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhorizon-zero-dawn-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/horizon-zero-dawn-complete-edition-pc-steam-gift-global-i10000145208004?gtag=391e4ef696", "https://www.amazon.com/Horizon-Zero-Dawn-Complete-playstation-4/dp/B07D2NY7RW?crid=1VB42LXVNN8OT&keywords=Horizon+Zero+Dawn&qid=1685750019&sprefix=the+secret+of+monkey+island%2Caps%2C422&sr=8-19&linkCode=ll1&tag=guessthegam06-20&linkId=2f2f1899885997e1be0c0ed730bac925&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Horizon',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '89%',
        genre: 'Action, RPG',
        console_platform: 'PS4',
        developer: 'Guerrilla Games',
        backloggd_link: '',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["The Secret of Monkey Island"],
        affiliate_links: ["https://www.g2a.com/the-secret-of-monkey-island-special-edition-steam-key-global-i10000007335002?gtag=391e4ef696", "https://www.amazon.com/Secret-Monkey-Island-Premium-Sega-CD/dp/B08KGYPVDJ?crid=13Y9PLT9OQN07&keywords=The+Secret+of+Monkey+Island&qid=1685749899&sprefix=grand+theft+auto+iii%2Caps%2C608&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=35924fa749143c67a106c39c4a5d8f50&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Monkey Island',
        twitter_ids: [],
        release_year: '1990',
        metacritic_score: 'Metacritic did not exist yet! But probably 100% ;)',
        genre: 'Point & Click, Adventure',
        console_platform: 'Many',
        developer: 'LucasArts',
        backloggd_link: '',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["Grand Theft Auto 3", "Grand Theft Auto III"],
        affiliate_links: ["https://www.g2a.com/grand-theft-auto-iii-steam-key-global-i10000007301005?aid=13323769?gtag=391e4ef696", "https://www.amazon.com/Grand-Theft-Auto-Trilogy-PlayStation-2/dp/B000KCX9M4?crid=2TLU57P5X00S6&keywords=Grand+Theft+Auto+III&qid=1685749755&sprefix=resident+evil+4%2Caps%2C472&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=8ddb4ef6beb09da463ed34c853fb385a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Grand Theft Auto',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '93%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Rockstar Games',
        backloggd_link: '',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Resident Evil 4"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Resident%20Evil%204%20Deluxe%20Edition%20Remake%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fresident-evil-4-deluxe-edition-remake-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/resident-evil-4-remake-deluxe-edition-preorder-bonus-pc-steam-key-global-i10000337236037?gtag=391e4ef696", "https://www.amazon.com/Resident-Evil-4-Collectors-Playstation-5/dp/B0BZN6JVDP?crid=2A2QMSMWHLOB8&keywords=Resident+Evil+4&qid=1685749567&sprefix=portal+2%2Caps%2C449&sr=8-4&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=8d243a7b83213e662da3f57e17fe876f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Resident Evil',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '96%',
        genre: 'Survival, Horror, Action, Adventure',
        console_platform: 'GameCube',
        developer: 'Capcom Production Studio 4',
        backloggd_link: '',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Portal 2"],
        affiliate_links: ["https://www.g2a.com/portal-2-steam-gift-global-i10000001119002?gtag=391e4ef696", "https://www.amazon.com/Portal-2-Playstation-3/dp/B003O6E3C8?crid=G2QU6DDCEZC7&keywords=Portal+2&qid=1685749464&sprefix=portal+2%2Caps%2C384&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=ddfed19755150dff109ba7b6e95676a4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Portal',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '95%',
        genre: 'Puzzle',
        console_platform: 'PC, PS3, X360',
        developer: 'Valve',
        backloggd_link: '',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Shadow of the Colossus"],
        affiliate_links: ["https://www.amazon.com/Shadow-Colossus-PlayStation-2-Certified-Refurbished/dp/B07KQG3N4B?crid=1DUJI1ZVYDYJK&keywords=Shadow+of+the+Colossus&qid=1685749202&sprefix=mario+golf%2Caps%2C472&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=10802ce5f3aac393d97c61b6eaf414ef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ico',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '91%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'SCE Japan Studio',
        backloggd_link: '',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Mario Golf"],
        affiliate_links: ["https://www.amazon.com/Mario-Golf-Renewed-nintendo-64/dp/B084C2XPBG?crid=3OU5EU7O9G090&keywords=Mario+Golf&qid=1685749124&sprefix=call+of+duty+modern+warfare+2%2Caps%2C405&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=56f3c3ed26abb14278cfce16d143be40&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '91%',
        genre: 'Sports',
        console_platform: 'N64',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["Call of Duty: Modern Warfare 2", "Call of Duty: Modern Warfare II"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Call%20of%20Duty%20Modern%20Warfare%202%202009%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcall-of-duty-modern-warfare-2-2009-pc%2F&intsrc=PUI2_9616", "https://www.g2a.com/call-of-duty-modern-warfare-2-bundle-steam-key-global-i10000048518002?gtag=391e4ef696", "https://www.g2a.com/call-of-duty-modern-warfare-2-bundle-steam-key-global-i10000048518002"],
        franchise: 'Call of Duty',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '86%',
        genre: 'Shooter',
        console_platform: 'PC, PS3, X360',
        developer: 'Infinity Ward',
        backloggd_link: '',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["The Witness"],
        affiliate_links: ["https://www.g2a.com/the-witness-gogcom-key-global-i10000009441008?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '87%',
        genre: 'Puzzle',
        console_platform: 'PC, PS4',
        developer: 'Thekla Inc',
        backloggd_link: '',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Halo: Combat Evolved"],
        affiliate_links: ["https://www.g2a.com/halo-combat-evolved-anniversary-dlc-steam-gift-global-i10000193803002?gtag=391e4ef696", "https://www.amazon.com/Halo-Combat-Evolved-Anniversary-Xbox-360/dp/B0050SYY5E?crid=4V4EKOQ5GV50&keywords=Halo%3A+Combat+Evolved&qid=1685747992&sprefix=bloodborne%2Caps%2C465&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=7db4012ecacc2422b49463745cd53298&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Halo',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '97%',
        genre: 'Shooter',
        console_platform: 'Xbox',
        developer: 'Bungie',
        backloggd_link: '',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Bloodborne"],
        affiliate_links: ["https://www.g2a.com/bloodborne-ps4-psn-account-account-global-i10000006490003?aid=13419419?gtag=391e4ef696", "https://www.amazon.com/Sony-Bloodborne-Game-Year-Ps4/dp/B0188QFSHC?crid=CDXAJI9SNZU7&keywords=Bloodborne&qid=1685747919&sprefix=bloodborne%2Caps%2C464&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=71cce4cfb978daa89b0e40726f17ef7b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bloodborne',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '92%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'From Software',
        backloggd_link: '',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Forza Horizon 5"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Forza%20Horizon%205%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fforza-horizon-5-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/forza-horizon-5-premium-edition-pc-steam-gift-global-i10000256484019?gtag=391e4ef696", "https://www.amazon.com/Forza-Horizon-Premium-Windows-Digital/dp/B09DCR61Q9?crid=2LQDSG59WYNAF&keywords=Forza+Horizon+5&qid=1685747670&sprefix=super+meat+boy%2Caps%2C454&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=f950746d56db4b0b33c8be3d0f1a280e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Forza',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '92%',
        genre: 'Racing',
        console_platform: 'PC, Xbox Series',
        developer: 'Playground Games',
        backloggd_link: '',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Super Meat Boy"],
        affiliate_links: ["https://www.g2a.com/super-meat-boy-steam-gift-global-i10000003796002?gtag=391e4ef696", "https://www.amazon.com/Limited-Nintendo-Switch-Alternate-Physical-Region/dp/B07MK3ZMBF?crid=H5NQMKMJ68PQ&keywords=Super+Meat+Boy&qid=1685747340&sprefix=the+elder+scrolls+iv+oblivion%2Caps%2C524&sr=8-8&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=6e9d7f56ac49af1db8903cfd6de0d0bb&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Super Meat Boy',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '87%',
        genre: 'Platformer',
        console_platform: 'X360',
        developer: 'Team Meat',
        backloggd_link: '',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["The Elder Scrolls IV: Oblivion"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Elder%20Scrolls%20IV%20Oblivion%20Game%20of%20the%20Year%20Edition%20Deluxe%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-elder-scrolls-iv-oblivion-game-of-the-year-edition-deluxe%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-elder-scrolls-iv-oblivion-goty-steam-gift-global-i10000003911001?gtag=391e4ef696", "https://www.amazon.com/Elder-Scrolls-IV-Oblivion-Collectors-Xbox/dp/B08F4PSHVV?crid=2D7S2ZFDYD8VJ&keywords=The+Elder+Scrolls+IV%3A+Oblivion&qid=1685747127&sprefix=the+elder+scrolls+iv+oblivion%2Caps%2C433&sr=8-11&linkCode=ll1&tag=guessthegam06-20&linkId=e3dcf7983bc323b70d69cad057abf9b8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Elder Scrolls',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '94%',
        genre: 'Action, RPG',
        console_platform: 'PC, X360',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["BioShock Infinite"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Bioshock%20Infinite%20Post%20Overflow%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbioshock-infinite%2F&intsrc=PUI2_9608", "https://www.g2a.com/bioshock-infinite-season-pass-steam-gift-global-i10000004195004?gtag=391e4ef696", "https://www.amazon.com/BioShock-Infinite-Ultimate-Songbird-PC/dp/B009PJ9N4G?crid=RJHI7B82M3F0&keywords=BioShock+Infinite&qid=1685746939&sprefix=chrono+trigger.%2Caps%2C404&sr=8-14&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=3e1fcf72a665c6adec5e427ebd0d2bdf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bioshock',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '94%',
        genre: 'Shooter',
        console_platform: 'PC, PS3, X360',
        developer: 'Irrational Games',
        backloggd_link: '',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Chrono Trigger"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Chrono%20Trigger%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fchrono-trigger%2F&intsrc=PUI2_9608", "https://www.g2a.com/chrono-trigger-steam-key-global-i10000156705001?aid=13466295?gtag=391e4ef696", "https://www.amazon.com/Chrono-Trigger-Renewed-nintendo-ds/dp/B07WSRW69Z?crid=1D8UPB8RYZVG8&keywords=Chrono+Trigger.&qid=1685746817&sprefix=furi%2Caps%2C416&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=80db2cc3fcb73a93215db5f4d5a580a7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Chrono',
        twitter_ids: [],
        release_year: '1995',
        metacritic_score: 'Too old for metacritic!',
        genre: 'JRPG',
        console_platform: 'SNES',
        developer: 'Square',
        backloggd_link: '',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["Furi"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Furi%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffuri%2F&intsrc=PUI2_9608", "https://www.g2a.com/furi-steam-key-global-i10000019195003?aid=13466439?gtag=391e4ef696", "https://www.amazon.com/Furi-Definitive-Switch-Limited-Run-Nintendo/dp/B07KVNRC31?crid=1J0U3LIX6KAFI&keywords=Furi&qid=1685746730&sprefix=furi%2Caps%2C828&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=2fd03afc6af31f427ee6882c556393ae&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '76%',
        genre: 'Action, Adventure, Hack and Slash, Bullet Hell',
        console_platform: 'PC, PS4',
        developer: 'The Game Bakers',
        backloggd_link: '',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Yakuza 0"],
        affiliate_links: ["https://www.g2a.com/yakuza-0-steam-gift-global-i10000156568002?gtag=391e4ef696", "https://www.amazon.com/Yakuza-Remastered-Collection-PlayStation-4/dp/B07WNS15YQ?keywords=Yakuza%2B0&qid=1685746511&sr=8-8&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=593b88af20ae44b806fc3aedd61af0de&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Yakuza',
        twitter_ids: [],
        release_year: '2015 (Japan), 2017 (NA/EU)',
        metacritic_score: '85%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, PS4',
        developer: 'Ryu Ga Gotoku Studio/Sega',
        backloggd_link: '',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["Dead Cells"],
        affiliate_links: ["https://www.g2a.com/dead-cells-steam-gift-global-i10000041807002?gtag=391e4ef696", "https://www.amazon.com/Dead-Cells-Action-Game-Nintendo-Switch/dp/B07SSV287J?crid=RIZ46T3DKS39&keywords=Dead%2BCells&qid=1685663990&sprefix=persona%2B5%2Caps%2C378&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=7e33108e2b9d97878b51bd71db063c23&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '89%',
        genre: 'Action, Platformer',
        console_platform: 'PC, Switch, PS4, XONE',
        developer: 'Motion Twin',
        backloggd_link: '',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Persona 5"],
        affiliate_links: ["https://www.g2a.com/persona-5-royal-pc-steam-gift-global-i10000192991005?gtag=391e4ef696", "https://www.amazon.com/Persona-5-Royal-Nintendo-Switch-Swtich/dp/B0BHWVBCR2?crid=2BFUJNOJHE5JT&keywords=Persona+5&qid=1685663328&sprefix=nier+automata%2Caps%2C386&sr=8-14&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=c73b14ff260d6fa0b2a9c97f09095a48&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Megami Tensei',
        twitter_ids: [],
        release_year: '2016 (JP), 2017 (Worldwide)',
        metacritic_score: '93%',
        genre: 'JRPG',
        console_platform: 'PS3, PS4',
        developer: 'Atlus',
        backloggd_link: '',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["NieR:Automata"],
        affiliate_links: ["https://www.g2a.com/nier-automata-game-of-the-yorha-edition-steam-gift-global-i10000183050001?gtag=391e4ef696", "https://www.amazon.com/NieR-Automata-Nintendo-Switch-Digital/dp/B0B7N8G8V4?crid=WFDEXA6T9TAW&keywords=NieR%3AAutomata&qid=1685663048&sprefix=super+mario+64%2Caps%2C385&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=887dd994fa652f177c676eeb16531cab&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Drakengard',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '88%',
        genre: 'Action, RPG (Religious Experience)',
        console_platform: 'PC, PS4',
        developer: 'PlatinumGames',
        backloggd_link: '',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario 64"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-64-Renewed-Nintendo/dp/B07WTXLQKQ?crid=282E2VWVC9R77&keywords=Super+Mario+64&qid=1685662828&sprefix=the+last+of+us%2Caps%2C636&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=f734baff647dc6042671b93c6e125fa0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: '94%',
        genre: 'Platformer',
        console_platform: 'N64',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["The Last of Us"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Last%20of%20Us%20Part%20I%20Digital%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-last-of-us-part-i-digital-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-last-of-us-part-i-preorder-bonus-pc-steam-key-global-i10000326425021?gtag=391e4ef696", "https://www.amazon.com/Last-Us-Part-PlayStation-5/dp/B0B3QWRQL8?crid=279LN4FURAH1R&keywords=The+Last+of+Us&qid=1685662654&sprefix=the+last+of+u%2Caps%2C442&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=9b194917763883a725613fa961476f95&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Last of Us',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '95%',
        genre: 'Action, Adventure',
        console_platform: 'PS3',
        developer: 'Naughty Dog',
        backloggd_link: '',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Left 4 Dead"],
        affiliate_links: ["https://www.g2a.com/left-4-dead-bundle-steam-gift-global-i10000007891003?gtag=391e4ef696", "https://www.amazon.com/NEW-Left-Dead-Videogame-xbox-360/dp/B004Y3D4WU?crid=1WWHVQD77BOP8&keywords=Left+4+Dead&qid=1685662546&sprefix=left+4+dead%2Caps%2C651&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=c63cb66f74ccc526b599a8edb156cba6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Left 4 Dead',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '89%',
        genre: 'Shooter',
        console_platform: 'PC, X360',
        developer: 'Valve South',
        backloggd_link: '',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["SimCopter"],
        affiliate_links: ["https://www.amazon.com/SimCopter-PC/dp/B00002EPY8?crid=1MP6DD3K888WB&keywords=SimCopter&qid=1685662428&sprefix=diablo+game%2Caps%2C388&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=fbc82eee4ea2831c8398b2f740f1083f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'SimCity',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Flight Simulator',
        console_platform: 'PC',
        developer: 'Maxis',
        backloggd_link: '',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["Diablo"],
        affiliate_links: ["https://www.g2a.com/diablo-hellfire-pc-gogcom-key-global-i10000192317001?gtag=391e4ef696", "https://www.amazon.com/Diablo-PC/dp/B0007YBJ1S?crid=3K2WP9CQ83KCX&keywords=Diablo+game&qid=1685662070&sprefix=diablo+game%2Caps%2C405&sr=8-8&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=ac954147d2f88d7871f6563b7f6a9129&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Diablo',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '94%',
        genre: 'ARPG',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Dark Souls"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DARK%20SOULS%20REMASTERED%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdark-souls-remastered-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dark-souls-remastered-pc-steam-key-global-i10000155592001?aid=13269947?gtag=391e4ef696", "https://www.amazon.com/Dark-Souls-Trilogy-PS4-playstation-4/dp/B07MG7Y4CB?crid=27ZALAHGJ47KQ&keywords=Dark+Souls&qid=1685661444&sprefix=ghostwire+tokyo%2Caps%2C408&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=b23ffaeb1058954ecde157a66d3aa15d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dark Souls',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '89%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360',
        developer: 'From Software Inc.',
        backloggd_link: '',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Ghostwire: Tokyo"],
        affiliate_links: ["https://www.g2a.com/ghostwire-tokyo-deluxe-edition-pc-steam-key-global-i10000302559010?aid=13207990?gtag=391e4ef696", "https://www.amazon.com/Ghostwire-Tokyo-Deluxe-PlayStation-5/dp/B09S2ZJHR5?crid=XE0JVZVI54RA&keywords=Ghostwire%3A+Tokyo&qid=1685661210&sprefix=ghostwire+tokyo%2Caps%2C468&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=f1c8cb2ec4b08cd85ff3fd035c408c4a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '77%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS5',
        developer: 'Tango Gameworks',
        backloggd_link: '',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Night In The Woods"],
        affiliate_links: ["https://www.g2a.com/night-in-the-woods-steam-key-global-i10000034896004?gtag=391e4ef696", "https://www.amazon.com/Night-Woods-Nintendo-Switch-Digital/dp/B07D1F6BJ2?crid=2DTXLB0FCFUS7&keywords=night+in+the+woods+video+game&qid=1685661107&sprefix=night+in+the+woods+game%2Caps%2C464&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=f70ff26221a76a9b5f933a194b8a19d6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '88%',
        genre: 'Narrative? Adventure? What even are genres?',
        console_platform: 'PC, PS4',
        developer: 'Secret Lab & Infinite Fall',
        backloggd_link: '',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: Breath of the Wild"],
        affiliate_links: ["https://www.g2a.com/the-legend-of-zelda-breath-of-the-wild-nintendo-switch-nintendo-eshop-key-united-states-i10000154753005?gtag=391e4ef696", "https://www.amazon.com/Legend-Zelda-Expansion-Nintendo-Digital/dp/B078JXVGS7?crid=3PAK70IMQJKHA&keywords=The%2BLegend%2Bof%2BZelda%3A%2BBreath%2Bof%2Bthe%2BWild&qid=1685660942&sprefix=fable%2Bthe%2Blost%2Bchapter%2Caps%2C420&sr=8-13&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=7368eede414322cc0cf571aab33acb31&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '97%',
        genre: 'Action, Adventure',
        console_platform: 'Switch, Wii U',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '34': {
        content: 'Fable is a game that transports players to a magical world of Albion where the player takes on the role of a hero capable of shaping the world around them. In Albion, you make the choices that affect how the world treats you, whether you\'ll be seen as a benevolent hero or a ruthless tyrant. You can choose to take on quests or wander the open world fighting monsters and looting treasure. Your actions can even affect your character\'s physical appearance, as alignment with good or evil will physically alter his/her appearance. The game also features unique mechanics such as the ability to marry NPCs and have children, which can further influence your game stories and change how NPCs react to you. One of the best features of Fable is its story, which is layered and complex. The game is divided into distinct chapters that tie together to form a comprehensive tale of loss, betrayal, and redemption. The narrative is also inextricably tied to the game\'s moral alignments, with different paths branching off from different moral choices.',
        submitted_by: '',
        answers: ["Fable: The Lost Chapter", "Fable"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fable%20The%20Lost%20Chapters%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffable-the-lost-chapters%2F&intsrc=PUI2_9608", "https://www.g2a.com/fable-the-lost-chapters-steam-key-global-i10000043886003?aid=11444407?gtag=391e4ef696", "https://www.amazon.com/Fable-Lost-Chapters-PC/dp/B0001UEHP8?crid=WNCBYG0ZER6S&keywords=Fable%3A%2BThe%2BLost%2BChapter&qid=1685660616&sprefix=dota%2B2%2Bpc%2Bgame%2Caps%2C488&sr=8-7&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=8bf6d2a20668eb364a02b81916172b24&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Fable',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '85%',
        genre: 'Action, RPG',
        console_platform: 'Xbox (2004), PC (2005)',
        developer: 'Big Blue Box Studios',
        backloggd_link: '',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Dota 2"],
        affiliate_links: [],
        franchise: 'Dota',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '90%',
        genre: 'MOBA',
        console_platform: 'PC',
        developer: 'Valve',
        backloggd_link: '',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["World of Warcraft"],
        affiliate_links: ["https://www.amazon.com/World-Warcraft-15th-Anniversary-Collectors-Collector/dp/B07RXWJLX1?crid=1U15NE62KQBSP&keywords=world+of+warcraft+collector%27s+edition&qid=1685577494&sprefix=World+of+Warcraft+collector%2Caps%2C815&sr=8-4&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=9e3872db6d32318061017b4ec4119983&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warcraft',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '93%',
        genre: 'MMO',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["Dead Space"],
        affiliate_links: ["https://www.g2a.com/dead-space-remake-pc-steam-gift-global-i10000336977011?aid=13390417?gtag=391e4ef696", "https://www.amazon.com/Dead-Space-Collectors-PS5-playstation-5/dp/B0BXZTX2WL?crid=3KU5HJTCETJTJ&keywords=Dead+Space&qid=1685576913&sprefix=sea+of+thieves%2Caps%2C556&sr=8-13&ufe=app_do%3Aamzn1.fos.2b70bf2b-6730-4ccf-ab97-eb60747b8daf&linkCode=ll1&tag=guessthegam06-20&linkId=ce56d1d78fb00db0743ce4560115f6f0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dead Space',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '86%',
        genre: 'Survival, Horror',
        console_platform: 'PS3, X360, PC',
        developer: 'EA Redwood Shores',
        backloggd_link: '',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["Sea of Thieves"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Sea%20of%20Thieves%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsea-of-thieves-deluxe-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/sea-of-thieves-pc-steam-gift-global-i10000145411012?gtag=391e4ef696", "https://www.amazon.com/Sea-Thieves-Standard-Xbox-Digital/dp/B0922SQCBR?crid=1COCL8RZNFKQX&keywords=Sea+of+Thieves&qid=1685576718&sprefix=sea+of+thieves%2Caps%2C409&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=046ae29f92528bd2de2dcb4c618bc2f1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sea of Thieves',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '67% (this is a crime!)',
        genre: 'Action, Adventure',
        console_platform: 'PC, XONE',
        developer: 'Rare',
        backloggd_link: '',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Monster Hunter: World"],
        affiliate_links: ["https://www.g2a.com/monster-hunter-world-steam-gift-global-i10000084263008?gtag=391e4ef696", "https://www.g2a.com/monster-hunter-world-steam-gift-global-i10000084263008"],
        franchise: 'Monster Hunter',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '90%',
        genre: 'Action, RPG',
        console_platform: 'PS4, XONE, PC',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Hollow Knight"],
        affiliate_links: ["https://www.g2a.com/hollow-knight-pc-steam-key-global-i10000034437006?aid=13233088?gtag=391e4ef696", "https://www.amazon.com/Hollow-Knight-Collectors-Sony-PlayStation-PS4/dp/B07XDD2W6N?crid=2H5WEIG6DTOGU&keywords=Hollow+Knight&qid=1685576354&sprefix=destiny+2%2Caps%2C559&sr=8-13&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=77573c369290e042c9d60c058c932fe3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hollow Knight',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '87%',
        genre: 'Platformer',
        console_platform: 'PC',
        developer: 'Team Cherry',
        backloggd_link: '',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Destiny 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Destiny%202%20Legacy%20Collection%202023%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdestiny-2-legacy-collection-2023-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/destiny-2-legacy-collection-2022-pc-steam-key-global-i10000302225002?aid=13299711?gtag=391e4ef696", "https://www.amazon.com/Destiny-2-Limited-Xbox-One/dp/B06XTQ9WFM?crid=3T5AEQ69KU2NE&keywords=Destiny+2&qid=1685576108&sprefix=animal+crossing+new+horizons%2Caps%2C581&sr=8-62&linkCode=ll1&tag=guessthegam06-20&linkId=fb6e75b701190344071e0b88fc77d5a5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Destiny',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '83%',
        genre: 'FPS, MMORPG',
        console_platform: 'PC, PS4, XONE',
        developer: 'Bungie',
        backloggd_link: '',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["Animal Crossing: New Horizons"],
        affiliate_links: ["https://www.g2a.com/animal-crossing-new-horizons-nintendo-switch-nintendo-eshop-key-united-states-i10000192990003?gtag=391e4ef696", "https://www.amazon.com/Animal-Crossing-Horizons-Standard-Nintendo/dp/B0BTKR52S1?crid=FMZ9IS6QKOJO&keywords=Animal+Crossing%3A+New+Horizons&qid=1685575992&sprefix=the+witcher+3+wild+hunt%2Caps%2C385&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=76673d6d1dda992b3cdf1ebe6c394003&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Animal Crossing',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '90%',
        genre: 'Simulation, apparently? 🤔',
        console_platform: 'Switch',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["The Witcher 3: Wild Hunt", "The Witcher 3"],
        affiliate_links: ["https://www.g2a.com/the-witcher-3-wild-hunt-goty-edition-steam-gift-global-i10000026141004?gtag=391e4ef696", "https://www.amazon.com/Bandai-Entertainment-Witcher-Complete-Nintendo-Switch/dp/B082P5XQMN?crid=V89PYR1UURSV&keywords=The+Witcher+3%3A+Wild+Hunt&qid=1685575862&sprefix=gris%2Caps%2C458&sr=8-7&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=7ca52f47c7fd3a0cb488362f4f624e32&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Witcher',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '93%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS4, XONE',
        developer: 'CD Project Red',
        backloggd_link: '',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["GRIS"],
        affiliate_links: ["https://www.g2a.com/gris-steam-gift-global-i10000176461003?gtag=391e4ef696", "https://www.amazon.com/Gris-Nintendo-Switch/dp/B099L44Z27?crid=23FLM09UFM6B4&keywords=GRIS&qid=1685575775&sprefix=returnal%2Caps%2C396&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=62b5fde249024677e41f919cb56158e7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '84%',
        genre: 'Platformer',
        console_platform: 'PC, Switch',
        developer: 'Nomada Studios',
        backloggd_link: '',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Returnal"],
        affiliate_links: ["https://www.g2a.com/returnal-pc-steam-gift-global-i10000245048008?aid=13403390?gtag=391e4ef696", "https://www.amazon.com/Returnal-PlayStation-5/dp/B08B41ZBXH?crid=BPRK7R0T3WC2&keywords=Returnal&qid=1685575680&sprefix=super+mario+rpg+legend+of+the+seven+stars%2Caps%2C523&sr=8-3&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=8bad4d3bcc6ac44d3c5cde12b76733b3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '86%',
        genre: 'Action, Adventure',
        console_platform: 'PS5',
        developer: 'Housemarque',
        backloggd_link: '',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario RPG: Legend of the Seven Stars"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-RPG-Legend-Renewed-Nintendo/dp/B07NY3GJ7X?crid=332ASUMAKYQ4Y&keywords=Super+Mario+RPG%3A+Legend+of+the+Seven+Stars&qid=1685575570&sprefix=super+mario+rpg+legend+of+the+seven+stars%2Caps%2C365&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=76542528aab6d04175e79d6744ea179a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'Too old for metacritic!',
        genre: 'RPG',
        console_platform: 'SNES',
        developer: 'Square (not Nintendo? 😲)',
        backloggd_link: '',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Code Vein"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=CODE%20VEIN%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcode-vein-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/code-vein-deluxe-edition-pc-steam-account-global-i10000084218032?gtag=391e4ef696", "https://www.amazon.com/Code-Vein-Deluxe-Xbox-Digital/dp/B07W2YXG3X?crid=20YBS7K5XQ0ZJ&keywords=Code+Vein&qid=1685575418&sprefix=code+vein%2Caps%2C466&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=436d0c2a14497394cdf7a9f760f5f964&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '70%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Bandai Namco Entertainment',
        backloggd_link: '',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Deep Rock Galactic"],
        affiliate_links: ["https://www.g2a.com/deep-rock-galactic-deluxe-edition-pc-steam-account-global-i10000081171028?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '85%',
        genre: 'Shooter, RPG, Dwarf sim',
        console_platform: 'PC, XONE',
        developer: 'Ghost Ship Games',
        backloggd_link: '',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Apex Legends"],
        affiliate_links: ["https://www.g2a.com/apex-legends-bloodhound-edition-pc-origin-key-global-i10000192584012?gtag=391e4ef696", "https://www.amazon.com/Apex-Legends-Bloodhound-No-Operating-System/dp/B07Y8RM45F?crid=258IISEJ5JLOG&keywords=Apex+Legends+game&qid=1685575037&sprefix=apex+legends+game%2Caps%2C501&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=3f8cc851107e19cff93dc7d0fa6c611c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Titanfall',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '88%',
        genre: 'FPS, Battle Royale',
        console_platform: 'PC, PS4, XONE',
        developer: 'Respawn Entertainment',
        backloggd_link: '',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Nidhogg"],
        affiliate_links: ["https://www.g2a.com/nidhogg-steam-key-global-i10000004800004?aid=13423283?gtag=391e4ef696"],
        franchise: 'Nidhogg',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '81%',
        genre: 'Fighting (sorta?)',
        console_platform: 'PC (later ported to PS4 & Vita)',
        developer: 'Meshoff',
        backloggd_link: '',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Sid Meier's Civilization VI", "Sid Meier's Civilization 6"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sid%20Meiers%20Civilization%20VI%20Anthology%20-%20MAC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsid-meiers-civilization-vi-anthology-mac%2F&intsrc=PUI2_9608", "https://www.g2a.com/sid-meiers-civilization-vi-anthology-pc-steam-key-global-i10000255897001?gtag=391e4ef696", "https://www.amazon.com/Sid-Meiers-Civilization-VI-Anthology/dp/B097BF6HRF?crid=2TQXHJU7REF0K&keywords=Sid+Meier%27s+Civilization+VI&qid=1685145531&sprefix=katamari+damacy%2Caps%2C388&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=776df72e7ee8dbe6bdae27ea1ac7fa81&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Civilization',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '88%',
        genre: 'Turn-based Strategy',
        console_platform: 'PC',
        developer: 'Firaxis Games',
        backloggd_link: '',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Katamari Damacy"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Katamari%20Damacy%20REROLL%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkatamari-damacy-reroll-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/katamari-damacy-reroll-steam-key-global-i10000176912001?aid=13447984?gtag=391e4ef696", "https://www.amazon.com/Katamari-Damacy-REROLL-Nintendo-Digital/dp/B07L8C9S9N?crid=38VDLQ158U3CI&keywords=Katamari+Damacy&qid=1685145264&sprefix=overwatch%2Caps%2C770&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=602557e8f678944ca5e3e51a8eeaabbf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Katamari Damacy',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '86%',
        genre: 'Puzzle, Action',
        console_platform: 'PS2',
        developer: 'Namco',
        backloggd_link: '',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Overwatch"],
        affiliate_links: ["https://www.amazon.com/Overwatch-Legendary-PlayStation-4/dp/B07D9QWSG1?crid=2KRO0XSDBQLJK&keywords=Overwatch&qid=1685145181&sprefix=overwatch%2Caps%2C425&sr=8-27&linkCode=ll1&tag=guessthegam06-20&linkId=4d6a109d2c4cf1de463ccfe8d5f8b23a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Overwatch',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '91%',
        genre: 'FPS',
        console_platform: 'PC, PS4, XONE',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["Inside"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=INSIDE%20%20LIMBO%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Finside-limbo%2F&intsrc=PUI2_9608", "https://www.g2a.com/inside-steam-gift-global-i10000019615003?gtag=391e4ef696", "https://www.amazon.com/Inside-Limbo-Double-Pack-PS4-playstation-4/dp/B073PHLHP8?crid=1RHBHVPVR6IMY&keywords=inside+game+ps4&qid=1685145055&sprefix=Inside+game%2Caps%2C360&sr=8-5&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=7228c1dffa05d3c37f10495eeaff9513&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '87%',
        genre: 'Platformer',
        console_platform: 'PC, PS4, XONE',
        developer: 'Playdead',
        backloggd_link: '',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["Batman: Arkham Asylum"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Batman%20Arkham%20Asylum%20Game%20of%20the%20Year%20Overflow%201%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbatman-arkham-asylum-game-of-the-year%2F&intsrc=PUI2_9608", "https://www.g2a.com/batman-arkham-asylum-goty-pc-steam-key-global-i10000001347004?aid=13445291?gtag=391e4ef696", "https://www.amazon.com/Batman-Arkham-Asylum-Game-PlayStation-PS3/dp/B01CVFD9XQ?keywords=Batman%3A+Arkham+Asylum+game&qid=1685144667&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=333ee7556e7cbfaf88150bfaa058b21e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Batman',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '91%',
        genre: 'Action Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'Rocksteady Studios',
        backloggd_link: '',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Dragon Age: Inquisition"],
        affiliate_links: ["https://www.g2a.com/dragon-age-inquisition-game-of-the-year-edition-origin-key-global-i10000007382003?aid=13318487?gtag=391e4ef696", "https://www.amazon.com/Dragon-Age-Inquisition-Game-Year-PlayStation/dp/B015OYM10I?crid=160S4RGVDEQKS&keywords=Dragon+Age%3A+Inquisition&qid=1685144165&sprefix=dragon+age+inquisition%2Caps%2C364&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=fa03606bb69dd4e169040bf485f6ce02&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dragon Age',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '85%',
        genre: 'RPG',
        console_platform: 'PC, PS3, PS4, X360, XONE',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["DIRT 4"],
        affiliate_links: ["https://www.g2a.com/dirt-4-pc-steam-key-global-i10000033853006?aid=13433798?gtag=391e4ef696", "https://www.amazon.com/Dirt-4-Xbox-One/dp/B07B8QZ63P?crid=2NTKDE0LJLA00&keywords=DIRT+4&qid=1685143888&sprefix=dirt+4%2Caps%2C377&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=b9f71ed8084b9570dc23661225ffd7be&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'DIRT',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '78%',
        genre: 'Racing',
        console_platform: 'PC, PS4, XONE',
        developer: 'Codemasters',
        backloggd_link: '',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Spore"],
        affiliate_links: ["https://www.g2a.com/spore-complete-pack-steam-gift-global-i10000017639001?gtag=391e4ef696", "https://www.amazon.com/Spore-Windows-XP-Vista-Mac-OS/dp/B000FN7K2S?crid=2GLIWA5BVLQUL&keywords=Spore&qid=1685143217&sprefix=spore%2Caps%2C419&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=712fb32b3f3fc1d88a5bd059c289426f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '84%',
        genre: 'Simulation? (Metacritic lists the genre as \'breeding\'  🤮)',
        console_platform: 'PC',
        developer: 'Maxis',
        backloggd_link: '',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["Burnout 3: Takedown", "Burnout 3"],
        affiliate_links: [],
        franchise: 'Burnout',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '94%',
        genre: 'Arcade Racing',
        console_platform: 'PS2, Xbox',
        developer: 'Criterion Games',
        backloggd_link: '',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["The Sims"],
        affiliate_links: ["https://www.amazon.com/Sims-Complete-Collection-PC/dp/B000BAVN14?crid=2AGUHWN17FOPO&keywords=The+Sims&qid=1685142849&sprefix=the+sims%2Caps%2C395&sr=8-36&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=cf0815cf5c4fb50ab04765c76fb54b18&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Sims',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '92%',
        genre: 'Simulation',
        console_platform: 'PC',
        developer: 'Maxis',
        backloggd_link: '',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars: Knights of the Old Republic"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Star%20Wars%20Knights%20of%20the%20Old%20Republic%20MAC%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstar-wars-knights-of-the-old-republic-mac%2F&intsrc=PUI2_9608", "https://www.g2a.com/star-wars-knights-of-the-old-republic-pc-steam-key-global-i10000007302003?aid=13264097?gtag=391e4ef696", "https://www.amazon.com/Star-Wars-Knights-Republic-Premium-Nintendo/dp/B0BZZVTYPK?crid=7SLDMALHX6FM&keywords=Star+Wars%3A+Knights+of+the+Old+Republic&qid=1685142725&sprefix=star+wars+knights+of+the+old+republic%2Caps%2C401&sr=8-4&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=9b6d10501b25bce5d6a24cb9ac118060&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Star Wars',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '93%',
        genre: 'RPG',
        console_platform: 'PC, Xbox',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Super Smash Bros. Ultimate"],
        affiliate_links: ["https://www.amazon.com/Super-Smash-Bros-Ultimate-Nintendo-Switch/dp/B01N5OKGLH?crid=2UC12NW6W3OF4&keywords=Super%2BSmash%2BBros.%2BUltimate&qid=1685142308&sprefix=super%2Bsmash%2Bbros.%2Bultimate%2Caps%2C674&sr=8-1&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=c4d22b7c59aada3029f0462259db5bc2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Smash Brothers',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '93%',
        genre: 'Platform Fighter',
        console_platform: 'Switch',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Goldeneye 007"],
        affiliate_links: ["https://www.amazon.com/GoldenEye-007-Renewed-nintendo-64/dp/B07X3KML98?crid=3FTTKWPUJ92G3&keywords=Goldeneye+007&qid=1685142179&sprefix=goldeneye+007%2Caps%2C368&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=13bb3b366e5ac6096d11fd1c0a6c305c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'James Bond',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '96%',
        genre: 'Shooter',
        console_platform: 'N64',
        developer: 'Rare',
        backloggd_link: '',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["Hotline Miami"],
        affiliate_links: ["https://www.g2a.com/hotline-miami-collection-xbox-one-xbox-live-key-united-states-i10000337773002?gtag=391e4ef696", "https://www.amazon.com/Hotline-Miami-Collection-Exclusive-Variant-Nintendo/dp/B08KHVTP5C?crid=2JNUHJO713SXT&keywords=Hotline+Miami&qid=1685141982&sprefix=hotline+miami%2Caps%2C424&sr=8-26&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=9fcfeec3d37665a9d27878b3835c5004&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hotline Miami',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '85%',
        genre: 'Action',
        console_platform: 'PC',
        developer: 'Dennaton Games',
        backloggd_link: '',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Rock Band"],
        affiliate_links: ["https://www.g2a.com/rock-band-vr-oculus-key-global-i10000149674001?gtag=391e4ef696", "https://www.amazon.com/Rock-Band-XBox-360-Renewed/dp/B07Y47VW96?crid=2G7GR2XUG63T&keywords=Rock+Band&qid=1685141826&sprefix=rock+band%2Caps%2C396&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=49ace788fa12238eba67f095eb0903d6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Rock Band',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '92%',
        genre: 'Music',
        console_platform: 'X360, PS3',
        developer: 'Harmonix',
        backloggd_link: '',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["Tony Hawk's Pro Skater"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tony%20Hawks%20Pro%20Skater%201%202%20Cross%20Gen%20Deluxe%20Bundle%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftony-hawks-pro-skater-1-2-cross-gen-deluxe-bundle-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/tony-hawkstm-pro-skatertm-1-2-digital-deluxe-edition-xbox-one-xbox-live-key-global-i10000195540002?gtag=391e4ef696", "https://www.amazon.com/Hawks-Skater-Cross-Gen-Deluxe-Bundle/dp/B093H17NV2?crid=3MW4AB3Y18TND&keywords=Tony+Hawk%27s+Pro+Skater&qid=1685141566&sprefix=tony+hawk%27s+pro+skater%2Caps%2C372&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=15df2bff45b7c53bb1eb8c86a3818e04&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Tony Hawk',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '92%',
        genre: 'Sports, Skateboarding',
        console_platform: 'PS1',
        developer: 'Neversoft',
        backloggd_link: '',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario Galaxy"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '97%',
        genre: 'Platformer',
        console_platform: 'Wii',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["Return of the Obra Dinn"],
        affiliate_links: ["https://www.g2a.com/return-of-the-obra-dinn-pc-steam-key-global-i10000174480003 ?gtag=391e4ef696", "https://www.amazon.com/Return-Obra-Dinn-Collectors-Nintendo-Switch/dp/B097J3W7WD?crid=21ZTGUHYK0Q8W&keywords=Return+of+the+Obra+Dinn&qid=1685141212&sprefix=return+of+the+obra+dinn%2Caps%2C409&sr=8-3&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=47278e8b218d5aab847bf4c17d54232f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '89%',
        genre: 'Puzzle',
        console_platform: 'PC',
        developer: 'Lucas Pope',
        backloggd_link: '',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["Fortnite"],
        affiliate_links: [],
        franchise: 'Fortnite',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '81%',
        genre: 'Shooter',
        console_platform: 'PC, PS4, XONE',
        developer: 'Epic Games',
        backloggd_link: '',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["God of War"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=God%20of%20War%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgod-of-war-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/god-of-war-pc-steam-gift-global-i10000152407007?gtag=391e4ef696", "https://www.amazon.com/God-War-Ragnar%C3%B6k-Launch-PlayStation-5/dp/B0B6228XBM?crid=2BZCA58JQJQBR&keywords=God+of+War&qid=1685140448&sprefix=god+of+war%2Caps%2C390&sr=8-7&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=d867b351787b0e2f105feb099aa390b7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'God of War',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '94%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Santa Monica Studio',
        backloggd_link: '',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["The Elder Scrolls V: Skyrim"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Elder%20Scrolls%20V%20Skyrim%20Anniversary%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-elder-scrolls-v-skyrim-anniversary-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-elder-scrolls-v-skyrim-anniversary-edition-ps5-psn-account-global-i10000277168013?gtag=391e4ef696", "https://www.amazon.com/Elder-Scrolls-Skyrim-Special-playstation-4/dp/B01GV7Y1SM?keywords=The+Elder+Scrolls+V%3A+Skyrim&qid=1685139952&sr=8-19&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=929637c521ba1c883eee04ab11ab6bdc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Elder Scrolls',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '94%',
        genre: 'RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["PUBG: Battlegrounds"],
        affiliate_links: ["https://www.amazon.com/PLAYERUNKNOWNS-BATTLEGROUNDS-PS4-playstation-4/dp/B07KFX7157?crid=12Q2P9DRYF191&keywords=PUBG%3A+Battlegrounds&qid=1685061912&sprefix=pubg+battlegrounds%2Caps%2C427&sr=8-15&linkCode=ll1&tag=guessthegam06-20&linkId=7d590260d1f047d88dfda49e7f660eb8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'PUBG',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '86%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Bluehole/PUBG Studios',
        backloggd_link: '',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["Wii Sports"],
        affiliate_links: ["https://www.amazon.com/Nintendo-Wii-Sports-Resort-Special-Renewed/dp/B082Z94G1M?crid=2VSP89M6Z6M5Z&keywords=Wii+Sports&qid=1685061757&sprefix=wii+sports%2Caps%2C375&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=2f02a7f654f1ed7e0a77d0c6c3aec5d0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Wii',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '76%',
        genre: 'Sports',
        console_platform: 'Wii',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Shovel Knight"],
        affiliate_links: ["https://www.amazon.com/Shovel-Knight-Wii-U-Renewed-nintendo/dp/B07Z893ZR4?crid=6U3AONBH65PA&keywords=Shovel+Knight&qid=1685061483&sprefix=shovel+knight%2Caps%2C364&sr=8-29&linkCode=ll1&tag=guessthegam06-20&linkId=966e621c7ddb8978f8d29bff193fcd41&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Shovel Knight',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '85%',
        genre: 'Platformer',
        console_platform: 'PC, 3DS, Wii U',
        developer: 'Yacht Club Games',
        backloggd_link: '',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["XCOM: Enemy Unknown"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Xcom%20Enemy%20Unknown%20Complete%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fxcom-enemy-unknown-the-complete-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/xcom-enemy-unknown-complete-pack-steam-gift-global-i10000001633001?gtag=391e4ef696", "https://www.amazon.com/XCOM-Enemy-Complete-Other-System/dp/B00HVH4NCQ?crid=2XHTBM86G5HBR&keywords=XCOM%3A+Enemy+Unknown&qid=1685060711&sprefix=xcom+enemy+unknown%2Caps%2C486&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=d2a5e4b56c50387d4d9254f8594a7428&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'X-COM',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '89%',
        genre: 'Strategy',
        console_platform: 'PC, PS3, X360',
        developer: 'Firaxis Games',
        backloggd_link: '',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Celeste"],
        affiliate_links: ["https://www.g2a.com/celeste-steam-gift-global-i10000135047002?gtag=391e4ef696", "https://www.amazon.com/Celeste-Collectors-NSW-Nintendo-Switch/dp/B0859NBZBP?crid=3EOZJR1R67EYD&keywords=Celeste&qid=1685060591&sprefix=celeste%2Caps%2C415&sr=8-14&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=b02f7329cb45a3146e2b8d925708986c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Celeste',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '88%',
        genre: 'Platformer',
        console_platform: 'PC, Switch, PS4, XONE',
        developer: 'Extremely OK Games',
        backloggd_link: '',
    },
    '77': {
        content: '',
        submitted_by: '',
        answers: ["Divinity: Original Sin II", "Divinity: Original Sin 2"],
        affiliate_links: ["https://www.g2a.com/divinity-original-sin-2-eternal-edition-steam-gift-global-i10000078703003?gtag=391e4ef696", "https://www.amazon.com/Divinity-Original-Sin-II-Definitive-Nintendo/dp/B0863GZKMF?crid=WEQEA9CDLBK1&keywords=Divinity%3A+Original+Sin+II&qid=1685060440&sprefix=divinity+original+sin+ii%2Caps%2C388&sr=8-1&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=bd11966ad45ffff7abd8686a10b64c3e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Divinity',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '93%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'Larian Studios',
        backloggd_link: '',
    },
    '78': {
        content: '',
        submitted_by: '',
        answers: ["Gone Home"],
        affiliate_links: ["https://www.g2a.com/gone-home-steam-gift-global-i10000002603001?gtag=391e4ef696", "https://www.amazon.com/Gone-Home-Nintendo-Switch-Physical/dp/B07TWYW22P?crid=1WTWNTM7YYJCT&keywords=gone+home+video+game&qid=1685060354&sprefix=Gone+Home%2Caps%2C1044&sr=8-1&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=8bff51d7a03830a4f4785c3c1bdc0c70&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '86%',
        genre: 'Narrative Exploration',
        console_platform: 'PC',
        developer: 'The Fullbright Company',
        backloggd_link: '',
    },
    '79': {
        content: '',
        submitted_by: '',
        answers: ["Fallout: New Vegas"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fallout%20New%20Vegas%20Ultimate%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffallout-new-vegas-ultimate-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/fallout-new-vegas-ultimate-edition-pc-steam-key-global-i10000002212009?aid=12996556?gtag=391e4ef696", "https://www.amazon.com/Fallout-New-Vegas-Ultimate-XBox-360/dp/B088FHQ7MG?crid=2O9EVESGZ4VWR&keywords=Fallout%3A+New+Vegas&qid=1685059733&sprefix=fallout+new+vegas%2Caps%2C378&sr=8-18&linkCode=ll1&tag=guessthegam06-20&linkId=5f7ebfad591b06a87b297228bdecf584&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Fallout',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '84%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'Obsidian Entertainment',
        backloggd_link: '',
    },
    '80': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: The Wind Waker"],
        affiliate_links: ["https://www.amazon.com/Legend-Zelda-Waker-nintendo-wii-u/dp/B002I0GF72?crid=36Y8Y6YAAMU8X&keywords=The+Legend+of+Zelda%3A+The+Wind+Waker&qid=1685059613&sprefix=the+legend+of+zelda+the+wind+waker%2Caps%2C714&sr=8-4&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=d6c73adda90e53076893e976444cf0b5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '96%',
        genre: 'Action, Adventure',
        console_platform: 'GameCube',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '81': {
        content: '',
        submitted_by: '',
        answers: ["Kerbal Space Program"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Kerbal%20Space%20Program%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkerbal-space-program%2F&intsrc=PUI2_9608", "https://www.g2a.com/kerbal-space-program-complete-edition-steam-key-global-i10000014989014?aid=13331776?gtag=391e4ef696", "https://www.amazon.com/Kerbal-Space-Program-Complete-Enhanced/dp/B08FS1ZS48?crid=8VSOVDM2V8BT&keywords=Kerbal+Space+Program&qid=1685058559&sprefix=kerbal+space+program%2Caps%2C368&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=85ce70743f3d307af36ab765962b11b5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Kerbal Space Program',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '88%',
        genre: 'Simulation',
        console_platform: 'PC',
        developer: 'Squad',
        backloggd_link: '',
    },
    '82': {
        content: '',
        submitted_by: '',
        answers: ["Braid"],
        affiliate_links: ["https://www.g2a.com/braid-steam-gift-global-i10000001243001?gtag=391e4ef696", "https://www.amazon.com/Braid-PC/dp/B0030BFW18?crid=5LY9P3XI5YX4&keywords=braid+video+game&qid=1685058363&sprefix=braid+game%2Caps%2C352&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=f777d269abc6b438789674ae1297c40b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '93%',
        genre: 'Platformer, Puzzle',
        console_platform: 'X360',
        developer: 'Number None',
        backloggd_link: '',
    },
    '83': {
        content: '',
        submitted_by: '',
        answers: ["Dead Rising"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dead%20Rising%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdead-rising-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dead-rising-triple-pack-steam-key-global-i10000146260001?gtag=391e4ef696", "https://www.amazon.com/Dead-Rising-PlayStation-4-Standard/dp/B01JCV26KO?crid=1J2SHKS6TK51E&keywords=Dead+Rising&qid=1685057672&sprefix=dead+rising%2Caps%2C355&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=2e78ad87f333c887d017c449149ba9b6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dead Rising',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '85%',
        genre: 'Action, Adventure',
        console_platform: 'X360',
        developer: 'Capcom Production Studio 1',
        backloggd_link: '',
    },
    '84': {
        content: '',
        submitted_by: '',
        answers: ["Stardew Valley"],
        affiliate_links: ["https://www.g2a.com/stardew-valley-steam-gift-global-i10000011727004?aid=13400263?gtag=391e4ef696", "https://www.amazon.com/Stardew-Valley-Collectors-Xbox-One-UK/dp/B06XWGF99X?crid=2ZQ1KAZZHRBHI&keywords=Stardew+Valley&qid=1685057532&sprefix=stardew+valley%2Caps%2C743&sr=8-15&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=70e38f15faf0192b84db56a66706565e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '89%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'ConcernedApe',
        backloggd_link: '',
    },
    '85': {
        content: '',
        submitted_by: '',
        answers: ["Control"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Control%20Ultimate%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcontrol-ultimate-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/control-ultimate-edition-pc-steam-key-global-i10000189076006?aid=13283007?gtag=391e4ef696", "https://www.amazon.com/Control-Ultimate-PlayStation-4/dp/B08L7NFCW6?crid=SUVLZM125EFI&keywords=Control+game&qid=1685057388&sprefix=control+gam%2Caps%2C400&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=2acb812bd0ade8b19eb7be7dcce3e77a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '85%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Remedy Entertainment',
        backloggd_link: '',
    },
    '86': {
        content: '',
        submitted_by: '',
        answers: ["Minecraft"],
        affiliate_links: ["https://www.g2a.com/minecraft-windows-10-edition-pc-microsoft-key-global-i10000032198001?aid=13409899?gtag=391e4ef696", "https://www.amazon.com/Minecraft-Bedrock-PS4-playstation-4/dp/B082J3D5HN?crid=18KGGGNYTND0S&keywords=minecraft+game&qid=1685057242&sprefix=minecraft+gam%2Caps%2C334&sr=8-20&linkCode=ll1&tag=guessthegam06-20&linkId=8194218c315ab7d209aeca0dac8a2a1c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Minecraft',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '93%',
        genre: 'Action, Adventure',
        console_platform: 'PC',
        developer: 'Mojang Studios',
        backloggd_link: '',
    },
    '87': {
        content: '',
        submitted_by: '',
        answers: ["Counter-Strike: Global Offensive"],
        affiliate_links: ["https://www.g2a.com/counter-strike-global-offensive-prime-status-upgrade-pc-steam-gift-global-i10000016291010?gtag=391e4ef696", "https://www.amazon.com/Counter-strike-Global-Offensive-Pc-Game-MAC/dp/B009FZZUPQ?crid=3AED1UXB2NN1H&keywords=Counter-Strike%3A+Global+Offensive&qid=1684972691&sprefix=glover+game%2Caps%2C1069&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=be6fb7d2c732d588fb320abc6b557796&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Counter-Strike',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '83%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Valve, Hidden Path Entertainment',
        backloggd_link: '',
    },
    '88': {
        content: '',
        submitted_by: '',
        answers: ["Glover"],
        affiliate_links: ["https://www.amazon.com/Glover-Playstation/dp/B00001QHWH?crid=3BYAB6AB1MO11&keywords=Glover+game&qid=1684972505&sprefix=glover+%2Caps%2C511&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=9cceae6c5c533b1e0a932759059591f3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Glover',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Platformer',
        console_platform: 'N64',
        developer: 'Interactive Studios',
        backloggd_link: '',
    },
    '89': {
        content: '',
        submitted_by: '',
        answers: ["Jak and Daxter: The Precursor Legacy"],
        affiliate_links: ["https://www.g2a.com/jak-and-daxter-the-precursor-legacy-psn-ps4-key-europe-i10000075636002?gtag=391e4ef696", "https://www.g2a.com/jak-and-daxter-the-precursor-legacy-psn-ps4-key-europe-i10000075636002"],
        franchise: 'Jak and Daxter',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '90%',
        genre: 'Platformer',
        console_platform: 'PS2',
        developer: 'Naughty Dog',
        backloggd_link: '',
    },
    '90': {
        content: '',
        submitted_by: '',
        answers: ["Papers, Please"],
        affiliate_links: ["https://www.g2a.com/papers-please-steam-gift-global-i10000000400002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '85%',
        genre: '??? Dystopian Simulation ???',
        console_platform: 'PC',
        developer: '3909 LLC',
        backloggd_link: '',
    },
    '91': {
        content: '',
        submitted_by: '',
        answers: ["Pokemon Go"],
        affiliate_links: [],
        franchise: 'Pokémon',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '69%',
        genre: 'AR, Outside(?)',
        console_platform: 'Mobile',
        developer: 'Niantic',
        backloggd_link: '',
    },
    '92': {
        content: '',
        submitted_by: '',
        answers: ["Metroid Prime"],
        affiliate_links: ["https://www.g2a.com/metroid-prime-remastered-nintendo-switch-nintendo-eshop-key-united-states-i10000338593001?gtag=391e4ef696", "https://www.amazon.com/Metroid-Prime-Trilogy-Collectors-nintendo-wii/dp/B07ZYP2BRF?crid=3KG9602950KWE&keywords=Metroid+Prime&qid=1684971606&sprefix=metroid+prime%2Caps%2C714&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=b7d08b75316de1343fa78e483c7f7c39&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metroid',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '97%',
        genre: 'Action/Shooter',
        console_platform: 'GameCube',
        developer: 'Retro Studios',
        backloggd_link: '',
    },
    '93': {
        content: '',
        submitted_by: '',
        answers: ["FTL: Faster Than Light"],
        affiliate_links: ["https://www.g2a.com/ftl-faster-than-light-steam-gift-global-i10000012903002?gtag=391e4ef696", "https://www.amazon.com/FTL-Faster-Than-Light-Download/dp/B00D7GNPO2?crid=97P7BMOAYZXK&keywords=FTL%3A+Faster+Than+Light+game&qid=1684971195&sprefix=ftl+faster+than+light+%2Caps%2C700&sr=8-18&linkCode=ll1&tag=guessthegam06-20&linkId=d8252e9eaed8c9923110592bf34bcadc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '84%',
        genre: 'Strategy',
        console_platform: 'PC',
        developer: 'Subset Games',
        backloggd_link: '',
    },
    '94': {
        content: '',
        submitted_by: '',
        answers: ["Diablo II", "Diablo 2"],
        affiliate_links: ["https://www.g2a.com/diablo-2-pc-battlenet-key-global-i10000043344001?aid=13434432?gtag=391e4ef696", "https://www.amazon.com/Diablo-II-Pc/dp/B00005BV7N?crid=1TJKOP8OP232H&keywords=Diablo+II&qid=1684970551&sprefix=paper+mario+the+thousand-year+door%2Caps%2C638&sr=8-1&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=a345a85e5f20db3432992006fab94bdd&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Diablo',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '88%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'Blizzard North',
        backloggd_link: '',
    },
    '95': {
        content: '',
        submitted_by: '',
        answers: ["Paper Mario: The Thousand-Year Door"],
        affiliate_links: ["https://www.amazon.com/Paper-Mario-Thousand-Year-Door-Gamecube/dp/B0002ILS1K?crid=XJ3ACSMP3C6M&keywords=Paper+Mario%3A+The+Thousand-Year+Door&qid=1684970253&sprefix=paper+mario+the+thousand-year+door%2Caps%2C656&sr=8-2&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=f8a8452784bdac3013af25a882a4838c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '87%',
        genre: 'RPG',
        console_platform: 'GameCube',
        developer: 'Intelligent Systems',
        backloggd_link: '',
    },
    '96': {
        content: '',
        submitted_by: '',
        answers: ["Transistor"],
        affiliate_links: ["https://www.g2a.com/transistor-steam-key-global-i10000018791005?gtag=391e4ef696", "https://www.amazon.com/Transistor-Limited-Run-265-ps4-games/dp/B07ZJRF2HJ?crid=2NAR9B3E1K3J8&keywords=Transistor+game&qid=1684970062&sprefix=transistor+%2Caps%2C574&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=46821b9ff8eb93870da89296833f7239&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '83%',
        genre: 'RPG',
        console_platform: 'PC, PS4',
        developer: 'Supergiant Games',
        backloggd_link: '',
    },
    '97': {
        content: '',
        submitted_by: '',
        answers: ["Marvel's Spider-Man: Miles Morales"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Marvels%20Spider%20Man%20Miles%20Morales%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmarvels-spider-man-miles-morales-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/spider-man-miles-morales-ps5-psn-account-global-i10000217221005?gtag=391e4ef696", "https://www.amazon.com/Spider-Man-Miles-Morales-Ultimate-playstation-5/dp/B08R8CJZC6?crid=15XNMID9USGSQ&keywords=Marvel%27s+Spider-Man%3A+Miles+Morales&qid=1684969848&sprefix=marvel%27s+spider-man+miles+morales%2Caps%2C758&sr=8-13&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=894cbac9bfe21947593f172923f737b9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spider-Man',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '85%',
        genre: 'Action, Adventure',
        console_platform: 'PS5, PS4',
        developer: 'Insomniac Games',
        backloggd_link: '',
    },
    '98': {
        content: '',
        submitted_by: '',
        answers: ["Final Fantasy VIII", "Final Fantasy 8"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Final%20Fantasy%20VIII%20Remaster%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-viii-remastered-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-viii-remastered-steam-key-global-i10000190435001?aid=13421399?gtag=391e4ef696", "https://www.amazon.com/Final-Fantasy-VIII-Remastered-PlayStation-4/dp/B08L3Q8MMD?crid=3VK7WZRJ889C8&keywords=Final+Fantasy+VIII&qid=1684969295&sprefix=final+fantasy+viii%2Caps%2C711&sr=8-14&linkCode=ll1&tag=guessthegam06-20&linkId=25d9040705e84f371e6c07fd77aff7a5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Final Fantasy',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '90%',
        genre: 'RPG',
        console_platform: 'PS1',
        developer: 'Square',
        backloggd_link: '',
    },
    '99': {
        content: '',
        submitted_by: '',
        answers: ["Street Fighter II: The World Warrior", "Street Fighter 2"],
        affiliate_links: ["https://www.amazon.com/Fighter-Warrior-Super-Nintendo-Entertainment-System/dp/B0C1L382NF?crid=2OGJQLWUAQ9I1&keywords=Street+Fighter+II%3A+The+World+Warrior&qid=1684967977&sprefix=%2Caps%2C859&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=a0a650111ee59fcc7607873917cb5f05&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Street Fighter',
        twitter_ids: [],
        release_year: '1991',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Fighting',
        console_platform: 'Arcade',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '100': {
        content: '',
        submitted_by: '',
        answers: ["Hunt: Showdown"],
        affiliate_links: ["https://www.g2a.com/hunt-showdown-gold-edition-xbox-one-xbox-live-key-united-states-i10000144233031?aid=13303707?gtag=391e4ef696", "https://www.amazon.com/HUNT-Showdown-Limited-Bounty-Hunter-PlayStation/dp/B0BQ1GCZMZ?crid=1UO7CCGRXYIIV&keywords=Hunt%3A+Showdown+game&qid=1684887332&sprefix=hunt+showdown+game%2Caps%2C557&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c3b50cfbc2ebe94015b2eeead6d98b14&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '81%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Crytek',
        backloggd_link: '',
    },
    '101': {
        content: '',
        submitted_by: '',
        answers: ["Dead by Daylight"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dead%20by%20Daylight%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdead-by-daylight-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dead-by-daylight-ultimate-edition-pc-steam-account-global-i10000018558040?gtag=391e4ef696", "https://www.g2a.com/dead-by-daylight-ultimate-edition-pc-steam-account-global-i10000018558040"],
        franchise: 'Dead by Daylight',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '71%',
        genre: 'Surival Horror/Asymmetric Multiplayer',
        console_platform: 'PC',
        developer: 'Behaviour Interactive',
        backloggd_link: '',
    },
    '102': {
        content: '',
        submitted_by: '',
        answers: ["Uncharted: Drake's Fortune", "Uncharted", "Uncharted 1"],
        affiliate_links: ["https://www.amazon.com/Uncharted-Drake-s-Fortune-Playstation-3/dp/B00LD4AY4O?crid=YCE602MDZIQ2&keywords=Uncharted%3A+Drake%27s+Fortune&qid=1684886901&sprefix=uncharted+drake%27s+fortune%2Caps%2C660&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=cbb7b51449bd071be6d422f764e18bd4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Uncharted',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '88%',
        genre: 'Action, Adventure',
        console_platform: 'PS3',
        developer: 'Naughty Dog',
        backloggd_link: '',
    },
    '103': {
        content: '',
        submitted_by: '',
        answers: ["Far Cry 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Far%20Cry%203%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffar-cry-3%2F&intsrc=PUI2_9608", "https://www.g2a.com/far-cry-3-deluxe-edition-steam-gift-global-i10000006377002?gtag=391e4ef696", "https://www.amazon.com/Far-Cry-Compliation-Xbox-360/dp/B00KG823T2?keywords=Far+Cry+3&qid=1684886749&sr=8-17&linkCode=ll1&tag=guessthegam06-20&linkId=d65488e57dee2aa5e6e734c0c61c0b41&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Far Cry',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '88%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '104': {
        content: '',
        submitted_by: '',
        answers: ["Dishonored"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dishonored%20Complete%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdishonored-complete-collection-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dishonored-complete-collection-steam-key-global-i10000083178002?gtag=391e4ef696", "https://www.amazon.com/Dishonored-Definitive-Xbox-One/dp/B00ZOET7BU?crid=2V7L7611AQWJA&keywords=Dishonored&qid=1684454432&sprefix=dishonored%2Caps%2C537&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=fb26afcec49c5d6035dfd30dc5d1166e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dishonored',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '91%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'Arkane Studios',
        backloggd_link: '',
    },
    '105': {
        content: '',
        submitted_by: '',
        answers: ["Metal Gear Rising: Revengeance"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=METAL%20GEAR%20RISING%20REVENGEANCE%20UK%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmetal-gear-rising-revengeance-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/metal-gear-rising-revengeance-steam-key-global-i10000017865008?aid=12672304?gtag=391e4ef696", "https://www.amazon.com/Revengeance-Premium-Package-Limited-Playstation-3/dp/B009CKQI1E?crid=3LVL0WEXT3Q0Y&keywords=Metal+Gear+Rising%3A+Revengeance&qid=1684453852&sprefix=metal+gear+rising+revengeance%2Caps%2C424&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=a1bbf03f128bd5c5978843beabe87f62&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metal Gear',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '83%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'PlatinumGames & Kojima Productions',
        backloggd_link: '',
    },
    '106': {
        content: '',
        submitted_by: '',
        answers: ["Risk of Rain 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Risk%20of%20Rain%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frisk-of-rain-2-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/risk-of-rain-2-steam-gift-global-i10000186331002?gtag=391e4ef696", "https://www.amazon.com/Revengeance-Premium-Package-Limited-Playstation-3/dp/B009CKQI1E?crid=3LVL0WEXT3Q0Y&keywords=Metal+Gear+Rising%3A+Revengeance&qid=1684453852&sprefix=metal+gear+rising+revengeance%2Caps%2C424&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=6c91c24acc9481553a53662fb57434f2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Risk of Rain',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '85%',
        genre: 'Action',
        console_platform: 'PC, PS, Switch, PS4, XONE',
        developer: 'Hopoo games',
        backloggd_link: '',
    },
    '107': {
        content: '',
        submitted_by: '',
        answers: ["Rollercoaster Tycoon"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=RollerCoaster%20Tycoon%20Deluxe%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frollercoaster-tycoon-deluxe%2F&intsrc=PUI2_9608", "https://www.g2a.com/rollercoaster-tycoon-deluxe-gogcom-key-global-i10000000653005?gtag=391e4ef696", "https://www.amazon.com/Scholastic-Roller-Coaster-Tycoon/dp/0439468973?crid=78B0C5030B6E&keywords=Rollercoaster+Tycoon&qid=1684453171&sprefix=death+stranding%2Caps%2C457&sr=8-9&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=5bff8fd4d4b0b4943a70f0ee5deec139&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'RollerCoaster Tycoon',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: 'Too old for metacritic! Here\'s a different hint... you probably got this game in a cereal box :)',
        genre: 'Simulation/Management',
        console_platform: 'PC',
        developer: 'Chris Sawyer',
        backloggd_link: '',
    },
    '108': {
        content: 'Death Stranding, released on November 8th, 2019, is a fascinating and unique game that challenged the conventions of traditional gaming. Created by the legendary game designer, Hideo Kojima, and developed by Kojima Productions, this open-world action-adventure game quickly became the talk of the gaming community. With its stunning graphics, deep narrative, and innovative gameplay mechanics, Death Stranding managed to capture the hearts and minds of both critics and gamers alike. The game takes place in a post-apocalyptic America, where society has been fractured by mysterious events called the Death Stranding. These events have caused a series of supernatural phenomena, including the appearance of BTs (Beached Things), invisible creatures from another dimension, and Timefall, a rain that ages everything it touches. The player takes on the role of Sam Porter Bridges (played by Norman Reedus), a \'porter\' tasked with delivering supplies and connecting the remaining cities and settlements through a communications network called the \'Chiral Network.\'l One of the most compelling aspects of Death Stranding is its asynchronous multiplayer component. As players progress through the game, they can leave behind structures, equipment, and signs that can be used by other players in their own journeys. This fosters a sense of community and collaboration, as players work together to overcome the challenges of the game world. Death Stranding is a game that defies categorization, blending together elements of action, adventure, and exploration with a thought-provoking narrative and memorable characters.',
        submitted_by: 'https://twitter.com/OppositeInvict2',
        answers: ["Death Stranding"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Death%20Stranding%20Directors%20Cut%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdeath-stranding-directors-cut-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/death-stranding-directors-cut-pc-steam-gift-global-i10000192339023?gtag=391e4ef696", "https://www.amazon.com/Death-Stranding-Directors-Cut-Playstation-5/dp/B0992ZKC5Q?keywords=Death+Stranding&qid=1684453027&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=cd855c31783ab568b4c82edb9f166625&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '82%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Kojima Productions',
        backloggd_link: '',
    },
    '109': {
        content: '',
        submitted_by: '',
        answers: ["Kingdom Hearts II", "Kingdom Hearts 2"],
        affiliate_links: ["https://www.amazon.com/Kingdom-Hearts-II-PlayStation-Certified-Refurbished/dp/B07KQDZCDB?crid=152XNW173729Y&keywords=Kingdom+Hearts+II&qid=1684452349&sprefix=kingdom+hearts+ii%2Caps%2C377&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c080a86cfff9b877972167139edcc0ef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Kingdom Hearts',
        twitter_ids: [],
        release_year: '2005 (JP), 2006 (Globally)',
        metacritic_score: '87%',
        genre: 'Action, RPG',
        console_platform: 'PS2',
        developer: 'Square Enix & Buena Vista Games',
        backloggd_link: '',
    },
    '110': {
        content: '',
        submitted_by: '',
        answers: ["Devil May Cry 5"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Devil%20May%20Cry%205%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdevil-may-cry-5-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/devil-may-cry-5-deluxe-edition-steam-key-global-i10000171621022?gtag=391e4ef696", "https://www.amazon.com/Devil-May-Cry-Deluxe-Xbox-One/dp/B07HJSC5V6?crid=2UL5DQR508V5K&keywords=Devil+May+Cry+5&qid=1684452152&sprefix=devil+may+cry+5%2Caps%2C406&sr=8-14&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=041d97b0e935d3ff81d44a84ed9cca41&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Devil May Cry',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '88%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '111': {
        content: 'Spyro the Dragon, a beloved platformer video game series, first soared onto the gaming scene in 1998. Developed by Insomniac Games and published by Sony Computer Entertainment, the franchise quickly gained popularity for its charming characters, vibrant graphics, and immersive gameplay. Spyro\'s adventures have captivated players of all ages, leaving a lasting impact on the gaming industry.Spyro\'s gameplay primarily revolves around exploration, collecting gems, and completing challenges across a multitude of vibrant levels. The franchise is known for its intuitive controls, which allow players to glide, charge, and breathe fire as they traverse the enchanting game worlds. In Spyro the Dragon, players take control of the eponymous purple dragon, Spyro, on a quest to rescue his fellow dragons who have been turned into crystalline statues by the evil Gnasty Gnorc. Spyro, accompanied by his dragonfly sidekick Sparx, must traverse through six distinct worlds, each containing several levels, to free his fellow dragons and ultimately confront Gnasty Gnorc.',
        submitted_by: '',
        answers: ["Spyro the Dragon", "Spyro"],
        affiliate_links: ["https://www.g2a.com/spyro-reignited-trilogy-steam-key-global-i10000175966010?gtag=391e4ef696", "https://www.amazon.com/Spyro-Reignited-Trilogy/dp/B07T4N5B8T?crid=6YN8I90C4CIX&keywords=Spyro&qid=1684452070&sprefix=spyro%2Caps%2C391&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=6da40273e71a41ee4915c8059aad5bf4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spyro the Dragon',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Action, Platformer',
        console_platform: 'PS1',
        developer: 'Insomniac Games',
        backloggd_link: '',
    },
    '112': {
        content: '',
        submitted_by: '',
        answers: ["Okami"],
        affiliate_links: ["https://www.g2a.com/okami-hd-steam-key-global-i10000026248002?aid=13448176?gtag=391e4ef696", "https://www.amazon.com/Okami-HD-PS4-playstation-4/dp/B078P1N1GG?crid=3RZLF29XPFVM5&keywords=Okami&qid=1684451902&sprefix=okami%2Caps%2C393&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=64b7384d94cdf6013b8b006708ee202a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '93%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Clover Studio',
        backloggd_link: '',
    },
    '113': {
        content: '',
        submitted_by: '',
        answers: ["Bomberman Hero"],
        affiliate_links: ["https://www.amazon.com/Bomberman-Hero-Renewed-nintendo-64/dp/B084GWT7DR?crid=3IJZ4XN1O7XPT&keywords=Bomberman+Hero&qid=1684451630&sprefix=bomberman+hero%2Caps%2C400&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=4a770a09746a4cb81f6dc7633f29e1ca&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bomberman',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Action, Platformer',
        console_platform: 'N64',
        developer: 'Hudson Soft',
        backloggd_link: '',
    },
    '114': {
        content: '',
        submitted_by: '',
        answers: ["LittleBigPlanet"],
        affiliate_links: ["https://www.amazon.com/LittleBigPlanet-Game-Year-Playstation-3-Renewed/dp/B07TY64VHT?keywords=LittleBigPlanet&qid=1684451467&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=120f2443606d5d430df8a95d746c66e0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'LittleBigPlanet',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '95%',
        genre: 'Action, Platformer',
        console_platform: 'PS3',
        developer: 'Media Molecule',
        backloggd_link: '',
    },
    '115': {
        content: '',
        submitted_by: '',
        answers: ["Final Fantasy XIV Online: A Realm Reborn", "Final Fantasy XIV"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=FINAL%20FANTASY%20XIV%20Online%20Comp%20Coll%20Ed%202021%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-xiv-online-complete-collectors-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-xiv-online-complete-edition-final-fantasy-key-north-america-i10000068335002?gtag=391e4ef696", "https://www.amazon.com/FINAL-FANTASY-Online-Complete-Collectors/dp/B09NLLPZSJ?crid=FYKG0RNE85WO&keywords=final+fantasy+xiv+online&qid=1684450649&sprefix=Final+Fantasy+XIV+Online%2Caps%2C355&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=9e82f35646b0771f3f0ef01dceaaed24&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Final Fantasy',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '83%',
        genre: 'MMORPG',
        console_platform: 'PC, PS3',
        developer: 'Square Enix',
        backloggd_link: '',
    },
    '116': {
        content: '',
        submitted_by: '',
        answers: ["Quake"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=QUAKE%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fquake%2F&intsrc=PUI2_9608", "https://www.g2a.com/quake-collection-steam-key-global-i10000034151003?gtag=391e4ef696", "https://www.amazon.com/Quake-Deluxe-Limited-Run-119-Nintendo/dp/B0BS8HCB73?crid=DOOTGFQ67UA8&keywords=Quake&qid=1684450079&sprefix=quake%2Caps%2C483&sr=8-15&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=34d9c386b7a1d897349bad348b8a1aec&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Quake',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: '94%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'id Software',
        backloggd_link: '',
    },
    '117': {
        content: '',
        submitted_by: '',
        answers: ["The Talos Principle"],
        affiliate_links: ["https://www.g2a.com/the-talos-principle-gold-edition-pc-gogcom-key-global-i10000006332022?gtag=391e4ef696", "https://www.amazon.com/Talos-Principle-Deluxe-PlayStation-4/dp/B00X67FWNY?crid=NWATLM5QAVBK&keywords=The+Talos+Principle&qid=1684449924&sprefix=the+talos+principle%2Caps%2C379&sr=8-1&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=be967aaa44521e68414b2d8fa2d15497&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Talos Principle',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '85%',
        genre: 'Puzzle',
        console_platform: 'PC',
        developer: 'Croteam',
        backloggd_link: '',
    },
    '118': {
        content: '',
        submitted_by: '',
        answers: ["The Stanley Parable"],
        affiliate_links: ["https://www.g2a.com/the-stanley-parable-ultra-deluxe-pc-steam-gift-global-i10000325640004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '88%',
        genre: 'Walking Simulator? Interactive Storytelling?',
        console_platform: 'PC',
        developer: 'Galactic Cafe',
        backloggd_link: '',
    },
    '119': {
        content: '',
        submitted_by: '',
        answers: ["Fez"],
        affiliate_links: ["https://www.amazon.com/FEZ-Standard-Switch-Digital-Code/dp/B09FM44VYZ?crid=32J9VB0ZLCVJR&keywords=fez+game&qid=1684449720&sprefix=Fez+game%2Caps%2C426&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=72ce07b18f51046027e5f2c997a44162&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '89%',
        genre: 'Puzzle',
        console_platform: 'X360',
        developer: 'Polytron Corporation',
        backloggd_link: '',
    },
    '120': {
        content: '',
        submitted_by: '',
        answers: ["Amnesia: The Dark Descent"],
        affiliate_links: ["https://www.g2a.com/amnesia-the-dark-descent-steam-gift-global-i10000001466001?gtag=391e4ef696"],
        franchise: 'Amnesia',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '85%',
        genre: 'Horror',
        console_platform: 'PC',
        developer: 'Frictional Games',
        backloggd_link: '',
    },
    '121': {
        content: '',
        submitted_by: '',
        answers: ["Professor Layton and the Curious Village"],
        affiliate_links: ["https://www.amazon.com/Professor-Layton-Curious-Village-Nintendo-DS/dp/B000N3PKEK?crid=3TDOKALRQ1J8C&keywords=Professor+Layton+and+the+Curious+Village&qid=1684448932&sprefix=professor+layton+and+the+curious+village%2Caps%2C537&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=61d1e05db1829b52633a18b758b28b51&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Professor Layton',
        twitter_ids: [],
        release_year: '2007 (JP), 2008 (Worldwide)',
        metacritic_score: '85%',
        genre: 'Puzzle',
        console_platform: 'DS',
        developer: 'Level-5',
        backloggd_link: '',
    },
    '122': {
        content: '',
        submitted_by: '',
        answers: ["Half-Life 2"],
        affiliate_links: ["https://www.g2a.com/half-life-2-steam-key-global-i10000003580008?gtag=391e4ef696", "https://www.amazon.com/Half-Life-Renewed-Pc/dp/B088F46GZQ?crid=VWT8ILMDL1RG&keywords=Half-Life+2&qid=1684448837&sprefix=half-life+2%2Caps%2C399&sr=8-16&linkCode=ll1&tag=guessthegam06-20&linkId=e2f082b70d5534d8205d5c29c24b8e07&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Half-Life',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '96%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Valve',
        backloggd_link: '',
    },
    '123': {
        content: '',
        submitted_by: '',
        answers: ["Assassin's Creed II", "Assassin's Creed 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20II%20NEW%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-ii-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-ii-deluxe-edition-ubisoft-connect-key-global-i10000005122006?gtag=391e4ef696", "https://www.amazon.com/Assassins-Creed-Game-essentials-playstation-3/dp/B009DLTF58?crid=F4RPRPV8Q88S&keywords=Assassin%27s+Creed+II&qid=1684448607&sprefix=the+simpsons+hit+%26+run%2Caps%2C801&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=bde8d9db3d4c2980718e92516c391f7d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Assassins Creed',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '91%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360',
        developer: 'Ubisoft Montréal',
        backloggd_link: '',
    },
    '124': {
        content: '',
        submitted_by: '',
        answers: ["The Simpsons: Hit & Run"],
        affiliate_links: ["https://www.amazon.com/Simpsons-Hit-Run-Gamecube-Renewed/dp/B07KQD6S7C?crid=329THJBLGMWZ8&keywords=The+Simpsons%3A+Hit+%26+Run&qid=1684448478&sprefix=the+simpsons+hit+%26+run%2Caps%2C395&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=5bfe0e08e38ef0dbbbab3196a8fa668b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Simpsons',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '78%',
        genre: '?Driving?',
        console_platform: 'GameCube, PS2, Xbox',
        developer: 'Radical Entertainment',
        backloggd_link: '',
    },
    '125': {
        content: '',
        submitted_by: '',
        answers: ["Payday 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Payday%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpayday-2-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/payday-2-legacy-collection-steam-key-global-i10000192666001?aid=13390774?gtag=391e4ef696", "https://www.amazon.com/Payday-2-Playstation-3-Renewed/dp/B088W1Z4N3?crid=2OZ9S93MWS6IZ&keywords=Payday+2&qid=1684448311&sprefix=payday+2%2Caps%2C461&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=832c553ee004b9a4293dc66522119c34&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Payday',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '79%',
        genre: 'Shooter, Heist Sim (?)',
        console_platform: 'PC, PS3, X360',
        developer: 'Overkill Software',
        backloggd_link: '',
    },
    '126': {
        content: '',
        submitted_by: '',
        answers: ["Metal Gear Solid 2: Sons of Liberty"],
        affiliate_links: ["https://www.amazon.com/Metal-Gear-Solid-2-Liberty-Pc/dp/B00005ML10?crid=2FP9PN14HEOQC&keywords=Metal+Gear+Solid+2%3A+Sons+of+Liberty&qid=1684448196&sprefix=metal+gear+solid+2+sons+of+liberty%2Caps%2C396&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=18d7ccaf51abddced98966425a208ca6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metal Gear',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '96%',
        genre: 'Action, Adventure/Stealth',
        console_platform: 'PS2',
        developer: 'Konami Computer Entertainment Japan',
        backloggd_link: '',
    },
    '127': {
        content: '',
        submitted_by: '',
        answers: ["Disco Elysium"],
        affiliate_links: ["https://www.g2a.com/disco-elysium-the-final-cut-pc-gogcom-key-global-i10000191524005?gtag=391e4ef696", "https://www.amazon.com/Disco-Elysium-Final-Cut-Nintendo-Switch/dp/B09P4CH1FB?crid=GHX3J9SGKOUA&keywords=Disco+Elysium&qid=1684448046&sprefix=disco+elysium%2Caps%2C449&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c6cdc9618b7ce1d307883f9e353b9fc2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '91%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'ZA/UM',
        backloggd_link: '',
    },
    '128': {
        content: '',
        submitted_by: '',
        answers: ["Red Dead Redemption"],
        affiliate_links: ["https://www.g2a.com/red-dead-redemption-xbox-360-xbox-live-key-global-i10000019359002?gtag=391e4ef696", "https://www.amazon.com/Red-Dead-Redemption-Game-Year-microsoft/dp/B005N4HBE6?crid=3K2SFWCK6RJLR&keywords=Red+Dead+Redemption&qid=1684447848&sprefix=rocket+league%2Caps%2C605&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=19ffc83967c31b1137146a8adda41c42&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Red Dead Redemption',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '95%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360',
        developer: 'Rockstar San Diego',
        backloggd_link: '',
    },
    '129': {
        content: '',
        submitted_by: '',
        answers: ["Rocket League"],
        affiliate_links: ["https://www.g2a.com/rocket-league-collectors-edition-pc-steam-gift-global-i10000017838001?gtag=391e4ef696", "https://www.amazon.com/Rocket-League-Collectors-PS4-playstation-3/dp/B01DVP2MQU?crid=3BYSPMD0RN6Y3&keywords=Rocket+League&qid=1684447712&sprefix=rocket+league%2Caps%2C419&sr=8-36&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=fecd1071246d84a8b4f60ce9fd931bc6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '86%',
        genre: '🤔 Sports? 🤔',
        console_platform: 'PC, PS4',
        developer: 'Psyonix',
        backloggd_link: '',
    },
    '130': {
        content: '',
        submitted_by: '',
        answers: ["ARK: Survival Evolved"],
        affiliate_links: ["https://www.g2a.com/ark-survival-evolved-ultimate-survivor-edition-pc-steam-account-global-i10000001612032?gtag=391e4ef696", "https://www.amazon.com/Solutions-PS4-ARK-Ultimate-Survivor-Multi/dp/B09KVJKH7V?crid=X4291HH2RX6R&keywords=ARK%3A+Survival+Evolved&qid=1684447582&sprefix=ark+survival+evolved%2Caps%2C465&sr=8-20&linkCode=ll1&tag=guessthegam06-20&linkId=575a303875733bafd7b7e76d6fa8c893&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'ARK',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '70%',
        genre: 'Action, Adventure',
        console_platform: 'PC',
        developer: 'Studio Wildcard',
        backloggd_link: '',
    },
    '131': {
        content: '',
        submitted_by: '',
        answers: ["Genshin Impact"],
        affiliate_links: [],
        franchise: 'Genshin Impact',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '84%',
        genre: 'RPG',
        console_platform: 'PC, PS4, Mobile',
        developer: 'miHoYo',
        backloggd_link: '',
    },
    '132': {
        content: '',
        submitted_by: '',
        answers: ["Counter-Strike"],
        affiliate_links: ["https://www.g2a.com/counter-strike-complete-steam-gift-global-i100000019450080?gtag=391e4ef696", "https://www.amazon.com/Counter-Strike-Xbox-Renewed/dp/B07ZYPKFP2?crid=3FL10Y7SXNPXJ&keywords=Counter-Strike&qid=1684367602&sprefix=counter-strike%2Caps%2C405&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=7c15fbaa9f62c8f1a5fc9f7abba7f4fd&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Counter-Strike',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '88%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Valve',
        backloggd_link: '',
    },
    '133': {
        content: '',
        submitted_by: '',
        answers: ["Mario Kart: Double Dash!!"],
        affiliate_links: ["https://www.amazon.com/Mario-Kart-Double-Dash-Renewed-Gamecube/dp/B07KQD7W2G?crid=2FK3HWE3I8VET&keywords=Mario+Kart%3A+Double+Dash%21%21&qid=1684882153&sprefix=mario+kart+double+dash+%2Caps%2C342&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=8be4d42b8f53ae82603713db2bf271ed&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario Kart',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '87%',
        genre: 'Racing',
        console_platform: 'GameCube',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '134': {
        content: '',
        submitted_by: '',
        answers: ["Undertale"],
        affiliate_links: ["https://www.g2a.com/undertale-steam-key-global-i10000005286007?gtag=391e4ef696", "https://www.amazon.com/Undertale-Collectors-PS-Vita-PlayStation/dp/B077H56F6P?crid=2TG0V95LHSZYL&keywords=Undertale+game&qid=1684367398&sprefix=undertale+ga%2Caps%2C357&sr=8-10&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=0c1e83c70347e8c872edb900f2846b35&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Undertale',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '92%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'Toby Fox',
        backloggd_link: '',
    },
    '135': {
        content: '',
        submitted_by: '',
        answers: ["Cities: Skylines"],
        affiliate_links: ["https://www.g2a.com/cities-skylines-city-startup-bundle-pc-steam-key-global-i10000218085001?gtag=391e4ef696", "https://www.amazon.com/Cities-Skylines-Nintendo-Switch/dp/B07RFSJNYC?crid=3PGZFROGAVXJK&keywords=Cities%3A+Skylines+bundle&qid=1684367090&sprefix=cities+skylines+bundle%2Caps%2C329&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=10903b7643525500afc64f44eca7a3ae&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Cities: Skylines',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '85%',
        genre: 'City Building',
        console_platform: 'PC',
        developer: 'Colossal Order',
        backloggd_link: '',
    },
    '136': {
        content: '',
        submitted_by: '',
        answers: ["Rust"],
        affiliate_links: ["https://www.g2a.com/rust-pc-steam-key-global-i10000001781013?gtag=391e4ef696", "https://www.amazon.com/Rust-Xbox-One/dp/B091F1J6R7?crid=2DBS98QFH7LQ2&keywords=Rust&qid=1684366715&sprefix=rust%2Caps%2C374&sr=8-6&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=414fba1b428851a5995e4532a00c0f1b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '69%',
        genre: 'Action, Adventure',
        console_platform: 'PC',
        developer: 'Facepunch Studios',
        backloggd_link: '',
    },
    '137': {
        content: '',
        submitted_by: '',
        answers: ["Viva Pi\u00f1ata"],
        affiliate_links: ["https://www.amazon.com/Pi%C3%B1ata-Platinum-Family-Renewed-Xbox-360/dp/B07S64MWW4?crid=30WIJ9CYQYL0&keywords=Viva+Pinata&qid=1684366631&sprefix=viva+pinata%2Caps%2C462&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=ae5eebde4c5ad26cb1bcd677a091b3dc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '84%',
        genre: 'Life Simulation',
        console_platform: 'X360',
        developer: 'Rare, Krome Studios',
        backloggd_link: '',
    },
    '138': {
        content: '',
        submitted_by: '',
        answers: ["Overcooked!"],
        affiliate_links: ["https://www.g2a.com/overcooked-steam-key-global-i10000025931006?aid=13234396?gtag=391e4ef696", "https://www.amazon.com/Overcooked-Special-Double-Pack-Nintendo-Switch/dp/B0824C7F2L?crid=JLQD3SFCC6XJ&keywords=Overcooked%21&qid=1684366456&sprefix=overcooked+%2Caps%2C412&sr=8-11&linkCode=ll1&tag=guessthegam06-20&linkId=e6e14797d5083594f8bb0c8f0504c356&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Overcooked',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '81%',
        genre: 'Arcade/Simulation/Complete Chaos',
        console_platform: 'PC, PS4, XONE',
        developer: 'Ghost Town Games',
        backloggd_link: '',
    },
    '139': {
        content: '',
        submitted_by: '',
        answers: ["Team Fortress 2"],
        affiliate_links: [],
        franchise: 'Team Fortress',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '92%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Valve',
        backloggd_link: '',
    },
    '140': {
        content: '',
        submitted_by: '',
        answers: ["Hitman 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=HITMAN%203%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhitman-3-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/hitman-3-deluxe-edition-pc-steam-key-global-i10000218039023?gtag=391e4ef696", "https://www.amazon.com/HITMAN-Deluxe-Xbox-Digital-Code/dp/B08TSCKLYQ?crid=3I6QV1W2FGBYP&keywords=Hitman+3&qid=1684366153&sprefix=hitman+3%2Caps%2C476&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=ea0fd6c00441cf9ed6941056ccfea450&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hitman',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '87%',
        genre: 'Stealth',
        console_platform: 'PC, PS4, PS5, XONE, Xbox Series',
        developer: 'IO Interactive',
        backloggd_link: '',
    },
    '141': {
        content: '',
        submitted_by: '',
        answers: ["Doom Eternal"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DOOM%20Eternal%20Deluxe%20Edition%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdoom-eternal-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/doom-eternal-deluxe-edition-steam-gift-global-i10000188637020?gtag=391e4ef696", "https://www.amazon.com/DOOM-Eternal-Collectors-PlayStation-4/dp/B094SR9951?crid=23UWG3WRO7BIA&keywords=Doom+Eternal&qid=1684366071&sprefix=doom+eternal%2Caps%2C421&sr=8-13&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=7af10e93d7c844507a2bb5ea7320b8fa&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Doom',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '88%',
        genre: 'Shooter',
        console_platform: 'PC, PS4, XONE',
        developer: 'id Software',
        backloggd_link: '',
    },
    '142': {
        content: '',
        submitted_by: '',
        answers: ["Hades"],
        affiliate_links: ["https://www.g2a.com/hades-steam-gift-global-i10000192688002?gtag=391e4ef696", "https://www.amazon.com/Hades-Limited-Nintendo-Switch/dp/B08X2K6B1Z?crid=UX9I3YJ2Z7MH&keywords=Hades&qid=1684365758&sprefix=hades%2Caps%2C354&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=63db1481876409c42fab7e7f8cb4304a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '93%',
        genre: 'Action, Adventure, Roguelike',
        console_platform: 'PC, Switch',
        developer: 'Supergiant Games',
        backloggd_link: '',
    },
    '143': {
        content: '',
        submitted_by: '',
        answers: ["Tunic"],
        affiliate_links: ["https://www.g2a.com/tunic-pc-steam-gift-global-i10000280043001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '85%',
        genre: 'Action, Adventure, RPG',
        console_platform: 'PC, Xbox Series, XONE',
        developer: 'Andrew Shouldice',
        backloggd_link: '',
    },
    '144': {
        content: '',
        submitted_by: '',
        answers: ["Ratchet & Clank"],
        affiliate_links: ["https://www.amazon.com/Ratchet-Clank-Collection-Playstation-3/dp/B0050SXI6K?crid=AFKRJRY400RW&keywords=Ratchet+%26+Clank&qid=1684365367&sprefix=ratchet+%26+clank%2Caps%2C441&sr=8-9&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=971c7f56b4dd1732a853f904cca5cdd9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ratchet & Clank',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '88%',
        genre: '3D Action, Platformer',
        console_platform: 'PS2',
        developer: 'Insomniac Games',
        backloggd_link: '',
    },
    '145': {
        content: '',
        submitted_by: '',
        answers: ["Elden Ring"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Elden%20Ring%20Deluxe%20Edition%20NEW%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Felden-ring-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/elden-ring-deluxe-edition-xbox-series-x-s-xbox-live-key-global-i10000273674014?gtag=391e4ef696", "https://www.amazon.com/Elden-Ring-Deluxe-Xbox-Digital/dp/B09QQXX3BD?crid=3CLK4DD698YEG&keywords=Elden+Ring&qid=1684365202&sprefix=elden+ring%2Caps%2C466&sr=8-13&linkCode=ll1&tag=guessthegam06-20&linkId=ea46ab2153ff1cefd03edab248d4b48c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Elden Ring',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '96%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS4, PS5, XONE, Xbox Series X',
        developer: 'From Software',
        backloggd_link: '',
    },
    '146': {
        content: '',
        submitted_by: '',
        answers: ["No Man's Sky"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=No%20Mans%20Sky%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fno-mans-sky%2F&intsrc=PUI2_9608", "https://www.g2a.com/no-mans-sky-pc-steam-gift-global-i10000016743008?gtag=391e4ef696", "https://www.amazon.com/Mans-Sky-PS4-Playstation-game-4/dp/B076P1BGGP?crid=1AIYS18IKF2XT&keywords=No+Man%27s+Sky&qid=1684365037&sprefix=no+man%27s+sky%2Caps%2C372&sr=8-7&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=8c883bc173add42072e3bcfe6cba21a4&language=en_US&ref_=as_li_ss_tl9bc0-4513d670b6bc"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '71%',
        genre: 'Action Adventure / Survival',
        console_platform: 'PC, PS4',
        developer: 'Hello Games',
        backloggd_link: '',
    },
    '147': {
        content: '',
        submitted_by: '',
        answers: ["Crazy Taxi"],
        affiliate_links: ["https://www.g2a.com/crazy-taxi-steam-key-global-i10000047390002?gtag=391e4ef696", "https://www.amazon.com/Crazy-Taxi-Renewed-GameCube/dp/B084M7Q9LN?crid=3AI65R6IQPGN&keywords=Crazy+Taxi&qid=1684364936&sprefix=crazy+taxi%2Caps%2C421&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=73ffe2599494c85976d5284ee1032881&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Crazy Taxi',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '80%',
        genre: 'Racing',
        console_platform: 'Arcade',
        developer: 'Hitmaker / Acclaim',
        backloggd_link: '',
    },
    '148': {
        content: '',
        submitted_by: '',
        answers: ["Crysis"],
        affiliate_links: ["https://www.g2a.com/crysis-maximum-edition-steam-gift-global-i10000042828001?gtag=391e4ef696", "https://www.amazon.com/Crysis-Remastered-Trilogy-Nintendo-Switch-PC/dp/B0C36WC488?crid=36L3O5X20DGLG&keywords=Crysis&qid=1684364770&sprefix=crysis%2Caps%2C388&sr=8-14&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=979ece8dd316254cc42e43f15ae0a910&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Crysis',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '91%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Crytek',
        backloggd_link: '',
    },
    '149': {
        content: '',
        submitted_by: '',
        answers: ["VVVVVV"],
        affiliate_links: ["https://www.g2a.com/vvvvvv-steam-key-global-i10000000981002?gtag=391e4ef696"],
        franchise: 'VVVVVV',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '81%',
        genre: 'Platformer',
        console_platform: 'PC',
        developer: 'Terry Cavanagh',
        backloggd_link: '',
    },
    '150': {
        content: '',
        submitted_by: '',
        answers: ["Starcraft"],
        affiliate_links: ["https://www.g2a.com/starcraft-remastered-pc-battlenet-key-global-i10000075440001?aid=12651273?gtag=391e4ef696", "https://www.amazon.com/StarCraft-Battle-Chest-PC-Mac/dp/B00001IVRD?crid=3R3JIP1DWAJSP&keywords=Starcraft+game&qid=1684364024&sprefix=starcraft+gam%2Caps%2C350&sr=8-4&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=6d74c8bf5924610de43a19efa2ff224a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'StarCraft',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '88%',
        genre: 'Real-time Strategy',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '151': {
        content: '',
        submitted_by: '',
        answers: ["Fall Guys"],
        affiliate_links: ["https://www.g2a.com/fall-guys-ultimate-knockout-collectors-edition-pc-steam-key-global-i10000206388009?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '81%',
        genre: 'Party',
        console_platform: 'PC, PS4',
        developer: 'Mediatronic',
        backloggd_link: '',
    },
    '152': {
        content: '',
        submitted_by: '',
        answers: ["Mother 3"],
        affiliate_links: ["https://www.amazon.com/Mother-Earthbound-Translation-Nintendo-Game-Boy/dp/B099ZFF8LQ?keywords=Mother+3&qid=1684195110&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=136fc3a08fdf0e857f5dcaa1bca1bc69&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mother',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: 'Not scored (JP exclusive release)',
        genre: 'RPG',
        console_platform: 'GBA',
        developer: 'Brownie Brown / HAL Laboratory',
        backloggd_link: '',
    },
    '153': {
        content: '',
        submitted_by: '',
        answers: ["Journey"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '92%',
        genre: 'Action, Adventure, Platformer',
        console_platform: 'PS3',
        developer: 'Thatgamecompany',
        backloggd_link: '',
    },
    '154': {
        content: '',
        submitted_by: '',
        answers: ["Silent Hill 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Silent%20Hill%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsilent-hill-2-pc%2F&intsrc=PUI2_9608", "https://www.amazon.com/Silent-Hill-2-Renewed-Playstation/dp/B086Z488CM?crid=2P4VJXAEKCO7J&keywords=Silent+Hill+2&qid=1684194403&sprefix=silent+hill+2%2Caps%2C774&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=aa858e4e46100c20e60c82ddd83dd301&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Silent Hill',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '89%',
        genre: 'Survival, Horror',
        console_platform: 'PS2, Xbox',
        developer: 'Team Silent (Konami Computer Entertainment Tokyo)',
        backloggd_link: '',
    },
    '155': {
        content: '',
        submitted_by: '',
        answers: ["Tom Clancy's Splinter Cell: Chaos Theory"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tom%20Clancys%20Splinter%20Cell%20Chaos%20Theory%20NEW%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftom-clancys-splinter-cell-chaos-theory-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tom-clancys-splinter-cell-chaos-theory-ubisoft-connect-key-global-i10000044673003?gtag=391e4ef696", "https://www.amazon.com/Tom-Clancys-Splinter-Cell-Classic-Trilogy/dp/B004HCYVO8?keywords=Tom+Clancy%27s+Splinter+Cell%3A+Chaos+Theory&qid=1683950318&s=videogames&sr=1-18&linkCode=ll1&tag=guessthegam06-20&linkId=4b6d2f4be64d3c595d0118b69c95cf90&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Tom Clancy',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '92%',
        genre: 'Stealth Action',
        console_platform: 'Xbox, PS2, GameCube, PC',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '156': {
        content: '',
        submitted_by: '',
        answers: ["League of Legends"],
        affiliate_links: [],
        franchise: 'League of Legends',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '78%',
        genre: 'MOBA',
        console_platform: 'PC',
        developer: 'Riot Games',
        backloggd_link: '',
    },
    '157': {
        content: '',
        submitted_by: '',
        answers: ["Borderlands"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Borderlands%20Franchise%20Pack%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fborderlands-franchise-pack%2F&intsrc=PUI2_9608", "https://www.g2a.com/borderlands-legendary-collection-xbox-one-xbox-live-key-united-states-i10000206673005?gtag=391e4ef696", "https://www.amazon.com/Borderlands-Legendary-Collection-Nintendo-Switch/dp/B086BTBPQM?crid=1IPJADDEHHJ2X&keywords=Borderlands&qid=1683949255&s=videogames&sprefix=borderlands%2Cvideogames%2C363&sr=1-9&linkCode=ll1&tag=guessthegam06-20&linkId=cbecedc1f89466965b949baeb83e74ce&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Borderlands',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '84%',
        genre: 'Shooter',
        console_platform: 'PC, PS3, X360',
        developer: 'Gearbox Software',
        backloggd_link: '',
    },
    '158': {
        content: '',
        submitted_by: '',
        answers: ["FEAR: First Encounter Assault Recon", "FEAR"],
        affiliate_links: ["https://www.amazon.com/F-R-First-Encounter-Assault-Recon/dp/B000HKGIOA?crid=1A8NQJ3FIFVMM&keywords=FEAR%3A+First+Encounter+Assault+Recon&qid=1683948854&s=videogames&sprefix=fear+first+encounter+assault+recon%2Cvideogames%2C711&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=2305316fa2c4bd32c042aca206a75860&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'FEAR',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '88%',
        genre: 'Psychological Horror / FPS',
        console_platform: 'PC, X360, PS3',
        developer: 'Monolith Productions',
        backloggd_link: '',
    },
    '159': {
        content: '',
        submitted_by: '',
        answers: ["Twisted Metal"],
        affiliate_links: ["https://www.amazon.com/Twisted-Metal-Renewed-playstation/dp/B085D7L4ST?crid=2UT0815SFNIDL&keywords=Twisted+Metal&qid=1683948205&s=videogames&sprefix=twisted+metal%2Cvideogames%2C400&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=2b67839f4ed0be5a33640259bdddb2b1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Twisted Metal',
        twitter_ids: [],
        release_year: '1995',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Vehicular combat',
        console_platform: 'PS1',
        developer: 'SingleTrac',
        backloggd_link: '',
    },
    '160': {
        content: '',
        submitted_by: '',
        answers: ["Killzone 2"],
        affiliate_links: ["https://www.amazon.com/PS3-Killzone-Trilogy-Collection-sony-playstation3/dp/B008PQU3E4?crid=3PGFESW0HV6YT&keywords=Killzone+2&qid=1683948134&s=videogames&sprefix=killzone+2%2Cvideogames%2C433&sr=1-16&linkCode=ll1&tag=guessthegam06-20&linkId=42e1de777b0cadfffb53e53bdd4b7563&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Killzone',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '91%',
        genre: 'Shooter',
        console_platform: 'PS3',
        developer: 'Guerilla Games',
        backloggd_link: '',
    },
    '161': {
        content: '',
        submitted_by: '',
        answers: ["Luigi's Mansion"],
        affiliate_links: ["https://www.amazon.com/Luigis-Mansion-Gamecube-Renewed/dp/B07KQD1RSZ?crid=20TTLJ62YS65H&keywords=Luigi%27s+Mansion&qid=1683947989&s=videogames&sprefix=luigi%27s+mansion%2Cvideogames%2C384&sr=1-9&linkCode=ll1&tag=guessthegam06-20&linkId=ee3263de2e69e6464557e266cd062c64&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Luigis Mansion',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '78%',
        genre: 'Action, Adventure',
        console_platform: 'GameCube',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '162': {
        content: '',
        submitted_by: '',
        answers: ["BioShock"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=BioShock%20The%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbioshock-the-collection%2F&intsrc=PUI2_9608", "https://www.g2a.com/bioshock-triple-pack-steam-key-global-i10000000584008?gtag=391e4ef696", "https://www.amazon.com/BioShock-Collection-Nintendo-Switch/dp/B086BTCXKZ?crid=2XY57C98GPZR3&keywords=BioShock&qid=1683947787&s=videogames&sprefix=bioshock%2Cvideogames%2C346&sr=1-5&linkCode=ll1&tag=guessthegam06-20&linkId=596ee05e0fd699f72a4a66507e51311e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bioshock',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '96%',
        genre: 'Shooter',
        console_platform: 'PC, X360',
        developer: '2K Games & Irrational Games',
        backloggd_link: '',
    },
    '163': {
        content: '',
        submitted_by: '',
        answers: ["Phasmophobia"],
        affiliate_links: ["https://www.g2a.com/phasmophobia-pc-steam-gift-global-i10000218773004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020 (Early Access)',
        metacritic_score: 'Not Yet Rated',
        genre: 'Horror/Simulator',
        console_platform: 'PC',
        developer: 'Kinetic Games',
        backloggd_link: '',
    },
    '164': {
        content: '',
        submitted_by: '',
        answers: ["Tomb Raider"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tomb%20Raider%20Collection%202016%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftomb-raider-collection-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tomb-raider-collection-steam-key-global-i10000007357005?gtag=391e4ef696", "https://www.amazon.com/Tomb-Raider-Trilogy-Playstation-3/dp/B002I0J5JW?crid=34D87HVH17DC9&keywords=Tomb+Raider+Collection&qid=1683947607&sprefix=tomb+raider+collection%2Caps%2C365&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=890f61f35dfd5d912b4606eedbbf73b1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Tomb Raider',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '87%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'Crystal Dynamics',
        backloggd_link: '',
    },
    '165': {
        content: '',
        submitted_by: '',
        answers: ["Unreal Tournament"],
        affiliate_links: ["https://www.g2a.com/unreal-tournament-game-of-the-year-edition-steam-key-global-i10000050095002?gtag=391e4ef696", "https://www.amazon.com/Unreal-Tournament-Game-Year-Mac/dp/B00005A3LG?crid=3Q5LNL6PQCDNA&keywords=Unreal+Tournament&qid=1683947242&sprefix=unreal+tournament%2Caps%2C374&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=3aadc91384532cc7656c4a25c0c9ebd6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Unreal',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '92%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Epic Games',
        backloggd_link: '',
    },
    '166': {
        content: '',
        submitted_by: '',
        answers: ["Ori and the Blind Forest"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Ori%20and%20the%20Blind%20Forest%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fori-and-the-blind-forest-definitive-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/ori-and-the-blind-forest-xbox-live-key-global-i10000001951007?gtag=391e4ef696", "https://www.amazon.com/Ori-Blind-Forest-Defintive-Nintendo-Switch/dp/B08KJGC2QC?crid=F1TOKC1GZ4AI&keywords=Ori+and+the+Blind+Forest&qid=1683947080&sprefix=ori+and+the+blind+forest%2Caps%2C374&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=3bf0a0aa0643f2903a2e0f0e1f3acd93&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ori',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '88%',
        genre: 'Platformer',
        console_platform: 'XONE, PC',
        developer: 'Moon Studios',
        backloggd_link: '',
    },
    '167': {
        content: '',
        submitted_by: '',
        answers: ["Friday the 13th: The Game"],
        affiliate_links: ["https://www.g2a.com/friday-the-13th-the-game-steam-key-global-i10000042338007?aid=13233952?gtag=391e4ef696", "https://www.amazon.com/Tisch-Friday-13th-Game-Ultimate-PC/dp/B0C1BSBLK9?keywords=Friday+the+13th%3A+The+Game&qid=1683946781&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=544db3665050bbb0eb63007770d7c499&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '61%',
        genre: 'Survival, Horror/Asymmetric Multiplayer',
        console_platform: 'PC, PS4, XONE',
        developer: 'IllFonic',
        backloggd_link: '',
    },
    '168': {
        content: '',
        submitted_by: '',
        answers: ["Crypt of the NecroDancer"],
        affiliate_links: ["https://www.g2a.com/crypt-of-the-necrodancer-steam-gift-global-i10000004674002?gtag=391e4ef696", "https://www.amazon.com/Crypt-Necrodancer-Collectiors-Nintendo-Switch/dp/B08L67XTQG?crid=60HPXOFU46GM&keywords=Crypt+of+the+NecroDancer&qid=1683853066&sprefix=manhunt+game%2Caps%2C991&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=f031ca83ed7b1066781a288753538e6f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '87%',
        genre: 'Roguelike, Rhythm',
        console_platform: 'PC',
        developer: 'Brace Yourself Games',
        backloggd_link: '',
    },
    '169': {
        content: '',
        submitted_by: '',
        answers: ["Manhunt"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Manhunt%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmanhunt%2F&intsrc=PUI2_9608", "https://www.g2a.com/manhunt-steam-key-global-i10000044389003?gtag=391e4ef696", "https://www.amazon.com/Manhunt-Xbox/dp/B0001JXQ5Q?crid=3AON7RL8YJYWJ&keywords=manhunt+game&qid=1683853012&sprefix=Manhunt%2Caps%2C348&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=b6a21664ca93d64b149307fa08b2721f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Manhunt',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '76%',
        genre: 'Stealth, Horror',
        console_platform: 'PS2',
        developer: 'Rockstar North',
        backloggd_link: '',
    },
    '170': {
        content: '',
        submitted_by: '',
        answers: ["Five Nights at Freddy's"],
        affiliate_links: ["https://www.g2a.com/five-nights-at-freddys-steam-key-global-i10000002662004?gtag=391e4ef696", "https://www.amazon.com/Five-Nights-Freddys-Core-Collection-PlayStation/dp/B08L93C4KV?crid=3HS5BL36TFD3K&keywords=Five+Nights+at+Freddy%27s&qid=1683852609&s=videogames&sprefix=five+nights+at+freddy%27s%2Cvideogames%2C345&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=5315db5b195d1ec7ddd8e14f073dacc7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Five Nights at Freddys',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '78%',
        genre: 'Horror',
        console_platform: 'PC',
        developer: 'Scott Cawthorn',
        backloggd_link: '',
    },
    '171': {
        content: '',
        submitted_by: '',
        answers: ["L.A. Noire"],
        affiliate_links: ["https://www.g2a.com/la-noire-complete-edition-steam-key-global-i10000001414009?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '89%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360, PC',
        developer: 'Team Bondi',
        backloggd_link: '',
    },
    '172': {
        content: '',
        submitted_by: '',
        answers: ["Castlevania: Symphony of the Night"],
        affiliate_links: ["https://www.amazon.com/Castlevania-Symphony-Night-Renewed-Pc/dp/B084KN9FKC?crid=11UA1N6E3WC97&keywords=Castlevania%3A+Symphony+of+the+Night&qid=1683852142&s=videogames&sprefix=castlevania+symphony+of+the+night%2Cvideogames%2C475&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=0e1329948988daf6cf4408290599aabb&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Castlevania',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '93%',
        genre: 'Action, RPG, Platform, Adventure',
        console_platform: 'PS1',
        developer: 'Konami Computer Entertainment Tokyo',
        backloggd_link: '',
    },
    '173': {
        content: '',
        submitted_by: '',
        answers: ["Age of Empires II: The Age of Kings", "Age of Empires 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Age%20of%20Empires%20Definitive%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fage-of-empires-definitive-collection-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/age-of-empires-ii-hd-the-african-kingdoms-steam-gift-europe-i10000007244003?gtag=391e4ef696", "https://www.amazon.com/Age-Empires-II-Gold-pc/dp/B00006HR22?crid=35EGFJIDC80EH&keywords=Age+of+Empires+II%3A+The+Age+of+Kings&qid=1683851978&s=videogames&sprefix=age+of+empires+ii+the+age+of+kings%2Cvideogames%2C369&sr=1-8&linkCode=ll1&tag=guessthegam06-20&linkId=3dca48c0d7f349206da91d844fa5fc55&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Age of Empires',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '92%',
        genre: 'Real-Time Strategy',
        console_platform: 'PC',
        developer: 'Ensemble Studios',
        backloggd_link: '',
    },
    '174': {
        content: '',
        submitted_by: '',
        answers: ["Battlefield 2"],
        affiliate_links: ["https://www.amazon.com/Battlefield-2-Complete-Pc/dp/B001Q9C5RU?crid=11PFW6WSMKWOQ&keywords=Battlefield+2&qid=1683851720&s=videogames&sprefix=battlefield+2%2Cvideogames%2C466&sr=1-5&linkCode=ll1&tag=guessthegam06-20&linkId=2a51bb90ecbae2bd0b24350a81169a4e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Battlefield',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '91%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'DICE',
        backloggd_link: '',
    },
    '175': {
        content: '',
        submitted_by: '',
        answers: ["Prince of Persia: The Sands of Time"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Prince%20of%20Persia%20The%20Sands%20of%20Time%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fprince-of-persia-the-sands-of-time%2F&intsrc=PUI2_9608", "https://www.g2a.com/prince-of-persia-the-sands-of-time-remake-xbox-series-x-xbox-live-key-united-states-i10000218455001?gtag=391e4ef696", "https://www.amazon.com/Prince-Persia-Sands-Time-Remake/dp/B08TS7B3YL?crid=LEG6H4WZA1RH&keywords=Prince+of+Persia%3A+The+Sands+of+Time&qid=1683851400&s=videogames&sprefix=prince+of+persia+the+sands+of+time%2Cvideogames%2C535&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=1a7405fb6912456b5807bb2040fffe39&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Prince of Persia',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '92%',
        genre: 'Action, Adventure',
        console_platform: 'GBA, PS2, GameCube, Xbox, PC',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '176': {
        content: '',
        submitted_by: '',
        answers: ["Darkest Dungeon"],
        affiliate_links: ["https://www.g2a.com/darkest-dungeon-ancestral-edition-steam-key-global-i10000154528001?aid=12627847?gtag=391e4ef696", "https://www.amazon.com/Darkest-Dungeon-Collectors-Console-Nintendo-Switch/dp/B07MWVBX1X?crid=3SVAWZAUMJTMI&keywords=Darkest+Dungeon&qid=1683851292&s=videogames&sprefix=darkest+dungeon%2Cvideogames%2C352&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=0eafb8efae4d3f948eb93fdded978ae0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Darkest Dungeon',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '84%',
        genre: 'Roguelite RPG',
        console_platform: 'PC',
        developer: 'Red Hook Studios',
        backloggd_link: '',
    },
    '177': {
        content: '',
        submitted_by: '',
        answers: ["Sonic the Hedgehog"],
        affiliate_links: ["https://www.g2a.com/sonic-the-hedgehog-ultimate-bundle-pc-steam-key-global-i10000336762002?gtag=391e4ef696", "https://www.amazon.com/Sonic-Hedgehog-Xbox-360-Renewed/dp/B07Y2FT9BF?crid=2ELZS15I635D4&keywords=Sonic+the+Hedgehog+bundle&qid=1683851211&s=videogames&sprefix=sonic+the+hedgehog+bundle%2Cvideogames%2C382&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=409508ac24e4877e432a551cb60a53e7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sonic The Hedgehog',
        twitter_ids: [],
        release_year: '1991',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Platformer',
        console_platform: 'Genesis',
        developer: 'Sonic Team',
        backloggd_link: '',
    },
    '178': {
        content: '',
        submitted_by: '',
        answers: ["System Shock 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=System%20Shock%20Enhanced%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsystem-shock-enhanced-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/system-shock-2-gogcom-key-global-i10000000133005?gtag=391e4ef696"],
        franchise: 'System Shock',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '92%',
        genre: 'Sci-Fi Action, Adventure',
        console_platform: 'PC',
        developer: 'Ken Levine, Irrational Games, Looking Glass Studios',
        backloggd_link: '',
    },
    '179': {
        content: '',
        submitted_by: '',
        answers: ["Spelunky"],
        affiliate_links: ["https://www.g2a.com/spelunky-steam-gift-global-i10000000394002?gtag=391e4ef696", "https://www.amazon.com/Spelunky-Collectors-Limited-Run-220-Playstation/dp/B07SG1GP63?crid=1SZ50YM6HJXUJ&keywords=spelunky&qid=1683850880&s=videogames&sprefix=spelunky%2Cvideogames%2C332&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=890dbd08bd39715d82256cabbf6d8ce1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spelunky',
        twitter_ids: [],
        release_year: '2012 (or 2008 in origin.. but practically speaking, 2012)',
        metacritic_score: '87%',
        genre: 'Platformer, Roguelike',
        console_platform: 'X360 (or PC sort of...)',
        developer: 'Mossmouth',
        backloggd_link: '',
    },
    '180': {
        content: '',
        submitted_by: '',
        answers: ["The Sims 4"],
        affiliate_links: ["https://www.amazon.com/Sims-Everyday-Bundle-Online-Game/dp/B0BR3W49KH?crid=I6D5EIM9RIJT&keywords=the%2Bsims%2B4%2Bbase%2Bgame&qid=1683850032&sprefix=the%2Bsims%2B4%2Bbase%2B%2Caps%2C862&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=70632cbc216b2a0b344173e059c8c775&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Sims',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '70%',
        genre: 'Virtual Life, Simulation',
        console_platform: 'PC',
        developer: 'Maxis',
        backloggd_link: '',
    },
    '181': {
        content: '',
        submitted_by: '',
        answers: ["Monster Hunter Rise"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Monster%20Hunter%20Rise%20Plus%20Sunbreak%20Deluxe%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmonster-hunter-rise-plus-sunbreak-deluxe-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/monster-hunter-rise-sunbreak-deluxe-edition-pc-steam-gift-global-i10000337162010?gtag=391e4ef696", "https://www.amazon.com/Monster-Hunter-Rise-Sunbreak-Standard/dp/B0B7LPVNXK?crid=19IVPINJK5B5U&keywords=Monster+Hunter+Rise&qid=1683849554&sprefix=monster+hunter+rise%2Caps%2C417&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=865c561508f8f793cf8a0bbbf9d5caef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Monster Hunter',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '88%',
        genre: 'Action, RPG',
        console_platform: 'Switch',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '182': {
        content: '',
        submitted_by: '',
        answers: ["Plants vs. Zombies"],
        affiliate_links: ["https://www.g2a.com/plants-vs-zombies-goty-edition-pc-origin-key-global-i10000029914004?gtag=391e4ef696", "https://www.amazon.com/Plants-Vs-Zombies-Nintendo-DS/dp/B00407F2BQ?crid=2G9CYTGO5WPQH&keywords=plants+vs.+zombies+game&qid=1683849485&sprefix=Plants+vs.+Zombies%2Caps%2C388&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=88ef4b08bf10d52eeff220470643e5e4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Plants vs. Zombies',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '87%',
        genre: 'Strategy, Defense?',
        console_platform: 'PC',
        developer: 'PopCap Games',
        backloggd_link: '',
    },
    '183': {
        content: '',
        submitted_by: '',
        answers: ["Cult of the Lamb"],
        affiliate_links: ["https://www.g2a.com/cult-of-the-lamb-pc-steam-gift-global-i10000326369004?gtag=391e4ef696", "https://www.amazon.com/Cult-Lamb-Deluxe-Nintendo-Switch/dp/B0BJW49FK2?crid=C31M09YZGADM&keywords=cult+of+the+lamb+game&qid=1683849161&sprefix=Cult+of+the+Lamb+%2Caps%2C415&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=ef93e67e793d91ff1a61f3adda1a296b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '82%',
        genre: 'Action, Adventure',
        console_platform: 'PC, Switch, PS4, PS5, XONE, Xbox Series',
        developer: 'Massive Monster',
        backloggd_link: '',
    },
    '184': {
        content: '',
        submitted_by: '',
        answers: ["Alien: Isolation"],
        affiliate_links: ["https://www.g2a.com/alien-isolation-collection-steam-gift-global-i10000007292006?gtag=391e4ef696", "https://www.amazon.com/Alien-Isolation-PS3-playstation-3/dp/B00HQCQ1R6?crid=3UCV7OWQABJR5&keywords=Alien%3A%2BIsolation&qid=1683680830&sprefix=alien%2Bisolation%2Caps%2C340&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=86734f1c90fc821e3c0ed66f5a94d7c7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Alien',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '81%',
        genre: 'Survival, Horror',
        console_platform: 'PC, PS3, PS4, X360, XONE',
        developer: 'Creative Assembly',
        backloggd_link: '',
    },
    '185': {
        content: '',
        submitted_by: '',
        answers: ["Mario & Luigi: Bowser's Inside Story"],
        affiliate_links: ["https://www.amazon.com/Mario-Luigi-Bowsers-Journey-Nintendo-3DS/dp/B088F8TXB3?crid=1JFAR3HAPWPCJ&keywords=Mario+%26+Luigi%3A+Bowser%27s+Inside+Story&qid=1683680716&sprefix=mario+%26+luigi+bowser%27s+inside+story%2Caps%2C795&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=b820c209aa1f112bb0b675f88a15f89f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '90%',
        genre: 'RPG',
        console_platform: 'DS',
        developer: 'AlphaDream Corporation',
        backloggd_link: '',
    },
    '186': {
        content: '',
        submitted_by: '',
        answers: ["Stray"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Stray%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstray-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/stray-ps5-psn-account-global-i10000326277008?aid=13397890?gtag=391e4ef696", "https://www.amazon.com/Stray-PlayStation-5/dp/B0B69SG993?crid=1OGKSBOZZJ7V3&keywords=Stray&qid=1683680610&sprefix=stray%2Caps%2C449&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=21b9ad6d219185e23ad9812086b8ee92&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '83%',
        genre: 'Action, Adventure',
        console_platform: 'PS4, PS5, PC',
        developer: 'BlueTwelve Studio',
        backloggd_link: '',
    },
    '187': {
        content: '',
        submitted_by: '',
        answers: ["Angry Birds"],
        affiliate_links: ["https://www.amazon.com/Angry-Birds-Trilogy-Nintendo-Wii/dp/B00DLL8P98?crid=YZ4QMYG548TK&keywords=angry%2Bbirds%2Bgame&qid=1683680534&sprefix=Angry%2BBirds%2Caps%2C325&sr=8-3&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=b0180abdf8ef584840dcfbfcdbf03333&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Angry Birds',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '80%',
        genre: 'Casual, Puzzle',
        console_platform: 'Mobile',
        developer: 'Rovio Entertainment',
        backloggd_link: '',
    },
    '188': {
        content: '',
        submitted_by: '',
        answers: ["Slay the Spire"],
        affiliate_links: ["https://www.g2a.com/slay-the-spire-steam-gift-global-i10000083851002?gtag=391e4ef696", "https://www.amazon.com/Slay-Spire-PlayStation-4/dp/B07X25XTJN?crid=M9UAIEBO5AZ8&keywords=Slay+the+Spire&qid=1683680409&sprefix=warhammer+vermintide+2+collectors%2Caps%2C417&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=da638e5024fe110b839c1ea172c62de5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '89%',
        genre: 'Roguelike Deck-Building',
        console_platform: 'PC, PS4, Switch, XONE',
        developer: 'MegaCrit',
        backloggd_link: '',
    },
    '189': {
        content: '',
        submitted_by: '',
        answers: ["Warhammer: Vermintide 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Warhammer%20Vermintide%202%20Collectors%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwarhammer-vermintide-2-collectors-edition-ztorm-4bf596dd-en.aspx%2F&intsrc=PUI2_9608", "https://www.g2a.com/warhammer-vermintide-2-content-bundle-2020-steam-key-global-i10000190414001?gtag=391e4ef696", "https://www.amazon.com/Warhammer-Vermintide-Deluxe-PS4-playstation-4/dp/B07QGG4CHR?crid=1M0NHQ62R9FCQ&keywords=Warhammer%3A+Vermintide+2&qid=1683680140&sprefix=warhammer+vermintide+2%2Caps%2C632&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=5f7e6b814df80c1e8eadf8b50d57d57f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warhammer',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '82%',
        genre: 'Action, Adventure? Horde thingy? Left4Dead-like?',
        console_platform: 'PC',
        developer: 'Fatshark',
        backloggd_link: '',
    },
    '190': {
        content: '',
        submitted_by: '',
        answers: ["Grim Fandango"],
        affiliate_links: ["https://www.g2a.com/grim-fandango-remastered-steam-key-global-i10000000435004?gtag=391e4ef696", "https://www.amazon.com/Grim-Fandango-Remastered-Nintendo-Switch/dp/B0B1H82Q66?crid=1GUQSA2K8H4EG&keywords=Grim+Fandango&qid=1683680007&sprefix=grim+fandango%2Caps%2C490&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=3baad3f8d0a4cfec209a67652ac17556&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Grim Fandango',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '94%',
        genre: 'Adventure',
        console_platform: 'PC',
        developer: 'LucasArts',
        backloggd_link: '',
    },
    '191': {
        content: '',
        submitted_by: '',
        answers: ["Titanfall 2"],
        affiliate_links: ["https://www.g2a.com/titanfall-2-ultimate-edition-pc-steam-gift-global-i10000084543008?gtag=391e4ef696", "https://www.amazon.com/Titanfall-Ultimate-Xbox-Digital-Code/dp/B074TFGYLW?crid=2E66EK7YDZXM&keywords=Titanfall+2&qid=1683679786&sprefix=titanfall+2%2Caps%2C346&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=17e7b262c51b7ba413c645948f803c84&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Titanfall',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '89%',
        genre: 'Shooter',
        console_platform: 'PC, PS4, XONE',
        developer: 'Respawn Entertainment',
        backloggd_link: '',
    },
    '192': {
        content: '',
        submitted_by: '',
        answers: ["Subnautica"],
        affiliate_links: ["https://www.g2a.com/subnautica-ultimate-collection-pc-steam-account-global-i10000015252019?gtag=391e4ef696", "https://www.amazon.com/Subnautica-Below-Zero-Double-Nintendo-Switch/dp/B08VFRVYT8?crid=1LY0MAL55ILRS&keywords=Subnautica&qid=1683679407&sprefix=subnautica%2Caps%2C332&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=ae84744cc447b1ea4ce799d09daa6806&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Subnautica',
        twitter_ids: [],
        release_year: '2014 (early access), 2018 (full release)',
        metacritic_score: '87%',
        genre: 'Exploration? Horror-ish? Adventure?',
        console_platform: 'PC',
        developer: 'Unknown Worlds Entertainment',
        backloggd_link: '',
    },
    '193': {
        content: '',
        submitted_by: '',
        answers: ["Xenoblade Chronicles 3"],
        affiliate_links: ["https://www.g2a.com/xenoblade-chronicles-3-nintendo-switch-nintendo-eshop-key-united-states-i10000326297001?gtag=391e4ef696", "https://www.amazon.com/Xenoblade-Chronicles-Expansion-Pass-Standard/dp/B0B9259PXG?crid=32LKGEQK7V3WC&keywords=Xenoblade+Chronicles+3&qid=1683679289&sprefix=xenoblade+chronicles+3%2Caps%2C357&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=42574c99aa87a30f8ed1557141466f18&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Xenoblade',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '89%',
        genre: 'Action RPG/JRPG',
        console_platform: 'Switch',
        developer: 'Monolith Soft',
        backloggd_link: '',
    },
    '194': {
        content: '',
        submitted_by: '',
        answers: ["World of Goo"],
        affiliate_links: ["https://www.g2a.com/world-of-goo-steam-gift-global-i10000002374001?gtag=391e4ef696", "https://www.amazon.com/Super-Rare-Games-SRG-27-Switch/dp/B089YW3F68?keywords=World+of+Goo&qid=1683679193&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=859081f83d95de42a012975d1bbeec64&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '90%',
        genre: 'Puzzle',
        console_platform: 'PC, Wii',
        developer: '2D Boy',
        backloggd_link: '',
    },
    '195': {
        content: '',
        submitted_by: '',
        answers: ["Kentucky Route Zero"],
        affiliate_links: ["https://www.g2a.com/kentucky-route-zero-steam-key-global-i10000045044002?gtag=391e4ef696", "https://www.amazon.com/Kentucky-Route-Zero-Switch-Digital/dp/B0848GDZ2S?crid=206I6OR3JWJNF&keywords=kentucky+route+zero+switch&qid=1683678859&sprefix=Kentucky+Route+Zero%2Caps%2C347&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=34e942f549b2f4e0068563d2fa24da68&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '86%',
        genre: 'Adventure',
        console_platform: 'PC',
        developer: 'Cardboard Computer',
        backloggd_link: '',
    },
    '196': {
        content: '',
        submitted_by: '',
        answers: ["Blasphemous"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Blasphemous%20%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fblasphemous-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/blasphemous-digital-deluxe-edition-steam-key-global-i10000190512004?gtag=391e4ef696", "https://www.amazon.com/Limited-Run-304-Blasphemous-PlayStation-4/dp/B08F38K9WX?crid=JJW8XC8N7Z55&keywords=Blasphemous&qid=1683678643&sprefix=blasphemous%2Caps%2C383&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=830f78da89fe8a922c59b5d799e954b9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Blasphemous',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '78',
        genre: 'Metroidvania/Platformer',
        console_platform: 'PC, Switch, PS4, XBOX ONE',
        developer: 'The Game Kitchen',
        backloggd_link: '',
    },
    '197': {
        content: '',
        submitted_by: '',
        answers: ["Life is Strange"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Life%20is%20Strange%20Complete%20Season%20Episodes%2015%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Flife-is-strange-complete-season-episodes-1-5%2F&intsrc=PUI2_9608", "https://www.g2a.com/life-is-strange-complete-season-episodes-1-5-steam-key-global-i10000025993009?gtag=391e4ef696", "https://www.amazon.com/Life-Strange-PlayStation-4/dp/B00SRZH210?crid=LHU55W031MT7&keywords=Life%2Bis%2BStrange%2Bcomplete%2Bedition&qid=1683678495&sprefix=life%2Bis%2Bstrange%2Bcomplete%2Bedition%2Caps%2C329&sr=8-5&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=1158f09f9df9955a6b15cbfa439d8418&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Life is Strange',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '83%',
        genre: 'Narrative Adventure',
        console_platform: 'PC, PS3, PS4, X360, XONE',
        developer: 'Dontnod Entertainment',
        backloggd_link: '',
    },
    '198': {
        content: '',
        submitted_by: '',
        answers: ["Getting Over It with Bennett Foddy"],
        affiliate_links: ["https://www.g2a.com/getting-over-it-with-bennett-foddy-steam-pc-gift-global-i10000084088002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: 'No metacritic score for some reason!',
        genre: '... platformer?',
        console_platform: 'PC',
        developer: 'Bennett Foddy',
        backloggd_link: '',
    },
    '199': {
        content: '',
        submitted_by: '',
        answers: ["Neon White"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Neon%20White%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fneon-white-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/neon-white-pc-steam-gift-global-i10000326322001?gtag=391e4ef696", "https://www.amazon.com/Neon-White-Standard-Nintendo-Digital/dp/B0B5RBPZFN?crid=3BMYWO8EKCZ21&keywords=neon+white+game&qid=1683677949&sprefix=Neon+White+%2Caps%2C334&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=2a20d1f76ba6c21c781a715afcd0f011&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '90%',
        genre: 'Shooter/Platformer',
        console_platform: 'PC, Switch',
        developer: 'Angel Matrix',
        backloggd_link: '',
    },
    '200': {
        content: '',
        submitted_by: '',
        answers: ["Mega Man 3"],
        affiliate_links: ["https://www.amazon.com/Mega-Man-Renewed-nintendo-entertainment-system/dp/B07P15YCCW?crid=G7W4G3ZXX1KB&keywords=Mega+Man+3&qid=1683677534&sprefix=mega+man+3%2Caps%2C365&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=989cb19729d32ca0e34c5062cd05e865&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mega Man',
        twitter_ids: [],
        release_year: '1990',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Side scroller / Platformer',
        console_platform: 'NES',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '201': {
        content: '',
        submitted_by: '',
        answers: ["Vampire Survivors"],
        affiliate_links: ["https://www.g2a.com/vampire-survivors-pc-steam-gift-global-i10000302266001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '86%',
        genre: 'Roguelike / Shoot \'Em Up',
        console_platform: 'PC',
        developer: 'Luca Galante',
        backloggd_link: '',
    },
    '202': {
        content: '',
        submitted_by: '',
        answers: ["Middle-earth: Shadow of Mordor"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Middleearth%20Shadow%20of%20Mordor%20%20Season%20Pass%20%281%29%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmiddle-earth-shadow-of-mordor-and-season-pass-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/middle-earth-shadow-of-mordor-game-of-the-year-edition-xbox-live-xbox-one-key-global-i10000005387004?gtag=391e4ef696", "https://www.amazon.com/Middle-Earth-Shadow-Mordor-Game-PlayStation/dp/B00WOK8YX4?crid=3ALVTGWVWFDPM&keywords=Middle-earth%3A+Shadow+of+Mordor&qid=1683677197&sprefix=middle-earth+shadow+of+mordor%2Caps%2C327&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=6557dcadb157453f0eab88501cdbeed0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Lord of the Rings',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '84%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Monolith Productions',
        backloggd_link: '',
    },
    '203': {
        content: '',
        submitted_by: '',
        answers: ["50 Cent: Blood on the Sand"],
        affiliate_links: ["https://www.amazon.com/50-Cent-Blood-Renewed-Xbox-360/dp/B082P6W6LH?crid=10IL56BYCPQ0E&keywords=50+cent+blood+on+the+sand+game&qid=1683676795&sprefix=50+Cent%3A+Blood+on+the+Sand%2Caps%2C303&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=e9fc7f224848b5d8c3d57727713b5a6f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '71%',
        genre: 'Shooter',
        console_platform: 'PS3, X360',
        developer: 'Swordfish Studios',
        backloggd_link: '',
    },
    '204': {
        content: '',
        submitted_by: '',
        answers: ["Enter the Gungeon"],
        affiliate_links: ["https://www.g2a.com/enter-the-gungeon-steam-gift-global-i10000013653003?gtag=391e4ef696", "https://www.amazon.com/Enter-Gungeon-Deluxe-Nintendo-Switch/dp/B07S226BXT?crid=1CPEITY79XV07&keywords=Enter+the+Gungeon&qid=1683676666&sprefix=enter+the+gungeon%2Caps%2C372&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=05ff5221c91dc126a66cec64bb00a68a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '84%',
        genre: 'Bullet-hell, Roguelike',
        console_platform: 'PC, PS4',
        developer: 'Dodge Roll',
        backloggd_link: '',
    },
    '205': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: A Link to the Past"],
        affiliate_links: ["https://www.amazon.com/Legend-Zelda-Adventure-Game-Boy-Advance/dp/B07TTVGSM9?crid=1MOKI0PRBLWPZ&keywords=the+legend+of+zelda+a+link+to+the+past+game&qid=1683676606&sprefix=The+Legend+of+Zelda%3A+A+Link+to+the+Past%2Caps%2C305&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=6a74fec8e9460209e4be199c360a93f4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '1991 (JP), 1992 (Worldwide)',
        metacritic_score: 'Too old for metacritic until ported, then 95%!',
        genre: 'Action, Adventure',
        console_platform: 'SNES',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '206': {
        content: '',
        submitted_by: '',
        answers: ["Hypnospace Outlaw"],
        affiliate_links: ["https://www.g2a.com/hypnospace-outlaw-steam-key-global-i10000185862002?gtag=391e4ef696", "https://www.amazon.com/Hypnospace-Outlaw-Nintendo-Switch/dp/B09QRN7LXL?crid=3U32VPG5XZKFH&keywords=Hypnospace+Outlaw&qid=1683676411&sprefix=hypnospace+outlaw%2Caps%2C352&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=4f76efa80127c57b45af35c3d3171a88&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '83%',
        genre: 'Adventure, Point-and-Click',
        console_platform: 'PC',
        developer: 'Tendershoot',
        backloggd_link: '',
    },
    '207': {
        content: '',
        submitted_by: '',
        answers: ["Cuphead"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Cuphead%202020%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcuphead-xbox-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/cuphead-xbox-live-key-xbox-one-windows-10-global-i10000081315002?gtag=391e4ef696", "https://www.amazon.com/Cuphead-Nintendo-Switch-Region-Free/dp/B0BHTW4JRW?crid=2L2CXVHAQZHCW&keywords=Cuphead&qid=1683676239&sprefix=cuphead%2Caps%2C416&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=34bf03a0fef5941ff6d58b6ea60d734d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '88%',
        genre: 'Platformer',
        console_platform: 'PC, XONE',
        developer: 'Studio MDHR',
        backloggd_link: '',
    },
    '208': {
        content: '',
        submitted_by: '',
        answers: ["Path of Exile"],
        affiliate_links: ["https://www.amazon.com/Grinding-Gear-Games-Exile-Download/dp/B00I0A363A?crid=3LT1KN00RW7BA&keywords=path+of+exile+ps4&qid=1683593644&sprefix=Path+of+Exile%2Caps%2C330&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=b87e1d3ce8d43ab2d30bb44b934128ff&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Path of Exile',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '86%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'Grinding Gear Games',
        backloggd_link: '',
    },
    '209': {
        content: '',
        submitted_by: '',
        answers: ["Doom"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DOOM%20Classic%20Complete%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdoom-classic-complete%2F&intsrc=PUI2_9608", "https://www.g2a.com/doom-classic-complete-steam-key-global-i10000008945003?gtag=391e4ef696", "https://www.amazon.com/Doom-Collectors-PlayStation-4-video-game/dp/B01BG1O75U?crid=2DZY34THE9I7B&keywords=doom+collector%27s+edition&qid=1683593449&sprefix=Doom+classic+complete%2Caps%2C313&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=3080e99c195151facf01a8cd8c7b6abf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Doom',
        twitter_ids: [],
        release_year: '1993',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'id Software',
        backloggd_link: '',
    },
    '210': {
        content: '',
        submitted_by: '',
        answers: ["Ultimate Chicken Horse"],
        affiliate_links: ["https://www.g2a.com/ultimate-chicken-horse-steam-gift-global-i10000011794002?gtag=391e4ef696", "https://www.amazon.com/Ultimate-Chicken-Horse-Neigh-Versary-Nintendo-Switch/dp/B099YLVPVB?crid=1ZF20XDT00AAC&keywords=Ultimate+Chicken+Horse&qid=1683592978&sprefix=catherine%2Caps%2C517&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=bcbf3f9690c166c4d0c66efc44abfc83&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '80%',
        genre: 'Platformer',
        console_platform: 'PC',
        developer: 'Clever Endeavour Games',
        backloggd_link: '',
    },
    '211': {
        content: '',
        submitted_by: '',
        answers: ["Catherine"],
        affiliate_links: ["https://www.g2a.com/catherine-classic-steam-gift-global-i10000179257001?gtag=391e4ef696", "https://www.amazon.com/Catherine-Full-Body-Standard-PlayStation-4/dp/B07NPJ5FVM?crid=3RYDOMDTWD5UW&keywords=Catherine&qid=1683592872&sprefix=ape+escape%2Caps%2C410&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=ffdc315f4bc1df44215641e86db55d39&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Catherine',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '79%',
        genre: 'Action, Adventure, Puzzle (It\'s a weird one)',
        console_platform: 'PS3, X360',
        developer: 'Atlus',
        backloggd_link: '',
    },
    '212': {
        content: '',
        submitted_by: '',
        answers: ["Ape Escape"],
        affiliate_links: ["https://www.amazon.com/Ape-Escape-Renewed-playstation/dp/B07ZN5P8R2?crid=2TUUVAZSWLX6F&keywords=Ape+Escape&qid=1683592800&sprefix=ape+escape%2Caps%2C404&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=0f3deceef8f0f952303997e7df16ef61&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ape Escape',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '90%',
        genre: 'Action, Adventure, 3D Platformer',
        console_platform: 'PS1',
        developer: 'Japan Studio',
        backloggd_link: '',
    },
    '213': {
        content: '',
        submitted_by: '',
        answers: ["Minesweeper"],
        affiliate_links: ["https://www.amazon.com/MaxStudio1Pro-Minesweeper/dp/B079KWTDC3?crid=23E4F1GD7LMSS&keywords=Minesweeper&qid=1683592720&sprefix=minesweeper%2Caps%2C397&sr=8-1&linkCode=ll2&tag=guessthegam06-20&linkId=9a588956c55cbedf4703c73f1da42d56&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '1989',
        metacritic_score: 'It\'s the perfect video game',
        genre: 'Puzzle',
        console_platform: 'PC',
        developer: 'Sort of unclear? Read the wikipedia page 😅',
        backloggd_link: '',
    },
    '214': {
        content: '',
        submitted_by: '',
        answers: ["Rise of the Tomb Raider"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Rise%20of%20the%20Tomb%20Raider%2020%20Year%20Celebration%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frise-of-the-tomb-raider-20-year-celebration%2F&intsrc=PUI2_9608", "https://www.g2a.com/rise-of-the-tomb-raider-20-years-celebration-ps4-psn-account-global-i10000028967018?aid=13387287?gtag=391e4ef696", "https://www.amazon.com/Rise-Tomb-Raider-Collectors-Xbox-One/dp/B01C7XQ0J2?crid=1LPPGVYOQXS1E&keywords=Rise+of+the+Tomb+Raider&qid=1683591784&sprefix=rise+of+the+tomb+raider%2Caps%2C361&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=db8c731bbe9413f9b274bcd23397a0e7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Tomb Raider',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '86%',
        genre: 'Action, Adventure',
        console_platform: 'X360, XONE',
        developer: 'Crystal Dynamics',
        backloggd_link: '',
    },
    '215': {
        content: '',
        submitted_by: '',
        answers: ["Max Payne"],
        affiliate_links: ["https://www.amazon.com/Max-Payne-Xbox-Renewed/dp/B07X61DGZT?crid=945SWJQ6QEE9&keywords=max+payne+game&qid=1683591946&sprefix=max+payne+game%2Caps%2C340&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=118b6df0e3539eac2527802f5ad33d37&language=en_US&ref_=as_li_ss_tl", "https://www.g2a.com/max-payne-complete-steam-key-global-i10000047489004?gtag=391e4ef696", "https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Max%20Payne%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmax-payne%2F&intsrc=PUI2_9608"],
        franchise: 'Max Payne',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '89%',
        genre: 'Shooter/Action',
        console_platform: 'PC',
        developer: 'Remedy Entertainment',
        backloggd_link: '',
    },
    '216': {
        content: '',
        submitted_by: '',
        answers: ["Metroid Fusion"],
        affiliate_links: ["https://www.amazon.com/Metroid-Fusion-Renewed-Game-Boy-Advance/dp/B07VL5XD55?crid=YM08JTMWZJ4D&keywords=Metroid+Fusion&qid=1683591511&sprefix=metroid+fusion%2Caps%2C1784&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=aac04057865e24b4c928bcfc034e6fe8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metroid',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '92%',
        genre: 'Action, Adventure, Platformer',
        console_platform: 'GBA',
        developer: 'Nintendo R&D1',
        backloggd_link: '',
    },
    '217': {
        content: '',
        submitted_by: '',
        answers: ["Hyper Light Drifter"],
        affiliate_links: ["https://www.g2a.com/hyper-light-drifter-steam-gift-global-i10000013649003?gtag=391e4ef696", "https://www.amazon.com/Hyper-Light-Drifter-Special-Nintendo/dp/B08XNMDVFW?crid=2D493IKMMOFRX&keywords=Hyper+Light+Drifter&qid=1683591431&sprefix=hyper+light+drifter%2Caps%2C342&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=6a7f51be4e811c7daec7ec4816eb782d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '88%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'Heart Machine',
        backloggd_link: '',
    },
    '218': {
        content: '',
        submitted_by: '',
        answers: ["Crusader Kings II", "Crusader Kings 2"],
        affiliate_links: ["https://www.g2a.com/crusader-kings-ii-imperial-collection-steam-key-global-i10000044271001?aid=13439032?gtag=391e4ef696"],
        franchise: 'Crusader Kings',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '82%',
        genre: 'Strategy',
        console_platform: 'PC',
        developer: 'Paradox Development Studio',
        backloggd_link: '',
    },
    '219': {
        content: '',
        submitted_by: '',
        answers: ["Watch Dogs"],
        affiliate_links: ["https://www.g2a.com/watch-dogs-complete-ubisoft-connect-key-global-i10000008941005?aid=13307911?gtag=391e4ef696", "https://www.amazon.com/Watch-Dogs-Complete-PS4-playstation-4/dp/B01HOJ6VNO?crid=OZFSHA758WOX&keywords=Watch+Dogs+complete&qid=1683591062&sprefix=watch+dogs+complete%2Caps%2C478&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=d03c7c55c3b9575abd3cea7e6cdc95d8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Watch Dogs',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '80%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, PS4, X360, XONE, Wii U',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '220': {
        content: '',
        submitted_by: '',
        answers: ["Counter-Strike: Source"],
        affiliate_links: ["https://www.g2a.com/counter-strike-source-steam-gift-global-i10000003735002?gtag=391e4ef696", "https://www.amazon.com/Counter-Strike-Source-PC/dp/B000AOJ7FK?crid=OEKAU5AWTRXP&keywords=Counter-Strike%3A+Source&qid=1683513421&s=videogames&sprefix=counter-strike+source%2Cvideogames%2C446&sr=1-5&linkCode=ll1&tag=guessthegam06-20&linkId=30b2eb41f62a62100c71c7684ef361b1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Counter-Strike',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '88%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Valve & Turtle Rock Studios',
        backloggd_link: '',
    },
    '221': {
        content: '',
        submitted_by: '',
        answers: ["Dig Dug"],
        affiliate_links: ["https://www.g2a.com/arcade-game-series-dig-dug-xbox-live-xbox-one-key-europe-i10000178923001?gtag=391e4ef696", "https://www.amazon.com/Dig-Dug-atari-5200/dp/B000F0XX0Y?crid=1OO0XO911JE77&keywords=Dig+Dug&qid=1683513234&s=videogames&sprefix=dig+dug%2Cvideogames%2C532&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=9bbfd1b1c49dd139d7966fd32887d3f8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '1982',
        metacritic_score: 'Too old for Metacritic!',
        genre: 'Arcade, Action, Puzzle, Maze game',
        console_platform: 'Arcade',
        developer: 'Namco',
        backloggd_link: '',
    },
    '222': {
        content: '',
        submitted_by: '',
        answers: ["Splatoon 2"],
        affiliate_links: ["https://www.g2a.com/splatoon-2-octo-expansion-nintendo-switch-nintendo-eshop-key-europe-i10000192827001?gtag=391e4ef696", "https://www.amazon.com/Splatoon-Nintendo-Switch-Digital-Code/dp/B073J2Y9NR?crid=PEQG7B0AWL8U&keywords=Splatoon+2&qid=1683513047&s=videogames&sprefix=splatoon+2%2Cvideogames%2C421&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=21393552288d7fc8b273da8c10b5ea13&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Splatoon',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '83%',
        genre: 'Shooter',
        console_platform: 'Switch',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '223': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario Odyssey"],
        affiliate_links: ["https://www.g2a.com/super-mario-odyssey-nintendo-switch-nintendo-eshop-key-north-america-i10000142048002?gtag=391e4ef696", "https://www.amazon.com/Super-Mario-Odyssey-Nintendo-Switch/dp/B01MY7GHKJ?crid=2NKSHX8VSGWEU&keywords=Super%2BMario%2BOdyssey&qid=1683512937&s=videogames&sprefix=super%2Bmario%2Bodyssey%2Cvideogames%2C408&sr=1-3&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=dc6804894cb925fd6ed4e85143e93fa2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '97%',
        genre: 'Action/Platformer',
        console_platform: 'Switch',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '224': {
        content: '',
        submitted_by: '',
        answers: ["Valorant"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '80%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Riot Games',
        backloggd_link: '',
    },
    '225': {
        content: '',
        submitted_by: '',
        answers: ["Noita"],
        affiliate_links: ["https://www.g2a.com/noita-steam-gift-global-i10000190811001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '76%',
        genre: 'Roguelite, Action, Adventure',
        console_platform: 'PC',
        developer: 'Nolla Games',
        backloggd_link: '',
    },
    '226': {
        content: '',
        submitted_by: '',
        answers: ["Spec Ops: The Line"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Spec%20Ops%20The%20Line%20Overflow%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fspec-ops-the-line%2F&intsrc=PUI2_9608", "https://www.g2a.com/spec-ops-the-line-steam-key-global-i10000003372008?gtag=391e4ef696", "https://www.amazon.com/Spec-Ops-Line-Playstation-3/dp/B00320JAWY?crid=BD3JXGQGROM6&keywords=Spec+Ops%3A+The+Line&qid=1683512604&s=videogames&sprefix=spec+ops+the+line%2Cvideogames%2C474&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=0bb2d3fba5bea4dfbd1546533e600726&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spec Ops',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '76%',
        genre: 'Shooter',
        console_platform: 'PC, PS3, X360',
        developer: 'Yager Development',
        backloggd_link: '',
    },
    '227': {
        content: '',
        submitted_by: '',
        answers: ["For Honor"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=For%20Honor%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffor-honor-complete-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/for-honor-complete-edition-xbox-live-xbox-one-key-global-i10000002186050?gtag=391e4ef696", "https://www.amazon.com/Honor-Complete-Xbox-Digital-Code/dp/B07MNBNRWZ?crid=4007H1L2DGVO&keywords=For+Honor&qid=1683512447&s=videogames&sprefix=for+honor%2Cvideogames%2C385&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=7fabf34bebb497e89a4bf969f342e912&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'For Honor',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '78%',
        genre: 'Action',
        console_platform: 'PC, PS4, XONE',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '228': {
        content: 'Rainbow Six Siege is a tactical shooter game developed and published by Ubisoft. It requires strategic planning and careful execution of plans in order to succeed. The game focuses on multiplayer gameplay, where each player is a member of a specialized counter-terrorism unit. The game\'s uniqueness lies in its destructible environments, which can be used to your advantage, and the various operators that each have their own special abilities and gadgets. One of the most significant differences between Rainbow Six Siege and other first-person shooter games is the emphasis on teamwork and communication. In order to be successful, players must work together to defend, attack, and accomplish goals. The game allows for a high degree of customization, allowing players to adjust their playstyle and operator selection based on the team\'s needs and objectives. The destructible environment in Rainbow Six Siege is a significant selling point, allowing players to breach walls, barricades, and floors to create new ways to navigate the map or eliminate enemies. This feature adds a layer of depth to the gameplay that is not offered in many other games. Another unique aspect of Rainbow Six Siege is the use of operator abilities, which provide players with a variety of strategies and solutions to complete objectives. With its emphasis on teamwork, communication, destructible environments, and diverse operators, it offers a fresh take on the genre. If you\'re interested in trying something new and different, Rainbow Six Siege is definitely worth a shot.',
        submitted_by: 'https://instagram.com/roypara7',
        answers: ["Tom Clancy's Rainbow Six Siege", "Rainbow Six Siege"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tom%20Clancys%20Rainbow%20Six%20Siege%20Ultimate%20Edition%20Year%208%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftom-clancys-rainbow-six-siege-ultimate-edition-year-8-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tom-clancys-rainbow-six-siege-ultimate-edition-xbox-one-series-x-s-xbox-live-key-global-i10000182721042?gtag=391e4ef696", "https://www.amazon.com/Tom-Clancys-Rainbow-Siege-Ultimate/dp/B08YPP6M58?crid=1EWMKI3EO2CT9&keywords=Tom+Clancy%27s+Rainbow+Six+Siege&qid=1683512059&s=videogames&sprefix=tom+clancy%27s+rainbow+six+siege%2Cvideogames%2C412&sr=1-12&linkCode=ll1&tag=guessthegam06-20&linkId=8698c69865bf20eb75babe1680132d88&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Tom Clancy',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '79%',
        genre: 'Shooter',
        console_platform: 'PC, PS4, XONE',
        developer: 'Ubisoft Games',
        backloggd_link: '',
    },
    '229': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars Jedi: Fallen Order"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Star%20Wars%20Jedi%20Fallen%20Order%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstar-wars-jedi-fallen-order-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/star-wars-jedi-fallen-order-deluxe-edition-steam-key-global-i10000187223021?gtag=391e4ef696", "https://www.amazon.com/Star-Wars-Jedi-Fallen-Order/dp/B0B4NSZ41G?crid=1MDI19XQZ6U1Q&keywords=Star+Wars+Jedi%3A+Fallen+Order&qid=1683511937&s=videogames&sprefix=star+wars+jedi+fallen+order%2Cvideogames%2C481&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=49e08f049103fd0c8864ce65542351d8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Star Wars',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '79%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Respawn Entertainment',
        backloggd_link: '',
    },
    '230': {
        content: '',
        submitted_by: '',
        answers: ["Into The Breach"],
        affiliate_links: ["https://www.g2a.com/into-the-breach-steam-gift-global-i10000145015002?gtag=391e4ef696", "https://www.amazon.com/Fangamer-Into-the-Breach-Switch/dp/B0B8241TB7?crid=DO1IQGATXLPU&keywords=Into+The+Breach&qid=1683511856&s=videogames&sprefix=into+the+breach%2Cvideogames%2C464&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=7ab5d9666e23db295030d9bc3a17c0ef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '90%',
        genre: 'Strategy',
        console_platform: 'PC, Switch',
        developer: 'Subset Games',
        backloggd_link: '',
    },
    '231': {
        content: '',
        submitted_by: '',
        answers: ["Mirror's Edge"],
        affiliate_links: ["https://www.g2a.com/mirrors-edge-steam-gift-global-i10000007662002?gtag=391e4ef696", "https://www.amazon.com/Mirrors-Edge-Xbox-360-Renewed/dp/B07XWNBYDW?crid=2KCHRDIRWD70B&keywords=mirror%27s+edge+ps4&qid=1683511795&s=videogames&sprefix=Mirror%27s+Edge%2Cvideogames%2C387&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=0fe49b4cc266f9c3938b9bcef6ace658&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mirrors Edge',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '79%',
        genre: 'Action, Adventure',
        console_platform: 'X360, PS3',
        developer: 'DICE',
        backloggd_link: '',
    },
    '232': {
        content: '',
        submitted_by: '',
        answers: ["Sonic Frontiers"],
        affiliate_links: ["https://www.g2a.com/sonic-frontiers-digital-deluxe-edition-pc-steam-key-global-i10000336725005?gtag=391e4ef696", "https://www.amazon.com/Sonic-Frontiers-Standard-Nintendo-Digital/dp/B0BQ1JJT8V?crid=UM5PK78C10EI&keywords=Sonic+Frontiers&qid=1683511431&sprefix=darksiders+complete%2Caps%2C703&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=4e0ad6527aaa98a54006aeeb3ff42954&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sonic The Hedgehog',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '71%',
        genre: 'Action, Adventure',
        console_platform: 'Switch, PS4, PS5, XONE, Xbox Series',
        developer: 'Sonic Team',
        backloggd_link: '',
    },
    '233': {
        content: '',
        submitted_by: '',
        answers: ["Darksiders"],
        affiliate_links: ["https://www.g2a.com/darksiders-complete-steam-key-global-i10000005728002?gtag=391e4ef696", "https://www.amazon.com/Darksiders-Complete-II-PC/dp/B0165T2OQC?crid=298XWVD9Q8VEZ&keywords=Darksiders+complete&qid=1683511364&sprefix=darksiders+comple%2Caps%2C705&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=de256d54fdc7ee038db41d671c76f538&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Darksiders',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '83%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360',
        developer: 'Vigil Games',
        backloggd_link: '',
    },
    '234': {
        content: '',
        submitted_by: '',
        answers: ["Deus Ex"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Deus%20Ex%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdeus-ex-collection%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-deus-ex-collection-steam-key-global-i10000181521001?gtag=391e4ef696"],
        franchise: 'Deus Ex',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '90%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'Ion Storm',
        backloggd_link: '',
    },
    '235': {
        content: '',
        submitted_by: '',
        answers: ["Nuclear Throne"],
        affiliate_links: ["https://www.g2a.com/nuclear-throne-steam-key-global-i10000008621004?aid=13291810?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '89%',
        genre: 'Top-Down Shoot-\'Em-Up',
        console_platform: 'PC, PS4, PS Vita',
        developer: 'Vlambeer',
        backloggd_link: '',
    },
    '236': {
        content: '',
        submitted_by: '',
        answers: ["Command & Conquer: Red Alert 2"],
        affiliate_links: ["https://www.amazon.com/Command-Conquer-Red-Alert-2-pc/dp/B0009J1JIY?crid=1FNS3QW1XR45F&keywords=Command+%26+Conquer%3A+Red+Alert+2&qid=1683248189&sprefix=command+%26+conquer+red+alert+2%2Caps%2C394&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=a4fdbcd0573ffbfef5242adfeab7b8ca&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Command & Conquer',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '84%',
        genre: 'Strategy, Real-time Strategy',
        console_platform: 'PC',
        developer: 'Westwood Pacific',
        backloggd_link: '',
    },
    '237': {
        content: '',
        submitted_by: '',
        answers: ["Silent Hill 4: The Room", "Silent Hill 4"],
        affiliate_links: ["https://www.amazon.com/Silent-Hill-Room-PlayStation-Renewed-2/dp/B082MT3RNT?crid=2VZ2X5AEWS59I&keywords=Silent+Hill+4%3A+The+Room&qid=1683248136&sprefix=silent+hill+4+the+room%2Caps%2C529&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=446519516990519df418fb0c562e5502&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Silent Hill',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '67%',
        genre: 'Survival, Horror',
        console_platform: 'PC, PS2, Xbox',
        developer: 'Team Silent',
        backloggd_link: '',
    },
    '238': {
        content: '',
        submitted_by: '',
        answers: ["Tom Clancy's The Division 2", "The Division 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tom%20Clancys%20The%20Division%202%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftom-clancys-the-division-2-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/tom-clancys-the-division-2-pc-steam-gift-global-i10000146655066?gtag=391e4ef696", "https://www.amazon.com/Tom-Clancys-Division-Standard-PlayStation-4/dp/B0BQ3H753M?crid=28MRRBJAMBRSH&keywords=Tom+Clancy%27s+The+Division+2&qid=1683247872&sprefix=fallout+4%2Caps%2C684&sr=8-21&linkCode=ll1&tag=guessthegam06-20&linkId=370e626da9427b45ab41b1e65a7a25c0&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Division',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '84%',
        genre: 'Shooter',
        console_platform: 'PC, PS4, XONE',
        developer: 'Massive Entertainment',
        backloggd_link: '',
    },
    '239': {
        content: '',
        submitted_by: '',
        answers: ["Fallout 4"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fallout%204%20VR%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffallout-4-vr%2F&intsrc=PUI2_9608", "https://www.g2a.com/fallout-4-vr-pc-steam-key-global-i10000084145002?aid=13266511?gtag=391e4ef696", "https://www.amazon.com/Fallout-Game-Year-PlayStation-video-4/dp/B074NGD393?crid=3NAC24ILA7KY8&keywords=Fallout+4&qid=1683247691&sprefix=alan+wake%2Caps%2C545&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=1bb0a659c12b994fd4f91ee11040339a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Fallout',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '84%',
        genre: 'RPG',
        console_platform: 'PC, PS4, XONE',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '240': {
        content: '',
        submitted_by: '',
        answers: ["Alan Wake"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Alan%20Wake%20Collectors%20Bundle%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Falan-wake-collectors-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/alan-wake-remastered-xbox-series-x-s-xbox-live-key-europe-i10000270949003?gtag=391e4ef696", "https://www.amazon.com/Alan-Wake-Remastered-XBX-Xbox-One/dp/B09FMK3ND4?crid=2EGZMCQDRMK2M&keywords=Alan+Wake&qid=1683247488&sprefix=alan+wake%2Caps%2C377&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=35112c6d5f983a6c6cb47c1c083483ee&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Alan Wake',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '83%',
        genre: 'Action, Adventure/Thriller',
        console_platform: 'X360',
        developer: 'Remedy Entertainment',
        backloggd_link: '',
    },
    '241': {
        content: '',
        submitted_by: '',
        answers: ["Grand Theft Auto IV", "Grand Theft Auto 4"],
        affiliate_links: ["https://www.g2a.com/grand-theft-auto-iv-complete-edition-steam-gift-global-i10000010696002?gtag=391e4ef696", "https://www.amazon.com/Grand-Theft-Auto-IV-Complete-Xbox/dp/B078PQ9PWK?crid=1SZOMFER5A2RX&keywords=Grand+Theft+Auto+IV&qid=1683247161&sprefix=cyberpunk+2077%2Caps%2C662&sr=8-13&linkCode=ll1&tag=guessthegam06-20&linkId=0c4ee8c8e21e7b97e836262f28ba49c7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Grand Theft Auto',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '98%',
        genre: 'Action, Adventure, Modern Open World',
        console_platform: 'PS3, X360',
        developer: 'Rockstar North',
        backloggd_link: '',
    },
    '242': {
        content: '',
        submitted_by: '',
        answers: ["Cyberpunk 2077"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Cyberpunk%202077%20NEW%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcyberpunk-2077-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/cyberpunk-2077-pc-steam-gift-global-i10000156543004?gtag=391e4ef696", "https://www.amazon.com/Cyberpunk-2077-PlayStation-4/dp/B07DJWBYKP?crid=25EXVH36B2QXQ&keywords=Cyberpunk+2077&qid=1683246896&sprefix=warframe+game%2Caps%2C588&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=d23b20c259865038cb4a597637f15700&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Cyberpunk',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '86%',
        genre: 'RPG',
        console_platform: 'PC, PS4, XONE, Stadia',
        developer: 'CD Projekt Red',
        backloggd_link: '',
    },
    '243': {
        content: '',
        submitted_by: '',
        answers: ["Warframe"],
        affiliate_links: ["https://www.g2a.com/warframe-rage-pinnacle-pack-steam-key-global-i10000182893001?gtag=391e4ef696", "https://www.amazon.com/Warframe-Lightning-Pack-Online-Game/dp/B0188BXE96?crid=M8FVFTOGV64B&keywords=Warframe+game&qid=1683246573&sprefix=warframe+ga%2Caps%2C414&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=015f716d5193815625a45de32493d66b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warframe',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '69%',
        genre: 'Sci-Fi Shooter',
        console_platform: 'PC',
        developer: 'Digital Extremes',
        backloggd_link: '',
    },
    '244': {
        content: '',
        submitted_by: '',
        answers: ["Need For Speed: Most Wanted"],
        affiliate_links: ["https://www.g2a.com/need-for-speed-most-wanted-pc-steam-gift-global-i10000043499004?gtag=391e4ef696", "https://www.amazon.com/Collection-Underground-Wanted-Renewed-PlayStation-2/dp/B08CJ3SYPG?crid=2UNHWDFSGBWY2&keywords=Need+For+Speed%3A+Most+Wanted&qid=1683246298&sprefix=need+for+speed+most+wanted%2Caps%2C437&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=f3b5524a8fafbe7dd1849874eb2d55e9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Need for Speed',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '82%',
        genre: 'Racing',
        console_platform: 'PC, PS2, Xbox, GameCube, DS, GBA, X360',
        developer: 'EA Canada & EA Black Box',
        backloggd_link: '',
    },
    '245': {
        content: '',
        submitted_by: '',
        answers: ["Beat Saber"],
        affiliate_links: ["https://www.g2a.com/beat-saber-pc-steam-key-global-i10000155137001?gtag=391e4ef696", "https://www.amazon.com/PlayStaion-VR-Beat-Saber-Full/dp/B07V5K2P5P?crid=1X0D2GHOXW3LD&keywords=beat+saber+ps4&qid=1683246004&sprefix=beat+saber+%2Caps%2C716&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=337ad60a241a921089ea4dcae99fd84a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '93%',
        genre: 'Rhythm',
        console_platform: 'PC, PS4',
        developer: 'Beat Games',
        backloggd_link: '',
    },
    '246': {
        content: '',
        submitted_by: '',
        answers: ["Monument Valley"],
        affiliate_links: ["https://www.g2a.com/monument-valley-panoramic-edition-pc-steam-key-global-i10000338940001?gtag=391e4ef696"],
        franchise: 'Monument Valley',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '89%',
        genre: 'Puzzle',
        console_platform: 'iOS (later ported elsewhere)',
        developer: 'Ustwo Games',
        backloggd_link: '',
    },
    '247': {
        content: '',
        submitted_by: '',
        answers: ["Soulcalibur"],
        affiliate_links: [],
        franchise: 'SoulCalibur',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: 'Original release too old for metacritic!',
        genre: 'Fighting',
        console_platform: 'Arcade (later Dreamcast)',
        developer: 'Project Soul',
        backloggd_link: '',
    },
    '248': {
        content: '',
        submitted_by: '',
        answers: ["Bugsnax"],
        affiliate_links: ["https://www.g2a.com/bugsnax-pc-steam-gift-global-i10000326078001?gtag=391e4ef696", "https://www.amazon.com/Bugsnax-Playstation-PS5-Physical-Version-5/dp/B09K3CQ7VF?crid=20ZFW4IPZHYPU&keywords=bugsnax+game&qid=1683244803&sprefix=bugsnax+gam%2Caps%2C567&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=71b35be1004cd0edf3b3f597aee042f5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '72%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, PS5',
        developer: 'Young Horses',
        backloggd_link: '',
    },
    '249': {
        content: '',
        submitted_by: '',
        answers: ["Satisfactory"],
        affiliate_links: ["https://www.g2a.com/satisfactory-pc-steam-gift-global-i10000186204004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019 (Early Access, and still is)',
        metacritic_score: 'Not Yet Scored (Early Access)',
        genre: 'Strategy, First Person, Open World',
        console_platform: 'PC',
        developer: 'Coffee Stain Studios',
        backloggd_link: '',
    },
    '250': {
        content: '',
        submitted_by: '',
        answers: ["No More Heroes"],
        affiliate_links: ["https://www.g2a.com/no-more-heroes-pc-steam-gift-global-i10000255727003?gtag=391e4ef696", "https://www.amazon.com/No-More-Heroes-Collectors-Limited-Nintendo/dp/B09GDZ1GP7?crid=J5NH5I2VYUM5&keywords=No+More+Heroes&qid=1683244536&s=videogames&sprefix=no+more+heroes%2Cvideogames%2C396&sr=1-5&linkCode=ll1&tag=guessthegam06-20&linkId=42f18d10ebcaaaa58ac06d39a917695b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'No More Heroes',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '83%',
        genre: 'Action, Adventure, Beat-\'Em-Up',
        console_platform: 'Wii',
        developer: 'Grasshopper Manufacture',
        backloggd_link: '',
    },
    '251': {
        content: '',
        submitted_by: '',
        answers: ["Diddy Kong Racing"],
        affiliate_links: ["https://www.amazon.com/Diddy-Kong-Racing-Renewed-nintendo-64/dp/B07WZG6TZB?crid=2LHU4PA4PQYUG&keywords=Diddy+Kong+Racing&qid=1683244466&s=videogames&sprefix=diddy+kong+racing%2Cvideogames%2C478&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=94e660a5ffe76c03296fcb16f148e774&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Diddy Kong',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '88%',
        genre: 'Racing',
        console_platform: 'N64',
        developer: 'Rare',
        backloggd_link: '',
    },
    '252': {
        content: '',
        submitted_by: '',
        answers: ["Slime Rancher"],
        affiliate_links: ["https://www.g2a.com/slime-rancher-steam-key-global-i10000008990006?aid=13233953?gtag=391e4ef696", "https://www.amazon.com/Slime-Rancher-Plortable-Switch-iOS/dp/B0B4BXDPLT?crid=3D3UVOBR1BEOJ&keywords=Slime+Rancher&qid=1683244350&s=videogames&sprefix=slime+rancher%2Cvideogames%2C431&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=e0117c969fa1f7731847069a45a18adf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '81%',
        genre: 'Action, Adventure',
        console_platform: 'PC, XONE',
        developer: 'Monomi Park',
        backloggd_link: '',
    },
    '253': {
        content: '',
        submitted_by: '',
        answers: ["MultiVersus"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '78%',
        genre: 'Fighting',
        console_platform: 'PC, PS4, PS5, XONE, Xbox Series',
        developer: 'Player First Games',
        backloggd_link: '',
    },
    '254': {
        content: '',
        submitted_by: '',
        answers: ["Superhot"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=SUPERHOT%20VR%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsuperhot-vr-1%2F&intsrc=PUI2_9608", "https://www.g2a.com/superhot-vr-pc-steam-gift-global-i10000072702002?gtag=391e4ef696", "https://www.amazon.com/Superhot-VR-PS4/dp/B079RL1GHG?keywords=Superhot&qid=1683243742&s=videogames&sr=1-6&linkCode=ll1&tag=guessthegam06-20&linkId=77218ba225ea7d81a39e769bef7486c1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Superhot',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '82%',
        genre: 'Shooter',
        console_platform: 'PC',
        developer: 'Superhot Team',
        backloggd_link: '',
    },
    '255': {
        content: '',
        submitted_by: '',
        answers: ["Brutal Legend"],
        affiliate_links: ["https://www.g2a.com/brutal-legend-steam-gift-global-i10000000357001?gtag=391e4ef696", "https://www.amazon.com/Brutal-Legend-Xbox-360/dp/B001AVXZBO?crid=2R470Z954CWVL&keywords=Brutal+Legend&qid=1683243602&s=videogames&sprefix=brutal+legend%2Cvideogames%2C804&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=ebdf5e4b60a605d080f70db9704d4e81&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '82%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360',
        developer: 'Double Fine Productions',
        backloggd_link: '',
    },
    '256': {
        content: '',
        submitted_by: '',
        answers: ["Mortal Kombat 11"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Mortal%20Kombat%2011%20Ultimate%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmortal-kombat-11-ultimate-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/mortal-kombat-11-ultimate-edition-pc-steam-key-global-i10000176931057?gtag=391e4ef696", "https://www.amazon.com/Mortal-Kombat-11-Ultimate-PlayStation-5/dp/B09617F8ZJ?crid=3C6HIGHDREBV2&keywords=Mortal+Kombat+11&qid=1683243075&s=videogames&sprefix=a+plague+tale+requiem+%2Cvideogames%2C696&sr=1-13&linkCode=ll1&tag=guessthegam06-20&linkId=0227904ed90abf3d5f62c68f74468f9d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mortal Kombat',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '82%',
        genre: 'Fighting',
        console_platform: 'PC, PS4, XONE, Switch',
        developer: 'NetherRealm Studios',
        backloggd_link: '',
    },
    '257': {
        content: '',
        submitted_by: '',
        answers: ["A Plague Tale: Requiem"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=A%20Plague%20Tale%20Requiem%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fa-plague-tale-requiem-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/a-plague-tale-requiem-pc-steam-gift-global-i10000256586004?gtag=391e4ef696", "https://www.amazon.com/Plague-Tale-Requiem-Xbox-Digital/dp/B0BHTZZQNB?crid=3SPCPVI4MNC3B&keywords=A+Plague+Tale%3A+Requiem&qid=1683242650&s=videogames&sprefix=a+plague+tale+requiem%2Cvideogames%2C389&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=10c35118b8a7b030ceec03d54dbb1cce&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'A Plague Tale',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '82%',
        genre: 'Action, Adventure, Survival, Horror, Stealth',
        console_platform: 'PC, Switch, Xbox Series, PS5',
        developer: 'Asobo Studio',
        backloggd_link: '',
    },
    '258': {
        content: '',
        submitted_by: '',
        answers: ["Valheim"],
        affiliate_links: ["https://www.g2a.com/valheim-pc-steam-key-global-i10000236813001?aid=13356966?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021 (Early Access)',
        metacritic_score: 'Not Yet Scored (Early Access)',
        genre: 'Survival, Action, Viking Sim',
        console_platform: 'PC',
        developer: 'Iron Gate Studio',
        backloggd_link: '',
    },
    '259': {
        content: '',
        submitted_by: '',
        answers: ["Warcraft III: Reign of Chaos", "Warcraft 3"],
        affiliate_links: ["https://www.g2a.com/warcraft-3-reign-of-chaos-battlenet-key-global-i10000043507002?gtag=391e4ef696", "https://www.amazon.com/WarCraft-III-Special-Limited-Widescreen/dp/B001AVANG4?keywords=Warcraft+III%3A+Reign+of+Chaos&qid=1683242267&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=7e56bc74a801ca46d781d324a0b11280&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warcraft',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '92%',
        genre: 'Real-time Strategy',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '260': {
        content: '',
        submitted_by: '',
        answers: ["Sekiro: Shadows Die Twice"],
        affiliate_links: ["https://www.g2a.com/sekiro-shadows-die-twice-goty-edition-pc-steam-gift-global-i10000171455001?gtag=391e4ef696", "https://www.amazon.com/Sekiro-Shadows-Twice-Game-Year-pc/dp/B08F1Y6WB2?crid=3AW2S4HCUSBAY&keywords=Sekiro%3A+Shadows+Die+Twice&qid=1683162237&sprefix=ghost+trick+phantom+detective%2Caps%2C475&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=0c4bde1e35595358eec45fed6d399de8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sekiro',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '88%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'FromSoftware',
        backloggd_link: '',
    },
    '261': {
        content: '',
        submitted_by: '',
        answers: ["Ghost Trick: Phantom Detective"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Ghost%20Trick%20Phantom%20Detective%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fghost-trick-phantom-detective-pc%2F&intsrc=PUI2_9608", "https://www.amazon.com/Ghost-Trick-Phantom-Detective-Nintendo-DS/dp/B002TDIEDG?crid=2UTXHRI5GH98W&keywords=Ghost+Trick%3A+Phantom+Detective&qid=1683162148&sprefix=ghost+trick+phantom+detective%2Caps%2C468&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c5bc15e49d34ec7e69dea341bf0b1e5d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2010 (JP), 2011 (US)',
        metacritic_score: '83%',
        genre: 'Adventure, Puzzle',
        console_platform: 'DS',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '262': {
        content: '',
        submitted_by: '',
        answers: ["Horizon Forbidden West"],
        affiliate_links: ["https://www.g2a.com/horizon-forbidden-west-ps5-psn-key-united-states-i10000302524001?gtag=391e4ef696", "https://www.amazon.com/Horizon-Forbidden-West-PlayStation-5/dp/B09FDCK9TG?crid=1Z3KNR6ZJD8ST&keywords=Horizon+Forbidden+West&qid=1683161893&sprefix=horizon+forbidden+west%2Caps%2C418&sr=8-16&linkCode=ll1&tag=guessthegam06-20&linkId=0a2705b4b397e9a67b1a4dab16efc735&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Horizon',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '88%',
        genre: 'Action, Adventure, RPG',
        console_platform: 'PS5, PS4',
        developer: 'Guerrilla Games',
        backloggd_link: '',
    },
    '263': {
        content: '',
        submitted_by: '',
        answers: ["Mafia"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Mafia%20Trilogy%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmafia-trilogy-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/mafia-trilogy-xbox-one-xbox-account-global-i10000195673016?aid=13400877?gtag=391e4ef696", "https://www.amazon.com/Mafia-Trilogy-Xbox-One/dp/B088X4SWK5?crid=3W4SRO67YMHHB&keywords=Mafia%3A+Trilogy&qid=1683101042&sprefix=mafia+trilogy%2Caps%2C431&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=6f21b4c0f539b8d3a9d56e60fbcfcb71&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mafia',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '88%',
        genre: 'Action, Adventure, Historic Open-World',
        console_platform: 'PC',
        developer: 'Illusion Softworks',
        backloggd_link: '',
    },
    '264': {
        content: '',
        submitted_by: '',
        answers: ["Bayonetta 2"],
        affiliate_links: ["https://www.amazon.com/Bayonetta-Physical-Game-Card-Digital-Download/dp/B077ZGRQM2?crid=2LMWZK8EBYDLC&keywords=Bayonetta+2&qid=1683100558&s=videogames&sprefix=bayonetta+2%2Cvideogames%2C305&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=b5248bc7bbb7855bd7df354847bfe958&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bayonetta',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '91%',
        genre: 'Action, Adventure',
        console_platform: 'Wii U',
        developer: 'PlatinumGames',
        backloggd_link: '',
    },
    '265': {
        content: '',
        submitted_by: '',
        answers: ["Wolfenstein 3D"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Wolfenstein%203D%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwolfenstein-3d%2F&intsrc=PUI2_9608", "https://www.g2a.com/wolfenstein-3d-steam-key-global-i10000048738002?gtag=391e4ef696", "https://www.amazon.com/Wolfenstein-3D-Game-Boy-Advance/dp/B00006409I?crid=2FQVUFZY2YLUI&keywords=Wolfenstein+3D&qid=1683100483&s=videogames&sprefix=wolfenstein+3d%2Cvideogames%2C300&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=505cab485999146e56a7be6533c17520&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Wolfenstein',
        twitter_ids: [],
        release_year: '1992',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Shooter',
        console_platform: 'DOS',
        developer: 'id Software',
        backloggd_link: '',
    },
    '266': {
        content: '',
        submitted_by: '',
        answers: ["Astroneer"],
        affiliate_links: ["https://www.g2a.com/astroneer-steam-gift-global-i10000031423004?gtag=391e4ef696", "https://www.amazon.com/Astroneer-PS4-playstation-4/dp/B07XDZ7JRB?crid=1K5Q5E3AV116M&keywords=Astroneer&qid=1683100278&sprefix=astroneer%2Caps%2C365&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=a0405d754ef03800542269d8efd89375&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '71%',
        genre: 'Action, Adventure, Survival',
        console_platform: 'PC, XONE',
        developer: 'System Era Softworks',
        backloggd_link: '',
    },
    '267': {
        content: '',
        submitted_by: '',
        answers: ["Spider-Man"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Marvels%20Spider%20Man%20Remastered%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmarvels-spider-man-remastered-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/marvels-spider-man-remastered-pc-steam-gift-global-i10000302546006?gtag=391e4ef696", "https://www.amazon.com/Marvels-Spider-Man-Game-Year-playstation-4/dp/B084S9GV11?crid=QENZIBNPEXBP&keywords=Spider-Man+Remastered&qid=1683100192&sprefix=spider-man+remastered%2Caps%2C314&sr=8-15&linkCode=ll1&tag=guessthegam06-20&linkId=07cf32df1876231f71e36f1c2b026a5c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spider-Man',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '87%',
        genre: 'Action, Adventure',
        console_platform: 'PS1',
        developer: 'Neversoft',
        backloggd_link: '',
    },
    '268': {
        content: '',
        submitted_by: '',
        answers: ["Pikmin"],
        affiliate_links: ["https://www.amazon.com/Pikmin-New-Play-Control-Nintendo-Wii/dp/B001CTL5GQ?crid=1WCQKB7D7SYIP&keywords=Pikmin&qid=1683100002&sprefix=donkey+kong+country%2Caps%2C336&sr=8-13&linkCode=ll1&tag=guessthegam06-20&linkId=f33413002f5bda4ac0fc21a0bbbd91fc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Pikmin',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '89%',
        genre: 'Strategy',
        console_platform: 'GameCube',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '269': {
        content: '',
        submitted_by: '',
        answers: ["Donkey Kong Country"],
        affiliate_links: ["https://www.amazon.com/Country-Renewed-Nintendo-Game-Boy-Advance/dp/B07X3MGR7Z?crid=2WBEKGHKFGWI1&keywords=Donkey+Kong+Country&qid=1683099875&sprefix=donkey+kong+country%2Caps%2C423&sr=8-13&linkCode=ll1&tag=guessthegam06-20&linkId=cc3a255107f207e9045ca804150eb6cb&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Donkey Kong',
        twitter_ids: [],
        release_year: '1994',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Side-scrolling Platformer',
        console_platform: 'SNES',
        developer: 'Rare',
        backloggd_link: '',
    },
    '270': {
        content: '',
        submitted_by: '',
        answers: ["Unrailed!"],
        affiliate_links: ["https://www.g2a.com/unrailed-pc-steam-key-global-i10000190515001?aid=13235434?gtag=391e4ef696", "https://www.amazon.com/Unrailed-Super-Rare-Games-49-Nintendo/dp/B093CSP836?keywords=Unrailed%21&qid=1683099755&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=d8d2b67c480eb826165e3aaa7fa53b35&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '77%',
        genre: 'Puzzle',
        console_platform: 'PC, PS4, PS5, XONE, Xbox Series S|X, Switch',
        developer: 'Indoor Astronaut',
        backloggd_link: '',
    },
    '271': {
        content: '',
        submitted_by: '',
        answers: ["NBA Jam"],
        affiliate_links: ["https://www.amazon.com/NBA-Jam-PS3/dp/B0055QC6D8?crid=P9NYXZCB099R&keywords=NBA+Jam&qid=1683075608&sprefix=nba+jam%2Caps%2C621&sr=8-12&linkCode=ll1&tag=guessthegam06-20&linkId=89be33d0e657cbea2388f0467f4abb7b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'NBA',
        twitter_ids: [],
        release_year: '1993',
        metacritic_score: 'Too old for metacritic!',
        genre: 'Sports',
        console_platform: 'Arcade',
        developer: 'Midway',
        backloggd_link: '',
    },
    '272': {
        content: '',
        submitted_by: '',
        answers: ["Final Fantasy VII Remake", "Final Fantasy 7 Remake"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=FINAL%20FANTASY%20VII%20REMAKE%20INTERGRADE%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-vii-remake-intergrade-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-vii-remake-intergrade-pc-steam-key-global-i10000245055009?gtag=391e4ef696", "https://www.amazon.com/Final-Fantasy-VII-Remake-PlayStation-Deluxe/dp/B07SJ2P1XC?crid=3ANPR69HDNIA3&keywords=Final+Fantasy+VII+Remake&qid=1683075424&sprefix=final+fantasy+vii+remake%2Caps%2C351&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=84c55baa860cb6c54309cf2e4c245289&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Final Fantasy',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '87%',
        genre: 'Action JRPG',
        console_platform: 'PS4',
        developer: 'Square Enix Business Division 1',
        backloggd_link: '',
    },
    '273': {
        content: '',
        submitted_by: '',
        answers: ["Medal of Honor"],
        affiliate_links: ["https://www.g2a.com/medal-of-honor-limited-edition-origin-key-global-i10000048550001?gtag=391e4ef696", "https://www.amazon.com/Medal-Honor-Playstation-3/dp/B002ZJNWWC?crid=FSMD01RZH1WR&keywords=Medal+of+Honor&qid=1683074770&sprefix=medal+of+honor%2Caps%2C329&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=f66e2e3a475d819436b3d04d48b6de7b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Medal of Honor',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '92%',
        genre: 'Shooter, Historic',
        console_platform: 'PS1',
        developer: 'DreamWorks Interactive',
        backloggd_link: '',
    },
    '274': {
        content: '',
        submitted_by: '',
        answers: ["Assassin's Creed Odyssey"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20Odyssey%20%20Ultimate%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-odyssey-ultimate-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-odyssey-ultimate-edition-pc-steam-account-global-i10000156558094?gtag=391e4ef696", "https://www.amazon.com/Assassins-Creed-Odyssey-Ultimate-Pre-Purchase/dp/B07DMCRJ56?crid=1J2LKK7ZPC0UD&keywords=Assassin%27s+Creed+Odyssey&qid=1683074557&sprefix=dark+souls+ii%2Caps%2C419&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=9569cb794de194a76028b6e4f2ccd55b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Assassins Creed',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '86%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Ubisoft Quebec',
        backloggd_link: '',
    },
    '275': {
        content: '',
        submitted_by: '',
        answers: ["Dark Souls II", "Dark Souls 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DARK%20SOULS%20II%20Scholar%20of%20the%20First%20Sin%20ZTORM%20%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdark-souls-ii-scholar-of-the-first-sin-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dark-souls-ii-scholar-of-the-first-sin-steam-gift-global-i10000005638002?gtag=391e4ef696", "https://www.amazon.com/Dark-Souls-II-Black-Armor-PlayStation/dp/B00F6YD2AK?crid=2Z417LIGO1NRD&keywords=Dark+Souls+II&qid=1683074326&sprefix=dark+souls+ii%2Caps%2C895&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=37e3b94739bdd7795d626b4d7ec681c5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dark Souls',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '91%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'FromSoftware',
        backloggd_link: '',
    },
    '276': {
        content: '',
        submitted_by: '',
        answers: ["Halo Wars"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Halo%20Wars%20Definitive%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhalo-wars-definitive-edition-xbox-pc%2F&intsrc=PUI2_9616", "https://www.g2a.com/halo-wars-definitive-edition-steam-gift-global-i10000034566002?gtag=391e4ef696", "https://www.amazon.com/Halo-Wars-Limited-Xbox-360-Collectors/dp/B001F6KSDM?crid=1SRGTCM75V476&keywords=Halo+Wars&qid=1683073874&sprefix=halo+wars%2Caps%2C348&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=c4b7bb62780fa671e2061eebde9f41b7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Halo',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '82%',
        genre: 'Real-time Strategy',
        console_platform: 'X360',
        developer: 'Ensemble Studios',
        backloggd_link: '',
    },
    '277': {
        content: '',
        submitted_by: '',
        answers: ["Hyper Demon"],
        affiliate_links: ["https://www.g2a.com/hyper-demon-pc-steam-gift-global-i10000336933003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '89%',
        genre: 'Action',
        console_platform: 'PC',
        developer: 'Sorath',
        backloggd_link: '',
    },
    '278': {
        content: '',
        submitted_by: '',
        answers: ["Gears of War"],
        affiliate_links: ["https://www.g2a.com/gears-of-war-ultimate-edition-xbox-live-key-xbox-one-global-i10000002166001?gtag=391e4ef696", "https://www.amazon.com/Gears-War-Ultimate-Xbox-One/dp/B00ZMBLKPG?crid=CZM04YE0WBJB&keywords=Gears+of+War&qid=1683073286&s=videogames-intl-ship&sprefix=gears+of+war%2Cvideogames-intl-ship%2C323&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=5d57f6d3740848aeed23d728572469fd&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Gears of War',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '94%',
        genre: 'Shooter, Action',
        console_platform: 'X360',
        developer: 'Epic Games',
        backloggd_link: '',
    },
    '279': {
        content: '',
        submitted_by: '',
        answers: ["Nioh"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Nioh%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fnioh-complete-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/nioh-complete-edition-steam-gift-pc-global-i10000082265003?gtag=391e4ef696", "https://www.amazon.com/Nioh-Collection-PS5-playstation-5/dp/B0993467K7?crid=2DIJRU9MG1YXB&keywords=Nioh&qid=1683073005&s=videogames-intl-ship&sprefix=nioh%2Cvideogames-intl-ship%2C337&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=1f9d44b489f1a1238635d7214a879040&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Nioh',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '88%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Team Ninja',
        backloggd_link: '',
    },
    '280': {
        content: '',
        submitted_by: '',
        answers: ["Portal"],
        affiliate_links: ["https://www.g2a.com/portal-steam-gift-global-i10000004699002?gtag=391e4ef696"],
        franchise: 'Portal',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '90%',
        genre: 'Puzzle',
        console_platform: 'PC, X360, PS3',
        developer: 'Valve',
        backloggd_link: '',
    },
    '281': {
        content: '',
        submitted_by: '',
        answers: ["Battlefield 3"],
        affiliate_links: ["https://www.g2a.com/battlefield-3-limited-edition-battlefield-3-premium-pack-pc-origin-key-global-i10000049894001?gtag=391e4ef696", "https://www.amazon.com/Electronic-Arts-Battlefield-3-Limited-Sony/dp/B004MKM94O?crid=19IESXYO25H9H&keywords=Battlefield+3&qid=1683072479&sprefix=battlefield+3%2Caps%2C512&sr=8-14&linkCode=ll1&tag=guessthegam06-20&linkId=2343f52049bf4069b78a259a4f425871&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Battlefield',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '89%',
        genre: 'Shooter',
        console_platform: 'PC, PS3, X360',
        developer: 'DICE',
        backloggd_link: '',
    },
    '282': {
        content: '',
        submitted_by: '',
        answers: ["Bastion"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '86%',
        genre: 'Action, RPG',
        console_platform: 'X360',
        developer: 'Supergiant Games',
        backloggd_link: '',
    },
    '283': {
        content: '',
        submitted_by: '',
        answers: ["Saints Row"],
        affiliate_links: ["https://www.amazon.com/Saints-Platinum-Hits-Renewed-Xbox-360/dp/B07Y8HHXVD?crid=1HGUK010UNUWX&keywords=Saints+Row&qid=1680739017&sprefix=saints+row%2Caps%2C987&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=67bdb645ae845d070cc6728f36030daa&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Saints Row',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '81%',
        genre: 'Action, Adventure',
        console_platform: 'X360',
        developer: 'Volition',
        backloggd_link: '',
    },
    '284': {
        content: '',
        submitted_by: '',
        answers: ["Dreams"],
        affiliate_links: ["https://www.g2a.com/dreams-ps4-psn-account-global-i10000193169003?gtag=391e4ef696", "https://www.amazon.com/Dreams-PlayStation-4/dp/B082LW28B2?crid=3UIVRRUR4A58X&keywords=Dreams+video+game&qid=1680738347&sprefix=dreams+video+gam%2Caps%2C316&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=601e877122b00b89b10da9312835e693&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '89%',
        genre: 'Creation',
        console_platform: 'PS4',
        developer: 'Media Molecule',
        backloggd_link: '',
    },
    '285': {
        content: '',
        submitted_by: '',
        answers: ["Dance Central"],
        affiliate_links: ["https://www.g2a.com/dance-central-spotlight-pc-xbox-live-key-united-states-i10000048540003?gtag=391e4ef696", "https://www.amazon.com/Dance-Central-Xbox-360/dp/B004I5EE46?crid=3EKCU4RGWWS9S&keywords=Dance%2BCentral&qid=1680737368&sprefix=dance%2Bcentral%2Caps%2C315&sr=8-2&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=7c004b32d64520d3cd0cffe64e2fdd49&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dance Central',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '82%',
        genre: 'Rhythm, Fitness',
        console_platform: 'X360',
        developer: 'Harmonix',
        backloggd_link: '',
    },
    '286': {
        content: '',
        submitted_by: '',
        answers: ["Dead Island"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dead%20Island%20Definitive%20Collection%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdead-island-definitive-collection-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/dead-island-steam-key-global-i10000043551007?gtag=391e4ef696", "https://www.amazon.com/Dead-Island-Definitive-Collection-Xbox-One/dp/B01C7L07OI?keywords=Dead+Island&qid=1680737099&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=269e7042b8705262944d185680d0c9f5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dead Island',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '80%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'Techland',
        backloggd_link: '',
    },
    '287': {
        content: '',
        submitted_by: '',
        answers: ["South Park: The Stick of Truth"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=South%20Park%20The%20Stick%20of%20Truth%20UPlay%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsouth-park-the-stick-of-truth%2F&intsrc=PUI2_9608", "https://www.g2a.com/south-park-the-stick-of-truth-steam-key-global-i10000004031011?gtag=391e4ef696", "https://www.amazon.com/South-Park-Stick-Truth-PlayStation-Standard/dp/B078YRH6XV?crid=D61EKK38S4I0&keywords=South+Park%3A+The+Stick+of+Truth&qid=1680736897&sprefix=titanfall%2Caps%2C375&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=ec055e8526d1a14ee7c08431542fbb35&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'South Park',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '85%',
        genre: 'RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'Obsidian Entertainment',
        backloggd_link: '',
    },
    '288': {
        content: '',
        submitted_by: '',
        answers: ["Titanfall"],
        affiliate_links: ["https://www.g2a.com/titanfall-deluxe-edition-key-origin-global-i10000049789001?gtag=391e4ef696", "https://www.amazon.com/Titanfall-Microsoft-XBox-One-Game/dp/B00D781WO0?crid=2HEVLEF9A0S0Q&keywords=Titanfall&qid=1680736837&sprefix=titanfall+%2Caps%2C317&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=2c31ba72c78c8a1d15d7d9e826611681&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Titanfall',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '86%',
        genre: 'Shooter',
        console_platform: 'PC, XONE, X360',
        developer: 'Respawn Entertainment',
        backloggd_link: '',
    },
    '289': {
        content: '',
        submitted_by: '',
        answers: ["The Banner Saga"],
        affiliate_links: ["https://www.g2a.com/the-banner-saga-steam-key-global-i10000000863003?gtag=391e4ef696", "https://www.amazon.com/Banner-Saga-Trilogy-Nintendo-Switch/dp/B07FX2WPSR?crid=2297FLZY2QSYU&keywords=The+Banner+Saga&qid=1680658070&sprefix=the+banner+sa%2Caps%2C463&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=080e4c1b9e059ad5fe18f2e8c5766810&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Banner Saga',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '80%',
        genre: 'RPG, Strategy',
        console_platform: 'PC, Mobile',
        developer: 'Stoic Studio',
        backloggd_link: '',
    },
    '290': {
        content: '',
        submitted_by: '',
        answers: ["The Last Guardian"],
        affiliate_links: ["https://www.g2a.com/the-last-guardian-ps4-psn-account-global-i10000034709002?gtag=391e4ef696", "https://www.amazon.com/Rivkin-Guardian-PlayStation-Video-Games-4/dp/B0BYSRHHCT?crid=1D4LPAV7UJFG2&keywords=The+Last+Guardian&qid=1680657894&sprefix=super+mario+maker+%2Caps%2C301&sr=8-13&linkCode=ll1&tag=guessthegam06-20&linkId=996500978f0d36d4e4e8e219daeb238e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '82%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Japan Studio, GenDesign',
        backloggd_link: '',
    },
    '291': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario Maker"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-Maker-Console-Deluxe-Set/dp/B014SHR1FM?crid=24F2GTAV4CKN7&keywords=Super+Mario+Maker%3A&qid=1680657670&sprefix=super+mario+maker+%2Caps%2C317&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=a568828734e41dad232f38f3a06a5166&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '88%',
        genre: 'Platformer, Creative',
        console_platform: 'Wii U',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '292': {
        content: '',
        submitted_by: '',
        answers: ["Sleeping Dogs"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sleeping%20Dogs%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsleeping-dogs-definitive-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/sleeping-dogs-definitive-edition-steam-gift-global-i10000002307004?gtag=391e4ef696", "https://www.amazon.com/Sleeping-Dogs-Definitive-Xbox-Digital/dp/B00WY2JJ68?crid=1NPNF1FWY1LB6&keywords=Sleeping%2BDogs&qid=1680657320&sprefix=crackdown%2Caps%2C409&sr=8-7&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=dfc92455a14b611fbc98a53d72e19665&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sleeping Dogs',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '80%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'United Front Games',
        backloggd_link: '',
    },
    '293': {
        content: '',
        submitted_by: '',
        answers: ["Crackdown"],
        affiliate_links: ["https://www.amazon.com/Crackdown-Xbox-360/dp/B000HCQK0A?keywords=Crackdown&qid=1680657157&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=a1af84fca36ab8ef1f7467efc281a2a9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Crackdown',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '83%',
        genre: 'Action, Adventure',
        console_platform: 'X360',
        developer: 'Realtime Worlds',
        backloggd_link: '',
    },
    '294': {
        content: '',
        submitted_by: '',
        answers: ["Halo 2"],
        affiliate_links: ["https://www.g2a.com/halo-2-anniversary-pc-steam-gift-global-i10000195542002?gtag=391e4ef696", "https://www.amazon.com/Halo-2-Limited-Collectors-Pc/dp/B0002A2S26?crid=DLJ707L60T4M&keywords=Halo+2&qid=1680260514&s=videogames&sprefix=firewatch%2Cvideogames%2C360&sr=1-4&linkCode=ll1&tag=guessthegam06-20&linkId=4d43fa033dc5cf102206985627ade18c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Halo',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '95%',
        genre: 'Shooter',
        console_platform: 'Xbox',
        developer: 'Bungie',
        backloggd_link: '',
    },
    '295': {
        content: '',
        submitted_by: '',
        answers: ["Firewatch"],
        affiliate_links: ["https://www.g2a.com/firewatch-steam-gift-global-i10000008737003?gtag=391e4ef696", "https://www.amazon.com/Firewatch-Xbox-One-Digital-Code/dp/B06XJZFW6B?crid=2YBG8VKK7N3RR&keywords=firewatch&qid=1680260436&s=videogames&sprefix=firewatch%2Cvideogames%2C339&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=82fcee55356535eb65f68a9f06da82b4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '81%',
        genre: 'Narrative Adventure',
        console_platform: 'PC, PS4, XONE',
        developer: 'Campo Santo',
        backloggd_link: '',
    },
    '296': {
        content: '',
        submitted_by: '',
        answers: ["Star Wars Battlefront (2004)"],
        affiliate_links: ["https://www.g2a.com/star-wars-battlefront-ultimate-edition-origin-key-global-i10000007868003?gtag=391e4ef696", "https://www.amazon.com/Star-Wars-Battlefront-Ultimate-Digital/dp/B016ASY8MG?crid=3SEWU3TIMII52&keywords=Star+Wars+Battlefront&qid=1680259650&sprefix=star+wars+battlefront+%2Caps%2C311&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=d9cd35a176c9fe328448814615c02347&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Star Wars',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '76%',
        genre: 'Shooter',
        console_platform: 'PC, PS2, Xbox',
        developer: 'Pandemic Studios',
        backloggd_link: '',
    },
    '297': {
        content: 'Hitman: Absolution is a stealth action video game that was released in 2012 by IO Interactive. The game is the fifth installment in the Hitman series and offers players a fresh take on the franchises classic gameplay. Players take on the role of Agent 47, a genetically-engineered assassin who must navigate through various levels and environments to complete missions. Each mission offers multiple pathways to achieve the objective, allowing players to exercise their creativity and experiment with different approaches. One of the game\'s defining features is the \'instinct\' system, which enables players to see through walls and predict enemy movement patterns. This system adds an extra layer of strategy to gameplay and allows players to plan their moves carefully. Hitman: Absolution boasts an impressive visual presentation, with each level featuring unique and detailed environments. The game\'s emphasis on strategy and creative problem-solving sets it apart from other games in the genre, and its unique mechanics and stunning visuals make for a memorable gaming experience. If you\'re looking for a challenging, thrilling, and immersive game, Hitman: Absolution is a great choice.',
        submitted_by: '',
        answers: ["Hitman: Absolution"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Hitman%20Absolution%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhitman-absolution-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/hitman-absolution-elite-edition-steam-key-global-i10000002240007?gtag=391e4ef696", "https://www.amazon.com/HITMAN-ABSOLUTION-PROFESSIONAL-M/dp/B00JPD5R6U?crid=VE2CDBALPYBZ&keywords=Hitman%3A+Absolution&qid=1680259554&sprefix=pokemon+legends+arceus%2Caps%2C1112&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=d51c3d3b694f9ae2536b14e652b04c8e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hitman',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '79%',
        genre: 'Action, Adventure, Stealth',
        console_platform: 'PC, PS3, X360',
        developer: 'IO Interactive',
        backloggd_link: '',
    },
    '298': {
        content: '',
        submitted_by: '',
        answers: ["Pokemon Legends: Arceus"],
        affiliate_links: ["https://www.g2a.com/pokemon-legends-arceus-nintendo-switch-nintendo-eshop-key-united-states-i10000300566001?gtag=391e4ef696", "https://www.amazon.com/Pok%C3%A9mon-Legends-Arceus-Standard-Digital/dp/B09BNK4592?keywords=Pokemon+Legends%3A+Arceus&qid=1680258999&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=02cdfaad9248f8a1baa34ece1bf5cacb&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Pokemon',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '83%',
        genre: 'RPG',
        console_platform: 'Switch',
        developer: 'Game Freak',
        backloggd_link: '',
    },
    '299': {
        content: 'Dark Souls III is an action role-playing video game that gained massive hype and attention during its release in 2016. For gamers who love a challenge and an immersive experience, Dark Souls III has everything they crave. Like every other Dark Souls game, it is designed to challenge even the most seasoned gamers with its unforgiving bosses, intricate level design, and punishing mechanics. The game demands hard work and patience, and it rewards those who persevere with a sense of accomplishment that few other games can deliver. The games gothic and ominous atmosphere gives gamers the feeling of being on a perilous journey in a dark and treacherous world. The visuals are stunning, with beautifully designed environments that deliver an immersive experience that is sure to keep gamers glued to the screen.Dark Souls III is a masterpiece for gamers who love adventure, challenges, and an experience that leaves them feeling accomplished. For those willing to put in the work, Dark Souls III is an experience like no other, and it is worth every moment spent in the dark, treacherous world it presents.',
        submitted_by: 'https://instagram.com/pauagoldriver',
        answers: ["Dark Souls III", "Dark Souls 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DARK%20SOULS%20III%20Deluxe%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdark-souls-iii-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dark-souls-iii-deluxe-edition-pc-steam-key-global-i10000014305007?gtag=391e4ef696", "https://www.amazon.com/Dark-Souls-III-Collectors-PlayStation-4/dp/B0191J0SE8?crid=18IV9TVMRQSO3&keywords=Dark+Souls+III&qid=1680258482&sprefix=beyond+good+%26+evil+game%2Caps%2C407&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=17a275fbf615dcfa1756b7e3c2b87a8b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dark Souls',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '89%',
        genre: 'RPG',
        console_platform: 'PS4, XONE, PC',
        developer: 'FromSoftware, Inc.',
        backloggd_link: '',
    },
    '300': {
        content: 'Beyond Good & Evil, a game that premiered in 2003, is a hidden gem that any video game enthusiast should give a try. Its unique blend of elements makes it unlike anything you have ever played before. It\'s a game that has it all — action, adventure, and suspense. Set in the world of Hillys, the protagonist, Jade, is a photojournalist who uncovers a conspiracy involving a sinister force that threatens the planet. As Jade, you must navigate through tough terrains, solve puzzles, and defeat enemy soldiers to ultimately save your planet. What sets this game apart from others is its innovative gameplay mechanics, likable characters, and beautiful art style. The combat is fast-paced but not overly complicated, making it easy to pick up and enjoy. The art style is vibrant, and the characters are beautifully designed, immersing players in Hillys\' world. If you\'re looking for a game that\'s different from the usual fare, Beyond Good & Evil is worth trying. Its complex story, exceptional gameplay, and immersive world will have you hooked from the first level.',
        submitted_by: 'https://www.instagram.com/rhysus.christ/',
        answers: ["Beyond Good & Evil"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Beyond%20Good%20and%20Evil%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbeyond-good-and-evil%2F&intsrc=PUI2_9608", "https://www.g2a.com/beyond-good-evil-ubisoft-connect-key-global-i10000000247004?gtag=391e4ef696", "https://www.amazon.com/Beyond-Good-Evil-xbox/dp/B00009ZVHR?crid=1EWQ8ELMQ1XY6&keywords=Beyond+Good+%26+Evil+game&qid=1680258388&sprefix=beyond+good+%26+evil+ga%2Caps%2C354&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=b708f656e32a54a768a51e4d2757ef7d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Beyond Good & Evil',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '83%',
        genre: 'Action',
        console_platform: 'GameCube, Xbox, PS2, PC',
        developer: 'Ubisoft Pictures SAS, Ubisoft, Inc.',
        backloggd_link: '',
    },
    '301': {
        content: 'Battletoads: The Ultimate Beat \'Em Up Game That’s Sure to Keep You Glued to Your Screen! If you are already familiar with video games, then it’s time to take your gaming skills to the next level by playing Battletoads. This game is a legendary beat ‘em up game that was first released in 1991, and re-released in 2020 with updated graphics and gameplay. It is available on Xbox, PC, and PlayStation, so no matter what platform you prefer to play on, you can experience the excitement and challenge of Battletoads. One of the most unique features of Battletoads is the different play styles for each of the three playable characters, Zitz, Rash, and Pimple, from their attacks to their special moves. Not only do you get to enjoy the game alone, but you can also enjoy the multiplayer co-op mode, which allows you to play with up to three of your friends. This mode adds a lot of replay value to the game and makes it incredibly enjoyable.',
        submitted_by: '',
        answers: ["Battletoads"],
        affiliate_links: ["https://www.g2a.com/battletoads-xbox-one-windows-10-xbox-live-key-united-states-i10000207026006?gtag=391e4ef696", "https://www.amazon.com/Battletoads-Legacy-Cartridge-Exclusive-Worldwide/dp/B094576YCV?crid=3VTSBQD7VZZZV&keywords=Battletoads&qid=1680257635&s=videogames&sprefix=battletoads%2Cvideogames%2C342&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=a533b6c9dbe6583a66eec9cb6618ef62&language=en_US&ref_=as_li_"],
        franchise: 'Battletoads',
        twitter_ids: [],
        release_year: '1991',
        metacritic_score: 'Too old',
        genre: 'Action',
        console_platform: 'NES',
        developer: 'Rare Limited',
        backloggd_link: '',
    },
    '302': {
        content: 'Dragon Age 2 is a sequel to the popular Dragon Age: Origins. Set in the world of Thedas, a richly detailed and complex world filled with magic, mythical creatures, and powerful factions, Dragon Age 2 is a game that is sure to delight fans of the fantasy genre. So, what makes this game different from others? Well, first of all, the player is given a lot of control over the story. The choices you make throughout the game will directly impact the direction of your character\'s narrative. It is up to you to decide whether to be a hero or a villain, whether to ally with certain factions or pursue your own agenda.Another unique feature of Dragon Age 2 is the combat system. Unlike many other role-playing games, Dragon Age 2 presents a more fast-paced, action-oriented combat system. This system allows the player to engage in more thrilling and dynamic battles with their enemies, rather than simply clicking buttons repeatedly.Dragon Age 2 is a game that has something to offer to both hardcore gamers and those familiar with the fantasy genre. With its unique combat system, player-driven storyline, and richly detailed lore, this game is sure to provide hours of immersive entertainment.',
        submitted_by: '',
        answers: ["Dragon Age II", "Dragon Age 2"],
        affiliate_links: ["https://www.g2a.com/dragon-age-ii-ultimate-edition-pc-steam-gift-global-i10000043364007?gtag=391e4ef696", "https://www.amazon.com/NEW-Dragon-Videogame-Software-Playstation-3/dp/B004Y3CNP4?crid=369V625SZTHKF&keywords=Dragon+Age+2&qid=1680257494&s=videogames&sprefix=dragon+age+2%2Cvideogames%2C759&sr=1-7&linkCode=ll1&tag=guessthegam06-20&linkId=41933108e669f6ca0be5d3e8682f603e&language=en_US&ref_=as_li_ss_"],
        franchise: 'Dragon Age',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '79%',
        genre: 'RPG',
        console_platform: 'PS3, X360, PC',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '303': {
        content: 'Developed by Rockstar Games, this game takes place in the late 1800s, where you are Arthur Morgan, a member of the Dutch van der Linde gang. The game takes you through a number of lands and territories, including snowy mountains to dusty towns, and even to the swamps.The game\'s graphics and attention to detail are what sets it apart, and the wilderness is unmatched compared to other games or open-world experiences. The countryside feels natural, the weather systems add to immersion, and the transitions between different states are seamless. The game\'s storyline is also quite compelling, and Morgan finds himself walking a fine line between loyalty to his gang and his own moral compass. This game is not just about shootouts and heists, but it\'s also about morality, loyalty, and survival. Red Dead Redemption 2 also has an abundance of side-quests that allow you to explore the game\'s world and interact with NPCs. From gathering supplies and food to train heists, the number of activities you can do in the game is almost limitless. Red Dead Redemption 2 is an epic and breathtaking game that is hard to put down once you start playing. It is the perfect balance of action and adventure, and the attention to detail is second to none. If you\'re a video game enthusiast or even remotely interested in trying a new game, then you won\'t regret giving Red Dead Redemption 2 a shot.',
        submitted_by: '',
        answers: ["Red Dead Redemption 2", "Red Dead Redemption II"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Red%20Dead%20Redemption%20II%20Ultimate%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fred-dead-redemption-2-ultimate-edition-rockstar-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/red-dead-redemption-2-ultimate-edition-xbox-live-key-xbox-one-global-i10000174280006?gtag=391e4ef696", "https://www.amazon.com/Red-Dead-Redemption-Ultimate-Digital/dp/B07DNGPJK3?crid=2QGTTA30Z1VNS&keywords=Red+Dead+Redemption+2&qid=1680243634&s=videogames&sprefix=crash+bandicoot+1%2Cvideogames%2C1104&sr=1-8&linkCode=ll1&tag=guessthegam06-20&linkId=62a2dfed8f6d0315a58e451efd7f14da&language=en_US&ref_=a"],
        franchise: 'Red Dead Redemption',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '97%',
        genre: 'Action',
        console_platform: 'PS4, XONE, PC',
        developer: 'Rockstar Studios',
        backloggd_link: '',
    },
    '304': {
        content: '',
        submitted_by: '',
        answers: ["Crash Bandicoot"],
        affiliate_links: ["https://www.g2a.com/crash-bandicoot-n-sane-trilogy-steam-gift-global-i10000081158010?gtag=391e4ef696", "https://www.amazon.com/Crash-Bandicoot-N-Sane-Trilogy-Nintendo/dp/B07BBP6VC8?crid=1BL76QIJ7Q6BJ&keywords=Crash+Bandicoot+1&qid=1680235748&s=videogames&sprefix=crash+bandicoot+1%2Cvideogames%2C319&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=3b0bea4c24dd185cee5f17a4c4b581ef&language=en_US&ref_=as_"],
        franchise: 'Crash Bandicoot',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'Too Old',
        genre: 'Action',
        console_platform: 'PS1',
        developer: 'Naughty Dog, Inc.',
        backloggd_link: '',
    },
    '305': {
        content: '',
        submitted_by: '',
        answers: ["Ultimate Marvel vs. Capcom 3", "Marvel vs. Capcom 3: Fate of Two Worlds", "Marvel vs. Capcom 3", "Marvel vs. Capcom III", "Ultimate Marvel vs. Capcom III"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=ULTIMATE%20MARVEL%20VS%20CAPCOM%203%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fultimate-marvel-vs-capcom-3-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/ultimate-marvel-vs-capcom-3-steam-key-global-i10000034042003?aid=10845451?gtag=391e4ef696", "https://www.amazon.com/Ultimate-Marvel-Vs-Capcom-Xbox-Renewed/dp/B07Y8JQ453?crid=2XK693PTTV1LP&keywords=Ultimate+Marvel+vs.+Capcom+3&qid=1680235229&s=videogames&sprefix=ultimate+marvel+vs.+capcom+3%2Cvideogames%2C1022&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=0bb00b56a47f2194598b7845aefa81c1&l"],
        franchise: 'Marvel vs. Capcom',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '79%',
        genre: 'Fighting',
        console_platform: 'PS3, X360',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '306': {
        content: '',
        submitted_by: '',
        answers: ["Psychonauts"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Psychonauts%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpsychonauts%2F&intsrc=PUI2_9608", "https://www.g2a.com/psychonauts-steam-key-global-i10000000578003?gtag=391e4ef696", "https://www.amazon.com/PsychoNauts-Renewed-Playstation-2/dp/B07ZYQ8WDL?crid=1H0I6QIC9WTL1&keywords=Psychonauts&qid=1680234685&s=videogames&sprefix=psychonauts%2Cvideogames%2C339&sr=1-4&linkCode=ll1&tag=guessthegam06-20&linkId=926d9b5a1298da1c57ba8bb2fab353bc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Psychonauts',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '87%',
        genre: 'Action, Adventure, Platformer',
        console_platform: 'PC, Xbox, PS2',
        developer: 'Double Fine Productions',
        backloggd_link: '',
    },
    '307': {
        content: '',
        submitted_by: '',
        answers: ["Super Mario World 2: Yoshi's Island", "Super Mario World 2", "Super Mario World II", "Yoshi's Island", "Yoshis Island"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-World-Yoshis-Island-Nintendo/dp/B00KI6T3QS?keywords=Super+Mario+World+2%3A+Yoshi%27s+Island&qid=1680230985&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=29719a2d6b1a0706ca84abfe6d9f3bd8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '1995',
        metacritic_score: 'Too Old',
        genre: 'Platform',
        console_platform: 'SNES',
        developer: 'Nintendo R&D2',
        backloggd_link: '',
    },
    '308': {
        content: '',
        submitted_by: '',
        answers: ["inFAMOUS"],
        affiliate_links: ["https://www.amazon.com/inFAMOUS-Playstation-3-Certified-Refurbished/dp/B07J5P857V?crid=79GYGGQXHRDZ&keywords=infamous+ps3&qid=1680229511&sprefix=inFAMOUS+%2Caps%2C328&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=7a7c91861eb752abb1c2ce03dbcaa2ef&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Infamous',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '85%',
        genre: 'Action, Adventure',
        console_platform: 'PS3',
        developer: 'Sucker Punch Productions',
        backloggd_link: '',
    },
    '309': {
        content: '',
        submitted_by: '',
        answers: ["Bulletstorm"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Bulletstorm%20Full%20Clip%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbulletstorm-full-clip-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/bulletstorm-full-clip-edition-steam-key-global-i10000034547002?aid=13233443?gtag=391e4ef696", "https://www.amazon.com/Bulletstorm-Full-Clip-Xbox-Digital/dp/B01MZBOQGM?crid=3Q1FTXCXQ3A7B&keywords=Bulletstorm&qid=1680229285&sprefix=age+of+conan+hyborian+adventures+game%2Caps%2C422&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=1c62e0aafb836a098cc8a484e2f1fa4c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '82%',
        genre: 'First-person shooter',
        console_platform: 'PC, PS3, X360',
        developer: 'People Can Fly, Epic Games',
        backloggd_link: '',
    },
    '310': {
        content: '',
        submitted_by: '',
        answers: ["Age of Conan: Hyborian Adventures", "Age of Conan: Unchained"],
        affiliate_links: ["https://www.g2a.com/age-of-conan-unchained-ultimate-level-80-bundle-dlc-steam-key-global-i10000070795001?gtag=391e4ef696", "https://www.amazon.com/Age-Conan-Hyborian-Adventures-PC/dp/B000RZPW9W?crid=1E7SM67XXXM0K&keywords=Age+of+Conan+hyborian+adventures+game&qid=1680229127&sprefix=age+of+conan+hyborian+adventuresgame%2Caps%2C331&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=4314854fe8a4ee73a3d8a544c6f88832&language=en"],
        franchise: 'Conan',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '80%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Funcom',
        backloggd_link: '',
    },
    '311': {
        content: '',
        submitted_by: '',
        answers: ["Heroes of the Storm"],
        affiliate_links: ["https://www.g2a.com/heroes-of-the-storm-starter-pack-battlenet-key-north-america-i10000000876001?gtag=391e4ef696", "https://www.amazon.com/Heroes-Storm-PC-Mac/dp/B00X0GLXQG?crid=3LQDS25J9JNAE&keywords=Heroes+of+the+Storm&qid=1680135689&sprefix=mark+of+the+ninja+special+edition%2Caps%2C405&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=0053dfa0ed0c49785bf58766d711cf68&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '86%',
        genre: 'MOBA',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '312': {
        content: '',
        submitted_by: '',
        answers: ["Mark of the Ninja"],
        affiliate_links: ["https://www.g2a.com/mark-of-the-ninja-special-edition-steam-gift-global-i10000008842001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '90%',
        genre: 'Action, Stealth',
        console_platform: 'X360',
        developer: 'Klei Entertainment',
        backloggd_link: '',
    },
    '313': {
        content: 'Pillars of Eternity II: Deadfire is the second game in the Pillars of Eternity series. It stands apart from other isometric RPG games thanks to its extremely deep and complex storyline. The game takes place in a world that is full of mystery and intrigue, and you will be constantly learning new things about the characters and the world around you as you progress. The story is set on a massive and sprawling archipelago, and there are countless islands and hidden secrets waiting to be discovered. Setting it apart from other RPG\'s include the ability to manage your own crew, ship, and resource management while you navigate your way through treacherous waters, battle fierce creatures, and explore ancient ruins. The game also features a robust character system that allows you to customize your own character and explore your own path. Whether you want to be a powerful mage, a skilled rogue, or a mighty warrior, the game has a class and skill system that will allow you to build the character of your dreams. Just a word of warning: Some argue that this game is much more enjoyable when you have played the first.',
        submitted_by: 'https://twitch.tv/helsrand',
        answers: ["Pillars of Eternity II: Deadfire", "Pillars of Eternity 2: Deadfire", "Pillars of Eternity 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Pillars%20of%20Eternity%20II%20Deadfire%20Obsidian%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpillars-of-eternity-ii-deadfire-obsidian-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/pillars-of-eternity-ii-deadfire-obsidian-edition-steam-key-global-i10000154169001?gtag=391e4ef696", "https://www.amazon.com/Pillars-Eternity-II-Deadfire-Collectors-PlayStation/dp/B07Y4MVZHX?crid=E0WE9RS3ABJS&keywords=Pillars+of+Eternity+II%3A+Deadfire&qid=1680135011&sprefix=binding+of+isaac+game%2Caps%2C357&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=36e07afeb2a67510f9372822fab134ab&language=en"],
        franchise: 'Pillars of Eternity',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '88%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'Obsidian Entertainment, Inc.',
        backloggd_link: '',
    },
    '314': {
        content: 'This dungeon-crawling rogue-like was developed by Edmund McMillen, the creator of Super Meat Boy, and it’s a game that’s hard to define. At first glance, it’s a game about a young boy named Isaac who is trying to escape his religious fanatical mother by fighting his way through a series of dungeon levels filled with monsters, traps, and secrets. Once you look deeper you\'ll see that its twisted humor and its gameplay mechanics are what set it apart. The game is essentially an endless series of randomized challenges where you never quite know what to expect next. You’ll collect items and power-ups, unlocking new abilities and discovering new ways to defeat the monsters in your path. The game has a unique visual style, with a top-down perspective and a cartoonish aesthetic that belies its dark themes. It’s not a game for the faint of heart, but if you’re the type of gamer who loves a challenge and is looking for something outside the mainstream, Binding of Isaac is a must-play. With hours and hours of unpredictable gameplay, this game will have you coming back for more again and again.',
        submitted_by: '',
        answers: ["Binding Of Isaac", "The Binding Of Isaac", "Binding of Isaac: Rebirth", "Binding of Isaac: Afterbirth", "Binding of Isaac: Repentance"],
        affiliate_links: ["https://www.g2a.com/the-binding-of-isaac-afterbirth-steam-gift-global-i10000007547004?gtag=391e4ef696", "https://www.amazon.com/Binding-Isaac-Afterbirth-Nintendo-Switch/dp/B01N21YHC5?crid=JXIT1PM4NKNC&keywords=Binding+Of+Isaac+game&qid=1680134941&sprefix=binding+of+isaac+g%2Caps%2C351&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=e0e2e6088068c0e234e704faa78d5982&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Binding of Isaac',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '84%',
        genre: 'Roguelike',
        console_platform: 'PC',
        developer: 'Edmund McMillen',
        backloggd_link: '',
    },
    '315': {
        content: 'Factorio is not just another video game, it\'s an engineering masterpiece that challenges players with complex puzzles and invites them to explore a vast and hostile world full of dangers and opportunities. Developed by Wube Software, Factorio is a top-down, real-time strategy game that combines elements of automation, resource management, and survival. The objective of the game is to build and maintain an efficient and self-sustaining factory that can produce advanced technologies to launch a rocket into space.    What makes Factorio different from other games is the depth of its gameplay mechanics and the level of control the player has over their environment. In Factorio, players have to gather resources, plan and construct buildings and infrastructure, automate production lines, and defend their base from hostile creatures. The game offers a wide range of tools and machines that can be used to create complex systems, from conveyors and inserters to chemical plants and nuclear reactors. Players can also research new technologies and upgrade their existing machinery to increase productivity and efficiency.    Factorio is an addictive and challenging game that requires attention to detail and a strategic mindset. The game\'s open-ended nature and modding community mean that there is always something new to discover and explore. If you are a video game enthusiast who enjoys problem-solving and building things from scratch, then Factorio is definitely worth giving a try.',
        submitted_by: '',
        answers: ["Factorio"],
        affiliate_links: ["https://www.g2a.com/factorio-steam-key-global-i10000011359006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '90%',
        genre: 'Automation, Resource management',
        console_platform: 'PC',
        developer: 'Wube Software',
        backloggd_link: '',
    },
    '316': {
        content: 'If you\'re an experienced gamer and looking for a fresh challenge, Dishonored is one game that will deliver. Developed by Arkane Studios and published by Bethesda Softworks, Dishonored changes things up with an immersive world that combines innovative gameplay and an intricate storyline that demands skillful strategy and decision-making. In Dishonored, you play as Corvo Attano, an ex-bodyguard and trained assassin who has been falsely accused of murder. The dystopian city of Dunwall, where the game is set, is your playground, full of danger and intrigue. What sets Dishonored apart from most video games is its unique blend of first-person shooter combat and stealth mechanics. This means that you can either engage in combat with a range of deadly weapons or use stealth to navigate the environment unnoticed. The game also lets you choose your own path, which means that you can approach the levels in tens of different of ways, depending on your style of play. There are multiple pathways, non-lethal options, and side quests to keep you engaged for hours on end. Each level has its challenges and obstacles, and every decision you make will impact the final outcome of the game.',
        submitted_by: 'https://www.youtube.com/channel/UCXRmFMrimKD8JH9MXI4Lzfw',
        answers: ["Dishonored"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dishonored%20Bethesda%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdishonored%2F&intsrc=PUI2_9608", "https://www.g2a.com/dishonored-definitive-edition-steam-key-global-i10000007659010?aid=13326585?gtag=391e4ef696", "https://www.amazon.com/Dishonored-Definitive-PS4-Bethesda/dp/B00ZF31O5M?crid=1A71E5W9K72JP&keywords=Dishonored&qid=1680132853&sprefix=dishonored%2Caps%2C900&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=c6006e1776e75be5447a5f623fa11f03&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dishonored',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '91%',
        genre: 'Stealth, Action-adventure',
        console_platform: 'PC, PS3, X360',
        developer: 'Arkane Studios',
        backloggd_link: '',
    },
    '317': {
        content: 'Are you tired of the same kind of video games that require much attention and strategy? Well, Cookie Clicker is not that kind of game! Cookie Clicker is a unique and addictive game with a simple objective. Gather cookies. All you have to do is click the cookie that appears on the screen and gain as many cookies as you can. The more cookies you have, the higher your level, and the more upgrades you receive. Upgrades include grandmas, farms, factories, and much more that help you to produce even more cookies. What makes Cookie Clicker standout is not its objective, but the pure simplicity of the game. There are no cutscenes or difficult levels to beat. The game can go on indefinitely, with little need for your constant attention. It\'s the perfect casual game to play when you\'re tired or not feeling like playing a game that requires significant attention.',
        submitted_by: 'dayalan',
        answers: ["Cookie Clicker"],
        affiliate_links: ["https://www.g2a.com/cookie-clicker-pc-steam-gift-europe-i10000266803006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: 'N/A',
        genre: 'Idle',
        console_platform: 'Browser',
        developer: 'Orteil, DashNet',
        backloggd_link: '',
    },
    '318': {
        content: 'PaRappa the Rapper is the classic rhythm game that was first released in 1996 for the PlayStation console. The game follows the story of a young rapper named PaRappa, who is trying to impress his crush by mastering the art of rapping. Unlike other rhythm games where players match notes to the beat of a song, PaRappa the Rapper uses a call-and-response system. Players must listen to a character\'s rap and then repeat it back by pressing the right buttons in time with the beat. The game\'s iconic art style and catchy soundtrack have made it a cult classic among gamers. The music features a mix of different genres, including hip hop, reggae, and even pop. Many of the songs have become beloved favorites among fans, such as the infectious \"Chop Chop Master Onion\'s Rap\" and the funky \"Love You\".Players will encounter odd characters such as a sentient onion martial arts master, a talking frog who wants to become a chef, and a bleating sheep who dreams of being a pop star. The game\'s sense of whimsy and lightheartedness make it a fun, charming experience that anyone can enjoy. If you\'re a fan of rhythm games or just looking for a unique and entertaining experience, PaRappa the Rapper is definitely worth checking out.',
        submitted_by: '',
        answers: ["PaRappa The Rapper"],
        affiliate_links: ["https://www.amazon.com/Parappa-Rapper-Playstation/dp/B000GVIU64?crid=1UPP439AMGME2&keywords=PaRappa+The+Rapper&qid=1680047629&sprefix=machinarium+gameparappa+the+rapper%2Caps%2C348&sr=8-11&linkCode=ll1&tag=guessthegam06-20&linkId=5780fcd90e9db23304cd99e029d7a184&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'PaRappa the Rapper',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '92%',
        genre: 'Rhythm game',
        console_platform: 'PS1',
        developer: 'NanaOn-Sha',
        backloggd_link: '',
    },
    '319': {
        content: 'Machinarium is a puzzle adventure game that stands out for its unique aesthetic, innovative gameplay mechanics, and immersive storytelling. Set in a dystopian world inhabited by robots, Machinarium follows the journey of a small robot named Josef on his quest to save his girlfriend and unravel a conspiracy that threatens the city. The game\'s hand-drawn artwork and atmospheric soundtrack create a charming and otherworldly ambiance that draws players into the game\'s universe. The game relies heavily on puzzles that require critical thinking, problem-solving, and quick reflexes in a point-and-click format. Each puzzle is distinct, challenging players to use their creativity and logic to find the optimal solution. The game has minimal dialogue and no cutscenes, relying on visual storytelling and subtle animations to convey its narrative. The absence of lengthy dialogue or exposition helps to maintain the game\'s immersive environment, allowing players to explore and experience the game\'s story on their terms.',
        submitted_by: 'https://instagram.com/me_llamo_pipe',
        answers: ["Machinarium"],
        affiliate_links: ["https://www.g2a.com/machinarium-collectors-edition-gogcom-key-global-i10000042729001?aid=13362794?gtag=391e4ef696", "https://www.amazon.com/Machinarium/dp/B00318COS4?crid=3VYG80FFM72J1&keywords=Machinarium+game&qid=1680047285&sprefix=machinarium+ga%2Caps%2C364&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=e4c4e1d396b435fd54098a742d1893b9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '85%',
        genre: 'Puzzle, Point-and-Click',
        console_platform: 'PC',
        developer: 'Amanita Design',
        backloggd_link: '',
    },
    '320': {
        content: 'Terraria, a popular 2D sandbox game, has been making waves in the gaming community since its release in 2011. In the game, players have access to a wide array of materials which can be used to create various tools, weapons, and structures. These resources can be gathered by exploring the world around you. The game features a procedurally generated world, which means that each player\'s experience will be unique. One unique aspect of Terraria is its boss battles. The game features a series of challenging enemies that can be fought for loot and progression. These battles can range from simple to incredibly difficult, meaning that players will have to constantly improve and adapt to succeed. Terraria is a fantastic game to play with friends. The game supports co-op play for up to eight players, making it a great option for those who want to experience the game with others.',
        submitted_by: 'https://www.youtube.com/@NickWoodburn',
        answers: ["Terraria"],
        affiliate_links: ["https://www.g2a.com/terraria-4-pack-steam-gift-global-i10000042771001?gtag=391e4ef696", "https://www.amazon.com/dp/B077TVSKSZ?&linkCode=ll1&tag=guessthegam06-20&linkId=b6d544438f0be50a889c539ed306e63a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '83%',
        genre: 'Sandbox, Adventure',
        console_platform: 'PC',
        developer: 'Re-Logic',
        backloggd_link: '',
    },
    '321': {
        content: '',
        submitted_by: '',
        answers: ["The Outer Worlds"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Outer%20Worlds%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-outer-worlds-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-outer-worlds-pc-steam-key-global-i10000189151004?aid=13318684?gtag=391e4ef696", "https://www.amazon.com/Outer-Worlds-Switch-Digital-Code/dp/B089RGJ2LQ?crid=3G7PO1C4GFN28&keywords=The+Outer+Worlds&qid=1682987906&sprefix=guess+the+game%2Caps%2C1035&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=f76cf3750b2314b9e523611cfe67c891&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Outer Worlds',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '82%',
        genre: 'Action, RPG',
        console_platform: 'PS4, PC, XONE',
        developer: 'Obsidian Entertainment',
        backloggd_link: '',
    },
    '322': {
        content: 'Happy April Fools Day!',
        submitted_by: '',
        answers: ["GuessThe.Game", "Guess The Game"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: 'Not Rated. But if it was it\'d be 100%',
        genre: 'Puzzle',
        console_platform: 'Browser',
        developer: 'Samuel Stiles',
        backloggd_link: '',
    },
    '323': {
        content: '',
        submitted_by: '',
        answers: ["Freedom Fighters"],
        affiliate_links: ["https://www.g2a.com/freedom-fighters-pc-steam-key-global-i10000050380002?gtag=391e4ef696", "https://www.amazon.com/Freedom-Fighters-Battle-Liberty-Microsoft-Xbox/dp/B000088KH4?crid=2VGM8BHJM0K3O&keywords=Freedom+Fighters&qid=1682987342&sprefix=soulcalibur+iv%2Caps%2C539&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=860b5ccd2e655ca890cd5af99a4f5707&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '81%',
        genre: '3rd-person, Shooter',
        console_platform: 'PS2, PC, GameCube, Xbox',
        developer: 'IO Interactive',
        backloggd_link: '',
    },
    '324': {
        content: '',
        submitted_by: '',
        answers: ["Soulcalibur IV", "Soulcalibur 4", "Soul Calibur IV", "Soul Calibur 4"],
        affiliate_links: ["https://www.amazon.com/Soul-Calibur-IV-Playstation-3/dp/B000ZK7ZMQ?crid=1ZO01JBK63W7V&keywords=Soulcalibur+IV&qid=1682987136&sprefix=soulcalibur+iv+%2Caps%2C367&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=6b3e86953b262e004147b76c33a0a3ad&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'SoulCalibur',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '85%',
        genre: 'Fighting',
        console_platform: 'PS3, X360',
        developer: 'Project Soul',
        backloggd_link: '',
    },
    '325': {
        content: '',
        submitted_by: '',
        answers: ["Pikmin 3"],
        affiliate_links: ["https://www.amazon.com/Pikmin-3-Deluxe-Nintendo-Switch/dp/B09N6ZTWX6?crid=20JH8YU1ENCYU&keywords=Pikmin+3&qid=1682986900&sprefix=pikmin+3%2Caps%2C453&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=7f6024fa1767530a85c6c916a157b2b8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Pikmin',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '87%',
        genre: 'Real-time Strategy, Puzzle',
        console_platform: 'Wii U',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '326': {
        content: '',
        submitted_by: '',
        answers: ["Dying Light"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dying%20Light%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdying-light-definitive-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dying-light-definitive-edition-pc-steam-key-global-i10000001162033?gtag=391e4ef696", "https://www.amazon.com/Dying-Light-Anniversary-Xbox-One/dp/B08LWYKYJ1?crid=XIXNSQ1A1M7I&keywords=dying%2Blight%2Benhanced%2Bedition&qid=1682986403&sprefix=Dying%2BLight%2Benhanced%2B%2Caps%2C570&sr=8-7&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=852dc2f51fd48d57f2b94338387b4b43&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dying Light',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '74%',
        genre: 'Survival horror',
        console_platform: 'PS4, PC, XONE',
        developer: 'Techland',
        backloggd_link: '',
    },
    '327': {
        content: '',
        submitted_by: '',
        answers: ["Divinity: Original Sin"],
        affiliate_links: ["https://www.g2a.com/divinity-original-sin-enhanced-edition-gogcom-key-global-i10000007243008?gtag=391e4ef696", "https://www.amazon.com/Divinity-Original-Sin-Enhanced-PlayStation-4/dp/B0114I1Z50?crid=3LWUJ64U33608&keywords=Divinity%3A+Original+Sin&qid=1682731648&s=videogames&sprefix=divinity+original+sin%2Cvideogames%2C466&sr=1-6&linkCode=ll1&tag=guessthegam06-20&linkId=25fd54bb60b046e4b7ed29dab56dd539&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Divinity',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '87%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'Larian Studios',
        backloggd_link: '',
    },
    '328': {
        content: '',
        submitted_by: '',
        answers: ["Rayman Legends"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Rayman%20Legends%20Uplay%20%281%29%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frayman-legends%2F&intsrc=PUI2_9608", "https://www.g2a.com/rayman-legends-rayman-origins-pc-ubisoft-connect-key-global-i10000194729001?gtag=391e4ef696", "https://www.amazon.com/Rayman-Legends-Vita-Playstation-2/dp/B00K6JLLI0?crid=31TBI9B2QLQOJ&keywords=Rayman+Legends&qid=1682731431&s=videogames&sprefix=rayman+legends%2Cvideogames%2C417&sr=1-11&linkCode=ll1&tag=guessthegam06-20&linkId=c87fc875ccbb39e8053f863491864243&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Rayman',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '90%',
        genre: 'Platform',
        console_platform: 'PS3, PC, Wii U, X360',
        developer: 'Ubisoft Montpellier',
        backloggd_link: '',
    },
    '329': {
        content: 'Xenogears is one of those hidden gems in the gaming world that many have yet to discover. It\'s a game that blends RPG elements with mecha combat in a fascinating sci-fi universe, and it\'s sure to appeal to any gamer out there. One of the things that make Xenogears stand out is its storyline. It tells a complex tale of love, betrayal, and self-discovery that spans across different worlds and dimensions. You play as Fei, a mysterious young man with a dark past, who finds himself caught up in a grand conspiracy that could put the fate of the entire universe at stake. The game\'s battle system is another area where it excels. Combat is turn-based, but you have the ability to control massive mechs called \'gears\' that bring a new level of strategy and excitement to each encounter. Different gears have different abilities and strengths, so choosing the right one for the job is essential. The world is rendered with a mix of 2D and 3D graphics that give it a unique look, and the soundtrack is one of the most memorable in gaming history.',
        submitted_by: 'https://twitter.com/ItstheCobo',
        answers: ["Xenogears"],
        affiliate_links: ["https://www.amazon.com/Xenogears-PlayStation/dp/B000038IFX?crid=3KHINW2544499&keywords=Xenogears&qid=1682731138&s=videogames&sprefix=xenogears%2Cvideogames%2C350&sr=1-5&linkCode=ll1&tag=guessthegam06-20&linkId=9649f38339f4b25d0f050143b341c1f7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Xeno',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '84%',
        genre: 'JRPG',
        console_platform: 'PS1',
        developer: 'Square Enix',
        backloggd_link: '',
    },
    '330': {
        content: 'Unlike most games that focus on fast-paced actions and combat, The Long Dark is a survival game that places the player in a harsh, unforgiving wilderness. The game\'s unique concept and gameplay mechanics make it stand out from the rest. The Long Dark takes place in a post-apocalyptic world where a geomagnetic disaster has left the world in ruins. Players take on the role of a lone survivor who must navigate through the wilderness to survive. This includes managing hunger, thirst, fatigue, and hypothermia while also avoiding hostile wildlife and hostile humans. The game is set in a massive open world with a dynamic weather system, and players can interact with the environment in many different ways. They can light a fire to stay warm, hunt and gather food, craft tools, and repair their clothes. The game also has a unique storytelling approach, featuring five different episodes in its story mode that players can explore. Each episode has its challenges, environments, and characters, making every playthrough unique. If you are looking for a game that challenges your survival skills and immerses you in a post-apocalyptic world, The Long Dark is the perfect choice.',
        submitted_by: 'https://mstdn.social/@Baa@fedi.absturztau.be',
        answers: ["The Long Dark"],
        affiliate_links: ["https://www.g2a.com/the-long-dark-pc-steam-key-global-i10000002056006?aid=13282968?gtag=391e4ef696", "https://www.amazon.com/Long-Dark-Xbox-One/dp/B07G3HBMKQ?crid=3JC504Y6KSQGH&keywords=The+Long+Dark&qid=1682731006&s=videogames&sprefix=the+long+dark%2Cvideogames%2C369&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=9801d12fad4df8b5d804da875281d4e7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '77%',
        genre: 'Survival',
        console_platform: 'PC, PS4, XONE',
        developer: 'Hinterland Studio',
        backloggd_link: '',
    },
    '331': {
        content: 'For those searching for a classic MMORPG experience with an expansive world and complex player interactions, Runescape is a must-try. While the game has been around for more than two decades, its popularity has only increased over time, with dedicated players praising its addictive gameplay, deep mechanics, and stunning visuals. At first glance, Old School Runescape may seem like any other MMORPG game, but this game has a steep learning curve, with players who put in the effort finding themselves rewarded with an endlessly entertaining experience. Known for its player driven economy, you\'ll find yourself doing a lot of person to person transactions depsite it having an auction house. This approach to the game economy creates a dynamic, ever-changing market that is heavily influenced by the players themselves. feature of Old School Runescape is player freedom. The game allows players to create their characters and design their gameplay experience. In Old School Runescape, you can be anything you want to be, from a master blacksmith to a notorious thief. Just like any other MMORPG, Runescape is all about community. The game encourages players to join clans and form alliances. The game has a strong player community with regular meetups and events, creating a real bond between players from different parts of the world.',
        submitted_by: 'https://twitch.tv/MikeTavish',
        answers: ["Runescape", "Old School Runescape"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Old%20School%20RuneScape%2012%20Month%20Membership%20OST%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fold-school-runescape-12-month-membership-ost-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/old-school-runescape-membership-6-months-ost-pc-steam-key-global-i10000244964013?gtag=391e4ef696"],
        franchise: 'RuneScape',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: 'Not Applicable',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Jagex',
        backloggd_link: '',
    },
    '332': {
        content: 'At its core, Baba Is You is a simple concept; you play as Baba, a small cute creature that you move around a grid-based level. The goal is to solve puzzles by manipulating the rules of the game. The phrase \"Baba Is You\" is actually a part of the game’s rules and can be manipulated by players. The game allows players to swap the elements of the rules, such as \"Rock Is Push\" or \"Wall Is Stop\" to change the environment and solve puzzles with new solutions. This level of flexibility allows players to approach each puzzle in their own unique way, and the difficulty of the puzzle can range from easy and straightforward to extremely challenging that takes hours to solve. The game is never boring as new elements and rules are introduced to the player as they make progress throughout the levels. Baba Is You is an indie masterpiece that is not only incredibly unique but also challenging and full of charm. It’s a game that’s sure to cement its place in gaming history as one of the most innovative and memorable games of its time. So if you’re looking for something refreshing and challenging, Baba Is You will surely scratch that itch!',
        submitted_by: 'https://twitch.tv/iamuncleslam',
        answers: ["Baba Is You"],
        affiliate_links: ["https://www.g2a.com/baba-is-you-steam-gift-global-i10000185894001?gtag=391e4ef696", "https://www.amazon.com/Baba-You-Switch-Digital-Code/dp/B07YQKZZPQ?keywords=Baba+Is+You&qid=1682730119&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=789d6d8e6d24ef0a72c78e35681946bc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '87%',
        genre: 'Puzzle',
        console_platform: 'PC,Mac, Linux, Switch',
        developer: 'Hempuli',
        backloggd_link: '',
    },
    '333': {
        content: 'Final Fantasy Tactics, released in 1997 for the PlayStation, is the original tactical RPG that started a new wave of gaming worldwide. The game resurrected the Final Fantasy world in a way that no one had seen before, making it a unique experience among RPGs. The story takes place in the fictional world of Ivalice, where players take the role of Ramza Beoulve, a mercenary who becomes embroiled in a complex plot of power, greed, and betrayal. The plot develops in a non-linear manner, and players\' actions throughout the game can change the plot outcome. What sets Final Fantasy Tactics apart from other RPGs is its tactical gameplay system. Instead of engaging in traditional turn-based combat found in most RPGs, gamers can enjoy grid-based battles that have strategic elements. Players must take into account the terrain, their position, and the strengths and weaknesses of their opponents and their own team members. This adds a new layer of strategic thinking that elevates the game beyond a standard RPG. Additionally, the games extensive job system allows players to assign characters different abilities and develop their job-specific skills as they progress throughout the game. This flexibility provides players with a customization that ensures an exciting and dynamic experience. In conclusion, if you are a video game enthusiast interested in exploring tactical games\' strategic side, Final Fantasy Tactics is an excellent choice. This game offers a unique blend of fantasy storytelling with a tactical RPG system, promising an unforgettable gameplay experience.',
        submitted_by: '',
        answers: ["Final Fantasy Tactics"],
        affiliate_links: ["https://www.amazon.com/Final-Fantasy-Tactics-Renewed-Playstation/dp/B07YQBFQKP?keywords=Final+Fantasy+Tactics&qid=1682730034&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=ba74bf5c1ee8416df507529e605aca64&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Final Fantasy',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '83%',
        genre: 'Turn-based Tactical RPG',
        console_platform: 'PS1',
        developer: 'Squaresoft',
        backloggd_link: '',
    },
    '334': {
        content: 'Escape from Tarkov is a game that has taken the gaming world by storm since its release in 2017. It is a hardcore tactical shooter that combines elements of role-playing and survival games, and it has gained a lot of popularity within the gaming community due to its realistic mechanics and unique gameplay. The game is set in the fictional Norvinsk region, which has been sealed off after a political conflict. Players take on the role of a mercenary trying to escape the city while battling scavengers, hostile players, and AI-controlled enemies. At the beginning of the game, players select their character, choose the equipment they want to bring, and start the game with very little resources. What sets this game apart from others is the emphasis on realism. The game includes realistic elements such as weapon handling, ballistics, and health management. For example, players must manage their hunger, thirst, and energy levels, while also dealing with injuries and ailments that can affect their movement, vision, and accuracy. Escape from Tarkov is not an easy game to play. It requires a lot of patience, strategy, and skill. There is a steep learning curve, and players must be prepared to lose their loot if they die in the game. The game is designed to reward players who take their time, plan their moves, and work together. Players must learn the maps, plan their routes, and coordinate with their team members. If you\'re a gamer who is looking for a new challenge and is willing to put in the time and effort to learn a new game, then Escape from Tarkov is definitely worth trying out. The game is unique, immersive, and extremely challenging, and it will keep you coming back for more.',
        submitted_by: '',
        answers: ["Escape from Tarkov"],
        affiliate_links: ["https://www.g2a.com/escape-from-tarkov-pc-battlestate-key-global-i10000148047004?aid=13392048?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: 'Not applicable',
        genre: 'Online Shooter',
        console_platform: 'PC',
        developer: 'Battlestate Games',
        backloggd_link: '',
    },
    '335': {
        content: '',
        submitted_by: 'Panske',
        answers: ["Guild Wars 2"],
        affiliate_links: ["https://www.g2a.com/guild-wars-2-heart-of-thorns-path-of-fire-pc-ncsoft-key-united-states-i10000337785001?gtag=391e4ef696", "https://www.amazon.com/Guild-Wars-Complete-Collection-Online/dp/B0BMR6QLLV?crid=1YPUTW8UK26T9&keywords=Guild+Wars+2&qid=1682215518&sprefix=untitled+goose+game%2Caps%2C476&sr=8-6&linkCode=ll1&tag=guessthegam06-20&linkId=71e61a95fef2e69d1e25916383f82d4f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Guild Wars',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '81%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'ArenaNet',
        backloggd_link: '',
    },
    '336': {
        content: '',
        submitted_by: 'https://twitter.com/OppositeInvict2',
        answers: ["Evil Genius"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Evil%20Genius%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fevil-genius%2F&intsrc=PUI2_9608", "https://www.g2a.com/evil-genius-steam-key-global-i10000000750003?gtag=391e4ef696", "https://www.amazon.com/Evil-Genius-UK-Pc/dp/B0014UYI6W?crid=3JRMX3NSPUV18&keywords=Evil+Genius+game&qid=1682035487&sprefix=evil+genius+gam%2Caps%2C456&sr=8-12&linkCode=ll1&tag=guessthegam06-20&linkId=561209684e1f0963bc7d324279333fa9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Evil Genius',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '75%',
        genre: 'Strategy, Base Building',
        console_platform: 'PC',
        developer: 'Elixir Studios',
        backloggd_link: '',
    },
    '337': {
        content: 'Are you a hardcore gamer looking to take a break from the intense action and jump into a game that\'s all about mischief? Look no further than Untitled Goose Game. The premise of the game is simple: you play as a goose, and your goal is to complete a list of tasks while causing as much chaos and annoyance to the human inhabitants of a small village as possible. The tasks range from stealing objects to watering gardens to making people fall over. Each task you complete grants you access to new areas in the village and new challenges to overcome. The goose is adorable and fun to control, but its mischievous nature can lead to some frustrating moments as you try to complete tasks while being chased by angry villagers. However, these moments only add to the overall enjoyment of the game and create a sense of accomplishment when you finally succeed. If you\'re looking for a fun, lighthearted game that\'s unlike anything else out there, give Untitled Goose Game a try. Its simple premise and charming protagonist make it a joy to play, while its challenging tasks and pursuit mechanic keep you on your toes. Plus, who doesn\'t love causing a little mischief every now and then?',
        submitted_by: 'https://youtube.com/vggeek',
        answers: ["Untitled Goose Game"],
        affiliate_links: ["https://www.g2a.com/untitled-goose-game-pc-steam-gift-global-i10000191937008?gtag=391e4ef696", "https://www.amazon.com/Untitled-Goose-Game-Nintendo-Switch/dp/B08DB49XRR?crid=2SCPBVJ05JHI&keywords=Untitled+Goose+Game&qid=1682035149&sprefix=untitled+goose+game%2Caps%2C557&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=a0eeac2b294ddc76244d3d2a78aaefac&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '79%',
        genre: 'Puzzle, Stealth',
        console_platform: 'Switch, PC',
        developer: 'House House Games',
        backloggd_link: '',
    },
    '338': {
        content: '',
        submitted_by: '',
        answers: ["The Legend of Zelda: Twilight Princess", "The Legend of Zelda Twilight Princess"],
        affiliate_links: ["https://www.amazon.com/Legend-Zelda-Twilight-Princess-Nintendo-Wii/dp/B07YZVXWT5?crid=3PMCPSVHXJA72&keywords=The+Legend+of+Zelda+Twilight+Princess&qid=1681950798&sprefix=the+legend+of+zelda+twilight+princess%2Caps%2C501&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=3248abec9d0a3c3b3dcca7255e29600c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '96%',
        genre: 'Action Adventure, Fantasy',
        console_platform: 'Wii, Gamecube',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '339': {
        content: 'Fire Emblem Awakening is a tactical role-playing game developed by Itelligent Systems and published by Nintendo for the Nintendo 3DS. It\'s a game that has gained a strong following since its release, and it\'s not hard to see why. Firstly, the game has a strong emphasis on its characters and their relationships with each other. These relationships are not just limited to the battlefield, but can be developed through different activities such as dialogues, side quests, and even in-game romances. These interactions add depth to the characters, making them more relatable and endearing. Another thing that sets this game apart is the permanent death feature. When one of your units falls in battle, they are gone for good. This adds a layer of strategy to the gameplay, as the player must carefully plan their moves to avoid losing important characters. It also gives weight to the battles, making each victory feel all the more satisfying. The player controls a group of units, each with their own strengths and weaknesses. The battles take place on grids, where each unit can move a certain number of spaces and attack enemies within their range. The player must use different tactics and strategies to defeat their enemies, taking into account each unit\'s abilities and the terrain. Its strong characters, permanent death feature, and tactical battle system make it stand out from other games in the genre.',
        submitted_by: 'https://www.instagram.com/rich_ulvila/',
        answers: ["Fire Emblem Awakening", "Fire Emblem: Awakening"],
        affiliate_links: ["https://www.g2a.com/fire-emblem-awakening-dlc-pack-dlc-nintendo-switch-nintendo-eshop-key-europe-i10000192828001?gtag=391e4ef696", "https://www.amazon.com/3DS-Fire-Emblem-Awakening-World-Nintendo/dp/B084295L4W?crid=110MV5O9PGD0D&keywords=Fire+Emblem+Awakening&qid=1681950597&sprefix=fire+emblem+awakening%2Caps%2C597&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=b28c6a6467635cffb51bbd78b16b8b5c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Fire Emblem',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '92%',
        genre: 'Tactical RPG',
        console_platform: '3DS',
        developer: 'Intelligent Systems',
        backloggd_link: '',
    },
    '340': {
        content: 'Skate, a skateboarding simulation game series, was first introduced by Electronic Arts (EA) in 2007. Developed by EA Black Box, the franchise is renowned for its intuitive controls, physics, and exhilarating gameplay. It has been well-received by both casual gamers and die-hard skateboarding enthusiasts, earning a special place in the gaming world. Skate\'s core gameplay revolves around mastering a variety of skateboarding tricks and moves to complete challenges and objectives. Its innovative \'Flickit\' control system employs the analog sticks to mimic the motion of the skateboard, allowing players to pull off tricks with an unprecedented level of realism and fluidity. This unique control scheme was a breath of fresh air compared to the more arcade-style gameplay of its predecessors in the genre.The franchise also features an open-world environment, giving players the freedom to explore and discover various skate spots across the city. Throughout the series, players can engage in career mode, online multiplayer, and even create their own skate parks using the in-game editor.The Skate franchise has captivated the hearts of players with its unique controls, engaging gameplay, and true-to-life skateboarding experience.',
        submitted_by: 'https://twitter.com/OppositeInvict2',
        answers: ["Skate", "S.k.a.t.e", "Skate."],
        affiliate_links: ["https://www.amazon.com/Skate-Xbox-360/dp/B000P0XA4I?crid=2645769CO76G3&keywords=Skate+game&qid=1681950286&sprefix=skate+ga%2Caps%2C599&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=1f28471cb4289c6876d1a02d748cc02c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Skate',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '86%',
        genre: 'Sports',
        console_platform: 'X360, Playstation 3',
        developer: 'Electronic Arts Black Box',
        backloggd_link: '',
    },
    '341': {
        content: 'Borderlands 2, an action-packed first-person shooter game, is one of the most iconic titles in the gaming industry. It has won numerous awards and gained a massive fan base since its release in 2012. Borderlands 2 stands out with several unique features such as impressively vast open-world setting, the ability to play with up to three other players co-operatively, and a unique art style that blends cell-shaded graphics and realistic visuals. The gameplay itself is fast-paced and exciting, with thousands of different guns to choose from, each with their own unique stats and abilities. Players can choose from several different classes, each with their own set of skills and abilities. As you progress through the game and complete missions, you\'ll gain experience points that can be used to level-up your character\'s skills and attributes. Additionally, the game\'s story and dialogue are full of humor and eccentric characters, making it an enjoyable experience from start to finish.',
        submitted_by: '',
        answers: ["Borderlands 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Borderlands%202%20Season%20Pass%20Overflow%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fborderlands-2-season-pass%2F&intsrc=PUI2_9608", "https://www.g2a.com/borderlands-2-complete-edition-steam-key-global-i10000002126002?gtag=391e4ef696", "https://www.amazon.com/Borderlands-2-Game-Renewed-xbox-360/dp/B082P99223?crid=3S4K5499K5Y3X&keywords=Borderlands+2&qid=1681516513&s=videogames&sprefix=duck+hunt%2Cvideogames%2C397&sr=1-13&linkCode=ll1&tag=guessthegam06-20&linkId=d43ad4209169faadbdcfdab500927ffa&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Borderlands',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '91%',
        genre: 'FPS, Action RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'Gearbox Software',
        backloggd_link: '',
    },
    '342': {
        content: 'Duck Hunt, an iconic light gun shooter game, was released in 1984 for the Nintendo Entertainment System (NES). Developed and published by Nintendo, this simple yet addictive game quickly became a favorite among players, leaving a lasting impact on the gaming community and the light gun shooter genre. Duck Hunt\'s gameplay is straightforward and accessible, making it a hit among gamers of all ages. Players use the NES Zapper, a light gun controller, to aim and shoot at ducks that fly across the screen. The objective is to shoot the required number of ducks in each round to advance to the next level. As players progress, the game increases in difficulty, with ducks flying faster and more erratically.A notable feature of Duck Hunt is the inclusion of the iconic hunting dog, who assists players by retrieving downed ducks. The dog has become a memorable character in gaming history, often remembered for his cheeky laughter whenever the player misses a shot.The game\'s cultural impact has persisted over the years, with the hunting dog and the NES Zapper becoming iconic symbols of the 1980s gaming era. Duck Hunt has also been referenced in various forms of media and was even included as a playable character in the Super Smash Bros. series, further cementing its legacy in gaming history.',
        submitted_by: '',
        answers: ["Duck Hunt", "Vs. Duck Hunt"],
        affiliate_links: ["https://www.amazon.com/Duck-Hunt-Renewed-Nintendo-Entertainment-System/dp/B07P131S5C?crid=LNEU8U6S1BLF&keywords=Duck+Hunt&qid=1681516200&sprefix=duck+hunt%2Caps%2C457&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=5afa3e1cd4c85e607d16128abbfba0a1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '1984',
        metacritic_score: 'Too old',
        genre: 'Sports',
        console_platform: 'NES',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '343': {
        content: 'Rock of Ages is a hilarious and addictive game that combines tower defense with rolling-ball racing. Set in a whimsical world of historical art and mythology, players take on the roles of two rock archetypes - Sisyphus and Atlas - with the task of navigating giant rolling boulders through a winding course to reach the enemy\'s castle and destroy it. Along the way, obstacles and defenses must be avoided or destroyed, and strategic placement of units and traps must be utilized to thwart the opponent.  The game features an extensive single-player campaign, with each level representing different art eras and accompanied by unmistakable visuals and music, from ancient Greek pottery to Gothic paintings. The attention to detail in each level immerses players in the historical art period and adds an educational touch to the game. Additionally, the multiplayer mode allows players to compete against each other in real-time battles, making for endless hours of fun with friends.',
        submitted_by: '',
        answers: ["Rock of Ages"],
        affiliate_links: ["https://www.g2a.com/rock-of-ages-steam-key-global-i10000035018003?gtag=391e4ef696"],
        franchise: 'Rock of Ages',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '72%',
        genre: 'Tower Defense, Racing',
        console_platform: 'X360, PC',
        developer: 'ACE Team',
        backloggd_link: '',
    },
    '344': {
        content: 'Dandara is an exciting and unique action-adventure game that takes players on a journey through a surreal, sci-fi world where gravity is no longer a limitation. Players navigate through a series of interconnected rooms and platforms, fighting off a variety of otherworldly enemies and using unique powers and abilities to overcome obstacles and solve puzzles. The game follows the titular heroine, Dandara, as she battles against a powerful enemy known as the Eldarian Army. Dandara is a powerful warrior with the unique ability to defy gravity and move freely through the environment. She can leap great distances, stick to ceilings and walls, and even warp into other dimensions to avoid danger. Along the way, players will encounter a cast of memorable characters, each with their own motivations and secrets. The game\'s story is rich and nuanced, featuring themes of hope, resistance, and the power of the human spirit to overcome oppression. Dandara is a must-play title for fans of platformers and action-adventure games alike, showcasing the best aspects of both genres while pushing boundaries in terms of mechanics and storytelling.',
        submitted_by: '',
        answers: ["Dandara"],
        affiliate_links: ["https://www.g2a.com/dandara-steam-key-global-i10000140950001?gtag=391e4ef696", "https://www.amazon.com/Dandara-Nintendo-Switch-Digital-Code/dp/B07FCL7XMJ?crid=3VF6RTA49OT8G&keywords=dandara+switch&qid=1681948953&s=videogames&sprefix=Dandara%2Cvideogames%2C373&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=532e2fb3eacec66dec08fc49fee1e1d9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '81% (Max across all platforms)',
        genre: 'Platformer, Metroidvania',
        console_platform: 'Mobile, PC, Switch, PS4, XONE',
        developer: 'Long Hat House',
        backloggd_link: '',
    },
    '345': {
        content: 'Goat Simulator is a game for people who want to unleash their inner goat and wreak havoc upon the unsuspecting residents of a small town. The game is a physics-based simulation where players control a goat and can do everything from headbutting cars to licking objects to sending humans flying after a well-placed jump. The beauty of Goat Simulator lies in its unpredictability. The game doesn\'t have any objectives or goals; players are free to do whatever they wish. The world is filled with secrets, mini-games, and easter eggs that keep the experience fresh and interesting.',
        submitted_by: '',
        answers: ["Goat Simulator"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Goat%20Simulator%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgoat-simulator%2F&intsrc=PUI2_9608", "https://www.g2a.com/goat-simulator-steam-key-global-i10000003697009?gtag=391e4ef696", "https://www.amazon.com/Goat-Simulator-Bundle-Xbox-One/dp/B08TC33V9H?crid=19U1DV9YH3JA6&keywords=Goat+Simulator&qid=1681948440&s=videogames&sprefix=goat+simulator%2Cvideogames%2C364&sr=1-2&linkCode=ll1&tag=guessthegam06-20&linkId=fee31c1ac69a4577b064c6e58478deab&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Goat Simulator',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '62%',
        genre: 'Action',
        console_platform: 'PC',
        developer: 'Coffee Stain Studios',
        backloggd_link: '',
    },
    '346': {
        content: 'Anno 1800, the latest installment in the popular city-building and management simulation franchise, takes players back to the dawn of the industrial age. Set in a time of great social and technological change, the game challenges players to build and manage a thriving metropolis while navigating the complex economic and political landscape of the 19th century. The game begins with players establishing a small settlement on a pristine island, gradually growing it into a bustling hub of industry, culture, and commerce. Along the way, they must manage a variety of resources, from raw materials to labor, and balance the needs of their citizens, who demand food, shelter, and entertainment.  Over the course of the game, players can advance their civilization by researching new technologies, trading with other nations, and even engaging in diplomacy and warfare. The game also features a robust multiplayer mode, which allows players to collaborate and compete with one another in a shared world.  What makes Anno 1800 truly special, however, is its attention to detail and historical accuracy. From the bustling city streets to the ornate buildings and ships, every aspect of the game showcases the style and atmosphere of the era in stunning detail. For fans of history, strategy, and simulation games, Anno 1800 is an absolute must-play.',
        submitted_by: 'https://twitter.com/groothammer',
        answers: ["Anno 1800"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Anno%201800%20Complete%20Edition%20Year%204%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fanno-1800-complete-edition-year-4-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/anno-1800-complete-edition-year-4-pc-steam-gift-global-i10000170811035?gtag=391e4ef696", "https://www.amazon.com/Anno-1800-Complete-Year-Code/dp/B09XNXL5SZ?crid=17A0GV6F2I4N&keywords=Anno+1800&qid=1681947985&s=videogames&sprefix=anno+1800%2Cvideogames%2C435&sr=1-3&linkCode=ll1&tag=guessthegam06-20&linkId=5c9fa066e922c7a165f84f2212412448&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Anno',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '81%',
        genre: 'City-building, Real-Time Strategy',
        console_platform: 'PC',
        developer: 'Ubisoft Blue Byte',
        backloggd_link: '',
    },
    '347': {
        content: 'Sonic Adventure 2 is an amazing, action-packed video game that was released by Sega in the early 2000s. The game features two storylines that players can choose from: playing as either the hero team or the villain team. The hero team includes Sonic, Tails, and Knuckles, while the villain team features Shadow the Hedgehog, Rouge the Bat, and Dr. Eggman. The game is set in a fictional version of the United States where both teams race to uncover a powerful magical object called the Master Emerald. Players will find the game thrilling and fast-paced, with each level having different objectives that require quick reflexes and fast thinking. Along the way, players can collect rings that will protect characters from attacks and enable them to unlock special abilities. The graphics and sound effects are absolutely top-notch, and the game’s characters are incredibly dynamic and engaging. The missions are challenging and require both skill and strategy to complete, making Sonic Adventure 2 a fantastic game for players of all ages. The game was so impactful and passed the test of time, that it has since been remastered and can be found on popular gaming platforms like PlayStation, Xbox, and Nintendo Switch.',
        submitted_by: '',
        answers: ["Sonic Adventure 2", "Sonic Adventure 2: Battle"],
        affiliate_links: ["https://www.g2a.com/sonic-adventure-2-pc-steam-key-global-i10000045646004?aid=13399414?gtag=391e4ef696", "https://www.amazon.com/Sonic-Adventure-2-Sega-Dreamcast/dp/B000059Z7G?crid=HQ5VU7BVNLSI&keywords=Sonic+Adventure+2&qid=1682035039&sprefix=sonic+adventure+2%2Caps%2C372&sr=8-10&linkCode=ll1&tag=guessthegam06-20&linkId=c411ef1682a7da57acc96fcd75bd3df6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sonic The Hedgehog',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '89%',
        genre: 'Platform, Action, Adventure',
        console_platform: 'Dreamcast',
        developer: 'Sonic Team USA',
        backloggd_link: '',
    },
    '348': {
        content: 'Planet Zoo is a simulation game that allows players to create and manage their own zoo. Developed by Frontier Developments and released in 2019, the game has received high praise for its stunning graphics and realistic gameplay. Players can create habitats for animals, hire staff to care for the park, and manage finances to ensure the zoo\'s success. One of the unique features of the game is the ability to take on the role of the animals themselves, allowing players to experience the park from a whole new perspective. The game offers a wide range of animals to choose from, including lions, elephants, and gorillas, as well as lesser-known animals such as meerkats and red pandas. The game also includes a breeding system, allowing players to breed animals and raise their offspring. Whether it\'s designing the perfect exhibit, researching animal behavior, or dealing with a crisis in the park, Planet Zoo offers a fun and immersive experience for players of all ages.',
        submitted_by: 'https://twitter.com/Saus_emaster',
        answers: ["Planet Zoo"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Planet%20Zoo%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fplanet-zoo-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/planet-zoo-ultimate-edition-pc-steam-key-global-i10000188830023?aid=13034909?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '81%',
        genre: 'Construction and management simulation',
        console_platform: 'PC',
        developer: 'Frontier Developments',
        backloggd_link: '',
    },
    '349': {
        content: 'Baldur\'s Gate II: Shadows of Amn is a role-playing video game that was released in 2000. It is the second installment in the Baldur\'s Gate series and takes place in the fantasy world of the Forgotten Realms. The player controls a group of adventurers on a quest to defeat a powerful sorcerer named Jon Irenicus.The game\'s plot is complex and full of twists and turns as the player navigates through various towns, dungeons, and forests to uncover clues to Irenicus\'s whereabouts. Along the way, the player can recruit new members to their party, each with their unique backstory and abilities.The game\'s combat system is turn-based and requires strategic planning and decision-making by the player. The game also features a vast array of magical spells and weapons that the player can use to defeat enemies and progress through the game. Baldur\'s Gate II: Shadows of Amn is a beloved classic that continues to captivate gamers today. Its immersive storyline, complex characters, and challenging gameplay make it a must-play for any RPG enthusiast.',
        submitted_by: '',
        answers: ["Baldur's Gate II: Shadows of Amn", "Baldur's Gate 2: Shadows of Amn", "Baldur's Gate 2", "Baldur's Gate II"],
        affiliate_links: ["https://www.amazon.com/Baldurs-Gate-II-Shadows-Collectors-pc/dp/B00004XOWL?crid=2F37DO40R6UZ8&keywords=Baldur%27s+Gate+II%3A+Shadows+of+Amn&qid=1682034951&sprefix=shadows+of+amnbaldur%27s+gate+ii+shadows+of+amn%2Caps%2C436&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=b443e247d6edbcd7b738a5daa729c324&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Baldurs Gate',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '95%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '350': {
        content: 'Cry of Fear is a survival horror game that is a must-play for any horror game enthusiast. Developed by Team Psykskallar, it is a PC game that was initially a mod for Half-Life 1 but later became a standalone game. It has an incredibly engaging storyline and gameplay that is sure to keep the players on their toes. Players take on the role of Simon, a young man who wakes up in a dark alley with no memory of how he got there. The moment the game starts, players will be thrown into nerve-wracking situations, fighting off grotesque and terrifying monsters with a range of weapons. Every turn players take, every corner they turn, brings them closer to the truth behind Simon\'s predicament. The game has a strong psychological element to it, with Simon battling his own demons along with the physical ones. The world is dim and eerie, leaving the player feeling tense and uneasy. Cry of Fear is not just about fighting off monsters, it delves into themes of mental health, loneliness, and trauma.',
        submitted_by: 'https://www.youtube.com/leashfox',
        answers: ["Cry of Fear"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: 'Not Rated',
        genre: 'Survival horror, First-person shooter',
        console_platform: 'PC',
        developer: 'Team Psykskallar',
        backloggd_link: '',
    },
    '351': {
        content: 'Mercenaries: Playground of Destruction is a stunning action-packed game filled with explosions, wars, and thrilling missions. Set in the late 2000s, the game takes place in a fictional country of North Korea, where a power-hungry dictator has taken control of the nation. Players take on the role of a mercenary who has been hired to eradicate the dictator and his cronies.  As a mercenary, you have a vast array of weapons and vehicles at your disposal, and you are free to explore the game\'s open world. You can fly helicopters, drive tanks or cars, and even use boats to travel around the map. The game offers players the ability to choose between various factions, each with its set of missions, making each playthrough different and exciting. One of the most striking features of the game is its destructible environment. Nearly everything can be blown up, whether it\'s an enemy base or a whole building. Players can use explosives to level structures to the ground, creating new paths or uncovering hidden areas.  It\'s no wonder that Mercenaries: Playground of Destruction has earned a reputation as a timeless classic that action game enthusiasts will still be playing for years to come.',
        submitted_by: '',
        answers: ["Mercenaries: Playground of Destruction", "Mercenaries"],
        affiliate_links: ["https://amzn.to/3LllPbk"],
        franchise: 'Mercenaries',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '84',
        genre: 'Action-Adventure',
        console_platform: 'PS2, Xbox',
        developer: 'Pandemic Studios',
        backloggd_link: '',
    },
    '352': {
        content: 'Navigate the perils of space and deception in \"Among Us,\" a thrilling multiplayer game of teamwork and betrayal. Set aboard a spaceship, players take on the roles of Crewmates and Impostors, each with their own objectives. Crewmates work together to complete tasks and maintain the ship, while Impostors lurk in the shadows, sabotaging efforts and eliminating Crewmates one by one. Communication is key as players gather in meetings to discuss suspicions, share evidence, and vote to eject potential Impostors. But be careful—deception runs deep, and trust can be a dangerous game. With its simple yet addictive gameplay, \"Among Us\" offers endless opportunities for mind games, strategic plays, and heart-pounding moments. Customize your character with colorful skins and hats, and explore a variety of maps, each with its own unique challenges. Whether you\'re an honest Crewmate or a cunning Impostor, every round is a new adventure in this social deduction phenomenon. If you\'re a fan of games like \"Town of Salem\" and \"Mafia,\" you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Among Us", "Wsr\u00f3d nas"],
        affiliate_links: ["https://www.g2a.com/among-us-pc-steam-gift-global-i10000206485001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '85%',
        genre: 'Party, Social Deduction',
        console_platform: 'iOS, Android',
        developer: 'Innersloth',
        backloggd_link: '',
    },
    '353': {
        content: 'Killer 7 is a unique and compelling game that effortlessly blends action, adventure, and mystery genres into one unforgettable experience. The game follows the dark and twisted world of a group of assassins known as the killers 7, each with their unique personalities, strengths, and weaknesses. The game\'s storyline takes place in an alternate universe where the United States has been the victim of several terrorist attacks, causing the creation of a new political organization. In this world, the color red represents death, and the killers 7 are tasked with eliminating evil forces that threaten the country\'s peace. Players take on the role of the notorious Harman Smith, a wheelchair-bound man whose seven-personality alters manifest into the game\'s playable characters. The game\'s unique gameplay mechanics make for a fascinating experience, as players control one of the seven personalities to fight enemies, solve puzzles, and progress through the game\'s stylized environments. Along the way, players will encounter numerous eccentric characters whose stories and motives intertwine with the overarching storyline.',
        submitted_by: 'https://www.youtube.com/user/twist',
        answers: ["Killer 7", "Killer7"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=killer7%20Digital%20Limited%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkiller7-digital-limited-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/killer7-digital-limited-edition-steam-key-global-i10000175997001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '74',
        genre: 'Action, Adventure, Shooter',
        console_platform: 'Gamecube, PS2',
        developer: 'Grasshopper Manufacture, Capcom Production Studio 4',
        backloggd_link: '',
    },
    '354': {
        content: 'Star Wars Episode I: Racer is an adrenaline-fueled racing game perfect for Star Wars fans and racing enthusiasts alike. Based on the famous pod-racing scene from The Phantom Menace, the game offers players the opportunity to enter the fast-paced world of podracing and compete against an array of skilled opponents. Set across eight dynamic courses, players are able to select from a range of playable characters, each with their unique racing pod, and speed through hazardous terrains, utilizing special moves, power-ups, and upgrades to achieve victory. The game offers both single-player and multiplayer modes, including split-screen for two players, and can be played on multiple platforms, including the N64, PC, and Dreamcast.',
        submitted_by: '',
        answers: ["Star Wars Episode I: Racer", "Star Wars Episode 1: Racer", "Star Wars: Episode I - Racer"],
        affiliate_links: ["https://www.g2a.com/star-wars-episode-i-racer-xbox-one-xbox-live-key-united-states-i10000176126006?gtag=391e4ef696", "https://www.amazon.com/Star-Wars-Episode-Racer-nintendo-64/dp/B00000J2OO?crid=12DMDDWM3V2SW&keywords=Star+Wars+Episode+1%3A+Racer&qid=1682214372&refresh=1&sprefix=star+wars+episode+1+racer+%2Caps%2C484&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=e6f183d6350d7393edb344a16604844f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Star Wars',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: 'Too old for Metacritic!',
        genre: 'Racing',
        console_platform: 'PC, N64',
        developer: 'LucasArts',
        backloggd_link: '',
    },
    '355': {
        content: 'ULTRAKILL is an adrenaline-fueled first-person shooter game that is sure to get your blood pumping. This game is all about causing as much destruction and chaos as possible while making your way through a variety of challenging levels. In this game, you play as an advanced android on a mission to rid the world of its demonic invaders. Armed with an arsenal of deadly weapons and superpowers, you will have to fight your way through hordes of undead enemies, powerful bosses, and other terrifying creatures. As you progress through the levels, you will unlock new weapons, upgrades, and abilities that will help you take down your enemies even more efficiently. One of the standout features of ULTRAKILL is its retro-inspired graphics and sound design. The game looks and sounds like a classic first-person shooter from the 90s, with its pixelated graphics and thumping soundtrack. However, don\'t let the retro aesthetics fool you – this is a modern game through and through, with tight controls, challenging levels, and a deep progression system.',
        submitted_by: 'https://www.youtube.com/channel/UCSanPwlYc-7wWGoANz0NyPA',
        answers: ["ULTRAKILL"],
        affiliate_links: ["https://www.g2a.com/ultrakill-pc-steam-account-global-i10000218330007?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020 (Early Access)',
        metacritic_score: 'Not Rated',
        genre: 'First-person shooter, Platformer',
        console_platform: 'PC',
        developer: 'Arsi \"Hakita\" Patala',
        backloggd_link: '',
    },
    '356': {
        content: 'GunZ: The Duel is an adrenaline-fueled multiplayer game that combines fast-paced action, epic battles, and exciting gunplay. It is a third-person shooter where players can perform incredible acrobatic moves, such as flipping and wall-running, while shooting their opponents.  The game takes place in a world where two factions fight for control of a city. Players can choose to join the rebels, who fight for freedom and justice, or the empire, who seek to maintain order and authority. As they progress through the game, they can unlock new weapons, skills, and equipment, allowing them to customize their characters to suit their playstyle. GunZ: The Duel is a must-play for anyone who loves fast-paced, action-packed games. It\'s an immersive and addictive experience that will keep players coming back for more. So, pick up your gun and get ready for the ultimate duel!',
        submitted_by: '',
        answers: ["GunZ: The Duel"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: 'Not Rated',
        genre: '3rd-person, Shooter',
        console_platform: 'PC',
        developer: 'MAIET Entertainment',
        backloggd_link: '',
    },
    '357': {
        content: '',
        submitted_by: '',
        answers: ["Nobody Saves The World"],
        affiliate_links: ["https://www.g2a.com/nobody-saves-the-world-pc-steam-gift-global-i10000300787004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '79%',
        genre: 'Adventure, Action, RPG',
        console_platform: 'PC, PS4, Xbox, PS5, Switch',
        developer: 'DrinkBox Studios Inc.',
        backloggd_link: '',
    },
    '358': {
        content: 'Viewtiful Joe is an action-packed side-scrolling beat \'em up video game developed by Clover Studio and published by Capcom. First released in 2003 for the Nintendo GameCube, it features a cel-shaded art style that gives the game a unique comic book-like aesthetic. The game follows the story of an ordinary moviegoer named Joe, who is pulled into the world of his favorite superhero film and transforms into the titular character, Viewtiful Joe, in order to save his girlfriend, Sylvia.The gameplay is focused on fast-paced action and innovative combat mechanics, where players use a variety of moves and techniques to defeat enemies and progress through levels. One of the defining features of Viewtiful Joe is the VFX system, which grants players the ability to manipulate time and perform stunning visual effects. By activating Slow, Mach Speed, or Zoom-In modes, players can unleash powerful attacks, dodge enemy projectiles, and solve puzzles to advance through the game\'s challenging stages. Throughout the game, players can also collect V-Points to upgrade Joe\'s abilities and unlock new moves, making him an even more formidable fighter. With its stylish visuals, engaging story, and addictive gameplay, Viewtiful Joe has earned a cult following among action game enthusiasts. If you\'re a fan of games like Devil May Cry or Bayonetta, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Viewtiful Joe"],
        affiliate_links: ["https://www.amazon.com/Viewtiful-Joe-Gamecube-Renewed/dp/B07WV5C2TN?crid=2FOO44MFPURHP&keywords=Viewtiful+Joe&qid=1683161391&sprefix=god+of+war+ragnarok%2Caps%2C438&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=bb1d5894a3774622d5922d7fa6bbfe3c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Viewtiful Joe',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '93%',
        genre: 'Action',
        console_platform: 'Gamecube',
        developer: 'Capcom Production Studio 4',
        backloggd_link: '',
    },
    '359': {
        content: 'God of War: Ragnarok is an action-adventure game developed by Santa Monica Studio and published by Sony Interactive Entertainment. As the sequel to the critically acclaimed 2018\'s God of War, this highly anticipated installment continues the journey of Kratos, the former Greek god of war, and his son Atreus, as they navigate the treacherous realms of Norse mythology. The story picks up where the previous game left off, with Kratos and Atreus facing the impending doom of Ragnarok, a cataclysmic event that threatens to destroy the Norse cosmos. Along the way, they will encounter familiar faces and new adversaries, delving deeper into the complex relationships between gods, giants, and mortals in a world torn by strife and conflict.God of War: Ragnarok maintains the series\' signature hack-and-slash gameplay, combining brutal combat with intricate puzzle-solving and exploration. Players can expect a refined combat system, with new abilities and weapons at their disposal, as well as an expanded skill tree for both Kratos and Atreus. The game\'s stunning visuals and immersive world-building further immerse players in the rich narrative and breathtaking environments. With its intense action, compelling characters, and captivating story, God of War: Ragnarok is set to be a thrilling addition to the franchise. If you\'re a fan of games like The Last of Us Part II or Uncharted 4: A Thief\'s End, you\'ll be a fan of this one.',
        submitted_by: 'https://twitter.com/kaeyagod1',
        answers: ["God of War: Ragnarok", "God of War Ragnarok", "God of War: Ragnar\u00f6k"],
        affiliate_links: ["https://www.g2a.com/god-of-war-ragnarok-digital-deluxe-edition-ps5-psn-key-united-states-i10000302523005?gtag=391e4ef696", "https://www.amazon.com/Ragnarok-Launch-Game-PlayStation-Brand-Upgrade/dp/B0BPQQWLVR?crid=4DHXN62YSGIL&keywords=God+of+War%3A+Ragnarok&qid=1683161077&sprefix=god+of+war+ragnarok%2Caps%2C388&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=5a6eba78c466d84372a792c20ab067bf&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'God of War',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '96%',
        genre: 'Action, adventure, fighting',
        console_platform: 'PS4, PS5',
        developer: 'Santa Monica',
        backloggd_link: '',
    },
    '360': {
        content: 'Bayonetta is a fast-paced action-adventure hack and slash game developed by PlatinumGames and published by Sega. First released in 2009 for the Xbox 360 and PlayStation 3, the game follows the titular character, Bayonetta, a powerful witch who awakens after a 500-year slumber with no memory of her past. As players progress through the game, Bayonetta gradually unravels the mysteries surrounding her origins, the ancient war between the Umbra Witches and Lumen Sages, and the forces vying for control of her destiny. The gameplay of Bayonetta focuses on fluid combat mechanics, allowing players to chain together a variety of attacks, dodges, and acrobatic moves to defeat hordes of angelic enemies. Bayonetta\'s unique Witch Time mechanic, activated by dodging enemy attacks at the last moment, allows players to slow down time, giving them a chance to land powerful counterattacks and string together devastating combos. Throughout the game, players can acquire new weapons, abilities, and magical attacks, further expanding Bayonetta\'s arsenal and combat options. The game\'s over-the-top action sequences, stylish visuals, and engaging story have earned it a loyal fan base and critical acclaim, leading to sequels and spin-offs in the franchise. With its thrilling gameplay, memorable protagonist, and captivating narrative, Bayonetta is a standout entry in the action-adventure genre. If you\'re a fan of games like Devil May Cry or Ninja Gaiden, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Bayonetta"],
        affiliate_links: ["https://www.g2a.com/bayonetta-xbox-one-xbox-live-key-united-states-i10000036451017?gtag=391e4ef696", "https://www.amazon.com/Bayonetta-Digital-Bundle-Nintendo-Switch/dp/B09QQQZV29?crid=3FB339505GJKM&keywords=Bayonetta&qid=1683160621&sprefix=marvel+snap+game%2Caps%2C388&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=2385a5e9e5cae7a148303d88d7a030ae&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Bayonetta',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '89%',
        genre: 'Action, Hack & Slash',
        console_platform: 'X360, PS3',
        developer: 'Platinum Games Inc.',
        backloggd_link: '',
    },
    '361': {
        content: '',
        submitted_by: '',
        answers: ["Marvel Snap"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '85%',
        genre: 'Card Game',
        console_platform: 'Mobile, PC',
        developer: 'Second Dinner Studios, Inc.',
        backloggd_link: '',
    },
    '362': {
        content: 'Metal Gear Solid is a stealth action-adventure game developed by Konami Computer Entertainment Japan and directed by the legendary Hideo Kojima. Released in 1998 for the PlayStation, Metal Gear Solid is the third installment in the acclaimed Metal Gear series and has since become one of the most influential titles in the gaming industry. The game follows the story of Solid Snake, an elite soldier, as he infiltrates the heavily fortified military base, Shadow Moses Island, to neutralize a terrorist threat and prevent the launch of a nuclear warhead. The gameplay of Metal Gear Solid emphasizes stealth and tactical decision-making, encouraging players to avoid direct confrontation with enemies and instead use their wits and environment to progress. Players can employ various tools, gadgets, and weapons to outmaneuver and neutralize guards, security systems, and other obstacles. The game\'s rich narrative is presented through cinematic cutscenes, codec conversations, and environmental storytelling, delving into themes of politics, warfare, and the human condition. Metal Gear Solid\'s innovative gameplay mechanics, compelling story, and memorable characters have left a lasting impact on the gaming landscape, spawning numerous sequels and spin-offs. If you\'re a fan of games like Splinter Cell or Deus Ex, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Metal Gear Solid"],
        affiliate_links: ["https://www.amazon.com/Metal-Gear-Solid-Collection-Xbox-360/dp/B0050SYOOA?crid=2A2BXEMQECN4R&keywords=Metal+Gear+Solid&qid=1683160164&sprefix=metal+gear+solid%2Caps%2C399&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=d9c249242031c5eb42d2f678e1dddab6&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metal Gear',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '94%',
        genre: 'Action, Stealth',
        console_platform: 'PS1',
        developer: 'Konami Computer Entertainment Japan, Inc',
        backloggd_link: '',
    },
    '363': {
        content: 'Prototype is an open-world action-adventure game developed by Radical Entertainment and published by Activision. Released in 2009 for PlayStation 3, Xbox 360, and Windows, the game follows the story of Alex Mercer, a powerful shapeshifter who wakes up in a morgue with no memory of his past, but possessing extraordinary abilities. Set in a gritty and chaotic version of Manhattan, players must navigate the city, uncovering the truth about Alex\'s origins and the sinister conspiracy that has engulfed the metropolis. The gameplay of Prototype emphasizes Alex\'s superhuman powers, allowing players to traverse the city with incredible agility, scale buildings, and perform death-defying leaps. The combat system is a blend of brutal melee attacks, shape-shifting abilities, and powerful area-of-effect attacks. As players progress, they can upgrade and evolve Alex\'s abilities, unlocking new moves and powers to unleash upon the game\'s various enemies, including military forces, infected creatures, and other powerful adversaries. Prototype\'s open-world structure encourages exploration and offers a variety of side missions and challenges, in addition to the main story. Its compelling narrative, combined with the freedom to explore and experiment with Alex\'s powers, makes for an engaging and unique gaming experience. If you\'re a fan of games like inFAMOUS or Just Cause, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Prototype"],
        affiliate_links: ["https://www.g2a.com/prototype-biohazard-bundle-xbox-one-key-united-states-i10000169963001?gtag=391e4ef696", "https://www.amazon.com/Prototype-Biohazard-Bundle-Xbox-One/dp/B01MRXJW4R?crid=13R6KWGV227EC&keywords=Prototype&qid=1683159929&sprefix=prototype%2Caps%2C450&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=621abbb8f27d2d371c0fdf5152c1aa00&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Prototype',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '79%',
        genre: 'Action, Open-World',
        console_platform: 'PC, X360, PS3',
        developer: 'Radical Entertainment Inc.',
        backloggd_link: '',
    },
    '364': {
        content: 'Danganronpa 2: Goodbye Despair is a visual novel adventure game developed by Spike Chunsoft. Released in 2012 for the PlayStation Portable and later for other platforms, it is the sequel to the popular game Danganronpa: Trigger Happy Havoc. The game follows a group of high school students who find themselves trapped on a deserted island under the control of Monokuma, a sadistic bear-like figure. The only way to escape is to participate in a deadly game where the students must kill each other without getting caught. The gameplay of Danganronpa 2 revolves around investigating crime scenes, collecting evidence, and engaging in fast-paced \"Class Trials\" to determine the culprit. Players will need to use their wits and deductive reasoning to uncover the truth behind each murder, as well as the mysterious circumstances of their confinement. The game features a cast of eccentric characters, each with their own unique talents and personalities, who players will interact with and grow to care for throughout the story. Danganronpa 2 combines its dark and twisted narrative with stylish visuals, memorable characters, and engaging gameplay mechanics. The game\'s immersive storyline, filled with twists and turns, keeps players on the edge of their seats, while its mix of investigation, exploration, and trial segments provides a compelling gameplay experience. If you\'re a fan of games like Ace Attorney or Zero Escape, you\'ll be a fan of this one.',
        submitted_by: 'https://twitter.com/adeliebird',
        answers: ["Danganronpa 2: Goodbye Despair", "Danganronpa 2 Goodbye Despair"],
        affiliate_links: ["https://www.g2a.com/danganronpa-2-goodbye-despair-steam-gift-europe-i10000014269005?gtag=391e4ef696", "https://www.amazon.com/Danganronpa-2-Goodbye-Despair-PlayStation-Vita/dp/B00IFF0SIQ?crid=2VBBC4ICPYY9W&keywords=Danganronpa+2%3A+Goodbye+Despair+game&qid=1683159869&sprefix=danganronpa+2+goodbye+despair+gam%2Caps%2C361&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=72ced7577586e50c601827bbe1dbfca4&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Danganronpa',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '81%',
        genre: 'Visual Novel',
        console_platform: 'PSP',
        developer: 'Spike Chunsoft',
        backloggd_link: '',
    },
    '365': {
        content: 'Garry\'s Mod, often abbreviated as GMod, is a sandbox physics game developed by Facepunch Studios and created by Garry Newman. Originally released in 2004 as a mod for Valve\'s Source engine, it later became a standalone game in 2006, available for Windows, macOS, and Linux. Garry\'s Mod is unique in that it doesn\'t have a specific objective or storyline; instead, it provides players with a vast array of tools and assets, allowing them to create, experiment, and explore within a virtual world.nThe core gameplay of Garry\'s Mod is centered around manipulating in-game objects, such as props, characters, and vehicles, with a variety of tools, like the iconic Physics Gun and Tool Gun. Players can build intricate contraptions, stage elaborate scenes, or just mess around with the game\'s physics engine, all in a sandbox environment. One of the most appealing aspects of Garry\'s Mod is its extensive modding community, which has created countless custom game modes, maps, and add-ons, greatly expanding the game\'s potential. Popular game modes include Trouble in Terrorist Town, Prop Hunt, and DarkRP, each offering a unique multiplayer experience that caters to different playstyles and interests. Garry\'s Mod is a game that fosters creativity, experimentation, and collaboration, making it a beloved title among casual and hardcore gamers alike. If you\'re a fan of sandbox games like Minecraft or LittleBigPlanet, you\'ll be a fan of this one.',
        submitted_by: 'https://instagram.com/manuvicentiel',
        answers: ["Garry's Mod", "Garrys Mod"],
        affiliate_links: ["https://www.g2a.com/garrys-mod-steam-key-global-i10000004391012?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: 'Too old (and doesn\'t make sense)',
        genre: 'Sandbox',
        console_platform: 'PC',
        developer: 'Facepunch Studios',
        backloggd_link: '',
    },
    '366': {
        content: 'The Walking Dead is an episodic graphic adventure game developed and published by Telltale Games. Based on the popular comic book series by Robert Kirkman, the game was first released in 2012 and consists of four main seasons and additional spin-offs. The game is set in the same post-apocalyptic world as the comics, where players must navigate a world ravaged by the undead while making difficult decisions that will impact the story and the characters around them. The gameplay of The Walking Dead primarily focuses on narrative and character development, with players taking on the role of various protagonists throughout the series, most notably Lee Everett in the first season and Clementine in later seasons. The game presents players with dialogue options, moral choices, and quick-time events, all of which can influence the course of the story and the relationships between characters. The game\'s branching narrative means that each player\'s experience can be unique, with choices made in earlier episodes potentially affecting later events. The Walking Dead has been widely praised for its compelling storytelling, memorable characters, and emotional impact. The game\'s focus on choice and consequence, along with its rich and immersive world, make it a standout title in the narrative-driven adventure game genre. If you\'re a fan of games like Life is Strange or Heavy Rain, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["The Walking Dead"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Walking%20Dead%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-walking-dead%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-walking-dead-steam-key-global-i10000000521005?aid=13410310?gtag=391e4ef696", "https://www.amazon.com/Walking-Dead-Complete-First-Season-PlayStation/dp/B00K2O54V4?crid=O77O49L4JLBC&keywords=the+walking+dead+season+1+game&qid=1683159493&sprefix=the+walking+dead+season+1+game%2Caps%2C392&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=e19e195460b26a7daad28b91ac095082&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Walking Dead',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '89%',
        genre: 'Adventure, Narrative',
        console_platform: 'PC, Mac, X360, PS3',
        developer: 'Telltale, Inc.',
        backloggd_link: '',
    },
    '367': {
        content: 'Hearthstone is a popular online digital collectible card game developed and published by Blizzard Entertainment. Released in 2014, the game is set within the universe of the company\'s iconic Warcraft franchise, featuring characters, creatures, and lore from the beloved series. Hearthstone is available on multiple platforms, including Windows, macOS, iOS, and Android, and boasts a large and active player base. The gameplay of Hearthstone revolves around turn-based one-on-one card battles between players, who each build their decks from a collection of digital cards. Players can choose from a variety of hero classes, such as Mage, Paladin, or Hunter, each with its own unique set of cards and abilities. The goal of the game is to reduce the opponent\'s health points to zero by strategically playing cards, which represent minions, spells, and weapons, as well as utilizing the hero\'s special ability. Hearthstone is known for its accessible gameplay, charming visuals, and deep strategic depth, offering a compelling experience for both casual players and competitive gamers. The game is continuously updated with new cards, expansions, and special events, ensuring a dynamic and ever-evolving landscape for players to explore and master. Additionally, the game features various game modes, including ranked play, casual matches, arena, single-player adventures and, arguably the best mode, battlegrounds.',
        submitted_by: '',
        answers: ["Hearthstone"],
        affiliate_links: ["https://www.g2a.com/hearthstone-booster-pack-code-battlenet-global-i10000042761002?gtag=391e4ef696", "https://www.amazon.com/Hearthstone-Card-Puzzle-Blizzard-Entertainment/dp/1945683848?crid=UNZE5VY96C9E&keywords=hearthstone+game&qid=1683158761&sprefix=Hearthston%2Caps%2C440&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=15de01cec6fb38ab392a22017da9bb15&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Warcraft',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '88%',
        genre: 'Strategy, Turn-based, Cards',
        console_platform: 'PC, Macintosh, iPhone, Android, iPad',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '368': {
        content: 'Streets of Rage 4 is a side-scrolling beat \'em up game developed by Dotemu, Lizardcube, and Guard Crush Games, and published by Dotemu. Released in 2020 for multiple platforms, it is the long-awaited sequel to the classic Streets of Rage series, which originally gained popularity on the Sega Genesis in the early 1990s. The game brings back the iconic characters Axel, Blaze, and others, as well as introduces new fighters, to face the crime syndicate that has taken over Wood Oak City. The gameplay of Streets of Rage 4 maintains the series\' signature fast-paced action, with players able to unleash a variety of punches, kicks, throws, and special moves to defeat waves of enemies and progress through the game\'s stages. The combat system has been refined and expanded, allowing for more intricate combos, juggling, and the ability to recover health through successful attacks after using special moves. The game also features a combo counter that rewards players for executing long chains of uninterrupted attacks. Streets of Rage 4\'s hand-drawn art style and animation bring the game to life, capturing the essence of the original series while providing a modern visual upgrade.  If you\'re a fan of beat \'em up games like Final Fight or River City Ransom, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Streets of Rage 4"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Streets%20of%20Rage%204%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstreets-of-rage-4-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/streets-of-rage-4-pc-steam-gift-global-i10000195280003?gtag=391e4ef696", "https://www.amazon.com/Streets-Rage-4-Anniversary-Nintendo-Switch/dp/B098TWJKFW?crid=3SXX5DFG7Z584&keywords=Streets+of+Rage+4&qid=1683158160&sprefix=streets+of+rage+4%2Caps%2C381&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=17e4b0285a52d69be6491dcafca7a62a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Streets of Rage',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '82%',
        genre: 'Action, Beat Em Up, Fighting',
        console_platform: 'XONE, PC, PS4, Switch, Mac',
        developer: 'DotEmu SAS, Guard Crush Games Inc., Lizardcube',
        backloggd_link: '',
    },
    '369': {
        content: 'Mike Tyson\'s Punch-Out!! is a classic boxing-themed sports game developed and published by Nintendo for the Nintendo Entertainment System (NES). Released in 1987, the game features the player controlling a young boxer named Little Mac, who aims to rise through the ranks and become the World Video Boxing Association (WVBA) champion. The final boss of the game is none other than the legendary heavyweight champion, Mike Tyson, who was at the height of his career when the game was released. The gameplay of Mike Tyson\'s Punch-Out!! revolves around one-on-one boxing matches, with players needing to carefully observe their opponents\' movements and patterns to find openings and land successful punches. Players can throw left and right jabs, uppercuts, and body blows, while also dodging, blocking, and countering enemy attacks. As Little Mac faces increasingly challenging opponents, mastering the timing and strategy of each match becomes crucial to progress through the game. Mike Tyson\'s Punch-Out!! is known for its colorful cast of characters, each with their own unique fighting styles and personalities, as well as its addictive gameplay and steep difficulty curve. The game has become a beloved classic and a staple of the NES library, paving the way for future boxing games and sports titles. If you\'re a fan of games like Super Punch-Out!! or Ready 2 Rumble Boxing, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Mike Tyson's Punch-Out!!", "Punch-Out!!", "Punch Out!!"],
        affiliate_links: ["https://www.amazon.com/Mike-Tysons-Punch-Out-Nintendo-entertainment-system/dp/B07P144PCT?crid=3G7X1DHM5CCHG&keywords=Mike+Tyson%27s+Punch-Out%21%21&qid=1683157992&sprefix=mike+tyson%27s+punch-out+%2Caps%2C524&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=30cbd56e8b265e03df537ddf06f9ed72&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Punch-Out!!',
        twitter_ids: [],
        release_year: '1987',
        metacritic_score: 'Too old',
        genre: 'Sports, Boxing',
        console_platform: 'NES',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '370': {
        content: 'Microsoft Flight Simulator is a realistic flight simulation game developed by Asobo Studio and published by Xbox Game Studios. Initially released in 1982, the game has undergone numerous iterations. The game allows players to experience the thrill of flying a wide variety of aircraft, from small propeller planes to massive airliners, in a meticulously detailed and accurate virtual world. The gameplay of Microsoft Flight Simulator emphasizes realism and authenticity, providing players with an extensive range of controls and instruments to manage their aircraft, along with realistic physics and weather systems. The game boasts a vast and impressive open-world environment, powered by Bing Maps and Microsoft\'s Azure AI technology, which accurately recreates the entire Earth, complete with true-to-life topography, cities, landmarks, and airports. Players can undertake various types of flights, ranging from short hops between local airports to long-haul journeys across continents, as well as participate in challenges and missions to test their piloting skills. The game also offers a comprehensive set of tutorials and assists, making it accessible to both newcomers and experienced flight sim enthusiasts. If you\'re a fan of games like X-Plane or IL-2 Sturmovik, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Flight Simulator", "Microsoft Flight Simulator"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Microsoft%20Flight%20Simulator%2040th%20Anniversary%20Premium%20Deluxe%20Edition%20-%20Xbox%20Series%20XS&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmicrosoft-flight-simulator-40th-anniversary-premium-deluxe-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/microsoft-flight-simulator-premium-deluxe-40th-anniversary-edition-pc-steam-gift-global-i10000195151042?gtag=391e4ef696", "https://www.amazon.com/Microsoft-Flight-Simulator-Anniversary-Premium/dp/B0BJH5T8LV?crid=X2SFYP5RFBCA&keywords=Microsoft+Flight+Simulator&qid=1683157903&sprefix=microsoftflight+simulator%2Caps%2C541&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=2e80942968bad2c899eb9d32e5207eec&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Microsoft Flight Simulator',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '91%',
        genre: 'Simulation',
        console_platform: 'PC',
        developer: 'Asobo Studio S.A.R.L.',
        backloggd_link: '',
    },
    '371': {
        content: 'Spiritfarer is a unique management simulation game developed and published by Thunder Lotus Games. Released in 2020 for multiple platforms, the game beautifully intertwines aspects of resource management and platforming with a poignant narrative. Players assume the role of Stella, a \"Spiritfarer\" who ferries the spirits of the deceased to the afterlife, helping them find peace before they pass on.    The gameplay of Spiritfarer involves exploring a colorful hand-drawn world by boat, gathering resources, crafting items, and befriending spirits. As Stella, players interact with various spirit passengers, each with their own stories and quests. You\'ll need to cater to their needs, build and upgrade facilities on your boat, and ultimately guide them to the Everdoor when they\'re ready to say their goodbyes.    Amidst its gameplay mechanics, Spiritfarer stands out for its heartfelt storytelling and emotional depth. The game artfully addresses themes of death, acceptance, and letting go, delivering a touching exploration of loss and connection through its memorable characters. With its captivating visuals, soothing music, and emotionally charged narrative, Spiritfarer offers a moving and thought-provoking experience that stays with players long after they\'ve finished playing. If you\'re a fan of games like Stardew Valley or Journey, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Spiritfarer"],
        affiliate_links: ["https://www.g2a.com/spiritfarer-pc-steam-gift-global-i10000217195001?gtag=391e4ef696", "https://www.amazon.com/iam8bit-0811949033192-Spiritfarer-Nintendo-Switch/dp/B096BMD67X?crid=1C2WEQZQ2TLU5&keywords=Spiritfarer&qid=1684194269&sprefix=spiritfarer%2Caps%2C401&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=7b029d67ddc806598657fb8848b55645&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '84%',
        genre: 'Management simulation, Action platformer',
        console_platform: 'PC, Switch, PS4, XONE, Stadia',
        developer: 'Thunder Lotus Games',
        backloggd_link: '',
    },
    '372': {
        content: 'And Yet It Moves is a unique puzzle-platformer game developed and published by independent developer Broken Rules. First released in 2009 for Windows, macOS, and Linux, the game later arrived on the WiiWare service in 2010. In And Yet It Moves, players control a paper collage character navigating a world that appears as if it was torn out from a sketchbook. The game\'s standout feature is its innovative mechanic that allows players to rotate the entire game world.    The gameplay revolves around this rotation mechanic, as players must twist and turn the environment to overcome obstacles, solve puzzles, and progress through the game\'s levels. By manipulating the world around the character, players can change the direction of gravity, transform walls into floors, and use momentum to fling the character across chasms. This mechanic requires players to think creatively and approach challenges from different perspectives.    And Yet It Moves\' distinctive art style, inspired by paper collage, and its innovative gameplay mechanics have garnered significant praise. The game manages to blend its unique visual aesthetics with cleverly designed puzzles to create an immersive and challenging experience. If you\'re a fan of puzzle-platformers like Braid or Limbo, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["And Yet It Moves"],
        affiliate_links: ["https://www.g2a.com/and-yet-it-moves-steam-key-global-i10000043345002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '75%',
        genre: 'Puzzle-platform',
        console_platform: 'PC',
        developer: 'Broken Rules',
        backloggd_link: '',
    },
    '373': {
        content: 'Dragon Age: Origins is a critically acclaimed role-playing game developed by BioWare and published by Electronic Arts. Released in 2009, the game is set in the dark fantasy world of Thedas and offers an immersive narrative-driven experience. Players begin by creating a character, choosing from various races (human, elf, or dwarf) and classes (warrior, rogue, or mage), and defining their backstory, which significantly impacts the unfolding of the game\'s narrative.    The gameplay of Dragon Age: Origins combines tactical real-time combat with pause-and-play strategies, allowing players to meticulously plan their moves or charge headfirst into the fray. As the story unfolds, players are faced with morally ambiguous choices that shape the world around them and the way characters perceive and interact with them. The game also features a deep character customization system and a robust party management mechanic, with companions who have their own personalities, motivations, and arcs.    Dragon Age: Origins is widely recognized for its richly detailed world, complex characters, and branching narrative, offering a compelling RPG experience that rewards thoughtful decision-making and strategic combat. The game\'s blend of immersive storytelling and deep role-playing elements has left a lasting impact on the genre. If you\'re a fan of games like Baldur\'s Gate or The Witcher series, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Dragon Age: Origins", "Dragon Age Origins"],
        affiliate_links: ["https://www.g2a.com/dragon-age-origins-ultimate-edition-steam-gift-global-i10000044805001?gtag=391e4ef696", "https://www.amazon.com/Dragon-Age-Origins-Ultimate-Xbox-Renewed/dp/B07XKTYJM6?crid=1CXKJ8Y8XXOOO&keywords=Dragon+Age+Origins&qid=1684193648&sprefix=ico%2Caps%2C575&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=eccdc941353268f14a9a6e93240ced23&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Dragon Age',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '91%',
        genre: 'RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '374': {
        content: 'Ico is a genre-defining action-adventure game developed by Team Ico and published by Sony Computer Entertainment. Released in 2001 for the PlayStation 2, the game presents a young boy named Ico who finds himself trapped in a mysterious and hauntingly beautiful castle. Along the way, he befriends Yorda, a fragile and ethereal girl, whom he must protect and guide through various puzzles and dangers as they attempt to escape.    The gameplay of Ico is a blend of puzzle-solving, platforming, and combat, with a strong emphasis on exploration and environmental interaction. However, what truly sets the game apart is its minimalistic design and the emotional connection it fosters between the player and Yorda. The game uses a simple, wordless narrative, subtle visual cues, and atmospheric sound design to create an immersive and emotionally engaging experience.    Ico is renowned for its artistic direction, innovative gameplay, and the emotional depth of its narrative. The game has been cited as a prime example of video games as an art form, influencing numerous other titles in the adventure genre. The bond between Ico and Yorda, and their shared struggle, resonate deeply, offering a gaming experience that is both poignant and memorable. If you\'re a fan of games like The Last Guardian or Journey, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Ico"],
        affiliate_links: ["https://www.amazon.com/Ico-Renewed-Playstation-2/dp/B083H9MSVZ?crid=2F73MPJ3ZJ5KV&keywords=Ico&qid=1684193336&sprefix=ico%2Caps%2C577&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=efff84b63f86ec516116b386847ade08&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ico',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '90%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Japan Studio, Team Ico',
        backloggd_link: '',
    },
    '375': {
        content: 'Limbo is a renowned indie puzzle-platformer game developed and published by Playdead. Released in 2010, Limbo quickly became a standout title due to its unique aesthetics, atmospheric gameplay, and thought-provoking narrative. The game follows a nameless boy who awakens in the middle of a dark, desolate landscape, and embarks on a quest to find his missing sister.    The gameplay of Limbo is characterized by its blend of platforming and puzzle-solving elements. Players must navigate the boy through various environmental hazards and traps, utilizing objects and mechanisms to progress. The game is known for its trial-and-error approach, with many puzzles and challenges designed to surprise and catch players off guard.    Limbo\'s distinctive monochrome art style, combined with its minimalist sound design and ambient soundtrack, creates a haunting and eerie atmosphere that permeates the entire game. The world of Limbo is both bleak and mesmerizing, filled with unsettling imagery and profound silences that contribute to a feeling of isolation and unease.    The game has been widely praised for its artistic direction, innovative gameplay, and the subtlety of its storytelling, making it a memorable experience in the indie gaming scene. If you\'re a fan of games like Inside or Braid, you\'ll be a fan of Limbo.',
        submitted_by: '',
        answers: ["Limbo"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=INSIDE%20%20LIMBO%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Finside-limbo%2F&intsrc=PUI2_9608", "https://www.g2a.com/inside-limbo-bundle-xbox-live-key-xbox-one-united-states-i10000179354001?gtag=391e4ef696", "https://www.amazon.com/Inside-Limbo-Double-Pack-PS4-playstation-4/dp/B073PHLHP8?crid=OR3Q9PAL6NZN&keywords=inside+limbo+double+pack&qid=1684193233&sprefix=inside+limbo%2Caps%2C623&sr=8-3&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=54159f86b00f323552cd0d695d038409&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '90%',
        genre: 'Puzzle-platform',
        console_platform: 'X360',
        developer: 'Playdead',
        backloggd_link: '',
    },
    '376': {
        content: 'Lemmings is a classic puzzle-platformer video game originally developed by DMA Design and published by Psygnosis in 1991. It became an instant success and has since been ported and remade for many different platforms. In Lemmings, players are tasked with guiding a flock of the titular creatures through various levels filled with obstacles and hazards.    The gameplay of Lemmings revolves around assigning specific roles to individual lemmings to safely navigate them through the landscape. These roles include diggers, climbers, builders, and blockers, among others. Each level requires strategic use of these roles to guide the lemmings to the exit, while preventing as many as possible from meeting unfortunate ends.    Lemmings is notable for its unique gameplay concept, charming pixel-art graphics, and catchy music. The game is known for its increasingly challenging levels, which require clever strategy and careful planning to solve. Its blend of cute characters and deceptively complex puzzles made it a standout title during its initial release and it continues to be appreciated as a classic of the puzzle genre.    The game has inspired numerous sequels and spin-offs, and its core mechanic of guiding character groups has influenced a variety of other games in the puzzle genre. If you\'re a fan of games like The Lost Vikings or World of Goo, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Lemmings"],
        affiliate_links: ["https://www.amazon.com/Lemmings-Sega-Game-Gear/dp/B00004R9GN?crid=7KU2J81I9XW5&keywords=Lemmings+game&qid=1684363523&sprefix=lemmings+gam%2Caps%2C431&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=7ae84b3aa2dcab1d57a0e8acc3ecd299&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Lemmings',
        twitter_ids: [],
        release_year: '1991',
        metacritic_score: 'Too old',
        genre: 'Puzzle, Strategy',
        console_platform: 'Amiga',
        developer: 'DMA Design',
        backloggd_link: '',
    },
    '377': {
        content: 'Spider-Man is an action-adventure game developed by Insomniac Games and published by Sony Interactive Entertainment. Released exclusively for the PlayStation 4, the game puts players in the shoes of the iconic Marvel superhero Spider-Man in a beautifully realized open-world version of modern-day New York City.    The gameplay of Spider-Man focuses on Spider-Man\'s traversal abilities, combat mechanics, and stealth. Players can swing through the city using Spider-Man\'s web-slinging abilities, which are used not just for transportation, but also in combat and environmental puzzles. The combat system is dynamic, allowing Spider-Man to engage enemies directly, use his environment, or take them out silently using stealth.    The narrative in Spider-Man is set in a universe that, while familiar to fans, offers a fresh take on Spider-Man\'s lore. The game features an older, more experienced Peter Parker who has to balance his crime-fighting duties with his personal life. It boasts a cast of characters from the Spider-Man universe, including Mary Jane Watson, Aunt May, and a host of famous villains.    Spider-Man received widespread acclaim for its narrative, character development, combat, and web-slinging mechanics, becoming one of the standout titles of the PS4 era. If you\'re a fan of games like Batman: Arkham series or Infamous Second Son, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Spider-Man", "Marvel's Spider-Man", "Spiderman"],
        affiliate_links: ["https://www.g2a.com/marvels-spider-man-remastered-pc-steam-gift-global-i10000302546006?gtag=391e4ef696", "https://www.amazon.com/Marvels-Spider-Man-Game-Year-PlayStation-4/dp/B07WLT1C27?crid=35QEFUB95PBVF&keywords=spiderman+ps4&qid=1684281584&s=videogames&sprefix=Spiderman%2Cvideogames%2C410&sr=1-1&linkCode=ll1&tag=guessthegam06-20&linkId=572ad786aab153fde265928643c48ace&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Spider-Man',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '87%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Insomniac Games',
        backloggd_link: '',
    },
    '378': {
        content: 'Loop Hero is a distinctive indie game developed by Four Quarters and published by Devolver Digital. Released in 2021, Loop Hero blends elements of deck-building, roguelike, and idle genres into a unique gameplay experience. Set in a world thrown into a timeless loop by a malevolent Lich, players control a hero who must journey through this looping path, combating a variety of creatures and slowly uncovering the truth behind the Lich\'s destructive force.    The gameplay of Loop Hero involves placing cards to create a path for the hero, who automatically traverses the loop and engages with enemies, treasure chests, and other encounters. The cards, which can represent enemies, buildings, terrain, or buffs, directly influence the hero\'s journey and growth. With each completed loop, the challenges increase, but so do the rewards.    Loop Hero stands out for its unique mechanics, retro pixel-art aesthetics, and atmospheric soundtrack, creating a sense of dread and mystery. It requires strategic deck-building and careful management of resources, offering a compelling blend of active decision-making and passive progression. The game\'s intriguing narrative slowly unfolds as players advance through the loops, adding a layer of depth to the gameplay.    Loop Hero has been acclaimed for its innovative design and addictive gameplay, offering a fresh twist on familiar genres. If you\'re a fan of games like Slay the Spire or Darkest Dungeon, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Loop Hero"],
        affiliate_links: ["https://www.g2a.com/loop-hero-pc-steam-gift-global-i10000237277005?gtag=391e4ef696", "https://www.amazon.com/Loop-Hero-Standard-Nintendo-Digital/dp/B09NWHQYXY?keywords=Loop+Hero&qid=1684281347&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=1c121e71751553b61fde492adcd0400e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '82%',
        genre: 'RPG, Roguelike, Deck-building game',
        console_platform: 'PC',
        developer: 'Four Quarters',
        backloggd_link: '',
    },
    '379': {
        content: 'Dust: An Elysian Tail is an action role-playing game developed by independent designer Dean Dodrill and published by Microsoft Studios. Released in 2012, the game is set in the beautifully hand-painted world of Falana, and follows the story of Dust, a warrior who sets off on a journey to reclaim his lost memories, accompanied by an anthropomorphic flying sword named Ahrah and a nimble companion named Fidget.    The gameplay in Dust: An Elysian Tail combines hack-and-slash action with RPG elements. As Dust, players engage in real-time combat using a combination of swordplay and magic attacks. The game\'s unique combat system allows players to string together a series of attacks into spectacular combos. As players progress, they can level up Dust, customize his skills, and acquire new abilities. Exploration of the game\'s vibrant world is also a key aspect, with numerous secrets and side quests scattered throughout the environments.    Dust: An Elysian Tail is celebrated for its lush visuals, engaging combat, and compelling storytelling. The game\'s hand-drawn art, fluid animations, and immersive soundtrack create a captivating and atmospheric experience. Its blend of action, exploration, and RPG mechanics offers a rewarding and memorable adventure. If you\'re a fan of games like Ori and the Blind Forest or Hollow Knight, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Dust: An Elysian Tail", "Dust An Elysian Tail"],
        affiliate_links: ["https://www.g2a.com/dust-an-elysian-tail-gogcom-key-global-i10000001464005?gtag=391e4ef696", "https://www.amazon.com/Dust-Elysian-Tail-Switch-Limited-Nintendo/dp/B07PHWRNK1?crid=2OZL7S0MC6TB3&keywords=Dust+An+Elysian+Tail&qid=1684280428&sprefix=dust+an+elysian+tail%2Caps%2C494&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=1fb271cbfde3f6c6ccc7c2693d73738c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '83%',
        genre: 'Action, RPG, hack and slash, Metroidvania',
        console_platform: 'X360',
        developer: 'Humble Hearts',
        backloggd_link: '',
    },
    '380': {
        content: 'Guitar Hero is a popular rhythm game series originally developed by Harmonix and published by RedOctane. First launched in 2005, the franchise quickly gained fame for its innovative gameplay and use of a guitar-shaped controller. Players take on the role of an aspiring musician and play along to various rock music tracks, hitting the right notes at the right time to rack up points and achieve stardom.    In Guitar Hero, players use the guitar controller to simulate playing lead, bass, or rhythm guitar across numerous rock music songs. The gameplay involves matching colored notes that scroll on-screen to colored fret buttons on the controller, strumming in time to the music in order to score points. The game features various difficulty levels, providing a challenge for beginners and experienced players alike.    Guitar Hero is known for its engaging and accessible gameplay, immersive rock star experience, and extensive library of playable songs. Its success helped to popularize the rhythm game genre and inspired a host of other music and rhythm games. The game and its numerous sequels and spin-offs have featured music from a wide range of artists and genres, making it a hit among music lovers and gamers.    If you\'re a fan of rhythm games like Rock Band or Dance Dance Revolution, you\'ll be a fan of Guitar Hero.',
        submitted_by: '',
        answers: ["Guitar Hero"],
        affiliate_links: ["https://www.amazon.com/Guitar-Hero-Live-2-Pack-Bundle-PlayStation/dp/B00ZQB7FME?crid=1IC79ADBDBQ0E&keywords=Guitar+Hero&qid=1684280262&sprefix=guitar+hero%2Caps%2C407&sr=8-4&ufe=app_do%3Aamzn1.fos.2b70bf2b-6730-4ccf-ab97-eb60747b8daf&linkCode=ll1&tag=guessthegam06-20&linkId=a0238b910b47cbed16344538852b2da9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Guitar Hero',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '91%',
        genre: 'Music, Rhythm',
        console_platform: 'PS2',
        developer: 'Harmonix',
        backloggd_link: '',
    },
    '381': {
        content: 'Star Fox 64, known as Lylat Wars in Europe and Australia, is an iconic 3D rail shooter game developed and published by Nintendo for the Nintendo 64 console. Released in 1997, the game casts players as Fox McCloud, the leader of the Star Fox team, a group of ace pilots on a mission to save the Lylat system from the evil scientist Andross.    The gameplay of Star Fox 64 is centered around piloting various vehicles, primarily the Arwing starfighter, through a series of levels while battling enemy forces. The game features \"Corridor Mode\" for linear stages, and \"All-Range Mode\" for open area stages where players can freely navigate. Players can perform various maneuvers like barrel rolls, somersaults, and U-turns, and make use of power-ups and special weapons to defeat enemies.    Star Fox 64 is renowned for its fast-paced action, branching level paths, and memorable voice acting. It introduced the Rumble Pak, an accessory that provides force feedback, enhancing the immersive experience. The game\'s multiplayer mode, where up to four players can engage in aerial combat, adds another level of excitement.    Star Fox 64 remains a celebrated title in Nintendo\'s lineup for its engaging gameplay, cinematic presentation, and innovative features. If you\'re a fan of games like Rogue Squadron or Panzer Dragoon, you\'ll be a fan of Star Fox 64.',
        submitted_by: '',
        answers: ["Star Fox 64", "Lylat Wars"],
        affiliate_links: ["https://www.amazon.com/Star-Fox-Rumble-Pak-Nintendo-64/dp/B00002STGU?crid=31FKR5VPMWQIX&keywords=Star+Fox+64&qid=1684280000&sprefix=star+fox+64%2Caps%2C393&sr=8-9&linkCode=ll1&tag=guessthegam06-20&linkId=df45dc3c06669a01af1d27b95c0e519a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Star Fox',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '88%',
        genre: 'Rail shooter, Shoot \'em up',
        console_platform: 'N64',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '382': {
        content: 'Xenoblade Chronicles is an epic role-playing game developed by Monolith Soft and published by Nintendo. First released in 2010 for the Wii, the game takes place on the bodies of two enormous titans, the Bionis and the Mechonis, and follows the story of Shulk, a young man who wields a powerful weapon known as the Monado, as he battles against the machine-like Mechon.    Xenoblade Chronicles is renowned for its intricate real-time combat system, vast open-world environments, and compelling narrative. The game employs a unique Auto-Attack system where characters attack automatically when enemies are in range. Special abilities called \"Arts\" can be used for additional damage or effects, and strategic positioning and timing of these abilities are crucial to success in battles.    The world of Xenoblade Chronicles is vast and teeming with life, offering hundreds of quests and a plethora of unique monsters to discover. Its rich narrative, filled with twists and turns, is supplemented by a deeply engaging character development system. The game also features a day-night cycle that affects the environment, quests, and creature activity, adding another layer of immersion.    Xenoblade Chronicles has been acclaimed for its ambitious scope, innovative combat, and compelling storytelling, offering an immersive and expansive RPG experience. If you\'re a fan of games like Final Fantasy XII or The Legend of Zelda: Breath of the Wild, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Xenoblade Chronicles"],
        affiliate_links: ["https://www.g2a.com/xenoblade-chronicles-definitive-edition-nintendo-switch-nintendo-eshop-key-north-america-i10000007909004?gtag=391e4ef696", "https://www.amazon.com/Xenoblade-Chronicles-Definitive-Switch-Digital/dp/B086ZBW3R9?crid=OCPP0KU0T5YU&keywords=Xenoblade%2BChronicles&qid=1684279443&sprefix=perfect%2Bdark%2Caps%2C1679&sr=8-1&th=1&linkCode=ll1&tag=guessthegam06-20&linkId=6267a7be8ec562a1fa00a69a5dac4873&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Xenoblade',
        twitter_ids: [],
        release_year: '2010 (Japan)',
        metacritic_score: '92%',
        genre: 'Action, RPG',
        console_platform: 'Wii',
        developer: 'Monolith Soft',
        backloggd_link: '',
    },
    '383': {
        content: 'Perfect Dark is a first-person shooter game developed by Rare and published by Nintendo for the Nintendo 64 console. Released in 2000, the game is set in a futuristic world and follows Joanna Dark, an agent working for the Carrington Institute, as she uncovers a conspiracy involving a rival corporation and an extraterrestrial species.    The gameplay of Perfect Dark builds upon the success of its spiritual predecessor, GoldenEye 007, featuring a blend of stealth and action. Players control Joanna through a series of missions, utilizing a wide array of weapons and gadgets to combat enemies, disable security systems, and complete objectives. The game is also known for its cooperative and counter-operative modes, as well as its competitive multiplayer mode, which includes AI opponents.    Perfect Dark stands out for its advanced graphics, complex AI, and immersive narrative. Its multiplayer mode, which can be customized with a variety of options and scenarios, is particularly acclaimed. Another unique feature is the inclusion of a secondary function for each weapon, providing players with increased strategic options during combat.    Perfect Dark received widespread praise upon release and is often considered one of the best games on the Nintendo 64 for its detailed environments, deep gameplay mechanics, and impressive multiplayer options. If you\'re a fan of games like GoldenEye 007 or the Halo series, you\'ll be a fan of Perfect Dark.',
        submitted_by: '',
        answers: ["Perfect Dark"],
        affiliate_links: ["https://www.amazon.com/Perfect-Dark-Renewed-nintendo-64/dp/B085LQSLN6?crid=JE110R9JO76F&keywords=Perfect+Dark&qid=1684278720&sprefix=perfect+dark%2Caps%2C379&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=4683c2b9975a42ac0564e2ebb7e20e7c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Perfect Dark',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '97%',
        genre: 'First-person shooter, Stealth',
        console_platform: 'N64',
        developer: 'Rare',
        backloggd_link: '',
    },
    '384': {
        content: 'Myst is a groundbreaking adventure puzzle game developed by Cyan and published by Brøderbund. First released in 1993, Myst transports players to a beautiful yet eerie island filled with intricate puzzles and mysterious structures. As an unnamed protagonist, you explore this seemingly deserted island, uncovering its secrets and the story of its previous inhabitants.    The gameplay in Myst is primarily focused on exploration and puzzle-solving. The island and its various linked worlds, known as \"Ages\", are rich in detail and filled with complex, interconnected puzzles. Players interact with the world by clicking on objects, manipulating them, or triggering events that provide clues or progress the story.    Myst was a pioneer in the use of pre-rendered 3D graphics, which created a stunningly immersive and visually rich experience that was unprecedented at the time of its release. Its narrative, subtly told through environmental cues and discovered texts, adds to the game\'s sense of mystery and intrigue.    Myst\'s combination of innovative graphics, challenging puzzles, and immersive storytelling captivated players and critics alike, making it one of the best-selling PC games for several years. If you\'re a fan of adventure puzzle games like The Witness or the Riven series, you\'ll be a fan of Myst.',
        submitted_by: '',
        answers: ["Myst"],
        affiliate_links: ["https://www.g2a.com/myst-masterpiece-edition-steam-key-global-i10000049337002?gtag=391e4ef696", "https://www.amazon.com/Myst-Masterpiece-pc/dp/B000FJG2S0?crid=2YTDA1I280113&keywords=Myst%3A+complete&linkCode=ll1&tag=guessthegam06-20&linkId=63df30e77f9c8e8a02b5c77eae97d6b1&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Myst',
        twitter_ids: [],
        release_year: '1993',
        metacritic_score: 'Too old',
        genre: 'Graphic adventure',
        console_platform: 'Macintosh, PC',
        developer: 'Cyan',
        backloggd_link: '',
    },
    '385': {
        content: 'Unpacking is a zen puzzle game developed by Witch Beam Games. Released in 2021, Unpacking invites players into a meditative, introspective journey expressed through the act of unpacking. Players experience the life of a character purely through the items they own and how they arrange them within their living spaces, gradually revealing a narrative through environmental storytelling.    Gameplay in Unpacking is simple yet captivating. Players unpack boxes in a variety of living spaces across several time periods. Each level presents a new environment to fill, from a single bedroom to an entire apartment. The challenge arises in figuring out the best place to store each item, often requiring clever use of space.    Unpacking stands out with its pixel art aesthetic, soothing soundscape, and lack of time pressure or scoring, offering a calming experience. It touches upon themes of growing up, moving on, and the emotional attachment we build with objects. These aspects, along with the silent narrative that unfolds, provide an emotional depth that resonates with many players.    Unpacking was met with acclaim for its unique concept, intuitive gameplay, and the ability to evoke nostalgia and sentimentality. If you\'re a fan of relaxing, thoughtful games like Animal Crossing or The Sims, you\'ll be a fan of Unpacking.',
        submitted_by: '',
        answers: ["Unpacking"],
        affiliate_links: ["https://www.g2a.com/unpacking-pc-steam-gift-global-i10000272554004?gtag=391e4ef696", "https://www.amazon.com/Unpacking-Nintendo-Switch/dp/B0BGYDR5XF?crid=2UB09A7STUXRN&keywords=unpacking+game&qid=1685574136&sprefix=Unpacking%2Caps%2C377&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=0f63fe7d52c15d03a964544c332b7c4b&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '84%',
        genre: 'Puzzle, Point-and-click',
        console_platform: 'PC, Switch, XONE, then PS4, PS5',
        developer: 'Witch Beam',
        backloggd_link: '',
    },
    '386': {
        content: 'Black & White is a god game developed by Lionhead Studios and published by Electronic Arts. Released in 2001, the game allows players to assume the role of a deity who must guide their followers, manage their civilization, and interact with other gods in a quest for dominance over the world.    The gameplay in Black & White combines elements of real-time strategy, city building, and creature training. Players can interact with the world either indirectly, by using miracles and divine interventions, or directly, through their creature, a giant animal avatar that can be trained to perform various tasks and reflect the player\'s moral alignment.    Players can choose to be a benevolent god, providing for their followers and nurturing their civilization, or a malevolent god, ruling through fear and destruction. The game\'s environment, narrative, and even the behavior of the creature avatar will shift according to the player\'s choices, offering a dynamic and morally complex gaming experience.    Black & White is celebrated for its ambitious concept, unique gameplay mechanics, and the depth of its AI, particularly with regards to the creature training. If you\'re a fan of god games like Populous or simulation games like The Sims, you\'ll be a fan of Black & White.',
        submitted_by: '',
        answers: ["Black & White", "Black and White"],
        affiliate_links: ["https://www.amazon.com/Black-White-PC/dp/B00004UE0O?crid=1T45Z5RD7U5FW&keywords=black+%26+white+game&qid=1685490196&sprefix=Black+%26+White+ga%2Caps%2C401&sr=8-6&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=b4c2c8c9448a2561915899a6a97c4a16&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Black & White',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '90%',
        genre: 'Simulation, Strategy, God-like?',
        console_platform: 'PC',
        developer: 'Lionhead Studios Ltd.',
        backloggd_link: '',
    },
    '387': {
        content: '',
        submitted_by: '',
        answers: ["Redfall"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Redfall%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fredfall-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/redfall-pc-steam-gift-global-i10000326336001?aid=13438607?gtag=391e4ef696", "https://www.amazon.com/Redfall-Standard-Xbox-Digital-Code/dp/B0BZ6D442T?crid=21H1J77M9RFLT&keywords=Redfall&qid=1685490562&sprefix=black+%26+white+game%2Caps%2C475&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=35e1220e5b966ca26864bd083105549c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '54%',
        genre: 'Action, shooter',
        console_platform: 'PC, Xbox Series',
        developer: 'Arkane Studios LLC',
        backloggd_link: '',
    },
    '388': {
        content: 'Phoenix Wright: Ace Attorney is a visual novel adventure game developed by Capcom. First released in 2001 for the Game Boy Advance and later ported to other platforms, the game follows the story of Phoenix Wright, a rookie defense attorney who takes on various cases to prove the innocence of his clients. Set in a world where courtroom trials are fast-paced and high-stakes, players must gather evidence, question witnesses, and use their wits to uncover the truth behind each case.    The gameplay of Phoenix Wright: Ace Attorney is divided into two main segments: investigation and courtroom trial. During the investigation phase, players explore different locations, gather evidence, and interview witnesses and persons of interest. In the courtroom trial phase, players present evidence, cross-examine witnesses, and identify contradictions in testimony to build their case and ultimately prove their client\'s innocence.    Phoenix Wright: Ace Attorney combines its engaging gameplay mechanics with a cast of colorful characters, intriguing mysteries, and a unique blend of humor and drama. The game\'s narrative is presented through a combination of text, character sprites, and animated sequences, immersing players in the captivating world of crime-solving and courtroom battles. If you\'re a fan of games like Danganronpa or Professor Layton, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Phoenix Wright: Ace Attorney"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Phoenix%20Wright%20Ace%20Attorney%20Trilogy%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fphoenix-wright-ace-attorney-trilogy-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/phoenix-wright-ace-attorney-trilogy-3-steam-gift-global-i10000005031002?gtag=391e4ef696", "https://www.amazon.com/Phoenix-Attorney-Trilogy-English-PlayStation-4/dp/B07QKJDCP3?crid=36AHHS5K6OWPO&keywords=Phoenix+Wright%3A+Ace+Attorney+trilogy&qid=1685573994&sprefix=phoenix+wright+ace+attorney+trilogy%2Caps%2C439&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=75b26fb18dc89bc5a4c9852c8910b969&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Ace Attorney',
        twitter_ids: [],
        release_year: '2001 (GBA), 2005 (DS)',
        metacritic_score: '81%',
        genre: 'Visual Novel, Investigation',
        console_platform: 'GBA',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '389': {
        content: 'Sly 2: Band of Thieves is an action-adventure video game developed by Sucker Punch Productions and published by Sony Computer Entertainment. The game follows the story of Sly Cooper, a skilled thief, and his team as they attempt to take down a group of villains who have stolen parts of the Cooper familys legacy. The game is played from a third-person perspective, and players must use a combination of stealth, platforming, and combat to progress through the games various levels.  One of the unique features of Sly 2 is the ability to play as each of the members of Slys team, including Bentley the turtle and Murray the hippopotamus. Each character has their own unique abilities and strengths, which players must use strategically to overcome obstacles and defeat enemies.  If youre a fan of these games: Assassins Creed, Batman: Arkham Asylum, and the Uncharted series, youll be a fan of this one.',
        submitted_by: 'https://twitter.com/mmult_',
        answers: ["Sly 2: Band of Thieves"],
        affiliate_links: ["https://www.amazon.com/Sly-Collection-Playstation-3/dp/B003O680RM?crid=23FIYJOB880ES&keywords=Sly+2%3A+Band+of+Thieves&qid=1685491556&sprefix=peggle%2Caps%2C474&sr=8-7&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=302ad138da8caa9836f3600e607c89fa&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Sly Cooper',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '88%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Sucker Punch Productions LLC',
        backloggd_link: '',
    },
    '390': {
        content: 'Silent Hill is a seminal survival horror video game developed by Team Silent and published by Konami. First released in 1999 for the PlayStation, the game is set in the eerie, fog-shrouded town of Silent Hill and follows Harry Mason as he searches for his missing adopted daughter amidst strange occurrences and grotesque creatures.    The gameplay of Silent Hill blends exploration, puzzle-solving, and combat. Players navigate the unsettling town and its alternate hellish reality, discovering clues, solving puzzles, and fighting or avoiding nightmarish creatures to progress. The game is renowned for its atmosphere, created by its thick fog, dynamic camera angles, and haunting soundtrack. The game\'s narrative, environmental storytelling, and sound design have been influential in the horror genre.    Silent Hill is regarded as one of the defining titles of the survival horror genre. Its compelling blend of atmospheric horror, engaging gameplay, and intricate storyline make for a uniquely chilling experience. If you\'re a fan of games like Resident Evil or Alan Wake, you\'ll be a fan of Silent Hill.',
        submitted_by: '',
        answers: ["Silent Hill"],
        affiliate_links: ["https://www.amazon.com/Silent-Hill-HD-Collection-Xbox-360/dp/B005EZ5GNW?crid=ANXWP07BGL7K&keywords=silent+hill+game&qid=1685405676&sprefix=Silent+Hill+%2Caps%2C1159&sr=8-4&linkCode=ll1&tag=guessthegam06-20&linkId=635fcee383f8c46119b44eb33e8eb2a8&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Silent Hill',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '86%',
        genre: 'Action, Adventure, Horror',
        console_platform: 'PS1',
        developer: 'Team Silent',
        backloggd_link: '',
    },
    '391': {
        content: 'Half-Life: Alyx is a revolutionary virtual reality first-person shooter developed and published by Valve. Released in 2020, the game serves as a prequel to Half-Life 2, placing players in the role of Alyx Vance, humanity\'s only hope for survival against the alien Combine forces.    The gameplay of Half-Life: Alyx is built from the ground up for VR, utilizing the medium\'s immersive capabilities to their fullest. Players explore, solve puzzles, and engage in combat, all while interacting with the environment in a deeply tactile way. Manipulating objects, using tools, and wielding weapons feel real, resulting in a level of immersion not seen in many other games.    Half-Life: Alyx has been lauded for its groundbreaking VR design, evocative narrative, and the way it pushes the boundaries of what\'s possible in gaming. If you\'re a fan of immersive first-person shooters like the original Half-Life series or Bioshock, you\'ll be a fan of Half-Life: Alyx.',
        submitted_by: '',
        answers: ["Half-Life: Alyx"],
        affiliate_links: ["https://www.g2a.com/half-life-alyx-steam-gift-global-i10000192575002?aid=13429703?gtag=391e4ef696"],
        franchise: 'Half-Life',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '93%',
        genre: 'Action, Adventure, Puzzle, Shooter',
        console_platform: 'PC',
        developer: 'Valve Corporation',
        backloggd_link: '',
    },
    '392': {
        content: 'Risk of Rain is a roguelike platformer developed by Hopoo Games and published by Chucklefish. Released in 2013, the game thrusts players into a hostile, alien world, where they must battle hordes of monstrous creatures, collect power-ups, and ultimately find a way to escape.    In Risk of Rain, gameplay revolves around traversing procedurally generated levels, fighting enemies, and gathering items that provide various buffs. The longer players spend in a level, the harder the enemies become, due to an innovative difficulty meter that increases over time. Players choose from various unique characters, each with their own abilities and play styles. Its pixel-art aesthetic is complemented by an atmospheric soundtrack that sets the tone for the relentless, alien world. The game also features multiplayer co-op, allowing friends to face the increasingly difficult hordes together.    Risk of Rain is renowned for its deep mechanics, replayability, and the satisfaction of mastering its various characters. It\'s a game that offers a thrilling challenge, demanding strategic decision-making and quick reflexes. If you\'re a fan of roguelikes like Dead Cells, Binding of Isaac, Spelunky, you\'ll be a fan of Risk of Rain.',
        submitted_by: '',
        answers: ["Risk of Rain"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Risk%20of%20Rain%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frisk-of-rain%2F&intsrc=PUI2_9608", "https://www.g2a.com/risk-of-rain-steam-key-global-i10000000048007?gtag=391e4ef696", "https://www.amazon.com/Risk-Rain-Standard-Xbox-Digital/dp/B07Z3XBMCS?crid=61EEILG2KS1D&keywords=Risk+of+Rain&qid=1685402407&sprefix=hogwarts+legacy%2Caps%2C613&sr=8-18&linkCode=ll1&tag=guessthegam06-20&linkId=951a5659e42f1e8cf970bed1834d5530&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Risk of Rain',
        twitter_ids: ["1667298276268445697", "1667293465913307137"],
        release_year: '2013',
        metacritic_score: '77%',
        genre: 'Action, Roguelike, Platformer',
        console_platform: 'PC',
        developer: 'Hopoo Games, LLC',
        backloggd_link: '',
    },
    '393': {
        content: 'Peggle is a casual puzzle game developed and published by PopCap Games. The game is all about shooting a ball at a field of pegs and bricks in order to clear them and score points, all while enjoying whimsical graphics, fun sound effects, and a cheerful soundtrack. But what makes Peggle truly unique are its characters. Each one has their own special abilities and personalities, ranging from the cool-headed Bjorn the Unicorn to the fiery Lord Cinderbottom The game has over 55 levels, each with its own unique layout and challenges. And, of course, there are the legendary Peggle Masters, which act as bosses that push players to their limits. If youre a fan of games like Tetris, Bejeweled, or Snood, youll be a fan of this one.',
        submitted_by: 'https://www.youtube.com/channel/UCVmjTuhHUBzmk59aSFk5BwA',
        answers: ["Peggle"],
        affiliate_links: ["https://www.amazon.com/Peggle-PC/dp/B001AZSEUW?crid=BJ4G8GNIAL0X&keywords=Peggle&qid=1685491368&sprefix=peggle%2Caps%2C547&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=387f48bf301e1c53a1e9e32e777b005d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Peggle',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '85%',
        genre: 'Puzzle?',
        console_platform: 'PC, Mobile',
        developer: 'PopCap Games, Inc.',
        backloggd_link: '',
    },
    '394': {
        content: '',
        submitted_by: '',
        answers: ["Hogwarts Legacy"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Hogwarts%20Legacy%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhogwarts-legacy-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/hogwarts-legacy-deluxe-edition-xbox-series-x-s-xbox-live-key-global-i10000218808035?gtag=391e4ef696", "https://www.amazon.com/Hogwarts-Legacy-Deluxe-PlayStation-5/dp/B0BBWPFRHV?crid=2P86GYTA3SG14&keywords=Hogwarts+Legacy&qid=1685401587&sprefix=hogwarts+legacy%2Caps%2C546&sr=8-8&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=d67496892250b69af41ee10fc151a3c7&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Harry Potter',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '83%',
        genre: 'RPG, 3rd-person, Action, Adventure',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'WB Games | Avalanche',
        backloggd_link: '',
    },
    '395': {
        content: 'Super Mario Sunshine is a classic 3D platformer developed and published by Nintendo for the GameCube. Released in 2002, the game follows Mario on a vacation-turned-mission on the tropical Isle Delfino, where he must clean up pollution and graffiti caused by his doppelgänger using a water-jetpack named F.L.U.D.D.    The gameplay in Super Mario Sunshine is centered around Mario\'s acrobatics and the unique functionalities of F.L.U.D.D. From hovering in mid-air to blasting away at high speeds, F.L.U.D.D. adds a fresh twist to Mario\'s movements and the game\'s puzzle-solving mechanics. Players collect Shine Sprites by completing tasks and solving puzzles, all while battling enemies and navigating tricky environments.    Super Mario Sunshine stands out with its vibrant aesthetics, unique water mechanics, and memorable boss fights. If you\'re a fan of 3D platformers like Super Mario Odyssey or Banjo-Kazooie, you\'ll be a fan of Super Mario Sunshine.',
        submitted_by: '',
        answers: ["Super Mario Sunshine"],
        affiliate_links: ["https://www.amazon.com/Super-Mario-Sunshine-Players-Choice-GameCube/dp/B0000E262M?crid=1A0HVFYZ7K0AY&keywords=Super+Mario+Sunshine&qid=1685401290&sprefix=super+mario+sunshine%2Caps%2C537&sr=8-14&linkCode=ll1&tag=guessthegam06-20&linkId=c4f81c39a64763ddbb8db6d0e32a3b53&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario',
        twitter_ids: ["1668388743512829952", "1668389581668884481"],
        release_year: '2002',
        metacritic_score: '92%',
        genre: 'Action, Adventure, Platformer',
        console_platform: 'GameCube',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '396': {
        content: 'Darksiders II is an action-adventure game developed by Vigil Games and published by THQ. Released in 2012, the game puts players in the role of Death, one of the Four Horsemen of the Apocalypse, in a parallel story to the first Darksiders game. Death embarks on a mission to prove the innocence of his brother War, blamed for starting the Apocalypse prematurely.    The gameplay in Darksiders II is a blend of combat, platforming, and puzzle-solving in a large, open world. The game introduces RPG elements like leveling up, skill trees, and loot, adding depth to the combat system. Players wield Death\'s iconic scythes and a variety of secondary weapons while exploring beautifully grim environments, battling foes, and overcoming obstacles.    Darksiders II expands on the solid foundation of its predecessor with more varied environments, a more complex storyline, and deeper gameplay mechanics. Death\'s agility and finesse contrast with War\'s brute strength from the first game, making for a unique play experience. If you\'re a fan of action-adventure games like God of War or The Legend of Zelda series, you\'ll be a fan of Darksiders II.',
        submitted_by: '',
        answers: ["Darksiders 2", "Darksiders II"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Darksiders%20III%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdarksiders-iii-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/darksiders-ii-deathinitive-edition-steam-gift-global-i10000007230004?gtag=391e4ef696", "https://www.amazon.com/Darksiders-II-Limited-Xbox-360/dp/B009K4OXWI?keywords=Darksiders+2&qid=1685399293&sr=8-16&linkCode=ll1&tag=guessthegam06-20&linkId=ded39a615f9f472da582aa6a9abae6c3&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Darksiders',
        twitter_ids: ["1668753202651734016"],
        release_year: '2012',
        metacritic_score: '83%',
        genre: 'Action',
        console_platform: 'PC, X360, PS3',
        developer: 'Vigil Games',
        backloggd_link: '',
    },
    '397': {
        content: 'Tomb Raider is an iconic action-adventure game developed by Core Design and published by Eidos Interactive for the PlayStation 1 in 1996. The game follows the daring archaeologist Lara Croft as she searches for the three mysterious Scion artefacts across the globe, from the lost city of Atlantis to the Pyramids of Egypt.    The gameplay of Tomb Raider is a mix of exploration, combat, and puzzle-solving in a 3D environment. Players control Lara through various tombs and caverns, battling animals, mythical creatures, and hostile humans while avoiding traps and solving puzzles to progress. Lara\'s athletic abilities, such as running, jumping, and climbing, play a vital role in exploration and puzzle-solving.    Tomb Raider was groundbreaking at its release, not just for its female protagonist, but also for its 3D design and gameplay. It defined the action-adventure genre with its blend of exploration, combat, and puzzle-solving in an immersive 3D world. Its narrative-driven gameplay and atmospheric soundtrack complemented the exploration of the mysterious, often eerie, ancient sites.    Tomb Raider is revered as a classic that has greatly influenced many action-adventure games that followed. If you\'re a fan of games like Uncharted or Prince of Persia, you\'ll be a fan of Tomb Raider.',
        submitted_by: '',
        answers: ["Tomb Raider"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tomb%20Raider%20I%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftomb-raider-i%2F&intsrc=PUI2_9608", "https://www.g2a.com/tomb-raider-i-steam-key-global-i10000011569003?gtag=391e4ef696", "https://www.amazon.com/Tomb-Raider-Playstation/dp/B000006RGS?crid=AQ63ER1T8WA7&keywords=tomb+raider+1996&qid=1685398599&sprefix=tomb+raider+19%2Caps%2C456&sr=8-6&ufe=app_do%3Aamzn1.fos.c3015c4a-46bb-44b9-81a4-dc28e6d374b3&linkCode=ll1&tag=guessthegam06-20&linkId=a6dfe4adae2e7bc9f81e20ccdb0b9733&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Tomb Raider',
        twitter_ids: ["1669308369948012549", "1669239022374330377", "1669338747387670528", "1669248501622927360", "1669196166326636545"],
        release_year: '1996',
        metacritic_score: 'Too old for metacritic',
        genre: 'Action, Adventure, Puzzle, Shooter',
        console_platform: 'DOS, PS1, Sega Saturn',
        developer: 'Core Design Ltd.',
        backloggd_link: '',
    },
    '398': {
        content: 'It Takes Two is a cooperative action-adventure game developed by Hazelight Studios and published by Electronic Arts. The game revolves around the story of a couple, Cody and May, who want to divorce but find themselves magically turned into dolls by their daughter Rose\'s tears, forcing them to work together to return to their human forms. Players take on the roles of both characters, collaborating to solve puzzles and overcome obstacles in a whimsical, fantastical world. The game\'s mechanics are centered around the concept of bridging two different elements to create a path forward. Each level contains unique challenges and gameplay mechanics that keep the experience fresh and exciting. The game features both local and online co-op modes, allowing players to team up with a friend or family member to enjoy the magic of the story together. It Takes Two is unique in its ability to craft a story that addresses mature themes while retaining a sense of whimsy and fun. It\'s a standout example of the potential for cooperative gameplay to elevate storytelling to new heights. If you\'re a fan of games like A Way Out, Brothers: A Tale of Two Sons, or Lovers in a Dangerous Spacetime, you\'ll be a fan of this one.',
        submitted_by: 'https://instagram.com/alycia_abs12',
        answers: ["It Takes Two"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=It%20Takes%20Two%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fit-takes-two-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/it-takes-two-pc-steam-gift-global-i10000221966005?gtag=391e4ef696", "https://www.amazon.com/Takes-Two-Xbox-One/dp/B08Q3V2TXV?crid=1A0OQXJ1WN8RF&keywords=it+takes+two+game&qid=1685573684&sprefix=It+Takes+Two%2Caps%2C409&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=1692a6c2e317d74aefbda3e664e18d5a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: ["1669471219345682432", "1669589500509429762", "1669651940139999233"],
        release_year: '2021',
        metacritic_score: '89%',
        genre: 'Action, Adventure, Puzzle, Multiplayer',
        console_platform: 'PC, PS4, PS5, XONE, Xbox Series',
        developer: 'Hazelight studios AB',
        backloggd_link: '',
    },
    '399': {
        content: 'The Red Lantern is a resource management narrative game developed and published by Timberline Studio. Released in 2020, the game focuses on the journey of the Musher as she sets out to train a sled dog team for the grueling Iditarod race, only to find herself lost in the harsh Alaskan wilderness.    In The Red Lantern, gameplay hinges on making decisions and managing limited resources. Players navigate the unpredictable wilderness, encountering various wildlife and dealing with survival scenarios. Choices made along the journey have tangible impacts, from interactions with animals to finding ways to keep warm in the freezing cold.    What makes The Red Lantern stand out is its rich narrative interwoven with survival mechanics, complemented by a visually striking, procedurally generated environment. Its unique blend of gameplay elements, combined with immersive sound design, successfully create an engaging and challenging wilderness survival experience.    The Red Lantern has been praised for its blend of narrative and survival elements, its atmospheric setting, and its portrayal of the bond between a musher and her dogs. If you\'re a fan of narrative survival games like Firewatch or The Long Dark, you\'ll be a fan of The Red Lantern.',
        submitted_by: '',
        answers: ["The Red Lantern"],
        affiliate_links: ["https://www.g2a.com/the-red-lantern-pc-steam-key-global-i10000338966001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '69%',
        genre: 'Action Adventure',
        console_platform: 'PC, Switch',
        developer: 'Timberline Studio',
        backloggd_link: '',
    },
    '400': {
        content: 'Kirby\'s Adventure is a platform game developed by HAL Laboratory and published by Nintendo for the Nintendo Entertainment System. Released in 1993, the game introduces players to the charming and colorful world of Dream Land, where the adorable hero, Kirby, embarks on a quest to restore the Star Rod, the source of all dreams, which has been stolen by the mischievous King Dedede.    In Kirby\'s Adventure, gameplay revolves around navigating diverse levels, fighting enemies, and solving puzzles. The game introduced Kirby\'s iconic ability to swallow enemies and copy their powers, adding depth to the combat and puzzle-solving mechanics. Kirby can gain up to 20 different abilities, from breathing fire to becoming a stone, all of which add an element of strategy to the game.    Kirby\'s Adventure stands out with its charming aesthetics, fun gameplay, and the innovative copy mechanic. Its brightly colored levels, adorable characters, and catchy music make for an endearing experience, while its challenging boss battles and hidden secrets add a layer of depth that appeals to both new and experienced players.    Kirby\'s Adventure has been celebrated for its delightful charm, engaging gameplay, and the introduction of Kirby\'s copy ability, which would become a staple in future Kirby games. If you\'re a fan of platformers like Super Mario Bros. or Donkey Kong, you\'ll be a fan of Kirby\'s Adventure.',
        submitted_by: '',
        answers: ["Kirby's Adventure"],
        affiliate_links: ["https://www.amazon.com/Kirbys-Adventure-Renewed-nintendo-entertainment-system/dp/B07NYZZTQQ?keywords=Kirby%27s+Adventure&qid=1686624938&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=663c3c6bcfdf47612c8f1b9960e39579&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Kirby',
        twitter_ids: [],
        release_year: '1993',
        metacritic_score: 'Not Rated',
        genre: 'Platform',
        console_platform: 'NES',
        developer: 'HAL Laboratory',
        backloggd_link: '',
    },
    '401': {
        content: 'Destiny is a shared-world, first-person shooter developed by Bungie and published by Activision. Released in 2014, the game propels players into the distant future where they take on the role of a Guardian, protectors of Earth\'s last safe city, wielding a power called Light to defend against alien races.    The gameplay in Destiny combines elements of first-person shooters with aspects of massively multiplayer online games. Players can explore the vast universe, combat enemy aliens, complete missions, and interact with other players, all while leveling up and acquiring new abilities and gear. It features both player versus environment (PvE) and player versus player (PvP) game modes.    Destiny stands out for its compelling blend of action, RPG elements, and online multiplayer. Its blend of cooperative and competitive play, along with the depth of its lore and universe, sets it apart from many other titles. The progression system and end-game content provide players with a sense of accomplishment and continual motivation to play.    Destiny has been praised for its engaging gameplay, impressive graphics, and robust multiplayer experiences. If you\'re a fan of online multiplayer shooters like Halo or Borderlands, you\'ll be a fan of Destiny.',
        submitted_by: '',
        answers: ["Destiny"],
        affiliate_links: ["https://www.g2a.com/destiny-the-collection-xbox-live-key-united-states-i10000028982005?gtag=391e4ef696", "https://www.amazon.com/Destiny-Collection-PlayStation-4-Standard/dp/B01K63R7HY?crid=3FPYJU26DWMID&keywords=Destiny&qid=1686625019&sprefix=destiny%2Caps%2C435&sr=8-51&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=609f7887742f2d0316b3c5e7453b46e9&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Destiny',
        twitter_ids: ["1670437918488535042", "1670681040673964032", "1670433595796897792", "1670726585253502976", "1670700791852662785"],
        release_year: '2014',
        metacritic_score: '76%',
        genre: 'First-person shooter',
        console_platform: 'PS3, PS4, X360, XONE',
        developer: 'Bungie',
        backloggd_link: '',
    },
    '402': {
        content: 'PuzzleQuest: Challenge of the Warlords is a unique blend of match-3 puzzle gameplay and role-playing mechanics, developed by Infinite Interactive and published by D3 Publisher. Launched in 2007, the game follows a hero in the Warlords universe, who embarks on a rich and engaging quest filled with battles against mythical creatures, using the power of match-3 puzzles.    Gameplay in PuzzleQuest involves turn-based combat where players and opponents take turns matching gems on a grid to accumulate mana, gain gold, inflict damage, or earn experience. Mana is used to cast a variety of spells that can turn the tide of battle. As you progress, you gain new abilities, increase your stats, acquire new equipment, and recruit companions with unique powers.    PuzzleQuest stands out for its clever fusion of puzzle and RPG elements. Its innovative mechanic of using a match-3 puzzle game for battles, paired with an immersive storyline, character progression, and strategic depth, makes for an exceptionally engaging experience.    PuzzleQuest: Challenge of the Warlords is widely praised for its captivating gameplay, challenging puzzles, and depth of its RPG mechanics. If you\'re a fan of games like Bejeweled or Candy Crush Saga but crave a deeper, narrative-driven experience, you\'ll be a fan of PuzzleQuest: Challenge of the Warlords.',
        submitted_by: '',
        answers: ["PuzzleQuest: Challenge of the Warlords", "Puzzle Quest: Challenge of the Warlords"],
        affiliate_links: ["https://www.amazon.com/Puzzle-Quest-Challenge-Warlords-Nintendo-Renewed/dp/B08K6139GK?keywords=PuzzleQuest%3A+Challenge+of+the+Warlords&qid=1686625625&sr=8-1-fkmr2&linkCode=ll1&tag=guessthegam06-20&linkId=eeafc6947ee997ba020098b6e555a8a5&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Puzzle Quest',
        twitter_ids: ["1670911394693406720", "1671026327917297667", "1670954353530900481", "1671077191281700864"],
        release_year: '2007',
        metacritic_score: '84%',
        genre: 'Tile-matching, RPG',
        console_platform: 'PSP, DS',
        developer: 'Infinite Interactive, 1st Playable Productions',
        backloggd_link: '',
    },
    '403': {
        content: 'Shadow Complex is a critically acclaimed action-adventure game developed by Chair Entertainment in collaboration with Epic Games and published by Microsoft Game Studios. Released in 2009, the game delivers an immersive narrative set in the world of Orson Scott Card\'s Empire series, where players assume the role of Jason Fleming, who stumbles upon a massive underground complex while out hiking with a friend.    Shadow Complex blends 2D gameplay with a 3D world, a style often referred to as 2.5D. Players navigate an expansive map, battling enemies and gaining power-ups that open up new areas. The game combines fast-paced action with exploration and puzzle-solving, all rendered with stunning visuals powered by Unreal Engine 3.    What makes Shadow Complex stand out is its compelling mix of modern gameplay elements with a retro, side-scrolling design, often seen in Metroidvania games. The detailed and expansive map, varied combat options, and a deep upgrade system contribute to an engaging experience that keeps players coming back.    Shadow Complex has been hailed for its intricate gameplay, captivating narrative, and impressive visuals. If you\'re a fan of Metroidvania style games like Metroid or Castlevania: Symphony of the Night, you\'ll be a fan of Shadow Complex.',
        submitted_by: '',
        answers: ["Shadow Complex"],
        affiliate_links: ["https://www.g2a.com/shadow-complex-remastered-steam-key-global-i10000015693004?gtag=391e4ef696", "https://www.amazon.com/Shadow-Complex-Remastered-PS4-PlayStation-4/dp/B01LPMWZPO?crid=1D3U2RM8F3MMU&keywords=shadow+complex+remastered&qid=1686625923&sprefix=Shadow+Complex%2Caps%2C377&sr=8-1&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=acbc6f7aa0f0502cd1830919833b995e&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: ["1671287564530065408", "1671403853336412160", "1671483780656234497"],
        release_year: '2009',
        metacritic_score: '88%',
        genre: 'Platform-adventure',
        console_platform: 'X360',
        developer: 'Chair Entertainment',
        backloggd_link: '',
    },
    '404': {
        content: 'Wave Race 64 is a classic jet-ski racing game developed and published by Nintendo for the Nintendo 64 console. Released in 1996, the game has players skimming across the surface of various bodies of water, tackling sharp turns, and jumping off waves to gain speed and secure victory.    The gameplay in Wave Race 64 emphasizes balance and control, with players having to master the physics of water and the nuances of their jet-ski. With various courses that range from tranquil azure seas to stormy, wave-tossed oceans, each race demands adaptability and skill. The game also includes a stunt mode where players perform tricks for points.    Wave Race 64 is widely remembered for its realistic water physics, which was unprecedented at the time of its release. The game\'s diverse courses, responsive controls, and dynamic weather conditions contribute to an engaging and immersive racing experience.    Wave Race 64 has been acclaimed for its exciting gameplay, innovative water physics, and challenging courses. If you\'re a fan of water-based racing games like Hydro Thunder or Riptide GP, you\'ll be a fan of Wave Race 64.',
        submitted_by: '',
        answers: ["Wave Race 64"],
        affiliate_links: ["https://www.amazon.com/Wave-Race-64-Renewed-nintendo/dp/B07X1KYC3S?crid=2HWBK53TNJ3GV&keywords=Wave+Race+64&qid=1686626023&sprefix=wave+race+64%2Caps%2C468&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=e4e266fba7962a8e3b19c17e3476771c&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: ["1671743094629253120", "1671853891473006592", "1671871020590936064", "1671637119440424960"],
        release_year: '1996',
        metacritic_score: '92%',
        genre: 'Racing',
        console_platform: 'N64',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '405': {
        content: 'Mario Golf: Super Rush is an engaging sports title developed and published by Nintendo for the Nintendo Switch. Released in 2021, the game brings together iconic characters from the Mario universe for a round of golf infused with power-ups, unique course designs, and plenty of charm.    Gameplay in Mario Golf: Super Rush combines traditional golf rules with exciting arcade-style mechanics. Players can opt for Standard Golf, Speed Golf, or the adventure mode, each offering unique twists to the golfing experience. From charging ahead with a burst of speed after each shot in Speed Golf, to leveling up your character and learning new skills in adventure mode, each game mode offers a distinct experience.    Mario Golf: Super Rush stands out with its accessible yet deep golf gameplay, colorful graphics, and unique roster of playable characters, each with their own special shots. The game\'s vibrant courses, filled with obstacles and power-ups, add a layer of strategy and unpredictability to each round.    Mario Golf: Super Rush is celebrated for its fun gameplay mechanics, varied modes, and multiplayer options, which make it a great game for both casual players and golf enthusiasts. If you\'re a fan of sports games with a twist, like Wii Sports or Mario Tennis Aces, you\'ll be a fan of Mario Golf: Super Rush.',
        submitted_by: '',
        answers: ["Mario Golf: Super Rush"],
        affiliate_links: ["https://www.g2a.com/mario-golf-super-rush-nintendo-switch-nintendo-eshop-key-united-states-i10000263303001?gtag=391e4ef696", "https://www.amazon.com/Mario-Golf-Standard-Pre-load-Digital/dp/B08X5YCVQJ?crid=3AR6LQOUV6EWU&keywords=Mario+Golf%3A+Super+Rush&qid=1686626128&sprefix=mario+golf+super+rush%2Caps%2C400&sr=8-5&linkCode=ll1&tag=guessthegam06-20&linkId=33e7ef944db24aaa0c1897e1b52e1905&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Mario Golf',
        twitter_ids: ["1672293446168526859", "1671882372785270796", "1672121512709570560", "1672172230430580738"],
        release_year: '2021',
        metacritic_score: '70%',
        genre: 'Sports',
        console_platform: 'Switch',
        developer: 'Camelot Software Planning',
        backloggd_link: '',
    },
    '406': {
        content: 'The Big Con is a quirky and nostalgic adventure game developed by Mighty Yell and published by Skybound Games. Released in 2021, the game invites players to step into the shoes of Ali, a teenage con artist in the \'90s, on a mission to save her mom\'s video store from loan sharks by hustling her way across the country.    Gameplay in The Big Con involves sneaking around, solving puzzles, and pulling off a variety of cons, from pickpocketing to impersonation. The game\'s dialogue is laden with humor and choice, allowing players to influence the direction of the story. Its setting captures the essence of the \'90s, from its aesthetic to its soundtrack.    The Big Con stands out with its unique premise, engaging narrative, and distinctive hand-drawn art style. Its blend of adventure and light-hearted con jobs, wrapped in a \'90s vibe, makes for a refreshingly original gaming experience.    The Big Con has been applauded for its charming characters, compelling story, and innovative gameplay. If you\'re a fan of narrative-driven games with a sense of humor, like Night in the Woods or Oxenfree, you\'ll be a fan of The Big Con.',
        submitted_by: '',
        answers: ["The Big Con"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Big%20Con%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-big-con-pc%2F&intsrc=PUI2_9608"],
        franchise: 'None',
        twitter_ids: ["1672370738882568192", "1672470170978205696"],
        release_year: '2021',
        metacritic_score: 'Not rated',
        genre: 'Adventure',
        console_platform: 'PC, XONE, Xbox Series',
        developer: 'Mighty Yell',
        backloggd_link: '',
    },
    '407': {
        content: 'Virtua Racing is a pioneering racing game developed by Sega and initially released in arcades in 1992. Known as one of the first fully 3D polygonal racing games, Virtua Racing transports players into the fast-paced world of open-wheel racing, challenging them to maneuver around a variety of tracks and against several competitors to secure a podium finish.    The gameplay in Virtua Racing revolves around mastering control over high-speed formula cars and effectively negotiating various turns and straights across different tracks. The game features a handful of distinct circuits, each with its own unique layout and set of challenges. With an innovative three-camera setup, players can switch between views to gain different perspectives on the race.    What makes Virtua Racing stand out is its technical innovation. Its groundbreaking 3D graphics and the realistic handling of the vehicles, combined with its challenging AI, set new standards for the racing genre upon its release.    Virtua Racing has been praised for its innovation, exciting gameplay, and the level of depth it brought to the racing genre. If you\'re a fan of arcade-style racing games like Daytona USA or Ridge Racer, you\'ll be a fan of Virtua Racing.',
        submitted_by: '',
        answers: ["Virtua Racing"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1672608064921550852"],
        release_year: '1992',
        metacritic_score: 'Too old',
        genre: 'Racing',
        console_platform: 'Arcade',
        developer: 'Sega AM2',
        backloggd_link: '',
    },
    '408': {
        content: 'Metal Gear Solid V: The Phantom Pain is an open-world stealth action-adventure game, the final installment in the Metal Gear series directed by Hideo Kojima. Released in 2015 by Konami, the game follows the journey of Punished \"Venom\" Snake as he navigates a series of war-torn environments in Afghanistan and Africa to exact revenge on the people who almost took his life.    The gameplay in The Phantom Pain is based on tactical stealth, allowing players to approach missions in multiple ways. It introduces a base-building feature where players can develop weapons and equipment for a personalized armory. The game also features a dynamic weather system and a day-night cycle that directly impacts gameplay and enemy behavior.    What sets The Phantom Pain apart is its blend of cinematic storytelling, strategic gameplay, and expansive open world. It combines intricate plotlines with intricate gameplay mechanics, including espionage, base-building, and resource management.    Metal Gear Solid V: The Phantom Pain has been acclaimed for its deep gameplay, engrossing narrative, and technical achievements, pushing the boundaries of what\'s possible in the stealth genre. If you\'re a fan of stealth action games like Splinter Cell: Blacklist or Deus Ex: Mankind Divided, you\'ll be a fan of Metal Gear Solid V: The Phantom Pain.',
        submitted_by: '',
        answers: ["Metal Gear Solid V: The Phantom Pain", "Metal Gear Solid 5: The Phantom Pain", "Metal Gear Solid V: Ground Zeroes", "Metal Gear Solid 5: Ground Zeroes", "Metal Gear Solid 5", "Metal Gear Solid V"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Metal%20Gear%20Solid%20V%20The%20Phantom%20Pain%20UK%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmetal-gear-solid-v-the-phantom-pain%2F&intsrc=PUI2_9608", "https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Metal%20Gear%20Solid%20V%20Ground%20Zeroes%20UK%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmetal-gear-solid-v-ground-zeroes-uk%2F&intsrc=PUI2_9608", "https://www.g2a.com/metal-gear-solid-v-the-phantom-pain-ps5-psn-account-account-global-i10000002656004?gtag=391e4ef696", "https://www.g2a.com/metal-gear-solid-v-ground-zeroes-steam-key-global-i10000004632009?aid=13213121?gtag=391e4ef696", "https://www.g2a.com/metal-gear-solid-v-ground-zeroes-steam-key-global-i10000004632009?aid=13213121", "https://www.amazon.com/Metal-Gear-Solid-Ground-Xbox-One/dp/B00I989HKE?crid=2HGG0KC6ODWHV&keywords=METAL+GEAR+SOLID+V%3A+GROUND+ZEROES&qid=1686699759&sprefix=metal+gear+solid+v+the+phantom+pain%2Caps%2C556&sr=8-2&linkCode=ll1&tag=guessthegam06-20&linkId=366d7ac2d5a15574b8e0ff64453fcc2d&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Metal Gear',
        twitter_ids: ["1673093866788880385", "1672969851936325633", "1672969466190364673", "1673163833458978817", "1673345228974039041"],
        release_year: '2015',
        metacritic_score: '93%',
        genre: 'Action-adventure, Stealth',
        console_platform: 'PS3, PS4, PC, X360, XONE',
        developer: 'Kojima Productions',
        backloggd_link: '',
    },
    '409': {
        content: 'Oxenfree is a supernatural thriller adventure game developed and published by Night School Studio. Released in 2016, the game weaves an engrossing tale revolving around Alex, a rebellious teenager who accidentally opens a ghostly rift while on an overnight trip to an abandoned military island.    The gameplay in Oxenfree blends exploration with narrative, focusing on player choice through a unique dialogue system that shapes the story. As Alex, players can freely roam the mysterious island, uncover its dark past, and communicate with ghosts using a handheld radio.    Oxenfree stands out for its compelling narrative, distinctive visual style, and innovative dialogue system. Its spooky yet charming atmosphere, dynamic characters, and haunting sound design contribute to a truly immersive experience.    Oxenfree has been celebrated for its masterful storytelling, atmospheric visuals, and memorable characters. If you\'re a fan of narrative-driven adventure games like Life is Strange or Firewatch, you\'ll be a fan of Oxenfree.',
        submitted_by: '',
        answers: ["Oxenfree"],
        affiliate_links: ["https://www.g2a.com/oxenfree-steam-key-global-i10000008025003?gtag=391e4ef696", "https://www.amazon.com/Oxenfree-Switch-Limited-Run-10-Nintendo/dp/B07S2ZT2BT?keywords=Oxenfree&qid=1686698840&sr=8-1&ufe=app_do%3Aamzn1.fos.f5122f16-c3e8-4386-bf32-63e904010ad0&linkCode=ll1&tag=guessthegam06-20&linkId=424d9b513280033defc5ccac579c22d2&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'OxenFree',
        twitter_ids: ["1673617325285572610", "1673589374569545728", "1673815876707971074"],
        release_year: '2016',
        metacritic_score: '80%',
        genre: 'Graphic adventure',
        console_platform: 'PC, XONE',
        developer: 'Night School Studio',
        backloggd_link: '',
    },
    '410': {
        content: 'Luigi\'s Mansion: Dark Moon, known as Luigi\'s Mansion 2 in Europe and Australia, is an action-adventure game developed by Next Level Games and published by Nintendo for the Nintendo 3DS. Released in 2013, the game sees Luigi, Mario\'s often overshadowed brother, take the spotlight as he embarks on a ghost-hunting adventure across multiple haunted mansions to restore the shattered Dark Moon.    Gameplay in Luigi\'s Mansion: Dark Moon revolves around exploring haunted mansions, solving puzzles, and using Luigi\'s trusty Poltergust 5000 vacuum cleaner to capture ghosts. Players must strategically use the vacuum\'s sucking and blowing functions to overcome obstacles and outsmart crafty ghosts.    Luigi\'s Mansion: Dark Moon stands out for its unique ghost-catching mechanics, charming visuals, and distinct blend of humor and horror. The game\'s well-crafted mansions, each boasting a distinct theme and unique set of challenges, add depth and variety to the ghost-hunting adventure.    Luigi\'s Mansion: Dark Moon has been praised for its engaging gameplay, delightful atmosphere, and innovative puzzle design. If you\'re a fan of puzzle-solving adventure games like The Legend of Zelda series or the original Luigi\'s Mansion game, you\'ll be a fan of Luigi\'s Mansion: Dark Moon.',
        submitted_by: '',
        answers: ["Luigi's Mansion: Dark Moon", "Luigi's Mansion 2"],
        affiliate_links: ["https://www.amazon.com/Luigis-Mansion-Dark-Moon-Nintendo-3ds/dp/B01J4JZG0G?crid=2G5EDWW3CT8CD&keywords=Luigi%27s+Mansion%3A+Dark+Moon&qid=1686699815&sprefix=metal+gear+solid+v+ground+zeroes%2Caps%2C466&sr=8-8&linkCode=ll1&tag=guessthegam06-20&linkId=34186a391a2f4be940e78004026e53b5&language=en_US&ref_=as_li_ss_tl", "https://www.amazon.com/Nintendo-Selects-Luigis-Mansion-3DS/dp/B0753JJ4JW?crid=1IRW8RP41HP9S&keywords=Luigi%27s+Mansion+2&qid=1686699896&sprefix=luigi%27s+mansion+2%2Caps%2C512&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=909dc39085b47aa98029ef5ffb5371cc&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Luigis Mansion',
        twitter_ids: ["1674208341789405184", "1673935591246024710", "1673935166027300865", "1673877250012516353", "1673890174382800896"],
        release_year: '2013',
        metacritic_score: '86%',
        genre: 'Action, Adventure',
        console_platform: '3DS',
        developer: 'Next Level Games',
        backloggd_link: '',
    },
    '411': {
        content: 'BurgerTime is an iconic arcade game developed by Data East for its DECO Cassette System. Released in 1982, the game invites players to take on the role of Chef Peter Pepper, whose objective is to make enormous hamburgers while dodging various food-themed enemies.    The gameplay in BurgerTime is a unique blend of platforming and puzzle-solving. Players navigate platforms and ladders to walk across burger ingredients, which then fall to lower levels, with the goal being to drop all ingredients to the bottom to form complete burgers. All the while, Chef Peter Pepper must evade foes like Mr. Hot Dog, Mr. Pickle, and Mr. Egg, or momentarily stun them with his limited supply of pepper.    BurgerTime stands out with its fun, food-themed premise and challenging gameplay mechanics. Its platforming and enemy-dodging elements, combined with the strategic ingredient drop mechanic, make for a uniquely engaging and charming game experience.    BurgerTime has been applauded for its innovative gameplay, whimsical theme, and enduring appeal. If you\'re a fan of classic arcade games like Pac-Man or Donkey Kong, you\'ll be a fan of BurgerTime.',
        submitted_by: '',
        answers: ["BurgerTime"],
        affiliate_links: ["https://www.amazon.com/BurgerTime-Colecovision/dp/B00AY70LXU?crid=2Q3XXS9CCOY8F&keywords=BurgerTime&qid=1686699135&sprefix=luigi%27s+mansion+dark+moon%2Caps%2C527&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=3d157c86d5fff66198cfad553795ccaa&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'BurgerTime',
        twitter_ids: ["1674190951370330112", "1674206723975036928", "1674197712093454336", "1674290541909999616", "1674441226404388864"],
        release_year: '1982',
        metacritic_score: 'Too old',
        genre: 'Platform',
        console_platform: 'Arcade',
        developer: 'Data East',
        backloggd_link: '',
    },
    '412': {
        content: 'The Lord of the Rings: Gollum is an action-adventure game developed by Daedalic Entertainment. Set in J.R.R. Tolkien\'s iconic Middle-earth, the game provides a fresh perspective on the saga, with players taking on the role of Gollum, the conflicted character known for his split personality and obsession with the One Ring.    Gameplay in The Lord of the Rings: Gollum combines stealth, exploration, and decision-making. As Gollum, players navigate diverse locations, from dark caves to grandiose Elven cities, while evading enemies and making decisions that influence Gollum\'s internal struggle between his Gollum and Sméagol personas.    The Lord of the Rings: Gollum distinguishes itself with its unique protagonist and narrative focus. Its blend of stealth mechanics, environmental puzzles, and a dynamic personality system results in an immersive experience that expands on the well-known lore of Middle-earth.    The Lord of the Rings: Gollum is commended for its innovative take on a classic story, intriguing gameplay mechanics, and compelling narrative. If you\'re a fan of narrative-driven adventure games like The Walking Dead: A Telltale Games Series or Middle-earth: Shadow of Mordor, you\'ll be a fan of The Lord of the Rings: Gollum.',
        submitted_by: '',
        answers: ["The Lord of the Rings: Gollum"],
        affiliate_links: ["https://www.g2a.com/the-lord-of-the-rings-gollum-precious-edition-pc-steam-gift-global-i10000326298009?gtag=391e4ef696", "https://www.amazon.com/Lord-Rings-Gollum-NSW-Nintendo-Switch/dp/B0B352ZXRG?crid=32U0AT20MU32H&keywords=The+Lord+of+the+Rings%3A+Gollum&qid=1686699194&sprefix=burgertime%2Caps%2C513&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=4f2f4dc110bc29830cafd1824c309d34&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'The Lord of the Rings',
        twitter_ids: ["1674554678233944064", "1674511061997977600", "1674428492963004421", "1674728009587195904", "1674652581036015617"],
        release_year: '2023',
        metacritic_score: '34%',
        genre: 'Action, Adventure',
        console_platform: 'PS4, PS5, PC, XONE, Xbox Series',
        developer: 'Daedalic Entertainment',
        backloggd_link: '',
    },
    '413': {
        content: '',
        submitted_by: '',
        answers: ["Dredge"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DREDGE%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdredge-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dredge-digital-deluxe-edition-pc-steam-key-global-i10000338628006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1674807956762955778", "1674899714213543937"],
        release_year: '2023',
        metacritic_score: '80%',
        genre: 'Action, Adventure, Sailing, Ships, Simulation',
        console_platform: 'XONE, Xbox Series, PC, PS4, PS5, Switch',
        developer: 'Black Salt Games',
        backloggd_link: '',
    },
    '414': {
        content: 'The Neverhood is a unique and imaginative video game that captures the hearts of players with its captivating storyline and stunning claymation visuals. In the game, the player takes on the role of Klaymen, a clay adventurer who awakens in a strange and mysterious world with no memory of his past.     As the player navigates through the complex and enchanting world of The Neverhood, they must solve a series of challenging puzzles and explore the intricacies of the game\'s lore. Along the way, they encounter a cast of quirky characters, including the evil Klogg, who seeks to thwart Klaymen\'s journey at every turn.    One of the most striking features of The Neverhood is its stunning stop-motion claymation visuals, which bring the game\'s world to life in a way that is both whimsical and haunting. From the game\'s imaginative landscapes to its quirky characters, every detail is meticulously crafted to create an immersive and unforgettable experience.    Whether you\'re a fan of puzzle games or simply looking for a unique and engaging adventure, The Neverhood is sure to capture your imagination and keep you hooked from start to finish. With its stunning visuals, imaginative storytelling, and challenging gameplay, it\'s no wonder why this beloved classic continues to be a fan favorite even decades after its original release.',
        submitted_by: 'https://www.instagram.com/exoditore/',
        answers: ["The Neverhood"],
        affiliate_links: [],
        franchise: 'The Neverhood',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'Too old for Metacritic',
        genre: 'Adventure, Point & Click, Claymation',
        console_platform: 'PC, PS1',
        developer: 'Same as game title',
        backloggd_link: '',
    },
    '415': {
        content: 'Super Smash Bros. Melee is a classic fighting game that was originally released for the Nintendo GameCube in 2001. Developed by Nintendo, the game features a wide variety of characters from different Nintendo franchises, such as Mario, Donkey Kong, and Pikachu. The objective of the game is to knock opposing characters off the stage and out of bounds, while also avoiding getting knocked out yourself. The game offers a variety of game modes, including a single-player mode, a multiplayer mode, and a tournament mode. In addition to the classic gameplay, Super Smash Bros. Melee also features a variety of unique items and power-ups, as well as special moves and attacks unique to each character.≈ Despite its simple premise, the game requires a significant amount of skill and strategy to master. The game has a huge following among competitive players, and has even been featured in major esports tournaments. If you\'re a fan of games like Street Fighter, Mortal Kombat, or Tekken, you\'ll be a fan of this one.',
        submitted_by: 'https://twitter.com/MrPotatobread/',
        answers: ["Super Smash Bros. Melee"],
        affiliate_links: ["https://www.amazon.com/Super-Smash-Bros-Melee-Renewed-Gamecube/dp/B07KQDWDXP?keywords=Super+Smash+Bros.+Melee&qid=1685570772&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=65b99d15f3edce047e0f004d76b44475&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Smash Brothers',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '92%',
        genre: 'Action',
        console_platform: 'GameCube',
        developer: 'HAL Laboratory, Inc.',
        backloggd_link: '',
    },
    '416': {
        content: 'Skate 2 is an action-packed skateboarding video game developed by EA Black Box and published by Electronic Arts. The game is a sequel to Skate and was released in 2009 for PlayStation 3 and Xbox 360. Skate 2 allows players to skate through the city of New San Vanelona, which has been completely rebuilt after becoming derelict in the first game. The game features a unique control scheme that lets players perform realistic skateboarding tricks using a dual analog stick system for total control and customization.  In Skate 2, players must compete in a variety of challenges, complete goals, and improve their stats in order to progress through the game. The game includes many unique features, such as the ability to create custom skateboards and design graphics for them, as well as the option to replay and share replays of their favorite skateboarding moments.  If you are a fan of similar games such as Tony Hawks Pro Skater, THUG, or Skate, youll definitely enjoy Skate 2.',
        submitted_by: '',
        answers: ["Skate 2"],
        affiliate_links: ["https://www.amazon.com/Skate-2-Platinum-Renewed-Xbox-360/dp/B086JL792W?crid=2GGQGWVQYAA1T&keywords=Skate+2&qid=1686874588&sprefix=signalis%2Caps%2C439&sr=8-1&linkCode=ll1&tag=guessthegam06-20&linkId=8c3f975af983efdfeb8ca54bc48818af&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Skate',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '84%',
        genre: 'Sports',
        console_platform: 'X360, PS3',
        developer: 'Electronic Arts Black Box',
        backloggd_link: '',
    },
    '417': {
        content: '',
        submitted_by: '',
        answers: ["One Piece Odyssey"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=ONE%20PIECE%20ODYSSEY%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fone-piece-odyssey-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/one-piece-odyssey-deluxe-edition-pc-steam-gift-global-i10000337232010?gtag=391e4ef696"],
        franchise: 'One Piece',
        twitter_ids: ["1676462570528489473", "1676401133437472768", "1676386534889783297"],
        release_year: '2023',
        metacritic_score: '77%',
        genre: 'RPG',
        console_platform: 'PC, PS4, PS5, Xbox Series',
        developer: 'ILCA, Inc.',
        backloggd_link: '',
    },
    '418': {
        content: 'Jet Set Radio is a video game developed by Smilebit and published by Sega in 2000. The game is set in Tokyo-To, a fictional city where players take on the role of members of a gang called the GGs who are constantly evading the police while graffiti tagging various locations around the city. The game is a mix of action and platforming, with players grinding, jumping, and tricking their way through levels. Players can pick up spray cans scattered throughout levels to use for tagging, but they must be careful to avoid rival gang members and the police who will attempt to catch them in the act. The games soundtrack is a blend of hip-hop, funk, and electronic music that adds to its unique aesthetic. The game received critical acclaim for its style, presentation, and gameplay, and has become a cult classic. If youre a fan of games like Tony Hawks Pro Skater, Sunset Overdrive, or the original Saints Row, youll be a fan of this one.',
        submitted_by: 'https://www.instagram.com/neckin_/',
        answers: ["Jet Set Radio", "Jet Grind Radio"],
        affiliate_links: ["https://www.g2a.com/jet-set-radio-steam-key-global-i10000000934004?gtag=391e4ef696"],
        franchise: 'Jet Set Radio',
        twitter_ids: ["1676821006789365765", "1676744365396602885", "1676821900545216512", "1676607093271306243"],
        release_year: '2000',
        metacritic_score: '94%',
        genre: 'Action',
        console_platform: 'Dreamcast',
        developer: 'Smilebit',
        backloggd_link: '',
    },
    '419': {
        content: 'Halo: Reach, developed by Bungie Studios and published by Microsoft Game Studios, is a first-person shooter game set in the year 2552. The game is a prequel to the original Halo game and follows the story of a team of Spartans known as Noble Team in their fight against the Covenant, a group of aliens who are bent on the destruction of humanity. Players control Noble Six, the newest member of the team, as they battle their way through various levels on the planet Reach in an attempt to stop the Covenant invasion.  What sets Halo: Reach apart from other games in the series is its emphasis on a more emotional and personal story. Players develop a connection to Noble Team as they witness their struggles and sacrifices throughout the game. The game also introduces new weapons and vehicles, such as the human jetpack and the Covenant’s powerful Skirmisher class.  Players can also create their own custom game types with the Forge tool, allowing for endless possibilities.',
        submitted_by: 'https://twitter.com/TheReverbix64',
        answers: ["Halo: Reach"],
        affiliate_links: ["https://www.g2a.com/halo-reach-steam-gift-global-i10000000927008?gtag=391e4ef696"],
        franchise: 'Halo',
        twitter_ids: ["1677082009016111105"],
        release_year: '2010',
        metacritic_score: '91%',
        genre: 'Action',
        console_platform: 'X360',
        developer: 'Bungie Studios',
        backloggd_link: '',
    },
    '420': {
        content: '',
        submitted_by: '',
        answers: ["Smite: Battleground of the Gods", "Smite"],
        affiliate_links: [],
        franchise: 'Smite',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '83%',
        genre: 'Action, Strategy, MOBA',
        console_platform: 'PC',
        developer: 'Hi-Rez Studios, Inc.',
        backloggd_link: '',
    },
    '421': {
        content: 'The Elder Scrolls II: Daggerfall, developed and published by Bethesda Softworks in 1996, is a classic role-playing game (RPG) with a massive open-world environment that lets players explore an enormous 3D world full of secrets, cities, dungeons, and crypts. The game is set in the High Rock and Hammerfell Kingdoms in Tamriel, a fictional continent in the Elder Scrolls universe. Players create their own character and encounter various factions, engage in battles, and progress through the main storyline while also taking on various side quests. The games unique and randomly generated terrain, combined with its fully customizable character creation system, created an options-rich game that allowed for a high degree of player choice in how to progress through the game. Other unique features included a political system that featured tensions between various kingdoms as well as complex dialogue trees and NPC interactions. The game has been praised for its immersive and immersive world-building and continues to have a dedicated following of fans to this day. If youre a fan of similar classic RPGs like Baldurs Gate, Planescape Torment, or Ultima VII, youll be a fan of this game.',
        submitted_by: 'https://twitter.com/corentin_lamy',
        answers: ["The Elder Scrolls II : Daggerfall", "The Elder Scrolls 2"],
        affiliate_links: [],
        franchise: 'The Elder Scrolls',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'too old',
        genre: 'RPG',
        console_platform: 'DOS',
        developer: 'Bethesda Softworks LLC',
        backloggd_link: '',
    },
    '422': {
        content: '',
        submitted_by: '',
        answers: ["High on Life"],
        affiliate_links: ["https://www.g2a.com/high-on-life-pc-steam-gift-global-i10000337496004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1678288822860148739", "1678128900734619648", "1678200723828293634"],
        release_year: '2022',
        metacritic_score: '69%',
        genre: 'Action',
        console_platform: 'PC, XONE',
        developer: 'Squanch Games, Inc.',
        backloggd_link: '',
    },
    '423': {
        content: '',
        submitted_by: '',
        answers: ["Rez Infinite"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Rez%20Infinite%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frez-infinite-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/rez-infinite-steam-key-global-i10000074411001?gtag=391e4ef696"],
        franchise: 'Rez',
        twitter_ids: ["1678436621405003776", "1678654902556393479", "1678805425393631239"],
        release_year: '2016',
        metacritic_score: '89%',
        genre: 'Action',
        console_platform: 'PS4',
        developer: 'Monstars Inc., Resonair Inc.',
        backloggd_link: '',
    },
    '424': {
        content: 'WHAT THE GOLF? is a comedic physics-based golf game developed by Triband. Players are presented with a variety of unique golf scenarios that playfully subvert the conventional rules of golf. Instead of golfing balls, the player might launch a person, a car, or even a horse-drawn carriage. The game also frequently distorts the physics of golf, for example by placing the player in control of an entire course instead of one ball, by having the ball somehow take on the properties of a boomerang, or by duplicating the ball mid-flight. Players progress through the game by completing increasingly difficult levels, some of which have multiple routes to completion. They can also participate in alternate game modes that offer timed challenges or puzzles to solve. The games simplicity and humor can be enjoyed by people of all ages and levels of gaming experience. If youre a fan of games such as Octodad: Deadliest Catch, Surgeon Simulator, or QWOP, youll be a fan of this one.',
        submitted_by: 'https://www.youtube.com/@SlowHey',
        answers: ["WHAT THE GOLF?"],
        affiliate_links: ["https://www.g2a.com/what-the-golf-pc-steam-gift-global-i10000219537004?gtag=391e4ef696", "https://www.amazon.com/What-Golf-Nintendo-Switch/dp/B09B8YC1PC?crid=1GJZYLMDC34PO&keywords=what+the+golf+game&qid=1686873801&sprefix=what+the+golf%3F+%2Caps%2C531&sr=8-2&ufe=app_do%3Aamzn1.fos.006c50ae-5d4c-4777-9bc0-4513d670b6bc&linkCode=ll1&tag=guessthegam06-20&linkId=84a7426759b7af6ef728b2b9fd016b53&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '83%',
        genre: 'Action, Sports',
        console_platform: 'iPhone, iPad, Mac, PC, Switch (2020)',
        developer: 'Triband Production ApS',
        backloggd_link: '',
    },
    '425': {
        content: '',
        submitted_by: '',
        answers: ["Age of Empires"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Age%20of%20Empires%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fage-of-empires-definitive-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/age-of-empires-definitive-collection-pc-steam-key-global-i10000219255002?gtag=391e4ef696"],
        franchise: 'Age of Empires',
        twitter_ids: ["1679284894730686464", "1679286482299940866", "1679440168644665350", "1679484485514166274", "1679376934042468352"],
        release_year: '1997',
        metacritic_score: '83%',
        genre: 'Strategy',
        console_platform: 'PC',
        developer: 'Ensemble Studios Corporation',
        backloggd_link: '',
    },
    '426': {
        content: '',
        submitted_by: '',
        answers: ["Mario Party"],
        affiliate_links: [],
        franchise: 'Mario Party',
        twitter_ids: ["1679732426669453313"],
        release_year: '1998',
        metacritic_score: '79%',
        genre: 'Multiplayer',
        console_platform: 'N64',
        developer: 'Hudson Soft Company, Ltd.',
        backloggd_link: '',
    },
    '427': {
        content: 'Sable is an open-world exploration game developed by Shedworks and published by Raw Fury. Released in 2021, the game sets players on a beautifully crafted alien planet as Sable, a young girl on her rite of passage known as the Gliding - a journey to understand the world around her and her place in it.    The gameplay in Sable is focused on exploration, puzzle-solving, and interacting with the inhabitants of its world. Players traverse vast deserts, discover ancient ruins, climb towering structures, and navigate challenging terrain using Sable\'s hoverbike.    Sable stands out with its striking visual style, reminiscent of French comic book art, and a contemplative narrative. Its richly detailed and atmospheric world is paired with a soothing, immersive soundtrack by indie band Japanese Breakfast.    Sable has been celebrated for its captivating visuals, atmospheric storytelling, and emphasis on exploration. If you\'re a fan of exploration-based games like Journey or The Legend of Zelda: Breath of the Wild, you\'ll be a fan of Sable.',
        submitted_by: '',
        answers: ["Sable"],
        affiliate_links: ["https://www.g2a.com/sable-pc-steam-gift-global-i10000270345004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1679879920821075968", "1680274059865497603", "1680288631586430977"],
        release_year: '2021',
        metacritic_score: '76%',
        genre: 'Adventure',
        console_platform: 'PC, XONE, Xbox Series',
        developer: 'Shedworks',
        backloggd_link: '',
    },
    '428': {
        content: 'Team Fortress Classic is a first-person shooter game developed and published by Valve. Released in 1999 as a remake of the original Team Fortress modification for Quake, the game pits two teams against each other in a variety of game modes, such as Capture the Flag and Territorial Control.    Gameplay in Team Fortress Classic is characterized by its class-based system, with each of the game\'s nine classes offering distinct abilities and roles, from the rapid-fire Heavy Weapons Guy to the healing Medic. Effective team coordination and strategy are vital to securing victory.    What sets Team Fortress Classic apart is its blend of fast-paced shooter action, teamwork-oriented gameplay, and distinctive class mechanics. Its vibrant maps and diverse game modes offer a dynamic battlefield that requires both tactical thinking and sharp shooting skills.    Team Fortress Classic has been lauded for its engaging multiplayer experience, distinctive classes, and focus on teamwork. If you\'re a fan of class-based multiplayer shooters like Overwatch or the sequel, Team Fortress 2, you\'ll be a fan of Team Fortress Classic.',
        submitted_by: '',
        answers: ["Team Fortress Classic", "Team Fortress"],
        affiliate_links: [],
        franchise: 'Team Fortress',
        twitter_ids: ["1680230647653355520"],
        release_year: '1999',
        metacritic_score: 'Not rated',
        genre: 'First-person shooter',
        console_platform: 'PC',
        developer: 'Valve',
        backloggd_link: '',
    },
    '429': {
        content: 'Nintendogs is a real-time pet simulation video game developed and published by Nintendo for the Nintendo DS. Released in 2005, the game allows players to raise, care for, and train virtual puppies (and in later versions, kittens) in real time, offering an endearing and engaging pet simulation experience.    Gameplay in Nintendogs centers around day-to-day pet care activities. From feeding, bathing, and walking your pet, to teaching it tricks or competing in obedience and agility trials, Nintendogs offers a variety of interactive experiences that leverage the unique features of the Nintendo DS, like touchscreen and microphone inputs.    Nintendogs sets itself apart with its charmingly realistic puppies, a range of breeds to choose from, and the interaction offered by the DS\'s hardware. Its emphasis on care, training, and bonding creates a warm and engaging atmosphere that\'s both relaxing and rewarding.    Nintendogs has been celebrated for its innovative use of the DS hardware, adorable pet simulation, and its immersive, caring gameplay. If you\'re a fan of pet simulation games like Petz or Tamagotchi, you\'ll be a fan of Nintendogs.',
        submitted_by: '',
        answers: ["Nintendogs"],
        affiliate_links: [],
        franchise: 'Nintendogs',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '83%',
        genre: 'Pet-raising simulation',
        console_platform: 'DS',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '430': {
        content: 'Owlboy is a platform-adventure game developed by the independent team D-Pad Studio. Released in 2016 after nearly a decade of development, the game sets players on a journey through the clouds as Otus, a mute owl-boy from a sky village who sets off to save his friends from a band of pirates.    The gameplay in Owlboy blends flight, exploration, and combat. Otus can fly freely around the game\'s pixel-art stages, picking up friends with unique abilities to help solve puzzles, navigate through the world, and defeat enemies.    What sets Owlboy apart is its combination of 16-bit visuals with modern gameplay mechanics, an engaging story, and deeply characterized allies. Its unique \"buddy system\" for abilities, along with the intricate, layered level designs, results in a fresh and compelling adventure experience.    Owlboy has been praised for its stunning pixel-art aesthetics, charming characters, and innovative gameplay mechanics. If you\'re a fan of character-rich adventure games like Celeste or Shovel Knight, you\'ll be a fan of Owlboy.',
        submitted_by: '',
        answers: ["Owlboy"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Owlboy%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fowlboy%2F&intsrc=PUI2_9608", "https://www.g2a.com/owlboy-steam-key-global-i10000029880002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1681480990613708800"],
        release_year: '2016',
        metacritic_score: '88%',
        genre: 'Platform-adventure',
        console_platform: 'PC',
        developer: 'D-Pad Studio',
        backloggd_link: '',
    },
    '431': {
        content: 'Spelunky 2 is a roguelike platformer developed by Mossmouth and BlitWorks. Released in 2020 as a sequel to the critically acclaimed original, the game lets players explore procedurally generated lunar caves as Ana Spelunky, daughter of the first game\'s protagonist, on a quest to find her missing parents.    The gameplay in Spelunky 2 is characterized by its blend of platforming, exploration, and roguelike elements. Players navigate through treacherous terrain, battle or dodge various enemies, discover hidden treasures, and contend with traps in ever-changing, challenging environments.    What sets Spelunky 2 apart is its deep, emergent gameplay stemming from complex interactions between its various elements. Its array of items, enemies, and environments can interact in unexpected ways, creating a unique experience with each playthrough.    Spelunky 2 has been acclaimed for its intricate level design, enhanced multiplayer modes, and punishing yet rewarding gameplay. If you\'re a fan of challenging roguelike games like The Binding of Isaac or Rogue Legacy, you\'ll be a fan of Spelunky 2.',
        submitted_by: '',
        answers: ["Spelunky 2"],
        affiliate_links: ["https://www.g2a.com/spelunky-2-pc-steam-gift-global-i10000218660004?gtag=391e4ef696"],
        franchise: 'Spelunky',
        twitter_ids: ["1681532493462159361", "1681659440947396609"],
        release_year: '2020',
        metacritic_score: '87%',
        genre: 'Platform, Roguelike',
        console_platform: 'PS4, PC',
        developer: 'Mossmouth, BlitWorks',
        backloggd_link: '',
    },
    '432': {
        content: 'Breakout is an arcade game developed and published by Atari, Inc. Introduced in 1976, the game is a simple yet addictive forerunner of the modern \"brick breaker\" genre. Players control a paddle and use it to bounce a ball off bricks lined at the top of the screen, with the goal to eliminate all bricks without letting the ball pass the paddle.    Gameplay in Breakout is straightforward but requires precision and timing. The ball speeds up with each hit, and the paddle shrinks as the player progresses, increasing the challenge. Some versions of the game include power-ups and additional brick types for added complexity.    Breakout stands out for its minimalist design and compelling \"easy to learn, hard to master\" gameplay. It’s a testament to the power of simple, accessible mechanics, providing a foundational blueprint for many subsequent games in the brick-breaking genre.    Breakout has been celebrated for its timeless, pick-up-and-play gameplay, and its influence on the game industry. If you\'re a fan of classic arcade games or modern brick breaker titles like Arkanoid or Shatter, you\'ll be a fan of Breakout.',
        submitted_by: '',
        answers: ["Breakout"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1681682463872135168", "1681775828982714368", "1681681769307975680", "1681815023801503745", "1681898826993369088"],
        release_year: '1976',
        metacritic_score: 'Too old',
        genre: 'Action?',
        console_platform: 'Arcade',
        developer: 'Atari, Inc.',
        backloggd_link: '',
    },
    '433': {
        content: 'Assassin\'s Creed is a historic action-adventure game developed by Ubisoft Montreal. Released in 2007, the game marked the beginning of a long and popular franchise. It invites players into a world where history meets fiction, putting them in the shoes of Altaïr Ibn-La\'Ahad, a member of the Assassins during the Third Crusade.    Gameplay in Assassin\'s Creed is characterized by its open-world environments, stealth elements, and parkour-inspired movement. Players navigate through historically accurate cityscapes, perform assassinations, and engage in combat with Templars, the game\'s main antagonists.     Assassin\'s Creed is known for its rich historical settings, intertwined with a larger, complex narrative about the battle between Assassins and Templars across centuries. Its blend of stealth, acrobatics, and exploration, set against a realistic historical backdrop, offers a unique gaming experience.    Assassin\'s Creed has been applauded for its compelling narrative, engaging gameplay, and the immense detail of its open world. If you\'re a fan of open-world action-adventure games like The Witcher series or the Far Cry series, you\'ll be a fan of Assassin\'s Creed.',
        submitted_by: '',
        answers: ["Assassin's Creed"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20UPLAY%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-directors-cut-edition-steam-gift-global-i10000003710001?gtag=391e4ef696"],
        franchise: 'Assassins Creed',
        twitter_ids: ["1682147138900459521", "1682044481095487488"],
        release_year: '2007',
        metacritic_score: '81%',
        genre: 'Action, Adventure, Stealth',
        console_platform: 'PS3, X360',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '434': {
        content: 'Inscryption is an atmospheric card-based puzzle game developed by Daniel Mullins Games. Launched in 2021, the game takes players on a mysterious and twisted journey where they play a deadly card game against a menacing, masked opponent.    The gameplay in Inscryption combines elements of deck-building roguelikes, escape room-style puzzles, and psychological horror. Players build their deck by defeating creatures and gaining their cards, while also solving puzzles and unveiling cryptic clues about the game\'s narrative.    What sets Inscryption apart is its innovative blend of genres and mechanics. Its intense, cerebral card battles are matched by equally intriguing narrative and puzzle-solving elements. Its ominous atmosphere and darkly intricate storyline provide a gripping backdrop to its strategic card play.    Inscryption has been widely acclaimed for its compelling narrative, innovative gameplay, and atmospheric presentation. If you\'re a fan of distinctive and challenging card games like Slay the Spire or tabletop puzzle games like Betrayal at House on the Hill, you\'ll be a fan of Inscryption.',
        submitted_by: 'https://www.instagram.com/vollyblue/',
        answers: ["Inscryption"],
        affiliate_links: ["https://www.g2a.com/inscryption-pc-steam-key-global-i10000270495002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1682397115635625988", "1682396857702699008", "1682395465021812736"],
        release_year: '2021',
        metacritic_score: '87%',
        genre: 'Roguelike deck-building',
        console_platform: 'PC',
        developer: 'Daniel Mullins Games',
        backloggd_link: '',
    },
    '435': {
        content: 'F-Zero is a futuristic racing game developed by Nintendo EAD and released in 1990 for the Super Nintendo Entertainment System. Known for its high-speed racing, challenging difficulty, and impressive Mode 7 graphics, F-Zero throws players into the 26th century, where they compete in the high-octane, anti-gravity F-Zero Grand Prix.    The gameplay in F-Zero involves racing against opponents on high-speed tracks suspended high above cityscapes. Players must expertly navigate turns, avoid hazards, and utilize speed boosters, all while managing their vehicle\'s energy, which doubles as both health and fuel for boosts.    What sets F-Zero apart is its fast-paced and unforgiving gameplay, requiring precision control and strategic use of boosts. Its diverse roster of vehicles, each with unique performance attributes, and the series\' iconic blue Falcon, piloted by Captain Falcon, are hallmarks of the franchise.    F-Zero has been celebrated for its thrilling speed, tight controls, and innovative design that helped define the future racing genre. If you\'re a fan of high-speed racing games like Wipeout or Mario Kart, you\'ll be a fan of F-Zero.',
        submitted_by: '',
        answers: ["f-zero"],
        affiliate_links: [],
        franchise: 'f-zero',
        twitter_ids: ["1682921553477111808", "1682785477714399232"],
        release_year: '1990',
        metacritic_score: 'Not rated',
        genre: 'Racing',
        console_platform: 'SNES',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '436': {
        content: 'Wii Play is a party video game developed and published by Nintendo for the Wii console. Released in 2006, Wii Play offers a collection of nine mini-games, designed to teach players the basics of the Wii Remote controller while providing simple and fun gameplay for all ages.    The gameplay in Wii Play ranges from shooting games to table tennis and fishing. Each game is designed to utilize the unique motion controls of the Wii Remote in different ways, offering a variety of experiences that highlight the controller\'s versatility.    What sets Wii Play apart is its simplicity and accessibility. Its collection of mini-games offers something for everyone, from the strategic billiards game to the chaotic cow racing. Its focus on fun, family-friendly competition and intuitive controls helped introduce many to the world of motion-controlled gaming.    Wii Play has been praised for its easy-to-learn gameplay and the way it demonstrates the capabilities of the Wii Remote. If you\'re a fan of casual, family-friendly games like Wii Sports or Mario Party, you\'ll be a fan of Wii Play.',
        submitted_by: '',
        answers: ["Wii Play"],
        affiliate_links: [],
        franchise: 'Wii',
        twitter_ids: ["1683326458171731969", "1683126208660389888", "1683118158297845760"],
        release_year: '2006',
        metacritic_score: '58%',
        genre: 'Party',
        console_platform: 'Wii',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '437': {
        content: 'Monster Hunter Tri is an action role-playing game developed by Capcom for the Nintendo Wii. Released in 2009, it invites players into a vibrant world of challenging hunts, where they gear up to take on enormous monsters in epic battles, crafting equipment from their remains to tackle even greater challenges.    In Monster Hunter Tri, players traverse large, open environments to hunt dangerous creatures, utilizing a variety of weapons and tactics. The game also introduces underwater environments and combat, expanding the arena for its large-scale battles.    The distinctive feature of Monster Hunter Tri is its deep combat and crafting systems. Each monster presents a unique challenge, requiring players to learn their behaviors and attack patterns. Victory provides materials for crafting a vast array of weapons and armor, allowing for a high degree of customization.    Monster Hunter Tri has been lauded for its intricate gameplay, rewarding progression, and the thrill of its monster hunts. If you\'re a fan of action RPGs with a focus on combat and crafting such as Dark Souls or The Witcher, you\'ll be a fan of Monster Hunter Tri.',
        submitted_by: '',
        answers: ["Monster Hunter Tri", "Monster Hunter 3"],
        affiliate_links: [],
        franchise: 'Monster Hunter',
        twitter_ids: ["1683503436728532993", "1683604278743539713", "1683993169732534272"],
        release_year: '2009',
        metacritic_score: '84%',
        genre: 'Action, RPG',
        console_platform: 'Wii',
        developer: 'Capcom Production Studio 1',
        backloggd_link: '',
    },
    '438': {
        content: 'Windjammers 2 is an exciting sports arcade game developed by Dotemu, the sequel to the cult classic Windjammers from 1994. Launched in 2022, it revives the fast-paced, frisbee-throwing action of the original, pitting players against each other in high-speed duels in various vibrant arenas.    The gameplay in Windjammers 2 revolves around throwing a disc into the opponent\'s goal while defending your own. Players can execute fast throws, lofty lobs, and dazzling special moves, making every match a frenzied test of reflexes and strategy.    What sets Windjammers 2 apart is its blend of sports and fighting game mechanics, offering a unique competitive experience. Its diverse roster of characters, each with unique skills, and a variety of arenas with their own quirks, add layers of depth to its simple yet exhilarating core gameplay.    Windjammers 2 has been praised for its dynamic gameplay, stylish 2D graphics, and faithful recreation of the original\'s addictive competitive action. If you\'re a fan of fast-paced sports games like Rocket League or competitive arcade games like Street Fighter, you\'ll be a fan of Windjammers 2.',
        submitted_by: '',
        answers: ["Windjammers 2"],
        affiliate_links: ["https://www.g2a.com/windjammers-2-pc-steam-gift-global-i10000301612004?gtag=391e4ef696"],
        franchise: 'Windjammers',
        twitter_ids: ["1684058082425331713"],
        release_year: '2022',
        metacritic_score: '80%',
        genre: 'Sports',
        console_platform: 'Switch, PS4, Stadia, PC, XONE',
        developer: 'Dotemu',
        backloggd_link: '',
    },
    '439': {
        content: 'Sanitarium is a psychological horror point-and-click adventure game developed by DreamForge Intertainment and published by ASC Games. Released in 1998, it plunges players into a nightmarish world, as they take on the role of an amnesiac protagonist trying to uncover his identity after waking up in an eerie sanitarium following a car accident.    The gameplay in Sanitarium primarily involves puzzle-solving and exploration, with players traversing through a series of disturbing environments, each with its own twisted narrative. Interacting with characters and objects, players gradually unravel the storyline, which is filled with chilling revelations.    What sets Sanitarium apart is its eerie atmosphere and complex narrative. Its storytelling method, combined with the point-and-click genre, allows for a deep exploration of the protagonist\'s disturbed psyche. The game doesn\'t shy away from discussing complex themes such as mental illness, which adds to its unique narrative approach.    Sanitarium has been applauded for its dark, complex story, innovative gameplay, and the unnerving yet compelling atmosphere it creates. If you\'re a fan of atmospheric point-and-click adventure games like Gabriel Knight or psychological thrillers like Silent Hill, you\'ll be a fan of Sanitarium.',
        submitted_by: 'https://www.youtube.com/@ozguryilmarx',
        answers: ["Sanitarium"],
        affiliate_links: ["https://www.g2a.com/sanitarium-steam-key-global-i10000000631002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1684328380894785536", "1684591994612621313", "1684425692748861441"],
        release_year: '1998',
        metacritic_score: 'Not rated',
        genre: 'Point-and-click adventure',
        console_platform: 'PC',
        developer: 'DreamForge Intertainment',
        backloggd_link: '',
    },
    '440': {
        content: 'Grand Theft Auto: Vice City is an action-adventure game developed by Rockstar North and published by Rockstar Games. Released in 2002, it invites players into the neon-lit, crime-ridden streets of Vice City, a fictional city based on 1980s Miami, as they step into the shoes of Tommy Vercetti, a mafia hitman fresh out of prison.    In Vice City, players engage in a wide variety of missions, from high-speed chases to intense shootouts, all while exploring the bustling, open-world city. Apart from story missions, players can also engage in side activities like property management, optional missions, and the iconic rampages.    What sets Vice City apart is its detailed and lively world, filled with colorful characters and satire of 1980s American culture. Its engaging storyline, coupled with its rich open-world gameplay, makes Vice City a standout title in the Grand Theft Auto series.    Grand Theft Auto: Vice City has been celebrated for its immersive world, engaging story, and the breadth of its gameplay. If you\'re a fan of open-world action games like Saints Row or Sleeping Dogs, you\'ll be a fan of Grand Theft Auto: Vice City.',
        submitted_by: '',
        answers: ["Grand Theft Auto: Vice City"],
        affiliate_links: ["https://www.g2a.com/grand-theft-auto-vice-city-steam-gift-global-i10000001584006?gtag=391e4ef696"],
        franchise: 'Grand Theft Auto',
        twitter_ids: ["1684565455292583938", "1684566246480805888", "1684914725170065409", "1684794397647425536"],
        release_year: '2002',
        metacritic_score: '95%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Rockstar North',
        backloggd_link: '',
    },
    '441': {
        content: 'Project Zomboid is an indie survival horror game that takes place in a post-apocalyptic world infested with zombies. Developed by The Indie Stone, the game provides an open-ended sandbox experience where players must gather resources, build shelter, and fend off hordes of the undead. In this isometric, retro-style game, players navigate through the desolate town of Muldraugh, Kentucky, searching for food, weapons, and other survivors to form alliances or face the terrifying world alone.  One of the standout features of Project Zomboid is the emphasis on realism and the importance of survival skills. Players must manage their characters hunger, thirst, fatigue, and mental state, while also facing the constant threat of illness, injury, and infection. The game simulates day-night cycles, weather conditions, and the gradual decay of perishable items, adding a layer of immersion and realism to the experience.  Furthermore, Project Zomboid offers a dynamic world where players can shape their own narratives. The game includes a deep crafting system that allows players to create weapons, fortifications, and even grow their own food. The choices made by the player directly impact their survival chances and the outcomes within the game.  If youre a fan of other survival horror games like DayZ, Dont Starve, or The Forest, youll be a fan of this one.',
        submitted_by: 'https://www.youtube.com/c/bigboybiggins',
        answers: ["Project Zomboid"],
        affiliate_links: ["https://www.g2a.com/project-zomboid-steam-key-global-i10000004077005?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1684939691601334272", "1684930220611317760"],
        release_year: '2011',
        metacritic_score: 'Early Access',
        genre: 'RPG, Simulation, Survival',
        console_platform: 'Linux, PC',
        developer: 'The Indie Stone',
        backloggd_link: '',
    },
    '442': {
        content: 'Far Cry 3: Blood Dragon is a first-person shooter developed by Ubisoft Montreal and published by Ubisoft. It was unleashed in 2013 as a standalone expansion for Far Cry 3. Set in a dystopian 2007, Blood Dragon immerses players in an elaborate homage to 1980s action films, as players control Sergeant Rex Power Colt, a biologically improved super-soldier on a mission to overthrow an insane former commander. Gameplay, while based on Far Cry 3, emphasizes over-the-top action, ridiculous scenarios, and a plethora of neon aesthetics reminiscent of classic sci-fi and action films. Players can get hands-on with powerful futuristic weaponry, encounter bizarre creatures like the fearsome titular Blood Dragons, and liberate outposts from enemy control in a vibrant open world. In addition to its story, Blood Dragon offers side quests that involve rescuing hostages or hunting unique creatures. Far Cry 3: Blood Dragon has been widely praised for its humor, memorable protagonist, and unique, loving tribute to 80s culture. If you\'re a fan of action-packed shooters like Bulletstorm and over-the-top retro-futuristic settings similar to Hotline Miami, you\'ll be a fan of Far Cry 3: Blood Dragon.',
        submitted_by: 'https://www.youtube.com/@-Dark-Serg-',
        answers: ["Far Cry 3: Blood Dragon"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Far%20Cry%203%20Blood%20Dragon%20Classic%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffar-cry-3-blood-dragon-classic-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/far-cry-3-blood-dragon-xbox-live-key-global-i10000006955007?gtag=391e4ef696"],
        franchise: 'Far Cry',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '81%',
        genre: 'Action, FPS',
        console_platform: 'PS3 , PC, X360',
        developer: 'Ubisoft Entertainment Inc.',
        backloggd_link: '',
    },
    '443': {
        content: 'Prey is a first-person shooter game with elements of psychological horror and stealth, developed by Arkane Studios and published by Bethesda Softworks. Released in 2017, players find themselves aboard the Talos I space station as Morgan Yu, a scientist with the task of combating hostile aliens known as the Typhon, while unraveling the station\'s dark secrets and their own mysterious past. What sets Prey apart is its ability for players to adopt alien powers, turning the tides on their alien adversaries and offering unconventional ways to traverse the complex. The open-world style of the game encourages exploration of the vast, interconnected environment, with play style that relies heavily on player’s choices and actions, making every playthrough a distinct experience. With its innovative gameplay, compelling story, and stunning visuals, Prey presents a unique twist on the sci-fi horror genre. The game received high praise for its atmosphere, detailed setting, and strong narrative. If you\'re a fan of first-person shooter games with deep storylines and elements of horror, like Bioshock or Dead Space, you\'ll be a fan of Prey.',
        submitted_by: 'https://www.youtube.com/@-Dark-Serg-',
        answers: ["Prey"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Prey%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fprey%2F&intsrc=PUI2_9608", "https://www.g2a.com/prey-2017-digital-deluxe-edition-steam-key-global-i10000034041010?gtag=391e4ef696"],
        franchise: 'Prey',
        twitter_ids: ["1685771868220096512", "1685654224825430016"],
        release_year: '2017',
        metacritic_score: '82%',
        genre: 'Action, FPS',
        console_platform: 'PC, PS4, XONE',
        developer: 'Arkane Studios LLC',
        backloggd_link: '',
    },
    '444': {
        content: 'Anthem is an online multiplayer action RPG developed by BioWare and published by Electronic Arts. Released in 2019, it propels players in the role of Freelancers, bold adventurers wielding powerful Javelin exosuits in a world left unfinished by the gods and brimming with primeval, highly active technology. The gameplay in Anthem is a fusion of shooting and role-playing elements, with players donning fully customizable exosuits, each with unique abilities and traits, to explore its expansive, ever-changing world, carry out missions, collect loot, and engage in cooperative multiplayer battles. The game boasts large-scale world events like Shaper Storms that dynamically alter the terrain, adding an element of unpredictability to each expedition. Players can trek, fly, and swim through spectacular and deadly vistas, which contribute to the game\'s immersion. Featuring a shared world that aims to seamlessly blend single-player and multiplayer experiences, it serves up cooperative PvE battles against savage beasts and ruthless marauders. Anthem\'s unique blend of action, RPG mechanics, immersive art style, and innovative approach to storytelling have garnered the game a unique place among modern RPGs. If you\'re a fan of cooperative online games like Destiny or Warframe combined with the RPG elements of the Mass Effect series, you\'ll be a fan of Anthem.',
        submitted_by: '',
        answers: ["Anthem"],
        affiliate_links: ["https://www.g2a.com/anthem-origin-key-pc-global-i10000068306011?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1686131846642556929"],
        release_year: '2019',
        metacritic_score: '59%',
        genre: '3rd-person, Shooter, Action, RPG Elements',
        console_platform: 'PC, PS4, XONE',
        developer: 'BioWare Edmonton, BioWare Austin, LLC',
        backloggd_link: '',
    },
    '445': {
        content: 'Only Up! is a captivating platformer game developed and published by SCKR Games. Launched in 2023, it presents a unique gaming perspective where players guide a charismatic character on a non-stop climb to the unreachable skies. The narrative is simple yet engaging. Players ascend vertically in the gameplay of Only Up!, maneuvering the protagonist through a rapidly scrolling environment from the bottom, composed of a myriad of platforms. It has been lauded for its addictive gameplay and skill-demanding complexity. If you\'re a fan of engaging platformers like Super Meat Boy or quick-paced endless games like Temple Run, you\'ll certainly be a fan of Only Up!',
        submitted_by: '',
        answers: ["Only Up!"],
        affiliate_links: ["https://www.g2a.com/only-up-pc-steam-key-global-i10000339816001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1686377589752119296", "1686530049531658245", "1686619816101126144", "1686834745177452545", "1686765204787630080"],
        release_year: '2023',
        metacritic_score: 'Not Rated',
        genre: 'Platformer, Frustrating',
        console_platform: 'PC',
        developer: 'SCKR Games',
        backloggd_link: '',
    },
    '446': {
        content: 'Day of the Tentacle is a graphic adventure game developed and published by LucasArts. Released in 1993, it thrusts players in a zany, comedic world of time-traveling adventures. As Bernard, Laverne, and Hoagie, three eccentric friends, the players must outsmart an evil, purple tentacle that dreams of world domination. In Day of the Tentacle, players navigate through separate timelines—past, present, and future—in an effort to thwart the villainous appendage\'s plan. Gameplay involves solving puzzles, interacting with a colorful cast of characters, and working to manipulate time to affect past and future scenarios for the trio\'s benefit. Its unique time-travel mechanic encourages clever puzzle-solving; changes made in one timeline directly impact the others. The narrative is delivered with slapstick humor, outrageous scenarios, and colorful animations, which are hallmarks of this title\'s charm. It has been lauded for its clever puzzles, distinctive art style, memorable characters, and the humorous script. If you\'re a fan of humorous adventure games like Sam & Max or Monkey Island series, you\'ll be a fan of Day of the Tentacle.',
        submitted_by: '',
        answers: ["Day of the Tentacle", "Maniac Mansion II: Day of the Tentacle"],
        affiliate_links: ["https://www.g2a.com/day-of-the-tentacle-remastered-steam-key-global-i10000012916004?gtag=391e4ef696"],
        franchise: 'Maniac Mansion',
        twitter_ids: ["1686878968060985344"],
        release_year: '1993',
        metacritic_score: '93%',
        genre: 'Adventure, Point & Click',
        console_platform: 'DOS',
        developer: 'LucasArts Entertainment Company LLC',
        backloggd_link: '',
    },
    '447': {
        content: 'Splatoon 3 is a team-based third-person shooter game developed and published by Nintendo. Launching in 2022, it welcomes players back into the vibrant and ink-splattered world of the Inklings, humanoid characters who can morph into squids. In the newest installment, players must navigate through a new city location, Splatville, and the desolate Splatlands region. The game still maintains its 4v4 Turf War battles where players aim to cover the most territory with their team\'s ink but introduces new weapons, moves, and abilities to add more strategic layers to the gameplay. Players can also engage in various styles of matches in different modes, enhancing the level of team-based multiplayer interaction. Apart from the classic multiplayer modes, Splatoon 3 also offers a deeply engaging single-player campaign, where players can uncover the mysteries of the Inkling world, battling against a range of creative bosses and enemies in the process.',
        submitted_by: 'https://www.instagram.com/magical_blue_hat/',
        answers: ["Splatoon 3"],
        affiliate_links: ["https://www.g2a.com/splatoon-3-nintendo-switch-nintendo-eshop-key-united-states-i10000336890001?gtag=391e4ef696", "https://www.amazon.com/Splatoon-Bundle-Standard-Nintendo-Digital/dp/B0BVD4ZW4S?crid=3KG0OP1G86EET&keywords=Splatoon+3&qid=1685572539&sprefix=splatoon+3%2Caps%2C437&sr=8-7&linkCode=ll1&tag=guessthegam06-20&linkId=17eaac15e7d779ec270e230edb6c5426&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Splatoon',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '83%',
        genre: 'Action',
        console_platform: 'Switch',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '448': {
        content: 'Friday the 13th: The Game is a survival horror video game developed by IllFonic and published by Gun Media. Released in 2017, players are plunged into the eerie surroundings of Camp Crystal Lake from the popular horror film franchise. The game operates on an asymmetrical multiplayer platform, with one player taking on the role of Jason Voorhees, tasked with hunting down and eliminating the other players who are taking the role of camp counselors. As Jason, players have access to a range of supernatural abilities, such as warping and heightened senses, while the counselors must employ stealth and strategy to avoid his pursuit and gather resources to survive, escape or even defeat Jason. The game offers a tense and atmospheric gaming experience that mirrors the frightful suspense of the film series. The gameplay relies on teamwork, strategy, and quick decision making, breathing new life into the survival horror genre. If you\'re a fan of asymmetrical multiplayer horror games like Dead by Daylight or Evolve, you\'ll be a fan of Friday the 13th: The Game.',
        submitted_by: '',
        answers: ["Friday the 13th: The Game"],
        affiliate_links: ["https://www.g2a.com/friday-the-13th-the-game-steam-key-global-i10000042338007?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '61%',
        genre: 'Action, Survival, Horror',
        console_platform: 'PC, PS4, XONE',
        developer: 'IllFonic LLC',
        backloggd_link: '',
    },
    '449': {
        content: '',
        submitted_by: '',
        answers: ["Pentiment"],
        affiliate_links: ["https://www.g2a.com/pentiment-pc-steam-gift-global-i10000336733003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '88%',
        genre: 'Adventure, Narrative',
        console_platform: 'PC, PC, XONE, Xbox Series',
        developer: 'Obsidian Entertainment, Inc.',
        backloggd_link: '',
    },
    '450': {
        content: 'Dance Dance Revolution is a rhythm-based game developed by Konami and first released in arcades in 1998 and later for PlayStation in 1999. This iconic game launches players onto a virtual dance floor where they match their physical steps with the on-screen instructions in tune with high-energy music. Gameplay lies within the coordination of rapid footwork as players step on up, down, left, and right arrows on a dance platform in response to corresponding prompts scrolling on the screen. This game, often shortened to DDR, revolutionized the rhythm genre with its focus on physical activity and catchy music tracks, including popular hits and Konami originals. While maintaining the fun factor, Dance Dance Revolution also introduced a new level of gaming by integrating a physical workout, which contributed to its massive worldwide popularity. The game boasts multiple difficulties and an engaging multiplayer mode which made DDR a hit in arcades, parties, and homes alike. Dance Dance Revolution is lauded for its unique gameplay, stellar soundtrack, and its revolution in bringing physicality into the gaming universe. If you\'re a fan of rhythm games like Just Dance or Beat Saber, you\'ll be a fan of Dance Dance Revolution.',
        submitted_by: '',
        answers: ["Dance Dance Revolution"],
        affiliate_links: [],
        franchise: 'Dance Dance Revolution',
        twitter_ids: [],
        release_year: '1998, 1999',
        metacritic_score: '90%',
        genre: 'Rhythm',
        console_platform: 'Arcade, PS1',
        developer: 'Konami Co., Ltd., KCE Tokyo, Inc.',
        backloggd_link: '',
    },
    '451': {
        content: 'Castle Crashers is a 2D beat \'em up video game developed by The Behemoth and released in 2008. Set in a fictional medieval universe, the game revolves around four knights who embark on a quest to rescue kidnapped princesses and reclaim stolen treasures, battling a variety of enemies along the way. Castle Crashers incorporates elements of traditional beat \'em up games with role-playing game characteristics, allowing players to level up their characters, unlock new weapons, and purchase skills or pets that can assist in battle. Combat is fast-paced and offers a blend of hack-and-slash combined with magic attacks. The game is notably recognized for its hand-drawn art style, humor, and multiplayer game modes which adds to the chaotic charm. Playing solo or cooperatively with up to four players either locally or online, provides endless fun and mayhem. Castle Crashers has been praised for its addictive gameplay and chaotic multiplayer action. If you\'re a fan of beat \'em up games with RPG elements like Scott Pilgrim vs. The World: The Game or Streets of Rage, you\'ll be a fan of Castle Crashers.',
        submitted_by: 'https://www.twitch.tv/buttahqueen',
        answers: ["Castle Crashers"],
        affiliate_links: ["https://www.g2a.com/castle-crashers-remastered-xbox-live-key-global-i10000005278001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '82%',
        genre: 'Action, Platformer, Side View, Brawler',
        console_platform: 'X360',
        developer: 'The Behemoth',
        backloggd_link: '',
    },
    '452': {
        content: 'Ryse: Son of Rome is an action-adventure hack and slash game developed by Crytek and published by Microsoft Studios. The game is set in an alternate version of Rome and follows the life of the Roman centurion Marius Titus as he embarks on a quest to avenge the death of his family. The gameplay mainly comprises of melee combat scenarios involving large groups of enemies, where players can execute enemies in a way that is likened to the gameplay of the God of War series. It is also peppered with quick-time events that add dramatic flair to the combat sequences. Ryse: Son of Rome pushes the multiplayer aspect with the Gladiator mode, a cooperative combat experience set in the Colosseum, where teamwork and strategy are crucial in the ever-changing arena. Further enhancing the cinematic experience is the game\'s use of Kinect which allows players to command troops in battle. The game is applauded for its stunning visuals, the cinematic presentation of the narrative and its immersive depiction of Ancient Rome. If you\'re a fan of action-adventure games like God of War or Assassin’s Creed, you\'ll be a fan of Ryse: Son of Rome.',
        submitted_by: '',
        answers: ["Ryse: Son of Rome"],
        affiliate_links: ["https://www.g2a.com/ryse-son-of-rome-xbox-live-key-global-i10000006263009?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1689279118251048961", "1689049648114126849"],
        release_year: '2013',
        metacritic_score: '60%',
        genre: 'Action, Adventure',
        console_platform: 'XONE',
        developer: 'Crytek GmbH',
        backloggd_link: '',
    },
    '453': {
        content: 'Fallout is a post-apocalyptic role-playing game developed and published by Interplay Productions. Released in 1997, the game allows players to step into a grim vision of the future, taking on the role of a dweller from Vault 13, sent out into the irradiated wastelands to find a replacement for their vault\'s water purification chip. As Fallout progresses, players encounter various towns, ruined cities, and factions, each shaped by the nuclear cataclysm in unique ways. The gameplay of Fallout is defined by its turn-based combat and open exploration, allowing players to take on missions and explore the world at their own pace, making choices that influence the world and story around them. The ability to shape the player character\'s skills and abilities, and the freedom to make moral selections that have significant impacts on the game world, make Fallout a unique and engaging RPG experience. With its dark setting, deep narrative, and tactical gameplay, Fallout has remained a cornerstone of the genre. If you\'re a fan of dystopian RPGs like Wasteland or Deus Ex, you\'ll be a fan of Fallout.',
        submitted_by: 'https://twitter.com/corentin_lamy',
        answers: ["Fallout"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fallout%20Classics%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffallout-classics-collection%2F&intsrc=PUI2_9608", "https://www.g2a.com/fallout-classic-collection-steam-key-global-i10000001986003?sid=12700265?gtag=391e4ef696"],
        franchise: 'Fallout',
        twitter_ids: ["1689416099576655872", "1689430922221719552", "1689688771611316224", "1689550108721913857", "1689524701020794880"],
        release_year: '1997',
        metacritic_score: '89%',
        genre: 'RPG, Isometric',
        console_platform: 'Macintosh, DOS, PC',
        developer: 'Interplay Productions, Inc., Black Isle Studios',
        backloggd_link: '',
    },
    '454': {
        content: 'Star Wars Jedi: Survivor is a thrilling action-adventure game developed by Respawn Entertainment and published by Electronic Arts. Launched in 2023, it\'s set in the legendary Star Wars universe, inviting players to guide a surviving Jedi through varied planets and grim circumstances. Players assume the role of a Jedi Knight who barely escapes the Order 66 purge, and they venture on a pursuit to piece together an understanding of the Force, dodging the looming threat of discovery by the relentless Empire. Jedi: Survivor gamely delivers a mixture of exploration, combat, and character progression. Players embark on a captivating single-player narrative exploring diverse environments, from lush forests to inhospitable industrial complexes, while uncovering indigenous mysteries and ancient civilizations. Fluid lightsaber combat sequences require strategic movement and parrying, enhancing the weighty yet responsive combat system. Unique to the game is the player\'s ability to utilise and expand their Force skill set, adding depth to the gameplay. Star Wars Jedi: Survivor has won admiration for its charismatic characters, meticulously designed worlds, and an appealing combat system with a Star Wars sheen. If you\'re a fan of immersive action-adventure games such as God of War or The Witcher, you\'ll be a fan of Star Wars Jedi: Survivor.',
        submitted_by: '',
        answers: ["Star Wars Jedi: Survivor"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=STAR%20WARS%20Jedi%20Survivor%20Deluxe%20Edition%20-%20Xbox%20Series%20XS&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstar-wars-jedi-survivor-deluxe-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/star-wars-jedi-survivor-deluxe-edition-pc-steam-gift-global-i10000337546010?sid=13444692?gtag=391e4ef696"],
        franchise: 'Star Wars',
        twitter_ids: ["1689897329069817856", "1689863207697928192", "1690105111027109888"],
        release_year: '2023',
        metacritic_score: '78%',
        genre: 'Action, 3rd-person, Sci-Fi',
        console_platform: 'PC, PS5, Xbox Series',
        developer: 'Respawn Entertainment LLC',
        backloggd_link: '',
    },
    '455': {
        content: 'Killing Floor 2 is a unique combination of gore-laden first-person shooter developed by Tripwire Interactive, released in 2016. It sees players battling against gruesome creatures known as Zeds in cooperative squad-based combat. Set in various locales in Europe, following an outbreak caused by a biotechnology company, players must defend themselves from waves of grotesque Zeds while unraveling the mystery of the outbreak. Play style in Killing Floor 2 primarily revolves around teamwork and strategy as players fortify their defenses, procure weapons, and support each other while they face increasing numbers of Zeds. Your survival heavily depends on your team\'s ability to seamlessly coordinate their strengths. Its slow-motion killing sequences, known as Zed Time, is a unique feature that allows players to witness their destructive power in all its glory. Praised for its challenging gameplay, variety of weapons and classes, and terrifying enemies, Killing Floor 2 stands as a staple of co-op horror. If you\'re a fan of cooperative shooters like Left 4 Dead or survival horrors like Dead By Daylight, you\'ll surely enjoy Killing Floor 2.',
        submitted_by: 'https://www.youtube.com/@-Dark-Serg-',
        answers: ["Killing Floor 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Killing%20Floor%20Complete%20Pack%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkilling-floor-complete-pack-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/killing-floor-2-digital-deluxe-edition-upgrade-steam-key-global-i10000000172002?gtag=391e4ef696"],
        franchise: 'Killing Floor',
        twitter_ids: ["1690122653217296389"],
        release_year: '2016',
        metacritic_score: '75%',
        genre: 'First-person shooter, Survival horror',
        console_platform: 'PC, PS4',
        developer: 'Tripwire Interactive',
        backloggd_link: '',
    },
    '456': {
        content: 'Bully is an action-adventure game developed by Rockstar Vancouver and published by Rockstar Games. Launched in 2006, it delves into the chaotic life of teenage misfit, Jimmy Hopkins, amid the backdrop of the fictitious Bullworth Academy, a private boarding school rife with corruption and juvenile pranks. The game promotes exploration, mischief, and narrative as players shuttle between classes, extracurricular activities, and schoolyard high jinks. The gameplay uniquely oscillates between engaging study classes and a myriad of missions involving fights, pranks, and competing school factions. One of Bully’s distinctive features is its intriguing school schedule system that spices up gameplay, every school day offering unexpected twists, fun challenges, and quests to undertake. Incorporating humor, teenage angst, and social commentary, Bully also thoughtfully addresses the issue of bullying and social dynamics often witnessed in a school environment. Its distinctive narrative, varied gameplay, and the youthful yet gritty atmosphere received applause from critics and players. If you\'re a fan of open-world action-adventure games like Grand Theft Auto: San Andreas or Life Is Strange, you\'ll be a fan of Bully.',
        submitted_by: '',
        answers: ["Bully"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Bully%20Scholarship%20Edition%20Steam%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbully-scholarship-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/bully-scholarship-edition-steam-gift-global-i10000015208004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '87%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Rockstar Vancouver',
        backloggd_link: '',
    },
    '457': {
        content: 'Bioshock 2 is a first-person shooter developed by 2K Marin and published by 2K Games. A sequel to the critically acclaimed Bioshock, this installment, released in 2010, takes players back to the dystopian underwater city of Rapture, but this time from a new perspective.    Set about a decade after the events of the first game, players take on the role of Subject Delta, a prototype for the iconic Big Daddies. Throughout the game, you\'re on a quest to find Eleanor, your bonded Little Sister, navigating through the dilapidated and haunting corridors of Rapture while confronting both its deranged inhabitants and the mysterious figure, Sofia Lamb.    Gameplay in Bioshock 2 integrates intense gunfights with the strategic use of plasmids, which grant the player special powers. The sequel introduced the ability to use weapons and plasmids simultaneously, ramping up the combat intensity. A key element of gameplay also revolves around the moral choices concerning the Little Sisters – to save or harvest them for their ADAM.    Bioshock 2 deepens the lore of Rapture, expanding upon its rich narrative and adding layers to the city\'s tragic history. The game has been praised for its atmospheric world-building, engaging storyline, and thoughtful mechanics. If you\'re a fan of story-driven shooters like the original Bioshock or the Dishonored series, you\'ll be a fan of Bioshock 2.',
        submitted_by: '',
        answers: ["Bioshock 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=BioShock%20The%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbioshock-the-collection%2F&intsrc=PUI2_9608", "https://www.g2a.com/bioshock-bundle-bioshock-bioshock-2-steam-key-global-i10000043842002?gtag=391e4ef696"],
        franchise: 'Bioshock',
        twitter_ids: ["1690979588522786817", "1690947094725480448", "1690936900066906113", "1690946324819218432", "1690861807613648896"],
        release_year: '2010',
        metacritic_score: '88%',
        genre: 'First-person shooter, horror elements',
        console_platform: 'PS3, PC, X360',
        developer: '2K Marin',
        backloggd_link: '',
    },
    '458': {
        content: 'Killzone is a first-person shooter developed by Guerrilla Games and published by Sony Computer Entertainment. Released in 2004, it throws players into the middle of a heated interplanetary war between the ruthless Helghast Empire and the resourceful Interplanetary Strategic Alliance. Players assume control of four unique ISA operatives, each with their own abilities and weapons. The gameplay is a combination of fast-paced shooting, tactical decision making, and intense combat scenarios. Players traverse a variety of war-torn locations, from gritty urban environments to hostile alien landscapes, as they battle through swarms of Helghast soldiers. Unique elements of the game include its cinematic storytelling, realistic physics, and cutting-edge graphics that push the PS2\'s capabilities to the limit. The game is also noted for its grim, atmospheric setting and the distinctive design of the Helghast enemies with their glowing red eyes. Killzone has been lauded for its thrilling campaigns, engaging multiplayer modes, and immersive world building. If you\'re a fan of first-person shooters like Call of Duty or Halo, you\'ll be a fan of Killzone.',
        submitted_by: '',
        answers: ["Killzone"],
        affiliate_links: [],
        franchise: 'Killzone',
        twitter_ids: ["1691209941669797890", "1691408651490148353", "1691535061911252992"],
        release_year: '2004',
        metacritic_score: '70%',
        genre: 'First-person shooter',
        console_platform: 'PS2',
        developer: 'Guerrilla Games',
        backloggd_link: '',
    },
    '459': {
        content: 'Shadow of the Tomb Raider is an action-adventure game developed by Eidos-Montréal and published by Square Enix. In this 2018 release, players are thrust back into the adventurous boots of Lara Croft, this time on a quest through the dangerous jungles of Central America to stop a Mayan apocalypse. The gameplay combines exploration, puzzle-solving, and combat, with a particular emphasis on stealth and environmental interactions. Players can traverse through their surroundings using acrobatic maneuvers and utilize the dense foliage and weather conditions to gain an edge against enemies. New to the series, the game also features large-scale urban environments filled with NPC characters, adding depth and realism to the world. The game pursues darker and more emotionally resonant themes than its predecessors as Lara grapples with the consequences of her actions. Shadow of the Tomb Raider was recognized for its well-crafted narratives, immersive environments, and the refined stealth-based combat, which adds a different dimension to the action-adventure experience. If you\'re a fan of action-packed adventure games like Uncharted or Assassin\'s Creed, you\'ll be a fan of Shadow of the Tomb Raider.',
        submitted_by: '',
        answers: ["Shadow of the Tomb Raider"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Shadow%20of%20the%20Tomb%20Raider%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fshadow-of-the-tomb-raider-definitive-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/shadow-of-the-tomb-raider-definitive-edition-xbox-live-xbox-one-key-global-i10000148601032?gtag=391e4ef696"],
        franchise: 'Tomb Raider',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '75%',
        genre: 'Action, Adventure',
        console_platform: 'PS4, PC, XONE',
        developer: 'Eidos-Montréal',
        backloggd_link: '',
    },
    '460': {
        content: 'Warcraft: Orcs & Humans is a real-time strategy game developed by Blizzard Entertainment. Released in 1994, it established an epic conflict between the Kingdom of Azeroth and the invading orcish Horde. This legendary title is where players first stepped into the rich and immersive world of Azeroth, offering a mixture of real-time strategy, fantasy, and RPG elements. Players can choose to command the heroic humans to protect their lands or lead the ruthless orc horde in their quest for domination. No matter which side they choose, players are tasked with gathering resources, building bases, recruiting armies, and leading their forces into battle in a strategic, real-time environment. Besides the engrossing single-player campaign, Warcraft: Orcs & Humans also offers a competitive multiplayer mode, where you can match your strategic prowess against others over a LAN connection. Since its release, the game has been celebrated for its compelling storytelling, addictive gameplay and seamless integration of RPG elements within its strategy framework. If you\'re a fan of real-time strategy games like Starcraft or Age of Empires, you\'ll be a fan of Warcraft: Orcs & Humans.',
        submitted_by: '',
        answers: ["Warcraft: Orcs & Humans", "Warcraft"],
        affiliate_links: [],
        franchise: 'Warcraft',
        twitter_ids: ["1692135993535578598", "1692240569467068743", "1692054480282341827", "1691955031635874174"],
        release_year: '1994',
        metacritic_score: 'Not Rated',
        genre: 'Real-time strategy',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '461': {
        content: 'Titan Quest is an action role-playing game developed by Iron Lore Entertainment and published by THQ. Released in 2006, the game takes players on an epic adventure through ancient Greece, Egypt, and China, as they unravel legends of the Titans, powerful beings who were imprisoned by the Gods but have now escaped. As players traverse the rich historical settings, they take on various mythical creatures in an attempt to restore order. Gameplay in Titan Quest is largely driven by its fast-paced combat system and deep character customization, further enhanced by its detailed loot system. Players can choose and upgrade their skills across three class trees, each offering a range of abilities that cater to different play styles. Unlike most action RPGs, Titan Quest features a unique, physics-based system, where the environment dynamically reacts to the player\'s actions. On its release, Titan Quest was lauded for its epic scale, immersive environments, and engaging gameplay mechanics. If you\'re a fan of action RPGs like Diablo or Sacred, you\'ll be a fan of Titan Quest.',
        submitted_by: '',
        answers: ["Titan Quest"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Titan%20Quest%20Anniversary%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftitan-quest-anniversary-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/titan-quest-bundle-steam-key-global-i10000187970001?sid=13404277?gtag=391e4ef696"],
        franchise: 'Titan Quest',
        twitter_ids: ["1692512564993761774", "1692378706453369082"],
        release_year: '2006',
        metacritic_score: '77%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'Iron Lore Entertainment',
        backloggd_link: '',
    },
    '462': {
        content: 'Crab Game is a vibrant multiplayer action game developed by Dani and published by DaniDev Studios. Released in 2021, the game immerses players in a chaotic, fun-filled world, reminding many of the hit Netflix series Squid Game. Players are randomly selected to participate in a variety of heart-racing mini-games, with each round eliminating the unfortunate losers until a single player remains victorious. Its gameplay involves exploring the colourful surroundings, evading deadly traps, and outsmarting your competitors in a series of survival challenges ranging from king of the hill, deadly tag, and explosive hot potato. Gameplay unpredictability coupled with the frenzied scramble of each round infuses every game with a unique, dynamic feeling. Crab Game has been lauded for its intense multiplayer showdowns, easy-to-grasp mechanics, and the thrilling tension it creates with its mini-game based survival format. If you\'re a fan of rapid-paced, multiplayer action games like Among Us or Fall Guys, you\'ll love the aggressive frenzy of Crab Game.',
        submitted_by: '',
        answers: ["Crab Game"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1692638624406479251", "1692567990737457396"],
        release_year: '2021',
        metacritic_score: 'Not Rated',
        genre: 'Party, Battle Royale',
        console_platform: 'PC',
        developer: 'Daniel Sooman',
        backloggd_link: '',
    },
    '463': {
        content: 'American McGee\'s Grimm is an episodic adventure game, developed by Spicy Horse. Released in 2008, this game immerses players in twisted versions of well-known fairy tales, as seen through the eyes of Grimm, a dwarf who finds the sanitization of his stories insufferable. Grimm\'s primary mission is to restore his dark version of events, affording a unique narrative approach to gaming. Players navigate through familiar tales like Little Red Riding Hood and Snow White, actively transforming them from their bright, Disney-like versions into much darker scenarios. This is achieved by manipulating the environment and influencing characters, leaving a trail of darkness and chaos behind. The game combines platform elements with point-and-click controls for puzzle-solving aspects, providing a unique gameplay twist. American McGee\'s Grimm has been praised for its unique premise, clever writing, and diverse gameplay elements. The visual distinction between the cutesy fairy tale world and Grimm\'s darker version is notable, offering a stark contrast that underscores Grimm\'s irreverent transformation of these tales. If you\'re a fan of narrative driven games like Broken Age or unique platformers like Little Nightmares, you\'ll be a fan of American McGee\'s Grimm.',
        submitted_by: '',
        answers: ["American McGee's Grimm", "Grimm"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: 'Not Rated',
        genre: 'Action, Adventure',
        console_platform: 'PC',
        developer: 'Spicy Horse',
        backloggd_link: '',
    },
    '464': {
        content: 'The Last of Us Part II is an action-adventure game developed by Naughty Dog and published by Sony Interactive Entertainment. As the sequel to the universally beloved The Last of Us, the game boasts an emotionally hard-hitting plot set in a post-apocalyptic world. Players take control of Ellie, now 19 and living in relative peace, until a violent event disrupts her life, thrusting her into a brutal journey of survival and revenge. The gameplay is built around stealth, crafting, and combat with infected humans and other survivors. Unique in this installment is the ability to traverse while prone, adding a new dimension to stealth and combat scenarios. Additionally, players can use a variety of weapons, and can customize and upgrade them through scavenging. The game has garnered appreciation for its evolved gameplay mechanics, improved graphics and sound design, and its nuanced take on morally complex themes. Its depiction of LGBT characters has also been a highlight, breaking new ground in mainstream gaming. The Last of Us Part II pushes boundaries and weaves a memorable story that lingers long after the controller is put down. If you are a fan of narrative-driven action games like Red Dead Redemption 2 or atmospheric post-apocalyptic tales like Metro Exodus, you will be a fan of The Last of Us Part II.',
        submitted_by: '',
        answers: ["The Last of Us Part II", "The Last Of Us Part 2"],
        affiliate_links: ["https://www.g2a.com/the-last-of-us-part-ii-ps4-psn-account-account-global-i10000193066003?gtag=391e4ef696"],
        franchise: 'The Last of Us',
        twitter_ids: ["1693615289374531894", "1693687304005492763", "1693577074194194858", "1693524641552162895", "1693499650756813080"],
        release_year: '2020',
        metacritic_score: '93%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Naughty Dog',
        backloggd_link: '',
    },
    '465': {
        content: 'S.T.A.L.K.E.R.: Shadow of Chernobyl is a first-person shooter survival horror game developed by Ukrainian game developer GSC Game World. Set in the area around the Chernobyl nuclear reactor disaster, known as The Zone, players take on the role of a Stalker, a scavenger searching for artifacts, combating hostile creatures and surviving in this alien environment, while also piecing together the protagonist\'s mysterious past. The gameplay of S.T.A.L.K.E.R.: Shadow of Chernobyl combines elements of FPS, RPG, and stealth games. Unique to Shadow of Chernobyl is the A-Life system, which governs the behavior of the world\'s various entities and factions in the absence of the player\'s direct involvement, generating a dynamic, living world. The game\'s story is non-linear, with multiple endings available based on the player’s choices. The game received widespread acclaim for its realistic depiction of the game\'s setting, the blend of genres, and the innovative A-Life system. If you\'re a fan of first-person shooter survival horror games like Metro 2033 or Far Cry series, you\'ll be a fan of S.T.A.L.K.E.R.: Shadow of Chernobyl.',
        submitted_by: '',
        answers: ["S.T.A.L.K.E.R.: Shadow of Chernobyl", "S.T.A.L.K.E.R."],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=STALKER%20Bundle%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstalker-bundle-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/stalker-shadow-of-chernobyl-gogcom-key-global-i10000000870005?gtag=391e4ef696"],
        franchise: 'S.T.A.L.K.E.R.',
        twitter_ids: ["1693854444541108282", "1693949622178267290", "1693845305224053067", "1694004216585658864", "1693846045942243553"],
        release_year: '2007',
        metacritic_score: '82%',
        genre: 'First-person shooter, Survival horror',
        console_platform: 'PC',
        developer: 'GSC Game World',
        backloggd_link: '',
    },
    '466': {
        content: 'Psychonauts 2 is a platform game developed by Double Fine Productions and published by Xbox Game Studios. Picking up after the events of the original Psychonauts and its VR follow-up Rhombus of Ruin, the game allows players to again step into the shoes of Razputin Aquato, an acrobatically inclined psychic spy, now serving as an intern with the titular Psychonauts. Players explore the physical world and use Razputin\'s psychic abilities to venture into the mental worlds of various characters. This time the Psychonauts must confront a mole in their ranks while dealing with the trauma and secrets uncovered by this psychic surveillance. Gameplay combines platforming and puzzle-solving, with Razputin\'s psychic abilities affecting both, being used to fight enemies, navigate challenging platforms, and manipulate the environments. Psychonauts 2 enriches its predecessor\'s acclaimed gameplay and whimsical storytelling with deeper narrative themes and even more vibrant, imaginative world-building. If you\'re a fan of games like Banjo-Kazooie or Ratchet & Clank that mix imaginative platforming with character-driven storytelling, you\'ll be a fan of Psychonauts 2.',
        submitted_by: '',
        answers: ["Psychonauts 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Psychonauts%202%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpsychonauts-2-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/psychonauts-2-pc-steam-gift-global-i10000190197005?gtag=391e4ef696"],
        franchise: 'Psychonauts',
        twitter_ids: ["1694199118485184663", "1694314525586346425", "1694452395114152147", "1694216184579629347", "1694110038112006604"],
        release_year: '2021',
        metacritic_score: '89%',
        genre: 'Action, Platform, Puzzle elements, Mindbending',
        console_platform: 'PS4, PC, XONE, Xbox Series',
        developer: 'Double Fine Productions, Inc.',
        backloggd_link: '',
    },
    '467': {
        content: 'Gauntlet is a fantasy-themed hack and slash arcade game developed by Atari Games and released in 1985. This groundbreaking multiplayer dungeon crawler allows up to four players to choose between a wizard, warrior, valkyrie, or elf, each having distinct abilities, with the wizard being skilled in magic, the warrior in hand-to-hand combat, the valkyrie in defenses, and the elf in speed. Players hack through hordes of enemies, gather keys, power-ups, and food, while exploring a myriad of mazes filled with traps, teleporters, and secret rooms. The game\'s emphasis on cooperative multiplayer, its damage-based health system and the ability to wear down walls struck a chord with fans upon release. Due to its inventive gameplay mechanics, Gauntlet is often hailed as a pioneer in the realm of multiplayer dungeon crawlers. Its innovative use of speech synthesis also added much to the game ambiance, with phrases like \"Elf needs food, badly\" becoming iconic in the gaming world. If you\'re a fan of multiplayer dungeon crawlers like Diablo or Magicka, you\'ll be a fan of Gauntlet.',
        submitted_by: '',
        answers: ["Gauntlet"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Gauntlet%20%204%20Pack%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgauntlet-4-pack%2F&intsrc=PUI2_9608", "https://www.g2a.com/gauntlet-slayer-edition-4-pack-steam-key-global-i10000007013003?gtag=391e4ef696"],
        franchise: 'Gauntlet',
        twitter_ids: ["1694607309555569109"],
        release_year: '1985',
        metacritic_score: 'Too old',
        genre: 'Hack and slash, Dungeon crawl',
        console_platform: 'Arcade',
        developer: 'Atari Games',
        backloggd_link: '',
    },
    '468': {
        content: 'Mortal Kombat is a classic arcade fighting game, developed by Midway and NetherRealm Studios. Released in 1992, it sparked a revolution in the fighting game genre with its visceral combat, interactive environment, and distinctive roster of characters. Players step into the tournament of Mortal Kombat, assuming the role of one from a selection of unique fighters, each with their own special abilities and fatalities. In a series of one-on-one matches, they battle through opponents to claim victory in the arena. Mortal Kombat set itself apart with its fast-paced action, dynamic fighting mechanics, and its notoriously bloody finishing moves known as \'Fatalities\'. At the time, the game ignited controversy due to its violent content, propelling it to infamy. It has since become one of the most popular and influential fighting games in the genre. Mortal Kombat is renowned for its captivating universe, unforgettable characters, and engaging gameplay. If you\'re a fan of high-adrenaline fighting games like Street Fighter or Tekken, you\'ll be a fan of Mortal Kombat.',
        submitted_by: '',
        answers: ["Mortal Kombat"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Mortal%20Kombat%201%20Premium%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmortal-kombat-1-premium-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/mortal-kombat-1-premium-edition-pc-steam-key-global-i10000339458006?gtag=391e4ef696"],
        franchise: 'Mortal Kombat',
        twitter_ids: ["1694839594787762523"],
        release_year: '1992',
        metacritic_score: 'Too old',
        genre: 'Fighting',
        console_platform: 'Arcade',
        developer: 'Midway',
        backloggd_link: '',
    },
    '469': {
        content: 'BeamNG.drive is a realistic, immersive driving game offering near limitless possibilities. Developed and published by BeamNG, this vehicular sandbox allows players to experience driving in a level of detail virtually unparalleled in other games. Launched in 2013 as a Tech Demo and later released on Steam in 2015, it takes players on a journey through a world where vehicles experience realistic damage and wear, thanks to the innovative and extremely accurate soft-body physics engine. The game includes open-world maps and terrains, featuring zones perfect for drag racing, off-road adventures, and even moonlight cruises. Players can experiment with various vehicles and situations, exploring the effects of a crash or testing out new stunts. The game offers a unique blend of applied physics, detailed customization, and an interactive, immersive environment. Allowing for amazing replay value, BeamNG.drive continues to keep players engaged with its fresh approach to the driving game genre. If you\'re a fan of simulation games like Euro Truck Simulator or extensive car customization games like Need For Speed, you\'ll be a fan of BeamNG.drive.',
        submitted_by: 'https://www.youtube.com/@ardoarmo5894',
        answers: ["BeamNG.drive"],
        affiliate_links: ["https://www.g2a.com/beamngdrive-steam-gift-global-i10000001157003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013 as Tech Demo, 2015 on Steam',
        metacritic_score: 'No Score',
        genre: 'Racing, Driving, Simulation',
        console_platform: 'PC',
        developer: 'BeamNG GmbH',
        backloggd_link: '',
    },
    '470': {
        content: 'Exoprimal, released in 2023, is an innovative action game developed and published by Capcom. The game propels players into a future where dinosaurs have resurged on Earth, due to mysterious exo-asteroids crashing onto the planet. The story revolves around the heroic members of the “Exoprimal” team, who leverage advanced exosuits to battle the ferocious dinosaur invasion. The gameplay is defined by its tactical cooperation, fighting massive dinosaur hordes using various customizable exosuits, each offering distinct abilities and weaponry. Strategic team play is crucial as the intensity of the dinosaur onslaught fluctuates, compelling space for dynamic, high-octane battles. Unique to the game is the \"Dino Summon\" system where players can call upon a dinosaur to aid in battle, adding a layer of excitement and unpredictability. Exoprimal stands out for its large-scale battles, strategic approach, and incorporation of dinosaurs creating a fusion of modern gaming mechanics with primordial scenarios. If you\'re a fan of cooperative action games like Monster Hunter and the storytelling delight of the Jurassic Park series, you\'ll be a fan of Exoprimal.',
        submitted_by: '',
        answers: ["Exoprimal"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Exoprimal%20Deluxe%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fexoprimal-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/exoprimal-deluxe-edition-pc-steam-gift-global-i10000338735007?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1695640426726756700", "1695565733701341521"],
        release_year: '2023',
        metacritic_score: '68%',
        genre: 'Action, Shooter',
        console_platform: 'PC, PS4, XONE, PS5, Xbox Series',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '471': {
        content: 'Diablo IV is a dungeon crawler action role-playing game developed and published by Blizzard Entertainment. Launched in 2023, it immerses players in the dark and gothic world of Sanctuary. Players get to choose from various character classes each with unique abilities, and venture into dangerous lands teeming with all manner of demonic creatures. A primary hook of Diablo IV lies in its combat mechanics, with an emphasis on loot gathering, character progression through skill trees and schematics, and real-time combat. Players also experience the thrill of cooperative multiplayer in shared open world areas. The game expands on its predecessors by introducing rideable mounts and larger, more diverse world areas to explore. If you\'re a fan of action RPGs with a deep narrative like Path of Exile or grim dungeon crawlers like Dark Souls, Diablo IV will surely captivate your interest.',
        submitted_by: '',
        answers: ["Diablo IV", "Diablo 4"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Diablo%20IV%20Digital%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdiablo-iv-digital-deluxe-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/diablo-iv-ultimate-edition-pc-battlenet-key-global-i10000192338032?gtag=391e4ef696"],
        franchise: 'Diablo',
        twitter_ids: ["1695814086775468516"],
        release_year: '2023',
        metacritic_score: '86%',
        genre: 'Action RPG, Hack and slash, Grindfest',
        console_platform: 'PC, PS4, XONE, PS5, Xbox Series',
        developer: 'Blizzard Entertainment Inc.',
        backloggd_link: '',
    },
    '472': {
        content: 'Dante’s Inferno is an engaging action-adventure video game developed by Visceral Games and published by Electronic Arts. Based on the first part of Dante Alighieri’s ‘Divine Comedy’, the 2010 game engrosses players in a quest through the nine Circles of Hell - limbo, lust, gluttony, greed, anger, heresy, violence, fraud, and treachery, as they guide Dante, the Templar knight. Dante’s Inferno emphasizes hack and slash combat, with Dante’s primary weapon being Death’s Scythe. A unique game element is pulled from the poem, where Dante can \'absolve\' or \'punish\' the damned, affecting his character progression. Players often face ethical dilemmas, dealing with condemned souls, ultimately molding Dante\'s skills and abilities. The game unfolds Dante\'s backstory through his journey, where he confronts his past sins in hauntingly desolate landscapes and battles monstrous entities. Dante’s Inferno is noteworthy for its gripping narrative, vivid imagery reflecting the torments of Hell, and a seamless blend of fast-paced action and platforming. If you\'re a fan of hack and slash games with deep narratives like God of War or Devil May Cry, you\'ll be a fan of Dante’s Inferno.',
        submitted_by: 'twitch.tv/ghostyyrose',
        answers: ["Dante\u2019s Inferno"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1696501880224952351", "1696381968999403785", "1696565161618710558"],
        release_year: '2010',
        metacritic_score: '75%',
        genre: 'Action, 3rd person, Fantasy',
        console_platform: 'X360, PS3, PSP',
        developer: 'Visceral Games (Redwood Shores), Artificial Mind & Movement',
        backloggd_link: '',
    },
    '473': {
        content: 'Vampyr is an action RPG developed by Dontnod Entertainment and published by Focus Home Interactive. Released in 2018, it immerses players in London\'s gloomy streets during the 1918 Spanish flu pandemic as Dr. Jonathan Reid, a doctor turned vampire. As Reid, players navigate a semi-open world torn between forces of life and death. Each citizen in the world has a unique role, personality, and backstory, and players face moral dilemmas over whether to feed on them to gain power. Combat hinges on a blend of melee and supernatural vampire powers, with progression linked to Reid\'s blood consumption. Vampyr\'s unique mechanic lies in its Citizen system. Each NPC has a life of their own and enriches the plot. Killing them for their blood will empower Reid but destabilize the now fragile London. Vampyr stands out for its atmospheric world, complex narrative choices tied to gameplay, and a distinct twist on the RPG formula with the integration of vampiric powers. If you\'re a fan of rich story-driven RPGs like The Witcher series or action games with moral choices like Infamous series, you\'ll be a fan of Vampyr.',
        submitted_by: 'https://www.instagram.com/bonder.25',
        answers: ["Vampyr"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Vampyr%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fvampyr%2F&intsrc=PUI2_9608", "https://www.g2a.com/vampyr-steam-gift-global-i10000149645005?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1696955498250252771", "1697025190549454992"],
        release_year: '2018',
        metacritic_score: '72%',
        genre: 'Action RPG, 3rd person, Horror',
        console_platform: 'PC, PS4, XONE',
        developer: 'DONT NOD Entertainment SA',
        backloggd_link: '',
    },
    '474': {
        content: 'SOMA is a science fiction survival horror video game developed and published by Frictional Games. Launched in 2015, SOMA drops players into the shoes of Simon Jarrett, an individual who has ended up in an underwater remote research facility with machinery that begins to take on human characteristics. With no recollection of how he got there, Simon\'s struggle to unravel the truth forms the main plotline of the game. The game focuses heavily on stealth, exploration, and puzzle-solving, with few direct combat scenarios, instead relying on the player\'s ability to navigate the surroundings whilst avoiding monstrous hazards. It uniquely blends elements of psychological horror with deep philosophical elements, tackling themes such as consciousness and the essence of humanity. SOMA has been praised for its incredible atmosphere, thoughtful narrative, and evocative sound design, all of which contributes to an experience that\'s as terrifying as it\'s intellectually stimulating. This existential thriller isn\'t for the faint of heart, but if you\'re a fan of bioengineered nightmares like Alien: Isolation or atmospheric psychological thrillers like Amnesia: The Dark Descent, you\'ll be a fan of SOMA.',
        submitted_by: 'https://twitter.com/ouijaisbored',
        answers: ["SOMA"],
        affiliate_links: ["https://www.g2a.com/soma-steam-gift-global-i10000005706003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1697241477406159147"],
        release_year: '2015',
        metacritic_score: '84%',
        genre: 'Adventure, Survival horror',
        console_platform: 'PC, Macintosh, PS4',
        developer: 'Frictional Games AB',
        backloggd_link: '',
    },
    '475': {
        content: 'Candy Crush Saga is a highly addictive puzzle game developed and published by King. Released in 2012, this sweet-filled, match-three game became an instant hit, has millions of players worldwide. Players get to enter a candy-coated world and match three or more identical candies in a row to score points and progress through hundreds of levels. The colourful, candy-filled game board is a treat for the eyes and the brain, as players contemplate the most effective moves to create combos and clear levels. As you progress in the game, you will come across different obstacles that make matching candies more difficult, providing a consistent challenge. The game also allows for social integration, where players can compete against their friends on Facebook to see who can achieve the highest score. The game\'s simple yet challenging mechanics, pleasing visuals, and the satisfaction of creating candy combos keep players coming back for more. Candy Crush Saga has been praised for its addictive gameplay, colourful graphics, and the use of strategic thinking. If you\'re a fan of match-three puzzle games like Bejeweled or Puzzle Quest, then you\'ll be a fan of Candy Crush Saga.',
        submitted_by: '',
        answers: ["Candy Crush Saga", "Candy Crush"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1697380588418732469"],
        release_year: '2012',
        metacritic_score: '79%',
        genre: 'Puzzle, Tile matching puzzle, addictive?',
        console_platform: 'Browser, iPhone, iPad, Android',
        developer: 'Midasplayer AB',
        backloggd_link: '',
    },
    '476': {
        content: '',
        submitted_by: 'https://www.instagram.com/riptryed/',
        answers: ["Dave the Diver"],
        affiliate_links: ["https://www.g2a.com/dave-the-diver-deluxe-edition-pc-steam-account-account-global-i10000338687010?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '90%',
        genre: 'Action, Simulation, Managerial, Business Simulation',
        console_platform: 'PC',
        developer: 'MINTROCKET',
        backloggd_link: '',
    },
    '477': {
        content: 'BattleBit Remastered is an exciting and modern take on the tactical shooter genre, developed and published by MrOkiDoki. Launched in 2023, the game offers players adrenaline-fueled gameplay set in expansive, detailed environments designed to create immersive battlefields. Players assume the roles of multiple units across several factions, utilizing a vast arsenal of weaponry and vehicle combat to enact their strategic approaches. The core of BattleBit Remastered is focused on team-based, strategic maneuvering, and combined arms warfare, rewarding careful planning and team cooperation. One unique element about the game is its modification system, which allows players to adapt their weapons and gear to specific combat situations, granting a further degree of depth and strategy. With its intricate game mechanics and a focus on teamwork, BattleBit Remastered pushes the boundaries of the tactical shooter genre, incorporating large-scale battles, destructible environments, and a compelling, war-torn narrative. While it provides an authentic military experience, its approachable design ensures it is still accessible to newcomers. If you\'re a fan of classic tactical shooters like Tom Clancy\'s Rainbow Six or large-scale warfare games like Battlefield, you\'ll be a fan of BattleBit Remastered.',
        submitted_by: 'https://www.youtube.com/@prodquill8442',
        answers: ["BattleBit Remastered"],
        affiliate_links: ["https://www.g2a.com/battlebit-remastered-supporter-edition-pc-steam-account-account-global-i10000339769010?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1698207265780531454"],
        release_year: '2023',
        metacritic_score: 'None yet',
        genre: 'Action, Massively Multiplayer, Shooter, Teamwork',
        console_platform: 'PC',
        developer: 'SgtOkiDoki, Vilaskis, TheLiquidHorse',
        backloggd_link: '',
    },
    '478': {
        content: 'Pacman is an iconic arcade game developed by Namco and first released in 1980. Immersing players in a maze-like game world, it introduces the eponymous Pacman, a yellow, pizza-shaped character that navigates the labyrinth, gobbling pellets and avoiding the game’s ghostly antagonists. The objective of the game is simple and addictive: eat all the dots in the maze while avoiding the four pesky ghosts - Blinky, Pinky, Inky, and Clyde. The gameplay mechanics introduce power pellets, special items that temporarily turn the tables, allowing Pacman to eat the ghosts for bonus points. This balance of chase and escape creates a sense of urgency and excitement within the game. Certain conditions and rules, such as the warp tunnels and fruit bonuses, add layers of complexity and a strategic component to what appears as straightforward gameplay. Pacman was groundbreaking for its non-violent, maze-chase gameplay, steering away from the shoot-em-up trend prevalent in the arcades of its era, and set the standard for future maze games. If you\'re a fan of classic arcade games like Space Invaders or Donkey Kong, you\'ll definitely be a fan of Pacman.',
        submitted_by: '',
        answers: ["Pacman", "Pac-man", "Pac"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=PAC%20MAN%20WORLD%20Re%20PAC%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpac-man-world-re-pac-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/pac-man-and-the-ghostly-adventures-steam-key-global-i10000044447002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1698557182202331523", "1698563109282037904", "1698579223735144949"],
        release_year: '1980',
        metacritic_score: 'Too old',
        genre: 'Acrtion, Arcade',
        console_platform: 'Arcade',
        developer: 'Namco Limited, Namco Bandai Games Inc., Namco Networks America Inc., Piko Interactive LLC',
        backloggd_link: '',
    },
    '479': {
        content: 'Signalis is a sci-fi horror game developed by Rose-Engine, released on October 27, 2022. The game follows the story of Elster, who is stuck on a mysterious planet filled with deadly creatures and dark secrets. Elster uses her skills and weapons to explore multiple planets and uncover its hidden past. The game utilizes a retro-style pixel art aesthetic, paired with a high-quality soundtrack that sets the tone for the game\'s haunting atmosphere. Players will need to use their problem-solving skills to progress through the game\'s levels, fighting for survival against a variety of monsters. It also features a psychological horror storyline, weaving in elements of mental torture and madness. As players uncover the story, they can upgrade their weapons and abilities, unlocking new areas to explore. Signalis has been praised for its unique combination of retro aesthetics and modern game design, along with its compelling storyline and immersive atmosphere, drawing comparisons to other horror games like Silent Hill, Dead Space and Bioshock. If you\'re a fan of these games, you\'ll be a fan of this one.',
        submitted_by: 'https://twitter.com/hoiiowknight',
        answers: ["Signalis"],
        affiliate_links: ["https://www.g2a.com/signalis-pc-steam-gift-global-i10000337235004?gtag=391e4ef696", "https://www.amazon.com/SIGNALIS-English-Nintendo-Switch/dp/B0B6GYDY92?keywords=Signalis&qid=1686874286&sr=8-3&linkCode=ll1&tag=guessthegam06-20&linkId=710c172607e643a036b403235cade54f&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: ["1699040857050034601"],
        release_year: '2022',
        metacritic_score: '81%',
        genre: 'Action, Shooter, Survival horror',
        console_platform: 'PC, PS4, XONE, Switch',
        developer: 'rose-engine GbR',
        backloggd_link: '',
    },
    '480': {
        content: 'Hotline Miami 2: Wrong Number is an intense top-down shooter video game developed by Dennaton Games and published by Devolver Digital. Released in 2015, this sequel to the original Hotline Miami continues the pulsating action and neon-drenched aesthetics while introducing a larger emphasis on storytelling. The game splinters its narrative across various characters and timelines, exploring the aftermath and origins of the events in the first game. Much like its predecessor, the gameplay in Hotline Miami 2 is a relentless rush of high-risk combat situations that require quick thinking and reflexes as players navigate through brightly lit, maze-like environments to take out enemies. Each level is a deadly dance of kill or be killed, where any hit is lethal. An infectious, bass-heavy soundtrack underlines each mission, creating an intense, hypnotic atmosphere. Hotline Miami 2 has been lauded for its challenging gameplay, striking art style, and haunting exploration of violence and its consequences. If you\'re a fan of high-speed action games like Enter the Gungeon or fast-paced tactical games like John Wick Hex, you\'ll be a fan of Hotline Miami 2: Wrong Number.',
        submitted_by: 'https://www.instagram.com/manuvicentiel/',
        answers: ["Hotline Miami 2: Wrong Number", "Hotline Miami 2"],
        affiliate_links: ["https://www.g2a.com/hotline-miami-2-wrong-number-digital-special-edition-steam-key-global-i10000007141003?aid=13387490?gtag=391e4ef696", "https://www.amazon.com/Hotline-Miami-Collection-PS4-playstation-4/dp/B092RJQ5N1?crid=3HWXU4R04GW9&keywords=Hotline+Miami+2%3A+Wrong+Number&qid=1685570937&sprefix=super+smash+bros.+melee%2Caps%2C906&sr=8-32&linkCode=ll1&tag=guessthegam06-20&linkId=db1fde27456a062865865cd1a229844a&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'Hotline Miami',
        twitter_ids: ["1699458725592150490", "1699396871649685765"],
        release_year: '2015',
        metacritic_score: '74%',
        genre: 'Action, Shooter',
        console_platform: 'PC, PS3, PS Vita, PS4, Mobile',
        developer: 'Dennaton Games',
        backloggd_link: '',
    },
    '481': {
        content: 'Legacy of Kain: Soul Reaver is an action-adventure game developed by Crystal Dynamics and published by Eidos Interactive. Released in 1999, Soul Reaver transports players into the dark fantasy world of Nosgoth, where they assume the role of Raziel, a vengeful vampire-turned-spectral being. Banished to the Abyss and left for dead by Kain, his maker, Raziel embarks on a cruel path for retribution. The gameplay combines exploration, combat, and puzzle-solving. The signature feature of the game is Raziel\'s ability to shift between the spectral and material planes, a mechanic that is integral to both combat and puzzle-solving. Players explore an expansive, semi-open world, battling enemies and uncovering the secrets of Nosgoth\'s past. Its cinematic narrative, presented through in-game dialogues and cutscenes, paints a gruesome, gripping tale of vengeance and redemption. Legacy of Kain: Soul Reaver receives well-deserved praise for its strong storytelling, immersive world, solid gameplay mechanics, and the creative use of plane-shifting in its puzzles. If you\'re a fan of atmospheric action-adventure titles like The Legend of Zelda: Ocarina of Time or Prince of Persia: The Sands of Time, you\'ll be a fan of Legacy of Kain: Soul Reaver.',
        submitted_by: 'https://twitter.com/Wheel_skeleton',
        answers: ["Legacy of Kain: Soul Reaver"],
        affiliate_links: ["https://www.g2a.com/legacy-of-kain-soul-reaver-steam-gift-global-i10000000295001?gtag=391e4ef696"],
        franchise: 'Legacy of Kain',
        twitter_ids: ["1699789111710900625", "1699827853075050519"],
        release_year: '1999',
        metacritic_score: '91%',
        genre: 'Action, Metroidvania, Puzzle elements',
        console_platform: 'PC, PS1',
        developer: 'Crystal Dynamics, Inc.',
        backloggd_link: '',
    },
    '482': {
        content: '',
        submitted_by: '',
        answers: ["Hi-Fi Rush"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=HiFi%20RUSH%20Digital%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhifi-rush-digital-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/hi-fi-rush-deluxe-edition-pc-steam-gift-global-i10000338052010?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '89%',
        genre: 'Action, Music, Rhythm',
        console_platform: 'PC, PC, Xbox Series',
        developer: 'Tango Gameworks',
        backloggd_link: '',
    },
    '483': {
        content: 'Destroy All Humans! is an open world action-adventure game developed by Pandemic Studios and published by THQ. Released in 2005, it catapults players into 1959 America and onto an extraordinary mission as Crypto, an alien from the Furon Empire, with one objective: harvest human DNA and cause as much destruction as possible. Gameplay encompasses a wide range of alien weaponry and psychic abilities to use against the panicked populace, police, and military forces, enabling spectacular displays of chaos. The game stands out with its tongue-in-cheek commentaries, parodying Cold War-era America\'s fear of alien invasion and controversial pop culture references. Other than causing bedlam, players are required to undertake missions that involve abduction, political assassination, and government infiltration, all whilst using a variety of futuristic weapons and advanced technology like their flying saucer. Destroy All Humans! is known for its unique gameplay elements, humorous narrative, and outlandish premise. Whether you\'re reading minds for humorous thoughts, impersonating officials, or simply lighting up cities with your death ray, the game offers a distinctive blend of action that any fan of bizarre, humorous games like Stubbs the Zombie or Mercenaries will love.',
        submitted_by: '',
        answers: ["Destroy All Humans!"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Destroy%20All%20Humans%20Remake%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdestroy-all-humans-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/destroy-all-humans-remake-pc-steam-key-global-i10000195330008?gtag=391e4ef696"],
        franchise: 'Destroy All Humans',
        twitter_ids: [],
        release_year: '2005. Remake 2020',
        metacritic_score: '74%',
        genre: 'Action, Adventure',
        console_platform: 'PS2, Xbox (Remade on modern platform later)',
        developer: 'Pandemic Studios',
        backloggd_link: '',
    },
    '484': {
        content: 'Assassin\'s Creed Unity is a historic action-adventure game developed by Ubisoft Montreal and published by Ubisoft. Set during the French Revolution, players take on the role of Arno Dorian, a young man embroiled in the ancient conflict between Assassins and Templars. The gameplay heavily focuses on stealth and parkour as players navigate across a perfectly reconstructed 18th-century Paris, scale iconic buildings like Notre-Dame and the Palace of Versailles, and blend into crowds to avoid detection. Apart from main missions, the game offers a wide array of side quests, including murder mysteries and co-op missions that add depth to Arno\'s story and the larger world. Uniquely, Assassin\'s Creed Unity introduced the franchise\'s first cooperative multiplayer mode, allowing friends to engage in narrative-driven missions together. The game has been acknowledged for its detailed, atmospheric world design, improved parkour mechanics, and cooperative gameplay aspect. If you\'re a fan of stealthy, action-packed games like Dishonored or the Hitman series, you\'ll be a fan of Assassin\'s Creed Unity.',
        submitted_by: '',
        answers: ["Assassin's Creed Unity"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20Unity%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-unity%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-unity-steam-gift-global-i10000001788001?gtag=391e4ef696"],
        franchise: 'Assassins Creed',
        twitter_ids: ["1700912473371459732", "1700728789120979019"],
        release_year: '2014',
        metacritic_score: '70%',
        genre: 'Action-adventure, Stealth',
        console_platform: 'PC, PS4, XONE',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '485': {
        content: 'Resident Evil is a survival horror game developed and published by Capcom. Initially released in 1996, this iconic game sets players in the midst of a zombie outbreak in the fictional Raccoon City, exploring the eerie confines of a secluded mansion filled with deadly traps, puzzles, and horrific creatures. Players assume the roles of either Chris Redfield or Jill Valentine, members of a special forces unit known as S.T.A.R.S., each with their unique abilities and storyline. Gameplay involves combat, exploration, and puzzle-solving, with limited resources adding a level of stress and strategy. Its heavy emphasis on atmosphere, slow exploration, and an overwhelming sense of dread set a precedent for many future horror games. Resident Evil has been lauded for creating the survival horror genre, with its tense atmosphere, horrifying enemies, and groundbreaking gameplay. The title has not only become an iconic piece of gaming history, but it also laid the foundation for an entire series of games. If you\'re a fan of survival horror games like Silent Hill or Dead Space, you\'ll be a fan of Resident Evil.',
        submitted_by: '',
        answers: ["Resident Evil"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Resident%20Evil%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fresident-evil-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/resident-evil-0-biohazard-0-hd-remaster-steam-gift-global-i10000008868004?gtag=391e4ef696"],
        franchise: 'Resident Evil',
        twitter_ids: ["1701202092612792630", "1700994588692128135"],
        release_year: '1996',
        metacritic_score: '91%',
        genre: 'Survival horror',
        console_platform: 'Playstation',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '486': {
        content: 'Rec Room is a vibrant and engaging virtual reality social club developed and published by Against Gravity. Released in 2016, this multiplayer video game brings players into atmospheric virtual reality spaces to participate in a plethora of games and activities. From exploring user-generated rooms to engaging in exciting cooperative challenges, the possibilities are endless in Rec Room. Amidst numerous activities are popular games like dodgeball, paintball, and charades or creative pursuits such as building custom rooms or becoming a virtual DJ. Each interaction in Rec Room is intended to maximize the use of motion and voice chat to communicate and interact with others, providing an immersive social VR experience. The game is predominantly praised for its lively community, the wide range of activities and the capacity to truly express oneself virtually. The fascinating blend of social interaction and gameplay make Rec Room stand out among other VR experiences. If you\'re a fan of immersive multiplayer games like VRChat or Tower Unite, you\'ll be a fan of Rec Room.',
        submitted_by: '',
        answers: ["Rec Room"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1701370781211476276", "1701517663925891119", "1701534245087477765"],
        release_year: '2016',
        metacritic_score: 'Not Rated',
        genre: 'Game creation system, massively multiplayer online',
        console_platform: 'PC',
        developer: 'Rec Room Inc.',
        backloggd_link: '',
    },
    '487': {
        content: 'Ghost of Tsushima is an open-world action-adventure game developed by Sucker Punch Productions and published by Sony Interactive Entertainment. Taking players back to 1274, the game sets you on a samurai\'s path during the first Mongol invasion of Japan. You step into the role of Jin Sakai, one of the last samurai on the island of Tsushima fighting for his homeland\'s freedom. Ghost of Tsushima combines elements of stealth, combat, and exploration in its gameplay. Players can choose to face enemies head-on in traditional samurai duels, or adopt the way of the Ghost, using stealth and deception to systematically dismantle enemy forces. The dynamic world is filled with rich characters, stunning vistas, and side quests that enhance the depth of the main narrative. The game\'s unique feature is the \'Kurosawa Mode\', a tribute to the legendary film director Akira Kurosawa, which makes the game mimic the stylized, black-and-white aesthetics of classical samurai films. Widely acclaimed for its lush graphics, engaging combat system, and immersive storyline, Ghost of Tsushima delivers a riveting samurai tale. If you\'re a fan of epic open-world games like Red Dead Redemption 2 or The Witcher 3, you\'ll be a fan of Ghost of Tsushima.',
        submitted_by: '',
        answers: ["Ghost of Tsushima"],
        affiliate_links: ["https://www.g2a.com/ghost-of-tsushima-ps4-psn-account-account-global-i10000195095006?sid=13438496?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1701720349291762169", "1701924102481629478"],
        release_year: '2020',
        metacritic_score: '83%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Sucker Punch Productions',
        backloggd_link: '',
    },
    '488': {
        content: 'Contra is a run-and-gun action game developed and published by Konami, first released in 1985. Set in the dystopian year of 2633, players take on the role of commandos Bill Rizer and Lance Bean, members of the elite Contra unit, dispatched to neutralize the Red Falcon Organization that is planning to take over Earth with an army of aliens. The gameplay in Contra is characterized by fast-paced, side-scrolling shooting where players are up against a swarm of enemies across various terrains, armed with power-ups that enhance their firepower. Its simultaneous two-player mode, challenging boss battles, and the inclusion of the now-iconic Konami code - a cheat that grants 30 extra lives - all contribute to its classic status. Contra is known for its high level of difficulty, great cooperative play, and innovative power-up system, turning it into a staple name in the sphere of action gaming. Its relentless action and unforgettable gameplay have made it a classic in the run-and-gun genre. If you\'re a fan of intense action games like Metal Slug or Gunstar Heroes, you\'ll be a fan of Contra.',
        submitted_by: '',
        answers: ["Contra"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Contra%20Anniversary%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcontra-anniversary-collection-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/contra-anniversary-collection-steam-key-global-i10000188702005?gtag=391e4ef696"],
        franchise: 'Contra',
        twitter_ids: ["1702288837433508151", "1702080395628417496", "1702229271064789424", "1702281852718035208"],
        release_year: '1985',
        metacritic_score: 'Too old',
        genre: 'Run and gun',
        console_platform: 'Arcade',
        developer: 'Konami',
        backloggd_link: '',
    },
    '489': {
        content: 'Burnout Paradise is an open-world racing video game developed by Criterion Games and published by Electronic Arts.  Released in 2008, the game thrusts players into the heart-pumping adrenaline of street racing in the sprawling metropolis of Paradise City. As a racer, players have to explore the city, finding competitions and challenges at every turn. The core gameplay involves competing in various races and vehicular combat events to earn new vehicles and open up new parts of the map. Beautifully detailed crash sequences are a standout feature, with slow-motion effects and stunning visuals that dramatize the chaos. Burnout Paradise revolutionized the racing genre with its seamless integration of single-player and multiplayer modes in a dynamic open world, setting new standards for gameplay freedom, variety, and engaging online social play. Its non-linear gameplay set in a massive cityscape offers endless hours of exploration and high-speed racing thrill. Burnout Paradise has been praised for its intense racing sequences, realistic crash physics, immersive world, and resistance to the standard racing game formula. If you\'re a fan of high-octane racing games like Need For Speed or Forza Horizon, you\'ll be a fan of Burnout Paradise.',
        submitted_by: '',
        answers: ["Burnout Paradise"],
        affiliate_links: ["https://www.g2a.com/burnout-paradise-remastered-pc-steam-gift-global-i10000155091010?gtag=391e4ef696"],
        franchise: 'Burnout',
        twitter_ids: ["1702462916669198775"],
        release_year: '2008',
        metacritic_score: '87%',
        genre: 'Racing',
        console_platform: 'PC, PS3, X360',
        developer: 'Criterion Games',
        backloggd_link: '',
    },
    '490': {
        content: 'Totally Accurate Battle Simulator is a physics-based tactics game developed by Landfall Games. Released in 2016, it enables players to orchestrate epic battles between a wide range of historical and mythical units. As the name suggests, Totally Accurate Battle Simulator is grounded in accuracy, as far as ragdoll physics in a comedic setting goes. Players are required to strategically place a variety of unit types with differing abilities on the battlefield, before sitting back and watching the wacky physics-based antics unfold. The gameplay is largely geared towards humor and experimentation. One could pit a horde of club-wielding cave people against a medieval knight or even contrast an army of towering mammoths to nimble halflings. The single-player campaign brims with challenging scenarios, but Sandbox mode allows for often hilarious, freewheeling experimentation. Totally Accurate Battle Simulator is primarily celebrated for its comedic tone, amusing ragdoll physics, and limitless replayability. If you\'re a fan of strategy games with a comic twist like The Battle Cats or light-hearted physics-based games like Human: Fall Flat, you\'ll be a fan of Totally Accurate Battle Simulator.',
        submitted_by: '',
        answers: ["Totally Accurate Battle Simulator"],
        affiliate_links: ["https://www.g2a.com/totally-accurate-battle-simulator-steam-gift-global-i10000186957003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: 'Not rated',
        genre: 'Strategy',
        console_platform: 'PC',
        developer: 'Landfall Games',
        backloggd_link: '',
    },
    '491': {
        content: 'Grand Theft Auto: Chinatown Wars is an action-adventure game developed by Rockstar Leeds in conjunction with Rockstar North and published by Rockstar Games. It was launched in 2009 and serves as an exciting addition to the renowned Grand Theft Auto series. The game catapults players into the complex and fast-paced underworld of Liberty City, accompanied by the character Huang Lee, who is out to avenge his father\'s murder and continue the family\'s tradition in the Triad criminal syndicate. Chinatown Wars veers from its console counterparts by adopting an isometric view, although it maintains the open-world gameplay that the series is cherished for. Players engage in intense gunfights, fulfill action-packed missions, deal in illicit drugs, and evade the police in dynamic chases. The game also incorporates a unique element of touch-screen mechanics on DS and mobile versions, from hot-wiring cars to assembling sniper rifles, which notably enhances immersive gameplay. Grand Theft Auto: Chinatown Wars was praised for its ambitious handheld touch controls, impressive graphics, and the depth of its world and story. If you\'re a fan of crime-filled action-adventure games like Mafia or Sleeping Dogs, you\'ll definitely appreciate Grand Theft Auto: Chinatown Wars.',
        submitted_by: '',
        answers: ["Grand Theft Auto: Chinatown Wars"],
        affiliate_links: [],
        franchise: 'Grand Theft Auto',
        twitter_ids: ["1703279643439026229", "1703175360232198469"],
        release_year: '2009',
        metacritic_score: '93%',
        genre: 'Action, Adventure',
        console_platform: 'DS, PSP',
        developer: 'Rockstar Leeds, Rockstar North',
        backloggd_link: '',
    },
    '492': {
        content: 'Banjo-Kazooie is a whimsical platformer developed by Rare and published by Nintendo. Unveiled in 1998, this game enchants players into a fantastic journey of a lovable bear, Banjo, and his astute bird companion, Kazooie. They embark on a daring mission to rescue Banjo\'s sister Tooty, who is kidnapped by the wicked witch Gruntilda. Gameplay in Banjo-Kazooie ingeniously intertwines exploration, puzzle-solving, and combat. They wander through a variety of colourful and imaginative worlds brimming with secrets and unique characters. In their quest, they tap into the odd pair\'s diverse array of abilities to overcome hurdles and ward off Gruntilda\'s evil minions. Both Banjo and Kazooie can learn new moves from Bottles, the mole, which further expands the gameplay possibilities. The game\'s charm lies not only in its engaging gameplay but also in its humorous dialogue, quirky characters, and catchy soundtrack. The game\'s innovative style of embedding puzzles within platforming and the \'collectathon\' elements of gameplay set a new trend in the genre. If you\'re a fan of games like Super Mario 64 and Donkey Kong 64 that mix platform gaming with elements of exploration and item collection, you\'ll be a fan of Banjo-Kazooie.',
        submitted_by: '',
        answers: ["Banjo-Kazooie"],
        affiliate_links: [],
        franchise: 'Banjo & Kazooie',
        twitter_ids: ["1703531767695544782", "1703529533305925899", "1703796786932879745", "1703792484634263801"],
        release_year: '1998',
        metacritic_score: '92%',
        genre: 'Platform, Action, Adventure',
        console_platform: 'N64',
        developer: 'Rare',
        backloggd_link: '',
    },
    '493': {
        content: 'Infamous 2 is an open-world action-adventure game developed by Sucker Punch Productions and published by Sony Computer Entertainment. Released in 2011, it is the second installment in the Infamous series and continues the story of Cole MacGrath, a bike messenger turned superpowered conduit after a massive explosion in Empire City.    In Infamous 2, Cole travels to the city of New Marais to develop his powers and prepare for the arrival of a formidable being known as The Beast. Along the way, he must navigate a city in turmoil, as militias, mutated creatures, and other conduits stand in his way.    The gameplay in Infamous 2 involves a mix of parkour, melee combat, and the use of Cole\'s electrical powers. Players can freely explore the city, engage in story missions, or participate in various side activities. A karma system affects the narrative and gameplay based on the player\'s moral choices, leading to different endings.    What sets Infamous 2 apart is its engaging narrative, varied gameplay, and the ability to shape Cole\'s character through moral decisions. The game was praised for its expansive open-world environment, improved combat mechanics, and compelling story. If you\'re a fan of superhero games like Spider-Man or open-world action games like Grand Theft Auto, you\'ll be a fan of Infamous 2.',
        submitted_by: '',
        answers: ["Infamous 2"],
        affiliate_links: [],
        franchise: 'Infamous',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '83%',
        genre: 'Action, Adventure',
        console_platform: 'PS3',
        developer: 'Sucker Punch Productions',
        backloggd_link: '',
    },
    '494': {
        content: 'Duke Nukem 3D is a first-person shooter game developed by 3D Realms and published by GT Interactive. Released in 1996, it invites players into the action-packed world of Duke Nukem, amidst a bustling metropolis under alien invasion. The game is renowned for its mature humor and pop culture references. In Duke Nukem 3D, players traverse through interactive levels full of enemies, puzzles, and secret areas, armed with an eccentric arsenal of weapons, from classic pistols to shrink rays. The game encourages exploration and player interaction with the hardcore environments, laying the groundwork for immersive, destructible environments in later FPS titles. Its iconic protagonist, the titular Duke Nukem, with his macho one-liners and muscular bravado, is a memorable figure of 90s gaming. Duke Nukem 3D stands out for its versatile level design, which swings from urban streets to space stations, a user-friendly level editor, and its boundary-pushing interactivity. If you\'re a fan of first-person shooter games with a comedic twist like Borderlands or Bulletstorm, you\'ll be a fan of Duke Nukem 3D.',
        submitted_by: '',
        answers: ["Duke Nukem 3D"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Duke%20Nukem%203D%2020th%20Anniversary%20World%20Tour%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fduke-nukem-3d-20th-anniversary-world-tour-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/duke-nukem-3d-20th-anniversary-world-tour-ps4-psn-account-account-global-i10000041618008?gtag=391e4ef696"],
        franchise: 'Duke Nukem',
        twitter_ids: ["1704549354265034895", "1704296111673073976", "1704399057601413530"],
        release_year: '1996',
        metacritic_score: '89%',
        genre: 'First-person shooter',
        console_platform: 'PC',
        developer: '3D Realms',
        backloggd_link: '',
    },
    '495': {
        content: 'Detroit: Become Human is an interactive drama and action-adventure game developed by Quantic Dream and published by Sony Interactive Entertainment. Set in a near-future Detroit where the rise of androids has transformed the world, players control three distinct androids whose fate intertwines in a complex narrative. The plot addresses themes of artificial intelligence and what it means to be human, through the eyes of androids Kara, Connor, and Markus as they navigate their way through a human-centric society. Gameplay consists of making dialogue and action decisions from the perspective of these three characters, with choices affecting how the story unfolds, leading to multiple potential endings. Quick time events and dialogue selections make up a critical part of gameplay, with player\'s actions and decisions having long-term consequences. A noteworthy feature is the ability to rewind to certain points in the story to redesign the path the narrative takes. Detroit: Become Human is celebrated for its deeply emotional storytelling, ambitious branching narrative, and stunning visuals. If you\'re a fan of choice-driven narrative games like Heavy Rain or Telltale\'s The Walking Dead, you\'ll be a fan of Detroit: Become Human.',
        submitted_by: '',
        answers: ["Detroit: Become Human"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Detroit%20Become%20Human%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdetroit-become-human-pc%2F&intsrc=PUI2_9608df7", "https://www.g2a.com/detroit-become-human-pc-steam-gift-global-i10000155809006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1704679997578051615", "1704625196915732858", "1704619140185522629", "1704880301174845595", "1704812729310028241"],
        release_year: '2018',
        metacritic_score: '78%',
        genre: 'Adventure',
        console_platform: 'PS4',
        developer: 'Quantic Dream',
        backloggd_link: '',
    },
    '496': {
        content: 'Mega Man 2 is an action platformer developed and published by Capcom and released in 1988. The second installment in the highly successful Mega Man series, it sees players stepping into the iconic blue armour of Mega Man, on a mission to stop the villainous Dr. Wily and his army of Robot Masters. In the gameplay, players traverse through different stages, each personalized to a specific Robot Master, where they navigate challenging terrain, combat various enemies, contend with tricky platforming segments, and eventually fight the Robot Masters in intense boss battles. Upon defeating a Robot Master, Mega Man acquires its unique weapon, which can be strategically used in future battles to exploit enemy weaknesses. Mega Man 2 is widely regarded as one of the best games in the series, and has been praised for its refined gameplay, memorable music, and increased difficulty level. Its interchanging of weapons system adds a strategic depth and replayability to the game that sets it apart. If you\'re a fan of challenging action platformers like Castlevania or Metroid, you\'ll be a fan of Mega Man 2.',
        submitted_by: '',
        answers: ["Mega Man 2"],
        affiliate_links: [],
        franchise: 'Mega Man',
        twitter_ids: ["1704858918600823112", "1704988988103115184", "1704980042084716729", "1704993320437448798"],
        release_year: '1988',
        metacritic_score: 'Too old',
        genre: 'Action, Platformer',
        console_platform: 'NES',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '497': {
        content: 'Tinykin is an adventurous puzzle-platformer game developed by Splashteam and published by Modern Wolf. Unleashed in 2022, it follows the intriguing story of a space explorer named Milo, who mysteriously winds up tiny and lost on Earth in a vastly different timeline- 1991. Here, he discovers creatures called Tinykin, who aid him in navigating through the strange surroundings in pursuit of his mission. Gameplay in Tinykin blends exploration, puzzle-solving, and platforming mechanics beautifully. Players explore a massive, transforming house utilizing Tinykin to manipulate the environment, solve tricky puzzles, and prevent harm from unsuspecting everyday dangers like dust bunnies and spiders. A fascinating element of the game is the ability to form useful tools and formations with Tinykin, including ladders and hammers. Milo\'s partnership with these colorful, changeable companions provides whimsical, innovative gameplay, and offers unique challenge with each level in a world teeming with surprise. Tinykin has been heralded for its engaging gameplay mechanics, charming 3D pixel-art visuals, and a delightful storyline. If you\'re a fan of ingenious puzzle platformers like Pikmin or Overcooked, you\'ll be a fan of Tinykin.',
        submitted_by: '',
        answers: ["Tinykin"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tinykin%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftinykin-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tinykin-pc-steam-key-global-i10000336791002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '78%',
        genre: 'Action, Platform, Puzzle elements',
        console_platform: 'PC, PS4, PS5, XONE, Switch, Xbox Series',
        developer: 'Splashteam',
        backloggd_link: '',
    },
    '498': {
        content: 'Tales of Arise is a role-playing game developed and published by Bandai Namco Entertainment. Released in 2021, this game continues the strong tradition of the iconic Tales series, allowing players to embark on an epic adventure on the twin planets of Dahna and Rena. Taking on the roles of Alphen, an amnesiac slave, and Shionne, a cursed noblewoman, players set out on a quest for freedom and to uncover the hidden truths of their world. Gameplay in Tales of Arise combines real-time combat, exploration, and puzzle-solving in immersive 3D environments. The dynamic \'Linear Motion Battle System\' allows for highly strategic and exciting battles, as players coordinate their party\'s abilities to overcome challenging foes. One of the key features of Tales of Arise is its leveling system, which enables players to grow their characters by equipping and mastering various ‘Artes’ or skills. Awash with vibrant visuals and complemented by a deeply involving narrative, Tales of Arise offers a gripping RPG experience. If you\'re a fan of epic role-playing games like Final Fantasy or Dragon Quest, you\'ll be a fan of Tales of Arise.',
        submitted_by: '',
        answers: ["Tales of Arise"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tales%20of%20Arise%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftales-of-arise-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tales-of-arise-ultimate-edition-pc-steam-gift-global-i10000250829016?gtag=391e4ef696"],
        franchise: 'Tales of',
        twitter_ids: ["1705817989566615657", "1705818757762097359"],
        release_year: '2021',
        metacritic_score: '87%',
        genre: 'Action RPG, Japanese-style RPG (JRPG)',
        console_platform: 'PC, PS4, PS5, XONE. Xbox Series',
        developer: 'BANDAI NAMCO Studios Inc.',
        backloggd_link: '',
    },
    '499': {
        content: 'Roblox is a massively multiplayer online and game creation platform developed and published by Roblox Corporation. When released in 2005/2006, Roblox unlocked the endless imaginations of players, as both the gaming environment where they are the in-game characters, and the developers themselves, creating and sharing their own 3D worlds. Players can design and build their own unique, interactive 3D games using the Roblox Studio, a powerful tool that is feature-rich with blending functionality and customization to model comprehensive environments and mechanics. This incredible game flexibility ranges from immersive simulations and role-plays to action-packed adventures and competitive sporting events. The gaming platform hosts thousands of user-generated games and experiences, with this colossal library pushing the boundaries of immersive game design and community collaboration. Players can also connect with an immense global community of gamers by collaborating on gameplay or by simply enjoying the creativity of others. Roblox has been praised for its unique model empowering players to create, its flourishing online community, and its wide-ranging access on various platforms including mobile and console. If you\'re a fan of creative sandbox games like Minecraft or platform gaming communities like LittleBigPlanet, you\'ll likely be a fan of Roblox.',
        submitted_by: '',
        answers: ["Roblox"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1705984269078933780", "1706069201075515429", "1706365851304399109", "1706250850883026999", "1705968801584697547"],
        release_year: '2005',
        metacritic_score: 'No score (& doesn\'t really make sense)',
        genre: 'Simulation, Open World, MMO',
        console_platform: 'PC',
        developer: 'Roblox Corporation',
        backloggd_link: '',
    },
    '500': {
        content: 'Golden Sun is a role-playing video game developed by Camelot Software Planning and published by Nintendo. Released in 2001, the game follows the journey of a group of magically-attuned \"adepts\" who are on a quest to prevent the destructive power of alchemy from being unleashed upon the world. In Golden Sun, players navigate through the game\'s fantasy-inspired world, battling enemies and solving puzzles using the character\'s special abilities, also known as psynergy. The game also incorporated an innovative \'Djinn\' system, where mystical creatures could be found and enlisted to boost the player\'s stats, unlocking powerful new abilities. These Djinn could be mixed and matched to suit the player\'s strategy, adding a layer of tactical depth to each battle. Its deep and intricate battle system, compelling story, and the vibrant world has made Golden Sun a favorite among RPG fans. The game garnered praise for its impressive graphics, well-designed puzzles and richly-detailed plot. If you\'re a fan of role-playing games like Dragon Quest or Final Fantasy, you\'ll be a fan of Golden Sun.',
        submitted_by: '',
        answers: ["Golden Sun"],
        affiliate_links: [],
        franchise: 'Golden Sun',
        twitter_ids: ["1706560541571887317", "1706555676669992979", "1706695032336433601", "1706455853044248898", "1706429997634695477"],
        release_year: '2001',
        metacritic_score: '91%',
        genre: 'RPG, Puzzle elements',
        console_platform: 'GBA',
        developer: 'Camelot Software Planning',
        backloggd_link: '',
    },
    '501': {
        content: 'Gotham Knights is an action RPG developed by WB Games Montréal and published by Warner Bros. Interactive Entertainment. Released in 2022, the game situates players in the sprawling urban shadows of Gotham City following the apparent death of Batman. Players step into the roles of Batmans four sidekicks - Batgirl, Nightwing, Red Hood, and Robin, each with their unique abilities. The gameplay is largely centered around combating various Gotham villains and exploring the city, either solo or through co-op multiplayer. Each character can progress and level up, possessing distinct skill sets and equipment that players can customize according to their playstyle. The game\'s open world, a darkly atmospheric version of Gotham, can be navigated using motorcycles or parkour-like movements. Interactions, mysteries, and the city\'s criminal activity dynamically change, depending on whether it\'s day or night. Gotham Knights has surprised players with its deep RPG elements, fantastic co-op realization, and the refreshing responsibility of keeping Batman\'s legacy alive without actually playing as Batman. Despite it\'s unique take, reception and critics have been poor with lots of criticism about it\'s story, combat and side activities. If you\'re a fan of action RPGs with open worlds like Spider-Man: Miles Morales or story-driven, superhero games like Marvel\'s Avengers, you\'ll be a fan of Gotham Knights.',
        submitted_by: '',
        answers: ["Gotham Knights"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Gotham%20Knights%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgotham-knights-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/gotham-knights-deluxe-edition-pc-steam-gift-global-i10000326126010?gtag=391e4ef696"],
        franchise: 'DC Comics',
        twitter_ids: ["1707132684835631159"],
        release_year: '2022',
        metacritic_score: '67%',
        genre: 'Action RPG, Open World',
        console_platform: 'PC, PS5, Xbox Series',
        developer: 'WB Games Montréal Inc.',
        backloggd_link: '',
    },
    '502': {
        content: 'Sonic Adventure is a 3D platformer developed by Sonic Team and published by Sega. Released in 1999, Sonic Adventure invites players into an exhilarating journey as they control one of six anthropomorphic characters, led by Sega\'s iconic hedgehog, Sonic. The game takes place in the massive, fully 3D world of Station Square and the Mystic Ruins, where Sonic and his friends must thwart the evil Dr. Robotnik\'s world-dominating plans. The game showcases the Sega Dreamcast\'s power with its fast-paced gameplay and groundbreaking visuals. Each character in Sonic Adventure has their own unique gameplay style and story arc, allowing players to experience the narrative from different perspectives. From Sonic\'s high-speed levels, Tails\' time-pressured races, Knuckles\' treasure hunts, or Amy\'s survival missions, the playstyle varies significantly, making it a distinct entry in the Sonic series. Sonic Adventure was hailed for its impressive graphics, varied gameplay styles, and the engaging storyline. If you\'re a fan of high-speed platformers like Crash Bandicoot, or sprawling adventure games like Super Mario Odyssey, you\'ll be a fan of Sonic Adventure.',
        submitted_by: 'https://twitter.com/CurtyTails',
        answers: ["Sonic Adventure", "Sonic Adventure Dx"],
        affiliate_links: [],
        franchise: 'Sonic The Hedgehog',
        twitter_ids: ["1707384024564404684", "1707378614923047279"],
        release_year: '1999',
        metacritic_score: 'Too old to have one',
        genre: 'Action',
        console_platform: 'Dreamcast',
        developer: 'Sonic Team',
        backloggd_link: '',
    },
    '503': {
        content: 'The Elder Scrolls III: Morrowind is an  open-world action role-playing game developed by Bethesda Game Studios and published by Bethesda Softworks. Released in 2002, it lets players step into the vast and diverse province of Morrowind, in the fantastical universe of Tamriel, as a recently freed prisoner prophesied to be the reincarnation of a legendary hero. Morrowind presents players with a vast, detailed world teeming with diverse cultures, ecologies, and intricate political dynamics to interact with. Its primary gameplay involves exploration, quests, and combat, with an emphasis on player freedom and choice. Morrowind’s rich and dense storytelling, coupled with its innovative character class system and spell creation mechanics, has made it a highly beloved addition to the Elder Scrolls series. With deep lore, intriguing quests, and a meticulously crafted environment, Morrowind provides a veritable playground of fantastical elements for players to enjoy and immerse themselves in. If you\'re a fan of large-scale fantasy RPGs like Baldur’s Gate or Dragon Age, you\'ll be a fan of The Elder Scrolls III: Morrowind.',
        submitted_by: '',
        answers: ["The Elder Scrolls III: Morrowind", "The Elder Scrolls 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Elder%20Scrolls%20III%20Morrowind%20Game%20of%20The%20Year%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-elder-scrolls-iii-morrowind-game-of-the-year-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-elder-scrolls-iii-morrowind-goty-edition-steam-gift-global-i10000069120002?gtag=391e4ef696"],
        franchise: 'The Elder Scrolls',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '89%',
        genre: 'RPG, Action RPG, Open World',
        console_platform: 'PC, Xbox',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '504': {
        content: 'Until Dawn is an interactive drama and survival horror video game developed by Supermassive Games and published by Sony Computer Entertainment. Released in 2015, Until Dawn plunges players into a tense horror adventure set in a remote mountain lodge where a group of friends have gathered on the anniversary of their friends\' disappearance. The primary gameplay mechanic in Until Dawn is making choices through the \"Butterfly Effect\" system. As players navigate the terrifying night, every decision, no matter how seemingly insignificant, holds weight, influencing the story and outcomes in unexpected ways. In tandem with its complex decision-making, Until Dawn also presents quick-time events adding another layer of variability and interactivity to the narrative. The game\'s suspense-filled story, unique gameplay mechanics, and impressive character performances offer a thrilling and immersive horror experience. Its stunning graphics add a heightened realism to the creepiness, making it even more intense. The survival of the characters lies in the hands of the player\'s choices, making for a deeply personal and suspenseful journey. If you\'re a fan of interactive drama and horror games like Heavy Rain or The Dark Pictures Anthology, you\'ll be a fan of Until Dawn.',
        submitted_by: '',
        answers: ["Until Dawn"],
        affiliate_links: ["https://www.g2a.com/until-dawn-ps4-psn-account-global-i10000005308003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '79%',
        genre: 'Adventure, Quick Time Events (QTEs), Survival horror',
        console_platform: 'PS4',
        developer: 'Supermassive Games Ltd.',
        backloggd_link: '',
    },
    '505': {
        content: 'Jazz Jackrabbit is a fast-paced platformer developed by Epic Megagames, now known as Epic Games. Released in 1994, it thrusts players into a colorful and zany universe, letting them take on the role of Jazz Jackrabbit, a green gun-toting hare on a mission to rescue his love interest, Princess Eva Earlong from his nemesis, a nefarious tortoise. Blessed with blazing speed and armed with a rapid-fire blaster, players navigate through a series of vibrant levels filled with enemies, interactive items, and hidden bonuses. The playstyle in Jazz Jackrabbit is characterized by its quick-paced action, blended with platform-based gameplay and exploration elements, echoing the style of popular fast-paced platformers of the era. Its memorable cartoon art style, energetic music, and quirky humor set it apart in the platformer genre. The game garnered praise for its stunning Visuals, catchy soundtrack and fast-paced gameplay, leaving a lasting impact on the platformer genre. If you\'re a fan of fast-paced platformer games like Sonic the Hedgehog or Mega Man, you\'ll undoubtedly be a fan of Jazz Jackrabbit.',
        submitted_by: 'https://www.youtube.com/@-Dark-Serg-',
        answers: ["Jazz Jackrabbit"],
        affiliate_links: ["https://www.g2a.com/jazz-jackrabbit-collection-gogcom-key-global-i10000152382001?sid=12683664?gtag=391e4ef696"],
        franchise: 'Jazz Jackrabbit',
        twitter_ids: [],
        release_year: '1994',
        metacritic_score: 'Too Old',
        genre: 'Action, Platform, Shooter',
        console_platform: 'DOS',
        developer: 'Epic MegaGames, Inc.',
        backloggd_link: '',
    },
    '506': {
        content: '',
        submitted_by: '',
        answers: ["Tears of The Kingdom", "The Legend of Zelda: Tears of the Kingdom"],
        affiliate_links: ["https://www.g2a.com/the-legend-of-zelda-tears-of-the-kingdom-nintendo-switch-nintendo-eshop-key-global-i10000338965002?gtag=391e4ef696"],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '96%',
        genre: 'Action, Puzzle elements, RPG elements, Open World',
        console_platform: 'Switch',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '507': {
        content: 'Bloons TD 6 is a tower defense game developed and published by Ninja Kiwi. Released in 2018, this addictive tower defense experience plunges players into a whimsical world where they must strategically place towers to stop swarms of colorful balloons, known as bloons, from reaching their base. As the sixth installment in the Bloons TD series, Bloons TD 6 boasts improved visuals, newer and more varied types of towers and upgrades, and a plethora of challenging maps. Gameplay heavily involves strategy, as players must decide where to place their towers for maximum effectiveness, when to upgrade, and how to use their limited resources. Unique to Bloons TD 6 are heroes - special monkey units with unique abilities - and several other game modes to shake up the traditional gameplay. Bloons TD 6 has been highly praised for its strategic depth, colorful presentation, and the fun, engaging nature of its gameplay. If you\'re a fan of tower defense games like Kingdom Rush and Plants vs. Zombies, you\'ll be a fan of Bloons TD 6.',
        submitted_by: 'https://www.youtube.com/@dreadnaughtdanger',
        answers: ["Bloons TD 6", "Bloons TD"],
        affiliate_links: ["https://www.g2a.com/bloons-td-6-steam-key-global-i10000178751003?sid=12853132?gtag=391e4ef696"],
        franchise: 'Bloons TD',
        twitter_ids: ["1709085822912287078", "1709085520364527734", "1708965990472527887", "1708947161013166511", "1709017523352490316"],
        release_year: '2018',
        metacritic_score: 'No metacritic score',
        genre: 'Strategy / tactics, Tower Defense',
        console_platform: 'Android, iPhone, iPad, PC',
        developer: 'Ninja Kiwi',
        backloggd_link: '',
    },
    '508': {
        content: 'Mario and Luigi: Partners in Time is a unique blend of action role-playing and platforming game developed by AlphaDream and published by Nintendo. Released in 2005, this engaging sequel in the Mario & Luigi series takes players back in time, where the iconic brothers team up with their younger selves to save Princess Peach from an alien invasion. The game\'s exciting time-traveling plot unfolds across both past and present Mushroom Kingdom, offering a stylistically diverse landscape, filled with familiar and fresh environments. The gameplay in Mario & Luigi: Partners in Time is defined by its innovative combination of real-time and turn-based combat, skillful platforming, and engaging puzzle-solving featuring the use of different abilities. A key feature of the game is the dual-screened play on the Nintendo DS, enhancing the depth of exploration and puzzle-solving. It has been lauded for its fun gameplay mechanics, humor-filled storyline, and greatly improved visuals. If you\'re a fan of action RPGs like Paper Mario or any of the original Mario & Luigi games, you\'ll most certainly be a fan of Mario and Luigi: Partners in Time.',
        submitted_by: 'https://www.instagram.com/anthonyjgo13/',
        answers: ["Mario and Luigi Partners in Time", "Mario & Luigi: Partners in Time"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: ["1709328104961782237", "1709696436995080554", "1709576312023650477", "1709431080321728948", "1709596787273617917"],
        release_year: '2005',
        metacritic_score: '86%',
        genre: 'Action, RPG, Puzzle elements',
        console_platform: 'DS',
        developer: 'AlphaDream Corporation, Ltd.',
        backloggd_link: '',
    },
    '509': {
        content: 'Thief: The Dark Project is a first-person stealth game developed by Looking Glass Studios and published by Eidos Interactive. Released in 1998, this classic game introduces players to the murky underworld of a steampunk metropolis, as they step into the shadowy shoes of Garrett, a master thief with a knack for stealth and thievery. Thief: The Dark Project garnered critical acclaim for its focus on stealth gameplay mechanics over direct combat. It set itself apart with its darkness and sound-based stealth system, encouraging players to use shadows and sounds to avoid enemies and progress through levels. The robust artificial intelligence, which reacts to the player\'s tactics, further elevates the gameplay experience. It also excelled in its narrative, delivering an engaging story with complex characters and a deeply immersive world. The use of light and dark as core mechanics, coupled with a powerful narrative and distinct atmospheric music, made Thief: The Dark Project a pioneering title in the stealth games genre. If you\'re a fan of stealth games like Dishonored or Hitman, you\'ll be a fan of Thief: The Dark Project.',
        submitted_by: '',
        answers: ["Thief: The Dark Project", "Thief"],
        affiliate_links: [],
        franchise: 'Thief',
        twitter_ids: ["1709785639690895366", "1709985487090225526"],
        release_year: '1998',
        metacritic_score: '92%',
        genre: 'Action, Stealth',
        console_platform: 'PC',
        developer: 'Looking Glass Studios, Inc.',
        backloggd_link: '',
    },
    '510': {
        content: 'Mortal Kombat 1, released in 2023, is a fighting game developed by NetherRealm Studios and published by Warner Bros. Interactive Entertainment. The game transports players into a fictional universe, featuring a plethora of fighters with unique abilities vying for supremacy in a high-stakes tournament. Players step into the shoes of these ruthless fighters, mastering their different fighting styles and specials to defeat opponents in thrilling, head-to-head battles. Mortal Kombat 1 is renowned for its combination of strategic fighting mechanics and over-the-top violence. It introduces players to the iconic Fatality system, where they can perform graphic finishing moves for the ultimate display of dominance. Apart from the single-player story mode, players can also challenge others around the globe in competitive online multiplayer modes, adding an additional layer of challenge. Mortal Kombat 1 has been praised for its deep, balanced fighting mechanics, fluid animations, and compelling roster of fighters. If you\'re a fan of fierce fighting games like Street Fighter or Tekken, you\'ll be a fan of Mortal Kombat 1.',
        submitted_by: '',
        answers: ["Mortal Kombat 1"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Mortal%20Kombat%201%20Premium%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmortal-kombat-1-premium-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/mortal-kombat-1-premium-edition-pc-steam-key-global-i10000339458006?sid=13522986?gtag=391e4ef696"],
        franchise: 'Mortal Kombat',
        twitter_ids: ["1710339832487031054"],
        release_year: '2023',
        metacritic_score: '86%',
        genre: 'Action, Fighting',
        console_platform: 'PC, Switch, PS5, Xbox Series',
        developer: 'NetherRealm Studios',
        backloggd_link: '',
    },
    '511': {
        content: 'EverQuest is a massively multiplayer online role-playing game (MMORPG) developed by Verant Interactive and 989 Studios, and published by Sony Online Entertainment. Released in 1999, it immerses players in the expansive, fantasy world of Norrath, teeming with mystical creatures, vast dungeons, towering cities, and diverse biomes. In EverQuest, players create a character from a broad array of races and classes, and then embark on epic quests, engage in combat, craft items, and socialize with other players, making the game as much about community interaction as it is about individual progress. The gameplay consists of co-operative monster battling, exploration, and quest completion across Norrath\'s sprawling world. What separates EverQuest from other games is its revolutionary focus on group-oriented advancement and complex, community-focused gameplay, which has inspired countless MMORPGs since its release. The game has been applauded for its immersive world, intricate gameplay mechanics, and meaningful player interactions. If you\'re a fan of MMORPGs like World of Warcraft or RuneScape, you\'ll be a fan of EverQuest.',
        submitted_by: '',
        answers: ["EverQuest"],
        affiliate_links: ["https://www.g2a.com/evilquest-steam-key-global-i10000044648002?gtag=391e4ef696"],
        franchise: 'EverQuest',
        twitter_ids: ["1710529314570010781", "1710364027824836661"],
        release_year: '1999',
        metacritic_score: '85%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Verant Interactive, 989 Studios',
        backloggd_link: '',
    },
    '512': {
        content: 'Super Monkey Ball 2 is an action-packed platformer developed by Amusement Vision and published by SEGA. Released in 2002, this highly addictive game invites players to take on the roles of different monkeys as they navigate complex mazes inside transparent balls. The story features AiAi and his friends trying to outwit their unceasing foe, Dr. Bad-Boon, amidst 100 levels of intricate labyrinths with diverse themes and challenging terrains. Game play in Super Monkey Ball 2 heavily relies on physics and the player\'s ability to meticulously tilt the environment, as opposed to directly controlling the characters, creating a unique platforming experience. The game also features innovative multiplayer modes that add to its replay value. Notable for its elaborate course design, engaging play mechanics, and its humorous, charming characters, Super Monkey Ball 2 was heralded as a triumphant sequel that bolstered the series\' prominence. If you\'re a fan of challenging physics-based games such as Marble Madness or Humpty Dumpty\'s world, you\'ll appreciate the dynamic, thrilling world of Super Monkey Ball 2.',
        submitted_by: '',
        answers: ["Super Monkey Ball 2"],
        affiliate_links: [],
        franchise: 'Super Monkey Ball',
        twitter_ids: ["1711094526558036034", "1710921678547554326"],
        release_year: '2002',
        metacritic_score: '87%',
        genre: 'Arcade, Mini-games, Party game, Platforming',
        console_platform: 'GameCube',
        developer: 'Amusement Vision, Ltd.',
        backloggd_link: '',
    },
    '513': {
        content: 'Assassin\'s Creed Valhalla is an action-adventure game developed by Ubisoft Montreal and published by Ubisoft. Released in 2020, the game lets players journey through the harsh landscapes of the Viking era as Eivor, a Viking raider leading their clan from the icy desolation of Norway to the lush farmlands of ninth-century England. In Assassin\'s Creed Valhalla, players engage in epic battles, employ strategic tactics, and level-up their character while forming alliances to secure their place among the English kingdoms. The gameplay not only highlights combat but also emphasizes exploration and player decision-making. Unlike the previous games in the series, Valhalla introduces features such as raids, settlement-building, and multiple dialogue choices to influence the storyline. It offers a rich narrative inspired by Norse mythology, along with a massive, beautifully crafted open-world environment filled with stunning vistas and intricate detail. Assassin\'s Creed Valhalla has been applauded for its immersive world, engaging story, and the balance between its action, exploration, and role-playing elements. If you\'re a fan of action-adventure games with strong historic elements, like Red Dead Redemption 2 or The Witcher 3, you\'ll truly enjoy Assassin\'s Creed Valhalla.',
        submitted_by: '',
        answers: ["Assassin's Creed Valhalla"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20Valhalla%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-valhalla-complete-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-valhalla-complete-edition-pc-steam-account-account-global-i10000195319087?gtag=391e4ef696"],
        franchise: 'Assassins Creed',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '80%',
        genre: 'Action RPG, Open World, Stealth',
        console_platform: 'Stadia, PC, PS4, XONE, Xbox Series, PS5',
        developer: 'Ubisoft Entertainment Inc.',
        backloggd_link: '',
    },
    '514': {
        content: 'Atomic Heart is an action role-playing game developed by Russian studio Mundfish. It was released in 2023 and invites players to experience a bizarre and intense universe, set in an alternate history Soviet Union where advanced technology and fantasy elements coexist. The game introduces players to the role of a special agent employed by the Soviet government, tasked with investigating a high-tech facility where things have gone awry. The gameplay of Atomic Heart mixes elements of first-person action and RPG mechanics. Players will explore twisted landscapes, defeat creatures and robots using a combination of firearms and melee weaponry, all while untangling the surreal narrative of the game. The combat system encourages strategic and innovative gameplay, allowing objects from the environment to be used as makeshift weapons. Known for its creatively grim environment and atmospheric storytelling, Atomic Heart provides a unique combination of science fiction, horror, and action elements all wrapped in one package. The game has been praised for its rich world-building, innovative gameplay mechanics, and the gripping horror elements of its narrative. If you\'re a fan of the strategic action of Bioshock or the dystopian environments of the Metro series, you\'ll surely be a fan of Atomic Heart.',
        submitted_by: '',
        answers: ["Atomic Heart"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Atomic%20Heart%20Premium%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fatomic-heart-premium-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/atomic-heart-premium-edition-pc-steam-key-global-i10000250244014?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1711604471153971590"],
        release_year: '2023',
        metacritic_score: '70%',
        genre: 'Action Shooter, RPG elements',
        console_platform: 'XONE, Xbox Series, PC, PS4, PS5',
        developer: 'Mundfish LLC',
        backloggd_link: '',
    },
    '515': {
        content: 'Orcs Must Die! is an action-tower defense game developed by Robot Entertainment, and was released in 2011. In this exhilarating title, players become The War Mage, tasked with defending fortress strongholds from conquering orcs and other fantastical creatures. Based on skillful strategy and bloody combat, Orcs Must Die! demands agile thinking and quick reflexes. The versatile gameplay combines elements of trap-laden tower defense with action-based combat, wherein players strategically place traps and fight enemies directly. The levels progressively become more challenging, pushing players to concoct new strategies to halt the onslaught of enemies. In addition to the trap and combat mechanics, the game introduces a scoring system, encouraging players to replay levels for achieving higher scores. This system also awards skulls, based on your performance, which can be used to upgrade or purchase new traps, adding an RPG-like progression to the game. Orcs Must Die! has garnered praise for its satisfying blend of strategy, action, and darkly comedic tone. If you\'re a fan of action-strategy tower defense games like Dungeon Defenders or Sanctum, you\'ll undoubtedly be a fan of Orcs Must Die!.',
        submitted_by: '',
        answers: ["Orcs Must Die!", "Orcs Must Die"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Orcs%20Must%20Die%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Forcs-must-die-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/orcs-must-die-complete-pack-steam-gift-global-i10000183055002?gtag=391e4ef696"],
        franchise: 'Orcs Must Die',
        twitter_ids: ["1712001470348845432"],
        release_year: '2011',
        metacritic_score: '79%',
        genre: 'Action, Strategy, Tower defense',
        console_platform: 'X360, PC',
        developer: 'Robot Entertainment, Inc.',
        backloggd_link: '',
    },
    '516': {
        content: 'Age of Mythology is a real-time strategy game developed by Ensemble Studios and published by Microsoft Game Studios. Released in 2002, it ventures into the realm of mythology, taking inspiration from the legends of ancient Greece, Egypt, and the Norse civilizations. As the player, you take on the role of an ancient civilization\'s leader, guiding your people through the ages, all while drawing upon the favor of the gods and mythical creatures to advantage your cause. The gameplay blends traditional real-time strategy elements like resource gathering, tech upgrades, and tactical combat, with a unique divine favor system that allows players to summon mythological creatures or invoke god powers. Age of Mythology\'s rich, mythic background provides a unique spin to its strategy framework and offers a wide array of units, buildings, and powers that add depth and versatility. Having set a new standard for strategy games with its combination of mythology and real-time strategy, Age of Mythology has been praised for its immersive world-building based on ancient lore, its innovative gameplay, and its diverse range of strategies. If you\'re a fan of epic real-time strategy games like Age of Empires or Rise of Nations, you\'ll be a fan of Age of Mythology.',
        submitted_by: '',
        answers: ["Age of Mythology"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Age%20of%20Mythology%20Extended%20Edition%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fage-of-mythology-extended-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/age-of-mythology-extended-edition-steam-gift-global-i10000001646005?gtag=391e4ef696"],
        franchise: 'Age of Empires',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '89%',
        genre: 'Real Time Strategy',
        console_platform: 'PC',
        developer: 'Ensemble Studios Corporation',
        backloggd_link: '',
    },
    '517': {
        content: 'Tetris is a tile-matching puzzle video game originally designed and programmed by Soviet-Russian software engineer Alexey Pajitnov. Released in 1984, it\'s one of the best-selling video games with over 495 million copies sold. The game captivates players with a simple yet addictive gameplay: random sequences of tetrominoes, geometric shapes composed of four square blocks, drop down a rectangular playing field. The objective is to manipulate the falling blocks to create fully-filled, unbroken horizontal lines, which then disappear, freeing up more space for the falling blocks. Careful planning and swift reactions are key in this game, as the speed of the falling tetrominoes increases over time, adding to the challenge. Iconic for its unpredictability, endless playability and the satisfying completeness when arranging the tetrominoes perfectly, Tetris is seen as the granddaddy of puzzle video games. It has been recognized widely for its influence and is a staple in popular culture. The game has been praised for its simplicity, replay value, and deep strategy. If you\'re a fan of puzzle games like Bejeweled or Candy Crush Saga, you\'ll be a fan of Tetris.    If your interested in learning more about the history of tetris, highly recommend: https://www.filfre.net/2017/06/tales-of-the-mirror-world-part-1-calculators-and-cybernetics/',
        submitted_by: '',
        answers: ["Tetris"],
        affiliate_links: [],
        franchise: 'Tetris',
        twitter_ids: ["1712814231253586430", "1712713674480132431", "1712592560089092412", "1712483535758782778", "1712633085614043595"],
        release_year: '1985',
        metacritic_score: 'Too Old',
        genre: 'Puzzle',
        console_platform: 'Electronika 60',
        developer: 'Alexey Pajitnov',
        backloggd_link: '',
    },
    '518': {
        content: 'Surgeon Simulator 2 is a humorous surgery simulation game which was developed and published by Bossa Studios. Launched in 2020, the game offers a unique and wacky take on performing surgery where players operate on patients with a wide array of unconventional surgical tools. The game is set in a chaotic 3D environment, where players, acting as surgeons, perform complex medical procedures which often result in humorous and absurd outcomes due to the purposely difficult controls. A notable aspect of Surgeon Simulator 2 is its cooperative multiplayer mode, allowing up to four players to collaborate on surgical procedures, which amps up the game’s humor and unpredictability. In addition to the surgery gameplay, Surgeon Simulator 2 also includes a Story Mode and a sandbox mode. The game has been recognized for its farcical humor, the challenging controls that create unpredictable results, and the fun cooperative multiplayer mode. If you\'re a fan of humorous and unconventional simulation games like Goat Simulator or Octodad: Dadliest Catch, you\'ll enjoy Surgeon Simulator 2.',
        submitted_by: '',
        answers: ["Surgeon Simulator 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Surgeon%20Simulator%202%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsurgeon-simulator-2-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/surgeon-simulator-2-pc-steam-key-global-i10000218132004?gtag=391e4ef696"],
        franchise: 'Surgeon Simulator',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '70%',
        genre: 'Simulation',
        console_platform: 'XONE, PC, Xbox Series',
        developer: 'Bossa Studios Ltd.',
        backloggd_link: '',
    },
    '519': {
        content: 'Civilization II is a turn-based strategy game developed by MicroProse and released in 1996. Players are thrust into the role of the leader of a fledgling civilization, with the challenge to guide their nation through millennia of development - from the inventing of writing, to launching space missions and beyond. Gameplay fundamentally revolves around managing and growing your civilization, building cities, researching technologies, dealing with other civilizations through war or diplomacy, and exploring the world map, ultimately aiming to become the most dominant civilization. Additionally, Civilization II introduces an intricate diplomatic system that allows players to interact with AI leaders to forge treaties, make demands, or declare war. Its isometric view, expanded tech tree, and the addition of new units and wonders of the world, were remarkable improvements from the original game. Civilization II’s engaging strategic depth, replayability, and innovative blend of war, politics, technology, and society simulation have made it a favorite among strategy gamers. If you\'re a fan of strategic simulation game with expansive scope such as Age of Empires or Europa Universalis, you\'ll be a fan of Civilization II.',
        submitted_by: '',
        answers: ["Civilization II", "Civilization 2", "Sid Meier's Civilization II"],
        affiliate_links: ["https://www.g2a.com/surgeon-simulator-2-pc-steam-key-global-i10000218132004?gtag=391e4ef696"],
        franchise: 'Civilization',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: '94%',
        genre: 'Turn-based strategy (TBS)',
        console_platform: 'PC 3.x',
        developer: 'Microprose',
        backloggd_link: '',
    },
    '520': {
        content: '7 Days to Die is an open-world survival horror game, developed and published by The Fun Pimps. Released in 2013, it immerses players into a post-apocalyptic world overrun by the undead. The gripping plot is set in the aftermath of a third world war that obliterated much of the globe, and players are challenged to survive as long as they can against an onslaught of the living dead. The gameplay in 7 Days to Die stands out due to its blend of first-person shooter, tower defense, and survival horror mechanics. Players can craft weapons, build fortifications, mine for resources, and explore the vast, zombie-infested world. It offers a unique degradation system, where objects and structures in the game world can be damaged, deteriorated, or destroyed, adding another layer of realism and challenge. The dynamic, persistent world also means that time passes, and with each passing day, the zombies get tougher, making your survival strategy all the more critical. 7 Days to Die has been praised for its depth of interactivity, innovative mechanics, and intense survival gameplay. If you\'re a fan of survival horror games like Resident Evil, DayZ or crafting-focused titles like Minecraft, you\'re bound to get hooked on 7 Days to Die.',
        submitted_by: '',
        answers: ["7 Days to Die"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=7%20Days%20to%20Die%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2F7-days-to-die%2F&intsrc=PUI2_9608", "https://www.g2a.com/7-days-to-die-steam-key-global-i10000004637011?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '45%',
        genre: 'RPG elements, Open World, Shooter, Survival horror',
        console_platform: 'PC, Macintosh',
        developer: 'The Fun Pimps Entertainment LLC',
        backloggd_link: '',
    },
    '521': {
        content: 'Heavy Rain is an interactive drama and action-adventure game developed by Quantic Dream and published by Sony Computer Entertainment. Released in 2010, Heavy Rain allows players to navigate the gripping story of four diverse characters as they try to solve the mystery of the Origami Killer, a serial killer who uses extended periods of rainfall to drown his victims. Players\' decisions and actions during the game directly impact the plot, leading to various endings. Gameplay is based on quick-time events and dialogue choices, lending the game a cinematic feel. Action sequences often require swift reflexes and precise execution of the game\'s contextual controls, while investigative segments use the game\'s proprietary \'thought\' system enabling players to access characters\' internal monologues. Heavy Rain\'s meticulous attention to detail, deep narrative, and immersive control scheme set it apart from traditional action-adventure games and have earned it critical acclaim. If you\'re a fan of interactive drama games like Life Is Strange or Detroit: Become Human, you\'ll be a fan of Heavy Rain.',
        submitted_by: '',
        answers: ["Heavy Rain"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Heavy%20Rain%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fheavy-rain-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/heavy-rain-pc-steam-key-global-i10000192330007?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1714323720066527249", "1714159982189420757"],
        release_year: '2010',
        metacritic_score: '87%',
        genre: 'Adventure, Quick Time Events (QTEs)',
        console_platform: 'PS3',
        developer: 'Quantic Dream SA',
        backloggd_link: '',
    },
    '522': {
        content: 'Fable II is an action role-playing open-world video game developed by Lionhead Studios and published by Microsoft Game Studios. Launched in 2008, the game immerses players in the expansive, vibrant world of Albion as they embark on a personal journey, spearheaded by their customized hero or heroine. The story, set 500 years after the original Fable, intertwines freedom, action and relationship-building as players develop their character\'s skills and align him or her as good, evil, or somewhere in between. Fable II\'s gameplay is a unique blend of combat, exploration, and role-playing, offering players a variety of quests, side missions, and interactive features, like owning properties, getting married, and raising a family. One innovative element is the introduction of a canine companion that aids in combat, treasure hunting, and interacting with the world. Fable II is beloved for its immersive world, compelling narrative, customizable gameplay, and its emotional impact shaped by player choices. If you\'re a fan of open-world RPGs with unique narrative choices like The Elder Scrolls series or The Witcher 3: Wild Hunt, you\'ll be a fan of Fable II.',
        submitted_by: '',
        answers: ["Fable II", "Fable 2"],
        affiliate_links: ["https://www.g2a.com/fable-ii-xbox-live-key-global-i10000145837001?gtag=391e4ef696"],
        franchise: 'Fable',
        twitter_ids: ["1714423000336920744", "1714667335129104639", "1714550256300659143", "1714502523761930270"],
        release_year: '2008',
        metacritic_score: '89%',
        genre: 'Action, RPG',
        console_platform: 'X360',
        developer: 'Lionhead Studios Ltd.',
        backloggd_link: '',
    },
    '523': {
        content: 'Q*bert is a classic arcade game developed and published by Gottlieb, releasing back in 1982. Fondly remembered for its distinctive isometric graphics, players maneuver the titular character, Q*bert, around a pyramid of colourful cubes. The objective in each level is to change the colours of every cube by hopping on them, all while avoiding various enemies that move in predictable patterns. The game\'s unique premise and controls represented a departure from contemporary action titles and popularized an innovative way to design platform game levels. Q*bert is known for its quirky protagonist, a bright orange, armless creature with a large snout, who communicates in gibberish. With every hop, dodge, and near-miss, the game brings a unique blend of strategy and action that has captivated players for decades. Its simple yet addicting gameplay, along with an ensemble of colourful characters and charming visual style, earned Q*bert a place among the classic arcade greats. If you\'re a fan of classic arcade games like Frogger or Donkey Kong, you\'ll be a fan of Q*bert.',
        submitted_by: '',
        answers: ["Q*bert"],
        affiliate_links: ["https://www.g2a.com/qbert-rebooted-the-xbox-one-edition-xbox-live-key-united-states-i10000179365001?gtag=391e4ef696"],
        franchise: 'Q*Bert',
        twitter_ids: ["1714981398996496418", "1715011514061427026", "1715005496967459069"],
        release_year: '1982',
        metacritic_score: 'Not rated',
        genre: 'Action, Puzzle, Platform',
        console_platform: 'Odyssey 2, Arcade',
        developer: 'D. Gottlieb & Co., Western Technologies Inc.',
        backloggd_link: '',
    },
    '524': {
        content: 'Tony Hawk\'s Pro Skater 2 is a skateboarding video game developed by Neversoft and published by Activision. Launched in 2000, it puts players in the skate shoes of legendary skateboarder Tony Hawk, among other professional skaters. In the game, players navigate through various environments, executing gravity-defying tricks on skateboards and completing specific objectives. The gameplay in Tony Hawk\'s Pro Skater 2 is an incredible blend of arcade-style action, timed challenges, and exploration. Players can gather speed, perform elaborate skateboarding tricks and combos, and explore levels to find secret areas and collectibles. The game introduced the \'manual\' trick, adding complexity and nuance to the combo system. Its locations range from famous skate parks to imaginative spaces like a giant bullring or an airport. Tony Hawk\'s Pro Skater 2 has earned recognition for its tight controls, impressive level design, and the addictive nature of its score-attack gameplay. It\'s also known for its eclectic soundtrack, which encompasses punk, rock, and hip-hop. If you\'re a fan of extreme sports games like SSX or sports-oriented action games like the NBA Street series, you\'ll be a fan of Tony Hawk\'s Pro Skater 2.',
        submitted_by: '',
        answers: ["Tony Hawk's Pro Skater 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tony%20Hawks%20Pro%20Skater%201%202%20Cross%20Gen%20Deluxe%20Bundle%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftony-hawks-pro-skater-1-2-cross-gen-deluxe-bundle-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/tony-hawks-pro-skater-1-2-digital-deluxe-edition-xbox-one-xbox-live-key-global-i10000195540002?gtag=391e4ef696"],
        franchise: 'Tony Hawk',
        twitter_ids: ["1715528119640019308", "1715372536270114906", "1715232301003473251", "1715353407769211212"],
        release_year: '2000',
        metacritic_score: '91%',
        genre: 'Sports',
        console_platform: 'PS, PC, Dreamcast',
        developer: 'Neversoft Entertainment, Inc.',
        backloggd_link: '',
    },
    '525': {
        content: 'Guild Wars is a massively multiplayer online role-playing game developed by ArenaNet and published by NCSOFT. Launched in 2005, the game submerges players into the fantasy world of Tyria, a land teeming with magic, conflict, and adventure. Players take on the role of a hero who must combat the sinister forces of the undead Elder Dragon, Zhaitan. In Guild Wars, gameplay involves engaging in real-time combat, exploring an expansive persistent world, and interacting with other players and NPC characters. The narrative unfolds through a series of storyline quests and missions, which offer cooperative, player vs. player, and player vs. environment modes. Guild Wars has been recognized for its rich lore, strategic combat system, and its beautiful, diverse environments. If you\'re a fan of epic MMORPGs like World of Warcraft or Final Fantasy XIV that mix immersive storytelling with strategic battles, you\'ll be a fan of Guild Wars.',
        submitted_by: '',
        answers: ["Guild Wars"],
        affiliate_links: ["https://www.g2a.com/guild-wars-the-complete-collection-pc-ncsoft-key-global-i10000017737002?gtag=391e4ef696"],
        franchise: 'Guild Wars',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '89%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'ArenaNet, LLC',
        backloggd_link: '',
    },
    '526': {
        content: 'Armored Core VI: Fires of Rubicon is a mech action game developed by FromSoftware and published by Bandai Namco Entertainment. Released in 2023, it immerses players in the ardor of mech warfare, piloting their customized bipedal tanks, also known as Armored Cores, through complex post-apocalyptic landscapes. In this installment, players experience a gripping tale of conflict, resource scarcity, and technology as they navigate through the ravaged world of Rubicon, a planet embroiled in the flames of perpetual war. Gameplay in Armored Core VI is a blend of strategic mech customization and high-stakes action. Players can craft their Armored Core with a multitude of parts and weapons, allowing for a vast array of tactical possibilities. The game introduces unraveled terrains and brutal enemies with enhanced AI, keeping even longtime fans on their toes. One of the unique aspects of the game is its emphasis on player strategy and adaptability, providing a true test of skill and planning. Armored Core VI: Fires of Rubicon has been heralded for its deep customization, exhilarating combat, and engaging storyline, making every mission a fresh, unique challenge. If you\'re a fan of strategic action games like the MechWarrior series or Titanfall, you\'ll be a fan of Armored Core VI: Fires of Rubicon.',
        submitted_by: '',
        answers: ["Armored Core VI: Fires of Rubicon", "Armored Core VI", "Armored Core 6"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=ARMORED%20CORE%20VI%20FIRES%20OF%20RUBICON%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Farmored-core-vi-fires-of-rubicon-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/armored-core-vi-fires-of-rubicon-deluxe-edition-pc-steam-gift-global-i10000337614007?gtag=391e4ef696"],
        franchise: 'Armored Core',
        twitter_ids: ["1715774899837645180", "1715852529882247580", "1715993056887947599", "1715881577602031766"],
        release_year: '2023',
        metacritic_score: '88%',
        genre: 'Action',
        console_platform: 'PC, PS4, XONE, PS5, Xbox Series',
        developer: 'FromSoftware, Inc.',
        backloggd_link: '',
    },
    '527': {
        content: 'TimeSplitters 2 is a first-person shooter game developed by Free Radical Design and published by Eidos Interactive. Released in 2002, it takes players on a journey through different timelines as they control a series of characters, each trying to recover pieces of the Time Crystal to prevent the eponymous TimeSplitters from changing history. The game blends fast-paced shooting action with a time-traveling narrative that shifts between prehistoric eras, the Wild West, dystopian futures, and more. From combating zombies in a haunted mansion to battling robots in the distant future, each levels distinctive setting offers unique gameplay elements and challenges. Beyond the main campaign, TimeSplitters 2 also features an expansive multiplayer mode, brimming with customizable settings, a variety of game types, and an assortment of whimsical characters. It\'s been celebrated for its quirky humor, diverse level designs, and dynamic multiplayer experience. The game stands out for its unique take on the first-person shooter genre and its ability to balance frenzied action with the immersive storyline. If you\'re a fan of fast-paced shooters like Quake or Unreal Tournament, or enjoy the time-traveling elements in games like Chrono Trigger, you\'ll be a fan of TimeSplitters 2.',
        submitted_by: '',
        answers: ["TimeSplitters 2"],
        affiliate_links: ["https://www.g2a.com/timesplitters-2-xbox-one-xbox-live-key-united-states-i10000337824002?gtag=391e4ef696"],
        franchise: 'TimeSplitters',
        twitter_ids: ["1716414987206357373", "1716515186150916103", "1716329288746594322"],
        release_year: '2002',
        metacritic_score: '90%',
        genre: 'Shooter',
        console_platform: 'PS2, Xbox, GameCube',
        developer: 'Free Radical Design Ltd.',
        backloggd_link: '',
    },
    '528': {
        content: 'The Wolf Among Us is an episodic graphic adventure game developed by Telltale Games and released in 2013. This game draws players into the dark underbelly of a world where fairy tale characters live amongst us disguised as normal humans. Players embody Bigby Wolf, known as the Big Bad Wolf in his past life, who is the sheriff of this hidden community in New York City. Presented in a noir-style atmosphere, the game challenges players to solve a mysterious murder while maintaining the fragile peace in Fabletown. The gameplay primarily consists of exploration, dialogue choices, and quick-time events. Player decisions affect Bigby\'s character development and his relationships with other characters, which can significantly impact the unfolding storyline. This branching narrative style adds replayability, as each player encounters a unique gaming experience based on their choices. The Wolf Among Us has been acclaimed for its engaging narrative, compelling characters, and the effective incorporation of choice-based gameplay. If you\'re a fan of immersive narrative games like Life is Strange or Telltale\'s The Walking Dead, you\'ll be a fan of The Wolf Among Us.',
        submitted_by: '',
        answers: ["The Wolf Among Us"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Wolf%20Among%20Us%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-wolf-among-us%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-wolf-among-us-steam-key-global-i10000001888004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1716575907895263653"],
        release_year: '2013',
        metacritic_score: '80%',
        genre: 'Adventure, Narrative',
        console_platform: 'PC, Mac, PS3, PS4, X360, XONE, PS Vita',
        developer: 'Telltale Games',
        backloggd_link: '',
    },
    '529': {
        content: 'Eye of the Beholder is a classic role-playing game developed by Westwood Associates and published by Strategic Simulations Inc. Released in 1991, the game takes players deep into the heart of the city of Waterdeep\'s mysterious catacombs, revealing dark secrets and frightening monsters lurking in the shadows. Players form a party of four adventurers, and navigate this first-person dungeon crawler, using the real-time combat system to battle ghoulish enemies within. Gameplay in Eye of the Beholder emphasizes puzzle-solving, strategic combat, and exploration of labyrinthine dungeons. Its magic system and character creation, based on Advanced Dungeons & Dragons rules, offer a high degree of customization to players, a unique feature in the era it was released. Character progression and immersive narrative combined make this a compelling adventure from start to finish. At the time of release the game had been lauded for its atmospheric graphics, complex dungeons and the strategic depth of its combat system. If you\'re a fan of dungeon crawl RPGs like Dungeon Master or The Bard\'s Tale, you\'ll be a fan of Eye of the Beholder.',
        submitted_by: '',
        answers: ["Eye of the Beholder", "Dungeons & Dragons: Eye of the Beholder"],
        affiliate_links: [],
        franchise: 'Dungeons & Dragons',
        twitter_ids: [],
        release_year: '1991',
        metacritic_score: 'Too old for metacritic',
        genre: 'RPG, Puzzle elements',
        console_platform: 'DOS, Amiga',
        developer: 'Westwood Associates',
        backloggd_link: '',
    },
    '530': {
        content: 'Sea of Stars is an engaging turn-based role-playing game developed and published by Sabotage Studio. Released in 2023, the game leads players into a vibrant, pixelated world before the arrival of light, where they assume the roles of Children of the Solstice, a duo of warriors capable of drawing power from the moon and sun. Gameplay in Sea of Stars utilizes traditional turn-based combat with modernized mechanics that add depth, keeping every encounter unique and challenging. Players traverse through a beautiful world full of intricate puzzles, engaging NPCs, and fearsome monsters, with the story unfolding based on their actions and interactions. A distinct feature of Sea of Stars is its utilize of dynamic environments, allowing players to manipulate their surroundings to gain strategic advantages in battles. Moreover, the game boasts an interactive world where certain actions ripple through the environment, altering future interactions and providing a deeper sense of immersion. Sea of Stars has been applauded for its timeless aesthetic, incomparable story, and satisfying turn-based combat. If you\'re a fan of classic turn-based RPGs like Final Fantasy or Chrono Trigger, you\'ll be a fan of Sea of Stars.',
        submitted_by: '',
        answers: ["Sea of Stars"],
        affiliate_links: ["https://www.g2a.com/sea-of-stars-pc-steam-gift-global-i10000500428003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1717452895841665132", "1717461710867742885", "1717408125257396705", "1717381119845224851"],
        release_year: '2023',
        metacritic_score: '88%',
        genre: 'Adventure, Indie, RPG, Turn-based strategy (TBS)',
        console_platform: 'PC, PS4, XONE, Switch, PS5, Xbox Series',
        developer: 'Sabotage Studio Inc.',
        backloggd_link: '',
    },
    '531': {
        content: 'Keep Talking and Nobody Explodes is a unique and thrilling puzzle game developed and published by Steel Crate Games. Released in 2015, the game flips the typical solo gameplay experience on its head, by immersing players in a high-stakes bomb disposal situation where communication is key. One player is responsible for defusing a virtual bomb, but the catch is, they cannot see the defusing instructions. The other players, the \"experts\", have access to a manual with tenser-defusing procedures but can\'t see the actual bomb. The gameplay involves interaction, swift decision-making, and comprehensive communication between players to successfully defuse virtual explosives. The game\'s unique elements include a variety of challenging puzzles that necessitate cooperation, an intensifying climatic countdown timer, and a high degree of replayability with procedurally generated puzzles ensuring a different experience every time. Keep Talking and Nobody Explodes has been highly praised for its innovative concept, engaging cooperative gameplay, and its ability to produce frenzied, fun-filled moments of panic and relief. If you\'re a fan of cooperative puzzle games like Overcooked or party games like Jackbox Party Pack, you\'ll be a fan of Keep Talking and Nobody Explodes.',
        submitted_by: '',
        answers: ["Keep Talking and Nobody Explodes"],
        affiliate_links: ["https://www.g2a.com/keep-talking-and-nobody-explodes-steam-gift-global-i10000006269002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1717665344322519271", "1717844341115208096"],
        release_year: '2015',
        metacritic_score: '88%',
        genre: 'Indie, Puzzle',
        console_platform: 'PC, Macintosh',
        developer: 'Steel Crate Games, Inc.',
        backloggd_link: '',
    },
    '532': {
        content: '',
        submitted_by: '',
        answers: ["Toontown Online", "Toontown", "Toontown Rewritten"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '81%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Disney Interactive, Inc.',
        backloggd_link: '',
    },
    '533': {
        content: 'Lies of P is a Souls-like action RPG developed by Round8 Studio and published by Neowiz. Unveiled in 2023, the game transports players into a grim, twisted version of Belle Époque Paris populated by horrifying mechanical beings. Players take on the role of Pinocchio, a cyborg awoken with no recollection of his past, on a dark and tragic quest to find Mr. Geppetto. The gameplay of Lies of P echoes the punishing combat and exploration of Souls-like genre, with an intricate, gothic world filled with a variety of monstrosities. Players are challenged with complex combat scenarios, environmental puzzles, and an advanced weapon crafting system that encourages strategic play. Unique to Lies of P, the morality system affects both the story and character abilities, offering a nuanced approach to player decision making. Lies of P has been recognized for its exquisite art style, richly-detailed world, and engaging, challenging gameplay. If you\'re a fan of souls-like games such as Dark Souls series or Bloodborne, you\'ll be a fan of Lies of P.',
        submitted_by: '',
        answers: ["Lies of P"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Lies%20of%20P%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Flies-of-p-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/lies-of-p-deluxe-edition-pc-steam-key-global-i10000339622005?suid=9d2f94e6-a1eb-49a5-980b-94925f3bb962?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1718547200618467445", "1718523085349851446"],
        release_year: '2023',
        metacritic_score: '83%',
        genre: '3rd person, RPG, Action, Adventure',
        console_platform: 'PC, XONE, Xbox Series, PS4, PS5',
        developer: 'Neowiz Corporation, Round 8 Studio',
        backloggd_link: '',
    },
    '534': {
        content: 'Kirby Air Ride is a racing game developed by HAL Laboratory and published by Nintendo for the GameCube. Released in 2003, it propels players into a colorful, 3D world featuring everyone\'s favorite pink puffball, Kirby. The game diverges from typical racing formulas, most notably in its innovative \'one button\' control method, where steering, braking, and power-up usage are all linked to the same input, making it easy to learn but challenging to master. Kirby Air Ride hosts three different game modes - Air Ride, Top Ride, and the unique City Trial - each providing a distinct, engaging experience. Its standout mode, City Trial, throws players into a vast cityscape with a countdown clock, where they must fortify their machines before a randomized final challenge, leading to frantic exploration and exciting unpredictability. Cohesive with the Kirby franchise, players can also suck up enemies to steal their powers, adding a strategic element to races. Celebrated for its inventive gameplay mechanics, refreshing approach to racing, and charming aesthetics, Kirby Air Ride is sure to captivate. If you\'re a fan of fun, unconventional racing games like Mario Kart or Diddy Kong Racing, you\'ll be a fan of Kirby Air Ride.',
        submitted_by: '',
        answers: ["Kirby Air Ride"],
        affiliate_links: [],
        franchise: 'Kirby',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '61%',
        genre: 'Arcade, Racing',
        console_platform: 'GameCube',
        developer: 'HAL Laboratory, Inc.',
        backloggd_link: '',
    },
    '535': {
        content: 'We Love Katamari is an action puzzle game developed and published by Namco for PlayStation 2. Released in 2005, this is the sequel to the quirky and addictive original, Katamari Damacy. The core gameplay involves controlling the Prince as he rolls a sticky ball (Katamari) around a variety of environments, picking up all manner of objects in his path, from tiny thumb tacks to massive buildings - with everything in-between! The aim is to grow the Katamari to a specified size within a given time limit. Additionally, when not performing tasks for the King of the Cosmos, players can take on requests from fans they meet along their journey. These tasks can creatively modify the traditional rolling gameplay, such as rolling a snowball or inflating a sumo wrestler. We Love Katamari has been celebrated for taking the unique, charming gameplay of the original and giving it breadth and depth across a wider variety of tasks, storylines, and environments. If you\'re a fan of inventive puzzle games like Lemmings or Pikmin, or enjoy Japanese quirkiness found in games like Parappa the Rapper, you\'ll be a fan of We Love Katamari.',
        submitted_by: 'https://twitter.com/Weirdack',
        answers: ["We Love Katamari"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=We%20Love%20Katamari%20REROLL%20Royal%20Reverie%20Special%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwe-love-katamari-reroll-royal-reverie-special-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/we-love-katamari-reroll-royal-reverie-pc-steam-key-global-i10000339578001?suid=c890f917-08a3-440e-bb99-a9350423e20a?gtag=391e4ef696"],
        franchise: 'Katamari Damacy',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '86%',
        genre: 'Adventure, Puzzle',
        console_platform: 'PS2',
        developer: 'Namco Limited',
        backloggd_link: '',
    },
    '536': {
        content: 'PowerWash Simulator is a first-person simulation game developed by FuturLab and published by Square Enix Collective. Released in early access in 2021, the game offers a uniquely satisfying gameplay experience where the player takes on the role of a professional power washer. With trusty high-pressure washer in hand, players are tasked to clean up a variety of dirt-coated surfaces, from dirty driveways and moss-covered roofs to grimy vehicles. Each level presents a new mess, increasing in size and complexity with a lifelike dirt accumulation system, requiring different techniques and strategic use of power washing tools. As players progress, they earn money which can be used to upgrade or purchase more powerful washing equipment. Apart from its gameplay, PowerWash Simulator is known for its relaxing and almost therapeutic experience which is complemented by high-quality sound design, lifelike visuals, and detailed environmental settings. Despite its simple concept, the game offers a level of satisfaction and an oddly mesmerising appeal that sets it apart in the gaming industry. If you\'re a fan of relaxing simulation games like Euro Truck Simulator or contemplative experiences like Stardew Valley, you\'ll become a fan of PowerWash Simulator.',
        submitted_by: '',
        answers: ["PowerWash Simulator"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Powerwash%20Simulator%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpowerwash-simulator-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/powerwash-simulator-pc-steam-gift-global-i10000253207004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '73%',
        genre: 'Indie, Simulator',
        console_platform: 'PC',
        developer: 'Futurlab Ltd.',
        backloggd_link: '',
    },
    '537': {
        content: 'The World Ends with You is an action role-playing game developed by Square Enix and Jupiter for the Nintendo DS. First released in 2007, it introduces players to the vibrant, stylized streets of Shibuya, Tokyo, as they control Neku, a teenager who wakes up with no memories in a mysterious game for his life. In The World Ends with You, players interact with the environment, complete puzzles, and fight against various enemies using a unique combat system that utilizes both screens on the Nintendo DS. The game requires players to juggle battles on the top and bottom screens, making strategic use of the game\'s pins (which grant different abilities) and fashionable clothing items (that offer stat enhancements). This definitive mix of stylish visuals, immersive storytelling, and innovative combat mechanics have captivated players. The game is known for its unique integration of pop culture and urban lifestyle into its narrative and gameplay. Moreover, the game’s theme of personal growth and understanding the perspectives of others creates a compelling narrative that stands out. If you\'re a fan of unique RPGs like Persona 5 or innovative narrative games like Life is Strange, you\'ll be a fan of The World Ends with You.',
        submitted_by: '',
        answers: ["The World Ends with You"],
        affiliate_links: [],
        franchise: 'The World Ends with You',
        twitter_ids: ["1719909677218344980", "1720177250195411374", "1719859370354434202", "1720117951066460642", "1719938689348878600"],
        release_year: '2007',
        metacritic_score: '88%',
        genre: '2D, Action, RPG',
        console_platform: 'DS',
        developer: 'Jupiter Corp., Square Enix Co., Ltd.',
        backloggd_link: '',
    },
    '538': {
        content: 'Ring Fit Adventure is a fitness action role-playing game developed and published by Nintendo. The game, released in 2019, takes fitness exercises beyond the mat, combining engaging RPG gameplay with a real-world physical workout. It places players in a vibrant world where they embark on an adventure to defeat a body-building dragon, playing as a customizable hero challenged to defeat enemies with real-life exercises. Featuring more than 60 real-world exercises, players navigate the game world by jogging in place and use the Ring-Con accessory to perform in-game actions like jumping, rowing and yoga poses. The game\'s seamless integration of fitness and gaming has attracted a unique fan base. It\'s more than a fun-filled adventure with an engaging storyline: it\'s a commitment to a healthier lifestyle with quantifiable results and customizable workouts. Ring Fit Adventure\'s gamified fitness approach was praised for being innovatively engaging, encouraging regular exercise. If you\'re a fan of fitness-centered games like Wii Fit or active games with RPG elements like Pokemon Go, you\'ll be a fan of Ring Fit Adventure.',
        submitted_by: 'https://twitter.com/Sky096_',
        answers: ["Ring Fit Adventure"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1720317278086914300"],
        release_year: '2019',
        metacritic_score: '83%',
        genre: 'Sport? Exercise? Exhausting?',
        console_platform: 'Switch',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '539': {
        content: 'Frostpunk is a survival game developed and published by 11 bit studios. Set in an alternate 1886, it places players in a world where severe climate change has thrust the Earth into a devastating winter. Players step into the role of a leader of a small colony of survivors, building and maintaining a city around a massive heat generator. Frostpunk combines city-building, survival, and strategy elements. Players must gather resources, manage workers, implement laws, and make tough decisions to ensure the survival of their society. One notable aspect of the game is \'The Book of Laws\' which allows players to make society-level decisions that often have moral implications. The game is renowned for its dark and thought-provoking themes, demanding gameplay, and its blend of city-management with survival elements in a chilling, frostbitten world. Frostpunk has been celebrated for its challenging decision-making gameplay and for its bleak yet beautifully realized world. If you\'re a fan of survival or city-building strategy games like This War of Mine or Banished, you\'ll be a fan of Frostpunk.',
        submitted_by: '',
        answers: ["Frostpunk"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Frostpunk%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffrostpunk-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/frostpunk-game-of-the-year-pc-steam-key-global-i10000148339017?gtag=391e4ef696"],
        franchise: 'Frostpunk',
        twitter_ids: ["1720581075246661884"],
        release_year: '2018',
        metacritic_score: '84%',
        genre: 'City-building, survival',
        console_platform: 'PC',
        developer: '11 bit studios',
        backloggd_link: '',
    },
    '540': {
        content: 'Ultima Online is a massively multiplayer online role-playing game developed by Origin Systems and was one of the first games of its genre upon release in 1997. This landmark game transports players into the expansive, persistent world of Britannia and offers a truly sandbox experience where players can engage in activities such as house building, crafting, and monster hunting. The backbone of Ultima Online is its emphasis on player interaction. It fosters a deep sense of community with players forming alliances, participating in epic player vs player battles, or simply socializing in the game\'s many towns. Players navigate the open-world environment from an isometric perspective and can interact with in-game items and characters, work on their skills, or embark on quests. Ultima Online is noted for its complex system that imposes penalties for unlawful actions, for its influence on ensuing MMORPGs and its continued popularity with expansion packs keeping its player base engaged. If you\'re a fan of complex MMORPGs with a high emphasis on player interaction like World of Warcraft or Eve Online, you\'ll be a fan of Ultima Online.',
        submitted_by: '',
        answers: ["Ultima Online"],
        affiliate_links: [],
        franchise: 'Ultima',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '59%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Origin Systems',
        backloggd_link: '',
    },
    '541': {
        content: 'Diablo III is an action role-playing game developed and published by Blizzard Entertainment. Thrust into a dark fantasy world plagued by the demonic forces of the Burning Hells, players assume the roles of brave heroes, keen on banishing evil from the world of Sanctuary. Released in 2012, the game centers on \'Nephalem\' heroes battling their way through Diablo, the prime evil. Diablo III marries the hack-and-slash combat of its predecessors with an intricate loot system, and significant character customization. Players tackle fiendish monsters, explore hazardous terrains, conquer dynamically generated dungeons and seek treasures, all while materializing their hero\'s unique abilities. Enhancing the gameplay are the online multiplayer modes and the hardcore mode for testing players\' might. Known for its compelling storyline filled with apocalyptic battles, immersive environments, and robust multiplayer options, Diablo III offers an unmatched treacherous, yet thrilling journey into the depths of hell. Its comprehensive RPG elements along with dynamic challenges and character development have set a high standard in the genre. If you\'re a fan of action-packed adventure games like Path of Exile or World of Warcraft, you\'ll be a fan of Diablo III.',
        submitted_by: '',
        answers: ["Diablo III", "Diablo 3"],
        affiliate_links: ["https://www.g2a.com/diablo-3-eternal-collection-xbox-one-xbox-live-key-global-i10000152150015?suid=6bf6cfd0-e715-43ce-bfc2-cacfd45f04fe?gtag=391e4ef696"],
        franchise: 'Diablo',
        twitter_ids: ["1721409295462379953", "1721449025461985520", "1721488860029272170", "1721310582169874458"],
        release_year: '2012',
        metacritic_score: '88%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'Blizzard Team 3',
        backloggd_link: '',
    },
    '542': {
        content: 'Sunset Overdrive is an open-world, third-person shooter game developed by Insomniac Games and published by Microsoft Studios. Released in 2014, the game transports players to an anarchic version of a 2027 metropolis known as Sunset City. As employees of the mega-corporation FizzCo, players are left to clean up the mess when a FizzCo energy drink mutates most of the population into frantic, toxic creatures. Comedic and irreverent, Sunset Overdrive encourages players to leverage an array of unconventional weapons and abilities to battle the mutants, with agility and creative movement through the city being a key survival strategy. Gameplay combines strategic shooting with parkour-style movement, using grind rails and zip lines for navigation, and bouncing off objects for verticality. Several multiplayer modes also exist, allowing players to team up for cooperative play. Sunset Overdrive has been lauded for its distinctive art style, high-octane action and freedom of movement inside its vibrant open world. If you\'re a fan of fast-paced action games with a healthy dose of humor like Saints Row or Crackdown, you\'ll be a fan of Sunset Overdrive.',
        submitted_by: '',
        answers: ["Sunset Overdrive"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sunset%20Overdrive%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsunset-overdrive-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/sunset-overdrive-steam-gift-global-i10000004357008?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1721794274918817806", "1721827379205546101", "1721715873193595017", "1721841019887697996"],
        release_year: '2014',
        metacritic_score: '81%',
        genre: 'Action, Adventure, 3rd-person, Shooter',
        console_platform: 'XONE',
        developer: 'Insomniac Games',
        backloggd_link: '',
    },
    '543': {
        content: 'Gears 5 is a third-person shooter game developed by The Coalition and published by Xbox Game Studios. Launched in 2019, it lets players dive into the war-torn world of Sera as Kait Diaz, a former Outsider turned Gear who embarks on a personal quest to uncover the truth behind her mysterious lineage and its connection to the Locust Horde. Building on the gameplay of previous installments, Gears 5 offers an assortment of campaign, multiplayer and cooperative modes. Players engage in intense firefights, make use of a wide array of weaponry, and employ strategic tactics against formidable enemies. Aside from classic cover-based shooting mechanics, Gears 5 introduces new abilities through the robot companion, JACK, adding another layer of strategy to the game. Known especially for its captivating story, diverse gameplay modes, and stunning graphics, Gears 5 has been commended for its refined combat, expansive multiplayer offerings, and its commitment to character-driven storytelling. If you\'re a fan of intensive action games like The Division or third-person shooters like Uncharted, you\'ll be a fan of Gears 5.',
        submitted_by: '',
        answers: ["Gears 5", "Gears of War 5"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Gears%20of%20War%205%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgears-5-xbox-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/gears-5-steam-gift-global-i10000189224010?gtag=391e4ef696"],
        franchise: 'Gears of War',
        twitter_ids: ["1722085363844583730", "1722322064579633368", "1722210578381541866"],
        release_year: '2019',
        metacritic_score: '82%',
        genre: 'Action, Adventure, 3rd-person, Shooter',
        console_platform: 'PC, XONE',
        developer: 'Coalition, The',
        backloggd_link: '',
    },
    '544': {
        content: 'Galaga is an iconic arcade shooter game developed and published by Namco. Released in 1981, it takes players on an intergalactic journey where they assume the role of a spaceship pilot battling against swarms of menacing alien forces. In Galaga, the objective is straightforward - dodge and destroy. Players manoeuvre their ship, which is stranded at the bottom of the screen, left and right to evade incoming alien attacks while firing back to annihilate enemies. The game is notorious for its increasingly challenging waves of attacking formations, including the signature capturing maneuver where one specific enemy type attempts to capture the player\'s spaceship with a tractor beam. If successful, the player must rescue the captured ship to achieve double firepower. With its absorbing gameplay, responsive controls, and escalating difficulty, Galaga set a new standard for arcade shooters in the early \'80s and continues to be a fan favorite today. Its simple yet captivating gameplay keeps players coming back for one more round. If you\'re a fan of classic arcade shooter games like Space Invaders or Centipede, you\'ll be a fan of Galaga.',
        submitted_by: '',
        answers: ["Galaga"],
        affiliate_links: [],
        franchise: 'Galaga',
        twitter_ids: ["1722391960046944548"],
        release_year: '1981',
        metacritic_score: 'Too old',
        genre: 'Fixed Shooter',
        console_platform: 'Arcade',
        developer: 'Namco',
        backloggd_link: '',
    },
    '545': {
        content: 'Grand Theft Auto V is an action-adventure game developed by Rockstar North and published by Rockstar Games. Launched in 2013, the game entices players to explore Los Santos, a fictional contemporary Southern California city, and follow the intertwined lives of three central characters: veteran criminal Michael, street-smart gangster Franklin, and manic drug dealer Trevor. During their criminal escapades, players can switch between these characters, undertaking a variety of missions from daring heists to high-speed escapes. Beyond the game’s gripping narrative, players can also enjoy a vast open-world landscape, engaging in anything from car customization and yoga sessions to stock market investments. The gameplay balances linear mission scenarios with open-world free-roaming, all saturated with realistic details for an immersive experience. Also notable is Grand Theft Auto Online, a dynamic online world where up to 30 players engage in both co-op and competitive gameplay. Recognized for its outstanding storytelling, rich gameplay, and unmatched detail, Grand Theft Auto V is a modern-day classic. If you\'re a fan of open-world action-adventure games like Red Dead Redemption 2 or Watch Dogs, you\'ll be a fan of Grand Theft Auto V.',
        submitted_by: '',
        answers: ["Grand Theft Auto V", "Grand Theft Auto 5"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Grand%20Theft%20Auto%20V%20and%20Criminal%20Enterprise%20Starter%20Pack%20Bundle%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgrand-theft-auto-v-premium-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/grand-theft-auto-v-premium-edition-xbox-one-xbox-live-key-global-i10000000788067?gtag=391e4ef696"],
        franchise: 'Grand Theft Auto',
        twitter_ids: ["1722997183492882790", "1722871046330593604", "1722752273896358227"],
        release_year: '2013',
        metacritic_score: '96%',
        genre: 'Action, Open World, Sandbox, Shooter',
        console_platform: 'PS3, X360',
        developer: 'Rockstar North Ltd.',
        backloggd_link: '',
    },
    '546': {
        content: 'Artifact is a digital card game developed and published by Valve. Released in 2018, Artifact tasks players with constructing decks and battling opponents in a sphere that marries traditional card-game strategy with the lore of the wildly popular Dota 2 universe. Each round of Artifact is a sprawling battle across three separate boards known as lanes, reminiscent of the map in Dota 2. The game boasts over 280 cards at launch, each offering their own abilities, which are supplemented by 44 hero cards, each tied to one of four color-coded play-styles. Its unique mechanics, such as the ability to buy items for heroes with gold earned from killing enemy units, augment the strategic depth of the game. Though the game may seem daunting for beginners, its tutorial system and casual play mode offer ample opportunity for players to understand the game’s rich strategy without feeling overwhelmed. Artifact\'s depth and strategic variety, coupled with elements that echo familiar Dota 2 mechanics, have been praised by critics. If you\'re a fan of complex deck-building games like Hearthstone or Gwent, or a fan of the Dota 2 universe, you\'re sure to be a fan of Artifact.',
        submitted_by: '',
        answers: ["Artifact"],
        affiliate_links: [],
        franchise: 'Dota',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '76%',
        genre: 'Digital collectible card game',
        console_platform: 'PC',
        developer: 'Valve',
        backloggd_link: '',
    },
    '547': {
        content: 'Twisted Metal 2 is a vehicular combat video game developed by SingleTrac and published by Sony Computer Entertainment. Launched in 1996, this sequel invites players to terrorize city streets in heavily armed, fantastical vehicles as iconic characters such as Sweet Tooth, a maniacal clown in a deadly ice cream truck, in pursuit of a single wish from the mysterious Calypso. Players navigate urban landscapes worldwide, converting landmarks like Paris Eiffel Tower into a battleground and firing missiles in Post-Apocalyptic New York. Players have the freedom to approach each level in their own way, setting up ambushes or going on the offensive, always mindful of destructive power-ups that can tilt the battle in their favor. This chaotic action-packed game is recognized for its diverse vehicle lineup, vivid environments, and thrilling multiplayer modes, allowing friends to face-off in vehicular warfare. Twisted Metal 2\'s audacious blend of absurdity, strategy, and destruction has left a lasting impact on the vehicular combat genre. If you\'re a fan of chaotic battle games involving vehicles like Rocket League or Mad Max, you\'ll be a fan of Twisted Metal 2.',
        submitted_by: '',
        answers: ["Twisted Metal 2"],
        affiliate_links: [],
        franchise: 'Twisted Metal',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'Not Rated',
        genre: 'Vehicular combat',
        console_platform: 'PS1',
        developer: 'Sony Interactive Studios America',
        backloggd_link: '',
    },
    '548': {
        content: 'Pokemon Sword is an adventure-filled RPG developed and published by Game Freak and The Pokemon Company for the Nintendo Switch platform. Unveiled in 2019, players plunge into the picturesque Galar region, a vast open world inspired by the United Kingdom. Players take on the role of a Pokemon Trainer seeking to become the champion of the Pokemon League. Along the journey, trainers collect a variety of unique Pokemon species, engage in strategic turn-based battles against rival trainers, and uncover the mysteries of the legendary Pokemon, Zacian. The gameplay is marked by enhanced dynamaxing and gigantamaxing features, producing larger-sized and stronger Pokemon during battles adding a strategic dimension to the famed Pokemon combats. Pokemon Sword stands out for its beautifully designed world, intricate plot, abundance of unique Pokemon and innovative combat mechanics. Since its release, it has been lauded for its rich environments, engaging combat system, and the sense of adventure it delivers. If you\'re a fan of role-playing games like Yo-Kai Watch or Digimon Story: Cyber Sleuth, you\'ll be a fan of Pokemon Sword.',
        submitted_by: '',
        answers: ["Pokemon Sword", "Pokemon Shield"],
        affiliate_links: ["https://www.g2a.com/pokemon-sword-nintendo-eshop-nintendo-switch-key-united-states-i10000190360002?gtag=391e4ef696"],
        franchise: 'Pokemon',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '80%',
        genre: 'RPG',
        console_platform: 'Switch',
        developer: 'Game Freak',
        backloggd_link: '',
    },
    '549': {
        content: 'Fallout 3 is a post-apocalyptic, open-world role-playing game developed by Bethesda Game Studios and published by Bethesda Softworks. Released in 2008, it immerses players into a bleak and devastated future in the year 2277, nearly two centuries after a nuclear war ravaged the earth. Players assume the role of an inhabitant of Vault 101, one of the several fallout shelters built to save humanity, who embarks on a perilous journey across the desolate ruins of Washington D.C., now known as the Capital Wasteland. The gameplay focuses on exploration, combat, and character development with a skill and attribute system. Players can interact with various characters, commit to a multitude of quests, confront hostile creatures and make choices that determine the fate of the wasteland. What makes Fallout 3 unique is its gritty atmosphere, compelling narrative and its combination of first-person shooter and RPG mechanics. Its distinctive retro-futuristic design, blending 1950s aesthetics with post-apocalyptic elements, adds to its unique ambiance. Fallout 3 received praises for its immersive world, depth gameplay, and thought-provoking themes. If you\'re a fan of role-playing games with deep narratives and open world exploration like The Elder Scrolls V: Skyrim or The Witcher 3: Wild Hunt, you\'ll be a fan of Fallout 3.',
        submitted_by: '',
        answers: ["Fallout 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fallout%203%20Game%20of%20the%20Year%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffallout-3-game-of-the-year-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/fallout-3-game-of-the-year-edition-steam-key-global-i10000001849008?suid=026d4224-d999-4d12-aa81-407d20f658b4?gtag=391e4ef696"],
        franchise: 'Fallout',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '93%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS3, X360',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '550': {
        content: 'The Sims 3, developed by EA Maxis and published by Electronic Arts, is a sandbox life simulation game that enables players to design and control virtual lives in a lively and colourful world. Released in 2009, The Sims 3 sees players create and customize a Sim - a virtual person - full of personality and charm, and then move them into fully customizable houses in a vibrant neighborhood. Players guide their Sim\'s life choices, including love, career, and friends, allowing fascinating scenarios to unfold in this open-ended gameplay, complete with life cycles and dreams. Unique elements of the game include a new Create-a-Sim system that provides nearly endless customization and a \"Story Progression\" feature, where Sims go about their daily lives even when not directly controlled by the player. The Sims 3 is recognized for its high replay value, improved graphics, and the depth of its simulation mechanics. If you\'re a fan of relaxing, creative games like Animal Crossing or Stardew Valley, you\'ll be a fan of The Sims 3.',
        submitted_by: '',
        answers: ["The Sims 3"],
        affiliate_links: ["https://www.g2a.com/the-sims-3-origin-key-global-i10000023727004?gtag=391e4ef696"],
        franchise: 'The Sims',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '86%',
        genre: 'Social simulation',
        console_platform: 'PC, Mobile',
        developer: 'Maxis, EA Mobile, Edge of Reality',
        backloggd_link: '',
    },
    '551': {
        content: 'The Oregon Trail is an educational video game developed by Don Rawitsch, Bill Heinemann, and Paul Dillenberger and was first released in 1971. Considered a hallmark in the edutainment genre, the game simulates the harsh conditions of 19th-century pioneer life on the historic Oregon Trail. Players assume the role of a wagon leader guiding a party of settlers from Independence, Missouri, to Oregon\'s Willamette Valley. Gameplay involves managing resources and making decisions on hunting, trading, and when to travel to avoid various hardships. Your party faces numerous dangers including disease, severe weather, and broken wagon parts which all impact your performance and score. The game, despite its age, offers a surprisingly robust and challenging simulation experience. One of the standout features about The Oregon Trail is its educational approach, seamlessly blending history and geography learning with engaging gameplay - a formula that would inspire a whole genre of educational games. The Oregon Trail has consistently been praised for its immersive, historical gameplay and educational value. If you\'re a fan of educational simulation games like Where in the World Is Carmen Sandiego? or SimCity, you\'ll be a fan of The Oregon Trail.',
        submitted_by: '',
        answers: ["The Oregon Trail"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '1971 (Re-made in 1985 - Screenshots taken from this version)',
        metacritic_score: '76%',
        genre: 'Strategy',
        console_platform: 'Mainframe, Apple II (1985)',
        developer: 'Don Rawitsch, Bill Heinemann, and Paul Dillenberger',
        backloggd_link: '',
    },
    '552': {
        content: 'Pikmin 4 is a real-time strategy game produced by Nintendo. Revealed in 2023, it continues the intergalactic exploration and biodiversity themes synonymous with the series. In this game, players command a troupe of adorable, plant-like species known as Pikmin, embarking on another quest to reclaim spaceship parts and survive in an enchanting, yet hazardous alien world. Players must leverage the unique abilities of each Pikmin variant - reds are fire-resistant, blues can traverse water, and yellows can be thrown higher - to solve environmental puzzles and overwhelm enemies. Pikmin 4 builds on its predecessor with the introduction of new Pikmin species and evolved AI behavior, injecting fresh nuances into the franchise\'s team-oriented strategic gameplay. It also incorporates the day-night cycle, forcing players to strategize and plan their actions based on the changing times and associated hazards. Its captivating mix of strategy, action, and puzzle-solving combined with vibrant graphics and immersive environments underline Pikmin 4\'s appeal. If you\'re a fan of strategy-driven exploration games like Starcraft or explorative, puzzle-based titles like Portal, you\'ll definitely find joy in Pikmin 4.',
        submitted_by: '',
        answers: ["Pikmin 4"],
        affiliate_links: ["https://www.g2a.com/pikmin-4-nintendo-switch-nintendo-eshop-key-europe-i10000500120001?gtag=391e4ef696"],
        franchise: 'Pikmin',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '87%',
        genre: 'Real-time strategy',
        console_platform: 'Switch',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '553': {
        content: 'Mario\'s Time Machine is an educational game developed and published by The Software Toolworks for multiple platforms like Super NES and PC. Released in 1993, it combines elements from the beloved Super Mario series with creative educational content. Players step into the ever-hopping shoes of Mario as he uses a time machine to recover artifacts stolen by Bowser, scattered in different points in history. Gameplay revolves around an entertaining blend of platforming, puzzle-solving, and historical trivia. Players travel to various periods like the Renaissance or Ancient Egypt, learning factual history through interactive gameplay. The game retains its classic side-scrolling platforming style, but with the intriguing addition of time travel and history lessons. Each historical period comes with challenges and puzzles related to its context, making historical learning fun and immersive. Mario\'s Time Machine is appreciated for its unique melding of education with the fun Mario universe and its approach to making learning history engaging for players. If you\'re a fan of educational adventure games like The Oregon Trail and Where in the World is Carmen Sandiego or platformers like Super Mario Bros., you\'ll love Mario\'s Time Machine.',
        submitted_by: '',
        answers: ["Mario Time Machine", "Mario's Time Machine"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '1993',
        metacritic_score: 'Too Old',
        genre: 'Platformer, Side-View, Educational (?)',
        console_platform: 'DOS, SNES, NES',
        developer: 'The Software Toolworks, Inc.',
        backloggd_link: '',
    },
    '554': {
        content: 'Patapon is a rhythm-based strategy game developed by Pyramid and Japan Studio and published by Sony Computer Entertainment. Debuting in 2008, the game invites players to step into the shoes of a god guiding the Patapon tribe, a group of small, eye-shaped creatures, towards their ultimate goal: the end of the world. Gameplay in Patapon requires rhythmic input from players, with specific button sequences initiating different commands, such as march, attack, or defend. This rhythm-based gameplay combined with elements of strategy, by equipping different weapons and units for battle, sets Patapon apart from its contemporaries. With its distinctive art style–featuring black silhouetted characters against colorful backgrounds–and a complex, multi-layered system of evolution and customization, Patapon offers a unique gaming experience. The game also features numerous battlefields, mini-games, and hunting challenges, along with the ability to create new Patapon units. Patapon has been lauded for its innovative blending of music/rhythm and strategy gameplay, as well as its charming and distinct aesthetics. If you\'re a fan of rhythm-based games like Elite Beat Agents or strategic games like Fire Emblem, you\'ll undoubtedly be a fan of Patapon.',
        submitted_by: 'https://twitter.com/Sky096_',
        answers: ["Patapon"],
        affiliate_links: [],
        franchise: 'Patapon',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '86%',
        genre: 'Music, Real Time Strategy',
        console_platform: 'PSP',
        developer: 'Sony Computer Entertainment Japan, Pyramid, Inc.',
        backloggd_link: '',
    },
    '555': {
        content: 'Paper Mario: The Origami King is an action-adventure game developed by Intelligent Systems and published by Nintendo for the Nintendo Switch. In this 2020 release, players journey along with the memorable character Mario as he embarks on a quest to rescue Princess Peach from the villainous King Olly, who has turned her and his castle into origami. The gameplay offers a unique blend of action, RPG elements and puzzle-solving using strategic ring-based battles, where players must align enemies correctly to maximize damage. The game\'s intricate origami-inspired visual style sets it apart as the world and its characters are made to look as if they are crafted from paper and origami. This charm is coupled with the series\' signature humor to build an engaging, whimsical world for players to explore. Throughout their journey, players encounter a variety of vibrant environments, solve clever puzzles, and battle quirky adversaries. Paper Mario: The Origami King has been lauded for its creative gameplay mechanics, charming art style, and captivating storytelling. If you\'re a fan of strategy RPGs like Fire Emblem or unique adventure games such as the Legend of Zelda series, you\'re sure to enjoy Paper Mario: The Origami King.',
        submitted_by: '',
        answers: ["Paper Mario: The Origami King"],
        affiliate_links: ["https://www.g2a.com/paper-mario-the-origami-king-nintendo-switch-nintendo-eshop-key-europe-i10000206391002?gtag=391e4ef696"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '80%',
        genre: 'Adventure, Turn Based RPG',
        console_platform: 'Switch',
        developer: 'Intelligent Systems Co., Ltd.',
        backloggd_link: '',
    },
    '556': {
        content: 'Jak II is an action-adventure game developed by Naughty Dog and published by Sony Computer Entertainment for the PlayStation 2. The sequel to the original game, Jak and Daxter: The Precursor Legacy, Jak II takes players on an engrossing journey as Jak, the protagonist, along with his best friend Daxter, as they are flung into a dystopian city ruled by a brutal tyrant. Jak II introduced a dark and complex narrative, coupled with a shift from the primarily platforming focus of its predecessor to incorporate more varied gameplay elements, notably those of shooting and driving segments. Apart from its base story missions, the game includes numerous side-quests, mini-games and exploration opportunities throughout the sprawling cityscape of Haven City, contributing to an engaging diverse gameplay experience. The game is keenly remembered for its significant difficulty spike compared to the predecessor and an impactful shift in thematics and tone. Fans have acclaimed Jak II for its engrossing narrative, expansive world, and diversified gameplay. If you\'re a fan of action-adventure platformers with a darker edge, like Ratchet & Clank: Going Commando or Banjo-Tooie, you\'ll be a fan of Jak II.',
        submitted_by: 'https://www.youtube.com/channel/UCB-5KhbSTZaBx2C0KCsWzlA',
        answers: ["Jak II", "Jak II: Renegade"],
        affiliate_links: [],
        franchise: 'Jak and Daxter',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '87%',
        genre: 'Adventure, Platform',
        console_platform: 'PS2',
        developer: 'Naughty Dog, Inc.',
        backloggd_link: '',
    },
    '557': {
        content: 'Saints Row: The Third is an action-adventure game developed by Volition and published by THQ, originally released in 2011 and receiving a remastered version in 2020. The game invites players onto the mean streets of Steelport, a fictitious city perpetually embroiled in gang warfare, placing them in the shoes of the Third Street Saints\' gang leader. The dramatic plot tweists of Saints Row: The Third take players on a action-packed journey with set pieces ranging from a bank heist gone wrong to skydiving while shooting rockets. While the missions drive the story, players are also free to explore the sprawling urban setting, engaging in side activities like taking over rival gang territories or customizing their character and their vehicular armada. The game is celebrated for its outrageous humor, irreverent story, and diverse gameplay, which blends traditional third-person shooting with over-the-top scenarios and customization options. Saints Row: The Third hasn\'t shied away from being compared to the realm of pop-culture, often poking fun at itself and other games. If you\'re a fan of action-packed, open-world games like Grand Theft Auto and Sleeping Dogs, with a unique blend of humor akin to Borderlands, you\'ll be a fan of Saints Row: The Third.',
        submitted_by: '',
        answers: ["Saints Row: The Third"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Saints%20Row%20the%20Third%20the%20full%20package%202015%20%281%29%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsaints-row-the-third-the-full-package%2F&intsrc=PUI2_9608", "https://www.g2a.com/saints-row-the-third-full-package-gogcom-key-global-i10000000628009?gtag=391e4ef696"],
        franchise: 'Saints Row',
        twitter_ids: [],
        release_year: '2011, Remastered in 2020',
        metacritic_score: '84%',
        genre: 'Action, Shooter, Open World, Sandbox',
        console_platform: 'PC, 360, PS3',
        developer: 'Volition, Inc.',
        backloggd_link: '',
    },
    '558': {
        content: 'Dynasty Warriors is an action-packed fighting game developed and produced by Japanese creators Omega Force and Koei. This 1997 release invites players onto the ancient battlefields of China during the Three Kingdoms era, where they employ the strategic artistry of masterful combat. The 3D one-versus-one fighting style sends gamers headfirst into a war zone where they enact intricate combination attacks and special moves that vary between characters. Noted for its historical context, players inhabit the roles of legendary martial heroes such as Lu Bu and Zhao Yun, each one carrying their distinctive weapons and fighting styles. Set against the backdrop of geopolitical warfare, the plot combines historical fact with legendary tales to provide an enriched storyline. Dynasty Warriors\' mix of history, mythology, and non-stop action puts it at the forefront of innovative and memorable fighting games. Alongside the intense gameplay, the atmospheric music accentuates the overall gaming experience providing a perfect accompaniment to the epic battles. If you\'re a fan of immersive fighting games like Samurai Warriors or Soul Calibur, you\'ll be a fan of Dynasty Warriors.',
        submitted_by: '',
        answers: ["Dynasty Warriors"],
        affiliate_links: [],
        franchise: 'Dynasty Warriors',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: 'Too Old',
        genre: 'Action, Arcade, Fighting',
        console_platform: 'Playstation',
        developer: 'Omega Force',
        backloggd_link: '',
    },
    '559': {
        content: 'Pokemon Ruby is a role-playing game developed by Game Freak and published by Nintendo for the Game Boy Advance. Set in the quaint, beautifully rendered region of Hoenn, players assume the role of a Pokemon trainer embarking on a quest to become the champion Trainer while thwarting the sinister plans of the evil Team Magma. With the gameplay structured around the capturing and training of a variety of fictional creatures called \'Pokemon\', players battle their way across various challenges, including Gym Leader battles, Pokemon Contests, and the Elite Four. The unique elements of Pokemon Ruby include the introduction of double battles and abilities, a weather system influencing gameplay, and an expanded roster of more than 200 Pokemon to capture. It also introduced their signature game mascot, Groudon. Since its release, the game has been praised for its complex, strategic battle mechanics, comprehensive plot, and extensive playtime. If you\'re a fan of turn-based role-playing games like Digimon World or Monster Rancher, you\'ll be a fan of Pokemon Ruby.',
        submitted_by: 'https://twitter.com/Sky096_',
        answers: ["Pokemon Ruby", "Pokemon Sapphire", "Pokemon Emerald"],
        affiliate_links: [],
        franchise: 'Pokemon',
        twitter_ids: [],
        release_year: '2002 (Japan)',
        metacritic_score: '82%',
        genre: 'Adventure, RPG, Turn-based',
        console_platform: 'GBA',
        developer: 'Game Freak, Inc., Creatures, Inc.',
        backloggd_link: '',
    },
    '560': {
        content: 'Omori is a surreal psychological horror role-playing game developed by Omocat. Released in 2020, it takes players on a heartbreaking journey of exploration into a strange world as they take on the role of the titular character, Omori. This game artfully combines element of horror, allowing players to pierce through the veil of escapism, and battle their own fears and anxieties. Each choice you make in Omori\'s turn-based combat system and puzzle-solving gameplay affects the game\'s story, leading to multiple endings. Layered within is an element of reality anchored in its story — addressing serious issues like mental health and dealing with trauma that enhances its eerie, yet poignant, narrative approach. Its unique blend of beautifully illustrated visuals, deep narrative, and engaging gameplay make Omori an unbeatable choice for fans of immersive RPGs. Notably, the game\'s soundtrack also takes center stage as it adds depth and emotion to the game-play, drawing players deeper into the surreal world of Omori. If you\'re a fan of psychological, immersive games like Undertale or Earthbound, you\'ll definitely enjoy the haunting poignance of Omori.',
        submitted_by: 'https://twitter.com/wowmom99',
        answers: ["Omori"],
        affiliate_links: ["https://www.g2a.com/omori-pc-steam-gift-global-i10000233084005?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '87%',
        genre: 'Adventure, Indie, RPG, Turn-based',
        console_platform: '2020: PC, Mac. 2022: XONE, Switch, PS4',
        developer: 'OMOCAT, LLC',
        backloggd_link: '',
    },
    '561': {
        content: 'Lineage II is a massively multiplayer online role-playing game (MMORPG) developed by NCSOFT. Released in 2004, players are plunged into an expansive medieval fantasy world, choosing from a range of seven races and 36 different classes to engage in epic battles, intricate quests, and explore diverse lands. The plot revolves around a war of realms, Illusion, Elmore, and Aden, filled with castles, creatures, and compelling lore. Its gameplay is centered around player vs player (PVP) combat, party-based dungeons, and large-scale, strategic siege warfare where players can even claim their castle. Lineage II is also known for its unique pet and mount system where players can capture, train, evolve, and ride creatures offering various combat advantages. The game has been praised for its complex character progression, detailed world-building, and thrilling PVP action that test both individual and collective player strategies. Its large-scale siege battles have created an enduring legacy for the game in the genre. If you\'re a fan of immersive MMORPGs like World of Warcraft or EverQuest, you\'ll be a fan of Lineage II.',
        submitted_by: 'https://www.instagram.com/lorena_carrotcake/',
        answers: ["Lineage II", "Lineage 2"],
        affiliate_links: [],
        franchise: 'Lineage',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '62%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'NCsoft Corporation',
        backloggd_link: '',
    },
    '562': {
        content: 'Mass Effect is a science-fiction action role-playing game developed by BioWare and published by Electronic Arts. Released in 2007, this epic game sets players on a thrilling voyage through the galaxy as Commander Shepard, an elite human soldier tasked with stopping a rogue agent hell-bent on bringing destruction to the universe. The game is known for its highly interactive dialogue system and narrative branching, which grosly shapes the game\'s plot based on player decisions. In Mass Effect, exploring stunning, alien worlds, battling diverse enemies, improving your team\'s skills, and managing relationships are fundamental to the overall experience. One of the unique selling points of the game is its complex morality system, which comprises both \'Paragon\' and \'Renegade\' paths, each influencing the narrative\'s course and character interactions. Mass Effect is praised widely for its richly detailed universe, superb storytelling, and the depth of its decision-making mechanics. If you\'re a fan of science-fiction RPGs like Star Wars: Knights of the Old Republic or action-packed adventures like Gears of War, you will undoubtedly be a fan of Mass Effect.',
        submitted_by: '',
        answers: ["Mass Effect"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Mass%20Effect%20Legendary%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmass-effect-legendary-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/mass-effect-legendary-edition-pc-steam-gift-global-i10000237064005?gtag=391e4ef696"],
        franchise: 'Mass Effect',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '89%',
        genre: 'Action RPG, 3rd-person',
        console_platform: 'X360, PC (2008)',
        developer: 'BioWare Corporation',
        backloggd_link: '',
    },
    '563': {
        content: 'Vanquish is a third-person shooter game developed by PlatinumGames and published by Sega. Released in 2010, the game immerses players in an adrenaline-fueled combat against a futuristic robotic army. The players step into the technologically advanced battlesuit of Sam Gideon, a Defense Advanced Research Projects Agency (DARPA) operative. The action takes place on a space station where Sam fights to rescue its occupiers and halt the apocalyptic plans of their captor. One unique element of Vanquish is its gameplay mechanism called \"Augmented Reaction\", which is a bullet time-like mechanic that allows Sam to slow down time, giving players a chance to determine their next move. Another notable feature of the game is its cover system, where taking too much damage forces players to strategize and make rapid decisions to survive. Vanquish has been praised for its fast-paced combat, innovative gameplay, and stylistic graphics. If you\'re a fan of adrenaline-packed shooter games like Gears of War or Metal Gear Rising: Revengeance, you\'ll be a fan of Vanquish.',
        submitted_by: 'https://twitter.com/Dezuuu',
        answers: ["Vanquish"],
        affiliate_links: ["https://www.g2a.com/vanquish-steam-key-global-i10000042384002?suid=52ad8797-46a9-4021-9a8a-3e742266082a?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1729284074534056333", "1729386451270259076"],
        release_year: '2010',
        metacritic_score: '78%',
        genre: 'Action, Shooter, Sci-Fi',
        console_platform: 'X360, PS3',
        developer: 'Platinum Games Inc.',
        backloggd_link: '',
    },
    '564': {
        content: 'Batman: Arkham Knight is an action-adventure game developed by Rocksteady Studios and published by Warner Bros. Interactive Entertainment. Released in 2015, it presents players with a dark, gripping narrative as they step into the role of Batman, the iconic superhero, during his most testing times. The story takes place in Gotham City, now fully open to players to explore, and infested with a rogues gallery of villains including Scarecrow, Harley Quinn, and the mysterious Arkham Knight. The gameplay combines elements of stealth, combat, detective skills, and navigation. Unique to this installment in the series is the Batmobile, which can be used for transportation, puzzle-solving and combat. The entire city of Gotham has been meticulously crafted to suit the Batmobile, with destructible environments and wide streets designed to make the most thrilling chases. The game’s graphical realism, storytelling, and innovative gameplay has been highly praised by critics and players alike. So, if you\'re a fan of dramatic action games like Assassin\'s Creed Syndicate or the Spider-Man series, you\'ll be a fan of Batman: Arkham Knight.',
        submitted_by: '',
        answers: ["Batman: Arkham Knight"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Batman%20Arkham%20Knight%20%20Premium%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbatman-arkham-knight-premium-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/batman-arkham-knight-premium-edition-steam-key-global-i10000002172005?gtag=391e4ef696"],
        franchise: 'Batman',
        twitter_ids: ["1729645115574992930", "1729641031904170291", "1729518249673728131", "1729636890331037896", "1729734560433287180"],
        release_year: '2015',
        metacritic_score: '85%',
        genre: 'Action, Beat \'em up, Puzzle elements',
        console_platform: 'PC, PS4, XONE',
        developer: 'Rocksteady Studios Ltd',
        backloggd_link: '',
    },
    '565': {
        content: 'Super Mario Bros is a renowned platform video game developed and published by Nintendo in 1985. In this iconic game, players control Mario, or his brother Luigi in the multiplayer mode, as they traverse across the Mushroom Kingdom, overcoming obstacles and adversaries to save Princess Toadstool from the main antagonist, Bowser. Super Mario Bros transformed the world of gaming with its side-scrolling design, and introduced distinctive traits like power-ups in the form of mushrooms and fire-flowers that augment Mario\'s abilities. Precise controls, coupled with creative level design featuring hidden passages and bonus rooms, made the gameplay both challenging and thrilling. In addition to its monumental influence on the side-scrolling genre, Super Mario Bros is acclaimed for its catchy music and vibrant graphics, elements that have become hallmarks of the franchise. The game\'s fascinating universe filled with fantastical creatures and inventive power-ups continues to captivate players. If you\'re a fan of classic platformers like Donkey Kong or Sonic the Hedgehog, you\'ll be a fan of Super Mario Bros.',
        submitted_by: '',
        answers: ["Super Mario Bros", "Super Mario Brothers"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: ["1730146945165865027", "1730091351583154568", "1730000404203602214"],
        release_year: '1985',
        metacritic_score: 'Too Old',
        genre: '2D Platformer',
        console_platform: 'NES, Arcade',
        developer: 'Nintendo Co., Ltd.',
        backloggd_link: '',
    },
    '566': {
        content: 'RollerCoaster Tycoon is a construction and management simulation game developed by Chris Sawyer Productions and published by Hasbro Interactive. Released in 1999, it enables players to design, customize, and manage their very own theme parks. Players are in charge of everything from choosing and placing rides and attractions, to hiring staff and adjusting prices. The gameplay is both strategic and creative, as players must ensure guest satisfaction and park profitability while overcoming various challenges, including landscape restrictions, weather changes, and resource management. A standout feature of the game is the ability to design custom rollercoasters using a simple but comprehensive coaster builder. This feature, combined with the game\'s detailed graphics and complex park management mechanics, captured the hearts of millions. RollerCoaster Tycoon was hailed for its engaging gameplay, depth of control, and freedom of design, making it a classic in the genre. If you\'re a fan of construction and management simulation games like SimCity or Theme Park, you\'ll be a fan of RollerCoaster Tycoon.',
        submitted_by: '',
        answers: ["RollerCoaster Tycoon"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=RollerCoaster%20Tycoon%20Deluxe%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frollercoaster-tycoon-deluxe%2F&intsrc=PUI2_9608", "https://www.g2a.com/rollercoaster-tycoon-deluxe-gogcom-key-global-i10000000653005?gtag=391e4ef696"],
        franchise: 'RollerCoaster Tycoon',
        twitter_ids: ["1730362716827664496"],
        release_year: '1999',
        metacritic_score: 'Too old',
        genre: 'Isometric, Simulation',
        console_platform: 'PC',
        developer: 'Chris Sawyer Productions',
        backloggd_link: '',
    },
    '567': {
        content: 'A Hat in Time is a 3D platform game developed by Gears for Breakfast. Released in 2017, the narrative follows a young girl known as Hat Kid, who stitches hats for wickedly fun abilities and embarks on a journey across time and space to collect missing Time Pieces and prevent universe-altering consequences. The distinctive gameplay is largely reminiscent of Nintendo 64-era 3D platformers. Players leap through vivid, dreamlike landscapes, combat foes with a trusty umbrella, and solve puzzles using a variety of handcrafted hats that impart unique powers including speed-boosts, brewing explosive potions, and even manipulation time. The quirky, vibrant worlds make exploration a utter delight while the time-bending puzzles provide a challenging novelty. Its engaging storyline, vibrant visuals, capricious level design, and innovative puzzle mechanics have garnered universal praise, as did its humor and charm. If youre a fan of whimsical 3D platformers like Super Mario Odyssey or Yooka-Laylee, youll be a fan of A Hat in Time.',
        submitted_by: '',
        answers: ["A Hat in Time"],
        affiliate_links: ["https://www.g2a.com/a-hat-in-time-seal-the-deal-steam-key-global-i10000176021001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '79%',
        genre: 'Platform',
        console_platform: 'PC',
        developer: 'Gears for Breakfast',
        backloggd_link: '',
    },
    '568': {
        content: 'NBA Street is a thrilling street basketball game developed by EA Canada and published by EA Sports BIG. Launched back in 2001, it lets players delve into the exciting world of street basketball, complete with its own unique style and culture. The game presents an edgy, over-the-top take on basketball, set in gritty, urban settings from across the nation. Players can choose from established NBA pros, and create a custom team to compete in three-on-three matches with a focus on flashy, acrobatic maneuvers. This game incorporates players traversing outdoor courts, pulling off extravagant tricks and stylish shots, symbolic of the street-ball culture. The game mechanics depart substantially from conventional basketball rules, offering an arcade-style gameplay that prioritizes style over strategy. Unique elements in the game include a innovative gamebreaker system, where outstanding performances fill a special meter which upon completion enables a near-unstoppable move. NBA Street has been celebrated for its unique, captivating premise, easy-to-learn controls, and engaging gameplay infused with hip-hop culture. If you\'re a fan of arcade-enthused sports games like NFL Street or FIFA Street, you\'ll be a fan of NBA Street.',
        submitted_by: '',
        answers: ["NBA Street"],
        affiliate_links: [],
        franchise: 'NBA Street',
        twitter_ids: ["1731130225369481393"],
        release_year: '2001',
        metacritic_score: '89%',
        genre: 'Sports',
        console_platform: 'PS2',
        developer: 'NuFX, EA Canada',
        backloggd_link: '',
    },
    '569': {
        content: 'Luigi\'s Mansion 3 is an action-adventure game developed by Next Level Games and published by Nintendo for the Nintendo Switch. Released in 2019, the game invites players to explore a haunted hotel, playing as the character Luigi who, equipped with his trusty Poltergust G-00, is on a mission to rescue his friends from the devious ghostly presence that took them hostage during their vacation. The gameplay in Luigi\'s Mansion 3 combines puzzle-solving and action, as players use Luigi and his gooey alter ego Gooigi to overcome obstacles and defeat ghostly enemies. The game\'s charm arises from the unique blend of whimsical humor and suspense and a lively variety of boss-ghost characters, each of which offers a unique battle strategy. Players can explore the haunted hotel\'s many floors, each floor featuring a distinctive theme and set of challenges. The game has been praised for its charming graphics, clever puzzles, engaging boss fights, and a co-op multiplayer mode. If you\'re a fan of comedic yet thrilling adventure games like The Legend of Zelda: Link\'s Awakening or Super Mario Odyssey, you\'ll be a fan of Luigi\'s Mansion 3.',
        submitted_by: '',
        answers: ["Luigi's Mansion 3"],
        affiliate_links: ["https://www.g2a.com/luigis-mansion-3-multiplayer-pack-dlc-nintendo-switch-nintendo-eshop-key-europe-i10000193115002?gtag=391e4ef696"],
        franchise: 'Luigis Mansion',
        twitter_ids: ["1731653493641879887"],
        release_year: '2019',
        metacritic_score: '86%',
        genre: 'Action, Adventure',
        console_platform: 'Switch',
        developer: 'Next Level Games',
        backloggd_link: '',
    },
    '570': {
        content: 'Lego Star Wars II: The Original Trilogy is an action-adventure video game developed by Traveller\'s Tales and published by LucasArts and TT Games. Released in 2006, it offers players the chance to play through all three of the original Star Wars films (Episode IV: A New Hope, Episode V: The Empire Strikes Back, Episode VI: Return of the Jedi), in one epic game - all recreated with a quirky Lego twist. Players can engage in blistering lightsaber battles, participate in space dogfights and more, all while controlling their favorite characters from a galaxy far, far away. With a humorous and light-hearted approach to the beloved Star Wars universe, the game blends the classic Lego video game gameplay with the epic storytelling of the original Star Wars trilogy. The unique Free Play mode allows players to replay levels with any characters they\'ve unlocked, adding immense replayability. If you\'re a fan of the humorous and addictive gameplay of Lego games, or the engaging action and rich storytelling of Star Wars games like Star Wars Jedi: Fallen Order and Star Wars: Knights of the Old Republic, you\'ll be a fan of Lego Star Wars II: The Original Trilogy.',
        submitted_by: '',
        answers: ["Lego Star Wars II: The Original Trilogy", "Lego Star Wars 2"],
        affiliate_links: [],
        franchise: 'LEGO',
        twitter_ids: ["1731852731760189739", "1731690655959699932"],
        release_year: '2006',
        metacritic_score: '81%',
        genre: 'Action, Adventure',
        console_platform: 'PC, Xbox, GameCube, GBA, DS, X360',
        developer: 'Travellers Tales',
        backloggd_link: '',
    },
    '571': {
        content: 'WarioWare: Touched!, released in 2004, is a unique, fast-paced mini-game collection developed and published by Nintendo for the Nintendo DS handheld console. The game puts you into the wacky world of Wario and his gang, where you take on rapid-fire microgames that utilise the DS\'s touchscreen and microphone features. Each microgame lasts only a few seconds and varies significantly, ranging from slicing objects that fly across the screen, to drawing shapes, blowing out virtual candles, or even iconic Nintendo mini-games with a quick twist. The whole array is blended with a comical and often absurd narrative, providing a strange, but delightful gameplay experience. WarioWare: Touched! has gained acclaim for its creativity, the sheer number of its microgames, intuitive controls, and for brilliantly showcasing the new features of the Nintendo DS, setting a benchmark for touchscreen games on the platform. If you\'re a fan of action-oriented mini-game collections like Mario Party or Rhythm Heaven, you\'ll certainly be a fan of WarioWare: Touched!',
        submitted_by: '',
        answers: ["WarioWare: Touched!"],
        affiliate_links: [],
        franchise: 'Wario',
        twitter_ids: ["1732398070090883181", "1732275714039378390"],
        release_year: '2004',
        metacritic_score: '81%',
        genre: 'Action, Rhythm',
        console_platform: 'DS',
        developer: 'Nintendo SPD, Intelligent Systems',
        backloggd_link: '',
    },
    '572': {
        content: 'Borderlands 3 is a first-person shooter game developed by Gearbox Software and published by 2K Games. Released in 2019, players are whisked away into a colorful, post-apocalyptic universe where they take on the roles of \"Vault Hunters\", each with unique abilities and skill trees. The plot focuses on stopping the power-hungry siblings Tyreen and Troy Calypso from harnessing the power of the alien Vaults spread across the galaxy. The gameplay embraces a loot-and-shoot style, where defeating enemies and completing tasks rewards players with myriad gear options including a notably wide array of distinct weapons. In addition to the expansive single-player campaign, Borderlands 3 offers multiplayer mode where players can band together in co-op missions. A hallmark feature of the series is its irreverent humor and vibrant, comic book-inspired art style that sets it apart from other shooter games. With its blend of frenetic gunplay, robust character customization, and addictive loot mechanics, Borderlands 3 has been lauded for its evolution of the franchise\'s adored looter-shooter formula. If you\'re a fan of fast-paced first-person shooters with RPG elements like Destiny or The Division, you\'ll be a fan of Borderlands 3.',
        submitted_by: '',
        answers: ["Borderlands 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Borderlands%203%20Super%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fborderlands-3-super-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/borderlands-3-super-deluxe-edition-steam-gift-global-i10000186970043?gtag=391e4ef696"],
        franchise: 'Borderlands',
        twitter_ids: ["1732559168085561809"],
        release_year: '2019',
        metacritic_score: '81%',
        genre: 'Action, Looter, FPS',
        console_platform: 'PC, PS4, XONE',
        developer: 'Gearbox Software',
        backloggd_link: '',
    },
    '573': {
        content: 'Remnant 2 is an action role-playing game developed by Gunfire Games and published by Perfect World Entertainment. Released in 2023, the game presents players with an apocalyptic world overrun by the monstrous entities known as the Root. Continuing from the narrative unveiled in the first game, Remnant 2 focuses on the survivors of humanity\'s last stronghold as they venture out to reclaim their planet. Gameplay in Remnant 2 is largely built around its engaging combat system that combines gunplay with unique abilities. Players can traverse the game\'s dynamically-generated worlds solo or in co-op mode, facing off against a myriad of challenging enemies and bosses. Remnant 2 offers a rich weapon and gear customization system, allowing players to adapt and enhance their playstyle throughout the game. With its mix of intense third-person shooting, profound RPG elements, and a compelling storyline, Remnant 2 has received praise for having increased world-building, engaging combat, and a multiplayer mode that adds depth to gameplay. If you\'re a fan of action-RPGs with a focus on strategic combat such as Dark Souls or the Monster Hunter series, you\'ll be a fan of Remnant 2.',
        submitted_by: '',
        answers: ["Remnant 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Remnant%202%20Ultimate%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fremnant-2-ultimate-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/remnant-ii-ultimate-edition-pc-steam-key-global-i10000339627009?suid=3b528485-8a50-4d2d-ba65-292f898304eb?gtag=391e4ef696"],
        franchise: 'Remnant',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '80%',
        genre: '3rd-person, Shooter, Action, RPG',
        console_platform: 'PC, PS5, Xbox Series',
        developer: 'Gunfire Games',
        backloggd_link: '',
    },
    '574': {
        content: 'The Forest is a survival horror game developed and published by Endnight Games. This extraordinary title released in 2018 provides a gripping gameplay experience that combines elements of survival, horror, and building in a unique fashion. After surviving a plane crash, players find themselves in a mysterious and haunting forest. Their goal: survive the wilderness with their son missing. Players must scavenge resources to build shelters, craft weapons, and hunt wildlife to ensure their survival. As they explore deeper into the forest, they encounter its cannibalistic inhabitants and are ensnared into the chilling narrative. Under the cover of darkness, these creatures become increasingly aggressive, creating intense nighttime gameplay. Unique to The Forest is its optional cooperative play mode, where the terrifying encounters can be experienced with friends. The combination of resource management, frightening creatures, day-night cycle and base building crafts an immersive, atmospheric gameplay experience that set The Forest apart. If you\'re a fan of survival horror games like Subnautica or Don\'t Starve, or enjoy the thrill of games like Outlast, you\'ll surely be a fan of The Forest.',
        submitted_by: '',
        answers: ["The Forest"],
        affiliate_links: ["https://www.g2a.com/the-forest-steam-key-global-i10000001880007?gtag=391e4ef696"],
        franchise: 'The Forest',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '83%',
        genre: 'Survival horror, survival game',
        console_platform: 'PC',
        developer: 'Endnight Games',
        backloggd_link: '',
    },
    '575': {
        content: 'Company of Heroes is a real-time strategy game set in World War II, developed by Relic Entertainment and published by THQ. Released in 2006, the game takes players on an immersive journey through the chapters of the European theater of WWII, primarily focusing on the Battle of Normandy and the liberation of France. The player commands two U.S. military units in a comprehensive campaign, playing out major battles and crucial engagements from the era. The gameplay of Company of Heroes is particularly characterized by its destructible environments and TrueSight system, a form of fog of war that simulates the units’ line of sight. The player\'s strategy revolves around securing resources spread across the map to build additional units and buildings. This game stands out for its realistic graphical representation, strategic depth, and its historical authenticity. The game\'s dynamic battlefields, commanding strategy, and historical context provide an engaging campaign that fans of the strategy genre appreciate. If you\'re a fan of strategy heavy-hitters like StarCraft or Age of Empires, you\'ll be a fan of the Company of Heroes.',
        submitted_by: '',
        answers: ["Company of Heroes"],
        affiliate_links: ["https://www.g2a.com/company-of-heroes-complete-pack-steam-key-global-i10000043946006?gtag=391e4ef696"],
        franchise: 'Company of Heroes',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '93%',
        genre: 'Real-time Strategy',
        console_platform: 'PC',
        developer: 'Relic Entertainment',
        backloggd_link: '',
    },
    '576': {
        content: 'LittleBigPlanet 2 is a puzzle-platformer game developed by Media Molecule and published by Sony Computer Entertainment for PlayStation 3. Released in 2011, it invites players into a whimsical world of imagination as they take on the role of Sackboy, a charming character made of fabric, on an adventure to save Craftworld from the evil Negativitron. LittleBigPlanet 2 introduces new gameplay mechanics and features to the series, like grappling hooks and power gloves, expanding the player\'s interaction with the game\'s quirky and colorful environments. Apart from the main storyline, players can create their own levels using the game\'s robust level editor, adding another layer of creativity and personalization. This editor not only allows players to build platforming levels but also games of different genres from racing to RPGs. LittleBigPlanet 2 has been praised for its charming aesthetics, inventive gameplay, and empowered user-generated content. It\'s a unique blend of platforming and creative design that offers a different experience with each playthrough. If you\'re a fan of imaginative puzzle-platformers like Super Mario Maker or creative games like Minecraft, you\'ll be a fan of LittleBigPlanet 2.',
        submitted_by: '',
        answers: ["LittleBigPlanet 2"],
        affiliate_links: [],
        franchise: 'LittleBigPlanet',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '91%',
        genre: 'Puzzle, Platformer, sandbox',
        console_platform: 'PS3',
        developer: 'Media Molecule',
        backloggd_link: '',
    },
    '577': {
        content: 'Gran Turismo is a racing game developed by Polyphony Digital and published by Sony Computer Entertainment. Released in 1997, this highly influential title is a realistic driving simulator that put players in the driving seat of a wide range of real-world cars, ranging from everyday city runs to high-powered sports cars. As players race through realistically recreated tracks around the world, the game offers a sophisticated physics engine that accurately models car behaviour, including tire grip and suspension performance. Gran Turismo also features a comprehensive single-player mode, where players earn credits to buy and upgrade cars by winning races and championships. Each car in the game, modeled after their real-world counterparts, has its own unique handling characteristics, introducing a strategic element to car selection and customization. Gran Turismo has been praised for its realism, attention to detail, and breadth of content. It has often been credited with pioneering the car racing simulation genre and popularizing car culture among gamers. If you\'re a fan of realistic driving games like Forza Motorsport or Project Cars, you\'ll be a fan of Gran Turismo.',
        submitted_by: '',
        answers: ["Gran Turismo"],
        affiliate_links: ["https://www.g2a.com/gran-turismo-7-ps5-psn-account-account-global-i10000310285007?suid=785272ae-3451-45c9-9560-78689a95862b?gtag=391e4ef696"],
        franchise: 'Gran Turismo',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: '96%',
        genre: 'Sim racing',
        console_platform: 'PS1',
        developer: 'Japan Studio',
        backloggd_link: '',
    },
    '578': {
        content: 'SSX 3 is an exhilarating snowboarding video game developed by EA Canada and published by EA Sports Big. Hitting the slopes in 2003, players traverse a massive, seamless mountain filled with perilous peaks and treacherous trails. Gamers control a rider who seeks to win a series of events and dominate the slopes, with the goal of ruling the mountain. Gameplay in SSX 3 involves high-speed races, gravity-defying tricks, and dynamic environmental interaction, all of which happen across three different peaks of a gargantuan mountain. The game also introduces a unique \'Big Challenge\' mode, where players can complete various tasks to earn rewards. Apart from snowboarding, SSX 3 offers several styles of play, including racing, freestyle, and free-ride. The game was praised for its graphical finesse, complex trick system, and engrossing single-player mode. Its incorporation of the adrenaline rush of extreme snowboarding with a solid, vibrant soundtrack creates an atmosphere of winter sports ecstasy. If you\'re a fan of extreme sports games such as Tony Hawk\'s Pro Skater or the original SSX series, you\'ll undoubtedly be a fan of SSX 3.',
        submitted_by: '',
        answers: ["SSX 3"],
        affiliate_links: [],
        franchise: 'SSX',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '93%',
        genre: 'Snowboarding, Racing',
        console_platform: 'GameCube, PS2, Xbox',
        developer: 'EA Canada',
        backloggd_link: '',
    },
    '579': {
        content: 'Virtua Tennis is a tennis sports game developed by Sega-AM3 and published by SEGA. Launched in 1999, it provides players with an authentic tennis experience as they compete in various tournaments and matches. Players control their characters on court, showcasing their tennis skills, holding serves, and making calculated shots against opponents in pursuit of victory. The gameplay of Virtua Tennis is incredibly intuitive and approachable, with simple controls that still provide depth for players who want to master their game. Besides the traditional single and multiplayer modes, the Virtua Tennis also includes a notable World Circuit mode, which allows players to undergo training exercises and participate in a series of tournaments, ultimately aiming to be the world champion. The game has been praised for its realistic graphics, seamlessly blending animation with true-to-life physics, which was revolutionary at the time of its release. Moreover, it brought an unprecedented level of realism and immersion into the portrayal of the sport. If you\'re a fan of engaging sports simulations like Top Spin or FIFA series, then Virtua Tennis will definitely serve up to your expectations.',
        submitted_by: '',
        answers: ["Virtua Tennis"],
        affiliate_links: [],
        franchise: 'Virtua Tennis',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: '92%',
        genre: 'Sports game',
        console_platform: 'Arcade',
        developer: 'Sega AM3',
        backloggd_link: '',
    },
    '580': {
        content: 'Quantum Break is an action-adventure game developed by Remedy Entertainment and published by Microsoft Studios. Released in 2016, Quantum Break merges time-shifting mechanics with high-stakes drama as it casts players in the role of protagonist Jack Joyce. After a time travel experiment goes awry, Jack gains the power to manipulate time, setting the stage for intense battles and mind-bending puzzles. The gameplay predominantly features third-person shooting and platformer elements, accentuated by Jack\'s time-bending abilities. Players can freeze time to escape from attacks or halt enemies, creating a dynamic combat system that combines traditional elements with innovative mechanics. Each chapter of the game is concluded with a TV-like episode that displays the consequences of the player\'s choices within the game. This interplay of gameplay and narrative, combined with the signature time manipulation mechanics, lends Quantum Break a unique blend of interactive and cinematic storytelling. Quantum Break has been praised for its original concept, captivating story, and enjoyable combat mechanics. If you\'re a fan of narrative-driven action games like The Last of Us or Alan Wake, you\'ll be a fan of Quantum Break.',
        submitted_by: '',
        answers: ["Quantum Break"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Quantum%20Break%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fquantum-break%2F&intsrc=PUI2_9608", "https://www.g2a.com/quantum-break-pc-steam-gift-global-i10000013874003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '77%',
        genre: 'Action, Adventure,  3rd-person, Shooter',
        console_platform: 'PC, XONE',
        developer: 'Remedy Entertainment',
        backloggd_link: '',
    },
    '581': {
        content: 'Lost Ark is a massively multiplayer online action role-playing game developed by Korean studio Smilegate RPG and published by Amazon Games. The game, released in 2018, sends players on a fantastical quest in the land of Arkesia to find the Lost Ark, which can ward off evil forces threatening the world. Players can choose from diverse classes each possessing unique abilities, adding variety to combat and strategic depth. The gameplay in Lost Ark is a spectacular blend of fast-paced combat and deep RPG mechanics. It excels at delivering large, broad-scale, warfare scenarios and numerous cooperative game modes that players can explore beyond the robust main storyline. The game also features line-of-sight mechanics, adding an extra dimension to boss fights and dungeons. Riotous PvP battles, abundant crafting and gathering options, and vast, breathtakingly beautiful open-world environments make Lost Ark a magnificently rich and immersive MMORPG experience. If you\'re a fan of immersive MMOs like World of Warcraft or Guild Wars 2, or of action RPGs like Diablo, you\'ll be a fan of Lost Ark.',
        submitted_by: '',
        answers: ["Lost Ark"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '81%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Smilegate RPG, Inc., Tripod Studio',
        backloggd_link: '',
    },
    '582': {
        content: 'House Flipper is a unique simulation game developed by Polish indie studio Empyrean and published by Frozen District and PlayWay. Released in 2018, it lets players experience the satisfaction (along with trials and tribulations) of buying, renovating, and selling houses for profit. Players don the role of a one-man renovation crew and perform tasks such as cleaning, installing new appliances, tearing down walls, painting rooms, installing new furniture and decorations, and much more. House Flipper is not just about physical renovation: It is also about tastefully transforming drab properties into dream homes and maximizing their market value. What sets House Flipper apart is its realism and attention to detail, right from the dirt and grime that need to be cleaned to the interior design that appeals to potential buyers. House Flipper offers an enjoyable fusion of manual labor, interior design, and the business acumen needed to succeed in real estate. If you\'re a fan of simulation games like The Sims or business games like Rollercoaster Tycoon, you\'ll definitely enjoy House Flipper.',
        submitted_by: '',
        answers: ["House Flipper"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=House%20Flipper%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhouse-flipper-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/house-flipper-steam-gift-global-i10000155447002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '67%',
        genre: 'Indie, Simulator',
        console_platform: 'PC, Macintosh',
        developer: 'Red Dot Games, Empyrean Games',
        backloggd_link: '',
    },
    '583': {
        content: 'Half-Life is a first-person shooter game developed by Valve, marking their debut in the gaming industry. Released in 1998, it catapults players into the shoes of theoretical physicist Gordon Freeman in the research facility of Black Mesa. During an experiment gone wrong, an alien world known as Xen is accidentally connected to Earth, unleashing a slew of hostile creatures into the facility. The main thrust of the gameplay in Half-Life involves combat and puzzle solving, with a notable absence of cut-scenes, instead using scripted sequences to push the narrative forward. Freeman\'s journey from the bowels of Black Mesa, to the alien world Xen, bristles with the tension of combat, exploration, and survival against overwhelming odds. Its seamless storytelling, rich atmospheric graphics, and immersive world-building were groundbreaking at the time of release, and have since influenced countless other games in the genre. Half-Life’s narrative is distinguished by its delicate blend of horror and action. If you\'re a fan of immersive first-person shooters like Bioshock or action adventure games like System Shock 2, you\'ll be a fan of Half-Life.',
        submitted_by: '',
        answers: ["Half-Life", "Half Life"],
        affiliate_links: ["https://www.g2a.com/half-life-complete-steam-key-global-i10000003198004?gtag=391e4ef696"],
        franchise: 'Half-Life',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '96%',
        genre: 'Adventure, Shooter, Puzzle Elements',
        console_platform: 'PC',
        developer: 'Valve L.L.C.',
        backloggd_link: '',
    },
    '584': {
        content: 'RoboCop: Rogue City is a story-driven, first-person action game developed by Teyon and published by Nacon. Released in 2023, the game let\'s players step into the iconic suit of RoboCop, the cybernetic police officer patrolling the crime-ridden streets of dystopian Detroit. Drawing inspiration from the original RoboCop trilogy, the game delivers a gripping narrative where players are tasked with enforcing the law, facing crime bosses and hordes of thugs while unravelling a plot that threatens the city. RoboCop: Rogue City is largely a shooter, with action packed sequences that blend firearm combat and tactical decision-making. Supporting its lethal arsenal are a range of unique abilities thanks to the RoboCop suit, allowing players to engage in play styles that mix stealth, technology and raw power. With an emphasis on respecting the legacy of the franchise, the game provides an authentic RoboCop experience while also introducing fresh elements to enrich the gameplay. If you\'re a fan of action-packed, story-driven shooter games like Deus Ex or Cyberpunk 2077, you\'ll be a fan of RoboCop: Rogue City.',
        submitted_by: '',
        answers: ["RoboCop: Rogue City"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=RoboCop%20Rogue%20City%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frobocop-rogue-city-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/robocop-rogue-city-pc-steam-gift-global-i10000338930003?gtag=391e4ef696"],
        franchise: 'RoboCop',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '75%',
        genre: 'FPS',
        console_platform: 'PC, PS5, Xbox Series',
        developer: 'Teyon S.A.',
        backloggd_link: '',
    },
    '585': {
        content: 'Cave Story is a platform-adventure game developed, designed, and illustrated by Daisuke Amaya, also known as Pixel. Released in 2005, the game becomes a journey into a magical world of caves as players take on the role of an amnesiac protagonist who wakes up in an unusual labyrinth. The gameplay in Cave Story is primarily based on exploration and platforming, sprinkled with energizing shoot-\'em-up sequences. As the story progresses, players venture further into the increasingly dangerous cave system, discovering unique characters and challenging bosses, all while upgrading guns and gaining new abilities. One of the many highlights of the game lies in its intricate and emotive storyline, taking players on a thrilling ride through hope, despair, and friendship. Cave Story is renowned for its enticing retro graphics, dynamic gameplay, and compelling plot, making it an unforgettable indie game. If you\'re a fan of platformer games with complex narratives like Celeste or Super Meat Boy, you\'ll be a fan of Cave Story.',
        submitted_by: '',
        answers: ["Cave Story", "Doukutsu Monogatari", "Tale of the Cave"],
        affiliate_links: ["https://www.g2a.com/cave-story-steam-key-global-i10000002496003?gtag=391e4ef696"],
        franchise: 'Cave Story',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '93%',
        genre: 'Adventure, Indie, Platform',
        console_platform: 'PC, Macintosh',
        developer: 'Studio Pixel, Nicalis, Inc.',
        backloggd_link: '',
    },
    '586': {
        content: 'X-Squad is a third-person tactical shooter video game developed by EA Games and released in 2000. The game’s story revolves around Ash, the leader of X-Squad, a secret high-tech paramilitary outfit, and his team\'s mission to rescue a scientist and disarm a dangerous weapon. Unique to X-Squad is its team-based gameplay, where players can control a squad of four characters, each with specialized abilities, creating a strategic blend of individual and team-based tactics. A variety of enemy types and scenarios requires players to adapt their strategies on the fly, changing between stealthy infiltration and all-out combat. The game offers several types of weapons and gadgets to aid the team\'s mission. With its intricate comparisons to the popular Rainbow Six series, the game seamlessly melds strategic squad controls with intense third-person shooting. Despite its challenging learning curve, X-Squad\'s well-designed levels and team mechanic have been praised for offering a unique take on the shooter genre. If you\'re a fan of strategic shooters like Ghost Recon or Rainbow Six, you\'ll be a fan of X-Squad.',
        submitted_by: '',
        answers: ["X-Squad", "X Squad"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2000',
        metacritic_score: '64%',
        genre: '3rd-person, Shooter',
        console_platform: 'PS2',
        developer: 'Electronic Arts Square, K.K.',
        backloggd_link: '',
    },
    '587': {
        content: 'Lords of the Fallen is an action role-playing video game developed by Deck13 Interactive and CI Games. Released in 2023, it thrusts players into a dark, medieval world where they play as Harkyn, a convicted criminal. Marked by society, Harkyn is the world\'s last hope against a powerful, seemingly unbeatable god who wages war upon humanity. Gameplay in Lords of the Fallen heavily revolves around tactical melee combat, where every enemy strike can be fatal. Players are thus encouraged to learn and analyze attack patterns, and strike at the crucial moment. Alongside brutal combat, the game also banks on challenging puzzles spread across atmospheric locations. Lords of the Fallen is known for its high difficulty, tight combat mechanics, and intricate RPG elements including a complex system of combat magic, a multitude of weapons, and diverse upgrading paths. It has been praised for its detailed world-building, compelling narrative, and intricately designed opponents. If you\'re a fan of challenging, combat-oriented RPGs like Dark Souls or The Witcher, you\'ll be a fan of Lords of the Fallen.',
        submitted_by: '',
        answers: ["Lords of the Fallen"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Lords%20of%20the%20Fallen%20Deluxe%20Edition%202023%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Flords-of-the-fallen-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-lords-of-the-fallen-deluxe-edition-pc-steam-gift-global-i10000336739010?gtag=391e4ef696"],
        franchise: 'Lords of the Fallen',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '75%',
        genre: 'Adventure, RPG, 3rd-person',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'Hexworks',
        backloggd_link: '',
    },
    '588': {
        content: 'Fire Emblem Heroes is a tactical role-playing game developed by Intelligent Systems and published by Nintendo. It was first released in 2017 as part of the historic Fire Emblem franchise. In this game, players traverse through a world of interconnecting realms as they engage in strategic, turn-based battles featuring heroes from various Fire Emblem games. Battles take place on a grid-based battlefield, where strategy and careful planning are key to emerging victorious. Like other Fire Emblem games, Heroes maintains the series\' core mechanics like the rock-paper-scissors inspired weapon triangle system. The characters, or \'Heroes,\' in the game can be enhanced and evolved, driving you to optimize your team\'s strength based on their unique attributes. Moreover, Fire Emblem Heroes, designed for mobile devices, also includes elements of gacha game mechanics, where players can spend in-game currency for a chance to acquire new heroes. Celebrated for its strategy-based gameplay, wealth of characters, and deep connectivity to the Fire Emblem universe, this game is a must-play for series veterans and novices alike. If you\'re a fan of tactical role-playing games like Final Fantasy Tactics or War of the Visions: Final Fantasy Brave Exvius, you\'ll be a fan of Fire Emblem Heroes.',
        submitted_by: '',
        answers: ["Fire Emblem Heroes"],
        affiliate_links: [],
        franchise: 'Fire Emblem',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '72%',
        genre: 'RPG, Turn-Based Strategy',
        console_platform: 'Mobile',
        developer: 'Intelligent Systems Co., Ltd.,  Nintendo EPD, DeNA Co., Ltd.',
        backloggd_link: '',
    },
    '589': {
        content: 'theHunter: Call of the Wild is an immersive hunting simulation game developed by Expansive Worlds and published by Avalanche Studios. Released in 2017, the game thrusts players into the heart of the wilderness, where they must rely on their wits and sharpshooting skills to hunt a variety of animals, from deer to bears. The game offers a vast, open-world environment filled with stunning natural beauty, set across multiple reserves each with their distinct flora and fauna. Heightening the sense of realism, the game has a dynamic day-night cycle and weather system, which affects animal behavior. Gameplay in theHunter: Call of the Wild primarily focuses on tracking and hunting animals, using a combination of stealth, strategy, and precision. Players earn points and level up by successfully completing hunts, which allows them to unlock better equipment and techniques, truly solidifying the sense of progression. This game delivers an exceptional level of detail, employing lifelike animal AI and requiring a skilled understanding of tracking and positioning for successful hunts. If you\'re a fan of realistic hunting games like Deer Hunter or open-world survival games like Far Cry, you\'ll be a fan of theHunter: Call of the Wild.',
        submitted_by: '',
        answers: ["theHunter: Call of the Wild"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=theHunter%20Call%20of%20the%20Wild%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthehunter-call-of-the-wild%2F&intsrc=PUI2_9608", "https://www.g2a.com/thehunter-call-of-the-wild-steam-key-global-i10000034090008?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '64%',
        genre: 'Shooter, Simulator, Sport',
        console_platform: 'PC, PS4, XONE',
        developer: 'Expansive Worlds AB',
        backloggd_link: '',
    },
    '590': {
        content: 'Super Mario Bros. Wonder is an exciting platform game developed and published by Nintendo. Released in 2023, this game continues the globally beloved Super Mario franchise, thrusting players once again into the vibrant Mushroom Kingdom as Mario and Luigi, in a quest to rescue Princess Peach from the devious Bowser. Gameplay in Super Mario Bros. Wonder consists of running, jumping, and battling through a range of richly decorated levels filled with hazards, hidden passages, and classic foes. In a tickle to veteran players, the game introduces exciting power-ups that enhance the Super Mario Bros. experience, hence the title \'Wonder\'. These power-ups give Mario and Luigi new abilities, adding an extra layer of strategy to the gameplay. Persistent exploration and collection of these powers add to the replay value of the game. Coupled with visually stunning graphics, Super Mario Bros. Wonder succeeds in bringing the charm of the old Mario world to modern gaming, making it a monumental success for the series. The game has been lauded for its fantastic level design, innovative power-ups, and its marriage of nostalgia and innovation. If you\'re a fan of vibrant, adventure-filled platform games like Sonic The Hedgehog or Donkey Kong, you\'ll be a fan of Super Mario Bros. Wonder.',
        submitted_by: '',
        answers: ["Super Mario Bros. Wonder", "Super Mario Brothers Wonder"],
        affiliate_links: ["https://www.g2a.com/super-mario-bros-wonder-nintendo-switch-nintendo-eshop-key-north-america-i10000501236002?gtag=391e4ef696"],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '92%',
        genre: 'Platform',
        console_platform: 'Switch',
        developer: 'Nintendo LLC',
        backloggd_link: '',
    },
    '591': {
        content: 'Hellblade: Senua\'s Sacrifice is a dark fantasy action-adventure game, developed and published by Ninja Theory. The game follows the harrowing journey of Senua, a Pict warrior tormented by psychosis, as she ventures into a hellish underworld inspired by Norse mythology and Celtic culture to rescue the soul of her dead lover from the goddess Hela. Emphasizing strong narrative and character-driven experience, the game presents intense combat sequences along with unique puzzle-solving elements throughout the exploration of its atmospheric world. Hellblade: Senua\'s Sacrifice incorporates a compelling system of auditory hallucinations and visual distortions to represent Senua\'s mental health struggles, introducing players to her immersive internal battles. The game\'s unflinching portrayal of psychosis, backed by research and consultation with neuroscientists and people experiencing psychosis, has been widely praised. Rich in symbolic narratives, Hellblade: Senua\'s Sacrifice sets a high standard for exploring mental health themes in gaming. Should you be a fan of narrative heavy action-adventure games like The Last of Us or God of War, there\'s a high chance you\'ll be taken by Hellblade: Senua\'s Sacrifice.',
        submitted_by: '',
        answers: ["Hellblade: Senua's Sacrifice"],
        affiliate_links: ["https://www.g2a.com/hellblade-senuas-sacrifice-steam-gift-pc-global-i10000074465004?gtag=391e4ef696"],
        franchise: 'Hellblade',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '83%',
        genre: 'Adventure, Action',
        console_platform: 'PC, PS4, then later switch & xbox',
        developer: 'Ninja Theory Ltd.',
        backloggd_link: '',
    },
    '592': {
        content: 'Resistance 3 is a science fiction first-person shooter developed by Insomniac Games and published by Sony Computer Entertainment. Released in 2011, it immerses players in an alternative 1950s universe where humanity is on the brink of extinction, courtesy of an alien race known as Chimera. The game casts players as Joseph Capelli, a former Sentinel forced out of hiding to journey across the devastated United States, with the mission to deliver a fatal blow to the occupying Chimeran force. In Resistance 3, fast-paced combat is mixed with a ravaging narrative as players use a vast arsenal of human and Chimeran weapons, along with a variety of special abilities. The game also introduces a new weapon upgrade system and features both online and offline multiplayer modes. Its notable environmental design, intense combat sequences, and compelling storyline make it a standout in the genre. Resistance 3 has been lauded for its solid gameplay, immersive story, and impressive visual design. If you\'re a fan of intense first-person shooter games like Call of Duty or Battlefield, you\'ll be a fan of Resistance 3.',
        submitted_by: '',
        answers: ["Resistance 3"],
        affiliate_links: [],
        franchise: 'Resistance',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '83%',
        genre: 'FPS',
        console_platform: 'PS3',
        developer: 'Insomniac Games, Inc.',
        backloggd_link: '',
    },
    '593': {
        content: 'Homeworld: Cataclysm, released in 2000, is a real-time strategy game developed by Barking Dog Studios and published by Sierra Studios. It is set in the Homeworld universe and depicts the desperate struggle of the Kiith Somtaaw, a clan of the Kushan peopl, as they face the arrival of an unstoppable bio-mechanical plague named The Beast. The gameplay in Homeworld: Cataclysm features real-time space combat emphasizing three-dimensional maneuvering of large fleets. It introduces new gameplay mechanics like new unit types, an improved interface, and upgradeable motherships to the original Homeworld\'s formula, while maintaining the epic scope and sense of discovery that made the original standout. The story unfolds through in-engine cinematics and voice-over narration during gameplay, which enhances its immersive narrative. The atmospheric music and intricate 3D spaceship designs, along with the rich storyline, contribute to its praise for an engrossing space opera experience. Homeworld: Cataclysm has been lauded for its deep strategic gameplay, rich ambience, and engaging plot. If you\'re a fan of space strategy games like StarCraft or the original Homeworld, you\'ll undoubtedly be a fan of Homeworld: Cataclysm.',
        submitted_by: '',
        answers: ["Homeworld: Cataclysm", "Homeworld: Emergence", "Homeworld"],
        affiliate_links: [],
        franchise: 'Homeworld',
        twitter_ids: ["1740459797600948484"],
        release_year: '2000',
        metacritic_score: '89%',
        genre: 'Adventure, Real Time Strategy',
        console_platform: 'PC',
        developer: 'Barking Dog Studios, Ltd.',
        backloggd_link: '',
    },
    '594': {
        content: 'Cities: Skylines II is a city-building simulation game developed by Colossal Order and published by Paradox Interactive. Launched in 2023, the game allows players to build and manage their own sprawling metropolis from scratch. Players take on the role of mayor and must oversee the growth and maintenance of their city whilst managing resources and needs of the population. The game presents myriad challenges including balancing budget, infrastructure, and policies, as well as dealing with real-world issues such as traffic congestion and environmental pollution. With its comprehensive and intricate systems for city-planning from zoning to public services, Cities: Skylines II gives players a complete control of customizing their city to their liking. Plus, it introduces new challenges and opportunities such as advanced technology, eco-friendly options, and upgraded graphics, making city-building more complex and realistic than ever before. Cities: Skylines II has been praised for its detailed and realistic approach, pushing the boundaries of city-building simulations. If you\'re a fan of intricate simulation games like SimCity or Planet Coaster, you\'ll be a fan of Cities: Skylines II.',
        submitted_by: '',
        answers: ["Cities: Skylines II"],
        affiliate_links: ["https://www.g2a.com/cities-skylines-ii-ultimate-edition-pc-steam-key-global-i10000339650005?suid=cdcc4e4a-3a6e-424c-8d21-9b2b8e8cc71c?gtag=391e4ef696"],
        franchise: 'Cities: Skylines',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '75%',
        genre: 'Simulation',
        console_platform: 'PC',
        developer: 'Colossal Order Ltd.',
        backloggd_link: '',
    },
    '595': {
        content: 'Boneworks is a virtual reality (VR) first-person-shooter developed and published by Stress Level Zero. Released in 2019, it challenges players with its deep physics-based combat system. Players assume the role of Ford, an operator in a virtual world. He needs to locate and destroy the Boneworks, a mythical device perceived to grant immortality. The game distinguishes itself with its extensive physical interactions, emulating realistic mechanic in a VR space. Weapons recoil, objects have weight, and players can even climb up structures. Unlike many other VR games, Boneworks doesn\'t hold back on complexity, forcing players to consider physics and momentum during combat and puzzle-solving. The game introduces a sandbox mode alongside the main campaign, allowing players to toy around freely with the impressive physics mechanics. Boneworks has been hailed for its ground-breaking approach to VR gameplay, outstanding immersion, and its commitment to creating a believable and consistent world governed by physics. If you\'re a fan of immersive VR games like Half-Life: Alyx or The Walking Dead: Saints & Sinners, you\'ll be a fan of Boneworks.',
        submitted_by: '',
        answers: ["Boneworks"],
        affiliate_links: ["https://www.g2a.com/boneworks-steam-gift-global-i10000192687002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '72%',
        genre: 'FPS, puzzle elements',
        console_platform: 'PC, VR',
        developer: 'Stress Level Zero',
        backloggd_link: '',
    },
    '596': {
        content: 'Ninja Blade is an action-adventure game developed by FromSoftware and published by Microsoft Game Studios. Set in a modern-day Tokyo overrun by parasites causing a fatal disease, it was released in 2009 and has the players assume the role of Ken Ogawa, an expert ninja, part of an international team deployed to combat this biohazard in the city. Throughout the game, Ken embarks on a mission filled with constant action, facing hordes of infected creatures and powerful bosses. Gameplay in Ninja Blade is characterized by its combination of fast-paced combat and platforming, where players use an arsenal of weapons and ninja abilities. The game also introduces various moments known as todome, cinematic quick time events where players must carry out a series of actions within a limited time. These moments are significant in intensifying the game\'s action sequences and making battles with enemies more intense and front-line. If you\'re a fan of action and ninja games like Ninja Gaiden or hack & slash games like Devil May Cry, you\'ll be a fan of Ninja Blade.',
        submitted_by: '',
        answers: ["Ninja Blade"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Ninja%20Blade%20Steam%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fninja-blade-steam%2F&intsrc=PUI2_9608", "https://www.g2a.com/ninja-blade-steam-gift-global-i10000045432001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '68%',
        genre: 'Action',
        console_platform: 'X360',
        developer: 'FromSoftware',
        backloggd_link: '',
    },
    '597': {
        content: 'Advance Wars is a tactical turn-based strategy game developed by Intelligent Systems and published by Nintendo. Released in 2001 for the Game Boy Advance, Advance Wars transports players to the fictional continent of Wars World, where they lead the Orange Star Army in a war against rival nations. Players hop into the shoes of an advisor and guide their army, which can include infantry, tanks, and different types of aircraft, in missions with various strategic objectives to accomplish. The mechanics of gameplay involve directing military units on grid-based maps, utilizing terrain advantages, and managing resources to outthink and outmaneuver enemy forces. Apart from its engaging story-driven Campaign Mode, Advance Wars also offers additional modes like War Room, VS and Design Maps for customized battles. The game stands out for its cheerful art style juxtaposed with deep strategic gameplay, offering an intriguing mix of light-hearted visuals and heavy tactical battles. It has been applauded for its depth, replayability and multiplayer features. If you\'re a fan of strategic turn-based games like Fire Emblem or Battle for Wesnoth, you\'ll be a fan of Advance Wars.',
        submitted_by: '',
        answers: ["Advance Wars"],
        affiliate_links: [],
        franchise: 'Wars',
        twitter_ids: [],
        release_year: '2001',
        metacritic_score: '92%',
        genre: 'Turn-based strategy',
        console_platform: 'GBA',
        developer: 'Intelligent Systems',
        backloggd_link: '',
    },
    '598': {
        content: 'Halo 3 is a first-person shooter game developed by Bungie and published by Microsoft Game Studios. Unleashed in 2007, it marks the climactic end of the original Halo trilogy where players resume the role of the Master Chief, a super-soldier equipped to prevent the onslaught led by the alien coalition known as the Covenant. With humanity\'s survival hanging by a thread, Master Chief\'s mission navigates him across an array of epic battlefields, with both on-foot shooting action and vehicular combat. Halo 3 builds on its predecessors by introducing new gameplay elements such as equipment items that can be deployed for defensive maneuvers or to augment attacks. Additionally, it features groundbreaking multiplayer modes engaging players in competitive or cooperative play, and an innovative Forge toolset that allows players to create their own maps, fostering a vibrant online community. Halo 3 has been universally praised for its engaging storyline, immersive environments, and revolutionary multiplayer experience. If you\'re a fan of sci-fi first-person shooters like Destiny or Gears of War, you\'ll be a fan of Halo 3.',
        submitted_by: '',
        answers: ["Halo 3"],
        affiliate_links: ["https://www.g2a.com/halo-3-pc-steam-gift-global-i10000206398001?gtag=391e4ef696"],
        franchise: 'Halo',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '94%',
        genre: 'First-person shooter',
        console_platform: 'X360',
        developer: 'Bungie',
        backloggd_link: '',
    },
    '599': {
        content: 'Yakuza: Like a Dragon is an action role-playing game developed and published by Sega. Released in 2020, the game takes a dramatic turn from the series\' traditional beat \'em up style, venturing into turn-based RPG territory while retaining the rich narrative and vibrant world of its predecessors. Players step into the shoes of Ichiban Kasuga, a loyal yakuza member betrayed by his own family and left for dead, embarking on a complex journey of redemption and survival on the dangerous streets of Yokohama. In contrast to previous titles, Yakuza: Like a Dragon adopts traditional RPG elements including a robust leveling system, job classes system, and strategic turn-based battles. Despite the major transformation in its gameplay model, the game maintains the series\' hallmark elements like side quests, mini-games, and the exploration of densely populated cityscapes rich in detail and personality. If you\'re a fan of action-adventure games with heavy RPG elements like Sleeping Dogs or Saints Row, or enjoy traditional RPGs with immersive storytelling such as Final Fantasy, you\'ll be a fan of Yakuza: Like a Dragon.',
        submitted_by: '',
        answers: ["Yakuza: Like a Dragon", "Yakuza 7"],
        affiliate_links: ["https://www.g2a.com/yakuza-like-a-dragon-pc-steam-key-global-i10000206484035?gtag=391e4ef696"],
        franchise: 'Yakuza',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '84%',
        genre: 'RPG',
        console_platform: 'PS4',
        developer: 'Ryu Ga Gotoku Studio',
        backloggd_link: '',
    },
    '600': {
        content: 'Dead or Alive is a fighting game developed by Team Ninja and published by Tecmo. Initially released in 1996, Dead or Alive invites players into fast-paced combat in a 3D environment, testing their reflexes and precision in this high-stakes world of the finest martial artists. Players take control of a range of characters, each boasting their unique fighting styles and special moves. The game shines with its strategic depth, where the combat system brims with holds, throws, and strikes, and where the right defensive move can turn the tide of a match. Also, its interactive stages can be used to inflict additional damage on opponents. Dead or Alive is well-known for its in-depth counter system and its female fighters. These elements, combined with the game\'s fluid movement and flashy graphics, make for a unique and engaging fighting experience. Dead or Alive has been lauded for its fast gameplay, the strategic depth of its fighting system, and the variety offered by its character roster. If you\'re a fan of 3D fighting games like Tekken or Virtua Fighter, you\'ll be a fan of Dead or Alive.',
        submitted_by: '',
        answers: ["Dead or Alive"],
        affiliate_links: [],
        franchise: 'Dead or Alive',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: '84%',
        genre: 'Fighting',
        console_platform: 'Arcade',
        developer: 'Team Ninja',
        backloggd_link: '',
    },
    '601': {
        content: 'Assassin\'s Creed: Brotherhood is an action-adventure game developed by Ubisoft Montreal and published by Ubisoft. As the direct sequel to Assassin\'s Creed II, this 2010 release enables players to once again navigate the historical world of Renaissance Italy as master assassin, Ezio Auditore. The heart of the gameplay in Brotherhood centers around the rich tapestry of Rome as the main hub, where Ezio can recruit and train new assassins, manage his brotherhood, and take on the powerful Templar Order. The game introduces a novel feature in the form of multiplayer mode, marking its standout presence in the Assassin\'s Creed series. Players experience rich, intense combat and stealth mechanics, seamless parkour, and intricate puzzles, all set within meticulously crafted environments. Besides, the unravelling of the captivating story toggling between past and present defines the essence of the gameplay. Assassin\'s Creed: Brotherhood has been greatly appreciated for providing a deeper dive into Ezio\'s story, multiplayer introduction, and the expanded open-world experience. If you\'re a fan of historical action-adventure games like The Witcher or stealth games like Dishonored, you\'ll be a fan of Assassin\'s Creed: Brotherhood.',
        submitted_by: '',
        answers: ["Assassin's Creed: Brotherhood"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Assassins%20Creed%20Brotherhood%20NEW%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-brotherhood-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/assassins-creed-brotherhood-steam-gift-global-i10000043255002?gtag=391e4ef696"],
        franchise: 'Assassins Creed',
        twitter_ids: ["1743062077579940122"],
        release_year: '2010',
        metacritic_score: '89%',
        genre: 'Action-adventure, Stealth',
        console_platform: 'PS3, X360',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '602': {
        content: 'Rain World is a survival platformer game developed by Videocult and published by Adult Swim Games. Released in 2017, it drops players into the role of a slugcat, a nimble creature navigating a harsh, decaying biosphere following an apocalyptic event. In Rain World, players must hunt for food and seek shelter before the deadly downpours begin, all while avoiding a plethora of predatory creatures. Gameplay is characterized by real-time simulation, where every creature follows its own survival instincts in a dynamically evolving ecosystem. Players must learn to understand these creatures and their behaviors to survive while adapting to the ever-changing environment. In addition to its intense survival gameplay, Rain World is recognised for its distinctive pixel art graphics, procedurally generated maps offering vast replayability, and a melancholic, atmospheric soundtrack. It doesn\'t just pose a challenge, but also tells an evocative story of survival amid desolation. Rain World has been commended for its deep aspect of gameplay, innovative mechanics, and the thought-provoking themes it explores. If you\'re a fan of survival-based platformers like Limbo and Ori and the Blind Forest, or enjoy challenging roguelike mechanics as found in Hollow Knight, you\'ll be a fan of Rain World.',
        submitted_by: '',
        answers: ["Rain World"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Rain%20World%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Frain-world-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/rain-world-pc-steam-account-global-i10000035262006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '59%',
        genre: 'Platform, survival',
        console_platform: 'PC, PS4',
        developer: 'Videocult',
        backloggd_link: '',
    },
    '603': {
        content: 'Muse Dash is a rhythm game developed and published by PeroPeroGames. Debuted in 2018, the game embarks players on an adrenaline-pumping, music-filled adventure as they smash enemies to the beat. Designed in aesthetic Anime style, the game introduces to players a simple one-button gameplay with a stunning and visually appealing environment that\'s heavily influenced by Japanese pop culture. With both rhythm and action game elements, players must hit enemies as they appear on screen according to the beat of the music, adding a layer of action to the traditional rhythm gameplay while also rewarding precise timing. The game boasts a broad music library with over 80 original songs that cover a diverse array of genres, from pop to rock to electro, providing players with a robust and engrossing musical experience in each level. Muse Dash has been lauded for its thrilling rhythm-based gameplay, rich soundtracks, and captivating visuals. If you\'re a fan of rhythm games like Crypt of the NecroDancer or action-packed runners like Bit.Trip Runner, you\'ll be a fan of Muse Dash.',
        submitted_by: '',
        answers: ["Muse Dash"],
        affiliate_links: ["https://www.g2a.com/muse-dash-steam-gift-global-i10000188869002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '73%',
        genre: 'Rhythm',
        console_platform: 'Mobile',
        developer: 'PeroPeroGames',
        backloggd_link: '',
    },
    '604': {
        content: 'PlanetSide 2 is a massively multiplayer online first-person shooter game developed by Daybreak Game Company. Released in 2012, players are plunged into a persistent world at war, dropping into the planets of Auraxis where three factions vie for control of territory and resources. As a soldier for either the Terran Republic, New Conglomerate, or Vanu Sovereignty, you\'re thrust into epic, player-driven battles with the ultimate aim of securing your faction\'s sovereignty. Gameplay in PlanetSide 2 is massive in scale, combining first-person shooter elements with large scale, strategic territory control mechanics. The persistent game world fosters continuous, raging battles, with the capacity to host hundreds of players in a single conflict. Its unique use of combined arms warfare, with air and ground vehicles playing crucial roles in the warzone, is a differentiating aspect of the game. PlanetSide 2 has been praised for its groundbreaking scope, complex gameplay, and the frantic intensity of its large-scale battles. If you\'re a fan of large scale multiplayer games like Battlefield or Destiny, you\'ll definitely be a fan of PlanetSide 2.',
        submitted_by: '',
        answers: ["PlanetSide 2"],
        affiliate_links: [],
        franchise: 'PlanetSide',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '84%',
        genre: 'Massively multiplayer online first-person shooter game',
        console_platform: 'PC',
        developer: 'Rogue Planet Games',
        backloggd_link: '',
    },
    '605': {
        content: 'Resident Evil Village is a survival horror video game developed and published by Capcom. Launched in 2021, it is the eighth installment in the iconic Resident Evil series. The game carries forward the story of protagonist Ethan Winters from Resident Evil 7, as he finds himself in a terrifying village filled with monstrous creatures while searching for his kidnapped daughter. Gameplay in Resident Evil Village is deeply immersive, combining first-person perspective exploration with intense combat and puzzle solving. Players interact with a variety of characters, both familiar faces and new introductions, all while trying to survive in the hostile environment. The village itself serves as a central hub with multiple routes filled with horrifying enemies and unlockable treasures. The game also introduces the Hunting system, where players can hunt specific animals in the village and cook meals to improve Ethan\'s abilities. One standout feature is the larger-than-life antagonist, Lady Dimitrescu, who has garnered significant player attention. Resident Evil Village is lauded for its atmospheric storytelling, engaging gameplay, and perfect blend of action and horror. If you\'re a fan of survival horror games like Outlast or Amnesia: The Dark Descent, you\'ll be a fan of Resident Evil Village.',
        submitted_by: '',
        answers: ["Resident Evil Village", "Resident Evil 8"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Resident%20Evil%20Village%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fresident-evil-village-deluxe-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/resident-evil-8-village-gold-edition-pc-steam-gift-global-i10000233107031?gtag=391e4ef696"],
        franchise: 'Resident Evil',
        twitter_ids: ["1744865713582715243", "1744531656776532449"],
        release_year: '2021',
        metacritic_score: '84%',
        genre: 'Survival horror',
        console_platform: 'PS4, PS5, Stadia, PC, XONE, Xbox Series',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '606': {
        content: 'Prince of Persia: Warrior Within is an action-adventure video game developed by Ubisoft Montreal and published by Ubisoft. Released in 2004, the game immerses players in the dark underworld of the Prince, the protagonist from the previous game, who is hunted by a supernatural creature due to his actions in altering time. Players find themselves in a harsh, brutal world filled with nightmarish landscapes, rogue assassins, and demonic enemies. The gameplay incorporates a more aggressive combat system and introduces new acrobatics abilities allowing the Prince to traverse the environment in fluid and impressive ways. One of the game\'s unique features is the time-control mechanics, where players can slow or reverse time, offering a unique approach to both combat and puzzle-solving. Players must use their wits, agility and deadly arsenal of weapons to reclaim their fate and defy the timeline that’s foretold their own death. Prince of Persia: Warrior Within is highly regarded for its gritty tone, intricate combat system, and innovative time-control mechanics. If you’re a fan of action-adventure games like Assassin\'s Creed or Tomb Raider that offer a blend of combat, exploration, and puzzles, you\'ll be a fan of Prince of Persia: Warrior Within.',
        submitted_by: '',
        answers: ["Prince of Persia: Warrior Within"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Prince%20of%20Persia%20The%20Warrior%20Within%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fprince-of-persia-the-warrior-within%2F&intsrc=PUI2_9608", "https://www.g2a.com/prince-of-persia-warrior-within-pc-gogcom-key-global-i10000007711005?gtag=391e4ef696"],
        franchise: 'Prince of Persia',
        twitter_ids: ["1745179425719701630", "1744966304711180687"],
        release_year: '2004',
        metacritic_score: '83%',
        genre: 'Action, Adventure, Platformer, hack and slash',
        console_platform: 'GameCube, PS2, PC, Xbox, Mobile',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '607': {
        content: 'Dungeon Keeper is a strategy game developed by Bullfrog Productions and published by Electronic Arts. Released in 1997, the game flips the script on typical fantasy narratives by letting players not only design, but also master and maintain their own sinister dungeon, full of traps and minions, all to keep invading do-gooders at bay. As the titular Dungeon Keeper, players can construct and customize their dungeons, recruit and train various mythical creatures to defend their territory, and furtively sabotage the virtuous heroes who dare to invade. The gameplay features a blend of real-time strategy and god game elements, with players managing resources, creatures and dungeon layouts in real-time while also gaining new spells and abilities that allow for strategic manipulation of the game world and its heroes. Dungeon Keeper has been praised for its darkly humorous take on traditional fantasy tropes, its unique blend of with its blend of strategy and simulation, and memorable gameplay mechanics. If you\'re a fan of unusual strategy games like Evil Genius or Theme Hospital, you\'ll be a fan of Dungeon Keeper.',
        submitted_by: '',
        answers: ["Dungeon Keeper"],
        affiliate_links: ["https://www.g2a.com/dungeon-keeper-gold-gogcom-key-global-i10000051402001?gtag=391e4ef696"],
        franchise: 'Dungeon Keeper',
        twitter_ids: ["1745273306201092596"],
        release_year: '1997',
        metacritic_score: 'Not Rated',
        genre: 'Real-time Strategy, God, Dungeon Management',
        console_platform: 'PC',
        developer: 'Bullfrog Productions',
        backloggd_link: '',
    },
    '608': {
        content: 'Gravity Rush 2 is an action-adventure game developed by Project Siren and published by Sony Interactive Entertainment for PlayStation 4. Picking up where its predecessor left off, this 2017 release thrusts players back into the enchanting, gravity-defying life of Kat, a woman who wields the power to manipulate gravity, exploring new locations and meeting new characters in the floating city of Hekseville. The unique physics-based mechanics allow players to alter the pull of gravity at will, using it to move through environments, avoid attacks, or execute powerful combat sequences. The world expands beyond the floating neighbourhoods seen in Gravity Rush, unveiling more intricate cities and locales. The game encourages exploration, reward curiosity with power-ups and upgrades scattered throughout the world. With its unique gravity-defying mechanics, beautifully animated graphics, and an engaging storyline that continues Kats adventure, Gravity Rush 2 has been lauded for its immersive gameplay and captivating world. If youre a fan of action-adventure games like infamous Second Son or Yakuza, youll be a fan of Gravity Rush 2.',
        submitted_by: '',
        answers: ["Gravity Rush 2"],
        affiliate_links: ["https://www.g2a.com/gravity-rush-2-ps4-psn-account-account-global-i10000035191003?gtag=391e4ef696"],
        franchise: 'Gravity Rush',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '80%',
        genre: 'Action, Adventure',
        console_platform: 'PS4',
        developer: 'Japan Studio',
        backloggd_link: '',
    },
    '609': {
        content: '',
        submitted_by: '',
        answers: ["Lethal Company"],
        affiliate_links: ["https://www.g2a.com/lethal-company-pc-steam-gift-global-i10000501351002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: 'No score yet',
        genre: 'Adventure, Indie',
        console_platform: 'PC',
        developer: 'Zeekerss',
        backloggd_link: '',
    },
    '610': {
        content: 'Outlast is a first-person survival horror game developed and published by Red Barrels. Released in 2013, it immerses players in the chilling and haunted halls of Mount Massive Asylum, playing as a journalist named Miles Upshur, who finds himself caught up in nightmarish events beyond his control while investigating a tip about inhumane experiments. The gameplay in Outlast revolves around stealth and situational awareness, as the players have no means to fight off the deranged inmates and can only run, hide, or die. The integrated night vision mode on the player\'s camcorder can help navigate the darker corners and corridors of the asylum but use it sparingly as battery life is limited. Its realistic portrayal of terror, combined with limited resources, enhances the suspense and dread encountered throughout gameplay. Outlast has been well received for its vivid graphics, terrifying atmosphere, and its unique, up-close-and-personal approach to horror. If you\'re a fan of survival horror games like Amnesia: The Dark Descent or Alien: Isolation, you\'ll be a fan of Outlast.',
        submitted_by: '',
        answers: ["Outlast"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Outlast%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Foutlast-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/outlast-outlastwhistleblower-steam-key-global-i10000001003002?gtag=391e4ef696"],
        franchise: 'Outlast',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '80%',
        genre: 'Adventure, Indie',
        console_platform: 'PC, PS4, XONE',
        developer: 'Red Barrels Inc.',
        backloggd_link: '',
    },
    '611': {
        content: 'Starfield is a single-player first-person sci-fi RPG developed by Bethesda Game Studios and published by Bethesda Softworks. Released in 2023, Starfield invites players to traverse the star-studded expanses of space, exploring a vast, immersive universe rich with galaxies, planets, and alien civilizations. The narrative centers around the player character, a member of Constellation, the last group of space explorers. It integrates dialogue-driven quests with open-world elements to create a broad, meaningful narrative. The gameplay in Starfield offers deep customization options for character creation, spaceship modification, and planetary exploration to interact with alien species, explore mysterious ruins, and face various interstellar threats. Renowned for its beautifully rendered universe meticulously crafted, innovative alien designs, and a dynamic physics-based flight model, the game creates a sense of living in a fully realized, explorable universe. This title has been lauded for its expansive world-building, engaging plot, and the depth of its gameplay. If you\'re a fan of open-world RPG games like Elder Scrolls V: Skyrim or space exploration games like No Man\'s Sky, you\'ll be a fan of Starfield.',
        submitted_by: '',
        answers: ["Starfield"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Starfield%20Premium%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstarfield-premium-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/starfield-premium-edition-preorder-bonus-pc-steam-key-global-i10000280040022?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1746674594184892526", "1746668831471223040"],
        release_year: '2023',
        metacritic_score: '83%',
        genre: 'Action, RPG',
        console_platform: 'PC, Xbox Series',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '612': {
        content: 'Valiant Hearts: The Great War is a puzzle adventure game developed by Ubisoft Montpellier and published by Ubisoft. Released in 2014, the game plunges players into the heart-wrenching realities of World War I as they control four characters linked by a loyal canine companion amidst the chaos of the trenches. Each character brings a unique skillset to solve puzzles and navigate through the war-torn scenery, from a burly prisoner of war with brute strength to a crafty American soldier with sneaky tactics. Valiant Hearts stands out for its historical authenticity, drawing inspiration from real letters written during the war, and balancing the grim realities of conflict with moments of poignant humanity. The vibrant hand-drawn art style starkly contrasts the brutal setting, adding another layer of emotional impact to the game. Additionally, its innovative puzzle mechanics mix seamlessly with the narrative, creating a compelling gameplay experience. The game has been universally acclaimed for its stunning visuals, powerful storytelling, and captivating characters. If you\'re a fan of emotive narrative-driven games like The Walking Dead or Brothers: A Tale of Two Sons, you\'ll become a fan of Valiant Hearts: The Great War.',
        submitted_by: '',
        answers: ["Valiant Hearts: The Great War", "Valiant Hearts"],
        affiliate_links: ["https://www.g2a.com/valiant-hearts-the-great-war-xbox-live-key-global-i10000045334006?gtag=391e4ef696", "https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Valiant%20Hearts%20%20The%20Great%20War%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fvaliant-hearts-the-great-war%2F&intsrc=PUI2_9608"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '79%',
        genre: 'Adventure, Platform, Puzzle',
        console_platform: 'PS3, PS4, PC, X360, XONE, Mobile',
        developer: 'Ubisoft Montpellier SAS',
        backloggd_link: '',
    },
    '613': {
        content: 'Wasteland 3 is a role-playing video game developed by inXile Entertainment and published by Deep Silver. Released in 2020, it continues the narrative from its predecessor, Wasteland 2, and plunges players into a post-apocalyptic Colorado, locked in an eternal winter. As part of the Desert Rangers, players are dispatched to the frozen wasteland to build an alliance with the Patriarch, the self-appointed ruler of Colorado. The gameplay in Wasteland 3 intertwines strategic combat with intricate narrative decisions. The turn-based combat system allows players to deploy a wide range of tactics and cooperation between team members, whereas the storyline branches according to the choices you make, often entailing moral dilemmas with no clear right or wrong answers. The game also supports cooperative play, allowing friends to experience the story together. Known for its rich storytelling, challenging tactical combat, and atmospheric post-apocalyptic setting, Wasteland 3 is rated highly among RPG fans. If you\'re a fan of narrative-heavy role-playing games with strategic combat like Divinity: Original Sin or Fallout, you\'ll be a fan of Wasteland 3.',
        submitted_by: '',
        answers: ["Wasteland 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Wastelands%203%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwasteland-3%2F&intsrc=PUI2_9608", "https://www.g2a.com/wasteland-3-steam-gift-global-i10000192444002?gtag=391e4ef696"],
        franchise: 'Wasteland',
        twitter_ids: [],
        release_year: '2020',
        metacritic_score: '85%',
        genre: 'Adventure, RPG, Turn-based strategy (TBS)',
        console_platform: 'PC, PS4, XONE',
        developer: 'inXile Entertainment, Inc.',
        backloggd_link: '',
    },
    '614': {
        content: 'Tekken is a fighting game developed and published by the gaming giant, Namco. Released in 1994, Tekken thrusts players into an intense worldwide martial arts tournament organised by the Mishima Zaibatsu corporation. Players choose from a multitude of vividly designed characters, each with their distinct movesets and fighting styles derived from various martial arts disciplines from around the world. The gameplay in Tekken focuses on one-on-one battles and combines a swift attack design with a rich strategy element, asking players not only to master complex combos but also to anticipate opponents\' moves. The game is also known for its inclusion of a juggle system, which allows players to keep opponents in the air for sequential hits, extending attack opportunities. A unique aspect of Tekken is its detailed character backgrounds and intertwining storylines, adding depth and intrigue to each battle. The game has been lauded for its deep, intuitive combat mechanics, diverse roster of fighters, and the revolution it brought to the fighting genre. If you\'re a fan of nail-biting fighting games like Street Fighter or Mortal Kombat, you\'re sure to be a fan of Tekken.',
        submitted_by: '',
        answers: ["Tekken", "Rave War"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=TEKKEN%208%20Deluxe%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftekken-8-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tekken-8-ultimate-edition-pc-steam-gift-global-i10000337602013?gtag=391e4ef696"],
        franchise: 'Tekken',
        twitter_ids: ["1747785120122118297", "1747978568746008617"],
        release_year: '1994',
        metacritic_score: 'Original Release too old for metacritic',
        genre: 'Fighting',
        console_platform: 'Arcade',
        developer: 'Namco Limited',
        backloggd_link: '',
    },
    '615': {
        content: 'SimCity 2000 is a city-building simulation video game developed by Maxis and published by EA Games. Launched in 1993, the game broadens the urban development horizon from its predecessor, SimCity, by allowing players to act as the mayor of a self-built city, intricately crafting residential, commercial, and industrial zones, including structures like schools, libraries, and parks. The gameplay in SimCity 2000 is all about strategic planning and management, as players must balance resource allocation, population growth, and infrastructure development while addressing a vast array of challenges such as crime, natural disasters, and environmental concerns. The introduction of an isometric perspective, as opposed to the top-down view of the original, introduces a truly three-dimensional feel to the cityscape, enhancing the player\'s sense of control and ownership. SimCity 2000 stands as a cornerstone of simulation games, receiving acclaim for its immersive gameplay, intense depth, and the freedom it offers players to create their unique metropolis. If you\'re a fan of intricate simulation games like RollerCoaster Tycoon or Cities: Skylines, you\'ll be a fan of SimCity 2000.',
        submitted_by: '',
        answers: ["SimCity 2000"],
        affiliate_links: ["https://www.g2a.com/simcity-2000-special-edition-ea-app-key-global-i10000000711001?suid=bb667d11-02d6-49eb-afbb-ad1727c949e3?gtag=391e4ef696"],
        franchise: 'SimCity',
        twitter_ids: ["1748141049980076207", "1748146839054946348"],
        release_year: '1993',
        metacritic_score: 'Too Old',
        genre: 'Simulation, City building',
        console_platform: 'DOS, Macintosh',
        developer: 'Maxis Software Inc.',
        backloggd_link: '',
    },
    '616': {
        content: 'Riders Republic is a massive multiplayer outdoor sports game developed and published by Ubisoft. The game, released in 2021, plunges players into a vibrant world inspired by iconic US national parks, where they can compete or cooperate with others in a plethora of extreme sports, including biking, skiing, snowboarding, wingsuit flying, and rocket wingsuiting. Players can wander freely or compete in frenetic races, trick challenges, and other events, pushing the limits in some of the world\'s most breathtaking landscapes. Riders Republic vividly illustrates the thrill of outdoor extreme sports with its precise mechanics, showing the careful balance between control and chaos that these disciplines require. Unique features, like the Mass Races where more than 60 players face off simultaneously, set this game apart. A comprehensive career mode enhances longevity, and its diverse range of disciplines and gear customization offers a deep and rewarding gameplay experience. Riders Republic has been lauded for its engaging multiplayer aspects, spectacular environments, and the sheer variety of activities it offers. If you\'re a fan of large-scale multiplayer games like Fall Guys or extreme sports games like the SSX series or Steep, you\'ll be a fan of Riders Republic.',
        submitted_by: '',
        answers: ["Riders Republic"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Riders%20Republic%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Friders-republic-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/riders-republic-xbox-series-x-xbox-live-key-global-i10000218438018?suid=eddda2bf-eba9-4263-bbf0-6d6260f4b8a3?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '78%',
        genre: 'Racing, Simulator, Sport',
        console_platform: 'PC, PS4, XONE, Stadia, PS5, Xbox Series',
        developer: 'Ubisoft Annecy SAS',
        backloggd_link: '',
    },
    '617': {
        content: 'Pizza Tower is a platform game developed and published by Pizza Tower Guy. Released in 2023, the game thrusts players into the dough-dusted shoes of Peppino Spaghetti, a quirky pizza chef on a quest to climb the ever-changing Pizza Tower. Taking place in a parmesan-sprinkled environment, players seek to dodge devious enemies, navigate dangerous platforms, and gather gigantic pizzas along the way. The gameplay of Pizza Tower is fast-paced and fluid, capturing the manic freneticism of a busy pizzeria in a platform game. It infuses classic side-scrolling action with eccentric, food-themes and humorous characters that spice up the gameplay. Pizza Tower stands out for its unique art style, challenging level design, and the playful sense of humor imbued in every slice of the game. Apart from hopping and bopping, players must perfect their pizza delivery strategies, utilizing power-ups, and even swapping ingredients to master each level. If you\'re a fan of platform games like Super Meat Boy or Celeste, where quick reflexes and high stakes create a deliciously tense gaming dish, you\'ll be a fan of Pizza Tower.',
        submitted_by: '',
        answers: ["Pizza Tower"],
        affiliate_links: ["https://www.g2a.com/pizza-tower-pc-steam-gift-global-i10000338066003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1748957635120918948", "1748843975757201822"],
        release_year: '2023',
        metacritic_score: '89%',
        genre: 'Indie, Platform',
        console_platform: 'PC',
        developer: 'Tour De Pizza',
        backloggd_link: '',
    },
    '618': {
        content: 'Final Fantasy VI is a role-playing video game developed and published by Square (now Square Enix). Released in 1994 as the sixth installment in the esteemed Final Fantasy series, it takes players on a epic journey through a steampunk-inspired world. Players control a diverse group of characters, each with their own backgrounds and personality traits, united in their mission to overthrow the Empire\'s tyrannical rule. The game features the series\' iconic turn-based combat system, as well as a magic system and the introduction of \"esper\" summoning, allowing characters to call upon powerful creatures for assistance in battle. The compelling narrative, enriched with themes of hope, sacrifice, and friendship, is accentuated by beautiful pixel art and a powerful musical score. Final Fantasy VI has been acclaimed for its vast, richly detailed world, complex character development, and an innovative battle system. Its powerful narrative set a new standard for storytelling in video games and continues to influence RPGs to this day. If you\'re a fan of story-driven, turn-based RPGs such as Chrono Trigger or the Dragon Quest series, you\'ll surely be a fan of Final Fantasy VI.',
        submitted_by: '',
        answers: ["Final Fantasy VI", "Final Fantasy III", "Final Fantasy 6", "Final Fantasy 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=FINAL%20FANTASY%20III%20Pixel%20Remaster%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-iii-pixel-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-iii-steam-key-global-i10000045402002?suid=6c90b87b-e198-476b-912a-cadab3d8901f?gtag=391e4ef696"],
        franchise: 'Final Fantasy',
        twitter_ids: ["1749475580175917409", "1749311912259543496", "1749483737719468120", "1749308986489278925", "1749481888203366552"],
        release_year: '1994',
        metacritic_score: 'Too Old',
        genre: 'RPG',
        console_platform: 'SNES',
        developer: 'Square Co., Ltd., Square Enix Co., Ltd.',
        backloggd_link: '',
    },
    '619': {
        content: 'Captain Toad: Treasure Tracker is an enchanting puzzle platformer game developed and published by Nintendo for the Wii U console. Released in 2014, the game allows players to assume the role of the venturesome Captain Toad on a challenging pursuit of treasure and the elusive Toadette in an array of intricately designed levels. The game stands out in its genre by fashioning a protagonist who, contrary to typical platformer heroes, cannot jump. Consequently, players need to work with the environment to navigate through platforms and avoid foes. Players can manipulate the camera to explore these levels from different angles, revealing hidden paths and treasures. Each level brings in a fresh new theme, with complex puzzles that often require a shift in perspective for their solutions. The game also introduces touch screen features and gyroscopic controls, adding another immersive dimension to the gameplay. Captain Toad: Treasure Tracker has been appreciated for its innovative approach, charming visuals, and brain-teasing puzzles. If youre a fan of intuitive puzzle games like Portal or creative platformers like Super Mario Odyssey, youll be a fan of Captain Toad: Treasure Tracker.',
        submitted_by: '',
        answers: ["Captain Toad: Treasure Tracker"],
        affiliate_links: ["https://www.g2a.com/captain-toad-treasure-tracker-nintendo-switch-nintendo-eshop-account-account-global-i10000192841002?gtag=391e4ef696"],
        franchise: 'Mario',
        twitter_ids: ["1749568840491503644", "1749685970356641833"],
        release_year: '2014',
        metacritic_score: '81%',
        genre: 'Platform, Puzzle, Strategy',
        console_platform: 'Wii U',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '620': {
        content: 'A Space for the Unbound is an adventure game with a pixel art style developed by Mojiken and published by Toge Productions. Released in 2023, the game takes place in the late 90s, rural Indonesia and revolves around two high school sweethearts who unravel the secrets of the supernatural and deal with depression, anxiety, and the end of their world. A Space for the Unbound is characterized by its deep storytelling, beautiful pixel art design, and a soothing acoustic soundtrack. Gameplay involves players exploring and interacting with an authentic Indonesian setting, solving puzzles, and making choices that steer the narrative. The game expertly weaves themes of love, friendship, and self-identity. One notable feature is the \"Book Dive\" mechanic, where players delve into people\'s minds to unearth their deepest secrets. The deep story coupled with an emotionally resonant music score gives an immersive narrative-driven experience. A Space for the Unbound\'s potent combination of compelling narrative, unique game mechanics, and evocative art style has garnered praise from critics and players alike. If you\'re a fan of narrative-driven adventure games such as Life is Strange or Oxenfree, you\'ll be a fan of A Space for the Unbound.',
        submitted_by: '',
        answers: ["A Space for the Unbound"],
        affiliate_links: ["https://www.g2a.com/a-space-for-the-unbound-pc-steam-key-global-i10000337956001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1750131944027738184"],
        release_year: '2023',
        metacritic_score: '86%',
        genre: 'Adventure, Indie, Puzzle',
        console_platform: 'XONE, Xbox Series, PC, PS4, Switch, PS5',
        developer: 'Mojiken Studio',
        backloggd_link: '',
    },
    '621': {
        content: 'Wipeout 3 is a futuristic racing game developed by Psygnosis and published by Sony Computer Entertainment. Launched in 1999, Wipeout 3 thrusts players into the adrenaline-pumping world of anti-gravity racing in the year 2116. Players take control of various high-speed hovercraft as they compete against others in fast-paced, kinetic races on gravity-defying tracks. Gameplay in Wipeout 3 builds on the previous titles in the series, introducing new tracks, vehicles and a tweaked weapons system. One of its unique features is the introduction of force fields for vehicle protection, adding a strategic layer to the intense racing action. The game also features a pulsating electronic soundtrack, which enhances the immersive, high-speed racing experience. Brilliantly blending high-speed racing with strategic gameplay, Wipeout 3 stands out with its combination of sleek graphics, kinetic gameplay, and techno-futuristic ambiance, making it a must-play for racing game enthusiasts. If you\'re a fan of fast-paced, futuristic racing games like F-Zero or Gran Turismo, you\'ll definitely be a fan of Wipeout 3.',
        submitted_by: '',
        answers: ["Wipeout 3"],
        affiliate_links: [],
        franchise: 'Wipeout',
        twitter_ids: ["1750395818228449281", "1750322495448666263"],
        release_year: '1999',
        metacritic_score: '89%',
        genre: 'Racing',
        console_platform: 'PS1',
        developer: 'Psygnosis Limited',
        backloggd_link: '',
    },
    '622': {
        content: 'Fable III is an action role-playing game developed by Lionhead Studios and published by Microsoft Game Studios. Released in 2010, Fable III immerses players in the vibrant, mystical realm of Albion, a land buckling under the reign of a tyrannical king. Players step into the boots of the Hero of Brightwall, a royal who is provoked into starting a rebellion to overthrow their sibling, the King, and seize the throne. This entrancing tale is spiced with a unique blend of exploration, combat, and wrenching moral choices that carve out the narrative. Its gameplay features real-time combat, immersive exploration, and interaction with diverse characters in a fully realized world that reacts to players\' decisions. Along with competing quests, Fable III offers unique elements such as the ability for players to get married, start a family, and impose their will as a ruler over Albion\'s lands. Fable III ranks highly for its compelling storytelling, immersive world, and deep player choice mechanics. If you\'re a fan of character-driven, choice-dependent games like The Witcher series or Dragon Age: Inquisition, you\'ll be a fan of Fable III.',
        submitted_by: '',
        answers: ["Fable III", "Fable 3"],
        affiliate_links: ["https://www.g2a.com/fable-iii-steam-key-global-i10000003328004?suid=865fcd96-1c76-41ef-9dc1-259756562c99?gtag=391e4ef696"],
        franchise: 'Fable',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '75%',
        genre: 'Action, Adventure, RPG',
        console_platform: 'X360',
        developer: 'Lionhead Studios Ltd.',
        backloggd_link: '',
    },
    '623': {
        content: 'Flower is a unique, casual exploration game developed by Thatgamecompany and published by Sony Computer Entertainment. Released in 2009, Flower thrusts players into an ethereal, surreal world, letting them control the wind and guide a cluster of flower petals through beautiful, lush landscapes. Flower differs from traditional games with its emphasis on relaxation and exploration rather than completion or challenge. The goal is to manipulate a petal through environments that respond to the movements of the player. As the gust of wind and the petals pass through, they interact with the environment, triggering changes like turning barren landscapes to bright green fields or activating wind turbines. Flowers style of gameplay fosters feelings of positive emotion, effortlessness, and serenity, while its stunning visuals and soothing soundtrack further enhance its tranquil atmosphere. Widely praised for its emotional depth, innovative gameplay, and artistry, Flower leaves a lasting impression for its unique gaming experience. If youre a fan of tranquil, exploration games like Journey or ABZÛ, youll be a fan of Flower.',
        submitted_by: '',
        answers: ["Flower"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Flower%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fflower-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/flower-steam-key-global-i10000182801002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '87%',
        genre: 'Adventure, art game',
        console_platform: 'PS3',
        developer: 'Thatgamecompany',
        backloggd_link: '',
    },
    '624': {
        content: 'Starbound is an action-adventure game developed and published by Chucklefish. Released in 2016, it takes players on an interstellar adventure to explore and inhabit procedurally generated planets in a boundless, pixelated universe. Taking on the role of a character who\'s just fled their home in a spaceship, players touchdown on unexplored plants teeming with life and resources, engaging in quests and forming colonies while encountering a slew of alien species. Gameplay in Starbound is varied ranging from combat to farming, crafting, and exploration with an emphasis on freedom and player choice. With the entire universe to explore, sandbox freedom is at the heart of Starbound\'s appeal. Notably, the robust and intuitive building mechanics allow the creation of everything from humble wooden cabins to massive robotic mining operations. The unique mix of Terraria-style crafting, open-world exploration, and charming sci-fi aesthetic has proven to be a hit amongst players. Starbound has been praised for its virtually infinite replayability, engaging survival mechanics, and the creative freedom it offers. If you\'re a fan of open-world sandbox games like Terraria or creativity driven games like Minecraft  , you\'ll be a fan of Starbound.',
        submitted_by: '',
        answers: ["Starbound"],
        affiliate_links: ["https://www.g2a.com/starbound-steam-key-global-i10000009279005?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1751756237652312529", "1751489531910455481", "1751416475225592084"],
        release_year: '2016',
        metacritic_score: '81%',
        genre: 'Action, Adventure',
        console_platform: 'PC',
        developer: 'Chucklefish',
        backloggd_link: '',
    },
    '625': {
        content: 'Uncharted 2: Among Thieves is an action-adventure game developed by Naughty Dog and published by Sony Computer Entertainment. At the center of this 2009 release is protagonist Nathan Drake, a likable treasure hunter with exceptional athletic skills and a flair for archaeology. Set against the backdrop of stunning landscapes, players guide Drake in his journey around the world, unraveling historical mysteries and facing numerous foes in intense combat sequences. Gameplay elements encompass a mixture of gunfights, platform climbing, puzzle-solving, and stealth. One key attribute of Uncharted 2 is its cinematic storytelling, with memorable characters, witty dialogue, and explosive set pieces that contribute to a rich narrative experience. Additionally, Uncharted 2 was praised for introducing a multiplayer mode, permitting competitive and cooperative play for the first time in the Uncharted series. Uncharted 2: Among Thieves has been lauded for its engaging gameplay, compelling narrative, and exceptional graphics. If you\'re a fan of thrilling action-adventure games like Tomb Raider or The Last of Us, you\'ll be a fan of Uncharted 2: Among Thieves.',
        submitted_by: '',
        answers: ["Uncharted 2: Among Thieves", "Uncharted 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=UNCHARTED%20Legacy%20of%20Thieves%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Funcharted-legacy-of-thieves-collection%2F&intsrc=PUI2_9608"],
        franchise: 'Uncharted',
        twitter_ids: ["1751631270495523215", "1751841459676811455"],
        release_year: '2009',
        metacritic_score: '96%',
        genre: 'Action, Adventure, 3rd-person, Shooter',
        console_platform: 'PS3',
        developer: 'Naughty Dog',
        backloggd_link: '',
    },
    '626': {
        content: 'Kirby and the Forgotten Land is a vivid and charming action platformer developed and published by Nintendo. Released in 2022, players take control of the beloved, round, pink character, Kirby, as he embarks on an adventure in a 3D world filled with both wondrous and perilous locales. The intriguing storyline puts Kirby accidentally transported to a mysterious new region - The Forgotten Land - from which he must find a way back home. The gameplay is a blend of classic Kirby mechanics like inhaling enemies, and new abilities, including the power to transform into objects using the \"Mouthful Mode.\" Through exploring diverse environments, solving engaging puzzles, and devouring adversaries, players unveil the secrets of the Forgotten Land. The switch to a 3D world for the traditional 2D hero presents a refreshing new gameplay dynamic as Kirby can now explore levels in all directions. Kirby and the Forgotten Land is quickly gathering praise for its charming art style, enjoyable platforming action, and delightful new mechanics. If you\'re a fan of the classic 3D platformers like Super Mario Odyssey or fun-filled adventure games like Yoshi\'s Crafted World, you\'ll be a fan of Kirby and the Forgotten Land.',
        submitted_by: '',
        answers: ["Kirby and the Forgotten Land"],
        affiliate_links: ["https://www.g2a.com/kirby-and-the-forgotten-land-nintendo-switch-nintendo-eshop-account-global-i10000326127004?suid=467b4d11-b513-41c6-85c3-9525cc752622?gtag=391e4ef696"],
        franchise: 'Kirby',
        twitter_ids: ["1752422721978265855", "1752106809584742611", "1752105380484682107", "1752210283156631797"],
        release_year: '2022',
        metacritic_score: '85%',
        genre: 'Platform',
        console_platform: 'Switch',
        developer: 'HAL Laboratory',
        backloggd_link: '',
    },
    '627': {
        content: 'Killzone 3 is a first-person shooter game developed by Guerrilla Games and published by Sony Computer Entertainment. Released in 2011, Killzone 3 draws players into a visceral war zone on planet Helghan, as they control Sev, a Special Forces operative fighting for his life and the future of the Interplanetary Strategic Alliance against the Helghast Empire. Technically spectacular and brutally challenging, Killzone 3 maintains an intense focus on its narrative-driven single-player campaign while adding a robust multiplayer component. The game\'s mechanics emphasize not just run-and-gun action, but also the strategic use of cover and tactical variety, allowing players to handle combat in broad daylight, nocturnal stealth, and zero-gravity environments. Killzone 3 is also known for its stunning visuals and sound design, which enhance its immersive, cinematic war-torn world. The game further leverage the PlayStation Move for a unique motion-control experience and features fully implemented 3D gaming. Highly lauded for it\'s combat mechanics, intense storyline, and graphical prowess, if you\'re a fan of first-person shooters like Call of Duty or Battlefield, you\'ll be a fan of Killzone 3.',
        submitted_by: '',
        answers: ["Killzone 3"],
        affiliate_links: [],
        franchise: 'Killzone',
        twitter_ids: ["1752807997972082743", "1752481342070100182"],
        release_year: '2011',
        metacritic_score: '84%',
        genre: 'First-person shooter',
        console_platform: 'PS3',
        developer: 'Guerrilla Games',
        backloggd_link: '',
    },
    '628': {
        content: 'Tomb Raider II is an action-adventure game developed by Core Design and published by Eidos Interactive. This 1997 release continues the gripping adventures of the iconic explorer Lara Croft. The game takes players across a variety of locales, from the Great Wall of China to a sunken ship in the ocean depths, in search of the mythical Dagger of Xian. This entrancing artifact supposedly bestows the power of a dragon upon whoever plunges it into their heart. Players guide Lara through sprawling exploration zones, solve elaborate puzzles, battle a variety of enemies, and discover hidden secrets. Tomb Raider II also introduced new game mechanics such as vehicle sections and an expanded set of moves for Lara, including ladder climbing and the ability to drive vehicles, which added new layers to the beloved gameplay. Celebrated for its captivating narrative, intriguing puzzles, and thrilling action sequences, Tomb Raider II firmly cemented Lara Croft as a gaming icon. If you\'re a fan of adventure games with strong narratives and puzzle elements like Uncharted and Prince of Persia, you\'ll be a fan of Tomb Raider II.',
        submitted_by: '',
        answers: ["Tomb Raider II", "Tomb Raider 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tomb%20Raider%20II%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftomb-raider-ii%2F&intsrc=PUI2_9608", "https://www.g2a.com/tomb-raider-ii-steam-key-global-i10000048426002?gtag=391e4ef696"],
        franchise: 'Tomb Raider',
        twitter_ids: ["1752942766844268617", "1753137061840753076"],
        release_year: '1997',
        metacritic_score: '85%',
        genre: 'Action, Adventure',
        console_platform: 'PC, PS1',
        developer: 'Core Design',
        backloggd_link: '',
    },
    '629': {
        content: 'Grand Theft Auto: San Andreas is an action-adventure game from Rockstar North, published by Rockstar Games. Released in 2004, the game invites players to the gangster-filled endeavors of Carl Johnson, who returns to his home city of San Andreas — a fictional depiction of 90s-era California — after the tragic death of his mother. As Johnson, players navigate through the three major cities of San Andreas, filled with seedy underworlds and criminal activities, while completing various missions. The gameplay involves brutal chases, clashing gang wars, intense shooting streaks and the exploration of an expansive, dynamic world. The game also boasts a unique RPG-style player progression system, letting players enhance their character\'s skills over time. Alongside story missions, the game offers plenty of side-activities, from working out at the gym to participating in territorial gang wars. Grand Theft Auto: San Andreas has been widely acclaimed for its compelling plotline, impressive world design, and immersive gameplay mechanics. If you\'re a fan of the open-world action games like Sleeping Dogs or Mafia, you\'ll surely be a fan of Grand Theft Auto: San Andreas.',
        submitted_by: '',
        answers: ["Grand Theft Auto: San Andreas"],
        affiliate_links: ["https://www.g2a.com/grand-theft-auto-san-andreas-steam-key-global-i10000004439009?suid=d95625aa-bd54-4ea3-8eb2-1ce563e76d7a?gtag=391e4ef696"],
        franchise: 'Grand Theft Auto',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '95%',
        genre: 'Action, Adventure',
        console_platform: 'PS2',
        developer: 'Rockstar North',
        backloggd_link: '',
    },
    '630': {
        content: 'Golden Axe is a classic side-scrolling beat \'em up video game developed by Sega. Released in 1989, it thrusts players into a high-fantasy medieval world to liberate the land of Yuria from the clutches of the evil Death Adder who holds the king and princess captive. Golden Axe can be played solo or cooperatively, offering three warriors: A barbarian, an Amazon, and a dwarf, each equipped with a different skill set and special magical abilities that are powered by potions. As players progress through the game, they fight hordes of diverse enemies, including knights, dragons and undead skeletons, all the while riding beasts, using magic and melee combat. One of the unique elements of Golden Axe is the inclusion of a dual-purpose \'hack and slash\' gameplay and magic, balancing physical combat with spellcasting against formidable foes. Moreover, the game ends with a meta-twist, revealing the entire adventure was a game played on an arcade machine. Golden Axe has been praised for its cooperative gameplay, vibrant graphics, and innovative blend of fantasy and arcade action. If you\'re a fan of beat \'em up games like Streets of Rage or Castle Crashers, you\'ll be a fan of Golden Axe.',
        submitted_by: '',
        answers: ["Golden Axe"],
        affiliate_links: ["https://www.g2a.com/golden-axe-steam-key-global-i10000051093002?gtag=391e4ef696"],
        franchise: 'Golden Axe',
        twitter_ids: [],
        release_year: '1989',
        metacritic_score: '68%',
        genre: 'Beat \'em up, Hack and slash',
        console_platform: 'Arcade',
        developer: 'Sega',
        backloggd_link: '',
    },
    '631': {
        content: 'Age of Empires III is a real-time strategy game developed by Ensemble Studios and published by Microsoft Game Studios. Released in 2005, the game whisks players into the era of European exploration and conquests between the 15th and 19th-century. Players command one of several history\'s most powerful civilizations, navigating the complexities of discovery, colonization, the Industrial Revolution, all the way to the American West expansion. Gameplay in Age of Empires III involves gathering resources, building a strategic base, and advancing through technological Ages while raising a mighty army. Notably, the game introduced the intriguing \"Home City\" system — a separate powerhouse that supports your expansion efforts by shipping supplies, reinforcements, and special abilities. Its detailed graphics and weather effects, realistic physics, and expansive campaign narrative set a new standard for the real-time strategy genre. Age of Empires III has been highly regarded for its historical background, strategic depth, and immersive gameplay. If you\'re a fan of strategic games that combine warfare and statecraft like Civilization or StarCraft, you\'ll be a fan of Age of Empires III.',
        submitted_by: '',
        answers: ["Age of Empires III", "Age of Empires 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Age%20of%20Empires%20III%20Definitive%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fage-of-empires-iii-definitive-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/age-of-empires-iii-complete-collection-steam-key-global-i10000016804011?suid=27eb90ba-facf-4999-90fa-f3725e30a9ba?gtag=391e4ef696"],
        franchise: 'Age of Empires',
        twitter_ids: ["1754043451216171158", "1753958569383272640"],
        release_year: '2005',
        metacritic_score: '81%',
        genre: 'Real-time Strategy',
        console_platform: 'PC',
        developer: 'Ensemble Studios',
        backloggd_link: '',
    },
    '632': {
        content: 'Mass Effect 2 is a highly acclaimed action role-playing game developed by BioWare and published by Electronic Arts. In the game, released in 2010, players navigate the vastness of space as Commander Shepard, a character whose personality and decisions are shaped by the player. The game presents a thrilling narrative set in a rich, alien-inhabited universe threatened by a species known as the Collectors. Told with stunning visuals and strong character development, Mass Effect 2 compellingly combines shooter and role-playing elements, allowing players to partake in real-time combat, strategic team-building, and interactive dialogue. The choices made by the player significantly affect the storyline, leading to multiple potential endings. Unique features include detailed character customization and a galaxy map for exploration of the vast Mass Effect universe. Building on the success of its predecessor, Mass Effect 2 received praise for its deep story, complex characters, and decision-based gameplay. If you\'re a fan of choice-driven sci-fi games like Star Wars: Knights of the Old Republic or action role-playing games like Dragon Age: Inquisition, you\'ll be a fan of Mass Effect 2.',
        submitted_by: '',
        answers: ["Mass Effect 2"],
        affiliate_links: ["https://www.g2a.com/mass-effect-2-steam-gift-global-i10000043421001?gtag=391e4ef696"],
        franchise: 'Mass Effect',
        twitter_ids: ["1754675480572088437"],
        release_year: '2010',
        metacritic_score: '96%',
        genre: 'Action, RPG, 3rd-person, Shooter',
        console_platform: 'PC, X360',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '633': {
        content: 'Far Cry 6, developed and published by Ubisoft, is an engaging first-person shooter game that launched in 2021. Players are immersed into the tumultuous world of Yara, a fictional Caribbean country that mirrors Cuba, where they step into the role of Dani Rojas, a guerrilla fighter determined to overthrow the ruthless dictator Antón Castillo, portrayed by renowned actor Giancarlo Esposito. The gameplay of Far Cry 6 is marked by its expansive open-world exploration, action-packed combat, and strategic elements. Players utilize a variety of weapons, vehicles, and makeshift tools to engage with enemy forces, sabotage operations, and complete mission objectives across urban and natural landscapes. The game also offers multiple ways to approach missions, encouraging tactical flexibility. Unique gameplay elements include the Amigos for Hire system, where players can recruit animal companions with special abilities. Its narrative is rich with political themes that intertwine with a deeply personal story for the player character, Dani. If you\'re a fan of gripping open-world shooters like Just Cause or the previous Far Cry titles, you\'ll undoubtedly be drawn to Far Cry 6.',
        submitted_by: '',
        answers: ["Far Cry 6"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Far%20Cry%206%20Ultimate%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffar-cry-6-ultimate-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/far-cry-6-gold-edition-pc-steam-gift-global-i10000206389063?gtag=391e4ef696"],
        franchise: 'Far Cry',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '73%',
        genre: 'First-person shooter',
        console_platform: 'PC, PS4, PS5, XONE, Xbox Series, Stadia, Luna',
        developer: 'Ubisoft Toronto',
        backloggd_link: '',
    },
    '634': {
        content: 'Scott Pilgrim vs. The World: The Game is a side-scrolling beat \'em up game based on the critically acclaimed graphic novel series, developed by Ubisoft Montreal and published by Ubisoft. Released in 2010, it puts players in the quirky, pop-culture-infused world of Scott Pilgrim, a bass guitarist who must defeat his girlfriend Ramona\'s seven evil exes. Borrowing heavily from classic arcade games, players can choose to play as Scott, Ramona, or one of their friends; Kim Pine or Stephen Stills. Its gameplay is characterized by combo-heavy fight sequences, interactive environments, and co-op multiplayer modes. Apart from the main story, side quests and mini-games provide players with opportunities for character development and levelling up, adding a layer of RPG elements. One distinctive feature is its chiptune-inspired soundtrack, composed by the band Anamanaguchi, that sets the energetic tone of the game. Scott Pilgrim vs. The World: The Game has received praise for its retro-style visuals, infectious soundtrack, and satisfying beat \'em up mechanics. If you\'re a fan of action-packed side-scrolling games such as Streets of Rage or Castle Crashers, you\'ll be a fan of Scott Pilgrim vs. The World: The Game.',
        submitted_by: '',
        answers: ["Scott Pilgrim vs. The World: The Game"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Scott%20Pilgrim%20vs%20the%20World%20The%20Game%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fscott-pilgrim-vs-the-world-the-game-complete-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/scott-pilgrim-vs-the-world-the-game-complete-edition-xbox-one-xbox-live-key-global-i10000256656006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '77%',
        genre: 'Beat \'em up, Action, RPG',
        console_platform: 'PS3, X360',
        developer: 'Ubisoft Montreal',
        backloggd_link: '',
    },
    '635': {
        content: 'Wipeout is a futuristic racing game developed and published by Psygnosis. Released in 1995, it takes players into a high-speed, anti-gravity racing realm where they take control of futuristic hovercrafts in thrilling races across various tracks. As a pilot, players compete in F3600 anti-gravity racing league against various teams, flying at breakneck speeds down challenging tracks located worldwide. The gameplay in Wipeout is characterized by its fast tempo, hairpin turns, and the ability to use various power-ups to enhance the speed or disrupt opponents. Each craft is fitted with an energy shield which, when depleted, will cause the vehicle to explode and necessitates a pit stop for repair. The game also stands out with its cutting-edge graphics for its time and electronic dance music soundtrack. It set the standard for many future games in the genre of futuristic racing. Wipeout has earned accolades for its innovative gameplay, speed sensation, advanced visuals, and atmospheric soundtrack. If you\'re a fan of adrenaline-pumping racing games like F-Zero or Trackmania, you\'ll be a fan of Wipeout.',
        submitted_by: '',
        answers: ["Wipeout"],
        affiliate_links: [],
        franchise: 'Wipeout',
        twitter_ids: [],
        release_year: '1995',
        metacritic_score: 'Not Rated',
        genre: 'Racing',
        console_platform: 'PS1',
        developer: 'Psygnosis',
        backloggd_link: '',
    },
    '636': {
        content: 'Shin Megami Tensei: Persona 4 is a role-playing game developed by Atlus. Launched in 2008, it casts players in the role of a high school student who has moved to the countryside of the Inaba region, and is haunted by mysterious murders tied to the area\'s television system. The game combines typical RPG elements with simulation elements. During daytime, players attend school and form relationships with other characters, while during the night, they may fight Shadows in a dimension entered through a television. The game features a weather forecast system with events happening on foggy days to replace the moon phase system implemented in the previous games. Persona 4\'s unique concoction of dungeon-crawling combat and social simulator has earned it a loyal fanbase and critical acclaim for its complex characters, mature narrative, and intrinsically rewarding gameplay mechanics. If you\'re a fan of JRPGs with deep narratives and unique gameplay like Xenogears or the Final Fantasy series, you\'ll be a fan of Shin Megami Tensei: Persona 4.',
        submitted_by: '',
        answers: ["Shin Megami Tensei: Persona 4", "Persona 4", "Persona 4 Golden"],
        affiliate_links: [],
        franchise: 'Megami Tensei',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '90%',
        genre: 'RPG, social simulation',
        console_platform: 'PS2',
        developer: 'Atlus',
        backloggd_link: '',
    },
    '637': {
        content: 'Fruit Ninja is an addictively fun arcade-style game developed and published by Halfbrick Studios. Released in 2010, this colorful, fast-paced game takes players into the world of skilled ninja who slices fruits with every swipe. The aim of the game is simple: slice as many fruits as you can while avoiding bombs. The gameplay in Fruit Ninja is easy to pick up yet challenging to master, ensuring endless fun. Players swipe across the screen juggling multiple fruits in the air and aiming to get high combo scores within a limited time frame. There are different modes to choose from, including Classic, Arcade, and Zen mode, each with unique gameplay twists. The game also offers a range of cool blades and dojos for players to unlock and collect. Fruit Ninja has garnered praise for its simplistic design, engaging gameplay, and vibrant graphics. Over the years, it has become a cultural phenomenon and is widely recognized as a classic mobile game all over the world. If you\'re a fan of fast-paced arcade games like Angry Birds or Subway Surfers, you\'ll be a fan of Fruit Ninja.',
        submitted_by: '',
        answers: ["Fruit Ninja"],
        affiliate_links: ["https://www.g2a.com/fruit-ninja-vr-steam-key-global-i10000019897002?gtag=391e4ef696"],
        franchise: 'Fruit Ninja',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '75%',
        genre: 'Arcade',
        console_platform: 'Mobile',
        developer: 'Halfbrick Studios Pty Ltd, Big Ant Studios Pty Ltd',
        backloggd_link: '',
    },
    '638': {
        content: 'Tibia is a highly immersive massively multiplayer online role-playing game developed and published by CipSoft. Released in 1997, it is one of the oldest and longest-running online games, which facilitates the exploration of a vast, medieval-themed open world filled with thrilling adventures and dangerous creatures. Players take on the roles of heroic characters, where they dive into intricate quests, engage in combat with monsters, accumulate experience points, and acquire valuable items. The game\'s unique open PvP system allows players to engage in combat with other players, adding an additional layer of tension and excitement. Approaching quests and battles require strategy and teamwork, as the diverse array of monsters often requires different tactics to defeat. Classes, like knights, paladins, sorcerers, and druids, offer different playstyles, accommodating a variety of players. Tibia also utilizes a semi-real time combat system, providing a sense of immediacy and engagement. One of its main distinctive elements is the severe death penalty, which makes every quest a high-stakes venture. Tibia has received accolades for its deep gameplay mechanics, intricate world-building, and challenging, yet rewarding player experience. If you\'re a fan of immersive online role-playing games such as Runescape or World of Warcraft, you\'ll be a fan of Tibia.',
        submitted_by: '',
        answers: ["Tibia"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: 'Original Release too old for metacritic',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'CIP Productions',
        backloggd_link: '',
    },
    '639': {
        content: 'Kingdom Hearts is an action role-playing game developed and published by Square for the PlayStation 2. Launched in 2002, it presents a magical adventure where players step into the shoes of Sora, a young boy lost in a universe populated by both Disney and Final Fantasy characters. Players journey through various Disney-themed worlds, combating armies of dark creatures known as the Heartless, as they aim to find Sora\'s lost friends. Kingdom Hearts features a unique combat system that combines real-time action with strategic RPG elements, demanding both skill and strategy. Noteworthy is the companionship system where Donald Duck and Goofy join Sora, helping him throughout his quest. Additionally, the narrative of Kingdom Hearts, known for its emotionality and deep themes, provides a unique blend of light-hearted Disney charm and more complex narrative elements from the Final Fantasy series. Kingdom Hearts has been popular for its compelling storytelling, iconic character interactions, and the dynamic blend of action and RPG gameplay. If you\'re a fan of action RPGs like Final Fantasy XV or Fable, you\'ll be a fan of Kingdom Hearts.',
        submitted_by: '',
        answers: ["Kingdom Hearts"],
        affiliate_links: [],
        franchise: 'Kingdom Hearts',
        twitter_ids: ["1756916060073140648", "1756813862446240122", "1756910495448388041"],
        release_year: '2002',
        metacritic_score: '85%',
        genre: 'Adventure, RPG, 3rd person',
        console_platform: 'PS2',
        developer: 'Square Co., Ltd.',
        backloggd_link: '',
    },
    '640': {
        content: 'Sniper Elite is a tactical shooter game developed by Rebellion Developments and published by MC2 France. Launched in 2005, the game draws players into the suspenseful world of World War II, casting them in the role of Karl Fairburne, an American Office of Strategic Services officer disguised as a German sniper. Set in Battle of Berlin\'s last days, Sniper Elite involves cracking open the secrets of German nuclear technology. The gameplay is based largely around managing Fairburne\'s position and accurately delivering deadly sniper shots from a distance. Its unique \'bullet cam\' feature presents a cinematic experience of long-range kills. Besides, players must also deal with factors such as wind direction, strength, heart rate, and breathing when taking aim. Aside from its engaging single-player campaign, Sniper Elite features competitive and cooperative multiplayer modes. Celebrated for its meticulously designed environments, realistic sniping mechanics, and riveting plot, Sniper Elite offers an immersive gaming experience. If you\'re a fan of stealth-based tactical shooters like Splinter Cell, or World War II games like Call of Duty: WWII, you\'ll be a fan of Sniper Elite.',
        submitted_by: '',
        answers: ["Sniper Elite"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sniper%20Elite%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsniper-elite%2F&intsrc=PUI2_9608", "https://www.g2a.com/sniper-elite-steam-key-global-i10000045654004?gtag=391e4ef696"],
        franchise: 'Sniper Elite',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '76%',
        genre: 'Shooter, Simulator, Strategy, Tactical',
        console_platform: 'PC, PS2, Xbox',
        developer: 'Rebellion Developments Ltd.',
        backloggd_link: '',
    },
    '641': {
        content: 'Deathloop is an action-adventure game developed by Arkane Studios and published by Bethesda Softworks. Released in 2021, Deathloop offers a twisted take on time-looping narratives, set in a vintage-styled, dystopian island called Blackreef. Players assume the role of Colt, a man trapped in a time loop, who is tasked with eliminating eight targets scattered across the island before midnight, while facing off against rival assassin Julianna. Gameplay of Deathloop is an engaging mix of first-person shooting, exploration, and stealth, revolving around the central time-loop mechanic whereby failing to eliminate all targets or dying resets the day. Beyond shooting and stealth, the game\'s major highlights are its eclectic mix of supernatural powers, intricate level design, and the unique multiplayer mode where players can invade others\' games as Julianna to prevent Colt from breaking the loop. Deathloop has been praised for its creative temporal mechanics, stylized art direction, atmospheric narrative, and complex character dynamics. If you\'re a fan of action-adventure titles with unique narrative structures such as The Outer Worlds or Dishonored series, you\'ll be a fan of Deathloop.',
        submitted_by: '',
        answers: ["Deathloop"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Deathloop%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdeathloop-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/deathloop-deluxe-edition-pc-steam-key-global-i10000219970008?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1757664663054192763", "1757643293129667003"],
        release_year: '2021',
        metacritic_score: '87%',
        genre: 'Adventure, Shooter',
        console_platform: 'PC, PS5',
        developer: 'Arkane Studios SASU',
        backloggd_link: '',
    },
    '642': {
        content: 'Rick Dangerous 2 is a platform game developed by Core Design and released in 1990. In this sequel to the original classic, players resume their role as Rick Dangerous, a suave Indiana Jones-like character, on a mission to stop an alien invasion. The gameplay offers a careful mix of exploration, puzzle-solving, and action in vast and challenging levels. The game unfolds in non-linear fashion, with five very distinct stages set in wildly different locations ranging from the jungles of South America to a futuristic alien planet. One unique aspect of Rick Dangerous 2 is that the order of the stages can be chosen by the players, which adds a layer of strategy to the experience. The game is filled with quirky humor, impressive pixel-art graphics of its era, and unforgiving traps that require precision and good memory. Rick Dangerous 2 is recognized for its creative level design, increased difficulty, and improved graphics compared to its predecessor. If you\'re a fan of classic platformers like Super Mario Bros. or adventurous puzzle games like Prince of Persia, you\'ll be a fan of Rick Dangerous 2.',
        submitted_by: '',
        answers: ["Rick Dangerous 2"],
        affiliate_links: [],
        franchise: 'Rick Dangerous',
        twitter_ids: [],
        release_year: '1990',
        metacritic_score: 'Original Release too old for metacritic',
        genre: 'Platform',
        console_platform: 'DOS, Amiga, Atari ST, Commodore 64, ZX Spectrum, Amstrad CPC',
        developer: 'Core Design Ltd.',
        backloggd_link: '',
    },
    '643': {
        content: 'Geometry Dash is a rhythm-based platformer game developed and published by Sweden-based developer Robert Topala under his company RobTop Games. Since its release in 2013, players have been challenged to navigate a square-shaped character through various levels while avoiding a plethora of obstacles in sync with the rhythm of the background music. The gameplay in Geometry Dash is paramount to its appeal, demanding excellent timing, precision, and reflexes from its players. The game pits you against a continuous side-scrolling platform, featuring geometric shapes and allowing players to jump, fly, and flip through dangerous passages and spiky obstacles. The game notably doesn\'t offer any checkpoints, thus players must complete each level in one go, adding to its difficulty and achievement factor. Geometry Dash has helped shape the rhythm-platform genre with its challenging gameplay, vibrant graphics, and a catchy soundtrack. Moreover, it supports a level editor for creative players to design and share their custom levels. Acclaimed for its addictive gameplay and innovation, Geometry Dash has solidified its place in the gaming genre. If you\'re a fan of rhythm-based games like BIT.TRIP Runner or platformer games like Super Meat Boy, you\'ll be a fan of Geometry Dash.',
        submitted_by: 'https://www.youtube.com/@pigzero8920',
        answers: ["Geometry Dash"],
        affiliate_links: ["https://www.g2a.com/geometry-dash-pc-steam-key-global-i10000018369004?gtag=391e4ef696", "https://www.amazon.com/RobTop-Games-Geometry-Dash/dp/B00EDTSKLU?crid=18DZZJ2OSW6SR&keywords=Geometry+dash&qid=1685491814&sprefix=geometry+dash%2Caps%2C579&sr=8-2&linkCode=ll2&tag=guessthegam06-20&linkId=77c8e6dcc4bd44e29beacf70f4438612&language=en_US&ref_=as_li_ss_tl"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: 'Not rated',
        genre: 'Arcade, Indie, Music',
        console_platform: 'Mobile',
        developer: 'RobTop Games AB',
        backloggd_link: '',
    },
    '644': {
        content: 'LocoRoco is a puzzle-platformer video game developed and published by Sony Computer Entertainment. The game, released in 2006, introduces players to a vibrant and whimsical world filled with smiling, singing blobs called LocoRocos. The key goal is to guide these LocoRocos through different environments by tilting the landscape, overcoming a variety of obstacles and encountering a range of exotic creatures along the way. Players can increase the size of the LocoRoco to pass through certain areas by feeding them fruits, enhance their maneuverability by splitting them into smaller fragments, or unite them to form a larger body. The game also features unique controls, relying on the shoulder buttons of the PlayStation Portable (PSP) to tilt the environment and direct the movement of the LocoRocos. LocoRoco has been praised for its simple yet addictive gameplay, distinctive art style, and upbeat music. The game is praised for its joyful and charming atmosphere, which effortlessly blends with the puzzle-solving and platforming aspects of the gameplay. If you\'re a fan of puzzle-platformer games like LittleBigPlanet or Kirby\'s Epic Yarn, you\'ll be a fan of LocoRoco.',
        submitted_by: '',
        answers: ["LocoRoco"],
        affiliate_links: [],
        franchise: 'LocoRoco',
        twitter_ids: ["1758800060349882699", "1758771428751794639", "1758733668028203183"],
        release_year: '2006',
        metacritic_score: '83%',
        genre: 'Puzzle, Strategy',
        console_platform: 'PSP',
        developer: 'Sony Computer Entertainment Japan',
        backloggd_link: '',
    },
    '645': {
        content: 'Dwarf Fortress is a complex simulation and management game developed by Tarn and Zach Adams under the studio name Bay 12 Games. Since its alpha release in 2006, it has developed a cult following for its steep learning curve, intricate gameplay, and endless possibilities. In Dwarf Fortress, players are tasked with establishing a successful colony of dwarves, starting with a group of seven dwarves and an initial supply caravan in a randomly generated, persistent world. The gameplay demands a deep understanding and manipulation of the game\'s complex systems, from resource management to dwarf psychology. The detailed simulation allows for emergent storytelling where each playthrough creates unique and often unpredictable tales. With its ASCII graphics, it may not look like much, but underneath the surface lies a game of immense depth and complexity. Dwarf Fortress has been praised for providing a near-infinite well of storytelling and its meticulously detailed simulation of a living, breathing world. If you\'re a fan of management and simulation games like RimWorld or Prison Architect, you\'ll be a fan of Dwarf Fortress.',
        submitted_by: '',
        answers: ["Dwarf Fortress"],
        affiliate_links: ["https://www.g2a.com/dwarf-fortress-pc-steam-gift-global-i10000337510004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1759100407412895756"],
        release_year: '2006',
        metacritic_score: '93%',
        genre: 'Simulator',
        console_platform: 'PC',
        developer: 'Bay 12 Games',
        backloggd_link: '',
    },
    '646': {
        content: '',
        submitted_by: '',
        answers: ["The Finals"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1759600819303841884", "1759461464966885451"],
        release_year: '2023',
        metacritic_score: '80%',
        genre: 'Shooter, Tactical',
        console_platform: 'PC, PS5, Xbox series',
        developer: 'Embark Studios AB',
        backloggd_link: '',
    },
    '647': {
        content: 'Diablo Immortal is an action role-playing game developed by Blizzard Entertainment and NetEase released in 2022. It is built exclusively for mobile, diving players into the iconic universe of Diablo, a world devastated by the conflict between angels and demons. Diablo Immortal takes place between the events of Diablo II and Diablo III, with players embarking on quests to defend Sanctuary from emerging threats and horrors. Its gameplay combines the fast-paced, hack-and-slash action of the Diablo series with the convenience and accessibility of mobile gaming, allowing players to slay demons, conquer dungeons, and loot epic gear anytime, anywhere. The game introduces new dungeons, classes, and a unique social hub where players can interact with others in their online journey. Diablo Immortal also brings real-time, massive multiplayer gameplay to the franchise like never before. Its captivating graphics, intuitive controls, and depth of gameplay provide an immersive Diablo experience right on mobile. If you\'re a fan of action RPGs such as Path of Exile or Torchlight, you\'ll be a fan of Diablo Immortal.',
        submitted_by: '',
        answers: ["Diablo Immortal"],
        affiliate_links: [],
        franchise: 'Diablo',
        twitter_ids: ["1759723728798191910", "1759813294947180935"],
        release_year: '2022',
        metacritic_score: '59%',
        genre: 'RPG',
        console_platform: 'PC, Mobile',
        developer: 'NetEase, Inc., Blizzard Entertainment Inc.',
        backloggd_link: '',
    },
    '648': {
        content: 'Barotrauma is a co-operative survival horror game developed by Undertow Games and FakeFish, and published by Daedalic Entertainment. Released in 2019, the game plunges players into the icy depths of Jupiter’s moon, Europa. Players assume the roles of submarine crew members, with positions like captain, engineer, or security officer, each vital for the submarine\'s operation and survival. The game is defined by its blend of survival, horror, and co-operative mechanics. The submarine is subject to constant threats, from the crushing pressure of the ocean depths, to the alien lifeforms lurking in the dark, and even the potential for internal conflicts among the crew. The crew must work together to navigate the crippling conditions or face a cold demise. Barotrauma is partially procedurally generated, ensuring each session provides a unique challenge. The game\'s combination of tense co-operative gameplay, complex procedural environments, and atmospheric horror has set it apart. If you\'re a fan of intense survival games like Subnautica or cooperative strategy games like Overcooked, you\'ll be a fan of Barotrauma.',
        submitted_by: 'https://www.instagram.com/sidekick1004',
        answers: ["Barotrauma"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Barotrauma%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbarotrauma-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/barotrauma-steam-gift-global-i10000188640002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '81%',
        genre: 'Indie, Simulator, Strategy',
        console_platform: 'PC',
        developer: 'FakeFish Ltd., Undertow Games',
        backloggd_link: '',
    },
    '649': {
        content: 'Dino Crisis is an action-adventure survival horror game developed and published by Capcom. Launched in 1999, Dino Crisis takes players on a terrifying journey as they explore Ibis Island, a remote facility filled with blood-thirsty dinosaurs. Players control Regina, a member of a special operations team, sent to locate a scientist gone rogue. With gameplay mixing elements of survival horror, puzzles, and action, the thrilling narrative unfolds in real-time, complete with in-game cutscenes that increase immersion. Unlike other survival games of the era, Dino Crisis implemented a more realistic approach to health and inventory management, placing a greater emphasis on strategy. The game stands out with its fast-paced encounters against prehistoric foes like velociraptors and the terrifying Tyrannosaurus rex, and its intricate puzzle-solving embedded within the facility\'s complex layout. Dino Crisis was well-received for its unique blend of action and survival horror, heart-pounding dinosaur encounters, and its changeable plotline based on player choice. If you\'re a fan of survival horror games with an action twist like Resident Evil or Silent Hill, you\'ll be a fan of Dino Crisis.',
        submitted_by: '',
        answers: ["Dino Crisis"],
        affiliate_links: [],
        franchise: 'Dino Crisis',
        twitter_ids: [],
        release_year: '1999',
        metacritic_score: 'No Metacritic for original release platform. 74% on dreamcast',
        genre: 'Adventure, Shooter',
        console_platform: 'PS1',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '650': {
        content: 'Sayonara Wild Hearts is a rhythm game developed by Simogo and published by Annapurna Interactive. Launched in 2019, it sweeps players into a vibrant and surreal universe as they guide a heartbroken young woman on an electric pop-filled journey to mend her shattered heart. Gameplay in Sayonara Wild Hearts revolves around players steering the protagonist through kaleidoscopic environments filled with obstacles, enemies, and collectable hearts, all in sync with the pulsating pop soundtrack. The unique elements of the game include its blend of different game mechanics like endless runner style navigation, rhythm-based action, and combat, all set against a psychedelic backdrop. Its visually rich and emotionally stirring story is told through a combination of music and stylized cinematic sequences, earning recognition for its audiovisual design and narrative depth. Delivering an intoxicating mix of fast-paced gameplay and a dynamic music score, Sayonara Wild Hearts has left a lasting impression on the gaming community. If you\'re a fan of rhythm games like Thumper or the aesthetics of games like Journey, you\'ll be a fan of Sayonara Wild Hearts.',
        submitted_by: '',
        answers: ["Sayonara Wild Hearts"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sayonara%20Wild%20Hearts%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsayonara-wild-hearts-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/sayonara-wild-hearts-steam-key-global-i10000192748001?suid=28b3ae4d-fd58-407e-bcfd-ff420c8d0776?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '84%',
        genre: 'Adventure, Arcade, Indie, Music',
        console_platform: 'Mobile, PS4, Switch, PC',
        developer: 'Simogo AB',
        backloggd_link: '',
    },
    '651': {
        content: 'Broforce is a side-scrolling run-and-gun platformer developed by Free Lives and published by Devolver Digital. Released in 2015, the game drops players into a satirical, pixel-art world packed full of action movie hero archetypes. In Broforce, players take control of \'Bros\' - exaggerated parodies of action heroes like Rambo, Terminator, and RoboCop - to liberate areas from enemy forces. The gameplay is characterized by its fast-paced action, destructible environments, and cooperative play. Each \'Bro\' has uniquely exaggerated powers and abilities which brings various strategic elements to the gameplay. While the primary objective is to defeat enemies and reach the level end, players will face a host of challenges, including intense boss battles, in true over-the-top action movie style. Beyond its gameplay, Broforce is noted for its comical undertones and affectionate parody of 80s and 90s action heroes. Broforce has been lauded for its exciting multiplayer experiences, challenging gameplay, and humorous take on action movie cliches. If you\'re a fan of high-octane action games like Metal Slug or explosive platformers like Super Bomberman, you\'ll be a fan of Broforce.',
        submitted_by: '',
        answers: ["Broforce"],
        affiliate_links: ["https://www.g2a.com/broforce-gogcom-key-global-i10000015259006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '83%',
        genre: 'Run-and-gun platform',
        console_platform: 'PC',
        developer: 'Free Lives',
        backloggd_link: '',
    },
    '652': {
        content: 'QWOP is a sports-based, ragdoll-physics game developed by Bennet Foddy. Brought to the gaming scene in 2008, QWOP tosses traditional game controls out the window and challenges players to control an Olympic sprinter named QWOP, using only the Q, W, O, and P keys of the keyboard. Each key corresponds to the movement of the sprinter\'s thighs and calves respectively. The goal? Running 100 meters without falling flat on your face, which is far, far harder than it sounds due their quirky and challenging controls. Though it may first come off as simple, QWOP provides hours of fun as players come to grips with the intentionally difficult and unintuitive running mechanic, resulting in much hilarity, critical thinking, and repeated attempts to master QWOP\'s running technique. The game has been praised for its tough yet engaging gameplay and original control schema. If you\'re a fan of humorous, challenging games that offer unique control schemes like Surgeon Simulator or Octodad, you\'ll be a fan of QWOP.',
        submitted_by: '',
        answers: ["QWOP"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1761632753127219269"],
        release_year: '2008',
        metacritic_score: 'Not rated',
        genre: 'Sports',
        console_platform: 'PC',
        developer: 'Bennett Foddy',
        backloggd_link: '',
    },
    '653': {
        content: 'Metal Gear Solid 3: Snake Eater is a stealth action-adventure game developed by Konami Computer Entertainment Japan and published by Konami. Released in 2004, the game puts players in the role of Naked Snake, an operative tasked with rescuing a Soviet scientist, thus thrusting them into a world of Cold War secrecy and jungle survival. Snake Eater revolves around stealth gameplay, where players must navigate through enemy territories without being spotted. Unique features like a camouflage index, which measures the player\'s visibility, and a survival viewer, which enables the player to manage Snake\'s health and equipment, augment the stealth experience. The game notably moves the series away from its traditional industrial setting into a lush, varied Soviet wilderness, which introduces a whole new facet of survival strategies. The narrative is lauded for its dramatic, emotional depth and intricate plot woven around themes of loyalty, patriotism, and what it means to be a soldier. Metal Gear Solid 3: Snake Eater has been praised for its gripping story, innovative gameplay mechanics, and tonal shift from the series\' norm. If you\'re a fan of stealth-action games like Splinter Cell or Hitman, you\'ll be a fan of Metal Gear Solid 3: Snake Eater.',
        submitted_by: '',
        answers: ["Metal Gear Solid 3: Snake Eater", "Metal Gear Solid 3", "Metal Gear Solid 3: Subsistence"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1281787/15105?prodsku=METAL%20GEAR%20SOLID%203%20Snake%20Eater%20Master%20Collection%20Version%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmetal-gear-solid-3-snake-eater-master-collection-version-pc%2F&intsrc=PUI2_9727", "https://www.g2a.com/metal-gear-solid-3-snake-eater-master-collection-version-ps4-psn-account-global-i10000501755003?suid=9be3af9d-b2fe-4928-9213-ec73d2f2e00b?gtag=391e4ef696"],
        franchise: 'Metal Gear',
        twitter_ids: ["1762107665453359534", "1762165753283797127", "1761904255781249226", "1761875016860266544", "1762208712897208501"],
        release_year: '2004',
        metacritic_score: '91%',
        genre: 'Action-adventure, Stealth',
        console_platform: 'PS2',
        developer: 'Konami Computer Entertainment Japan',
        backloggd_link: '',
    },
    '654': {
        content: 'The Witcher 2: Assassins of Kings is an ambitious action role-playing game developed by CD Projekt Red. The game, released in 2011, drops players into a richly detailed fantasy world inspired by Slavic mythology, where they play as Geralt of Rivia, a skilled \"witcher,\" or monster hunter. In Assasins of Kings, Geralt finds himself embroiled in a complex plot involving the assassination of kings, political intrigue, and mystic forces. Featuring a mature, multi-layered narrative and a vast world to explore, the game focuses on consequence-laden decision-making, branching narrative paths, and morally ambiguous choices. Players battle foes with a combination of magical abilities and traditional weaponry, with gameplay emphasizing strategy over brute force. The game also offers a variety of mini-games and side quests, adding depth and variety to its immersive world. The Witcher 2\'s impressive visuals, nuanced story, and robust combat system have been celebrated by players and critics alike. If you\'re a fan of action-RPGs with branching narratives and deep, immersive worlds such as the Elder Scrolls series or Dragon Age, The Witcher 2: Assassins of Kings will be right up your alley.',
        submitted_by: '',
        answers: ["The Witcher 2: Assassins of Kings", "The Witcher 2"],
        affiliate_links: ["https://www.g2a.com/the-witcher-2-assassins-of-kings-enhanced-edition-steam-gift-global-i10000008049004?gtag=391e4ef696"],
        franchise: 'Witcher',
        twitter_ids: ["1762518245347578299", "1762460919668363550"],
        release_year: '2011',
        metacritic_score: '88%',
        genre: 'Action, RPG',
        console_platform: 'PC',
        developer: 'CD Projekt Red',
        backloggd_link: '',
    },
    '655': {
        content: 'Don\'t Starve is a survival game developed and published by the Canadian indie company Klei Entertainment. Launched in 2013, this game presents a gloomy open-world wilderness filled with bizarre creatures and mysterious oddities. Players take on the role of a scientist named Wilson, who finds himself transported to a dark and surreal wilderness by a demon. The prime goal is to survive as many days as possible, foraging for food, crafting survival gear, and making sure Wilson doesn\'t lose his sanity in the process. Don\'t Starve\'s gameplay hinges on survival and exploration, combining elements of action, strategy, and roguelike games with a distinctive art style that’s both whimsical and eerie. Players must harness their resourcefulness and adaptability in the ever-changing, procedurally-generated landscapes filled with unknown perils. The game stands out for its punishing difficulty, extensive crafting system, and the unique mechanic of sanity alongside hunger and health, all contributing to its highly tactical and strategic gameplay. If you\'re a fan of survival games like Minecraft, Terraria or roguelike games like The Binding of Isaac, you\'ll be a fan of Don\'t Starve.',
        submitted_by: '',
        answers: ["Don't Starve", "Don\u2019t Starve Together"],
        affiliate_links: ["https://www.g2a.com/dont-starve-steam-gift-global-i10000003621004?gtag=391e4ef696"],
        franchise: 'Don\'t Starve',
        twitter_ids: ["1762822089713537102", "1762733391428944327", "1762857415697985868", "1762617781067853996", "1762822722172682251"],
        release_year: '2013',
        metacritic_score: '78%',
        genre: 'Survival',
        console_platform: 'PC',
        developer: 'Klei Entertainment',
        backloggd_link: '',
    },
    '656': {
        content: 'Doom II is a first-person shooter video game developed by id Software and published by GT Interactive. Released in 1994, it serves as a direct sequel to the pioneer of first-person shooters - Doom. In this installment, the protagonist, Doomguy, returns from hell to find Earth overrun by the same demonic forces. The game retains the adrenaline-pumping gameplay of the original Doom, tasking players with carving their way through swarms of horrific demons in fast-paced, intense battles. Packaged with a vast arsenal of weaponry, players navigate through levels laden with secret areas, using keys to unlock different path sections. The game also expanded the multiplayer functionality introducing cooperative play and deathmatches. Doom II was lauded for its non-stop action, gory visuals, and the genius of its level design that provided equal measures of brain and brawn. It significantly influenced the first-person shooter genre and cemented its status among the iconic games of the 90s. If you\'re a fan of thrilling and action-packed first-person shooter games like Wolfenstein or Quake, then Doom II will be right up your alley.',
        submitted_by: '',
        answers: ["Doom II", "Doom 2", "Doom II: Hell on Earth"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DOOM%20Classic%20Complete%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdoom-classic-complete%2F&intsrc=PUI2_9608", "https://www.g2a.com/doom-ii-steam-key-global-i10000008942003?suid=c3cf2d12-dea5-4fae-bc86-c2e583472781?gtag=391e4ef696"],
        franchise: 'Doom',
        twitter_ids: ["1763255694231290090", "1763217306509861326", "1763096066470125672", "1763231675692028129", "1763152729948717497"],
        release_year: '1994',
        metacritic_score: '77%',
        genre: 'First-person shooter',
        console_platform: 'PC',
        developer: 'id Software',
        backloggd_link: '',
    },
    '657': {
        content: 'Clash Royale is a real-time multiplayer game developed and published by Supercell. Released in 2016, it drops players into a fast-paced battle arena where the objective is to destroy the most opposing towers, with the ultimate goal of toppling the opponent\'s King\'s tower. As a player, you assume the role of a commander, deploying various troops represented by a deck of cards onto the battlefield. Combining elements of collectible card games, tower defense, and multiplayer online battle arena (MOBA), Clash Royale offers a unique blend of strategy and action. Players collect and upgrade a variety of cards featuring Clash of Clans troops, spells, and defenses, and famous Royales: Princes, Knights, Baby Dragons etc. The gameplay emphasizes decision-making and timing, rewarding strategic deck building and smart troop placement. ladder, tournament, friendly match and clan wars modes offer a range of thrilling battles. Clash Royale is celebrated for its compelling real-time strategy gameplay and the exciting mix of tactical decision-making. If you\'re a fan of real-time strategy games like Age of Empires or multiplayer online battle arena games like League of Legends and DOTA 2, you\'re going to love Clash Royale.',
        submitted_by: '',
        answers: ["Clash Royale"],
        affiliate_links: [],
        franchise: 'Clash',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '86%',
        genre: 'Real-time Strategy',
        console_platform: 'Mobile',
        developer: 'Supercell',
        backloggd_link: '',
    },
    '658': {
        content: 'Rogue Legacy is a genealogical rogue-lite game developed and published by Cellar Door Games. Released in 2013, the game welcomes players to a procedurally generated castle where death is just the beginning of the adventure. In Rogue Legacy, you take on the role of a unique descendent of your previous character every time you die. Each descendant has their own traits and abilities, which sparks new challenges and gameplay dynamics with every playthrough. On their journey, players traverse through a constantly evolving castle, battling monstrous inhabitants and collecting treasures to upgrade their deceased ancestor\'s manor, providing subsequent descendants with enhanced abilities. Its clever mix of platforming, dungeon crawler aesthetics, and RPG mechanics painted against the backdrop of a quirky and humorous genealogy concept makes this game a delightful blend of challenging gameplay and compelling narratives. Rogue Legacy has been recognized for its innovative character inheritance system, strategic depth, and engrossing progression elements. If you\'re a fan of roguelike platformers such as Dead Cells or Spelunky, you\'ll be a fan of Rogue Legacy.',
        submitted_by: '',
        answers: ["Rogue Legacy"],
        affiliate_links: ["https://www.g2a.com/rogue-legacy-gogcom-key-global-i10000000064008?gtag=391e4ef696"],
        franchise: 'rogue legacy',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '85%',
        genre: 'Platformer, roguelike, metroidvania',
        console_platform: 'PC',
        developer: 'Cellar Door Games',
        backloggd_link: '',
    },
    '659': {
        content: 'Super Hexagon is an indie minimal action game developed by Terry Cavanagh. Launched in 2012, players steer a small triangle trying to avoid incoming walls in pulse-pounding sessions. Presented as a series of hexagonal structures, players face an overload of visual and auditory stimuli. As the game proceeds, obstacles move faster, and patterns get more complex, with players only armed with the ability to navigate their triangle left or right. The gameplay of Super Hexagon is a masterful study in simplicity and attention orientation. One unique feature is the dynamic nature of the game soundtrack that syncs and transforms with the pace of the gameplay adding another layer of immersive experience. High-speed, rhythm, and spatial awareness are critical for survival in this game characterized by its challenging difficulty, addictive gameplay, and distinctive electronic soundtrack. Super Hexagon\'s addictive simplicity, pulsing visuals, and hypnotic music have all contributed to its critically acclaimed status in the indie game community. If you\'re a fan of minimalist yet challenging games like VVVVVV or rhythm-based games like Beat Saber, you\'ll be a fan of Super Hexagon.',
        submitted_by: '',
        answers: ["Super Hexagon"],
        affiliate_links: ["https://www.g2a.com/super-hexagon-steam-key-global-i10000000343003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1764214072378339698", "1764307496938709096", "1764178135833067779", "1764365013408465158", "1764052401013608935"],
        release_year: '2012',
        metacritic_score: '85%',
        genre: 'Action, twitch',
        console_platform: 'iOS',
        developer: 'Terry Cavanagh',
        backloggd_link: '',
    },
    '660': {
        content: 'Fire Emblem Warriors is a hack-and-slash action RPG developed by Omega Force and Team Ninja, and published by Nintendo. Released in 2017, it combines the tactical strategy elements of the Fire Emblem series with the thrilling combat of Dynasty Warriors. In the game, players control various characters from different Fire Emblem installments as they battle to save their kingdom from an interdimensional threat. As well as fighting hordes of enemies, players must make strategic decisions, whether it\'s commanding units on the battlefield, choosing the right heroes for each mission or forming relationships between characters, boosting their abilities. Unique to the game is the Pair Up system, allowing two characters to unite into a single unit, capable of delivering devastating dual attacks. Fire Emblem Warriors has been lauded for its exciting crossover concept, fast-paced battles, and strategic depth, providing an interesting spin on both franchises. If you\'re a fan of the strategic combat in games like Valkyria Chronicles or the dynamic action in titles such as Hyrule Warriors, you\'ll be a fan of Fire Emblem Warriors.',
        submitted_by: '',
        answers: ["Fire Emblem Warriors"],
        affiliate_links: ["https://www.g2a.com/fire-emblem-warriors-nintendo-eshop-nintendo-switch-key-europe-i10000169624001?suid=9ed32e0a-28dc-4c63-94e4-d14684e70982?gtag=391e4ef696"],
        franchise: 'Fire Emblem',
        twitter_ids: ["1764487927214625125", "1764524918383792436", "1764661639423406389"],
        release_year: '2017',
        metacritic_score: '74%',
        genre: 'Hack and slash, Action, RPG',
        console_platform: 'Switch, 3DS',
        developer: 'Omega Force, Team Ninja',
        backloggd_link: '',
    },
    '661': {
        content: 'Tekken 3 is a fighting game developed and published by Namco, the third installment in the renowned Tekken series. Released in 1997, it transports players into a world of high-octane martial arts. Players step into the shoes of a multitude of characters each boasting their unique fighting styles as they engage in intense one-on-one battles. Set in the near future, the story revolves around the Mishima corporation\'s quest for world domination and the struggle to stop them. The gameplay in Tekken 3 emphasizes a more fluid combat system with more emphasis on the third axis, allowing characters to sidestep in or out of the background. This improvement, coupled with more dynamic speed and improved graphics, gives players a more immersive fighting experience. Side games like Tekken Force and Tekken Ball add extra enjoyment beyond traditional combat. Besides its riveting gameplay, the game is also recognized for its extensive character roster and engaging storyline. Tekken 3 has been hailed for its deep fighting mechanisms and its incredible contribution to the 3D fighting genre. If you\'re a fan of intense fighting games like Street Fighter or Mortal Kombat, you\'ll be a fan of Tekken 3.',
        submitted_by: '',
        answers: ["Tekken 3"],
        affiliate_links: [],
        franchise: 'Tekken',
        twitter_ids: ["1764902779741483363", "1764985107293544710", "1764789044792131608", "1765000737245757699"],
        release_year: '1997',
        metacritic_score: '96%',
        genre: 'Fighting',
        console_platform: 'Arcade',
        developer: 'Namco',
        backloggd_link: '',
    },
    '662': {
        content: 'Thumper is a critically acclaimed rhythm game developed and published by Drool. Released in 2016, Thumper pushes players into the unsettling world of a space beetle braving a hellish void to confront a giant, maniacal head. In Thumper, players navigate through nine high-speed levels, keeping in time with the dark, original soundtrack to survive various obstacles and fight daunting bosses. The gameplay in Thumper skillfully blends elements of rhythm and action genres, as players must not only keep up with the tempo but also react quickly to on-screen prompts and environmental hazards. Thumpers simple controllers, combined with its intense speed, result in an addictive and heart-pounding gaming experience. The game\'s rhythm-based gameplay creates a deep sense of immersion, helped by its stunning, often psychedelic visuals, which earned it several industry accolades. Thumper has been lauded for its innovative rhythm action mechanics, gripping soundtrack, and the intense, visceral sensation it delivers. If you\'re a fan of rhythm games like AudioSurf or adventure games like The Void, you\'ll be a fan of Thumper.',
        submitted_by: '',
        answers: ["Thumper"],
        affiliate_links: ["https://www.g2a.com/thumper-steam-gift-global-i10000028768002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1765475745130823750"],
        release_year: '2016',
        metacritic_score: '84%',
        genre: 'Rhythm',
        console_platform: 'PC, PS4, PS VR',
        developer: 'Drool',
        backloggd_link: '',
    },
    '663': {
        content: 'Dragon Quest Builders is a sandbox action role-playing game developed and published by Square Enix. Launched in 2016, the game offers an innovative take on the popular Dragon Quest universe, combining the series\' iconic RPG mechanics with Minecraft-like block-building gameplay. Players immerse themselves in the role of the Builder, who, in the aftermath of the world\'s destruction by the Dragonlord, is tasked with rebuilding civilization by gathering materials and crafting a variety of structures. Central gameplay mechanics include exploration, quest completion, combat, and structure building. The storyline in Dragon Quest Builders takes a unique spin on the outcome of the original Dragon Quest, allowing players to rewrite history in their own way, providing a fresh experience even for long-time series fans. It\'s a game commended for its creative playstyle, charming art style, and engaging storyline. If you\'re a fan of creative RPG games like Minecraft or Terrarria intermingled with traditional role-playing elements seen in Final Fantasy or The Legend of Zelda, you would indeed be a fan of Dragon Quest Builders.',
        submitted_by: '',
        answers: ["Dragon Quest Builders"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=DRAGON%20QUEST%20BUILDERS%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdragon-quest-builders-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dragon-quest-builders-pc-steam-key-global-i10000170099002?gtag=391e4ef696"],
        franchise: 'Dragon Quest',
        twitter_ids: ["1765723595307683989"],
        release_year: '2016',
        metacritic_score: '83%',
        genre: 'Action, RPG, Sandbox',
        console_platform: 'PS3, PS4, PS Vita',
        developer: 'Square Enix',
        backloggd_link: '',
    },
    '664': {
        content: 'Ice Climber is a vertical platformer game developed and published by Nintendo. Released in 1985, the game takes players on an adventurous climb up icy mountains as the sibling characters Popo and Nana, collectively known as the Ice Climbers. The game\'s objective is to break through a series of icy platforms with a mallet while avoiding various enemies like seals and birds. Features like bonus vegetables to collect for extra points and the opportunity to hitch a ride on a condor for bonus round, add depth and strategy to the game. Ice Climber has been recognized for its tightly combined elements of platform jumping and combat, with its uniquely designed levels providing significant replayability. The game also takes advantage of the vertical scrolling feature, a unique concept during its time of release. Ice Climber is known for its challenging gameplay and has been considered a classic in Nintendo\'s roster of games. If you\'re a fan of classic, challenging, and unique platformer games like Super Mario Bros or Donkey Kong, you\'ll be a fan of Ice Climber.',
        submitted_by: '',
        answers: ["Ice Climber"],
        affiliate_links: [],
        franchise: 'Ice Climber',
        twitter_ids: ["1765869874105004332"],
        release_year: '1985',
        metacritic_score: '66%',
        genre: 'Platform',
        console_platform: 'Arcade, NES',
        developer: 'Nintendo',
        backloggd_link: '',
    },
    '665': {
        content: 'Velocity 2X is an enticing blend of fast-paced shoot \'em up and side-scrolling action platformer developed and published by FuturLab. Released in 2014, the game sees players assume the role of Lt. Kai Tana, a fighter pilot lost in a distant galaxy, who with her high-tech \"Quarp Jet\" spacecraft, must navigate through numerous space sectors, all while battling hostile alien forces. The gameplay in Velocity 2X is split between top-down space combat, where players pilot the Quarp Jet, and on-foot platforming sequences, where players control Tana directly. What sets Velocity 2X apart is its emphasis on speed and precision, with levels often featuring a mix of rapid-scrolling navigation, bullet-dodging aerobatics, and precision bombing in spacecraft sections plus fast-paced shooting and telemetric teleportation in on-foot sections. Successful advancement requires not just reflexes, but a keen understanding of each level\'s design. Velocity 2X is lauded for its seamless blend of genres, fluid controls, and a schismatic gameplay experience. If you\'re a fan of intense shoot \'em up games such as Ikaruga or fast-paced platformers like Celeste, you\'ll be a fan of Velocity 2X.',
        submitted_by: '',
        answers: ["Velocity 2X"],
        affiliate_links: ["https://www.g2a.com/velocity-2x-steam-key-global-i10000004752002?suid=19782643-f525-4e1e-b45b-d03e8f3bdd4f?gtag=391e4ef696"],
        franchise: 'Velocity',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '86%',
        genre: 'Platformer, Puzzle, Shooter',
        console_platform: 'PS Vita, PS4',
        developer: 'Futurlab Ltd.',
        backloggd_link: '',
    },
    '666': {
        content: 'Pokémon Sleep is an innovative mobile game developed and published by Nintendo and The Pokémon Company. Launched in 2023, this game introduces a unique concept; it aims to turn sleep into entertainment by rewarding players for their sleeping habits. In Pokémon Sleep, players\' sleep patterns and the amount of time spent sleeping affect the gameplay, which ties in with the overall Pokémon theme of training and evolution. The game is primarily meant to be played before bedtime and throughout the night, providing a whole new segment of gameplay unlike the core series. It strives to enhance the player’s lifestyle by blending game and real-life sleep, making Pokémon Sleep an inventive expedition in the world of gaming. The game has been appreciated for its distinctive gameplay mechanics and its encouraging contribution to healthy sleep habits. If you\'re a fan of lifestyle-focused games such as Pokémon Go and Walkr: Fitness Space Adventure, you\'ll likely enjoy Pokémon Sleep.',
        submitted_by: '',
        answers: ["Pok\u00e9mon Sleep", "Pokemon Sleep"],
        affiliate_links: [],
        franchise: 'Pokemon',
        twitter_ids: ["1766704789063180473", "1766924578473816413", "1766819542003589360", "1766597903395545509"],
        release_year: '2023',
        metacritic_score: 'Not reviewed',
        genre: 'Simulator?, Tracker',
        console_platform: 'Mobile',
        developer: 'SELECT BUTTON Inc.',
        backloggd_link: '',
    },
    '667': {
        content: 'Like a Dragon: Infinite Wealth is a role-playing game developed by Ryu Ga Gotoku Studio and published by SEGA. The game was released in 2024 and continues the legacy of the iconic Yakuza series, casting players in a new storyline set in Japan\'s gritty criminal underworld. Players get to embody an ambitious new character, whose ultimate quest is to amass unimaginable wealth. A unique blend of intense street brawls, strategic team management, and real-time battles permeate the gameplay. As the title suggests, the acquisition and management of wealth form a core part of the game, with players leveraging businesses, investments, and alliances to grow their empire. Numerous subplots and side missions supplement the engaging main story, offering opportunities to further understand the character and delve deeper into the rich narrative. The game\'s expansive cityscape, filled with vibrant nightlife and myriad opportunities, provides an immersive backdrop to the thrilling yakuza journey. Like a Dragon: Infinite Wealth is celebrated for its absorbing narrative, strategic gameplay, and faithful rendition of urban Japan. If you\'re a fan of deep, narrative-driven games like Persona 5 or gritty open-world adventures like the Grand Theft Auto series, you\'ll be a fan of Like a Dragon: Infinite Wealth.',
        submitted_by: '',
        answers: ["Like a Dragon: Infinite Wealth"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Like%20a%20Dragon%20Infinite%20Wealth%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Flike-a-dragon-infinite-wealth-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/like-a-dragon-infinite-wealth-ultimate-edition-pc-steam-account-global-i10000502561031?gtag=391e4ef696"],
        franchise: 'Yakuza',
        twitter_ids: ["1767163887256736043", "1766949369733931156"],
        release_year: '2024',
        metacritic_score: '89%',
        genre: 'Adventure, RPG',
        console_platform: 'PC, PS4, XONE, PS5, Xbox Series',
        developer: 'Ryu Ga Gotoku Studio',
        backloggd_link: '',
    },
    '668': {
        content: 'Wolfenstein II: The New Colossus is a first-person shooter game developed by MachineGames and published by Bethesda Softworks, a sequel to the 2014 game Wolfenstein: The New Order. In this 2017 release, players control war hero B.J. Blazkowicz, who rallies the rebellious American resistance fighters to take back control from the Nazi regime. Set in a chilling alternate history where the Nazis won World War II, the storyline entails invigorating heroics, harrowing cliffhangers, and blood-pumping action sequences. Gameplay mechanics in this shooter are fast-paced and fluid, with an arsenal of weapons which can be dual wielded for ruthless onslaughts. The game offers a blend of stealth and run-and-gun styles of play, allowing players to choose their approach to various encounters. Additional to the campaign, character-rich side-missions, upgradeable skill sets and a vast and detailed environment for exploration supplement depth to the gameplay. Known for its immersive narrative with weighty themes, impressive visual fidelity, and an exhilarating combat system, Wolfenstein II: The New Colossus is a game that keeps the adrenaline pumping. If you\'re a fan of intense first-person shooters like Doom or the Far Cry series, you\'ll be a fan of this one.',
        submitted_by: '',
        answers: ["Wolfenstein II: The New Colossus", "Wolfenstein 2: The New Colossus"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Wolfenstein%20II%20The%20New%20Colossus%20Digital%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwolfenstein-ii-the-new-colossus-digital-deluxe-edition%2F&intsrc=PUI2_9608", "https://www.g2a.com/wolfenstein-ii-the-new-colossus-digital-deluxe-edition-pc-steam-key-global-i10000083640001?gtag=391e4ef696"],
        franchise: 'Wolfenstein',
        twitter_ids: ["1767519831882973537"],
        release_year: '2017',
        metacritic_score: '86%',
        genre: 'FPS',
        console_platform: 'PC, PS4, XONE',
        developer: 'ZeniMax Sweden AB/MachineGames',
        backloggd_link: '',
    },
    '669': {
        content: 'Blood Bowl III is a turn-based strategy sports video game developed by Cyanide and published by Nacon. Launched in 2023, it\'s the bloodthirsty and chaotic meeting point of Warhammer\'s grim dark universe and American football, where players engage in a deadly clash masquerading as a sports match. Players take control of classic fantasy races, such as the brutal Orks or agile Wood Elves, and strategically plan out their moves on the field, with the aim being as much about decimating the opposing team as scoring touchdowns. This iteration has brought in new teams, rules, and improved visuals, continuing the franchise\'s legacy of deep grid-based strategy and bloody, over-the-top spectacle. Blood Bowl III is celebrated for its unique blend of strategic depth and tongue-in-cheek, violent humor twisted within a sports game concept. The appeal lies in its robust tactics, irreverent spin on sports mechanics, and the unpredictable moments created by dice-roll outcomes. If you\'re a fan of strategy games like XCOM: Enemy Unknown or comically violent games like Orcs Must Die!, you\'ll be a fan of Blood Bowl III.',
        submitted_by: '',
        answers: ["Blood Bowl III", "Blood Bowl 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Blood%20Bowl%203%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fblood-bowl-3-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/blood-bowl-3-pc-steam-gift-global-i10000251057004?gtag=391e4ef696"],
        franchise: 'Blood Bowl',
        twitter_ids: ["1767881568670953644"],
        release_year: '2023',
        metacritic_score: '60%',
        genre: 'RPG, Sport?,Turn-based strategy (TBS)',
        console_platform: 'PC, PS4, XONE, PS5, Xbox Series',
        developer: 'Cyanide S.A.',
        backloggd_link: '',
    },
    '670': {
        content: 'Warcraft II: Tides of Darkness is a fantasy-themed real-time strategy game developed by Blizzard Entertainment and sequel to the groundbreaking original Warcraft. The game plunges players into the war-torn world of Azeroth, split between the hostile orcish Horde and the steadfast human Alliance. As an epic saga unfolds, players are tasked with gathering resources, building bases, commanding armies, and wiping out enemy forces spread across a variety of landscapes. The sequel capitalized on the winning formula of its predecessor by serving up larger maps, more unit types, and a richer storyline interspersed with grand campaign missions and intense battles. It also introduced naval and aerial units—a unique dimension to warfare at the time. Players can choose between playing the campaigns sequentially to follow the story, or dive into stand-alone scenarios for instant battlefield action. The inclusion of a multiplayer mode cannot be overlooked, fostering intense competitive play. Warcraft II: Tides of Darkness has been acclaimed for its engaging campaigns, strategic depth, and multiplayer component. If you\'re a fan of real-time strategy games like Age of Empires or StarCraft, you\'ll be a fan of Warcraft II: Tides of Darkness.',
        submitted_by: '',
        answers: ["Warcraft II: Tides of Darkness", "WarCraft 2", "WarCraft II", "Warcraft II: Beyond the Dark Portal"],
        affiliate_links: ["https://www.g2a.com/warcraft-i-ii-bundle-gogcom-key-global-i10000186336001?gtag=391e4ef696"],
        franchise: 'Warcraft',
        twitter_ids: ["1768034701980152131", "1768248283179143663", "1768200305227804692"],
        release_year: '1995',
        metacritic_score: 'Original Release too old for metacritic',
        genre: 'Real-Time Strategy',
        console_platform: 'DOS',
        developer: 'Blizzard Entertainment Inc.',
        backloggd_link: '',
    },
    '671': {
        content: 'Fire Emblem Engage is a tactical RPG developed by Intelligent Systems and published by Nintendo. Launched in 2023, this heart-racing installment in the legendary Fire Emblem series transports players to a vividly-realized fantasy world, as they command and forge relationships with an assortment of unique characters. In Fire Emblem Engage, the classic turn-based tactical combat is enhanced, offering an immersive blend of strategic planning and real-time action. Players are compelled to meticulously position units and exploit enemy weaknesses, with significant emphasis on unit relationships and character development. The game\'s unique feature is the Engage System, where bonds between characters can affect gameplay, unlock new abilities, and result in powerful combo attacks. The game\'s captivating storyline, populated with richly-drawn characters, provides ample motivation to fight through grueling battles. Fire Emblem Engage has garnered praise for its intricate gameplay mechanics, compelling narrative, and the extraordinary depth of its unit customization. If you\'re a fan of strategic games imbued with character-driven narratives like Persona 5 or Final Fantasy Tactics, you\'ll be a fan of Fire Emblem Engage.',
        submitted_by: '',
        answers: ["Fire Emblem Engage"],
        affiliate_links: ["https://www.g2a.com/fire-emblem-engage-nintendo-switch-nintendo-eshop-key-north-america-i10000338191003?gtag=391e4ef696"],
        franchise: 'Fire Emblem',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '80%',
        genre: 'RPG, Tactical, Turn-based strategy',
        console_platform: 'Switch',
        developer: 'Intelligent Systems Co., Ltd.',
        backloggd_link: '',
    },
    '672': {
        content: 'Star Wars: Lethal Alliance is an action-adventure game developed by Ubisoft Casablanca and published by Ubisoft. Released in 2006, players are whisked away to a galaxy far, far away where they play as Rianna Saren and her security droid Zeeo, a duo tasked with a covert mission to steal the plans for the Death Star. Through gameplay that combines combat, puzzle-solving and exploration, players traverse various iconic Star Wars locations including Tatooine, Mustafar, and the Death Star itself. Most distinctive to this game are its acrobatic movement and teamwork mechanics: whether it\'s using Zeeo as a platform to reach inaccessible areas, or operating him as a turret, team-based strategies are the core of advancing. Star Wars: Lethal Alliance stands out for its unique storyline that weaves itself into the larger Star Wars narrative, the bond between its two protagonists, and its action-packed gameplay. If you\'re a fan of the Star Wars-themed action games like Star Wars: The Force Unleashed or the puzzle-solving adventure games like Uncharted series, you\'ll be a fan of Star Wars: Lethal Alliance.',
        submitted_by: '',
        answers: ["Star Wars: Lethal Alliance"],
        affiliate_links: [],
        franchise: 'Star Wars',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '61%',
        genre: 'Action, Adventure, 3rd Person',
        console_platform: 'PSP',
        developer: 'Ubisoft Entertainment Inc.',
        backloggd_link: '',
    },
    '673': {
        content: 'Super Auto Pets is a free-to-play strategy auto battler game developed and published by Team Wood Games. Released in 2021, it places players in the colorful and whimsical world of automated animal battles. Assuming the role of a petaurant owner, players gather and upgrade a team of adorable animal pets, such as penguins, hippos, and kangaroos, to compete in automated PvP combat against other players from around the world. The game has a fast-paced and strategic gameplay. The success in battles heavily depends on the robust lineup of pets, their synergies, and tactical positioning before each round starts. Each pet possesses unique abilities, creating a vast number of strategic possibilities. As the game progresses, players can level up their pets and unlock new ones to strengthen their team. Super Auto Pets has been lauded for its addictive gameplay, charming aesthetics, and the depth of its strategy. Despite its cute surface, it offers a deeply competitive metagame for those willing to delve into it. If you\'re a fan of strategy auto battler games like Hearthstone\'s Battlegrounds or Teamfight Tactics, you\'ll be a fan of Super Auto Pets.',
        submitted_by: 'https://www.youtube.com/@Meoin',
        answers: ["Super Auto Pets"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: 'No score',
        genre: 'Turn-based strategy, Auto Battler',
        console_platform: 'Browser, Mobile, PC',
        developer: 'Team Wood Games ApS',
        backloggd_link: '',
    },
    '674': {
        content: 'Prince of Persia: The Lost Crown is an action-adventure game developed and published by Ubisoft. Launched in 2024, it continues the iconic Prince of Persia series, challenging players to reclaim the lost crown and restore Prince\'s rightful rule against a plethora of tyrannical forces. Players embark on a journey through richly designed environments, including intricately designed palaces, bustling bazaars, and treacherous dungeons. The gameplay combines acrobatic platforming with tactical combat, featuring a blend of parkour-inspired movement and sword fighting against formidable foes. Unique to the game, a time manipulation mechanic gives players the power to rewind time, adding an intricate layer to both puzzle-solving and combat. For exploration, players can take advantage of the Prince\'s agility to navigate the detailed environments, unveiling ancient secrets and strategic routes. Prince of Persia: The Lost Crown has been praised for its satisfying blend of acrobatic exploration, real-time combat, puzzle-solving, and compelling narrative. If you\'re a fan of adventure games with fluid movement and tactical combat like Assassin\'s Creed or Shadows of Mordor, you\'ll be a fan of Prince of Persia: The Lost Crown.',
        submitted_by: '',
        answers: ["Prince of Persia: The Lost Crown"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Prince%20of%20Persia%20The%20Lost%20Crown%20Deluxe%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fprince-of-persia-the-lost-crown-deluxe-edition-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/prince-of-persia-the-lost-crown-deluxe-edition-xbox-series-x-s-xbox-live-key-global-i10000502454015?gtag=391e4ef696"],
        franchise: 'Prince of Persia',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '84%',
        genre: 'Adventure, Platform',
        console_platform: 'PC, PS4, XONE, Switch, PS5, Xbox Series',
        developer: 'Ubisoft Montpellier SAS',
        backloggd_link: '',
    },
    '675': {
        content: 'Brink is a first-person shooter game with a focus on parkour-style movement, developed by Splash Damage and published by Bethesda Softworks. Released in 2011, it is set in a dystopian future where the Earth is devastated by climate change. Players are thrust into the heated conflict between the Resistance, who want to leave the floating city known as \'The Ark\', and Security, who want to protect the city against the Resistance. In Brink, battles play out in objective-based campaigns, using an intricate mix of gunplay, group dynamics and the SMART (Smooth Movement Across Random Terrain) system, which allows for seamless navigation through the environment. Noteworthy is the game\'s online feature where campaigns can be played cooperatively or competitively, blurring the lines between single and multiplayer modes. Brink\'s mix of fast-paced gameplay, innovative parkour mechanics and a distinct approach to character customization, paints an engaging picture of a bleak future. Brink has been complimented for its innovative movement system, unique art style, and the variety in its gameplay. If you\'re a fan of titles with robust multiplayer dynamics like Team Fortress 2 or objective-oriented shooters like Dirty Bomb, you\'ll be a fan of Brink.',
        submitted_by: '',
        answers: ["Brink"],
        affiliate_links: ["https://www.g2a.com/brink-complete-dlc-pack-steam-key-global-i10000046864002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2011',
        metacritic_score: '70%',
        genre: 'Shooter',
        console_platform: 'PC, X360, PS3',
        developer: 'Splash Damage, Ltd',
        backloggd_link: '',
    },
    '676': {
        content: 'Tony Hawk\'s American Wasteland is an extreme sports video game developed by Neversoft and published by Activision. Launching in 2006, it offers players a thrilling skateboarding experience set within a vast, open-ended world inspired by Los Angeles and its famous skate culture. In the storyline, you control a skateboarder seeking to make a name for themselves on the American West coast, undertaking various missions and challenges given by NPCs. The game incorporates a mix of skateboard riding, tricks, and acrobatics, focusing on both technical execution and extreme, adrenaline-filled stunts. Unlike its predecessors, American Wasteland presents a continuous streaming city for a more immersive experience, eliminating the load times between different sections of the city. It introduces new features like the ability to ride BMX bikes and perform a wider repertoire of tricks. Tony Hawk\'s American Wasteland has been well received for its expansive open-world environment, deep trick system, and addictive gameplay. If you\'re a fan of extreme sports games like Skate or SSX, you\'ll be a fan of Tony Hawk\'s American Wasteland.',
        submitted_by: '',
        answers: ["Tony Hawk's American Wasteland"],
        affiliate_links: [],
        franchise: 'Tony Hawk',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '77%',
        genre: 'Sport',
        console_platform: 'PS2, PC, X360, GameCube, Xbox',
        developer: 'Neversoft Entertainment, Inc.',
        backloggd_link: '',
    },
    '677': {
        content: 'Fallout 2 is a post-apocalyptic RPG that takes place in a world devastated by nuclear war. As the player, you take control of a character who emerges from the safety of a Vault to venture out into the wasteland, embarking on an epic adventure that sees you battling mutants, raiders, and hostile factions in a struggle to survive.    The game world is vast and immersive, filled with danger and opportunity at every turn. You are free to explore the wasteland at your leisure, taking on quests, interacting with NPCs, and fighting your way through a variety of challenging environments.    Along the way, you\'ll encounter a range of interesting characters, each with their own unique personalities, motivations, and backstories. You\'ll be forced to make tough choices and weigh the consequences of your actions as you navigate this complex, morally ambiguous world.    Fallout 2 is a masterpiece of game design, with a rich, deep story that keeps players engaged for hours on end. Whether you\'re a fan of RPGs, post-apocalyptic worlds, or just good old-fashioned storytelling, this game is a must-play.',
        submitted_by: '',
        answers: ["Fallout 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Fallout%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffallout-2-a-post-nuclear-role-playing-game%2F&intsrc=PUI2_9608", "https://www.g2a.com/fallout-2-steam-key-global-i10000005800004?suid=6f8d826d-615d-4c6e-8474-ee9ac6bba74d?gtag=391e4ef696"],
        franchise: 'Fallout',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '86%',
        genre: 'RPG, Adventure, 3rd-person',
        console_platform: 'PC',
        developer: 'Black Isle Studios',
        backloggd_link: '',
    },
    '678': {
        content: 'The Forgotten City is a story-rich exploration game steered by clever puzzles and dialogue, developed by Modern Storyteller and released in 2021. The game transports players back in time to an eerily beautiful and cursed Roman city trapped in a repeating time-loop. As the protagonist, the player must navigate a tangled web of intrigue and mystery to unravel the secret binding the city to its time-locked fate. The game puts a strong emphasis on dialogue, interaction, and moral choices, demanding players outwit or charm different characters to advance the plot. One of the fascinating aspects of this game is its use of the \"Golden Rule;\" if anyone within the city sins, everyone will be punished. This setup provides for a unique, morally challenging gaming experience where every decision can have far-reaching consequences. The Forgotten City shines with its intricate story, morality-based mechanics, and the well-realized ancient Roman setting. If you\'re a fan of narrative-driven, choice-consequential games like BioShock and The Witcher series, you\'ll be a fan of The Forgotten City.',
        submitted_by: '',
        answers: ["The Forgotten City"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Forgotten%20City%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-forgotten-city-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-forgotten-city-pc-steam-gift-global-i10000264192003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '85%',
        genre: 'Mystery, Adventure, RPG',
        console_platform: 'PC, PS4, XONE, PS5, Xbox Series',
        developer: 'Modern Storyteller Pty Ltd',
        backloggd_link: '',
    },
    '679': {
        content: '',
        submitted_by: '',
        answers: ["Pacific Drive"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Pacific%20Drive%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpacific-drive-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/pacific-drive-deluxe-edition-pc-steam-gift-global-i10000502805004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '79%',
        genre: 'Action, Driving, Simulation, Thriller',
        console_platform: 'PS5, PC',
        developer: 'Ironwood Studios LLC',
        backloggd_link: '',
    },
    '680': {
        content: 'Splitgate is a free-to-play first-person shooter game developed and published by 1047 Games. Released in 2022, Splitgate cleverly blends fast-paced, reflex-oriented shooter mechanics with a unique portal system, drawing comparisons to both Halo and Portal. Players assume the role of a high-tech soldier in visually dynamic arenas. The game’s standout feature is the ability for players to create portal pairs on specific surfaces, allowing for instantaneous travel across the map or creative flanking manoeuvres. These portal mechanics introduce a tactical layer that innovates on traditional shooter gameplay. The arena-style maps and fast-paced gunplay provide a competitive experience, whilst the portals offer a strategic edge that keeps players on their toes. Each match in Splitgate quickly becomes a chaotic, playful and strategic showdown. Splitgate has been lauded for its seamless blend of first-person shooter mechanics with tactical portal usage, creating a fresh and thrilling experience. If you\'re a fan of strategic shooter games like Halo or Apex Legends, or enjoy the inventive gameplay mechanics in Portal, you\'ll be a fan of Splitgate.',
        submitted_by: '',
        answers: ["Splitgate"],
        affiliate_links: ["https://www.g2a.com/splitgate-gold-edition-pc-steam-gift-europe-i10000266274006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1771784819287994482", "1771554714544484521"],
        release_year: '2022',
        metacritic_score: '71%',
        genre: 'First-person shooter',
        console_platform: 'PC',
        developer: '1047 Games',
        backloggd_link: '',
    },
    '681': {
        content: 'Hitman: Blood Money is a stealth video game developed by IO Interactive and published by Eidos Interactive. The game, out in 2006, sees players cast as the infamous Agent 47, a highly skilled assassin, embarking on an international journey of high-stakes contracts. In Hitman: Blood Money, gameplay is primarily centered around stealth and strategic planning, allowing players to eliminate their targets without raising alarm. Each mission, set in different parts of the world, provides a sandbox-style environment filled with potential disguises, hiding spots, and methods of assassination for players to exploit. The game introduces the \'Blood Money\' system where players are rewarded for how cleanly they handle their contracts with less attention drawing more pay. This money can be used to upgrade weapons and equipment to suit player\'s unique style of play. Additionally, the game incorporates a \'Notoriety System\' which, based on player\'s actions and choices, can influence NPC behaviors and future missions. Hitman: Blood Money, celebrated for its intricate level design, adaptive gameplay, and creative assassination methods, is a worthy addition to the stealth genre. If you\'re a fan of games that demand careful planning and stealth, like Metal Gear Solid or Splinter Cell, you\'ll appreciate Hitman: Blood Money.',
        submitted_by: '',
        answers: ["Hitman: Blood Money"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Hitman%20Blood%20Money%20STEAM%20NEW%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhitman-blood-money-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/hitman-blood-money-steam-gift-global-i10000006133001?gtag=391e4ef696"],
        franchise: 'Hitman',
        twitter_ids: ["1772133194441117931"],
        release_year: '2006',
        metacritic_score: '82%',
        genre: 'Stealth',
        console_platform: 'PC, PS2, Xbox, X360',
        developer: 'IO Interactive',
        backloggd_link: '',
    },
    '682': {
        content: 'Splatoon is a third-person shooter game developed and published by Nintendo for the Wii U. Unveiled in 2015, Splatoon introduces players to a vibrant, post-apocalyptic world inhabited by Inklings, anthropomorphic squids that can transform into humanoid teens. As an Inkling, you participate in lively turf wars, where the objective is to cover as much territory as possible with your team\'s ink color while minimizing your opponent\'s influence. The exciting, fast-paced gameplay encourages strategic thinking and teamwork, with players needing to balance between attacking, inking territory, and maintaining their ink levels. The ability of Inklings to switch between squid and humanoid forms adds a unique twist to the genre, allowing players to replenish ink supplies or navigate the environment at high speeds by swimming through their ink color. Splatoon\'s innovative take on the shooter genre, colorful art style, and addictive multiplayer mode, has won it widespread acclaim and a dedicated fanbase. If you\'re a fan of team-based shooter games like Overwatch or Fortnite, you\'ll appreciate the unique mechanics and vibrant world of Splatoon.',
        submitted_by: '',
        answers: ["Splatoon"],
        affiliate_links: [],
        franchise: 'Splatoon',
        twitter_ids: ["1772491100005060781"],
        release_year: '2015',
        metacritic_score: '81%',
        genre: 'Action, Shooter?, 3rd-person',
        console_platform: 'Wii U',
        developer: 'Nintendo EPD',
        backloggd_link: '',
    },
    '683': {
        content: 'Robotron 64 is a fast-paced, action-packed shoot ‘em up game developed by Player 1 and published by Crave Entertainment. Released in 1998, this game is a reimagining of the classic arcade game Robotron: 2084, its storyline revolving around the player\'s efforts to save the last human family from a robot uprising. Set in the year 2084, players take control of a lone hero, blasting his way through waves upon waves of relentless robots across an array of different worlds. It features isometric gameplay, with its standout features being the twin-stick controls, which allow for independent movement and shooting, and the intense, high-speed action. The game consists of nearly 200 rapid-fire levels, often filled to the brim with enemies, demanding quick reflexes and sharpshooting accuracy. Robotron 64 was celebrated for its frantic gameplay, addictive mechanics, and the loyalty it showed to the spirit of the original arcade game. If you are a fan of high-intensity shooters like Geometry Wars or twin-stick shooters like Nex Machina, you’ll be a fan of Robotron 64.',
        submitted_by: '',
        answers: ["Robotron 64"],
        affiliate_links: [],
        franchise: 'Robotron',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: 'Original Release too old for Metacritic',
        genre: 'Arcade, Platform, Shooter, Sci-fi',
        console_platform: 'N64',
        developer: 'Player 1',
        backloggd_link: '',
    },
    '684': {
        content: 'Goat Simulator 3 is a quirky comedy simulation game, developed and published by Coffee Stain Studios, released in 2022. Players are launched into the bizarre yet hilarious world of goat life where they control a goat character, causing as much chaos and destruction in the game world as possible. Set in an open-world environment, players can freely roam and interact with virtually everything using a mix of physics and ragdoll mechanics to intensify the peculiarity of the gameplay. From headbutting objects to licking items to making them float with the goat\'s long tongue, the antics are limited only by the imagination. The third release in the series, this edition heightens the zaniness with an upgrade in graphics, bigger maps, more objects to interact with, and even wackier goat modifications. Players are scored based on the havoc they cause, emphasizing the game\'s playful, non-serious nature. Goat Simulator 3 has been praised for its humorous concept, relaxed gameplay, and the sheer joy of its absurd antics. If you\'re a fan of unconventional simulation games like I Am Bread or Octodad: Dadliest Catch, you\'ll be a fan of Goat Simulator 3.',
        submitted_by: '',
        answers: ["Goat Simulator 3"],
        affiliate_links: ["https://www.g2a.com/goat-simulator-3-pc-steam-gift-global-i10000326327012?gtag=391e4ef696"],
        franchise: 'Goat Simulator',
        twitter_ids: ["1773331158601699787", "1773333890419065293", "1773236535497830466"],
        release_year: '2022',
        metacritic_score: '68%',
        genre: 'Action, Physics, Simulation',
        console_platform: 'PC, PS5, Xbox Series, Mobile',
        developer: 'Coffee Stain North',
        backloggd_link: '',
    },
    '685': {
        content: 'Donkey Kong is a classic platform game developed by Nintendo and released in 1981. It introduces players to the world of Donkey Kong, a large ape, and portrays a rivalry of sorts with the character, Mario, who is on a daring quest to rescue Lady — now known as Pauline — from the clutches of the gigantic ape. The game unfolds over four different stages, featuring a combination of platforms, ladders, and conveyor belts. The game\'s play style involves both strategy and quick reflexes, as players must navigate ever-changing scenarios, dodge hazards, and spring over barrels hurdled by the gorilla, all while scaling a construction site to reach the distressed damsel. The game is renowned for being one of the earliest examples of the platform game genre and its introduction of characters Mario and Donkey Kong into the pantheon of iconic video game characters. It set the stage for many subsequent games, making it a key part of video game history. If you\'re a fan of classic platform games like Super Mario Bros or Sonic the Hedgehog, you\'ll be a fan of Donkey Kong.',
        submitted_by: '',
        answers: ["Donkey Kong"],
        affiliate_links: [],
        franchise: 'Donkey Kong',
        twitter_ids: ["1773383125625885098", "1773487878649028672"],
        release_year: '1981',
        metacritic_score: 'Original Release too old for Metacritic',
        genre: '2D scrolling, Platformer',
        console_platform: 'Arcade',
        developer: 'Nintendo R&D1, Nintendo Co., Ltd.',
        backloggd_link: '',
    },
    '686': {
        content: 'Cocoon is an engaging adventure game developed and published in 2023. Set in a dystopian future, players take the role of an inexperienced yet determined scientist, attempting to save his urban city from a rapid ecological decline by re-engineering rare species of flora crafted within a protective structure known as the Cocoon. Gameplay hinges on exploration, puzzle-solving, and strategic decision making. Players traverse through the dilapidated city, gathering resources, and the Cocoon serves as a nurturing hub where players use their resources and undertake various scientific procedures to cultivate life-saving plants. The game is unique, with its deep focus on environmental responsibility, the complexity of its eco-centric puzzles, and a strong narrative that seamlessly interweaves personal and global stakes. Its atmospheric world, evocative narrative, and meaningful gameplay have been celebrated within the gaming community. If you\'re a fan of immersive narrative-driven games like What Remains of Edith Finch or thought-provoking strategy games like This War of Mine, you\'ll be a fan of Cocoon.',
        submitted_by: '',
        answers: ["Cocoon"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=COCOON%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcocoon-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/cocoon-pc-steam-key-global-i10000500525003?suid=4f6ce607-210a-4dd5-aeed-c53dcb2b0117?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1774054938475159814", "1773937179040841985"],
        release_year: '2023',
        metacritic_score: '88%',
        genre: 'Puzzle, Diagonal-down, Platform',
        console_platform: 'PC, PS4, XONE, Switch, PS5, Xbox Series',
        developer: 'Geometric Interactive',
        backloggd_link: '',
    },
    '687': {
        content: 'Golf Story is a sports-adventure hybrid game developed and published by Sidebar Games. Launched in 2017, the game delves into the life and struggles of a down-on-his-luck golfer trying to reclaim his childhood love for golf and to solve life’s problems with a swing. In Golf Story, players both play golf and navigate RPG-style quests, improving their golf skills and equipment along the way. The game uniquely blends golfing mechanics with an engaging narrative, in which players need to solve puzzles, meet quirky characters, and conquer golf challenges on eight unique courses, from underwhelming local clubs to grandiose luxury resorts. Its combination of sports and role-playing elements along with a charming pixel-art style design are factors that make it stand out amongst other games. Golf Story has been praised for its whimsical narrative, strategic golf gameplay, and warm nostalgic nod to retro RPGs. If you\'re a fan of sport adventure games like Mario Tennis Aces or entertaining RPGs like Stardew Valley, you\'ll be a fan of Golf Story.',
        submitted_by: '',
        answers: ["Golf Story"],
        affiliate_links: ["https://www.g2a.com/golf-story-nintendo-switch-nintendo-eshop-key-united-states-i10000190667001?gtag=391e4ef696"],
        franchise: 'Golf Story',
        twitter_ids: ["1774219603134070866", "1774320672765788354"],
        release_year: '2017',
        metacritic_score: '78%',
        genre: 'Sports, RPG, Adventure',
        console_platform: 'Switch',
        developer: 'Sidebar Games',
        backloggd_link: '',
    },
    '688': {
        content: 'Happy April Fool\'s Day!  Go to https://guessthe.game/p/322 to play last years game',
        submitted_by: '',
        answers: ["The Murder of Sonic the Hedgehog"],
        affiliate_links: [],
        franchise: 'Sonic The Hedgehog',
        twitter_ids: ["1774888247677657520", "1774719110318010453", "1774665539232628782", "1774731155402551341", "1774934018787799325"],
        release_year: 'April 1st, 2023',
        metacritic_score: '82%',
        genre: 'Visual Novel',
        console_platform: 'PC, Mac',
        developer: 'Sega',
        backloggd_link: '',
    },
    '689': {
        content: 'Thief is a stealth video game developed by Eidos-Montréal and published by Square Enix. Launched in 2014, the game reboots the Thief series, re-introducing players to Garrett, the Master Thief, who prowls the shadow-filled streets of the City, stealing from the rich and corrupt. Gameplay in Thief emphasises stealth and cunning, as players navigate the labyrinthine city, pick locks, pickpocket unsuspecting victims, and evade or quietly eliminate guards. Light and sound play crucial roles in the game; the darkness can cloak Garrett\'s actions, while the sound of his movements can alert his enemies. Apart from main missions, there\'s a wealth of side quests and hidden treasures to discover, expanding the rich and atmospheric world of the City. Players can also customize the difficulty settings, tailoring the game to their preferred level of stealth and challenge. Thief has been praised for its finely crafted stealth mechanics, immersive atmosphere, and the freedom it offers players to approach tasks stealthily or aggressively. If you\'re a fan of stealth-driven games like Dishonored or the Hitman series, you\'ll be a fan of Thief.',
        submitted_by: '',
        answers: ["Thief"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Thief%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthief-collection%2F&intsrc=PUI2_9608", "https://www.g2a.com/thief-collection-steam-key-global-i10000006371005?gtag=391e4ef696"],
        franchise: 'Thief',
        twitter_ids: ["1775034608054046906", "1775139130931900706"],
        release_year: '2014',
        metacritic_score: '70%',
        genre: 'Action, Puzzle elements, Stealth',
        console_platform: 'PC, X360, PS3, PS4, XONE',
        developer: 'Eidos Montréal',
        backloggd_link: '',
    },
    '690': {
        content: 'Astral Chain is an action-adventure game developed by PlatinumGames and published by Nintendo for the Nintendo Switch. Released in 2019, the game immerses players in a dystopian future where they assume the role of a member of the special police task force named Neuron. In Astral Chain, players engage in combat using a weapon known as a Legion, an entity connected to the player character by a chain. The gameplay combines elements of hack-and-slash combat, exploration, and puzzle-solving, with an emphasis on using the character\'s Legion to collaborate in battles and solve environmental puzzles. The game is set in the Ark, a multi-cultural city located on an artificial island, offering a visually striking urban environment for players to explore. Astral Chain\'s innovative combat system, cooperative gameplay elements, and captivating cyberpunk setting have been widely complimented by players and critics alike. If you\'re a fan of action-adventure games with unique combat mechanics like Bayonetta or Devil May Cry, you\'ll be a fan of Astral Chain.',
        submitted_by: '',
        answers: ["Astral Chain"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1775383408132161962", "1775304221463658883"],
        release_year: '2019',
        metacritic_score: '87%',
        genre: '3rd-person, Hack and slash, Puzzle elements, RPG elements',
        console_platform: 'Switch',
        developer: 'Platinum Games Inc.',
        backloggd_link: '',
    },
    '691': {
        content: 'Frogger is an arcade game developed by Konami and published by Sega. Initially launched in 1981, the game centers around a plucky frog that players guide across a busy highway and a raging river. With its simple yet addictive gameplay, Frogger introduced players to the perils of traffic and the trials of river crossings long before \"crossy road\" style games were a trend. The player\'s objective is to successfully maneuver the frog, avoiding cars, trucks, and other threats on the highway, then navigate a river fraught with hazards such as speedy turtles and treacherous logs. The game is celebrated for its innovative for its time multiple screen and even competitive gameplay element. Unique for representing multiple traffic lanes and a river filled with challenges simultaneously, the game has holds a legendary status in the gaming community for its memorable gameplay and addictive appeal. Frogger has been hailed for its simple yet innovative gameplay, enduring charm, and the thrilling challenge it offers. If you\'re a fan of classic arcade games like Pac-Man or Donkey Kong, or more modern mobile games like Crossy Road, you\'ll be a fan of Frogger.',
        submitted_by: '',
        answers: ["Frogger"],
        affiliate_links: [],
        franchise: 'Frogger',
        twitter_ids: [],
        release_year: '1981',
        metacritic_score: 'Original release too old for metacritic',
        genre: 'Top-down, Arcade',
        console_platform: 'Arcade, PC-6001',
        developer: 'Konami Industry Co. Ltd.',
        backloggd_link: '',
    },
    '692': {
        content: 'Animal Crossing: New Leaf is a life simulation video game developed and published by Nintendo for the Nintendo 3DS console. Introduced in 2012, New Leaf invites players to live a virtual life in real-time in a village inhabited by anthropomorphic animals. In a unique twist to the series, players will wear the mayor\'s hat, allowing a greater degree of customization, ranging from public works projects to town ordinances affecting how the village operates. With a multitude of activities like fishing, fossil hunting, and interacting with affable villagers, players can go at their own pace, instilling a relaxed and charming gameplay experience. Collaboration is also a key aspect, with local or online multiplayer enabling visits to friends\' villages. Part of its allure is the changing of seasons, special events, and the clock which mirrors the real-world time and calendar. Animal Crossing: New Leaf is celebrated for its endearing characters, customization options, and relaxing, freeform gameplay. If you\'re a fan of life simulation games like Stardew Valley and Harvest Moon, you\'ll be a fan of Animal Crossing: New Leaf.',
        submitted_by: '',
        answers: ["Animal Crossing: New Leaf"],
        affiliate_links: [],
        franchise: 'Animal Crossing',
        twitter_ids: ["1776275948616433820", "1776008375618118119", "1776110909791899735", "1776217326636998909", "1776103067408007402"],
        release_year: '2012',
        metacritic_score: '88%',
        genre: 'Social Simulation',
        console_platform: '3DS',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '693': {
        content: 'The Lord of the Rings: The Fellowship of the Ring is an action-adventure game developed by WXP and published by Vivendi Universal Games. Released in 2002, it allows players to experience the epic fantasy world created by J.R.R. Tolkien, as they embody the roles of Frodo, Aragorn, or Gandalf. The game closely follows the plot of the first book (and not the film), as the player embarks on the quest to destroy the One Ring. The game offers a blend of combat, puzzle-solving, and exploration, with different characters providing unique play styles. As Frodo, players employ stealth and cunning; as Aragorn, they engage in direct combat; and as Gandalf, they use powerful spells to overcome enemies. The gameplay offers a thorough illustration of Middle Earth adorned by its rich lore and iconic characters. While simultaneously maintaining the linearity of the book\'s narrative, it also allows plenty of explorations. The Lord of the Rings: The Fellowship of the Ring is praised for its immersive world, storyline faithful to the book, and diverse gameplay. If you\'re a fan of story-driven action-adventure games like Fable or The Witcher, you\'ll be a fan of The Lord of the Rings: The Fellowship of the Ring.',
        submitted_by: '',
        answers: ["The Lord of the Rings: The Fellowship of the Ring", "The Fellowship of the Ring"],
        affiliate_links: [],
        franchise: 'The Lord of the Rings',
        twitter_ids: ["1776487185572581707", "1776603139241582901"],
        release_year: '2002',
        metacritic_score: '59%',
        genre: '3rd-person Action RPG, Puzzle elements, Fantasy',
        console_platform: 'Xbox, GBA, PS2, PC',
        developer: 'WXP, Inc.',
        backloggd_link: '',
    },
    '694': {
        content: 'Barnyard is an action-adventure game developed by Blue Tongue Entertainment and published by THQ, based on the animated film of the same name. Released in 2006, the game takes players to the playable character, a customizable cow, the game takes place in a world where barnyard animals can talk and walk on two legs when humans aren\'t around. Missions range from racing bikes to launching cows with milk-squirting antics, all while maintaining a level of humor and fun. The game encompasses a wide-open world, where players can roam freely, interact with other characters, and embark on missions to advance the story or earn currency for new customization options. Players can even play mini-games in the games included in-game night barn area. Noted for its faithful recreation of the film\'s quirky world and characters, Barnyard offers an immersive experience filled with humor and fun for gamers of all ages. Filled with engaging missions and a charming world to explore, Barnyard remains a nostalgic favorite for fans of the film. If you\'re a fan of film-based games like Madagascar or open-world exploratory games such as Bully, you\'ll be a fan of Barnyard.',
        submitted_by: '',
        answers: ["Barnyard"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1776838691064848549", "1776868810709979211", "1776832230859362376", "1776862334704656752", "1776843094345801801"],
        release_year: '2006',
        metacritic_score: '65%',
        genre: '3rd-person, Action, Adventure',
        console_platform: 'GameCube, PS2, PC',
        developer: 'Blue Tongue Entertainment Pty, Ltd.',
        backloggd_link: '',
    },
    '695': {
        content: 'Teamfight Tactics is an auto-battler game developed and published by Riot Games. Released in 2020, Teamfight Tactics is set in the League of Legends universe where players face off in a round-based strategy game. Each round has players drafting champions and placing them on a hexagonal board where they automatically battle against enemies. The goal is to be the last player standing amid eight rivals. The strategy lies in tactically arranging the champions, choosing the optimal team composition, and managing resources. Players must adapt to their opponents\' strategies and changes within the game to secure victory. The diverse roster of champions and synergistic combinations of characters and items create a game with remarkable strategic depth. Its art style, mechanics, and extensive use of the League of Legends lore add to its unique appeal. Teamfight Tactics has been acknowledged for its addictive gameplay, strategic depth, and ease of accessibility to new players. If you\'re a fan of auto-battler games like Dota Underlords or Chess Rush, you\'ll be a fan of Teamfight Tactics.',
        submitted_by: '',
        answers: ["Teamfight Tactics"],
        affiliate_links: ["https://www.g2a.com/teamfight-tactics-2800-rp-riot-key-mena-i10000340039001?gtag=391e4ef696"],
        franchise: 'League of Legends',
        twitter_ids: ["1777306368623378853", "1777369423940108366", "1777018515800875081"],
        release_year: '2019',
        metacritic_score: '79%',
        genre: 'Strategy',
        console_platform: 'PC, Mobile',
        developer: 'Riot Games, Inc.',
        backloggd_link: '',
    },
    '696': {
        content: '',
        submitted_by: '',
        answers: ["Banishers: Ghosts of New Eden"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Banishers%20Ghosts%20of%20New%20Eden%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbanishers-ghosts-of-new-eden-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/banishers-ghosts-of-new-eden-pc-steam-gift-global-i10000339908005?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1777670464854274306"],
        release_year: '2024',
        metacritic_score: '79%',
        genre: 'Action, RPG',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'DON\'T NOD Entertainment SA',
        backloggd_link: '',
    },
    '697': {
        content: 'No More Heroes 2: Desperate Struggle is an action-adventure hack and slash game developed by Grasshopper Manufacture and published by Ubisoft. Unleashed in 2010 as a direct sequel to the first game, players reprise the role of Travis Touchdown, a top-ranked assassin seeking revenge in Santa Destroy, a fictional Californian city. Gameplay is a blend of fast-paced sword-fighting and exploration with improved controls over its predecessor. The beautifully stylized cell-shaded graphics lend a unique visual appeal to the game, enhancing the overall ultraviolent experience. The game diverges from the sandbox-style of the original, opting to transport Travis directly to his next mission, thus making the pace faster and more furious. It also features various mini-games designed as 8-bit style throwbacks to classic arcade games. Engaging boss fights, quirky sense of humor, and Travis\' customizable beam katana, all contribute to the title\'s signature charm. No More Heroes 2: Desperate Struggle is revered for its creative design, witty dialogue, and relentlessly fun combat. If you\'re a fan of high-octane action games like MadWorld and metal Gear Rising: Revengeance, you\'ll be a fan of No More Heroes 2: Desperate Struggle.',
        submitted_by: '',
        answers: ["No More Heroes 2: Desperate Struggle", "No More Heroes 2"],
        affiliate_links: ["https://www.g2a.com/no-more-heroes-2-desperate-struggle-pc-steam-gift-global-i10000255644004?gtag=391e4ef696"],
        franchise: 'No More Heroes',
        twitter_ids: ["1777925702634914220"],
        release_year: '2010',
        metacritic_score: '84%',
        genre: 'Action, 3rd Person, Hack and slash, Quick Time Events (QTE)',
        console_platform: 'Wii',
        developer: 'Grasshopper Manufacture Inc.',
        backloggd_link: '',
    },
    '698': {
        content: 'Klonoa: Door to Phantomile is a platform game developed and published by Namco. Released in 1997, it invites players into a fantasy world as Klonoa, a cat-like character with long ears enabling him to glide in the air, on a mission to save the dreamland Phantomile from the villain, Ghadius, and his plot to unleash chaos. The gameplay in Klonoa delves into 2.5D platforming, meaning it combines 2D side-scrolling with 3D visual depth. This distinct approach allows players to move not just left and right but towards and away from the background, creating interactive, multi-layered levels. Klonoa can use his Wind Bullet to inflate enemies and use them either as a weapon or for reaching higher platforms. The game\'s engaging, vibrant visuals, captivating storytelling, and inventive gameplay have earned it acclaim and a dedicated fanbase. Its blend of light ambiance with serious narrative undertones has set it apart in the platform genre. If you\'re a fan of creative platform games like Super Mario Odyssey or Kirby\'s Dream Land, you\'ll be a fan of Klonoa: Door to Phantomile.',
        submitted_by: '',
        answers: ["Klonoa: Door to Phantomile"],
        affiliate_links: [],
        franchise: 'Klonoa',
        twitter_ids: ["1778181719495221526", "1778382225932726273"],
        release_year: '1997',
        metacritic_score: 'Too Old for Metacritic',
        genre: 'Action, Platform, Fantasy',
        console_platform: 'PS',
        developer: 'Namco Limited',
        backloggd_link: '',
    },
    '699': {
        content: 'Enter the Matrix is an action-adventure video game developed by Shiny Entertainment and published by Atari, Inc. Released in 2003, with the unique feature of being an integral part of the Matrix movie storyline. It allows players to step into the roles of Niobe and Ghost, secondary characters in the second Matrix film, The Matrix Reloaded. Gameplay merges various game genres, providing a potent blend of martial arts combat, high-speed driving sequences, and gunfights. The play style heavily emphasizes the franchise’s trademark ‘bullet time’ feature, giving players the ability to dodge bullets and execute superhuman moves in slow motion. The game\'s plot aligns with events in The Matrix Reloaded and includes over an hour of original cinematic footage exclusive to the game, blurring the line between the gaming and cinematic experiences. Enter the Matrix has been commended for its ambitious endeavor to integrate the game into the Matrix universe and for its unique gameplay elements that echo the film’s style. If you\'re a fan of games like Max Payne that weave in film aspects, or action-packed adventures like Tomb Raider, you\'ll be a fan of Enter the Matrix.',
        submitted_by: '',
        answers: ["Enter the Matrix"],
        affiliate_links: [],
        franchise: 'The Matrix',
        twitter_ids: ["1778581405280153986"],
        release_year: '2003',
        metacritic_score: '58%',
        genre: 'Action, Shooter, Sci-Fi',
        console_platform: 'GameCube, Xbox, PS2, PC',
        developer: 'Shiny Entertainment, Inc.',
        backloggd_link: '',
    },
    '700': {
        content: 'Last Epoch, an action role-playing game developed by Eleventh Hour Games, invites players into a breathtaking world filled with lore, magic, and monsters. Set in the land of Eterra, players can venture through different eras, from the ancient past to a desolate far future, and witness the world change dynamically based on their actions. As a newly trained \"Traveler,\" you\'re tasked with attempting to prevent the apocalypse itself. Gameplay focuses heavily on intense combat, an extensive skill system, and a complex crafting mechanic. It’s an experience with a perfect blend of combat, exploration, and character customization. With ten playable classes, each with unique abilities, Last Epoch encourages player experimentation and diversity. It offers an engaging storyline, where single decisions can affect the entire game world and its history. The game features a strong multiplayer component, allowing you to share the adventure with friends, or brave the challenges alone. The game\'s intricate crafting system, vast world, and skill depth have all been praised. If you\'re a fan of action-RPGs like Diablo or Path of Exile, you\'ll definitely be a fan of Last Epoch.',
        submitted_by: '',
        answers: ["Last Epoch"],
        affiliate_links: ["https://www.g2a.com/last-epoch-ultimate-edition-pc-steam-gift-global-i10000187486005?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2019 (Early Access), 2024',
        metacritic_score: '80%',
        genre: 'Action, RPG, Fantasy',
        console_platform: 'PC, Linux',
        developer: 'Eleventh Hour Games',
        backloggd_link: '',
    },
    '701': {
        content: 'Jurassic Park is a thrilling action-adventure game based on the iconic film, developed and published by Ocean Software. Released in 1993, it plunges players into the dinosaur-infested island of Isla Nublar, taking on the role of Alan Grant, with the mission to escape the park safely. Gameplay alternates between first-person and top-down perspectives, providing a unique play style as players explore buildings and navigate the perilous dinosaur-laden outdoor environments. It combines exploration, puzzle-solving, and non-stop action with players needing to dodge or disable all manner of prehistoric predators while also grappling with a wide variety of puzzles to progress through the game. Resources are scarce, and caution is advised as not every dinosaur encountered poses a threat. The detailed pixel art graphics lead players to fortify the vivacious environment, making the thrill of evading a T-Rex in a kitchen all the more exciting. If you\'re a fan of the suspense and exploration of games like Resident Evil or Silent Hill with the added allure of dinosaurs, you\'ll be a fan of Jurassic Park.',
        submitted_by: '',
        answers: ["Jurassic Park"],
        affiliate_links: ["https://www.g2a.com/jurassic-park-classic-games-collection-pc-steam-key-global-i10000502185001?gtag=391e4ef696"],
        franchise: 'Jurassic Park',
        twitter_ids: [],
        release_year: '1993',
        metacritic_score: 'Original Release too old for Metacritic',
        genre: 'Adventure, Top Down',
        console_platform: 'SNES',
        developer: 'Ocean of America, Inc.',
        backloggd_link: '',
    },
    '702': {
        content: 'Super Mario Galaxy 2 is a platform video game developed and published by Nintendo for the Wii console. Released in 2010, the game expands upon the gravity-defying platforming of the original and treats players to an interstellar adventure with Mario and Yoshi to save Princess Peach from the villainous Bowser. With a cinematic scope, the game invites players to journey through numerous galaxies, each containing unique planets and regions with varied themes, enemies, and puzzles. The gameplay in Super Mario Galaxy 2 is varied and dynamic, with a mixture of running, jumping, and exploration, combined with gravity-based physics puzzles. Players can take control of Mario or Yoshi, each of which offer their own distinct abilities like Yoshi\'s flutter jump and tongue grab. The game also introduces new power-ups, like the Drill Mushroom and the Rock Mushroom, adding another layer to the exhilarating platforming. Super Mario Galaxy 2 has been highly praised for its innovative gameplay, spectacular level design, and the depth of its content. If you\'re a fan of platform games like Donkey Kong Country Returns or Rayman Origins, you\'ll be a fan of Super Mario Galaxy 2.',
        submitted_by: '',
        answers: ["Super Mario Galaxy 2"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '97%',
        genre: 'Adventure, Platform',
        console_platform: 'Wii',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '703': {
        content: 'Halo 4 is a first-person shooter game developed by 343 Industries and published by Microsoft Studios. Launched in 2012, Halo 4 offers players an immersive interstellar experience as they assume the role of Master Chief Petty Officer John-117, a super-soldier known as a Spartan. This installment marks the first chapter of the Reclaimer Saga, which embarks players on an epic journey across alien worlds in a bid to save humanity. In the game’s single-player campaign, players forge ahead through a plethora of alien enemies and environments while discovering the secrets of the Forerunner civilization. The game also boasts a variety of multiplayer modes, increasing in-game versatility. A standout feature is the Spartan Ops mode, a mission-based cooperative mode separate from the main campaign. Halo 4 exemplifies advancements in graphics, design, and gameplay, setting a new standard within the Halo franchise while retaining the series\' classic feel. It has received high praise for its ambitious story, refined multiplayer, and stunning visuals. If you\'re a fan of shooter games with a deep storyline such as Destiny or Gears of War, you\'ll be a fan of Halo 4.',
        submitted_by: '',
        answers: ["Halo 4"],
        affiliate_links: ["https://www.g2a.com/halo-4-pc-steam-gift-global-i10000219967004?gtag=391e4ef696"],
        franchise: 'Halo',
        twitter_ids: [],
        release_year: '2012',
        metacritic_score: '84%',
        genre: 'Action, FPS',
        console_platform: 'X360',
        developer: '343 Industries, Inc.',
        backloggd_link: '',
    },
    '704': {
        content: 'Alpha Protocol is an action role-playing game developed by Obsidian Entertainment and published by Sega. Released in 2010, this game immerses the player into the dangerous world of espionage as Michael Thorton, a newly recruited agent for a clandestine agency. His mission is to unravel global conspiracies and navigate treacherous alliances while maintaining stealth and disguises. Players can interact with various characters in the game to gather information, forming relationships that can drastically alter the trajectory of the storyline. Its unique Dialogue Stance System allows dynamic conversations and decisions that affect not only the plot but also Thorton\'s skills and abilities. Gameplay features include intensive combat, stealth strategies, and close-quarter scenarios, with players having the freedom to customize Thorton\'s skill set. Alpha Protocol defines itself within the genre with its emphasis on player choice, branching narrative, and role-playing elements integrated with action-packed gameplay. If you\'re a fan of choice-driven RPGs like Mass Effect or immersive action games like Splinter Cell, you\'ll be a fan of Alpha Protocol.',
        submitted_by: '',
        answers: ["Alpha Protocol"],
        affiliate_links: ["https://www.g2a.com/alpha-protocol-steam-gift-global-i10000003365001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1780358738697757001"],
        release_year: '2010',
        metacritic_score: '72%',
        genre: 'Action, RPG, Shooter, Stealth',
        console_platform: 'PS3, X360, PC',
        developer: 'Obsidian Entertainment, Inc.',
        backloggd_link: '',
    },
    '705': {
        content: 'NFS: Unbound is a thrilling racing game developed by Ghost Games and published by Electronic Arts. Released in 2022 as the latest addition to the renowned Need For Speed franchise, it allows players to indulge in high-octane racing in a sprawling, evolving open world inspired by modern urban landscapes. Assuming the role of a promising street racer, players are challenged to climb the ranks in the dangerous world of illegal racing. NFS: Unbound excels in delivering heart-pumping races that involve navigating through busy streets, dodging traffic, and outsmarting law enforcement. In between races, players can explore the vast city with a day-night cycle, customize their ride with a broad range of modifications, and engage in various optional challenges. The game\'s destructible environments, next-gen graphics, dynamic weather system and advanced driving mechanics infuse the racing experience with a fresh, vibrant energy. NFS: Unbound has been commended for its immersive world, exhilarating speed thrills, and intense police chases. If you\'re a fan of high-speed racing games like Forza Horizon or Burnout Paradise, you\'ll be a fan of NFS: Unbound.',
        submitted_by: '',
        answers: ["NFS: Unbound", "Need for Speed: Unbound"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Need%20for%20Speed%20Unbound%20-%20Xbox%20Series%20XS&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fneed-for-speed-unbound-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/need-for-speed-unbound-pc-steam-gift-global-i10000336997001?gtag=391e4ef696"],
        franchise: 'Need for Speed',
        twitter_ids: ["1780827455311458478"],
        release_year: '2022',
        metacritic_score: '73%',
        genre: 'Racing, Driving',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'Criterion Software Ltd.',
        backloggd_link: '',
    },
    '706': {
        content: 'The Lost Vikings is a puzzle-platform video game developed by Silicon & Synapse. Released in 1993, it sees players take control of three Viking characters - Erik, Baleog, and Olaf, each with their own unique skills, as they traverse time and space in a bid to return home after being kidnapped by aliens. The gameplay in The Lost Vikings requires players to strategically use the abilities of each character to solve puzzles and overcome obstacles. No Viking can be left behind, so players must control them individually but operate them as a team to progress through each level. Its unique gameplay mechanics, charming humor, and engaging puzzles made The Lost Vikings stand out in the platforming genre. The game\'s success led it to become one of Blizzard Entertainment\'s defining early titles, despite its divergence from the company\'s later focus on strategy and MMORPG titles. If you\'re a fan of puzzle-platformers like Lemmings or modern cooperative games like Overcooked, you\'ll be a fan of The Lost Vikings.',
        submitted_by: '',
        answers: ["The Lost Vikings"],
        affiliate_links: [],
        franchise: 'The Lost Vikings',
        twitter_ids: ["1781110464233054435", "1781290345050833110"],
        release_year: '1993',
        metacritic_score: 'Too Old',
        genre: 'Puzzle, Side view, 2D scrolling, Platform',
        console_platform: 'DOS, SNES, Amiga, Genesis',
        developer: 'Silicon & Synapse, Inc.',
        backloggd_link: '',
    },
    '707': {
        content: 'Venba is a narrative cooking game developed and published by Visai Studios. Released in 2023, Venba invites players to traverse through an intimate family story as they take on the role of an Indian immigrant mother, who navigates her new life in Canada while trying to keep her family\'s traditional culinary culture alive. Venba stands out with its distinctive mixture of puzzle-solving and narrative-driven gameplay; players prepare various traditional South Indian dishes by arranging ingredients on a puzzle board, reflecting the mother\'s inventive cooking process. Alongside these culinary puzzles, players also experience an emotive storyline, dealing with themes of homesickness, cultural identity, and the nurturing power of food. With its hand-painted art style and mouth-watering culinary creations, Venba is more than just a cooking game, it\'s a wholesome journey into lived experiences. The game has been praised for its heartwarming narrative, innovative puzzle mechanics, and the immersive cultural storytelling that portrays the mother\'s journey. If you\'re a fan of narrative-driven games like Florence or casual puzzle games like Cooking Mama, you\'ll be a fan of Venba.',
        submitted_by: '',
        answers: ["Venba"],
        affiliate_links: ["https://www.g2a.com/venba-pc-steam-gift-global-i10000502497003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1781656546520281108"],
        release_year: '2023',
        metacritic_score: '79%',
        genre: 'Cooking, Puzzle, Narrative',
        console_platform: 'Switch, PS5, PC, XONE, Xbox Series',
        developer: 'Visai Games',
        backloggd_link: '',
    },
    '708': {
        content: 'NFL Street is an action-packed American football video game developed by EA Tiburon and published by Electronic Arts in 2004. The high-stakes, fast-paced gameplay takes place in urban locations rather than traditional football fields, offering an edgier, street-oriented aesthetic. Players control teams of seven as they employ a blend of traditional football strategies and excessive over-the-top maneuvers to compete in various game modes. The game integrates humorous trash-talking and flashy plays alongside standard scoring, infusing gameplay with a unique and engaging atmosphere. Players can design their own teams, customize their attributes, and enhance the players\' skills through the game\'s innovative \'style points\' system. These style points are earned by performing exhibitions of agility, strength, and style on the pitch. NFL Street is heralded for its blend of hardcore sports simulation with light-hearted, accessible gameplay. Its diverse gameplay elements make it a superb alternative for traditional sports videogame enthusiasts. If you\'re a fan of the over-the-top sports competition in games like NBA Jam or FIFA Street, you\'ll be a fan of NFL Street.',
        submitted_by: '',
        answers: ["NFL Street"],
        affiliate_links: [],
        franchise: 'NFL Street',
        twitter_ids: ["1781815636043137395", "1782022370967908392", "1781983305019371634"],
        release_year: '2004',
        metacritic_score: '81%',
        genre: 'Sports',
        console_platform: 'PS2, GameCube, Xbox',
        developer: 'EA Tiburon',
        backloggd_link: '',
    },
    '709': {
        content: 'Race Driver: Grid is a racing video game developed and published by Codemasters. Launched in 2008, Grid transports players into the glamorous world of professional racing circuits. You step into the role of a new driver for hire, working your way up the ranks by competing across three continents in various racing disciplines. Grid offers a unique blend of arcade and simulation driving, delivering high-speed thrills while maintaining a sizable degree of realism. The game\'s Ego engine allows for an impressive \'Flashback\' system that lets players rewind and play back certain portions of the race. This innovative feature adds a strategic element to the fast-paced racing action. Grid’s diverse set of races, realistic vehicle dynamics, and stunning graphics provide an engaging and immersive racing experience. The game has been praised for its comprehensive single-player campaign and its intense and exciting multiplayer races. If you\'re a fan of the adrenaline-fueled racing found in games like Need for Speed or Forza Motorsport, you\'ll be a fan of Race Driver: Grid.',
        submitted_by: '',
        answers: ["Race Driver: Grid", "Grid"],
        affiliate_links: [],
        franchise: 'Grid',
        twitter_ids: ["1782378874107502646"],
        release_year: '2008',
        metacritic_score: '87%',
        genre: 'Racing',
        console_platform: 'PC, PS3, X360, DS',
        developer: 'Codemasters',
        backloggd_link: '',
    },
    '710': {
        content: 'Pikmin 2 is a real-time strategy video game developed by Nintendo EAD and published by Nintendo. Released in 2004 as a sequel to the original Pikmin, this game continues the adventures of Captain Olimar, who crash-landed on an alien planet in the first installment. In Pikmin 2, instead of merely surviving, Olimar returns to the planet with a new companion, Louie, to collect artifacts and repay the debt of their employer\'s company. The gameplay revolves around exploring the lush but perilous alien world, leading and managing groups of Pikmin, small plant-based creatures with different abilities, to complete various tasks. Players must strategize on how to use these abilities to overcome obstacles, defeat enemies, and retrieve items. With its immersive world, dynamic day-night cycles, and innovative creature commanding mechanics, Pikmin 2 offers a unique blend of strategy, exploration, and puzzle-solving elements. Pikmin 2 is widely praised for its enhanced multiplayer features, charming art style, and depth of gameplay. If you\'re a fan of strategic, exploration-based games such as Overlord or Dungeon Keeper, you\'ll be a fan of Pikmin 2.',
        submitted_by: '',
        answers: ["Pikmin 2"],
        affiliate_links: ["https://www.g2a.com/pikmin-1-2-bundle-nintendo-switch-nintendo-eshop-account-global-i10000502368002?suid=8a937dd4-4a6f-4364-87dd-9aadeebc5876?gtag=391e4ef696"],
        franchise: 'Pikmin',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '90%',
        genre: 'Real-time strategy, puzzle',
        console_platform: 'GameCube',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '711': {
        content: 'Batman: Arkham City is an action-adventure game developed by Rocksteady Studios and published by Warner Bros. Interactive Entertainment. Launched in 2011, Arkham City escorts players into the gloomy, crime-infested streets of Arkham City, an open-world expanse encasing heart of Gotham\'s slums. In the game\'s plot, players assume the role of Batman, battling his way through treacherous quests to foil the diabolical plans of some of his most notorious adversaries, including The Joker, Two-Face, and Harley Quinn. Gameplay in Arkham City primarily consists of combat, exploration, and stealth. Batman\'s repertoire of high-tech gadgets, detective skills, and acrobatics, along with the option to glide using his cape, contribute to unique gameplay mechanics that allow for a blend of strategy and direct combat. Apart from progressing the story, players can delve into a multitude of side missions, collectibles, and challenges that deepen the experience. Famed for its gameplay diversity, intriguing narrative, and the authentic representation of the Batman universe, Batman: Arkham City has been praised as one of the best superhero games ever made. If you\'re a fan of action-adventure games with stealth elements like Assassin’s Creed or the other games in the Batman: Arkham series, you\'ll be a fan of Batman: Arkham City.',
        submitted_by: '',
        answers: ["Batman: Arkham City"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Batman%20Arkham%20City%20Game%20of%20the%20Year%20Overflow%201%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbatman-arkham-city-game-of-the-year%2F&intsrc=PUI2_9608", "https://www.g2a.com/batman-arkham-city-goty-edition-steam-gift-global-i10000000987001?gtag=391e4ef696"],
        franchise: 'Batman',
        twitter_ids: ["1782900730644377664"],
        release_year: '2011',
        metacritic_score: '94%',
        genre: 'Action, Adventure',
        console_platform: 'PS3, X360',
        developer: 'Rocksteady Studios',
        backloggd_link: '',
    },
    '712': {
        content: 'Worms is a 2D artillery tactical game developed by Team17. Launched in 1995, it initiated a new gaming series that would become immensely popular worldwide. In Worms, players control a team of up to four cartoon-style, weapon-toting earthworms and navigate them across a deformable landscape. On their turns, players can use a variety of outrageous weapons, such as exploding sheep and banana bombs, to eliminate the opposing teams. The game\'s humor, primarily from the worm\'s silly catchphrases and the absurdity of the weapons, gives it unique and appealing charm. Worms mixes strategic gameplay with a distinctive visual aesthetic and a fun sense of humor. The game calls for careful planning and strategy, with players needing to move their worms into advantageous positions, select the right weapons, and adjust the power and angle of their shots to ensure maximum damage to opponents. Worms was highly praised upon release for its innovative gameplay, humor, and multiple play modes. If you\'re a fan of fun, strategic games with a humorous twist, such as Angry Birds or the later Worms games, you\'ll be a fan of the original Worms as well.',
        submitted_by: '',
        answers: ["Worms"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1281787/15105?prodsku=Worms%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fworms%2F&intsrc=PUI2_9727", "https://www.g2a.com/worms-wmd-pc-steam-key-global-i10000025509006?suid=28256b7f-9178-48d3-9220-bb7b7fee17a0?gtag=391e4ef696"],
        franchise: 'Worms',
        twitter_ids: [],
        release_year: '1995',
        metacritic_score: 'Not Rated',
        genre: 'Artillery, strategy',
        console_platform: 'Amiga',
        developer: 'Team17',
        backloggd_link: '',
    },
    '713': {
        content: 'Metro Exodus is an immersive first-person shooter developed by 4A Games and published by Deep Silver. As the third installment in the Metro video game series, it ushers players into a post-apocalyptic open world setting inspired by Dmitry Glukhovsky\'s novels. Extracted from the ruins of Nuclear-devastated Russia, players take on the role of Artyom as he embarks on a continent-spanning journey aboard the Aurora, a heavily modified steam locomotive. Across a journey that spans the seasons, from spring through to the depths of nuclear winter, the game\'s thrill lies in a blend of survival horror, stealth, exploration, and careful ammunition management. Combat involves a mixture of traditional and makeshift weapons while scavenging for supplies is paramount. The game has an emphasis on atmosphere and its environment is one of the most engaging elements, with a day/night cycle and dynamic weather conditions that impact gameplay. Enhancing the aesthetic realism is a system that involves cleaning and maintaining weapons to prevent jamming or overheating. Metro Exodus has been lauded for its atmospheric storytelling, beautiful graphics, and clever gameplay mechanics. If you\'re a fan of atmospheric first-person shooters like Bioshock or survival horror games like The Last of Us, you\'ll be a fan of Metro Exodus.',
        submitted_by: '',
        answers: ["Metro Exodus"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Metro%20Exodus%20%20Gold%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmetro-exodus-gold-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/metro-exodus-gold-edition-steam-gift-global-i10000170310001?gtag=391e4ef696"],
        franchise: 'Metro',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '82%',
        genre: 'FPS',
        console_platform: 'PS4, PC, XONE',
        developer: '4A Games',
        backloggd_link: '',
    },
    '714': {
        content: '',
        submitted_by: '',
        answers: ["Content Warning"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["https://www.g2a.com/content-warning-pc-steam-gift-global-i10000504789004?gtag=391e4ef696"],
        release_year: '2024',
        metacritic_score: 'Not Rated',
        genre: 'Survival horror?',
        console_platform: 'PC',
        developer: 'Skog, Zorro, Wilnyl, Philip, thePetHen',
        backloggd_link: '',
    },
    '715': {
        content: 'Railroad Tycoon is a business simulation game developed by MPS Labs and published by MicroProse. Released in 1990, the game thrusts players into the role of an aspiring railroad baron in the 19th and early 20th centuries. In Railroad Tycoon, players lay tracks, build stations, purchase or construct locomotives, and compete with other railway companies as they strive to dominate the markets. The game is notable for its blend of economics, business strategy, and historical elements. Players have the challenge of managing each aspect of the business, from constructing profitable routes, upgrading their rail network, to staving off fierce competition in a bustling industrial era. The game provided a sense of realism by requiring players to contend with economic conditions, geography, and technological advancement that mirrored the actual developments of railroad expansion during the period. Railroad Tycoon is recognized for its trailblazing combination of simulation and strategy gameplay, as well as its influential role in the railway-themed board games and video games that followed. If you\'re a fan of business simulation games like SimCity or Transport Tycoon, you\'ll be a fan of Railroad Tycoon.',
        submitted_by: '',
        answers: ["Railroad Tycoon"],
        affiliate_links: ["https://www.g2a.com/railroad-tycoon-collection-steam-gift-global-i10000019674001?gtag=391e4ef696"],
        franchise: 'Railroad Tycoon',
        twitter_ids: [],
        release_year: '1990',
        metacritic_score: 'Not Rated',
        genre: 'Business simulation game',
        console_platform: 'DOS',
        developer: 'MPS Labs',
        backloggd_link: '',
    },
    '716': {
        content: 'Kirby Star Allies is an action-packed platformer game developed by HAL Laboratory and published by Nintendo for the Nintendo Switch. Unleashed in 2018, players venture into the vibrant and whimsical world embodied by the adorable pink puffball, Kirby. As Kirby, players can inhale enemies to copy abilities or even befriend foes to create a team of allies, an all-new mechanic in the Kirby series. The gameplay involves navigating through colorful landscapes, engaging in thrilling boss battles, solving puzzles, and collecting various items. It also allows for up to four players to play cooperatively, making the journey through Dream Land a collaborative effort. Key features of the game include combining abilities to create powerful new attacks and using the Joy-Con controllers to perform \'friend actions\', which execute player-combined abilities. Kirby Star Allies has been praised for its charming visuals, dynamic gameplay, cooperative multiplayer mode, and its innovation within the long-standing Kirby franchise. If you\'re a fan of cooperative platform games like Super Mario Bros. U Deluxe or fun-filled adventure games like Yoshi\'s Crafted World, you\'ll be a fan of Kirby Star Allies.',
        submitted_by: '',
        answers: ["Kirby Star Allies"],
        affiliate_links: ["https://www.g2a.com/kirby-star-allies-nintendo-switch-nintendo-eshop-key-united-states-i10000169625003?suid=bf16b520-89a8-4f70-8e7f-a71de4c1dee4?gtag=391e4ef696"],
        franchise: 'Kirby',
        twitter_ids: ["1784799149004660764", "1784912318234558968", "1784710382059205028"],
        release_year: '2018',
        metacritic_score: '73%',
        genre: 'Platformer',
        console_platform: 'Switch',
        developer: 'HAL Laboratory',
        backloggd_link: '',
    },
    '717': {
        content: 'Valkyria Chronicles is a tactical role-playing game developed and published by Sega. Set in the fictitious continent of Europa in the 1930s, it tells the story of Welkin Gunther, Alicia Melchiott, and their comrades in the militia squad 7 of the neutral Principality of Gallia, caught in a war between two major powers. The gameplay of Valkyria Chronicles combines strategy elements of tactical role-playing games with the real-time action of third-person shooters. Players take turns moving their characters into positions to complete mission objectives ranging from taking out enemies, capturing territory, or rescuing allies. Its distinctive CANVAS Graphic Engine, which gives the game visuals resembling a watercolor painting in motion, stands out, enhancing the game\'s storybook aesthetic. Furthermore, its unique blend of military realism and romanticized aesthetics offer a different take on war-themed games. Valkyria Chronicles earned praises for its stunning art style, engaging tactical combat system, and a compelling story. If you\'re a fan of strategy role-playing games like Fire Emblem or Final Fantasy Tactics, you\'ll be a fan of Valkyria Chronicles.',
        submitted_by: '',
        answers: ["Valkyria Chronicles"],
        affiliate_links: ["https://www.g2a.com/valkyria-chronicles-steam-gift-global-i10000019548004?gtag=391e4ef696"],
        franchise: 'Valkyria',
        twitter_ids: ["1785067279513735555", "1785386464236450055"],
        release_year: '2008',
        metacritic_score: '86%',
        genre: 'Tactical RPG',
        console_platform: 'PS3',
        developer: 'Sega',
        backloggd_link: '',
    },
    '718': {
        content: 'Elite Beat Agents is a music-based rhythm game developed by iNiS and published by Nintendo for the Nintendo DS handheld gaming system. Launched in 2006, Elite Beat Agents immerses players in the shoes of a secret government agency that uses music and dance to help people in need. As members of the \'Elite Beat Agents\', gamers tap, slide, and spin their stylus to the beat, creating an interactive experience that is equal parts challenging and engaging. Gameplay is mission-based with each mission corresponding to a real-world pop song and a comical yet touching story. To progress, players must accurately mimic the rhythm of the music and the actions on the screen, which range from dancing to firefighting and even babysitting. The game has been lauded for its unique combination of zany humor, engaging rhythm gameplay, and its toe-tapping soundtrack, which includes 19 chart-topping hits from past decades. If you\'re a fan of rhythm-based games such as Guitar Hero or Dance Dance Revolution, you\'ll be a fan of Elite Beat Agents.',
        submitted_by: '',
        answers: ["Elite Beat Agents"],
        affiliate_links: [],
        franchise: 'Ouendan',
        twitter_ids: ["1785738598408728921", "1785746391857234300"],
        release_year: '2006',
        metacritic_score: '87%',
        genre: 'Rhythm',
        console_platform: 'DS',
        developer: 'iNiS',
        backloggd_link: '',
    },
    '719': {
        content: 'Dune II is a real-time strategy (RTS) game developed by Westwood Studios and published by Virgin Games in 1992. Viewed as one of the pioneers that established the conventions of the RTS genre, the game takes players to the hostile desert planet of Arrakis—inspired by Frank Herbert\'s Dune universe. Players can enlist with one of the three feuding houses: Atreides, Harkonnen, or Ordos, each vying for control over the spice melange, the most vital resource on Arrakis. The gameplay of Dune II focuses on the base-and-army-building element unique to the RTS genre, compelling strategic planning, resource management, and tactical combat against AI enemies. Moreover, players have to manage the harvesting of spice while defending against large, marauding sandworms that can engulf entire units. The beauty of Dune II lies in the diverse strategies each house offers, with various units and structures leading to distinctly different play styles. Dune II set the foundation for future RTS games, introducing mechanics like tech trees, resource gathering, and a point-and-click interface that became staples of the genre. If you\'re a fan of real-time strategy games like Command & Conquer or StarCraft, you\'ll be a fan of Dune II.',
        submitted_by: '',
        answers: ["Dune II", "Dune 2"],
        affiliate_links: [],
        franchise: 'Dune',
        twitter_ids: ["1786175556570267903", "1786002479215165845"],
        release_year: '1992',
        metacritic_score: 'Too old for metacritic',
        genre: 'Real-time strategy',
        console_platform: 'DOS',
        developer: 'Westwood Studios',
        backloggd_link: '',
    },
    '720': {
        content: 'The Evil Within 2 is a survival horror game developed by Tango Gameworks and published by Bethesda Softworks. Released in 2017, the game invites players back into the dark and terrifying world of former detective Sebastian Castellanos, as he embarks on a quest to rescue his daughter from a nightmarish dimension. The Evil Within 2 blends exploration, puzzle-solving, and combat to create a thrilling, fear-inducing experience. Unlike its predecessor, the game introduces semi-open world environments, filled with side missions and hidden secrets, adding a new level of freedom and depth to the gameplay. The narrative is intertwined with nerve-wracking combat against grotesque creatures and maintains a constant sense of impending doom that underlines the entire game. Weapons and resources are scarce, making strategy and careful planning essential to survival. The Evil Within 2 has been applauded for its story-driven gameplay, oppressive horror ambiance, and the balance it strikes between open-world exploration and survival horror elements. If you\'re a fan of horror survival games like Resident Evil or Dead Space, you\'ll be a fan of The Evil Within 2.',
        submitted_by: '',
        answers: ["The Evil Within 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=The%20Evil%20Within%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-evil-within-2%2F&intsrc=PUI2_9608", "https://www.g2a.com/the-evil-within-2-ps4-psn-account-account-global-i10000068333015?gtag=391e4ef696"],
        franchise: 'The Evil Within',
        twitter_ids: ["1786199041698910507"],
        release_year: '2017',
        metacritic_score: '76%',
        genre: 'Survival horror',
        console_platform: 'PC. PS4, XONE',
        developer: 'Tango Gameworks',
        backloggd_link: '',
    },
    '721': {
        content: 'Pummel Party is a multiplayer party game developed and published by Rebuilt Games. Released in 2018, it allows up to eight players to bash and race each other in a frenzied, all-out brawl, either online or locally. Players find themselves in a vibrant, changeable game board, partaking in a variety of mini-games and engaging in hilarious battles. The gameplay in Pummel Party combines both strategy and chance. Players navigate around the game board, seeking to collect as many keys as they can to unlock treasure chests and gain powerful items, whilst avoiding perilous events which drastically shift the game\'s dynamics. The game\'s 30 unique mini-games range from dodging traffic, to sniping each other in classic shooting galleries, giving it its chaotic and fun atmosphere. Pummel Party received much acclaim for its exciting blend of strategy, random events, and fast-paced mini-games which bring a refreshing twist to the party game genre. If you\'re a fan of engaging multiplayer games like Mario Party or Overcooked, you\'ll be a fan of Pummel Party.',
        submitted_by: '',
        answers: ["Pummel Party"],
        affiliate_links: ["https://www.g2a.com/pummel-party-steam-gift-global-i10000171639002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: 'TBD',
        genre: 'Multiplayer, Fun, Minigames',
        console_platform: 'PC',
        developer: 'Rebuilt Games',
        backloggd_link: '',
    },
    '722': {
        content: 'The Saboteur is an open-world action-adventure game developed by Pandemic Studios and published by Electronic Arts. Unveiled in 2009, The Saboteur invites players to experience the enigmatic life of Sean Devlin, a mechanic and race car driver inspired by the real-life Irishman William Grover-Williams, who becomes embroiled in the French Resistance during WWII. Drawing on a 1940s noir aesthetic, players navigate through the Nazi-occupied Paris, partaking in sabotage missions, conducting stealthy assassinations, or participating in car chases in the city\'s intricate art deco architecture. The Saboteur is notable due to its \'Will to Fight\' system where areas in the game become vibrant and full of colour as resistance rises in the French city. The game\'s reward is not only in its gameplay but in the transformation of the grayscale world into vivid colours, signifying the restoration of hope and freedom. The Saboteur offers an exciting blend of action, stealth, driving, and platforming elements, brought together with a captivating narrative. If you\'re a fan of open-world action games like Mafia or L.A. Noire with narratives set in historical periods, you\'ll be a fan of The Saboteur.',
        submitted_by: '',
        answers: ["The Saboteur"],
        affiliate_links: ["https://www.g2a.com/the-saboteur-origin-key-global-i10000043940001?suid=bb77b1d0-a13e-49ec-98d5-bce73a379319?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '76%',
        genre: 'Action, Adventure, Shooter, Stealth',
        console_platform: 'PS3, X360, PC',
        developer: 'Pandemic Studios, LLC',
        backloggd_link: '',
    },
    '723': {
        content: 'Mega Man 11 is an action-platform game developed and published by Capcom. True to its lineage, the game continues the beloved adventures of the blue-armored hero, Mega Man, as he strives to foil yet another wicked plot by the malevolent Dr. Wily. The game features 2.5D graphics with fully hand-drawn characters and environments. The gameplay involves navigating through eight stages, each with its own unique boss. Players can acquire new weapons and abilities by defeating these bosses, enhancing Mega Man\'s arsenal for the subsequent stages. The addition of the Double Gear system in this edition allows players to either power up their attacks or slow down time, adding a fresh element to the series\' tried-and-true gameplay. Mega Man 11 retains the energy, challenges, and fast-paced action of the series, while simultaneously incorporating contemporary gaming mechanics, polished visuals and creative level designs. Noted for its balanced difficulty, an upgrade to its classic formula, and engaging boss battles, Mega Man 11 is a must-play for any platformer lover. If you\'re a fan of action-packed platform games like Sonic Mania or Cuphead, you\'ll be a fan of Mega Man 11.',
        submitted_by: '',
        answers: ["Mega Man 11"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Mega%20Man%2011%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmega-man-11-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/mega-man-11-xbox-live-key-xbox-one-global-i10000171739004?gtag=391e4ef696"],
        franchise: 'Mega Man',
        twitter_ids: ["1787624038514500081", "1787427430052847934"],
        release_year: '2018',
        metacritic_score: '79%',
        genre: 'Platformer, 2D Scrolling',
        console_platform: 'PC, XONE, PS4, Switch',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '724': {
        content: '',
        submitted_by: '',
        answers: ["Unicorn Overlord"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1787626475685871809", "1787829636006572127"],
        release_year: '2024',
        metacritic_score: '89%',
        genre: 'Tactical RPG, Fantasy',
        console_platform: 'PS5, PS4, Switch, Xbox Series',
        developer: 'Vanillaware Ltd.',
        backloggd_link: '',
    },
    '725': {
        content: 'Tales of Symphonia is a role-playing game developed by Namco Tales Studio and published by Namco. Released in 2003, it immerses players in the mystical world of Sylvarant where they assume the role of Lloyd Irving, a young hero on a quest to rejuvenate his world. The game is characterized by its Linear Motion Battle System, allowing players to execute combo attacks and special techniques in real-time combat. Apart from combat, players journey through a fully rendered 3D world, engaging with a variety of characters, solving puzzles and overcoming challenges to progress the story. A unique aspect of the game is the relationship among the team members. Players actions and choices have ripple effects on the relationships, significantly influencing the team dynamic and gameplay. Tales of Symphonia is revered for its robust combat system, tremendous depth, engaging characters, and the rich, conceptual world it creates. It has often been described as a defining title in the RPG genre. If you\'re a fan of role-playing games with detailed combat systems like Final Fantasy or Dragon Quest, you\'ll be a fan of Tales of Symphonia.',
        submitted_by: '',
        answers: ["Tales of Symphonia"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Tales%20of%20Symphonia%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftales-of-symphonia-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/tales-of-syphonia-steam-gift-global-i10000010264002?gtag=391e4ef696"],
        franchise: 'Tales of',
        twitter_ids: ["1788136323330498702"],
        release_year: '2003',
        metacritic_score: '86%',
        genre: 'Action, RPG',
        console_platform: 'GameCube',
        developer: 'Namco Tales Studio Ltd., Production I.G, Inc.',
        backloggd_link: '',
    },
    '726': {
        content: 'Dragon Warrior, known as Dragon Quest in Japan, is a classic role-playing game developed by Chunsoft and published by Enix. Released in 1986, the game ushered players into a medieval fantasy world, casting them as a young hero on a quest to save Princess Gwaelin and defeat the villainous Dragonlord. Inspired by Western RPGs like Ultima, Dragon Warrior brought the genre to the masses in Japan, emphasising strategic combat and intricate dungeon exploration. Played from a top-down perspective, the gameplay revolves around turn-based battles, grinding for experience points, and discovering new towns, dungeons, and secrets. This, combined with a deep, immersive narrative told through in-game dialogues, set the standard for the modern Japanese role-playing genre. Universally acclaimed for its groundbreaking design and captivating storytelling, Dragon Warrior is an enduring classic that continues to influence the RPG genre. If you\'re a fan of iconic role-playing games like Final Fantasy or Breath of Fire, you\'ll be a fan of Dragon Warrior.',
        submitted_by: '',
        answers: ["Dragon Warrior", "Dragon Quest"],
        affiliate_links: [],
        franchise: 'Dragon Quest',
        twitter_ids: [],
        release_year: '1986 (Remastered 2019)',
        metacritic_score: 'Original release too old for metacritic but would have received a high score (Remaster 63%)',
        genre: 'RPG',
        console_platform: 'NES (Remastered on Switch)',
        developer: 'Chunsoft Co., Ltd.',
        backloggd_link: '',
    },
    '727': {
        content: 'Her Story is an interactive movie and adventure game developed by Sam Barlow. Released in 2015, it invites players into a captivating detective narrative as they analyse a series of police interview tapes to uncover the truth about a woman\'s reported missing husband. The game is primarily driven by its innovative use of live-action video footage, placing players in front of a retro PC interface to search and view clips of the various interviews with the woman, portrayed by actress Viva Seifert. With a non-linear storyline, Her Story grants players significant freedom in piecing together the mystery, letting them explore the database of video clips in any order, with progression largely guided by their own intuition and curiosity. Its unique narrative structure, compelling acting, and the immersion of its investigative gameplay have been highly praised. Her Story stands as a testament to the potential of interactive storytelling, providing an engaging and unique experience. If you\'re a fan of narrative-driven games like Gone Home or interactive mysteries like The Vanishing of Ethan Carter, you\'ll certainly be a fan of Her Story.',
        submitted_by: '',
        answers: ["Her Story"],
        affiliate_links: ["https://www.g2a.com/her-story-steam-gift-global-i10000002178001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '86%',
        genre: 'Full Motion Video (FMV), Point-and-click, Mystery',
        console_platform: 'PC, Mobile, Macintosh',
        developer: 'Half Mermaid Productions',
        backloggd_link: '',
    },
    '728': {
        content: 'Gex: Enter the Gecko is a platform game developed by Crystal Dynamics and published by Midway Games. Released in 1998, it brings the snarky, television-obsessed gecko, Gex, back for more high-paced action. After his retirement from the public eye, Gex is pulled back into the Media Dimension to combat his archenemy, Rez. The player, embodying Gex, traverses through a range of TV-themed levels, each tailor-made satires of popular television and movie genres, from Looney Tunes cartoons to James Bond spy flicks. Gameplay in Gex: Enter the Gecko is characterized by its platforming mechanics and the use of Gex\'s specialized abilities such as climbing on walls and ceilings or whipping enemies with his tail. The game challenges players to find remote controls hidden within each level to progress further into the Media Dimension, adding an element of exploration into its platforming format. With its unique thematic approach, humorous tone, and dynamic gameplay, Gex: Enter the Gecko stands as a memorable title from the era. If you\'re a fan of platforming games with a comedic touch like Conker\'s Bad Fur Day or Earthworm Jim, you\'ll be a fan of Gex: Enter the Gecko.',
        submitted_by: '',
        answers: ["Gex: Enter the Gecko", "Gex 2"],
        affiliate_links: [],
        franchise: 'Gex',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: 'Original series too old for metacritic',
        genre: 'Action Adventure, 3rd person',
        console_platform: 'PC, N64, PS',
        developer: 'Crystal Dynamics, Inc.',
        backloggd_link: '',
    },
    '729': {
        content: '',
        submitted_by: '',
        answers: ["Balatro"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Balatro%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbalatro-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/balatro-pc-steam-gift-global-i10000503618002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1789679201995391341", "1789645644816076887"],
        release_year: '2024',
        metacritic_score: '90%',
        genre: 'Cards, Turn Based, Roguelike',
        console_platform: 'XONE, PC, Switch, PS5, Macintosh, Xbox Series, PS4',
        developer: 'LocalThunk Inc.',
        backloggd_link: '',
    },
    '730': {
        content: 'Middle-earth: Shadow of War is an action-adventure game developed by Monolith Productions and published by Warner Bros. Interactive Entertainment. Released in 2017, the game continues the narrative of Middle-earth: Shadow of Mordor, bringing players back to the mystical landscape of J.R.R. Tolkien\'s Middle-earth. Players control Talion, a ranger who shares his body with the spirit of elf lord Celebrimbor, as they wage war against Sauron in Mordor. The gameplay combines action strategy with an innovative Nemesis System, that personalizes each enemy encountered, making every gameplay experience unique. As Talion, players engage in visceral combat, going head-to-head with massive armies, conquering territories, and forming alliances. Shadow of War\'s dedication to telling a deep, immersive tale set within a rich, storied universe, combined with its unique gameplay mechanics make it a standout entry in the genre of action-adventure games. It\'s been praised for its expansive world, complex characters, and the increased depth of the upgraded Nemesis System. If you\'re a fan of the epic fantasy games like Dragon Age: Inquisition or The Witcher 3: Wild Hunt, you\'ll be a fan of Middle-earth: Shadow of War.',
        submitted_by: '',
        answers: ["Middle-earth: Shadow of War"],
        affiliate_links: ["https://www.g2a.com/middle-earth-shadow-of-war-definitive-edition-steam-gift-global-i10000170809005?gtag=391e4ef696"],
        franchise: 'The Lord of the Rings',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '75%',
        genre: 'Action, RPG',
        console_platform: 'PC, XONE, PS4',
        developer: 'Monolith Productions, Inc.',
        backloggd_link: '',
    },
    '731': {
        content: 'Drakengard 3 is an action role-playing game developed by Access Games and published by Square Enix. Released in 2013 as a prequel to the original game, it transports players into a dark fantasy world fueled by conflict. Players control Zero, a woman who can manipulate magic through song, in her quest to assassinate her five sisters, who have brought peace to the world but at a sacrifice that Zero deems too great. Gameplay in Drakengard 3 alternates between ground-based hack-and-slash combat, aerial battles atop her dragon companion, Mikhail, and role-playing game exploration. Unique to Drakengard 3 is the Intoner mode, where Zero can temporarily boost her abilities to extreme levels, and the addition of weapon stories, which detail the history and lore behind each weapon obtainable in the game. The narrative of Drakengard 3, with its focus on anti-heroine Zero, is both controversial and compelling, offering a mix of bloody action and dark humor that sets it apart from conventional fantasy tales. It has been admired for its unconventional storytelling, role-playing elements, and engaging combat mechanics. If you\'re a fan of the intricate storytelling and combat of games like Nier: Automata or the Dark Souls series, you\'ll be a fan of Drakengard 3.',
        submitted_by: 'https://twitter.com/nagiseku',
        answers: ["Drakengard 3", "Drag-On Dragoon 3"],
        affiliate_links: [],
        franchise: 'Drakengard',
        twitter_ids: ["1790142826241528167", "1790383477663183346"],
        release_year: '2013',
        metacritic_score: '61%',
        genre: 'Action, RPG, Hack and slash, Fantasy',
        console_platform: 'PS3',
        developer: 'Access Games Inc.',
        backloggd_link: '',
    },
    '732': {
        content: 'Return to Monkey Island is a long-awaited adventure game, developed and published by Terrible Toybox. Released in 2022, two decades after its predecessor, the game once again invites players to don the pirate hat of Guybrush Threepwood as he desperately tries to save his wife Elaine from the villainous ghost pirate LeChuck. This sequel stays true to its roots with its robust point-and-click gameplay coupled with sharp humor and tricky puzzles. In Return to Monkey Island, players are tasked with exploring vibrant Caribbean islands and interacting with a host of colorful characters, while solving mind-boggling puzzles that will test their wit and tenacity. Apart from its trademark humor and challenging gameplay, the game especially stands out for its stunning hand-drawn art style which brings an iconic series into the modern age, while retaining its original charm. Return to Monkey Island has won over fans and critics with its clever story, interesting characters, and engaging gameplay. If you\'re a fan of intricate point-and-click adventure games like Thimbleweed Park or The Secret of Monkey Island, you\'ll surely be a fan of Return to Monkey Island.',
        submitted_by: '',
        answers: ["Return to Monkey Island"],
        affiliate_links: ["https://www.g2a.com/return-to-monkey-island-pc-steam-gift-global-i10000336724003?gtag=391e4ef696"],
        franchise: 'Monkey Island',
        twitter_ids: ["1790624253588471935", "1790621586518593590"],
        release_year: '2022',
        metacritic_score: '87%',
        genre: 'Adventure, 3rd-person, Point and Click',
        console_platform: 'PC, Switch, PS5, Mobile, Macintosh, Xbox Series, Linux',
        developer: 'Terrible Toybox, Inc., Bada Games',
        backloggd_link: '',
    },
    '733': {
        content: 'Populous is a god game developed by Bullfrog Productions and published by Electronic Arts. Released in 1989, this groundbreaking game puts players in the divine seat, allowing them to command their followers in a bid for global dominance against an opposing deity. The player takes on the role of a deity, manipulating the game world and inciting followers to wage war on opposing factions. The gameplay in Populous revolves around increasing follower base, which in turn amplifies the player\'s divine powers such as creating natural disasters. There is a distinct emphasis on terrain manipulation, a unique mechanic whereby raising and lowering land could help the player cultivate larger populations or direct enemy forces. With its engaging blend of strategic planning and omnipotent might, Populous is often hailed as the progenitor of the \"God game\" genre. The game has been praised for its innovative gameplay, impactful decisions, and the moral implications of wielding divine powers. If you\'re a fan of strategic god games like Black & White or Reus, you\'ll be a fan of Populous.',
        submitted_by: '',
        answers: ["Populous"],
        affiliate_links: [],
        franchise: 'Populous',
        twitter_ids: ["1791066357561242019", "1791103164277510648"],
        release_year: '1989',
        metacritic_score: 'Original release too old for metacritic score',
        genre: 'Strategy, Isometric',
        console_platform: 'DOS, Amiga, Atari ST',
        developer: 'Bullfrog Productions, Ltd.',
        backloggd_link: '',
    },
    '734': {
        content: 'Skull and Bones is an open-world, naval combat game developed by Ubisoft Singapore. Set free in 2024, players are thrust into the unforgiving waters of the Indian Ocean as a pirate captain during the Golden Age of Piracy. In the vast, multiplayer world of Skull and Bones, you not only fight against rival pirate captains but also face the harsh forces of nature. The gameplay is deeply rooted in naval warfare; players can choose from an array of powerful warships, each with unique abilities and weapons, to engage in fierce naval battles in both the single-player campaign and online multiplayer modes. Maneuvering the ship, adjusting for wind direction, and tactically using your crew during combat make the game\'s battles exciting and strategic. The game also features a dynamic weather system and a day-night cycle, affecting the gameplay and the strategies players have to adopt. Apart from battles, players can also explore, trade, and hunt for treasures in this vast open-world seafaring adventure. Skull and Bones is a fusion of intense naval warfare and expansive world exploration. If you\'re a fan of naval combat games like Assassin\'s Creed: Black Flag, or love exploring open oceans as in Sea of Thieves, you\'ll be a fan of Skull and Bones.',
        submitted_by: '',
        answers: ["Skull and Bones", "Skull & Bones"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Skull%20and%20Bones%207800%20Gold%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fskull-and-bones-7800-gold-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/skull-bones-premium-edition-pc-ubisoft-connect-account-global-i10000156572010?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '58%',
        genre: 'Action, 3rd-person, RPG elements, Open World',
        console_platform: 'PC, PS5, Xbox Series',
        developer: 'Ubisoft Singapore Pte Ltd.',
        backloggd_link: '',
    },
    '735': {
        content: 'Cobalt Core is a tactical roguelike deck-building game developed and published by Rocket Rat Games. Released in 2023, it immerses players in a captivating sci-fi universe where they must navigate the dangers of space using strategic card battles. Players take on the role of a starship captain, assembling a crew and customizing their deck to face off against various threats. The gameplay in Cobalt Core revolves around turn-based combat, with players drawing cards to perform actions, attack enemies, and defend their ship. The game features procedurally generated encounters, ensuring each playthrough is unique and challenging. One of the standout elements of Cobalt Core is its blend of tactical decision-making and deck-building, requiring players to carefully plan their moves and adapt to ever-changing situations. The rich lore and detailed world-building further enhance the immersive experience, making every mission feel significant. Cobalt Core has been praised for its depth, replayability, and strategic complexity. If you\'re a fan of tactical roguelike games like Slay the Spire or FTL: Faster Than Light, you\'ll be a fan of Cobalt Core.',
        submitted_by: '',
        answers: ["Cobalt Core"],
        affiliate_links: ["https://www.g2a.com/cobalt-core-pc-steam-gift-global-i10000501651001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1791853821615833235", "1791544260455751719"],
        release_year: '2023',
        metacritic_score: '94%',
        genre: 'Roguelike deck-building',
        console_platform: 'PC, Switch',
        developer: 'Rocket Rat Games',
        backloggd_link: '',
    },
    '736': {
        content: 'Company of Heroes 2 is a real-time strategy video game developed by Relic Entertainment and published by Sega. Released in 2013, it is a sequel to the highly praised Company of Heroes. Set in World War II, the game focuses on the Eastern Front, with the players primarily commanding the Red Army in their attempt to repel the Nazi invaders from the Soviet Union. The game is marked by its Essence 3.0 game engine which features TrueSight that mimics troop visibility in real-world combat. Weather conditions are another key gameplay factor that deeply affect the battlefield visibility and troop movement. As a commander, players have to make strategic decisions, manage resources, and navigate their troops in the harsh realities of war. Alongside the robust single-player mode, the game also boasts a compelling multiplayer scene with competitive and cooperative modes. Company of Heroes 2 was lauded for its intense strategic gameplay, realistic combat mechanics, and atmospheric setting. If you\'re a fan of rich, tactical war games like Age of Empires or StarCraft, you\'ll be a fan of Company of Heroes 2.',
        submitted_by: '',
        answers: ["Company of Heroes 2"],
        affiliate_links: ["https://www.g2a.com/company-of-heroes-2-master-collection-steam-gift-global-i10000007716002?gtag=391e4ef696"],
        franchise: 'Company of Heroes',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '80%',
        genre: 'Real-time strategy',
        console_platform: 'PC',
        developer: 'Relic Entertainment',
        backloggd_link: '',
    },
    '737': {
        content: 'Wii Fit is a fitness-themed video game developed and published by Nintendo for the Wii console. Released in 2007, Wii Fit redefined the concept of video gaming by integrating fitness exercises into a fun and interactive platform. Players use the Wii Balance Board, a device resembling a weight scale, to participate in a variety of activities including yoga, strength training, aerobics, and balance games aimed at promoting a healthy, active lifestyle. The game employs a unique user interface that allows players to plan and track workouts, compete in challenges, and monitor their progress with fitness age calculations and weight management graphs. Each exercise and game blends physical activity and gameplay elements that utilize the balance board in innovative ways. Wii Fit\'s unique integration of gameplay and physical activity has been praised for promoting exercise in an enjoyable way and its ability to cater workouts to different skill levels. Not only a commercial success, but it also paved the way for a new era of workout-centric games. If you\'re a fan of fitness games like Zumba Fitness or Just Dance, or you love the innovation Nintendo brings to its gaming hardware, like the Wii Sports, you\'ll be a fan of Wii Fit.',
        submitted_by: '',
        answers: ["Wii Fit", "Wii Fit U", "Wii Fit Plus"],
        affiliate_links: [],
        franchise: 'Wii Sports',
        twitter_ids: ["1792333930710098403", "1792635726892335563", "1792554617269576066"],
        release_year: '2007',
        metacritic_score: '80%',
        genre: 'Exergaming',
        console_platform: 'Wii',
        developer: 'Nintendo EAD.',
        backloggd_link: '',
    },
    '738': {
        content: 'Resident Evil 2 is a survival horror game developed and published by Capcom, released in 1998. It blends elements of action and puzzle-solving in a chilling and suspense-filled gameplay set in the zombie-infested Raccoon City. Players take control of either Leon S. Kennedy, a rookie police officer, or Claire Redfield, a college student looking for her brother, as they attempt to escape the city. In Resident Evil 2, players combat horrifying creatures and solve intricate puzzles while exploring the city. The narrative changes depending on the chosen character and path, adding depth and replayability. Among Resident Evil 2\'s distinct features is its \"Zapping System\" which offers a different perspective on the story and slightly modified scenarios depending on the character and order of play. This title was a massive success and set a high standard for survival horror games, praised for its atmosphere, storyline, gameplay, and audio design. If you\'re a fan of survival horror games like Silent Hill or DEAD SPACE, you\'ll be a fan of Resident Evil 2.',
        submitted_by: '',
        answers: ["Resident Evil 2"],
        affiliate_links: [],
        franchise: 'Resident Evil',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '89%',
        genre: 'Survival horror',
        console_platform: 'PS1',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '739': {
        content: 'Gears of War 2 is a tactical third-person shooter developed by Epic Games and published by Microsoft Game Studios. Released in 2008, the second installment in the Gears of War series, the game thrusts players back into the dystopian planet of Sera, controlling COG soldier Marcus Fenix in humanity\'s desperate struggle against the subterranean Locust Horde. Gears of War 2 has players fighting through intense battles, utilizing cover and cooperative tactics with supporting characters while wielding a variety of weapons, including the iconic chainsaw bayonet-equipped assault rifle. Diverse gameplay elements range from on-rails vehicle sequences and combat in huge mechs to sections where Marcus must repel waves of enemies. The sequel introduced the popular Horde mode, a cooperative multiplayer mode in which up to five players face increasingly difficult waves of Locust enemies. Gears of War 2 has been highly lauded for its immersive narrative, refined gameplay mechanics, and the addition of Horde mode. If you\'re a fan of tactical shooters like Tom Clancy\'s Rainbow Six Siege or epic story games like Mass Effect, you\'ll be a fan of Gears of War 2.',
        submitted_by: '',
        answers: ["Gears of War 2"],
        affiliate_links: ["https://www.g2a.com/gears-of-war-2-xbox-360-xbox-live-key-global-i10000003318001?gtag=391e4ef696"],
        franchise: 'Gears of War',
        twitter_ids: ["1793247372027277506", "1793370630185971893", "1793135664835465363", "1793173685462864089"],
        release_year: '2008',
        metacritic_score: '93%',
        genre: '3rd-person, Shooter',
        console_platform: 'X360',
        developer: 'Epic Games',
        backloggd_link: '',
    },
    '740': {
        content: 'Rome: Total War is a strategy game developed by The Creative Assembly and published by Activision. Released in 2004, it immerses players into the classical antiquity period, specifically the wars of the Roman Republic. Taking on the role of a leader of one of the three great houses of Rome - the Julii, Brutii, or Scipii - players are tasked with expanding their influence across an integrated campaign map that represents provinces of the Roman world. Gameplay in Rome: Total War is a blend of strategic planning on the world map and tactical decisions on the battlefield. Players manage the economic, diplomatic, and military aspects of their faction, while real-time tactical battles put them in command of thousands of troops in grand, historically-inspired battles. Rome: Total War has been lauded for its impressive scale, innovative blend of turn-based strategy and real-time tactics, and rich historical detail. If you\'re a fan of strategy games like Civilization or Age of Empires, you\'ll be a fan of Rome: Total War.',
        submitted_by: '',
        answers: ["Rome: Total War"],
        affiliate_links: ["https://www.g2a.com/rome-total-war-collection-steam-gift-global-i10000044486001?gtag=391e4ef696"],
        franchise: 'Total War',
        twitter_ids: ["1793421608071332113", "1793739697174577589", "1793486666058928262"],
        release_year: '2004',
        metacritic_score: '92%',
        genre: 'Real-time tactics, turn-based strategy',
        console_platform: 'PC',
        developer: 'Creative Assembly',
        backloggd_link: '',
    },
    '741': {
        content: 'Payday 3 is an action-packed first-person shooter developed by Starbreeze Studios. Released in 2023, the much-anticipated game transports players back into the intense world of high-stakes heists and relentless law enforcement. In this installment, players embrace the roles of seasoned criminals undertaking daring heists across various locations. Payday 3 notably evolves its predecessors\' gameplay with improved AI, more complex missions, and advanced character customization options to enhance each player\'s strategic approach. In addition to the main missions, players can engage in dynamic scenarios where their choices significantly impact the mission’s outcome, adding depth and replayability. The game facilitates cooperation with its focus on multiplayer gameplay, demanding team coordination and strategy to complete heists successfully. The comprehensive crime.net interface, another unique feature, allows players to pick contracts, upgrade equipment, and manage assets. With its improved graphics and immersive narrative, Payday 3 provides fans with a grittier, more refined entry to the series. If you\'re a fan of cooperative first-person shooters like Left 4 Dead or Rainbow Six: Siege, you\'ll be a fan of Payday 3.',
        submitted_by: '',
        answers: ["Payday 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=PAYDAY%203%20GOLD%20EDITION%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fpayday-3-gold-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/payday-3-gold-edition-pc-steam-gift-global-i10000339647011?gtag=391e4ef696"],
        franchise: 'Payday',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '66%',
        genre: 'First-person shooter',
        console_platform: 'PC, PS5, Xbox Series',
        developer: 'Starbreeze Studios',
        backloggd_link: '',
    },
    '742': {
        content: 'Raft is a survival game developed by Redbeet Interactive and published by Axolot Games, released in 2022. Stranded on a small raft in the middle of the ocean, players find themselves in a struggle for survival against the elements. The game is characterized by its open-world sea adventure gameplay that challenges players to expand their raft, gather resources, and defend themselves against a multitude of threats, including sharks and other marine menaces while keeping hydration and hunger levels in check. A unique aspect of Raft is its emphasis on water-based gameplay and raft-building mechanics, allowing players to customize their floating home. Multiplayer support enhances the experience by letting you work with others in the quest for survival. Exploration plays a critical role in Raft, with players discovering islands, underwater wreckage, and encountering other players across its extensive marine world. Raft\'s unique blend of survival, resource gathering, and exploration mechanics, alongside its unusual maritime setting, make it an engaging and challenging game. If you\'re a fan of survival games like The Forest or Stranded Deep, you\'ll be a fan of Raft.',
        submitted_by: '',
        answers: ["Raft"],
        affiliate_links: ["https://www.g2a.com/raft-steam-gift-global-i10000155568002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016 (early access), 2022 (full release)',
        metacritic_score: 'Not Rated',
        genre: 'Survival, Sandbox',
        console_platform: 'PC',
        developer: 'Redbeet Interactive',
        backloggd_link: '',
    },
    '743': {
        content: 'Gorogoa is a puzzle video game developed and published by Jason Roberts in 2017. This unique game presents a beautifully hand-drawn world that players explore through intricate puzzles made up of stunningly illustrated panels. Players manipulate the images within each panel, aiming to guide the protagonist, a boy, on his quest to find mythical beasts. Unlike traditional games where players control characters directly, in Gorogoa, players explore and manipulate a patchwork of interactive scenes on a two-dimensional grid. Split, stacked, and combined in various ways, these illustrations unravel the narratively rich world. Drawing upon themes of spirituality, divinity, and the interconnectedness of existence, it\'s renowned for its mesmerizing visuals, innovative gameplay, and immersive narrative design. The game\'s poignant storytelling, combined with its mind-bending puzzles and breathtaking artwork, has won it critical acclaim. If you\'re a fan of inventive puzzle games like Monument Valley or narratively rich exploration games like Journey, you\'ll be a fan of Gorogoa.',
        submitted_by: '',
        answers: ["Gorogoa"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Gorogoa%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgorogoa-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/gorogoa-steam-key-global-i10000146163001?suid=4b9fbb60-63d2-4b5c-b485-7bdba9ac54fe?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1794705513302913037"],
        release_year: '2017',
        metacritic_score: '84%',
        genre: 'Puzzle',
        console_platform: 'PC, iOS, Switch',
        developer: 'Jason Roberts',
        backloggd_link: '',
    },
    '744': {
        content: 'Crusader Kings III is a grand strategy game developed by Paradox Development Studio and published by Paradox Interactive. Released in 2020, it uniquely marries roleplaying and strategy as players are tasked with leading a dynasty through the Middle Ages from the Viking Age to the Fall of Byzantium. In Crusader Kings III, the gameplay is focused on managing crucial elements such as military strategy, political maneuvers, and familial relationships through a blend of diplomacy, warfare, and marriage. It\'s a game dominated by exploration, decision-making, and the handling of complex characters, with hundreds of potential events based on the decisions you make. Its intricate feudal system combined with a deep relatable narrative makes it stand out in the strategy genre. With beautifully rendered character portraits, an easy to navigate UI, and the profound complexity of its giant sandbox world, Crusader Kings III offers an immersive medieval grand strategy experience. If you\'re a fan of deep strategy games like Civilization or Total War, or enjoy the intricacies of managing a narrative-driven simulation game like The Sims, you\'ll be a fan of Crusader Kings III.',
        submitted_by: '',
        answers: ["Crusader Kings III", "Crusader Kings 3"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Crusader%20Kings%20III%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fcrusader-kings-iii-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/crusader-kings-iii-royal-edition-pc-steam-key-global-i10000195605008?suid=0fcb6e6c-bd6e-440e-a6d7-dcc1c815873f?gtag=391e4ef696"],
        franchise: 'Crusader Kings',
        twitter_ids: ["1794956739357401567", "1795076665019683212"],
        release_year: '2020',
        metacritic_score: '91%',
        genre: 'Grand strategy, RPG',
        console_platform: 'PC',
        developer: 'Paradox Development Studio',
        backloggd_link: '',
    },
    '745': {
        content: 'Tekken 5 is a fighting game developed and published by Namco for arcades in 2004, and later ported to the PlayStation 2. The 5th installment in the popular Tekken series thrusts players into the King of Iron Fist Tournament where they can choose to fight as one of over 30 characters, each with their own unique martial art style and move sets. Tekken 5 refines on the series\' fast-paced, combo-heavy gameplay, with enhanced graphics, more fluid movements, and a deeper fighting system that places a greater emphasis on juggling opponents. A pivotal feature of Tekken 5 is its arcade-history mode, where players can play older versions of the Tekken games. It also introduced customization options, allowing players to alter characters\' appearances through clothing and accessories. acclaimed for its stunning visuals, fluid mechanics and vast character roster, Tekken 5 stands as a high point in the series. The game\'s dramatic story mode, larger character roster, and gameplay improvements were well received, marking it as a standout title in the genre. If you\'re a fan of intricate fighting games like Street Fighter or Mortal Kombat, you\'ll certainly be a fan of Tekken 5.',
        submitted_by: '',
        answers: ["Tekken 5"],
        affiliate_links: [],
        franchise: 'Tekken',
        twitter_ids: ["1795175036585746927"],
        release_year: '2004',
        metacritic_score: '88%',
        genre: 'Fighting',
        console_platform: 'Arcade',
        developer: 'Namco',
        backloggd_link: '',
    },
    '746': {
        content: 'Jade Empire is an action role-playing game developed by BioWare and published by Microsoft Game Studios. Launched in 2005, it immerses players in a rich, fantastical world based on ancient China, as they step into the shoes of a martial arts student on a quest to rescue their kidnapped master and unearth deep-seated corruption. In Jade Empire, gameplay revolves around real-time combat, lucrative dialogue, and ethical decision-making, which shape the protagonist\'s moral alignment, affecting the narrative\'s flow and outcomes. Players will encounter a broad spectrum of fighting styles, with the flexibility to switch among martial arts, magic, and weapon-based combat. Character development, party formation, and side quests add another layer of complexity to this enchanting RPG. Jade Empire has garnered praise for its unique combat system, immersive narrative steeped in Chinese mythology, and the ethical complexities driving the storyline\'s progression. If you\'re a fan of action-packed role-playing games such as Knights of the Old Republic or Dragon Age: Origins, you\'ll be a fan of Jade Empire.',
        submitted_by: '',
        answers: ["Jade Empire"],
        affiliate_links: ["https://www.g2a.com/jade-empire-special-edition-steam-gift-global-i10000000076001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1795678590564196772", "1795780381754479076"],
        release_year: '2005',
        metacritic_score: '89%',
        genre: 'Action, RPG',
        console_platform: 'Xbox',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '747': {
        content: 'Alone in the Dark is a survival horror game developed by Infogrames. Released in 1992, it pushes players into the eerie mansion of Derceto, tasked with stepping into the shoes of either Edward Carnby, a private investigator, or Emily Hartwood, Derceto\'s seemingly doomed niece. In Alone in the Dark, players are met with an interesting blend of action-adventure and survival-horror gameplay elements, as they unravel an occult-themed plot deeply rooted in H.P. Lovecraft\'s mythology. From fending off zombies to deciphering riddles and puzzles, every inch of Derceto brings adventure and horror simultaneously. With dynamic scenario modulations between exploring the haunting mansion, evading creatures, or fighting them off, the game unfolds further layers of suspense through fixed, cinematic camera angles offering pre-rendered backgrounds which was novel for its time. Alone in the Dark is hailed as the pioneer of the survival-horror genre, marking its esteemed legacy with its innovative gameplay, suspenseful story, and immersive experience. If you\'re a fan of absorbing survival horror titles like Resident Evil or Silent Hill, you\'ll be a fan of Alone in the Dark.',
        submitted_by: '',
        answers: ["Alone in the Dark"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Alone%20in%20the%20Dark%20Anthology%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Falone-in-the-dark-anthology-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/alone-in-the-dark-the-trilogy-1-2-3-gogcom-key-global-i10000007224003?gtag=391e4ef696"],
        franchise: 'Alone in the Dark',
        twitter_ids: ["1795950828307526136"],
        release_year: '1992',
        metacritic_score: 'Too old for metacritic',
        genre: 'Survival horror',
        console_platform: 'DOS',
        developer: 'Infogrames',
        backloggd_link: '',
    },
    '748': {
        content: 'Super Mario Strikers, also known as Mario Smash Football, is an engaging soccer video game developed by Next Level Games and published by Nintendo for the GameCube console. Launching in 2005, Super Mario Strikers invites players onto an electrifying, over-the-top version of a soccer field, teaming up with familiar faces from the Mushroom Kingdom like Mario, Luigi, Peach, Donkey Kong, and Yoshi to name a few. The game stands out among other sports titles by taking the traditional rules of soccer and adding a hefty dose of Mario-style power-ups, special moves, and chaotic action. The game highlights 5-on-5 matches, where players can pass, shoot, and tackle competitors while also harnessing unique Super Strikes to score impressive goals. The game also features a distinctive art style with edgier character designs, which sets it apart from the usual light-hearted aesthetic of Mario games. Super Mario Strikers has been greatly praised for its fun multiplayer modes, enhancing the arcade-style soccer with elements of unpredictability and intense competitiveness. If you\'re a fan of sports video games with an arcade twist such as Rocket League or Mario Kart, you\'ll be a fan of Super Mario Strikers.',
        submitted_by: '',
        answers: ["Super Mario Strikers"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '76%',
        genre: 'Sports',
        console_platform: 'GameCube',
        developer: 'Next Level Games',
        backloggd_link: '',
    },
    '749': {
        content: '',
        submitted_by: '',
        answers: ["Thimbleweed Park"],
        affiliate_links: ["https://www.g2a.com/thimbleweed-park-steam-gift-global-i10000035690002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2017',
        metacritic_score: '84%',
        genre: 'Adventure, 3rd-person, Puzzle elements, Point-and-click, Comedy, Crime',
        console_platform: 'XONE, Switch, PS4, Mobile, Linux, Macintosh, PC',
        developer: 'Terrible Toybox, Inc.',
        backloggd_link: '',
    },
    '750': {
        content: 'Spyro 2: Season of Flame is a platforming video game developed by Digital Eclipse and published by Universal Interactive. Released in 2002, this installment of the Spyro series takes players back to the dragon realms, where all the fireflies that are responsible for dragon\'s fire-breathing capabilities have been kidnapped. As Spyro, players are tasked to retrieve the missing fireflies and restore the dragon realms to normal. Spyro 2: Season of Flame offers a mix of platforming, exploration, and puzzle-solving gameplay. Spyro can run, jump, glide, and now use new breath types like ice and lightning, each with their own unique abilities to help traverse each level and defeat enemies. This game also introduces multiple playable characters, added as a unique twist to offer varied gameplay in addition to Spyro\'s traditional levels. Praised for its diverse platforming levels, engaging gameplay, colorful graphics, and the new elemental breath mechanics, Spyro 2: Season of Flame offers an exhilarating and enjoyable journey to fans of the genre. If you\'re a fan of inventive platform games like Crash Bandicoot or Jak and Daxter, you\'ll be a fan of Spyro 2: Season of Flame.',
        submitted_by: '',
        answers: ["Spyro 2: Season of Flame", "Spyro 2"],
        affiliate_links: [],
        franchise: 'Spyro the Dragon',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '76%',
        genre: 'Isometric, Adventure, Platform, Puzzle elements',
        console_platform: 'GBA',
        developer: 'Digital Eclipse Software, Inc.',
        backloggd_link: '',
    },
    '751': {
        content: 'Street Fighter 6 is a competitive fighting game developed by Capcom. Released in 2023, it propels players into intense one-on-one battles and deepens the intricate story of the Street Fighter universe. Players control a diverse roster of fighters, each with unique skills and combat styles, in an array of vividly-rendered stages. From fireballs to uppercuts, the tactical depth of hand-to-hand combat is brought to life with precise controls and fluid animations. The engaging storyline further builds on the conflicts and relationships among the fighters, while arcade, versus, and online multiplayer modes provide extensive replayability. Perhaps most unique is the use of \"V-System\" game mechanics, which allow for characters to perform character-specific attacks and strategies, adding another layer of complexity and strategy to the game. Street Fighter 6 has been highly praised for its balanced yet complex fighting system, visually impressive aesthetics and the depth of its character roster and storylines. If you\'re a fan of competitive fighting games such as Mortal Kombat, Tekken, or the previous iterations in the Street Fighter franchise, you\'ll absolutely be a fan of Street Fighter 6.',
        submitted_by: '',
        answers: ["Street Fighter 6"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Street%20Fighter%206%20Ultimate%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fstreet-fighter-6-ultimate-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/street-fighter-6-ultimate-edition-pc-steam-key-global-i10000337542014?gtag=391e4ef696"],
        franchise: 'Street Fighter',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '92%',
        genre: 'Side View, Fighting',
        console_platform: 'PS4, Xbox Series, PS5, PC',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '752': {
        content: '',
        submitted_by: '',
        answers: ["WarCraft Rumble"],
        affiliate_links: [],
        franchise: 'Warcraft',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '70%',
        genre: 'Top-down, Tower defense',
        console_platform: 'Mobile',
        developer: 'Blizzard Entertainment Inc.',
        backloggd_link: '',
    },
    '753': {
        content: 'Final Fantasy XV is an action role-playing game developed and published by Square Enix. Released in 2016, it takes players on a journey through the breathtaking world of Eos, following Noctis, the crown prince of the Kingdom of Lucis, on his quest to reclaim his homeland from the empire of Niflheim. Final Fantasy XV stands out in the franchise for its real-time combat system, diverging from the turn-based combat of previous titles. Players can switch weapons on-the-fly and use magical abilities, while also commanding three other characters. Its blend of action and strategy introduces an innovative approach to combat in the series. The game also features a day-night cycle, which impacts monster strengths and abilities, adding further depth to the gameplay. In addition to main missions, players can engage in various side quests, fishing, and camping, creating a balance between intense action and leisurely exploration.The game\'s emphasis on friendship and enduring bonds alongside its dazzling visuals and flexible combat system has resulted in high praise. If you\'re a fan of visually stunning, immersive RPG games like The Witcher 3: Wild Hunt or Dragon Age: Inquisition, you\'ll be a fan of Final Fantasy XV.',
        submitted_by: '',
        answers: ["Final Fantasy XV"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=FINAL%20FANTASY%20XV%20WINDOWS%20EDITION%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffinal-fantasy-xv-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/final-fantasy-xv-windows-edition-steam-gift-global-i10000145138007?gtag=391e4ef696"],
        franchise: 'Final Fantasy',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '83%',
        genre: 'Action, RPG, Open world',
        console_platform: 'PS4, XONE',
        developer: 'Square Enix Business Division 2',
        backloggd_link: '',
    },
    '754': {
        content: 'Sam & Max: Hit the Road is a graphic adventure video game developed and published by LucasArts. Released in 1993, it catapults players into the zany universe of Sam and Max, a dog detective and rabbit-like thing, as they embark on a cross-country trek to find the missing star of a local carnival. Sam & Max: Hit the Road utilizes a point-and-click interface, and players guide the duo through a wacky America, solving puzzles and interacting with a host of eccentric characters. With its blend of crime-solving and comedic elements, this game brings a unique wit and charm to the adventure genre, complemented by the game\'s distinctive cartoony visual style and quirky soundtrack. The interactions between Sam and Max are filled with humorous dialogue, adding a layer of comedy to the absorbing puzzle-solving gameplay. Sam & Max: Hit the Road has been praised for its compelling story, innovative gameplay, and whimsical humor. If you\'re a fan of humorous point-and-click adventure games like The Secret of Monkey Island or Leisure Suit Larry, you\'ll be a fan of Sam & Max: Hit the Road.',
        submitted_by: '',
        answers: ["Sam & Max: Hit the Road"],
        affiliate_links: ["https://www.g2a.com/sam-max-hit-the-road-pc-steam-key-global-i10000009541002?gtag=391e4ef696"],
        franchise: 'Sam & Max',
        twitter_ids: ["1798893780520837613", "1798688614903390467"],
        release_year: '1993',
        metacritic_score: 'Original release too old for metacritic score',
        genre: 'Graphic adventure, Point-and-click, Comedy',
        console_platform: 'DOS',
        developer: 'LucasArts Entertainment Company LLC',
        backloggd_link: '',
    },
    '755': {
        content: 'God of War II is an action-adventure game, developed by Santa Monica Studio and published by Sony Computer Entertainment. It was released in 2007, this is the second installment in the God of War series. The game follows Kratos, a spartan warrior who has become the new God of War, but is betrayed by Zeus, leading him on a quest for revenge. Players guide Kratos through ancient Greece, battling mythological creatures, solving puzzles, and engaging in cinematic, visceral combat. God of War II is known for its combination of combat, platforming, and puzzle-solving, alongside its dramatic, high-stakes narrative. Its epic boss battles, often with giant creatures plucked straight from Greek mythology, are one of its defining gameplay elements. In the game, Kratos wields the Blades of Athena, partaking in real-time combat while utilizing magic as well. The game has gained critical acclaim for its spectacular combat system, engaging narrative, and its gritty graphical style. If you\'re a fan of cinematic action games like Prince of Persia: The Sands of Time or the Devil May Cry series, you\'ll be a fan of God of War II.',
        submitted_by: '',
        answers: ["God of War II", "God of War 2"],
        affiliate_links: [],
        franchise: 'God of War',
        twitter_ids: ["1798930569213919566", "1798837927600169449"],
        release_year: '2007',
        metacritic_score: '93%',
        genre: 'Action, Hack and slash, Puzzle elements, Quick Time Events (QTEs)',
        console_platform: 'PS2',
        developer: 'SCE Studio Santa Monica',
        backloggd_link: '',
    },
    '756': {
        content: 'Thirsty Suitors is a vibrant action role-playing game developed by Jank Brain and published by Xalavia. Launched in 2023, the game invites players into the chaotic world of Jala, a young South Asian woman who must battle her ex-boyfriends through a series of captivating and challenging beat \'em up quests while also cooking memorable, and healing, south Asian recipes. The game takes place in a whimsical representation of South Asian diasporic suburbia, capturing the cultural milieu with its color-filled art style and a dreamy, synth-driven soundtrack reminiscent of Bollywood music. Thirsty Suitors is distinguished by its heartfelt narrative, thrilling battles, and its coupling of brawler and cooking mechanics. It employs a unique playing style, combining traditional RPG elements with rhythm-based combat and cooking mini-games. Players gain experience and learn new combat moves as they progress, while also learning to cook an array of South Asian dishes. The game\'s upbeat and engaging storyline, combined with its distinctive cultural representation, has been much appreciated by players and critics alike. If you\'re a fan of quirky action RPGs with a strong narrative focus like Yakuza: Like a Dragon or Scott Pilgrim vs. the World: The Game, you\'ll become a fan of Thirsty Suitors.',
        submitted_by: '',
        answers: ["Thirsty Suitors"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Thirsty%20Suitors%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthirsty-suitors-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/thirsty-suitors-pc-steam-gift-global-i10000280045001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '80%',
        genre: 'RPG, Sports, 3rd-person, Turn-based, Dating simulation, QTE, Skateboarding',
        console_platform: 'PC, PS5, XONE, PS4, Xbox Series, Switch',
        developer: 'Outerloop Games LLC',
        backloggd_link: '',
    },
    '757': {
        content: '',
        submitted_by: '',
        answers: ["Turnip Boy Robs a Bank"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Turnip%20Boy%20Robs%20a%20Bank%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fturnip-boy-robs-a-bank-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/turnip-boy-robs-a-bank-pc-steam-gift-global-i10000503135003?gtag=391e4ef696"],
        franchise: 'Turnip Boy',
        twitter_ids: ["1799662936333627758", "1799776941421322603"],
        release_year: '2024',
        metacritic_score: '82%',
        genre: 'Action, Diagonal-down, Crime',
        console_platform: 'PC, XONE, Xbox Cloud Gaming, Xbox Series, Switch',
        developer: 'Snoozy Kazoo LLC',
        backloggd_link: '',
    },
    '758': {
        content: 'Dragon\'s Dogma II is a captivating action role-playing game developed and published by Capcom. Launched in 2024 as a follow-up to the successful original, players get to step back into the fantasy world of Gransys, this time with new landscapes, creatures, and quests. In the game, players control a customizable protagonist, known as an Arisen, using a combination of weapons, spells, and support from AI companions, called Pawns, to fight monstrous beings and complete quests. Dragon\'s Dogma II stays true to the action-packed real-time combat and vast open world exploration that made the original stand out. It also revitalizes the game\'s innovative \'Pawn system,\' where players can train and command their AI companions and share them online with others. It has been highly praised for its complex and dynamic combat mechanisms, its richly detailed world, and engrossing narrative. The advance in console technology has allowed for an even greater sense of immersion and detail in the environments and monstrous adversaries players face. If you\'re a fan of high-stakes combat and expansive world exploration games like Dark Souls or The Witcher, you\'ll surely be a fan of Dragon\'s Dogma II.',
        submitted_by: '',
        answers: ["Dragon's Dogma II"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dragons%20Dogma%202%20Deluxe%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdragons-dogma-2-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dragons-dogma-ii-deluxe-edition-pc-steam-account-global-i10000502239010?suid=2d8f6a32-f601-449c-aac6-ad0eb9dbd50b?gtag=391e4ef696"],
        franchise: 'Dragon\'s Dogma',
        twitter_ids: ["1800277587232477244"],
        release_year: '2024',
        metacritic_score: '88%',
        genre: 'Action, RPG,  Open world',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '759': {
        content: 'Helldivers is a hardcore, cooperative, twin-stick shooter game developed by Arrowhead Game Studios and published by Sony Computer Entertainment in 2015. The game flings you into an intergalactic war where your primary objective is to protect Super Earth by combating alien races. You are part of the Helldivers, the spearhead of humanity\'s defense. Gameplay is based around completing objectives on procedurally generated maps, battling against three different enemy species. This top-down shooter game pushes cooperative gameplay with friendly fire potentially damaging or killing allies, necessitating careful shooting and tactical team coordination. What sets Helldivers apart is its Galactic Campaign feature, wherein players across the world participate in a shared struggle for control of the galaxy. Every victory and defeat impacts the same shared galactic map, uniting players in a common cause. The game has been highly rated for its intense, cooperative play and unique galactic war mechanic. If you\'re a fan of cooperative, tactical shooters like Alien Swarm or action-packed games with an interstellar setting like StarCraft, you\'ll be a fan of Helldivers.',
        submitted_by: '',
        answers: ["Helldivers"],
        affiliate_links: ["https://www.g2a.com/helldivers-digital-deluxe-edition-steam-key-global-i10000007969002?suid=a41c8155-d777-4827-81cd-04d448d0e74e?gtag=391e4ef696"],
        franchise: 'Helldivers',
        twitter_ids: [],
        release_year: '2015',
        metacritic_score: '83%',
        genre: 'Action, Top-down, Persistent, Sci-fi',
        console_platform: 'PS3,PS4, PC',
        developer: 'Arrowhead Game Studios AB',
        backloggd_link: '',
    },
    '760': {
        content: 'Kirby\'s Epic Yarn is a platformer game developed by Good-Feel and HAL Laboratory and published by Nintendo for the Wii console. Released in 2010, it offers players a new take on Kirby, a popular character from Nintendo\'s repertoire, as he is transformed into a creature made of yarn after being swallowed by a mysterious creature called Yin-Yarn. Instead of his usual ability to eat enemies, Kirby can now use his new yarn form to alter the shape of his body to solve puzzles and defeat enemies. The gameplay in Kirby\'s Epic Yarn is easy to grasp, making it suitable for players of all ages. It uses a unique art style where everything in the game appears to be made of fabric. The game also introduces cooperative multiplayer which allows two players to play through the entire story together. Kirby\'s Epic Yarn received praise for its creative gameplay mechanics, beautifully whimsical aesthetic, and its accessibility to players of all experience levels. If you\'re a fan of platformers like Super Mario Bros. or Yoshi\'s Woolly World, you\'ll be a fan of Kirby\'s Epic Yarn.',
        submitted_by: '',
        answers: ["Kirby's Epic Yarn"],
        affiliate_links: [],
        franchise: 'Kirby',
        twitter_ids: ["1800743322178453906"],
        release_year: '2010',
        metacritic_score: '86%',
        genre: 'Action, Side view, 2D scrolling, Platform',
        console_platform: 'Wii',
        developer: 'Good-Feel Co., Ltd.',
        backloggd_link: '',
    },
    '761': {
        content: 'Disneys Aladdin is a platform game developed by Virgin Games and Disney Software based on the 1992 motion picture of the same name. Released in 1993, players experience the captivating world of Agrabah as Aladdin, the diamond in the rough, seeking his destiny and love. The gameplay in Disney\'s Aladdin is primarily focused on action and platforming, with Aladdin navigating the busy streets of Agrabah, the treasures-filled Cave of Wonders, and the Sultan\'s palace. Assisting Aladdin is a magic carpet, clever monkey friend Abu, and a magical genie. The game stands out with its animation, as the animators from the Disney movie also assisted in creating the game\'s graphics, providing it with a unique and authentic Disney feel. The player has to stay one jump ahead of their foes using Aladdins acrobatic skills, swordplay, and even clever tricks with Abu, allowing for a diverse and entertaining playstyle. Disney\'s Aladdin has been praised for its compelling gameplay and faithful adaptation of the animated film\'s charm and wonder. If you\'re a fan of visually enthralling platform games like Lion King or Jungle Book, you\'ll be a fan of Disney\'s Aladdin.',
        submitted_by: '',
        answers: ["Disney's Aladdin", "Aladdin"],
        affiliate_links: ["https://www.g2a.com/disneys-aladdin-steam-key-global-i10000169385001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1801122929867932028", "1801182564159266989", "1801183075252003261", "1801109515229606259", "1801051222373269598"],
        release_year: '1993',
        metacritic_score: 'Original release too old for metacritic',
        genre: 'Side view, 2D scrolling, Platform',
        console_platform: 'SNES',
        developer: 'Capcom',
        backloggd_link: '',
    },
    '762': {
        content: 'Brothers: A Tale of Two Sons is an adventure game set in a stunning fantasy world, developed by Starbreeze Studios and published by 505 Games. Released in 2013, players control two brothers, Naiee and Naia, simultaneously on their quest to find a cure for their dying father. Unlike single-character adventure games, Brothers requires players to manipulate two characters simultaneously, using a unique dual-stick control scheme, which plays a significant role in puzzle-solving and environment interaction, forming an integral part of the gameplay. The brothers\' journey leads them across a spectrum of meticulously designed environments, encountering a plethora of mythical creatures and taking on a variety of tasks and puzzles, from cooperative cliff climbing to calming a grieving giantess. This game blends strong narration with compelling gameplay mechanics, and the emotional plot unfolds without a single dialogue line, relying on actions, gestures and shared experiences between the two brothers. Brothers: A Tale of Two Sons has been lauded for its visual storytelling, innovative gameplay, and emotionally nuanced narrative. If you\'re a fan of narrative-driven adventure games like Journey or Limbo, you\'ll be a fan of Brothers: A Tale of Two Sons.',
        submitted_by: '',
        answers: ["Brothers: A Tale of Two Sons"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Brothers%20A%20tale%20of%20Two%20Sons%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbrothers-a-tale-of-two-sons%2F&intsrc=PUI2_9608"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2013',
        metacritic_score: '90%',
        genre: 'Action, Adventure, Puzzle elements',
        console_platform: 'PS3, X360, PC',
        developer: 'Starbreeze Studios AB',
        backloggd_link: '',
    },
    '763': {
        content: 'Norco is an indie adventure game developed by Geography of Robots and published by Raw Fury. Released in 2022, Norco thrusts players into a dystopian Southern Louisiana as they embark on a journey to find their missing brother. Set in an industrialized world experiencing environmental decay, players explore pixel-art landscapes teeming with detailed, atmospheric scenes and rich in local culture and history. The gameplay in Norco unpacks a deep narrative through dialogue, exploration, and puzzles, featuring branching dialogue trees and an interactive narrative that evolves based on player choices. The game is distinguished by its gritty, realist narrative threaded with Southern Gothic elements. Norco has been lauded for its beautifully grim pixel-art visuals, haunting atmospheric soundtrack, and thought-provoking themes of ecological crisis and familial bonds. Its engrossing tale of resilience and determination in an unsettling dystopia resonates deeply, making it a game that\'s hard to forget. If you\'re a fan of narrative-driven adventure games like Kentucky Route Zero and Disco Elysium, you\'ll be a fan of Norco.',
        submitted_by: '',
        answers: ["Norco"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=NORCO%20Special%20Edition%20ZTORM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fnorco-special-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/norco-pc-steam-gift-global-i10000337894003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2022',
        metacritic_score: '89%',
        genre: 'Adventure game',
        console_platform: 'PC',
        developer: 'Geography of Robots',
        backloggd_link: '',
    },
    '764': {
        content: 'DayZ is an open-world survival horror video game developed and released by Bohemia Interactive in 2018. Set in the wasteland of a post-Soviet state hit by a new and unknown infection turning humans into infected zombies, players are plunged right into the heart of the apocalypse where survival is critical. The game employs a unique blend of multiplayer, first and third-person perspectives and comprehensive survival mechanics allowing players not just to fight infected, but other players, wilderness, diseases, and starvation. Players navigate through the expansive game environment, scavenging for food, water, ammunition, and gear while avoiding or battling infected and other survivors, all while managing health, thirst, and hunger levels.  Unmatched in its emphasis on player interaction, DayZ enables dynamic and emergent gameplay creating unique storylines and experiences for each player with every playthrough. One prominent feature is the game\'s permadeath, where death, whether from infection or another player, means starting over with a new character. DayZ has been celebrated for its immersive and unforgiving gameplay, attention to realism, and the complexities introduced by the combination of survival and PvP. If you\'re a fan of survival horror games like H1Z1 or Rust, you\'ll be a fan of DayZ.',
        submitted_by: '',
        answers: ["DayZ"],
        affiliate_links: ["https://www.g2a.com/dayz-steam-gift-global-i10000002337002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1802442129198702724"],
        release_year: '2013 (Early Access), 2018',
        metacritic_score: 'Not Rated',
        genre: 'Survival',
        console_platform: 'PC',
        developer: 'Bohemia Interactive Studio s.r.o.',
        backloggd_link: '',
    },
    '765': {
        content: 'Ninja Gaiden is a side-scrolling action-platformer game developed by Tecmo, released in 1988. The game casts players as Ryu Hayabusa, a highly-skilled ninja, on a mission of revenge in America following the death of his father. The plot unravels through cinematic cutscenes, an innovative introduction at the time, and leaves players gripped to the intense narrative. The gameplay consists of challenging levels littered with numerous enemies and traps, requiring quick reflexes and strategic actions to conquer. Players have to utilize Ryu\'s wide range of ninja abilities, such as wall jumping and slashing with the Dragon Sword, along with unique powerups found throughout the levels. The game is notorious for its high level of difficulty, offering an enthralling blend of challenge and reward. The console version introduced even more challenging elements, with enemy respawns and limited continues, adding layers of strategy and planning. Ninja Gaiden has been praised for its unique combination of cinematic storytelling, fast-paced platforming, challenging gameplay and striking 8-bit visuals. If you\'re a fan of hard-core action platformers like Castlevania or Mega Man, you\'ll be a fan of Ninja Gaiden.',
        submitted_by: '',
        answers: ["Ninja Gaiden"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=NINJA%20GAIDEN%20Master%20Collection%20NINJA%20GAIDEN%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fninja-gaiden-master-collection-ninja-gaiden-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/ninja-gaiden-master-collection-deluxe-edition-pc-steam-gift-global-i10000255544012?gtag=391e4ef696"],
        franchise: 'Ninja Gaiden',
        twitter_ids: ["1802461883871396093", "1802461599598301640", "1802467240031502502"],
        release_year: '1988',
        metacritic_score: 'Not rated',
        genre: 'Platform, action, hack-and-slash',
        console_platform: 'NES',
        developer: 'Tecmo, Ltd.',
        backloggd_link: '',
    },
    '766': {
        content: 'SingStar Party is a popular karaoke game developed by SCE London Studio and published by Sony Computer Entertainment. Initially released in 2004 for PlayStation 2, SingStar Party invites players to belt out their favorite chart-toppers, turning your living room into an instant party. The gameplay involves matching your singing to the original artists\' timing and pitch using the included special SingStar microphone. This game offers an array of 30 popular songs and allows for competitive and cooperative multiplayer modes, accommodating up to eight players in Party Mode. Apart from singing the hits, players can record and play back their performances, adding a new level of fun and embarrassment to your SingStar experience. With its commitment to serving as an engaging, entertaining social game, SingStar Party has maintained a beloved spot in the world of party games. The game has been acclaimed for its straightforward, fun gameplay, a wide variety of songs, and its function as the perfect ice breaker at social gatherings. If you\'re a fan of interactive and entertaining party games like Just Dance or Guitar Hero, you\'ll be a fan of SingStar Party.',
        submitted_by: '',
        answers: ["SingStar Party", "SingStar"],
        affiliate_links: [],
        franchise: 'SingStar',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: 'Not Rated',
        genre: 'Music',
        console_platform: 'PS2',
        developer: 'SCEE Studio London',
        backloggd_link: '',
    },
    '767': {
        content: 'Neverwinter Nights is a role-playing game developed by BioWare and published by Infogrames. Released in 2002, the game carries players into the rich fantasy world of the Forgotten Realms, in the city of Neverwinter, where a lethal plague is wreaking havoc. Players assume the role of a customizable protagonist tasked with finding a cure. The game emphasizes character development, dialogue, and narrative depth. Gameplay in Neverwinter Nights includes dungeon delving, combat, and interactions with other characters. Its revolutionary aspect lies in its Aurora toolset, which allows players to create their own modules – playable quests with unique stories and worlds. This has resulted in a vast library of player-created content for endless exploration, taking it beyond the traditional single-player campaign. Neverwinter Nights is noted for its engaging character development, immersive plot, and the breadth of its gameplay, especially due to its level creation toolset. If you\'re a fan of narrative-driven role-playing games like Baldur\'s Gate or Dragon Age: Origins, you\'ll be a fan of Neverwinter Nights.',
        submitted_by: '',
        answers: ["Neverwinter Nights"],
        affiliate_links: [],
        franchise: 'Dungeons & Dragons',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '91%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'BioWare',
        backloggd_link: '',
    },
    '768': {
        content: 'Pitfall! is a classic platformer game developed and published by Activision, first released in 1982. As one of the most popular games on the Atari 2600, Pitfall! had players venture into the shoes of Pitfall Harry, an adventurous explorer navigating his way through a perilous jungle filled with treacherous obstacles and dangerous creatures. Pitfall!\'s gameplay emphasizes precise timing and quick reactions, as players must jump over pits, climb ropes, dodge quicksand, avoid rolling logs, and evade a collection of dangerous wildlife, all in search of its hidden treasures tucked away in underground tunnels and treetops. The game was revered for its non-linear exploration, offering a large jungle landscape to traverse, with the screen scrolling horizontally rather than advancing screen by screen, which distinguished it in the platform genre. Pitfall! has been celebrated for its innovative game design, fluid animation, and the continual sense of tension it delivers. If you\'re a fan of classic adventure platformer games like Super Mario Bros or Donkey Kong, you\'ll be a fan of Pitfall!.',
        submitted_by: '',
        answers: ["Pitfall!"],
        affiliate_links: [],
        franchise: 'Pitfall',
        twitter_ids: ["1803757113581150427", "1803642847737844122", "1803566537954996540"],
        release_year: '1982',
        metacritic_score: 'Not Rated',
        genre: 'Platform',
        console_platform: 'Atari 2600',
        developer: 'Activision, Inc.',
        backloggd_link: '',
    },
    '769': {
        content: 'The Messenger is a retro-inspired action-platformer developed by Sabotage Studio and published by Devolver Digital. Released in 2018, the game follows the journey of a young ninja tasked with carrying a scroll across a demon-infested world to save his clan. Gameplay is a blend of traditional 8-bit era platforming, met with modern design sensibilities. Initially, players progress through linear levels, battling enemies and overcoming obstacles, utilizing a unique cloudstep mechanic which allows consecutive jumps each time they hit an enemy or object. As the story develops, a surprising mid-game transformation shifts the style to a full fledged 16-bit metroidvania, bringing forth complex, non-linear exploration and significant backtracking. This shift expands the ninja\'s capabilities and modifies the world in previously unforeseeable ways. Snappy controls, challenging gameplay, witty dialogues and a stellar synth soundtrack have all contributed to The Messenger\'s high praise. The game has been lauded for its clever design, unexpected narrative twists, tight gameplay and nostalgic callbacks to classic platformers. If you\'re a fan of action-platformers like Shovel Knight or retro-inspired games like Axiom Verge, you\'ll be a fan of The Messenger.',
        submitted_by: '',
        answers: ["The Messenger"],
        affiliate_links: ["https://www.g2a.com/the-messenger-steam-gift-global-i10000171227002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1804123815212986830"],
        release_year: '2018',
        metacritic_score: '86%',
        genre: 'Action, Platformer',
        console_platform: 'PC, Switch',
        developer: 'Sabotage Studio Inc.',
        backloggd_link: '',
    },
    '770': {
        content: '',
        submitted_by: '',
        answers: ["Animal Well"],
        affiliate_links: ["https://www.g2a.com/animal-well-pc-steam-gift-global-i10000505622003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1804282591714947103"],
        release_year: '2024',
        metacritic_score: '91%',
        genre: 'Metroidvania, puzzle',
        console_platform: 'PS5, PC, Switch',
        developer: 'Shared Memory LLC',
        backloggd_link: '',
    },
    '771': {
        content: 'Second Life is a virtual world game developed and published by Linden Lab. Launched in 2003, this ground-breaking online game offers players, also known as residents, the ability to explore a persistently online and ever-changing world, simulate real-life activities, and interact with others in various settings. Players can customize their avatars, construct and furnish their homes, or establish businesses within the game, reflecting real life’s creative and economic activities. In Second life, social interactions take precedence, with no set objectives or mandatory tasks given. Gameplay is largely user-directed, with residents shaping their experiences through their choices and actions. From attending live music performances to participating in group discussions on a myriad of topics, each experience in Second Life can be as unique as its residents. Second Life\'s unique social and interactive model provides an experience unlike any traditional game, redefining boundaries between creator and consumer in virtual space. It has been praised for its innovative use of player agency and immersive social role-play. If you\'re a fan of immersive online games with strong social elements like Habbo Hotel or IMVU, you\'ll be a fan of Second Life.',
        submitted_by: '',
        answers: ["Second Life"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: ["1804855778592850316"],
        release_year: '2003',
        metacritic_score: 'Not Rated',
        genre: 'MMOSG, MMORPG',
        console_platform: 'PC',
        developer: 'Linden Research, Inc.',
        backloggd_link: '',
    },
    '772': {
        content: 'Metroid Prime is a first-person action-adventure game developed by Retro Studios and published by Nintendo. Released in 2002, Metroid Prime represents the first installment in the Metroid Prime series, which offers an immersive experience as players are transported to the alien world of Talon IV. As Samus Aran, an intergalactic bounty hunter, players navigate the perilous 3D environments from a first-person perspective, combating space pirates and indigenous creatures, while unraveling the story behind the destructive Phazon meteor impact. The gameplay in Metroid Prime is a blend of exploration, puzzle-solving, and combat, as players utilize Samus\' wide range of equipment and abilities to traverse through various interconnected environments. What sets Metroid Prime apart is the Morph Ball mechanic, allowing Samus to transform into a compact, mobile sphere to access hard-to-reach areas. It smoothly combines the first-person perspective with the platforming elements of the original Metroid games. Metroid Prime has been praised for its atmospheric exploration, rich, detailed graphics, and well-executed blend of genres. If you\'re a fan of exploration-based games like Tomb Raider or complex shooters like Halo, you\'ll be a fan of Metroid Prime.',
        submitted_by: '',
        answers: ["Metroid Prime"],
        affiliate_links: ["https://www.g2a.com/metroid-prime-remastered-nintendo-switch-nintendo-eshop-account-global-i10000338593003?suid=1ddf81ac-ac3c-4ae9-8964-1126620e9e50?gtag=391e4ef696"],
        franchise: 'Metroid',
        twitter_ids: ["1805023742092804300", "1805092146560577745", "1805108495102222684"],
        release_year: '2002',
        metacritic_score: '97%',
        genre: 'Action, Adventure',
        console_platform: 'GameCube',
        developer: 'Retro Studios Inc.',
        backloggd_link: '',
    },
    '773': {
        content: 'Clash of Clans is a popular freemium mobile strategy game developed and published by the Finnish game developer, Supercell. Released in 2012, the game propels players into a world of fierce clan battles and strategic planning. Players assume the role of village chief and are tasked with expanding their village by building and improving structures, as well as creating an army consisting of various heroic unit types. The core of the gameplay involves attacking other players to loot resources while simultaneously defending their own bases. Clan Wars, introduced in a later update, add a new level of depth to the game, where Clans go head-to-head in epic battles for prestige and bonus loot. As players progress, they can also join together with others to form clans, share troops, and engage in large scale clan wars together. Clash of Clans is renowned for its addictive gameplay, colorful graphics and a friendly community that can often lead to intense rivalries. Its constant updates continue to keep the game fresh and appealing. If you\'re a fan of strategy and base-building games like Age of Empires or Stronghold, you\'ll certainly be a fan of Clash of Clans.',
        submitted_by: '',
        answers: ["Clash of Clans"],
        affiliate_links: [],
        franchise: 'Clash',
        twitter_ids: ["1805423609659249081"],
        release_year: '2012',
        metacritic_score: '74%',
        genre: 'Strategy',
        console_platform: 'iOS',
        developer: 'Supercell Oy',
        backloggd_link: '',
    },
    '774': {
        content: 'Grounded is a survival game developed by Obsidian Entertainment and published by Xbox Game Studios. Set in a suburban backyard, players control a character shrunken to an insect\'s size navigating a massive, vibrant and dangerous world, teaming up with friends to thrive and survive. Launched in 2020 in early access and fully released in 2022, Grounded challenges the players to craft weapons and tools, forage for food, build and fortify bases, and defend against a variety of gargantuan insects. In Grounded, navigating the terrain is as challenging as battling the insects, as each blade of grass and leaf offers a unique route to traverse the backyard. The game has been praised for its unique setting, the depth of its crafting and building systems, its strong emphasis on cooperative play, and its tongue-in-cheek take on the survival genre. The mixture of everyday environments and the extraordinary scale delivers a familiar, yet heart-pounding adventure, with a tight-knit multiplayer community. If you\'re a fan of creative survival games like Minecraft or cooperative building games like Ark: Survival Evolved, you\'ll enjoy engaging with the minuscule yet enthralling world of Grounded.',
        submitted_by: '',
        answers: ["Grounded"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Grounded%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fgrounded-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/grounded-pc-steam-gift-global-i10000206432001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1805840225786020116", "1805929911841939828", "1805819787081138322", "1805745394556494113"],
        release_year: '2020 (Early Access), 2022 (Full Release)',
        metacritic_score: '83%',
        genre: 'Survival',
        console_platform: 'PC, XONE, Xbox Series',
        developer: 'Obsidian Entertainment, Inc.',
        backloggd_link: '',
    },
    '775': {
        content: 'Baldur\'s Gate is a role-playing game developed by BioWare and published by Interplay Entertainment. It was released in 1998 and set in the medieval fantasy world of the Forgotten Realms, a popular setting in Dungeons & Dragons. The game allows players to control a protagonist who sets off on an epic adventure to uncover their true identity and avert a crisis threatening Baldur\'s Gate, a bustling commercial hub in the Sword Coast. Gameplay mechanics in Baldur\'s Gate are a blend of strategic combat and NPC interaction which enforce player decisions and party management. Players can form a party of up to six characters, each with their individual skills and alignments. Character morality plays a key role, influencing the storyline and NPC interactions. Random encounters and exploration make every playthrough unique in this in-depth RPG. Its critical legacy lies in its immersive storytelling, branching dialogues, and detailed character development. If you\'re a fan of deep RPG games like Dragon Age: Origins or Knights of the Old Republic, you\'ll be a fan of Baldur\'s Gate.',
        submitted_by: '',
        answers: ["Baldur's Gate"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Baldurs%20Gate%20Enhanced%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbaldurs-gate-enhanced-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/baldurs-gate-enhanced-edition-steam-gift-global-i10000013953001?gtag=391e4ef696"],
        franchise: 'Baldurs Gate',
        twitter_ids: ["1806133125220639122"],
        release_year: '1998',
        metacritic_score: '91%',
        genre: 'RPG',
        console_platform: 'PC',
        developer: 'BioWare Corporation',
        backloggd_link: '',
    },
    '776': {
        content: 'Assassin\'s Creed Mirage is an action-adventure game developed and published by Ubisoft, which was released in 2023. It continues on the franchise\'s successful path by inviting players to step into the past, this time in a vibrantly depicted fictional city inspired by the Middle East during the era of the Arabian Nights. Players control a dedicated member of the Assassin Brotherhood, tasked with strategic assignments while navigating a complex political landscape. Assassin\'s Creed Mirage carries over the franchise\'s trademark parkour-inspired movement and stealth mechanics, which are enhanced through the dynamic desert environment. An intriguing mix of historical and fantastical elements crafts a compelling narrative, with quests leading players on a journey of discovery and vengeance. The game\'s combat system, characterized by a blend of melee and ranged weapons, is thrilling and immersive. A standout feature in Assassin\'s Creed Mirage is the unique interaction with the environment, with the shifting sands of the desert landscape providing an ever-changing gameplay experience. If you\'re a fan of stealth-action games like Dishonored or historical action-adventure games like Prince of Persia: The Sands of Time, you\'ll become a fan of Assassin\'s Creed Mirage.',
        submitted_by: '',
        answers: ["Assassin's Creed Mirage"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Assassins%20Creed%20Mirage%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fassassins-creed-mirage-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/assassins-creed-mirage-xbox-series-x-s-xbox-live-key-global-i10000339508009?gtag=391e4ef696"],
        franchise: 'Assassins Creed',
        twitter_ids: ["1806458066499490259"],
        release_year: '2023',
        metacritic_score: '76%',
        genre: 'Action, Adventure',
        console_platform: 'PS4, PS5, PC, XONE, Xbox Series',
        developer: 'Ubisoft Bordeaux SAS',
        backloggd_link: '',
    },
    '777': {
        content: '',
        submitted_by: '',
        answers: ["Children of the Sun"],
        affiliate_links: ["https://www.g2a.com/children-of-the-sun-pc-steam-gift-global-i10000504674001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '81%',
        genre: 'Action, Puzzle, Shooter',
        console_platform: 'PC',
        developer: 'René Rother',
        backloggd_link: '',
    },
    '778': {
        content: 'Fallout 76 is an online multiplayer action role-playing game developed by Bethesda Game Studios and published by Bethesda Softworks. Released in 2018, it acts as a prequel to all previous games in the Fallout series, players found themselves in the rugged wilderness of a post-apocalyptic West Virginia. Players in Fallout 76 emerge from the safety of Vault 76 into the unforgiving world of the Wasteland, with a main task to recolonize and rebuild society after the nuclear devestation. Gameplay involves exploration, crafting, building, and combat with a wide range of dystopian creatures amidst irradiated landscapes. It introduced a number of innovative features, such as the ability to build bases anywhere on the map and take part in a dynamic player-driven economy. While there are still quests, it heavily relies on survival-based elements and player interactions, such as forming alliances or making enemies with other players. Fallout 76 has been recognized for its ambitious approach and the visually arresting world it presents for players to explore. If you\'re a fan of survival games with crafting and building mechanics such as Rust or 7 Days to Die, or you enjoy other games in Bethesda\'s RPG repertoire such as Skyrim, you\'ll be a fan of Fallout 76.',
        submitted_by: '',
        answers: ["Fallout 76"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1281787/15105?prodsku=Fallout%2076%20STEAM%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ffallout-76-pc%2F&intsrc=PUI2_9727", "https://www.g2a.com/fallout-76-ps4-psn-account-account-global-i10000156540043?suid=1534f5f2-8890-4156-81cb-576edbb7e3f5?gtag=391e4ef696"],
        franchise: 'Fallout',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '52%',
        genre: 'Action, MMORPG',
        console_platform: 'XONE, PS4, PC',
        developer: 'Bethesda Game Studios',
        backloggd_link: '',
    },
    '779': {
        content: 'Left 4 Dead 2 is a cooperative first-person shooter video game developed and published by Valve. Released in 2009, the game fuses the urgency of horror with the thrill of action-packed shooter gameplay. Set in the aftermath of a zombie apocalypse, players form teams of four and assume the identities of Survivors - ordinary humans with a shared goal of survival. Battling through five campaigns, players traverse various environments, from swamps and storm drains to amusement parks. Unique to Left 4 Dead 2 is its artificial intelligence system, known as the \"Director\", which alters gameplay based on player performance, promoting replayability and keeping players on their toes. Also, it introduced melee weapons to the franchise, offering a more diverse combat experience. Players must utilize teamwork and strategy, reinforcing the importance of cooperation in the face of overwhelming odds. Left 4 Dead 2 has been lauded for its addictive cooperative gameplay, tension-filled atmosphere, and dynamic AI system. If you\'re a fan of cooperative survival shooters like Killing Floor or Payday, you\'ll definitely be a fan of Left 4 Dead 2.',
        submitted_by: '',
        answers: ["Left 4 Dead 2"],
        affiliate_links: ["https://www.g2a.com/left-4-dead-2-steam-key-global-i10000002208012?gtag=391e4ef696"],
        franchise: 'Left 4 Dead',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '89%',
        genre: 'Action, FPS, Survival',
        console_platform: 'X360, PC',
        developer: 'Valve Corporation',
        backloggd_link: '',
    },
    '780': {
        content: 'WarioWare, Inc.: Mega Microgame$! is a quirky, fast-paced party video game developed by Nintendo R&D1 and Intelligent Systems. Published by Nintendo, the game was released in 2003 and lets players control Wario, famed Nintendo character and Mario\'s archrival, who has decided to start his own game company. Gameplay in WarioWare involves rapidly completing a succession of \"microgames\"—brief, simple games that last only a few seconds each, with hundreds spread across multiple themed stages. These stages are presented as software created by the in-game characters, each with its own genre and graphic style, from retro 8-bit to more contemporary designs. The microgames increase in speed and difficulty as the player progresses, creating a frantic and exhilarating experience. WarioWare is renowned for its unconventional gameplay and humor, as well as its unique approach to showcasing bite-sized gaming. Several sequels and spin-offs have built on its innovative style. If you\'re a fan of rapid-paced party games like Mario Party or collections of mini-games like Rhythm Heaven, you\'ll undoubtedly enjoy WarioWare, Inc.: Mega Microgame$!.',
        submitted_by: 'https://twitter.com/Sky096_',
        answers: ["WarioWare, Inc.: Mega Microgame$!", "WarioWare, Inc.: Mega Microgames!", "WarioWare, Inc.: Minigame Mania"],
        affiliate_links: [],
        franchise: 'Wario',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: '89%',
        genre: 'Arcade',
        console_platform: 'GBA',
        developer: 'Nintendo R&D1',
        backloggd_link: '',
    },
    '781': {
        content: 'Rise of the Rōnin is an action-adventure game developed and published by Samurai Studios. Released in 2024, it immerses players in a beautifully rendered, Edo period Japan. In this game, players step into the role of a rōnin, a masterless samurai, roaming across the country in the pursuit of justice and honor. This open-world game offers a multitude of quests involving samurai duels, stealth missions, and historic warfare, all requiring strategic thinking to fulfill a samurai\'s vow. The gameplay combines engaging sword-fighting mechanics with aspects of stealth and tactical decision-making, presenting a blend of intense action and patient strategy that is distinctive to samurai-themed games. Besides combat scenarios, the players also have a chance to explore serene Japanese landscapes and bustling marketplaces, creating an atmosphere that celebrates historical Japanese culture. Rise of the Rōnin has been acclaimed for its authentic visuals of Feudal Japan, strategic gameplay, and a compelling narrative that sends players on a quest to restore their honor. If you\'re a fan of versatile action-adventure games like Sekiro: Shadows Die Twice or historical epics like Ghost of Tsushima, you\'ll be a fan of Rise of the Rōnin.',
        submitted_by: '',
        answers: ["Rise of the R\u014dnin", "Rise of the Ronin"],
        affiliate_links: ["https://www.g2a.com/rise-of-the-ronin-ps5-psn-account-global-i10000503199001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1808649653816029607", "1808470487669874972", "1808352249397027014"],
        release_year: '2024',
        metacritic_score: '76%',
        genre: 'Action, RPG, Adventure',
        console_platform: 'PS5',
        developer: 'Team Ninja',
        backloggd_link: '',
    },
    '782': {
        content: 'Forsaken is a 3D first-person shooter game developed by Probe Entertainment and published by Acclaim Entertainment. Released in 1998, the game presents a post-apocalyptic storyline where players assume the role of a mercenary exploring a vast, forsaken Earth rendered inhospitable by a global warfare. The gameplay prominently features free-floating movement, a cutting edge feature for the time, allowing players to move and aim in all directions as they explore eerie, abandoned spaces. Each level offers different types of enemies, puzzles, and secret areas, ramping up the stakes and suspense. Players can upgrade weapons and equipment, which becomes crucial as challenges escalate. Additionally, Forsaken includes multiplayer modes which add another layer of excitement. Its graphically rich and interactive environment, combined with its immersive storyline and intense action, offers players a truly engaging experience. This game was highly appraised for its physics, graphics, atmospheric soundtrack, and the complexity and variability of its levels. If you\'re a fan of gripping first-person shooter games like Quake or Descent, you\'ll be a fan of Forsaken.',
        submitted_by: '',
        answers: ["Forsaken"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Forsaken%20Remastered%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fforsaken-remastered-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/forsaken-remastered-steam-key-global-i10000170653001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '75%',
        genre: 'FPS, Post-apocalyptic',
        console_platform: 'PC, PS1, N64',
        developer: 'Probe Entertainment Ltd.',
        backloggd_link: '',
    },
    '783': {
        content: 'Dishonored 2 is a stealth action-adventure game developed by Arkane Studios and published by Bethesda Softworks. Released in 2016, it plunges players back into the dark, gritty city of Karnaca, expanding on the narrative of the original Dishonored game. In Dishonored 2, players have the choice of playing as either Emily Kaldwin, a queen who\'s been dethroned by a witch, or Corvo Attano, the disgraced royal protector seeking to restore Emily. The gameplay emphasizes stealth and cunning, but also allows for a more confrontational approach if the player wishes. The game offers a variety of ways to tackle missions from assassination methods to clever infiltration techniques. Its void-powered supernatural abilities like mesmerize and doppleganger, and the innovative use of the time-travel mechanic in one specific mission adds further depth to gameplay and strategy. One of its unique aspects is the \"Chaos system\" which results in story alterations depending on player\'s action and the level of chaos they cause. Dishonored 2 has garnered acclaim for its complex level design, freedom of choice in gameplay and the game\'s reactivity to player decisions. If you\'re a fan of stealth-based games like Hitman or stealth-action experiences like the Metal Gear Solid series, you\'ll be a fan of Dishonored 2.',
        submitted_by: '',
        answers: ["Dishonored 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Dishonored%20Complete%20Collection%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fdishonored-complete-collection-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/dishonored-2-steam-gift-global-i10000002531003?gtag=391e4ef696"],
        franchise: 'Dishonored',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '86%',
        genre: 'Action, Puzzle elements, RPG elements, Stealth',
        console_platform: 'PC, PS4, XONE',
        developer: 'Arkane Studios SASU',
        backloggd_link: '',
    },
    '784': {
        content: 'SteamWorld Build is an adventure real-time strategy game developed and published by Image & Form Games. Released in 2023, it is the newest addition to the popular SteamWorld franchise and introduces players to a charming, vibrant steampunk world where robots have taken over. As the name suggests, SteamWorld Build incorporates extensive building and crafting mechanics, as players control a rag-tag group of steampunk robots striving to construct and defend their town from threats. The gameplay challenges the players to gather resources, create buildings, shape the landscape, and fend off enemies through strategic planning and combat. The robotic world bursts with cheeky humor, engaging characters, and head-scratching puzzles. It stands out with its emphasis on balancing fast-paced strategical decision-making with exploratory adventure, which isn’t typically seen in similar titles. SteamWorld Build has been lauded for its quirky artistic design, immersive building mechanics, and strategic depth. If you\'re a fan of crafting and building games like Terraria or strategy adventures like The Escapists, you\'ll be a fan of SteamWorld Build.',
        submitted_by: '',
        answers: ["SteamWorld Build"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=SteamWorld%20Build%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsteamworld-build-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/steamworld-build-deluxe-edition-pc-steam-account-global-i10000502183006?gtag=391e4ef696"],
        franchise: 'SteamWorld',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '74%',
        genre: 'Simulation, Real-time, City building,',
        console_platform: 'Xbox Series, PC, PS4, XONE, Switch, PS5',
        developer: 'The Station Game Ltd',
        backloggd_link: '',
    },
    '785': {
        content: 'The Elder Scrolls Online is a massively multiplayer online role-playing game (MMORPG) developed by ZeniMax Online Studios and published by Bethesda Softworks. Set in the continent of Tamriel, familiar to fans of the Elder Scrolls series, it immerses players in a thrilling fantasy world, amidst a deadly conflict as the Daedric Prince Molag Bal tries to pull all of Tamriel into his realm. The gameplay comprises real-time combat combined with classic MMO features like crafting, exploration, and character customization. One of its unique elements is the extensive freedom it offers; you can align yourself with one of the three competing factions or go solo, explore the vast realm of Tamriel, pick locks, participate in dynamic events, and even go on criminal sprees. The game offers an expansive deal of lore for the players to explore, in a world that dynamically responds to their actions. If you\'re a fan of immersive role-playing games such as World of Warcraft or Guild Wars, you\'ll be a fan of The Elder Scrolls Online.',
        submitted_by: '',
        answers: ["The Elder Scrolls Online"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1281787/15105?prodsku=The%20Elder%20Scrolls%20Online%20Standard%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fthe-elder-scrolls-online-pc%2F&intsrc=PUI2_9727", "https://www.g2a.com/the-elder-scrolls-online-steam-gift-global-i10000001827002?gtag=391e4ef696"],
        franchise: 'The Elder Scrolls',
        twitter_ids: [],
        release_year: '2014',
        metacritic_score: '71%',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'ZeniMax Online Studios, LLC',
        backloggd_link: '',
    },
    '786': {
        content: 'Far Cry 2 is a first-person shooter video game developed by Ubisoft Montreal and published by Ubisoft. Released in 2008, Far Cry 2 transports players to the heart of Africa wherein lies a war-torn, procedurally generated open-world environment. The storyline revolves around the player\'s mission to locate and assassinate an arms dealer known as \'The Jackal\'. The gameplay focuses heavily on survival tactics, simulated ecosystems and strategy, with players often having to navigate their way through factional disputes and unpredictable wildlifes. The weather system and day-night cycle add another layer of realism, causing the challenge to vary depending on the conditions. What sets Far Cry 2 apart from its peers is the unique immersive experience it provides wherein weaponry might jam or misfire, vehicles degrade and eventually break down, and players are forced to navigate using in-game maps instead of following a HUD. Far Cry 2 has been lauded for its impressive attention to detail, remarkable AI, and its stress on realistic survival tactics, earning it significant appreciation from gamers and critics alike. If you\'re a fan of first-person action games with deep immersion and unhinged survival challenges like Metro Exodus or STALKER: Shadow of Chernobyl, you\'ll be a fan of Far Cry 2.',
        submitted_by: '',
        answers: ["Far Cry 2"],
        affiliate_links: ["https://www.g2a.com/far-cry-2-steam-gift-global-i10000035420002?gtag=391e4ef696"],
        franchise: 'Far Cry',
        twitter_ids: [],
        release_year: '2008',
        metacritic_score: '85%',
        genre: 'Action, Adventure, FPS',
        console_platform: 'PC, PS3, X360',
        developer: 'Ubisoft Entertainment Inc.',
        backloggd_link: '',
    },
    '787': {
        content: 'Battlefield 2042 is a highly immersive multiplayer first-person shooter game developed by DICE and published by Electronic Arts. Released in 2021, it propels players into a near-future setting, amid global conflict fuelled by the world\'s greatest powers fighting over dwindling resources. The game displays a breathtaking world with dynamic weather conditions and fully destructible environments, from South Korea\'s skyscrapers to the desert expanses of Qatar. Players get to assume diverse roles such as engineers, medics, and recon, each offering its unique set of gear and specialties. Battlefield 2042 is beloved for its intense large-scale battles that support up to 128 players and its return of Conquest and Breakthrough modes. Moreover, its introduction of the new Portal mode allows players to recreate and share custom game modes using maps and assets from past Battlefield titles. This game features a stunning next-gen visual experience that brings the theater of war to life and a wide variety of vehicles and cutting-edge military weaponry that add to its immense tactical depth. If you\'re a fan of first-person shooter games with massive, intense multiplayer battles like Call of Duty: Warzone or Fortnite, you might enjoy Battlefield 2042 as well.',
        submitted_by: '',
        answers: ["Battlefield 2042"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Battlefield%202042%20Ultimate%20Edition%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fbattlefield-2042-ultimate-edition-xbox%2F&intsrc=PUI2_9608", "https://www.g2a.com/battlefield-2042-pc-steam-gift-global-i10000255724004?gtag=391e4ef696"],
        franchise: 'Battlefield',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '68%',
        genre: 'FPS',
        console_platform: 'Xbox Series, PC, PS4, XONE, PS5',
        developer: 'EA Digital Illusions CE AB',
        backloggd_link: '',
    },
    '788': {
        content: 'Pokémon XD: Gale of Darkness is a role-playing game developed by Genius Sonority and published by The Pokémon Company for the Nintendo GameCube. Released in 2005, the game offers a unique experience in the Pokémon universe, as players assume the role of a Pokémon Trainer named Michael, on a mission to save the region of Orre from a sinister organization called Cipher who uses Shadow Pokémon for their evil deeds. The gameplay primarily involves battling using traditional Pokémon mechanics, capturing Shadow Pokémon and removing their aggressive tendencies with the unique Snag machine, and exploring various environments. Additionally, Pokémon XD features an array of mini-games in the in-game Poké Spots. What sets Gale of Darkness apart is its focus on Shadow Pokémon and RPG elements, offering a darker narrative than most Pokémon games with its emphasis on saving Pokémon rather than purely battling and collecting. Pokémon XD: Gale of Darkness has been praised for its rich storyline, interesting mechanics involving Shadow Pokémon, and its memorable cast of characters. If you\'re a fan of classic Pokémon games like Pokémon Emerald or engaging RPGs like Final Fantasy, you\'ll likely be a fan of Pokémon XD: Gale of Darkness.',
        submitted_by: '',
        answers: ["Pok\u00e9mon XD: Gale of Darkness", "Pokemon XD: Gale of Darkness"],
        affiliate_links: [],
        franchise: 'Pokemon',
        twitter_ids: [],
        release_year: '2005',
        metacritic_score: '64%',
        genre: 'RPG, Turn-based',
        console_platform: 'GameCube',
        developer: 'Genius Sonority, Inc.',
        backloggd_link: '',
    },
    '789': {
        content: 'Twinsen\'s Odyssey, also known as Little Big Adventure 2, is an action-adventure game developed by Adeline Software International and published by Electronic Arts. Launched in 1997, the game thrusts players into the enchanting world of Twinsun as Twinsen, the planet\'s hero. The gameplay involves puzzle-solving, exploration, combat, and platform elements with a unique aspect being the ability to change Twinsen\'s behavior, affecting how he interacts with his environment. In this game, Twinsen embarks on a quest to investigate a series of alien invasions interrupting his peaceful life. Players help Twinsen traverse varied landscapes, understand extraterrestrial secrets, combat strange creatures, and ultimately, save his planet from an impending cataclysm. Twinsen\'s Odyssey incorporates real-time 3D landscapes, full motion videos for additional narrative, and atmospheric orchestral music. Celebrated for its imaginative universe, captivating storytelling, immersive 3D graphics, energetic soundtrack, and complex gameplay mechanics, Twinsen\'s Odyssey offers an endearing gaming experience. If you\'re a fan of adventurous puzzle-solving games like Syberia and whimsical explorative games like Beyond Good & Evil, you\'ll be a fan of Twinsen\'s Odyssey.',
        submitted_by: '',
        answers: ["Twinsen's Odyssey", "Little Big Adventure 2"],
        affiliate_links: ["https://www.g2a.com/little-big-adventure-2-steam-key-global-i10000006681004?gtag=391e4ef696"],
        franchise: 'Little Big Adventure',
        twitter_ids: [],
        release_year: '1997',
        metacritic_score: 'Original release too old for metacritic score',
        genre: 'Action, Adventure, Diagonal-down',
        console_platform: 'PC, DOS',
        developer: 'Adeline Software International',
        backloggd_link: '',
    },
    '790': {
        content: 'The Legend of Zelda: The Minish Cap is an action-adventure game developed by Nintendo and Capcom. As part of the iconic Legend of Zelda series, this 2004 release thrusts players into a mystical world as the series\' hero, Link. With a trusty, magical, talking cap named Ezlo, players embark on a quest through Hyrule to rescue Princess Zelda from the malevolent sorcerer Vaati. The gameplay in The Minish Cap incorporates a unique size-changing dynamic: Link can shrink down to interact with the Minish, tiny creatures that offer guidance and unveil secrets about the world. The blend of exploration, puzzle-solving, and combat is characteristic of the Zelda series, yet the ability to literally transform the world\'s scale adds a new layer of strategic depth and exploration. The Minish Cap has received critical acclaim for its innovative gameplay mechanics, charming narrative, and its contribution to the rich lore of the Legend of Zelda universe. If you\'re a fan of action-adventure games with deep lore and intricate puzzles like Okami or Secret of Mana, you\'ll be a fan of The Legend of Zelda: The Minish Cap.',
        submitted_by: '',
        answers: ["The Legend of Zelda: The Minish Cap"],
        affiliate_links: [],
        franchise: 'The Legend of Zelda',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '89%',
        genre: 'Adventure, Top Down, Fantasy',
        console_platform: 'GBA',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '791': {
        content: 'Crow Country is an immersive, narrative-driven adventure game launched in 2024. Developed by a renowned studio famous for atmospheric narratives, Crow Country immerses players in a picturesque but mysteriously sinister rural town in midwestern America. The protagonist, a young city dweller, returns to his grandparent\'s decaying farm, where the natural and supernatural collide. Gameplay in Crow Country is primarily about exploration and puzzle-solving. Players engage with the environment by exploring the world and interacting with its inhabitants, both human and otherwise. Unraveling the story involves decoding the riddles of the land, understanding the behavior of the local crows, and uncovering artifacts from the protagonist\'s family history. What sets Crow Country apart is the attention to detail in the game world and the richly developed lore, which weaves together local legends, historical events, and familial secrets. The game\'s narrative uses puzzles not just as a challenge, but as a device to deepen the connection between the player and the game world. If you\'re a fan of narrative-driven games with intricate puzzles like Gone Home or Firewatch, you\'ll be a fan of Crow Country.',
        submitted_by: '',
        answers: ["Crow Country"],
        affiliate_links: ["https://www.g2a.com/crow-country-xbox-series-x-s-xbox-live-account-global-i10000505666008?suid=e6bc0234-aebb-4a40-b56b-2f5ca22e8e14?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1812347813792989289"],
        release_year: '2024',
        metacritic_score: '83%',
        genre: 'Survival horror',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'SFB Games Ltd.',
        backloggd_link: '',
    },
    '792': {
        content: '',
        submitted_by: '',
        answers: ["20 Minutes Till Dawn"],
        affiliate_links: ["https://www.g2a.com/20-minutes-till-dawn-pc-steam-gift-global-i10000326303001?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1812250098975301869", "1812463348056818087"],
        release_year: '2022 (early access), 2023 (full)',
        metacritic_score: 'Not Rated',
        genre: 'Roguelike, shoot \'em up',
        console_platform: 'PC, Android, iOS',
        developer: 'flanne',
        backloggd_link: '',
    },
    '793': {
        content: 'Uncharted 4: A Thief\'s End is an action-adventure game developed by Naughty Dog and published by Sony Computer Entertainment. Released in 2016, players are taken on a globe-trotting journey as treasure hunter Nathan Drake, who comes out of retirement to embark on a perilous quest for the legendary pirate treasure of Henry Avery. The gameplay in Uncharted 4 is characterized by its exploration, stealth elements, and third-person combat, with players using firearms, melee combat, and stealth approaches to fend off hostile foes. Set across various stunning locations, players solve puzzles, traverse environments, and engage in high-speed chases and intense action sequences. The game received widespread acclaim for its cinematic storytelling, character development, visual fidelity, and gameplay mechanics. The multiplayer mode adds another layer to the gameplay, allowing players to engage in cooperative or competitive matches, which further extend the game\'s replayability. If you\'re a fan of story-driven action-adventure games like Tomb Raider or The Last of Us, you\'ll be a fan of Uncharted 4: A Thief\'s End.',
        submitted_by: '',
        answers: ["Uncharted 4: A Thief's End", "Uncharted 4"],
        affiliate_links: ["https://www.g2a.com/uncharted-4-a-thiefs-end-ps4-psn-account-account-global-i10000018170003?suid=2d9f9ef3-7b3a-4d4a-b835-375dff42b755?gtag=391e4ef696"],
        franchise: 'Uncharted',
        twitter_ids: ["1812826260868857966"],
        release_year: '2016',
        metacritic_score: '93%',
        genre: 'Action, Adventure, 3rd-person, Shooter',
        console_platform: 'PS4',
        developer: 'Naughty Dog',
        backloggd_link: '',
    },
    '794': {
        content: '',
        submitted_by: '',
        answers: ["Blood Bowl"],
        affiliate_links: [],
        franchise: 'Blood Bowl',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '72%',
        genre: 'Sports, real-time strategy, turn-based strategy',
        console_platform: 'PC',
        developer: 'Cyanide',
        backloggd_link: '',
    },
    '795': {
        content: 'Starcraft 2, a military science fiction real-time strategy game, was released in 2010 by Blizzard Entertainment. Picking up where the original StarCraft left off, the game puts players in the midst of an interstellar war between three species - the adaptable Terrans, the insectoid Zerg, and the advanced Protoss. The game is divided into three parts, the base game and two expansion packs, each one focusing on one of the factions. Gameplay involves managing resources, building armies, and outmaneuvering opponents to achieve strategic victories. The game\'s multiplayer mode is commendable, offering ladders for competitive play and lots of room for player development. Starcraft 2 also introduced an elaborate single-player campaign including cinema-quality storytelling and full voice acting, unlike its predecessor. On release, the game was lauded for its engaging gameplay, expansive lore, high-quality production values, and the depth of its multiplayer strategic options. If you\'re a fan of real-time strategy games like Command & Conquer or Age of Empires, you\'ll be a fan of Starcraft 2.',
        submitted_by: '',
        answers: ["Starcraft 2", "Starcraft II: Wings of Liberty", "Starcraft II"],
        affiliate_links: [],
        franchise: 'StarCraft',
        twitter_ids: ["1813680166972895310"],
        release_year: '2010',
        metacritic_score: '93%',
        genre: 'Real-time strategy',
        console_platform: 'PC',
        developer: 'Blizzard Entertainment',
        backloggd_link: '',
    },
    '796': {
        content: 'Sid Meier\'s Pirates! is a historical action-adventure game developed by MicroProse and designed by Sid Meier. Launched in 1987, this game plunges players into the golden age of piracy in the Caribbean Sea. Players start as a rookie pirate and gradually rise in rank in 17th-century Caribbean. The gameplay is a unique blend of ship combat, treasure hunting, exploration, and even dancing, requiring both strategy and real-time action skills. Giving players free rein to sail the game\'s open world, undertake perilous treasure hunts, trade goods, raid towns, and seek vengeance on notorious pirates makes Pirates! a highly replayable game due to a variety of possible career paths, encounters, and outcomes. The game\'s charm lies not only in its engaging blend of strategy and action but also in historical accuracy, featuring real-life pirate legends like Blackbeard and Henry Morgan. Sid Meier\'s Pirates! has been lauded for its innovative sandbox-style gameplay, gripping narratives, and vibrant recreation of the pirate-infested Caribbean. If you\'re a fan of exploration-driven games like The Elder Scrolls V: Skyrim or seafaring adventures like Sea of Thieves, you\'ll be a fan of Sid Meier\'s Pirates!',
        submitted_by: '',
        answers: ["Sid Meier's Pirates!"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Sid%20Meiers%20Pirates%20Steam%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fsid-meiers-pirates%2F&intsrc=PUI2_9608", "https://www.g2a.com/sid-meiers-pirates-steam-gift-global-i10000005024001?gtag=391e4ef696"],
        franchise: 'Sid Meier',
        twitter_ids: [],
        release_year: '1987',
        metacritic_score: 'Not Rated',
        genre: 'Action-adventure, strategy',
        console_platform: 'Commodore 64',
        developer: 'MicroProse',
        backloggd_link: '',
    },
    '797': {
        content: 'BattleTech is a turn-based strategy video game developed by Harebrained Schemes and published by Paradox Interactive. Released in 2018, BattleTech immerses players in a universe of interstellar combat, where they command their own mercenary outfit of BattleMechs, the giant war machines that dominate the battlefield. Gameplay in BattleTech is a combination of tactical combat and management sim. During tactical combat, players move their BattleMechs across the battlefield to shoot, melee, or even stomp on enemies. The management sim aspect comes in between missions, where players manage resources, upgrade their mechs, hire pilots, and guide the growth of their mercenary company. The game shines with its deeply strategic gameplay and immersive world-building. The BattleTech universe is enriched with intricate politics, economics, and technological evolution, making the player\'s role as a mercenary commander both challenging and rewarding. BattleTech has been lauded for its deep strategic elements, complex combat, and the rich, expansive universe it presents. If you\'re a fan of turn-based strategy games like XCOM: Enemy Unknown or the management simulation of games like FTL: Faster Than Light, you\'ll be a fan of BattleTech.',
        submitted_by: '',
        answers: ["BattleTech"],
        affiliate_links: ["https://www.g2a.com/battletech-steam-gift-global-i10000149680002?gtag=391e4ef696"],
        franchise: 'BattleTech',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '78%',
        genre: 'Turn-based strategy',
        console_platform: 'PC',
        developer: 'Harebrained Schemes',
        backloggd_link: '',
    },
    '798': {
        content: '',
        submitted_by: '',
        answers: ["Indika"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=INDIKA%20DELUXE%20EDITION%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Findika-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/indika-pc-steam-gift-global-i10000505367003?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '80%',
        genre: 'Adventure',
        console_platform: 'PS5, PC, Xbox Series',
        developer: 'Odd-Meter',
        backloggd_link: '',
    },
    '799': {
        content: 'MapleStory is a free-to-play, 2D side-scrolling massively multiplayer online role-playing game (MMORPG) developed by the South Korean company Wizet. Released in 2003, it immerses players in the Maple World, where they can defeat monsters, complete quests or socialize with other players. As they progress, players level up their characters by dealing with NPCs and battling enemies, and then enhance their abilities by spending skill points. The narrative aspect of the game is revealed through a series of quests featuring various storylines, much of which involves the Black Mage, the game\'s primary antagonist. Players can choose from a variety of classes, each with its own unique skills and abilities, and customize their character\'s appearance. The game stands out for its vibrant anime-style graphics, open-world environment, and a rich community system with guilds. MapleStory has been praised for its engaging gameplay and extensive customization, making it a staple in the MMORPG world. Its longevity can be attributed to regular updates and expansions which constantly introduce fresh content. If you\'re a fan of MMORPGs with extensive player communication like Ragnarok Online or Guild Wars, you\'ll be a fan of MapleStory.',
        submitted_by: '',
        answers: ["MapleStory"],
        affiliate_links: [],
        franchise: 'MapleStory',
        twitter_ids: [],
        release_year: '2003',
        metacritic_score: 'Not Rated',
        genre: 'MMORPG',
        console_platform: 'PC',
        developer: 'Wizet',
        backloggd_link: '',
    },
    '800': {
        content: 'A Way Out is an action-adventure video game developed by Hazelight Studios and published by Electronic Arts. Introduced to the gaming world in 2018, A Way Out uniquely offers a purely cooperative gaming experience, where players take on the roles of Vincent Moretti and Leo Caruso, two prisoners determined to escape prison and navigate subsequent events from different perspectives. Gameplay is split-screen cooperative, and requires effective teamwork and strategic communication to overcome challenges, solve puzzles, and progress through the story. This includes engaging sequences of stealth, close combat, car chases, and more, all framed within an engaging narrative filled with suspense and unexpected twists. The strength of A Way Out lies in its compelling story, remarkable characters, and immersive co-operative gameplay mechanics that foster collaboration and strategic thinking. This title has been heralded for its emphasis on cooperative play, emotional narrative, and innovative design. If you\'re a fan of unique cooperative games like Brothers: A Tale of Two Sons or narrative-driven action-adventures like Uncharted, you\'ll be a fan of A Way Out.',
        submitted_by: '',
        answers: ["A Way Out"],
        affiliate_links: ["https://www.g2a.com/a-way-out-pc-steam-gift-global-i10000068293010?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1815241818470076836"],
        release_year: '2018',
        metacritic_score: '78%',
        genre: 'Action, Adventure',
        console_platform: 'PS4, PC, XONE',
        developer: 'Hazelight Studios',
        backloggd_link: '',
    },
    '801': {
        content: 'TowerFall is an indie action game developed by Matt Makes Games. Launched in 2013, it takes players into a world of archery combat where they engage in intense matches in tightly-designed arenas. Playing as archers, players are tasked with defeating their opponents using limited supplies of arrows and stomping on their heads. The mechanics of TowerFall are compelling, enabling head-on confrontations, sneaky ambushes, or brisk evasions, all requiring strategies based on one\'s playing style. Apart from a frenetic multiplayer mode which supports up to four players, TowerFall also features a single-player mode where players combat endless waves of AI adversaries. The game is renowned for its fast-paced gameplay, retro-styled pixelated art design, and multiplayer mode that instigates frenzied competitive play on couches. Another unique aspect of TowerFall is its physics-inspired gameplay, where arrows fly with a sense of weight and can even be caught mid-air. TowerFall has been universally praised for its balance, simplicity, and the sheer fun of its multiplayer matches. If you\'re a fan of competitive multiplayer games like Super Smash Bros or indie action games like Celeste, you\'ll be a fan of TowerFall.',
        submitted_by: '',
        answers: ["TowerFall", "TowerFall Ascension"],
        affiliate_links: ["https://www.g2a.com/towerfall-ascension-steam-gift-global-i10000004168002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1815713258973667760"],
        release_year: '2013',
        metacritic_score: '87%',
        genre: 'Action',
        console_platform: 'Ouya',
        developer: 'Maddy Makes Games',
        backloggd_link: '',
    },
    '802': {
        content: 'Wartales is a tactical RPG developed and published by Shiro Games and released in 2023. Set in a dark and ruthless medieval universe, players lead a group of mercenaries who have had to resort to banditry for survival after a deadly plague ravaged their world. Wartales gameplay is a blend of tactical combat and open-world exploration. Players venture across hazardous landscapes, engage in turn-based strategic battles, and manage their party\'s resources, all while uncovering the rich lore of the world. The game also features RPG elements, focusing on character development and crafting, where players can enhance their group\'s skills and craft a variety of items and equipment. Wartales also brings innovation with its immersive economic system and an interactive world where player actions impact the environment and political landscape. Wartales has been recognized for its deep tactical play, vast open-world environment, and fact that each decision carries weight, leading to different game experiences. If you\'re a fan of in-depth tactical RPGs like Banner Saga or immersive open-world games like Mount & Blade, you\'ll be a fan of Wartales.',
        submitted_by: '',
        answers: ["Wartales"],
        affiliate_links: ["https://www.g2a.com/wartales-pc-steam-gift-global-i10000279633004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2021 (Early Access), 2023',
        metacritic_score: '79%',
        genre: 'Tactical role-playing',
        console_platform: 'PC',
        developer: 'Shiro Games',
        backloggd_link: '',
    },
    '803': {
        content: 'Super Monkey Ball is a platformer game developed by Amusement Vision and published by Sega. Released in 2001, Super Monkey Ball invites players to control a monkey trapped in a transparent ball through a maze-like environment. The originality of Super Monkey Ball comes from the unique control scheme: instead of directly controlling the monkey, players tilt the environment to navigate the monkey ball towards the goal, while avoiding various obstacles and perilous drops. The game tests the player\'s timing and precision, with each stage varying in difficulty and complexity. Furthermore, Super Monkey Ball features several mini-games, including Monkey Bowling and Monkey Racing, which can be played with friends for some exciting multiplayer action. Its charming design, wacky idea and challenging gameplay have made it an unforgettable piece of gaming history. Super Monkey Ball is often praised for its originality and addictively frustrating gameplay that offers endless rounds of fun. If you\'re a fan of tricky platformer games such as the Donkey Kong Country series or Marble Madness, you\'ll definitely enjoy Super Monkey Ball.',
        submitted_by: '',
        answers: ["Super Monkey Ball"],
        affiliate_links: [],
        franchise: 'Super Monkey Ball',
        twitter_ids: ["1816372850757943628", "1816569887134724212", "1816333742106984753", "1816358052771291476"],
        release_year: '2001',
        metacritic_score: '87%',
        genre: 'Platform, party',
        console_platform: 'GameCube',
        developer: 'Amusement Vision',
        backloggd_link: '',
    },
    '804': {
        content: 'Space Invaders is a classic arcade game developed by Taito and published by Midway in North America. Released in 1978, Space Invaders ushered in the golden era of arcade video games, pitting players against formations of alien invaders. The objective of the game is simple but addicting - control a laser cannon that moves horizontally across the bottom of the screen and fire at the descending rows of aliens. All the while, players must dodge the enemy\'s return fire and prevent the invaders from reaching the screen\'s bottom. Gameplay is tactically rich with strategically placed, destructible bunkers providing temporary shelter from the persistent alien assault. The relentless march of the aliens, quickening in pace as their numbers thin, combined with the ominous in-game soundtrack creates an atmosphere of increasing tension. Space Invaders offered a unique combination of shooter and strategy elements, a formula that has since become a staple in the gaming industry. Its influence can be seen in the plethora of modern space-themed shooter games available today. If you\'re a fan of classic arcade games like Galaga or modern shoot \'em ups like Geometry Wars, you\'ll be a fan of Space Invaders.',
        submitted_by: '',
        answers: ["Space Invaders"],
        affiliate_links: ["https://www.g2a.com/space-invaders-extreme-steam-gift-europe-i10000142316003?gtag=391e4ef696"],
        franchise: 'Space Invaders',
        twitter_ids: [],
        release_year: '1978',
        metacritic_score: 'Too Old',
        genre: 'Arcade',
        console_platform: 'Arcade',
        developer: 'Taito',
        backloggd_link: '',
    },
    '805': {
        content: 'Command & Conquer: Red Alert is a real-time strategy game developed by Westwood Studios and published by Virgin Interactive. Embarking on a journey to an alternate past where covert Allied forces battle an aggressive Soviet Union for control over the European mainland, players are introduced to a different WW2. Red Alert is known for its methodical gameplay which combines resource management, base construction, and large-scale tactical warfare. Players harvest resources, construct buildings, research new technologies, and assemble armies of diverse units to defend their base and launch strategic assaults on their opponents\' encampments. Gameplay unfolds in a series of campaign missions interspersed with cinematic cut-scenes, giving a cinematic feel to the engaging plot. Red Alert boasts superb replayability, thanks to its multiplayer mode in which players can test their strategies against others. Red Alert has earned praise for its blend of tactical depth, fast-paced action, compelling storylines, and an unforgettable, atmospheric soundtrack. If you\'re a fan of strategy games like Age of Empires or StarCraft, you\'ll be a fan of Command & Conquer: Red Alert.',
        submitted_by: '',
        answers: ["Command & Conquer: Red Alert"],
        affiliate_links: [],
        franchise: 'Command & Conquer',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: '90%',
        genre: 'Real-time Strategy, Sci-fi',
        console_platform: 'DOS, PC',
        developer: 'Westwood Studios, Inc.',
        backloggd_link: '',
    },
    '806': {
        content: '',
        submitted_by: '',
        answers: ["Buckshot Roulette"],
        affiliate_links: ["https://www.g2a.com/buckshot-roulette-pc-steam-gift-global-i10000504233004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: '85%',
        genre: '1st-person, Fantasy, Horror, Turn Based',
        console_platform: 'PC',
        developer: 'Mike Klubnika',
        backloggd_link: '',
    },
    '807': {
        content: 'Kingdom Come: Deliverance is a role-playing game developed by Warhorse Studios and published by Deep Silver. Set in the medieval Kingdom of Bohemia, an Imperial State of the Holy Roman Empire, the game takes players back to the year 1403, where they slip into the role of Henry, a blacksmith\'s son whose life is ravaged when his village is burned to the ground by an army of invaders. In Kingdom Come: Deliverance, the gameplay is largely non-linear, with a focus on realistic historical details, such as combat techniques, clothing, and architecture. The game stands out due to its system of branching quests, which can be solved in multiple ways and bear consequences that affect the story. Even mundane tasks like eating and sleeping gain importance in this hyper-realistic world, forging an immersive experience for players. The combat of Kingdom Come: Deliverance is notable for its complexity, drawing upon the real-world physics of swordfighting and armor defense. Packed with breathtaking scenic landscapes, intricate storyline and intense gameplay - Kingdom Come: Deliverance provides a unique taste of life and struggle in the Middle Ages. If you\'re a fan of high-stakes RPGs with deep storytelling, such as The Witcher series or The Elder Scrolls V: Skyrim, you\'ll be a fan of Kingdom Come: Deliverance.',
        submitted_by: '',
        answers: ["Kingdom Come: Deliverance"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Kingdom%20Come%20Deliverance%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fkingdom-come-deliverance%2F&intsrc=PUI2_9608", "https://www.g2a.com/kingdom-come-deliverance-collection-steam-key-global-i10000068834016?gtag=391e4ef696"],
        franchise: 'Kingdom Come',
        twitter_ids: ["1818176302194758052", "1817970385155154347", "1817690359155294615"],
        release_year: '2018',
        metacritic_score: '76%',
        genre: 'Action, RPG, Open World',
        console_platform: 'PS4, XONE, PC',
        developer: 'Warhorse Studios s.r.o.',
        backloggd_link: '',
    },
    '808': {
        content: 'Need for Speed: Underground is a thrilling racing video game developed by EA Black Box and published by Electronic Arts. Releasing in 2003, it turbocharged the popular Need for Speed series by focusing on illegal street racing and automotive tuning, capturing the essence of modern car culture. As a player, you drive through a fictional city, based on a combination of New York City, Philadelphia, and Los Angeles, participating in various types of races, acquiring performance upgrades for your vehicles along the way. The game features a diverse roster of import tuner cars and allows players to customize their vehicles extensively, from their performance attributes to their visual appearances. There is an immersive storyline, told through pre-rendered videos, where players race to be crowned the best underground racer. The gameplay involves tight turns, high speeds, and daring maneuvers, with the police noticeably absent, which became a distinctive feature of the game giving players the freedom to race uninterrupted. Need for Speed: Underground has gained fame for its cinematic presentation, extensive customization options, and high-speed action. If you\'re a fan of adrenaline-pumping racing games like Burnout Paradise or Forza Horizon, you\'ll be a fan of Need for Speed: Underground.',
        submitted_by: 'https://www.youtube.com/@-Dark-Serg-',
        answers: ["Need for Speed: Underground"],
        affiliate_links: [],
        franchise: 'Need for Speed',
        twitter_ids: ["1818250771059593428"],
        release_year: '2003',
        metacritic_score: '82%',
        genre: 'Racing, Driving, Automobile, Street racing',
        console_platform: 'PC, PS2, Xbox, GameCube',
        developer: 'Electronic Arts Black Box',
        backloggd_link: '',
    },
    '809': {
        content: 'Party Animals is a whimsically chaotic physics-based party game developed and published by Recreate Games. Released in 2023, the game thrusts players into wildly amusing scenarios where they control cute animals engaged in hilarious brawls. The goal is straightforward; come out on top in the various challenges using any means necessary. The gameplay of Party Animals is characterised by its acrobatic controls and comical physics, which results in unpredictable and chaotic matches. The game stands out with its characterisation of different animals, from puppies and bunnies to kittens and ducks, each with their own zany moves. Players can compete in multiple game modes, including a last pet standing mode and team-based objectives, providing a plethora of ridiculous and mirthful moments. Party Animals has been highly praised for its captivating hilarity, adorable character designs, and the chaotic yet strategic gameplay that keeps players constantly engaged. If you\'re a fan of chaotic party games like Fall Guys or humorous brawlers like Gang Beasts, you\'ll be a fan of Party Animals.',
        submitted_by: '',
        answers: ["Party Animals"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1272000/15105?prodsku=Party%20Animals%20-%20Xbox%20One&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fparty-animals-xbox%2F&intsrc=PUI2_9616", "https://www.g2a.com/party-animals-deluxe-edition-pc-steam-key-global-i10000219016010?suid=075f57c5-3a74-4cb7-bf50-a9d1181accec?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1818617085922013441"],
        release_year: '2023',
        metacritic_score: '76%',
        genre: '3rd-person, Party game',
        console_platform: 'Xbox Series, XONE, PC',
        developer: 'Recreate Games',
        backloggd_link: '',
    },
    '810': {
        content: 'River City Ransom, a beat \'em up video game developed by Technōs Japan and published by American Technos, was released in 1989 in the United States. It tosses players into the shoes of high school students Alex and Ryan as they traverse in a fictional city called River City with a mission to rescue Ryan\'s girlfriend Cyndi from the clutches of a villain named Slick. This open world game encourages exploration and even non-linear progression, a rare feature for its time. Players punch, kick, and use various weapons to defeat a myriad of foes, improving their characters by purchasing power-ups, new abilities, and stat-boosting items with in-game currency dropped from defeated enemies. Combat includes a variety of moves and techniques, with AI opponents that can at times mimic player techniques making the game more challenging. The game is often remembered for its unique blend of action and RPG elements, its engaging multiplayer mode, and its memorable dialogue, creating a quirky, comic book-like atmosphere. River City Ransom has been praised for its innovative gameplay mechanics and humorous tone. If you\'re a fan of beat \'em up games like Double Dragon or Scott Pilgrim vs. The World, you\'ll be a fan of River City Ransom.',
        submitted_by: '',
        answers: ["River City Ransom", "Street Gangs"],
        affiliate_links: [],
        franchise: 'River City Ransom',
        twitter_ids: ["1818974128306614372"],
        release_year: '1989',
        metacritic_score: 'Original release too old for metacritic',
        genre: 'Side view, Beat \'em up',
        console_platform: 'NES',
        developer: 'Technos Japan Corp.',
        backloggd_link: '',
    },
    '811': {
        content: 'Assassin\'s Creed IV: Black Flag is an action-adventure game developed by Ubisoft Montreal and published by Ubisoft. It invites players into the captivating world of piracy in the Caribbean Sea during the Golden Age of Piracy. Players assume the role of Edward Kenway, a fearsome pirate and a member of the legendary Assassin Brotherhood, embarking on an epic adventure that traverses numerous islands. As players chart Edward\'s journey, they can commandeer ships, engage in naval combat, hunt for treasure, explore uncharted territories, and use his assassin skills to alter the course of history. The game is known for its massive open-world environment, allowing for seamless transitions between exploration on land and at sea. It also introduced new weapons and ship customization, bolstering the depth of its already expansive gameplay. The marriage of a captivating storyline, immersive gameplay, and vibrant world-building is what sets Black Flag apart. If you\'re a fan of action-adventure games with historical elements like Red Dead Redemption or open-world seafaring games like Sea of Thieves, you\'ll be a fan of Assassin\'s Creed IV: Black Flag.',
        submitted_by: '',
        answers: ["Assassin's Creed IV: Black Flag"],
        affiliate_links: ["https://www.g2a.com/assassins-creed-iv-black-flag-steam-gift-global-i10000006431004?suid=8f35fb8c-5798-4db9-a1cf-279213c21a7e?gtag=391e4ef696"],
        franchise: 'Assassins Creed',
        twitter_ids: ["1819159750724276467"],
        release_year: '2013',
        metacritic_score: '84%',
        genre: 'Action, Adventure, Stealth, 3rd person',
        console_platform: 'Wii U, XONE, X360, PC, PS3, PS4',
        developer: 'Ubisoft Entertainment Inc.',
        backloggd_link: '',
    },
    '812': {
        content: 'Terminator: Resistance is a first-person shooter video game developed by Teyon and published by Reef Entertainment, set in the post-apocalyptic world of the Terminator franchise. Released in 2019, players take on the role of Jacob Rivers, a soldier in the human resistance against Skynet. The game takes place during the Future War scenario, previously seen briefly in the franchise\'s films. The gameplay of Terminator: Resistance is centered around combat against numerous robotic enemies designed by Skynet, using a variety of weapons such as plasma rifles, shotguns, and explosives. It also incorporates stealth mechanics, and as the story unfolds, players can interact with other human survivors, where their performance and choices could influence the game\'s outcome. Its RPG elements include leveling up, crafting materials, and branching dialogue options. Unique to the game, an hacking mechanic can be utilized to turn the Terminators against each other, providing an immersive combat strategy and intense action. Terminator: Resistance has been recognized for its tribute to the iconic franchise, with a faithful depiction of its apocalyptic setting. If you\'re a fan of first-person shooter games akin to Resistance: Fall of Man or RPGs with branching narratives like Fallout: New Vegas, you\'ll be a fan of Terminator: Resistance.',
        submitted_by: '',
        answers: ["Terminator: Resistance"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Terminator%20Resistance%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fterminator-resistance-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/terminator-resistance-steam-gift-global-i10000192316002?gtag=391e4ef696"],
        franchise: 'Terminator',
        twitter_ids: [],
        release_year: '2019',
        metacritic_score: '60%',
        genre: 'FPS, Post-apocalyptic',
        console_platform: 'XONE, PC, PS4',
        developer: 'Teyon S.A.',
        backloggd_link: '',
    },
    '813': {
        content: '',
        submitted_by: '',
        answers: ["Exit 8", "The Exit 8"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2023',
        metacritic_score: 'No metacritic score',
        genre: '1st-person, Horror?',
        console_platform: 'PC',
        developer: 'Kotake Create',
        backloggd_link: '',
    },
    '814': {
        content: 'Another Crab\'s Treasure is an underwater exploration game developed by Deep Blue Games and released in 2024. Set in the mystically enchanting ocean depth, players assume the role of an adventurous hermit crab, seeking lost treasures and navigating through the diverse and complex underwater landscapes. The gameplay is distinguished by the harmonious blend of exploration and puzzle-solving, where players must interact with various sea creatures and manipulate surroundings to progress further. As an added layer of complexity, players must periodically seek new shells to inhabit, introducing additional strategic aspects to the gameplay. Unique to this game is its bioluminescent graphics style that beautifully illustrates the deep sea environment and its inhabitants. The game\'s narrative interweaves a story of adventure and survival, creating an emotionally engaging gaming experience. Reviews have praised Another Crab\'s Treasure for its innovative gameplay, visually striking design, and immersive story. If you\'re a fan of exploration and puzzle-solving games such as Subnautica or the engaging story-driven games like Journey, you\'ll be a fan of Another Crab\'s Treasure.',
        submitted_by: '',
        answers: ["Another Crab's Treasure"],
        affiliate_links: ["https://www.g2a.com/another-crabs-treasure-pc-steam-gift-global-i10000505335004?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1820562753263935547", "1820424858620072279", "1820323922627215377", "1820568416711020558"],
        release_year: '2024',
        metacritic_score: '78%',
        genre: '3rd Person, Souls-like',
        console_platform: 'PC, Switch, Xbox Series, PS5, XONE',
        developer: 'Aggro Crab Games',
        backloggd_link: '',
    },
    '815': {
        content: 'Crash Bandicoot: Warped is an exciting platform game developed by Naughty Dog and published by Sony Computer Entertainment. Released in 1998, it thrusts players into a vibrant, cartoony world teeming with dangers as they guide the titular hero, Crash Bandicoot, on a quest to stop the villainous Uka Uka and Doctor Neo Cortex from enacting their time-twisting plan. The gameplay in Warped revolves around navigating through different levels, each representing a distinct period in time, from the prehistoric age to the future. Unique to this installation in the series are the various types of levels beyond basic platforming, including underwater, riding, and flying levels. Crash is accompanied by his little sister, Coco, who, for the first time in the series, becomes a playable character in certain levels. The game emphasizes accurate jumps, precise timing, and quick reflexes, while also rewarding exploration with hidden areas and collectibles. Crash Bandicoot: Warped has been lauded for its creative level design, fast-paced platforming action, and charming characters. If you\'re a fan of other whimsical platform games like Spyro the Dragon or Ratchet & Clank, you\'ll be a fan of Crash Bandicoot: Warped.',
        submitted_by: '',
        answers: ["Crash Bandicoot: Warped", "Crash Bandicoot 3: Warped"],
        affiliate_links: [],
        franchise: 'Crash Bandicoot',
        twitter_ids: ["1820731939906531569", "1820787287833051586"],
        release_year: '1998',
        metacritic_score: '91%',
        genre: '3rd-person, Platformer',
        console_platform: 'PS1',
        developer: 'Naughty Dog, Inc.',
        backloggd_link: '',
    },
    '816': {
        content: 'Bomberman Generation is an action-adventure game developed by Hudson Soft and published by Konami for GameCube, released in 2002. The game thrusts players into a colourful, 3D universe where they take up the role of the iconic Bomberman, charged with the mission to retrieve six mystical Bomb Elements before the dastardly Hige Hige Bandits do. Gameplay in Bomberman Generation blends classic bombing action with puzzle-solving and exploration. Players navigate through elaborate levels, dropping bombs to defeat enemies and break barriers, while also collecting power-ups to enhance their abilities. However, Bomberman Generation also introduces innovative Charabom battles and the unique ability to merge these Charabom creatures to create powerful, new forms. Each level in the game ends with colossal boss battles that test both strategy and reflexes. Boasting rich graphics for its time and skillful blending of several gameplay elements, Bomberman Generation has gathered praise for its refreshing take on the classic Bomberman formula. If you\'re a fan of action-adventure games such as Super Mario Sunshine or strategic puzzle games like The Legend of Zelda, you\'ll be a fan of Bomberman Generation.',
        submitted_by: '',
        answers: ["Bomberman Generation"],
        affiliate_links: [],
        franchise: 'Bomberman',
        twitter_ids: ["1821148909596524659", "1820957642929664318", "1821175279768817983"],
        release_year: '2002',
        metacritic_score: '81%',
        genre: 'Action, Diagonal-down, Puzzle elements',
        console_platform: 'GameCube',
        developer: 'Game Arts Co., Ltd.',
        backloggd_link: '',
    },
    '817': {
        content: 'Rise of the Triad: Dark War is a first-person shooter developed and published by Apogee Software. Released in 1994, the game thrusts players into a high-stakes mission as members of the HUNT (High-Risk United Nations Task-force), aiming to stop a dangerous cult from unleashing a deadly weapon. The game unfolds on the cult base situated on an island, inviting players to employ both their stealth abilities and firepower to disrupt the menacing organization. A unique aspect of Rise of the Triad is the diverse range of unique weapons and power-ups available to players, from magic baseball bats to mushroom power-ups that cause powerful hallucinogenic effects. With five different characters to choose from, each with their unique strengths, players can experience different play styles and strategies. The game is known for its over-the-top action, a wide array of enemies and bosses, and clever level design. Enthusiastic reviews credited Rise of the Triad for its fast-paced gameplay, eccentric array of weapons, and its unique take on the first-person shooter genre. If you\'re a fan of action-packed first-person shooters like Doom or Wolfenstein 3D, you\'ll be a fan of Rise of the Triad: Dark War.',
        submitted_by: 'https://www.youtube.com/@-Dark-Serg-',
        answers: ["Rise of the Triad: Dark War", "Wolfenstein: Rise of the Triad", "Rise of the Triad"],
        affiliate_links: ["https://www.g2a.com/rise-of-the-triad-dark-war-steam-gift-global-i10000011312001?gtag=391e4ef696"],
        franchise: 'Wolfenstein',
        twitter_ids: ["1821664869600629059"],
        release_year: '1994',
        metacritic_score: 'Too old for metacritic score',
        genre: 'Action, FPS',
        console_platform: 'DOS',
        developer: 'Apogee Software, Ltd.',
        backloggd_link: '',
    },
    '818': {
        content: '',
        submitted_by: '',
        answers: ["Stellar Blade", "Project EVE"],
        affiliate_links: ["https://www.g2a.com/stellar-blade-ps5-psn-account-global-i10000504703008?suid=19bb2b13-9dfd-4af6-a382-c2e39f929cd5?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1821695841603842438"],
        release_year: '2024',
        metacritic_score: '81%',
        genre: 'Action, 3rd person, Hack and slash, RPG elements',
        console_platform: 'PS5',
        developer: 'Shiftup Corp.',
        backloggd_link: '',
    },
    '819': {
        content: '1000xRESIST is a dystopian real-time strategy game developed and published by The Resistance Studio. Released in 2024, players are thrust into a grim future battling against oppressive rogue AI forces that have seized control of the world. Assuming the role of the leader of the human resistance, players must strategize, collect resources, build and develop their bases, and control units to push back the AI and reclaim humanity\'s homeland. Gameplay in 1000xRESIST leans heavily on strategic decision-making and adaptive tactics, given the AI\'s knack for evolving its strategies based on players\' actions. An additional unique factor is the dynamic weather system affecting conditions of battles and resource availability, adding an extra layer of unpredictability and strategic depth. 1000xRESIST has been commended for its exciting strategic gameplay, immersive storyline, and the genuinely challenging AI that keeps even the most seasoned real-time strategy players on their toes. If you\'re a fan of complex strategy games like Starcraft or Command & Conquer, you\'ll undoubtedly find a thrilling challenge in 1000xRESIST.',
        submitted_by: '',
        answers: ["1000xRESIST"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '85%',
        genre: 'Adventure',
        console_platform: 'Switch, PC',
        developer: 'Sunset Visitor',
        backloggd_link: '',
    },
    '820': {
        content: 'Sins of a Solar Empire is a sci-fi real-time strategy game developed by Ironclad Games and published by Stardock Entertainment. Released in 2008, the game plunges players into a grand space opera, where they must manage and expand their galactic empires, weave diplomatic ties, or wage star-spanning wars. As the leader of one of three unique races, players oversee their civilization\'s economy, military, technology, and diplomacy. Balancing these aspects is key to survival and victory in a galaxy filled with rival empires and roaming pirates. The gameplay in Sins of a Solar Empire is noted for its mix of 4X strategy elements with faster-paced real-time strategy mechanics. It presents a seamless transition between the micro-scale of spaceship battles and the macro-scale of managing entire solar systems. Sins of a Solar Empire has been celebrated for its impressive scale, sophisticated diplomacy, epic battles, and the degree of strategic depth it offers. If you\'re a fan of grand strategy games like Stellaris or complex RTS games like StarCraft, you\'ll be a fan of Sins of a Solar Empire.',
        submitted_by: '',
        answers: ["Sins of a Solar Empire"],
        affiliate_links: [],
        franchise: 'Sins of a Solar Empire',
        twitter_ids: ["1822603852136185948"],
        release_year: '2008',
        metacritic_score: '87%',
        genre: 'Real-time Strategy',
        console_platform: 'PC',
        developer: 'Ironclad Games',
        backloggd_link: '',
    },
    '821': {
        content: 'The Legend of Zelda: Majora\'s Mask is an action-adventure game developed and published by Nintendo and the sixth installment in the legendary Zelda series. This game submerges players in the mystical world of Termina, a parallel universe to Hyrule, where Link, the protagonist, has only three days to prevent a catastrophic event — the moon crashing into the planet. The game introduces a unique time-travel mechanic, where players must repeatedly relive the same three days and find ways to alter the course of events. This mechanic forces players to manage their time wisely, adding an extra layer of strategy to quests and puzzles. The game also revolutionizes gameplay with its mask system — these 24 collectible masks grant Link new abilities or transform him into new forms, further diversifying gameplay. The Legend of Zelda: Majora\'s Mask is known for its darker tone, unconventional story, and complex gameplay mechanics that set it apart from earlier Zelda games. If you\'re a fan of immersive adventure games with puzzle-solving elements like Shadow of the Colossus or challenging time-management games like Chrono Trigger, you\'ll be a fan of The Legend of Zelda: Majora\'s Mask.',
        submitted_by: '',
        answers: ["The Legend of Zelda: Majora's Mask"],
        affiliate_links: [],
        franchise: 'The Legend of Zelda',
        twitter_ids: ["1822994085465977291", "1822759160318206094", "1823228143580139560"],
        release_year: '2000',
        metacritic_score: '95%',
        genre: 'Action, Adventure',
        console_platform: 'N64',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '822': {
        content: 'Castlevania: Aria of Sorrow is an action-adventure game developed and published by Konami for the Game Boy Advance. Released in 2003, the game transports players to a Gothic castle filled with supernatural enemies, casting them in the role of Soma Cruz - a youth with the ability to absorb the souls of monsters. Set in 2035, Aria of Sorrow deviates from the traditional medieval setting of previous Castlevania games, instead opting for a futuristic, yet still darkly atmospheric, world. The gameplay mechanics remain faithful to the franchise\'s explorative, combat-centered style, but with a unique twist - the Tactical Soul system. This new feature allows Soma to use abilities obtained from enemies, adding further depth and strategy to the combat. Along with complex boss battles and secrets hidden throughout the expansive castle, Aria of Sorrow offers a balance of challenge and discovery, resulting in an impressive addition to the storied Castlevania series. If you\'re a fan of action-adventure games like Metroid: Fusion or The Legend of Zelda: A Link to the Past, you\'ll be a fan of Castlevania: Aria of Sorrow.',
        submitted_by: '',
        answers: ["Castlevania: Aria of Sorrow"],
        affiliate_links: [],
        franchise: 'Castlevania',
        twitter_ids: ["1823153527851720723", "1823194315742687456", "1823323007848239320", "1823228143580139560"],
        release_year: '2003',
        metacritic_score: '91%',
        genre: 'Action, RPG, Metroidvania',
        console_platform: 'GBA',
        developer: 'Konami Computer Entertainment Tokyo',
        backloggd_link: '',
    },
    '823': {
        content: 'Donkey Kong Country: Tropical Freeze is a side-scrolling platform game developed by Retro Studios and published by Nintendo for the Wii U console. The game introduces players to the vibrant and chilly world of the Snowmads who have invaded Donkey Kong Island, forcing Donkey Kong and his pals to reclaim their home. Tactical planning and precise timing are prerequisites for navigating through the game\'s detailed environments, overflowing with secrets and collectibles. Each of the playable characters has their unique abilities; Donkey Kong is strong, Diddy Kong can hover using a jetpack, Dixie Kong can spin her ponytail to reach higher locations, and Cranky Kong can bounce on his cane like a pogo stick. The gameplay is distinguished by its inventive level design, complete with dynamic camera perspectives and cinematic blizzards that truly transport you into a frozen landscape. Noted for its challenging stages, engaging cooperative multiplayer, and exceptional graphics, Donkey Kong Country: Tropical Freeze delivers a frosty yet charming platforming experience. If you\'re a fan of platform games rich in exploration and visually stunning environments like Rayman Legends or Super Mario World, you\'ll be a fan of Donkey Kong Country: Tropical Freeze.',
        submitted_by: '',
        answers: ["Donkey Kong Country: Tropical Freeze"],
        affiliate_links: ["https://www.g2a.com/donkey-kong-country-tropical-freeze-nintendo-switch-nintendo-eshop-account-global-i10000188679003?gtag=391e4ef696"],
        franchise: 'Donkey Kong',
        twitter_ids: ["1823804706659164460"],
        release_year: '2014',
        metacritic_score: '83%',
        genre: 'Platform',
        console_platform: 'Wii U',
        developer: 'Retro Studios',
        backloggd_link: '',
    },
    '824': {
        content: 'Tomb Raider: The Last Revelation is an action-adventure game developed by Core Design and published by Eidos Interactive. Released in 1999, it marks the fourth installment of the iconic Tomb Raider series with players once again donning the role of intrepid archaeologist Lara Croft. The game involves players navigating through a variety of environments, from desert tombs to the bustling streets of Cairo, while contending with a cavalcade of enemies, numerous puzzles, and perilous traps. Notably, the game’s narrative focuses exclusively on Lara’s journey in Egypt, tracing a gripping tale of ancient gods and artefacts that come to life as she inadvertently frees the evil god Seth. The Last Revelation included several enhancements over previous entries in the series, such as the inclusion of a tutorial mode and the ability for Lara to interact with her environment in new ways. It was particularly applauded for its impressive visuals, challenging puzzle design, and the compelling narrative that it brought to life. If you\'re a fan of action-adventure games with a heavy focus on exploration and puzzle-solving like Uncharted or Prince of Persia, you\'ll definitely be a fan of Tomb Raider: The Last Revelation.',
        submitted_by: '',
        answers: ["Tomb Raider: The Last Revelation"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1281763/15105?prodsku=Tomb%20Raider%20IV%20The%20Last%20Revelation%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Ftomb-raider-iv-the-last-revelation%2F&intsrc=PUI2_9726", "https://www.g2a.com/tomb-raider-iv-the-last-revelation-steam-gift-global-i10000048418001?gtag=391e4ef696"],
        franchise: 'Tomb Raider',
        twitter_ids: ["1824213527915356469"],
        release_year: '1999',
        metacritic_score: 'Not Rated',
        genre: 'Action, Adventure, Platform',
        console_platform: 'PC, PS1, Dreamcast',
        developer: 'Core Design',
        backloggd_link: '',
    },
    '825': {
        content: 'Animal Crossing is a community simulation game developed and published by Nintendo, first released in Japan in 2001. In the charming and relaxed world of Animal Crossing, players immerse themselves as a human character who relocates to a rural village populated with anthropomorphic animals. The gameplay is characterized by its open-endedness, where time passes in real-time and seasonal changes occur, bringing new activities and experiences to the game. Players engage in an array of activities such as fishing, bug catching, fossil hunting, gardening and interacting with villagers, - activities which foster a sense of community and camaraderie. Additionally, unique elements such as the in-game clock, mirroring real-world time, establishes a natural course of daily and seasonal events.  Animal Crossing has been beloved for its innovative gameplay, charming characters, and the tranquil yet engaging world it offers. If you\'re a fan of life simulation games like Harvest Moon or The Sims, as well as social interaction games like Neopets, you\'ll fall in love with Animal Crossing.',
        submitted_by: '',
        answers: ["Animal Crossing"],
        affiliate_links: [],
        franchise: 'Animal Crossing',
        twitter_ids: ["1824341044265357481"],
        release_year: '2001',
        metacritic_score: '87%',
        genre: 'Life simulation',
        console_platform: 'Gamecube',
        developer: 'Nintendo EAD',
        backloggd_link: '',
    },
    '826': {
        content: 'Moonlighter is an action RPG with rogue-lite elements, developed by Digital Sun and published by 11 bit studios. Launched in 2018, Moonlighter transports the player into strange dimensions where they navigate the life of Will, a diligent shopkeeper by day and daring adventurer by night. The unique aspect of this game lies in its blend of shopkeeping and dungeon crawling. During the day, players manage Will\'s shop, setting prices, serving customers, and upgrading the store. However, when the night falls, they delve into procedurally generated dungeons, brimming with fierce creatures and alluring treasures. This loot secured from the dungeons can then be sold in the shop. One does not simply hack and slash their way through Moonlighter, the game combines combating elements with sound economic strategy. Players must balance their time between running a profitable business and fighting dangerous creatures. Moonlighter has been praised for its balance of combat, exploration, and economy management which creates a uniquely addictive gameplay experience. If you\'re a fan of dungeon exploring games like The Binding of Isaac or business simulation games like Stardew Valley, you\'ll be a fan of Moonlighter.',
        submitted_by: '',
        answers: ["Moonlighter"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Moonlighter%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fmoonlighter-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/moonlighter-complete-edition-pc-steam-key-global-i10000155977013?suid=513523ec-c2cc-46b5-b216-8e38a8cc99b1?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1824894329279655945"],
        release_year: '2018',
        metacritic_score: '81%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS4, XONE, Switch, Mobile',
        developer: 'Digital Sun',
        backloggd_link: '',
    },
    '827': {
        content: 'Outriders is a high-octane, action RPG developed by People Can Fly and published by Square Enix. Initially released in 2021, Outriders transports players to a hostile alien world named Enoch, drawing surviving humans from a devastated earth into a desperate fight for survival and power. Players take on the role of an Outrider, supernatural warriors with destructive powers who stand as humanity\'s last line of defense. The gameplay in Outriders is a gritty mix of gunplay and supernatural abilities. Players get to choose from four distinct classes, each with its own specific skill-set and play style. Players can face Enoch\'s horrors alone or form a team, battling through the game\'s story-centric campaign in cooperative multiplayer. Alongside the main story arc, there are plentiful side quests and character development opportunities. Outriders is a gun-slinging RPG with an emphasis on cooperative play, strategic class-based combat, and an intertwining narrative. If you\'re a fan of action RPGs like Destiny or The Division, you\'ll be a fan of Outriders.',
        submitted_by: '',
        answers: ["Outriders"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Outriders%20Complete%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Foutriders-complete-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/outriders-complete-edition-pc-steam-gift-global-i10000219024024?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: ["1825141219439092047", "1824929747551584671"],
        release_year: '2021',
        metacritic_score: '73%',
        genre: '3rd-person shooter, Action, RPG',
        console_platform: 'PS4, PS5, Stadia, PC, XONE, Xbox Series',
        developer: 'People Can Fly',
        backloggd_link: '',
    },
    '828': {
        content: 'Severed is an action-adventure game developed and published by DrinkBox Studios. Released in 2016, it tells the gripping tale of Sasha, a one-armed warrior journeying through a nightmarish world to find and rescue her family. She wields a living sword to defeat enemies, and the unique feature of Severed is its touch-based combat system, where players must swipe at the screen to control Sasha\'s sword, chopping off enemy limbs to use in character upgrades and puzzle-solving. The game\'s RPG elements add depth to the gameplay, with different abilities and upgrades that build on top of each other. In addition, players must strategically select and dissect which enemies to face in order to survive in this macabre adventure. Severed\'s graphical style, with its vibrant colors and stylized depictions of the grotesque, sets it apart from its peers. Its combat system, engrossing narrative, and singular art style have been applauded by critics and audiences alike. If you\'re a fan of action-adventure games with unique touch controls such as Fruit Ninja or Infinity Blade, or enjoy the macabre and narrative-driven game experiences offered in games like Hollow Knight, you\'ll be a fan of Severed.',
        submitted_by: '',
        answers: ["Severed"],
        affiliate_links: [],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2016',
        metacritic_score: '82%',
        genre: 'Action, Adventure',
        console_platform: 'PS Vita, Mobile, 3DS',
        developer: 'DrinkBox Studios',
        backloggd_link: '',
    },
    '829': {
        content: 'Overlord is an action-adventure game released in 2007, developed by Triumph Studios and published by Codemasters. The game unfolds in a high-fantasy setting, where players control a resurrected warrior known as the Overlord who must re-establish his dominion over a world populated by bumbling, comical minions and deceitful heroes. Gameplay in Overlord is a blend of action, adventure, and strategy. Players are tasked with leading the Overlord\'s hordes of gremlin-like minions to pillage lands, slay supposed heroes, and gather resources. Interestingly, the Overlord is largely invulnerable, with players instead focusing on directing their minions, who have different strengths, weaknesses, and abilities. The game\'s moral choices also affect gameplay, with decisions to be tyrannical or just altering the world, available upgrades, and minion abilities. Overlord is celebrated for its unique twist on fantasy tropes, darkly humorous plot, and strategic, minion-based gameplay mechanics. If you\'re a fan of games featuring strategic creature control, such as Pikmin, or darkly humorous adventure games like Dungeon Keeper, you\'ll be a fan of Overlord.',
        submitted_by: '',
        answers: ["Overlord", "Overlord: Raising Hell"],
        affiliate_links: ["https://www.g2a.com/overlord-pc-steam-key-global-i10000006646003?suid=36c85b3d-7884-45d4-ac58-d49204c54651?gtag=391e4ef696"],
        franchise: 'Overlord',
        twitter_ids: [],
        release_year: '2007',
        metacritic_score: '76%',
        genre: 'Action, RPG',
        console_platform: 'PC, X360',
        developer: 'Triumph Studios',
        backloggd_link: '',
    },
    '830': {
        content: 'Injustice 2 is a fighting game developed by NetherRealm Studios and published by Warner Bros. Interactive Entertainment. Released in 2017, it is a direct sequel to Injustice: Gods Among Us, allowing players to control heroes and villains from the DC universe in high-intensity battles. Channelling their inner superhero or supervillain, players engage in one-on-one combat using various character-specific abilities and game mechanics, including a unique gear system. This system introduces RPG-like elements, enabling players to customize characters’ appearances and modify their abilities and stats. Set within an alternate, dystopian DC Universe, the storyline unfolds as Batman and his insurgence attempt to rebuild society while fending off those who seek to restore Superman’s regime; amid this chaos, a new threat emerges. Apart from the engaging plot, players can prove their prowess in various multiplayer modes, offline single-player experiences and the expansive gear system. Injustice 2 has been praised for its captivating storyline, polished gameplay mechanics, stunning visuals and the depth added by the character customization system. If you\'re a fan of fighting games with deep lore such as Mortal Kombat or Marvel vs. Capcom, you\'ll be a fan of Injustice 2.',
        submitted_by: '',
        answers: ["Injustice 2"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=Injustice%202%20Legendary%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Finjustice-2-legendary-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/injustice-2-ultimate-edition-pc-steam-key-global-i10000041505005?gtag=391e4ef696"],
        franchise: 'Injustice',
        twitter_ids: ["1826394941519692098", "1826511411079774462", "1826133058648449465"],
        release_year: '2017',
        metacritic_score: '87%',
        genre: 'Fighting',
        console_platform: 'Mobile, PS4, XONE, PC',
        developer: 'NetherRealm Studios',
        backloggd_link: '',
    },
    '831': {
        content: 'Hey You, Pikachu! is an interactive pet simulation game developed by Ambrella and published by Nintendo for the Nintendo 64. First released in 1998, the game lets players care for, and interact with, the fan-favourite Pokémon, Pikachu. Through a special voice recognition unit, players can verbally give commands or engage in conversations with Pikachu, making it a pioneering title in voice-controlled gaming. The gameplay primarily revolves around various mini-games and daily activities, such as fishing, collecting food, or treasure hunting, all accomplished with the help of Pikachu. As players form a bond with their Pikachu, they can unlock more activities and locations for exploration. Hey You, Pikachu! has been noted for its novel control mechanics, charming activities, and the novelty of interacting with one of the most beloved Pokémon characters. The game offers a unique, engaging, and at times, a delightfully challenging experience of animal companionship, both for children and Pokémon enthusiasts alike. If you\'re a fan of interactive pet games like Nintendogs or the Tamagotchi series, you\'ll be a fan of Hey You, Pikachu!.',
        submitted_by: '',
        answers: ["Hey You, Pikachu!"],
        affiliate_links: [],
        franchise: 'Pokemon',
        twitter_ids: [],
        release_year: '1998',
        metacritic_score: '57%',
        genre: 'Digital pet, life simulation',
        console_platform: 'N64',
        developer: 'Ambrella',
        backloggd_link: '',
    },
    '832': {
        content: 'State of Decay 2 is an open-world zombie survival game developed by Undead Labs and published by Microsoft Studios. Released in 2018, it unfolds the grim picture of a post-apocalyptic world where players must build, defend, and manage a community of survivors in a fight against flesheaters. The human-controlled survivors are tasked to gather resources, establish bases, and maintain the community\'s health and morale. The game emphasizes impactful decision-making in real-time scenarios, where every character\'s life matters. Whether it\'s developing interpersonal relationships or battling for survival, each decision alters the course of gameplay, contributing to State of Decay\'s non-linear story. Unique to the series is the perma-death system, where if a character dies, they\'re gone forever, adding a layer of tension to the gameplay. State of Decay 2 also allows cooperative multiplayer gameplay, making survival in the post-apocalyptic world more intense and engaging. The game has gathered acclaim for its deep, RPG mechanics, engaging world, and cooperative multiplayer gameplay. If you\'re a fan of intense survival games like The Last of Us or open-world survival games like DayZ, you\'ll be a fan of State of Decay 2.',
        submitted_by: '',
        answers: ["State of Decay 2"],
        affiliate_links: ["https://www.g2a.com/state-of-decay-2-xbox-one-windows-10-xbox-live-key-global-i10000068421001?gtag=391e4ef696"],
        franchise: 'State of Decay',
        twitter_ids: [],
        release_year: '2018',
        metacritic_score: '66%',
        genre: 'Survival',
        console_platform: 'PC, XONE',
        developer: 'Undead Labs',
        backloggd_link: '',
    },
    '833': {
        content: 'Little Kitty, Big City is a unique simulation-adventure game developed by Mightypaw Games. Released in 2024, it captivates players with its adorable, richly detailed world where they experience life as an urban feline. As a small kitty, you have to explore the vast, bustling city in its charming, paw-sized detail, interact with different city-dwellers, and embark on quests that bring joy to your human and animal friends. Gameplay in Little Kitty, Big City combines elements of adventure, role-playing, and simulation, featuring a variety of mini-games and activities. These range from hunting mice in the park to playing with other cats or finding a purrfect spot to nap. The game\'s detailed, vibrant landscapes and its day-night cycle make for an immersive and engaging environment. This title truly stands out for its unique concept and charming, heartwarming gameplay. The cityscape\'s scale, seen from a kitten\'s perspective, gives the gameplay a unique twist. Little Kitty, Big City takes simulation games to new heights and if you\'re a fan of whimsical adventure games like Animal Crossing or any form of pet simulator games, you\'ll be a fan of Little Kitty, Big City.',
        submitted_by: '',
        answers: ["Little Kitty, Big City"],
        affiliate_links: ["https://www.g2a.com/little-kitty-big-city-pc-steam-gift-global-i10000505437002?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '79%',
        genre: 'Open World, Adventure',
        console_platform: 'PC, XONE, Switch, Xbox Series',
        developer: 'Double Dagger Studio LLC',
        backloggd_link: '',
    },
    '834': {
        content: 'Champions of Norrath is an action role-playing game developed by Snowblind Studios and published by Sony Online Entertainment. Launched in 2004, the game whisks players into the mystical world of Norrath, a realm heavily inspired by the EverQuest universe, where they take on the heroic roles of various champions to thwart a gathering evil force. In Champions of Norrath, players traverse through dungeons and vast landscapes, battling hordes of enemies and uncovering hidden treasures. The game relies heavily on the famed hack-and-slash combat style and offers both single-player and multiplayer modes, including an online cooperative play mode. The characters in the game are highly customizable, with players being able to select and upgrade class, race, gender, and even physical attributes of their champions. The game\'s RPG elements and random loot system ensure there\'s always something new to discover. Champions of Norrath has received considerable praise for its engaging gameplay, multiplayer capabilities, and the depth of its character customization. If you\'re a fan of immersive action RPGs like Baldur\'s Gate: Dark Alliance or Diablo II, you\'ll be a fan of Champions of Norrath.',
        submitted_by: '',
        answers: ["Champions of Norrath"],
        affiliate_links: [],
        franchise: 'EverQuest',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '85%',
        genre: 'Action, RPG, Hack and slash, Fantasy',
        console_platform: 'PS2',
        developer: 'Snowblind Studios, Inc.',
        backloggd_link: '',
    },
    '835': {
        content: 'WWE 2K24 is a professional wrestling video game developed by Visual Concepts and published by 2K Sports. Shipped out in 2024, it offers fans an exhilarating recreation of the WWE universe, allowing them to step into the ring as their favourite wrestling superstars. Players can engage in thrilling matches, replicate iconic battles, or create their own wrestling legend from scratch in an comprehensive career mode. The gameplay is a mix of simulation and arcade style, rewarding tactical decisions and timing alongside flashy theatrics and high-energy action. What sets WWE 2K24 apart is its attention to detail, from wrestlers\' unique move sets and trademark mannerisms to the authentic recreation of the WWE\'s high-octane atmosphere. Alongside single-player and local multiplayer, WWE 2K24 includes an expansive online multiplayer mode, enabling players to compete with or against friends and other players worldwide. It received praise for its improved graphics, gigantic roster, and refined mechanics, matching the spectacle and drama of professional wrestling. If you\'re a fan of sport simulation games like Madden NFL or Fight Night, you\'ll be a fan of WWE 2K24.',
        submitted_by: '',
        answers: ["WWE 2K24"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=WWE%202K24%20Deluxe%20Edition%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fwwe-2k24-deluxe-edition-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/wwe-2k24-deluxe-edition-pc-steam-key-global-i10000503273009?gtag=391e4ef696"],
        franchise: 'WWE',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '78%',
        genre: 'Sports',
        console_platform: 'XONE, Xbox Series, PS4, PS5, PC',
        developer: 'Visual Concepts Entertainment, Inc.',
        backloggd_link: '',
    },
    '836': {
        content: 'X-Men Origins: Wolverine is an action-adventure game developed by Raven Software and published by Activision. The game was released in 2009 and is a tie-in to the movie of the same name. Players are invited to experience the troubled past and formidable power of Wolverine, the iconic clawed mutant, as they fight their way through numerous enemies and epic boss battles. X-Men Origins: Wolverine follows a plot similar to the film, but also includes elements from the wider X-Men comics, providing an immersive experience for fans. The gameplay combines fast-paced action and combat sequences, stealth missions, and exploration. One of the unique features of the game is Wolverine\'s regenerative ability, which is showcased in detailed animations. This complements his famed claws, which players use in devastating combos and in the game\'s brutal finishing moves. X-Men Origins: Wolverine has been lauded for its faithful representation of the character\'s aggression, its intense combat system, and the incorporation of Wolverine’s mutant abilities. If you\'re a fan of superhero games like Spider-Man: Web of Shadows or Batman: Arkham Asylum, you\'ll be a fan of X-Men Origins: Wolverine.',
        submitted_by: '',
        answers: ["X-Men Origins: Wolverine"],
        affiliate_links: [],
        franchise: 'X-Men',
        twitter_ids: [],
        release_year: '2009',
        metacritic_score: '77%',
        genre: 'Action, Adventure, Hack and Slash',
        console_platform: 'PC, PS3, X360, Wii, PS2, PSP, DS',
        developer: 'Raven Software Corporation',
        backloggd_link: '',
    },
    '837': {
        content: 'Homeworld 3 is a real-time strategy game developed by Blackbird Interactive and published by Gearbox Publishing. Released in 2024, this sequel resumes the epic saga of the first two Homeworld games. Players embark on another space odyssey, guiding mighty fleets across uncharted galaxies while unravelling an evolving storyline. Harnessing the power of strategy and real-time tactics, players fight gruelling space battles, manage resources, and engage in intricate unit micromanagement across a fully three-dimensional battlefield presented in spectacular sci-fi visuals. Several gameplay modes provide various navigational and tactical challenges, with the core focus remaining on dynamic fleet management and strategic engagements. Homeworld 3 pushes the boundaries of the space strategy genre with its unique blend of vast scale planetary conflict, intense tactical gameplay, and engaging narrative. Its artistic presentation, alongside a sweeping orchestral score, further supplements the atmospheric immersion, creating a quintessential space epic. The game has been widely praised for its impressive visuals, immersive storytelling, and complex gameplay mechanics. If you\'re a fan of intricate strategy games like StarCraft or the prior Homeworld entries, you\'ll definitely be a fan of Homeworld 3.',
        submitted_by: '',
        answers: ["Homeworld 3"],
        affiliate_links: ["greenmangaming.sjv.io/9g5AgW", "https://www.g2a.com/homeworld-3-pc-steam-gift-global-i10000280042003?gtag=391e4ef696"],
        franchise: 'Homeworld',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '75%',
        genre: 'Real-time strategy, Sci-fi',
        console_platform: 'PC',
        developer: 'Blackbird Interactive, Inc.',
        backloggd_link: '',
    },
    '838': {
        content: 'Super Ghouls n Ghosts is an action-platformer game developed and published by Capcom. Released in 1991, the game casts players as Arthur, a brave knight who must rescue the princess from the grips of the demon emperor. True to its name, Super Ghouls n Ghosts delivers a chilling, atmospheric game world filled with ghouls, ghosts, and other supernatural adversaries, which Arthur combats using his trusty weapons, including his iconic lance. The gameplay in Super Ghouls n Ghosts is characterized by its blend of side-scrolling action, platforming challenges, and its perilous and often tricky enemy placements. The game is also famous for its incredibly high level of difficulty, making every jump, attack, and enemy encounter a thrilling test of skill. Adding to its uniqueness, Arthur can acquire new weapons and armor, including a golden armor that grants magical attacks. The game has been praised for its spooky visuals, haunting soundtrack, and the extreme yet satisfying challenge it offers. If you\'re a fan of difficult platformers like Castlevania or Ghosts n Goblins, you\'ll be a fan of Super Ghouls n Ghosts.',
        submitted_by: '',
        answers: ["Super Ghouls 'n Ghosts"],
        affiliate_links: [],
        franchise: 'Ghosts \'n Goblins',
        twitter_ids: [],
        release_year: '1991',
        metacritic_score: 'Original release too old for metacritic',
        genre: 'Arcade, Platform',
        console_platform: 'SNES',
        developer: 'Capcom Co., Ltd.',
        backloggd_link: '',
    },
    '839': {
        content: 'Metroid Dread is an action-adventure game developed by Nintendo\'s EPD division and MercurySteam. Released in 2021, the game is a long-awaited continuation of the beloved Metroid series, taking place after the events of Metroid Fusion. Here, players once again embody the battle-hardened bounty hunter, Samus Aran, on a new mission on the mysterious planet ZDR. Samus must confront both new and familiar enemies in her ongoing fight against the extraterrestrial Metroid species and other galactic threats. Metroid Dread introduces enhanced stealth mechanics and stalking threats, complementing the game\'s emphasis on exploration, combat, and puzzle-solving inside complex labyrinthine settings. The game utilizes a unique blend of 2D and 3D graphics, resulting in a visually dynamic sidescrolling experience. Metroid Dread has been hailed for its challenging combat, intriguing story, and successful fusion of traditional Metroid gameplay with fresh mechanics. If you\'re a fan of atmospheric action-adventure games like Hollow Knight or Castlevania: Symphony of the Night, you\'ll be a fan of Metroid Dread.',
        submitted_by: 'https://twitter.com/lizzishift',
        answers: ["Metroid Dread"],
        affiliate_links: ["https://www.g2a.com/metroid-dread-nintendo-switch-nintendo-eshop-account-global-i10000337144003https://www.g2a.com/metroid-dread-nintendo-switch-nintendo-eshop-account-global-i10000337144003https://www.g2a.com/metroid-dread-nintendo-switch-nintendo-eshop-account-global-i10000337144003?gtag=391e4ef696"],
        franchise: 'Metroid',
        twitter_ids: [],
        release_year: '2021',
        metacritic_score: '88%',
        genre: 'Metroidvania',
        console_platform: 'Switch',
        developer: 'Mercury Steam Entertainment S.L.',
        backloggd_link: '',
    },
    '840': {
        content: 'Battlefield 2142 is a first-person shooter game developed by EA DICE and published by Electronic Arts, which invites players into a dystopian future where a new Ice Age has sparked a struggle for the last remaining land. Set in the 22nd century, the game pits the European Union (EU) against the newly formed Pan Asian Coalition (PAC) in intense, large-scale battles. In Battlefield 2142, players engage in epic warfare across a variety of futuristic environments using high-tech weapons and vehicles. The key novelty of this game is the Titan Mode where players must destroy the opposing team\'s Titan - a giant, heavily armored flying warship - while protecting their own. With emphasis on teamwork and strategy, Battlefield 2142 offers an immersive multiplayer experience featuring up to 64 players battling it out in distinct classes like Assault, Recon, Engineer and Support. Its unique setting, combined with the innovative gameplay, makes Battlefield 2142 a standout within the Battlefield series. If you\'re a fan of large-scale tactical shooters like Call of Duty: Modern Warfare or Tom Clancy\'s Ghost Recon, you\'ll be a fan of Battlefield 2142.',
        submitted_by: '',
        answers: ["Battlefield 2142"],
        affiliate_links: [],
        franchise: 'Battlefield',
        twitter_ids: [],
        release_year: '2006',
        metacritic_score: '80%',
        genre: 'FPS',
        console_platform: 'PC',
        developer: 'Digital Illusions CE AB',
        backloggd_link: '',
    },
    '841': {
        content: 'Dark Cloud is an action role-playing game developed by Level-5 and published by Sony Computer Entertainment. Released in 2002, players journey into a captivating fantasy world that has been devastated by the Dark Genie, where they take on the role of a boy named Toan, chosen to reconstruct the world with a mystical artifact known as \"Atla\". As Toan, players venture into procedurally generated dungeons, layered with battles against fierce monsters, and filled with the essential \"Atla\" that hold fragments of the ruined civilization. Dark Cloud combines traditional hack-and-slash combat with strategic elements of city-building, as players have the freedom to rebuild the world as they see fit, placing buildings, people, and even the terrain according to their preference. The unique Georama system used for this reconstruction has been applauded for giving players creative control over the world they\'re tasked to mend. With its immense charm and innovative gameplay, Dark Cloud remains a classic in its genre. If you\'re a fan of adventurous role-playing games like The Legend of Zelda or action-packed city-builders like Dragon Quest Builders, you\'ll be a fan of Dark Cloud.',
        submitted_by: '',
        answers: ["Dark Cloud"],
        affiliate_links: [],
        franchise: 'Dark Cloud',
        twitter_ids: [],
        release_year: '2002',
        metacritic_score: '80%',
        genre: 'Action, RPG, Puzzle elements',
        console_platform: 'PS2',
        developer: 'Level-5 Inc.',
        backloggd_link: '',
    },
    '842': {
        content: 'NieR is an action role-playing video game developed by Cavia and published by Square Enix which was released in 2010. It drops players into a post-apocalyptic world as they step into the role of NieR, a kind but determined father on a quest to find a cure for his daughter\'s fatal illness. The gameplay in NieR primarily revolves around hack-and-slash combat, puzzle-solving and exploration, allowing players to take on an assortment of enemies in various in-game locations. A unique feature of the game is its incorporation of different gameplay styles; in some segments, it can change from a 3D action game into a top-down perspective, a side-scrolling platformer, or even a text adventure. The engrossing narrative, complemented by an astonishing soundtrack, provides an emotionally resonant experience. NieR\'s approach to storytelling through its innovative mechanics and poignant story of determination and sacrifice set it apart in the sprawling canvas of action RPGs. If you\'re a fan of narrative-driven games with a seamless blend of various gameplay styles such as Bayonetta or Devil May Cry, NieR is sure to rank among your favorites.',
        submitted_by: '',
        answers: ["NieR", "NieR RepliCant", "NieR Gestalt", "NieR Replicant ver.1.22474487139..."],
        affiliate_links: [],
        franchise: 'Drakengard',
        twitter_ids: [],
        release_year: '2010',
        metacritic_score: '68%',
        genre: 'Action, RPG, Post-apocalyptic',
        console_platform: 'PS3, X360',
        developer: 'cavia inc.',
        backloggd_link: '',
    },
    '843': {
        content: 'Sand Land is an innovative sandbox game released in 2024, developed and published by Lunar Apex Studios. In this highly engaging and exploratory game, players are transported to an immersive desert environment where they must survive and thrive using the resources around them. Playing as a stranded explorer, players will need to dig, collect, and build to create their own sand kingdom in a vast, dynamic and interactive landscape. Sand Land\'s gameplay is centered around open-world exploration, resource management, and strategy, with an emphasis on creativity and problem-solving. One of the unique features of the game is its complex sand physics system that responds realistically to player actions, which adds a new level of intricacy and unpredictability during gameplay. The game is designed to challenge the player’s survival skills and creativity with its constantly changing weather patterns, unpredictable sandstorms, and hidden treasures waiting to be unearthed. Sand Land has been praised for its detailed graphics, realistic physics, and the endless possibilities it presents for player creativity. If you\'re a fan of open-world survival games like Minecraft or the creativity of Terraria, you\'d definitely enjoy playing Sand Land.',
        submitted_by: '',
        answers: ["Sand Land"],
        affiliate_links: ["greenmangaming.sjv.io/vNRy4j", "https://www.g2a.com/sand-land-deluxe-edition-pc-steam-gift-global-i10000503970006?gtag=391e4ef696"],
        franchise: 'None',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '75%',
        genre: 'Action, RPG',
        console_platform: 'PC, PS4, PS5, Xbox Series',
        developer: 'ILCA, Inc.',
        backloggd_link: '',
    },
    '844': {
        content: 'Mario Pinball Land is a pinball video game developed by Fuse Games and published by Nintendo for the Game Boy Advance. Launched in 2004, this out-of-the-box spin on traditional pinball features everyone\'s favorite plumber, Mario, transformed into a pinball to rescue Princess Peach. Using familiar pinball mechanics, players are tasked with navigating through themed boards, defeating classic Mario enemies and bosses, and collecting power-ups, all with the goal of saving the princess. One of the unique aspects of the game is its remarkable integration of classic Mario elements such as throwing fireballs and stomping on Goomba into pinball gameplay. It also expands on traditional pinball by featuring multiple interconnected boards, much like different levels in adventure games, which adds depth and progression to the overall play. The game enthusiastically blends the charm and adventure of the Mario universe with the fun and addictiveness of pinball games. Highly praised for its innovative approach, Mario Pinball Land offers a fresh, challenging spin to traditional pinball and Mario games. If you\'re a fan of unique arcade games like Sonic Spinball or adventurous platformers like Donkey Kong Country, you\'ll be a fan of Mario Pinball Land.',
        submitted_by: '',
        answers: ["Mario Pinball Land", "Super Mario Ball"],
        affiliate_links: [],
        franchise: 'Mario',
        twitter_ids: [],
        release_year: '2004',
        metacritic_score: '62%',
        genre: 'Pinball',
        console_platform: 'Game Boy Advance',
        developer: 'Fuse Games Limited',
        backloggd_link: '',
    },
    '845': {
        content: 'Bust-A-Move, also known as Puzzle Bobble, is a tile-matching arcade puzzle game developed and published by Taito. Released in 1996, it brings players into a colorful world led by Bub and Bob, the dragons from Bubble Bobble, where they are tasked with shooting colored bubbles into a field above, to create and pop clusters of the same color. The gameplay of Bust-A-Move is marked by its strategic and fast-paced nature. Players must carefully aim and launch their bubbles to optimize their matches while attempting to clear the field as quickly as they can, with each level increasing in complexity and speed. The bubbles will descend a little further after a certain amount of time or shots have been fired, bringing an element of urgency to each round. Apart from its engaging solo play, it popularized competitive play, with modes allowing two players to challenge each other. Bust-A-Move has been lauded for its addictive and easy-to-learn yet hard-to-master gameplay, coupled with its vibrant visuals and memorable characters. If you\'re a fan of competitive puzzle games like Tetris or Dr. Mario, you\'ll certainly be a fan of Bust-A-Move.',
        submitted_by: '',
        answers: ["Bust-A-Move", "Puzzle Bobble", "Bubble Buster"],
        affiliate_links: [],
        franchise: 'Bubble Bobble',
        twitter_ids: [],
        release_year: '1996',
        metacritic_score: 'Original release too old for metacritic',
        genre: 'Puzzle, Arcade',
        console_platform: 'Arcade',
        developer: 'Taito Corporation',
        backloggd_link: '',
    },
    '846': {
        content: 'Helldivers II is a top-down shooter game developed by Arrowhead Game Studios and published by PlayStation Mobile. Launched in 2024 as a sequel to the original cult classic, it plunges players back into intergalactic warfare as elite soldiers known as Helldivers, tasked to protect \'Super Earth\' against alien invaders. Gameplay in Helldivers II expands on its predecessor\'s twin-stick shooting mechanics, involving cooperative multiplayer, friendly fire, and procedurally generated levels. Players navigate treacherous landscapes, battle against fierce alien creatures, and complete objectives, all whilst avoiding the dynamic dangers of each environment. A key element of the game is strategic teamwork, requiring careful coordination and communication to survive its intense, chaotic gameplay. Unique to Helldivers II is the Galactic Campaign, where the collective efforts of players worldwide contribute to the progress of a shared warfront. Helldivers II has been praised for its challenging combat, enhanced multiplayer experience, and the depth of its strategic elements. If you\'re a fan of high-octane cooperative shooters like Alien Swarm or the strategic warfare of XCOM, you\'ll be a fan of Helldivers II.',
        submitted_by: '',
        answers: ["Helldivers II"],
        affiliate_links: ["https://greenmangaming.sjv.io/c/4098203/1269775/15105?prodsku=HELLDIVERS%202%20-%20PC&u=https%3A%2F%2Fwww.greenmangaming.com%2Fgames%2Fhelldivers-2-pc%2F&intsrc=PUI2_9608", "https://www.g2a.com/helldivers-2-pc-steam-gift-global-i10000500930001?gtag=391e4ef696"],
        franchise: 'Helldivers',
        twitter_ids: [],
        release_year: '2024',
        metacritic_score: '83%',
        genre: 'FPS',
        console_platform: 'PC, PS5',
        developer: 'Arrowhead Game Studios AB',
        backloggd_link: '',
    },
};
