import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

const FinalAnswer = ({ answer }) => {
    const [showOtherAnswers, toggleShowOtherAnswers] = useState(false);

    return (
        <h3 style={{ textAlign: 'center' }}>
            {'The answer was'}:{' '}
            <div className="answer">
                <span>{answer[0]}</span>
            </div>
            {answer.length > 1 && (
                <>
                    <svg
                        className={`dropdown-icon ${showOtherAnswers ? 'rotated' : ''}`}
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                            toggleShowOtherAnswers(!showOtherAnswers);
                        }}
                        style={{ verticalAlign: 'middle', cursor: 'pointer' }}
                    >
                        <path
                            d="M7.41 7.84L12 12.42L16.59 7.84L18 9.25L12 15.25L6 9.25L7.41 7.84Z"
                            fill="currentColor"
                        />
                    </svg>

                    <AnimatePresence>
                        {showOtherAnswers && (
                            <motion.div
                                initial={{ opacity: 0, height: 0 }}
                                animate={{ opacity: 1, height: 'auto' }}
                                exit={{ opacity: 0, height: 0 }}
                                transition={{ duration: 0.3 }}
                            >
                                {/*<span style={{ color: '#eee' }}> Other answers: </span>*/}
                                {answer.slice(1).map((item, index) => (
                                    <span key={index}>
                                        <span className={'answer'}>{item}</span>
                                        <span style={{ color: '#eee' }}>{index !== answer.length - 2 ? ', ' : ''}</span>
                                    </span>
                                ))}
                            </motion.div>
                        )}
                    </AnimatePresence>
                </>
            )}
        </h3>
    );
};

export default FinalAnswer;
