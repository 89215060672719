// @flow

import type { PUZZLE_TYPES_TYPE } from '../constants';
import type { GameSettings } from './game_settings';

import { PUZZLE_TYPES } from '../constants';
import { $NotNull } from '../globals';
import { GTACSettings } from './gtac_settings';
import { GTASettings } from './gta_settings'; // has to be after GTAC
import { GTGCSettings } from './gtgc_settings';
import { GTGSettings } from './gtg_settings'; // has to be after GTGC
import { GTBSettings } from './gtb_settings';
import { GTMCSettings } from './gtmc_settings';
import { GTMSettings } from './gtm_settings'; // has to be after GTMC
import { GTLSettings } from './gtl_settings';

let SINGLETON_MAP = {
    [PUZZLE_TYPES.GTG]: undefined,
    [PUZZLE_TYPES.GTGC]: undefined,
    [PUZZLE_TYPES.GTA]: undefined,
    [PUZZLE_TYPES.GTAC]: undefined,
    [PUZZLE_TYPES.GTB]: undefined,
    [PUZZLE_TYPES.GTM]: undefined,
    [PUZZLE_TYPES.GTMC]: undefined,
    [PUZZLE_TYPES.GTL]: undefined,
};

export function settingsFactory(puzzleType: PUZZLE_TYPES_TYPE): GameSettings {
    if (SINGLETON_MAP[puzzleType]) {
        return SINGLETON_MAP[puzzleType];
    }
    let settings;
    switch (puzzleType) {
        case PUZZLE_TYPES.GTG:
            settings = new GTGSettings();
            break;
        case PUZZLE_TYPES.GTGC:
            settings = new GTGCSettings();
            break;
        case PUZZLE_TYPES.GTA:
            settings = new GTASettings();
            break;
        case PUZZLE_TYPES.GTAC:
            settings = new GTACSettings();
            break;
        case PUZZLE_TYPES.GTB:
            settings = new GTBSettings();
            break;
        case PUZZLE_TYPES.GTM:
            settings = new GTMSettings();
            break;
        case PUZZLE_TYPES.GTMC:
            settings = new GTMCSettings();
            break;
        case PUZZLE_TYPES.GTL:
            settings = new GTLSettings();
            break;
        default:
            console.log('using default settings');
            settings = new GTGSettings();
            break;
    }
    SINGLETON_MAP[puzzleType] = settings;
    return $NotNull(SINGLETON_MAP[puzzleType]);
}
