import React from 'react';

const winMessages = {
    1: "You Nailed it!",
    ...Object.fromEntries(
        [2, 3, 4].map(key => [key, "Good Guess!"])
    ),
    5: "You got it!",
    6: "Nice, that was close!" // "Phew", should this be chosen randomly?
}

const FinalMessage = ({ gameState, num_guesses }) => {

    const winMessage = winMessages[num_guesses];

    return (
        <>
         {gameState === 'win' && <h2 className="win-msg">
                        {winMessage}
                    </h2>}
                    {gameState === 'lose' && (
                        <h2 className="lose-msg" style={{ textAlign: 'center' }}>
                            {'Oh no! Better luck next time'}
                        </h2>
                    )}
        </>
    );
};

export default FinalMessage;
