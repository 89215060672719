/* eslint-disable no-useless-escape */
// @flow

export type TGTACAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
    artist: string,
};

type TGTACAnswers = {
    [string]: TGTACAnswer
};

export const answers: TGTACAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Thriller"],
        affiliate_links: [],
        franchise: 'Michael Jackson',
        source: 'https://a5.mzstatic.com/us/r1000/0/Music115/v4/32/4f/fd/324ffda2-9e51-8f6a-0c2d-c6fd2b41ac55/074643811224.jpg',
        uncover_order: [5, 5, 4, 1],
        artist: 'Michael Jackson',
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Dark Side of the Moon"],
        affiliate_links: [],
        franchise: 'Pink Floyd',
        source: 'https://a5.mzstatic.com/us/r1000/0/Music115/v4/3c/1b/a9/3c1ba9e1-15b1-03b3-3bfd-09dbd9f1705b/dj.mggvbaou.jpg',
        uncover_order: [1, 5, 3, 4],
        artist: 'Pink Floyd',
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["Rumours"],
        affiliate_links: [],
        franchise: 'Fleetwood Mac',
        source: 'https://a5.mzstatic.com/us/r1000/0/Music124/v4/4d/13/ba/4d13bac3-d3d5-7581-2c74-034219eadf2b/081227970949.jpg',
        uncover_order: [5, 6, 0, 2],
        artist: 'Fleetwood Mac',
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Whenever You Need Somebody"],
        affiliate_links: [],
        franchise: 'Rick Astley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/ce/6d/5b/ce6d5b48-8c36-b990-3b9c-81862fadb459/0859381157694.jpg/600x600bb.jpg',
        uncover_order: [5, 3, 0, 8],
        artist: 'Rick Astley',
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Lungs"],
        affiliate_links: [],
        franchise: 'Florence + The Machine',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/46/45/58/46455821-202f-5fac-ee42-87853cb9fa03/09UMGIM14223.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'Florence + The Machine',
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["21"],
        affiliate_links: [],
        franchise: 'Adele',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/d8/e3/f9/d8e3f9ea-d6fe-9a1b-9f13-109983d3062e/191404113868.png/600x600bb.jpg',
        uncover_order: [6, 2, 4, 8],
        artist: 'Adele',
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Midnights"],
        affiliate_links: [],
        franchise: 'Taylor Swift',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/59/13/5c/59135ccc-8425-415c-7f89-8aeada60088e/22UM1IM22440.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 8, 4, 1],
        artist: 'Taylor Swift',
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Sigh No More"],
        affiliate_links: [],
        franchise: 'Mumford & Sons',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/f4/51/99/f45199b6-9529-c884-110d-11d21ed67d8a/602547344403_1.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 8],
        artist: 'Mumford & Sons',
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Demon Days"],
        affiliate_links: [],
        franchise: 'Gorillaz',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/1c/0f/81/1c0f818a-e458-dd84-6f1b-ccbdf5fe14d6/825646291045.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 2, 8],
        artist: 'Gorillaz',
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["When We All Fall Asleep, Where Do We Go?"],
        affiliate_links: [],
        franchise: 'Billie Eilish',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/1a/37/d1/1a37d1b1-8508-54f2-f541-bf4e437dda76/19UMGIM05028.rgb.jpg/600x600bb.jpg',
        uncover_order: [5, 6, 4, 1],
        artist: 'Billie Eilish',
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["Jolene"],
        affiliate_links: [],
        franchise: 'Dolly Parton',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/0b/f8/1e/0bf81e88-de6e-aba3-9fd4-e7407bfbcc31/886445438048.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 4, 2],
        artist: 'Dolly Parton',
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["VHS"],
        affiliate_links: [],
        franchise: 'X Ambassadors',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ba/85/8e/ba858e00-8c2d-5c8f-c417-e577829c26fa/15UMGIM23181.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'X Ambassadors',
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Dangerously in Love"],
        affiliate_links: [],
        franchise: 'Beyoncé',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/03/c5/d4/mzi.ldvrmhxt.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 0, 7],
        artist: 'Beyoncé',
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Totally Krossed Out"],
        affiliate_links: [],
        franchise: 'Kriss Kross',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/ea/03/30/ea033079-3254-3a3c-e2a6-504b30095ca6/dj.wutawrld.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 2, 0],
        artist: 'Kriss Kross',
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Pure Heroine"],
        affiliate_links: [],
        franchise: 'Lorde',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music126/v4/e1/d3/23/e1d323d6-a7e4-6d5e-e6f6-5105c76db133/13UAAIM68691.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 2, 5, 8],
        artist: 'Lorde',
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Ocean Eyes"],
        affiliate_links: [],
        franchise: 'Owl City',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/f6/72/94/f67294c7-f123-c698-c70b-9e9aa1b0947f/09UMGIM22612.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 6, 2],
        artist: 'Owl City',
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["She's So Unusual"],
        affiliate_links: [],
        franchise: 'Cyndi Lauper',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/8a/8c/13/8a8c13e0-06af-1710-7b7d-ebec65fb8361/074643893022.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 0, 4],
        artist: 'Cyndi Lauper',
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["A Night at the Opera"],
        affiliate_links: [],
        franchise: 'Queen',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/b1/a9/84/b1a984dc-8dce-e8cb-1a0e-20293f7c500a/14DMGIM05548.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 5, 4, 8],
        artist: 'Queen',
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Billy Talent II"],
        affiliate_links: [],
        franchise: 'Billy Talent',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/31/89/a2/mzi.ntaoftmc.jpg/600x600bb.jpg',
        uncover_order: [6, 3, 4, 2],
        artist: 'Billy Talent',
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["THE E.N.D.", "THE ENERGY NEVER DIES"],
        affiliate_links: [],
        franchise: 'The Black Eyed Peas',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/49/c5/4b/49c54bff-bb76-bad6-48a1-e1de9d12a7f8/09UMGIM13833.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 4, 2],
        artist: 'The Black Eyed Peas',
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Dire Straits"],
        affiliate_links: [],
        franchise: 'Dire Straits',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/47/c3/21/mzi.ciyzkqao.jpg/600x600bb.jpg',
        uncover_order: [8, 2, 3, 1],
        artist: 'Dire Straits',
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["Common Courtesy"],
        affiliate_links: [],
        franchise: 'A Day To Remember',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/ca/44/2f/ca442f10-5017-f0ba-cee1-d560fefd58a9/0045778775460.png/600x600bb.jpg',
        uncover_order: [6, 2, 5, 8],
        artist: 'A Day to Remember',
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Enema of the State"],
        affiliate_links: [],
        franchise: 'Blink-182',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/c2/f2/78/c2f2783f-8e65-0b2b-80ac-b52c4cfece2d/16UMGIM35657.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 8],
        artist: 'Blink-182',
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["Waking Up the Neighbours"],
        affiliate_links: [],
        franchise: 'Bryan Adams',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/b0/f7/5d/b0f75dab-7e01-d4c6-61c3-e6253a2cae22/06UMGIM18645.rgb.jpg/600x600bb.jpg',
        uncover_order: [7, 6, 4, 2],
        artist: 'Bryan Adams',
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["In Absentia"],
        affiliate_links: [],
        franchise: 'Porcupine Tree',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/c7/d7/cb/c7d7cb93-6292-156d-bf68-ae324c8e6424/802644401669.jpg/600x600bb.jpg',
        uncover_order: [0, 7, 5, 8],
        artist: 'Porcupine Tree',
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["St. Elsewhere"],
        affiliate_links: [],
        franchise: 'Gnarls Barkley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/10/77/43/mzi.mhgimyxe.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 4, 1],
        artist: 'Gnarls Barkley',
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["Miss E... So Addictive"],
        affiliate_links: [],
        franchise: 'Missy Elliott',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/d1/f0/f4/d1f0f433-54c8-13e0-9736-15782c6b7bdb/mzi.fnlfdvuf.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'Missy Elliott',
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Nevermind"],
        affiliate_links: [],
        franchise: 'Nirvana',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/95/fd/b9/95fdb9b2-6d2b-92a6-97f2-51c1a6d77f1a/00602527874609.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 3, 1, 7],
        artist: 'Nirvana',
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["After Hours"],
        affiliate_links: [],
        franchise: 'The Weeknd',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/6f/bc/e6/6fbce6c4-c38c-72d8-4fd0-66cfff32f679/20UMGIM12176.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 1, 4],
        artist: 'The Weeknd',
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["Let's Talk About Love"],
        affiliate_links: [],
        franchise: 'Celine Dion',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/39/25/f7/3925f7d9-8190-2431-71c6-1c44cec6864b/886447917251.jpg/600x600bb.jpg',
        uncover_order: [8, 1, 5, 7],
        artist: 'Celine Dion',
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["I Never Loved a Man the Way I Love You"],
        affiliate_links: [],
        franchise: 'Aretha Franklin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/d1/31/ee/d131eec4-614c-d661-b580-3dc32b8547e8/603497896622.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 3, 2],
        artist: 'Aretha Franklin',
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Mezzanine"],
        affiliate_links: [],
        franchise: 'Massive Attack',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/0a/98/55/0a98555b-8d9d-3b46-660a-b91261557d17/00724384559953.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 2, 4],
        artist: 'Massive Attack',
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Tha Carter III"],
        affiliate_links: [],
        franchise: 'Lil Wayne',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/7d/63/39/7d6339c5-6ce7-7cf6-5f5b-6f3f3f478817/08UMGIM10308.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 1, 2],
        artist: 'Lil Wayne',
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["Like a Prayer"],
        affiliate_links: [],
        franchise: 'Madonna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/20/3c/f5/203cf53d-689e-528f-29d7-ba33758254aa/mzi.rotbotfl.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 1, 5],
        artist: 'Madonna',
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Night Visions"],
        affiliate_links: [],
        franchise: 'Imagine Dragons',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/1f/fa/09/1ffa092f-f52f-4a66-7d10-4cc5982dc747/12UMGIM46901.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 4, 2],
        artist: 'Imagine Dragons',
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["AM"],
        affiliate_links: [],
        franchise: 'Arctic Monkeys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music113/v4/cc/0f/2d/cc0f2d02-5ff1-10e7-eea2-76863a55dbad/887828031795.png/600x600bb.jpg',
        uncover_order: [6, 0, 4, 3],
        artist: 'Arctic Monkeys',
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["The Doors"],
        affiliate_links: [],
        franchise: 'The Doors',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/97/e2/ce/97e2ce16-02e1-54f6-3896-dcc55249ff7d/603497838738.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 4, 2],
        artist: 'The Doors',
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["All That You Can't Leave Behind"],
        affiliate_links: [],
        franchise: 'U2',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/e3/bd/02/e3bd02e5-5f43-8047-1a2b-4a4f8914fc60/17UMGIM95495.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'U2',
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Elephunk"],
        affiliate_links: [],
        franchise: 'The Black Eyed Peas',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/05/a3/a1/05a3a1aa-c472-a7f2-4d8b-9eacff8a1e43/22UMGIM30753.rgb.jpg/600x600bb.jpg',
        uncover_order: [3, 5, 4, 8],
        artist: 'The Black Eyed Peas',
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Aftermath"],
        affiliate_links: [],
        franchise: 'The Rolling Stones',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/ea/26/4d/ea264dc4-5f6e-29a2-c95e-18097da49be9/00018771894520.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 8, 4, 1],
        artist: 'The Rolling Stones',
    },
    '41': {
        content: '',
        submitted_by: '',
        answers: ["Born to Die"],
        affiliate_links: [],
        franchise: 'Lana Del Rey',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/5f/d9/63/5fd96387-45fa-6b94-afd8-7b2c4a24a93b/11UMGIM38959.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 8],
        artist: 'Lana Del Rey',
    },
    '42': {
        content: '',
        submitted_by: '',
        answers: ["The Wall"],
        affiliate_links: [],
        franchise: 'Pink Floyd',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/3c/b4/e3/3cb4e3d0-cd77-8f18-7465-d60e6949b435/886445635850.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 1, 7],
        artist: 'Pink Floyd',
    },
    '43': {
        content: '',
        submitted_by: '',
        answers: ["Born In the U.S.A."],
        affiliate_links: [],
        franchise: 'Bruce Springsteen',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/32/72/68/327268ba-b9dd-b322-2a16-bdd0212df48c/074643865326.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 3, 0],
        artist: 'Bruce Springsteen',
    },
    '44': {
        content: '',
        submitted_by: '',
        answers: ["In the Court of the Crimson King"],
        affiliate_links: [],
        franchise: 'King Crimson',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music5/v4/2f/c7/19/2fc71988-6871-be2c-6731-a3d0f2a6b232/Court_2500px.jpg/600x600bb.jpg',
        uncover_order: [6, 5, 4, 2],
        artist: 'King Crimson',
    },
    '45': {
        content: '',
        submitted_by: '',
        answers: ["Graduation"],
        affiliate_links: [],
        franchise: 'Kanye West',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/2f/db/2c/2fdb2c9d-171c-c6dc-57ee-4bae2b4bb11a/07UMGIM12671.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 0, 2],
        artist: 'Kanye West',
    },
    '46': {
        content: '',
        submitted_by: '',
        answers: ["Kind of Blue"],
        affiliate_links: [],
        franchise: 'Miles Davis',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music/7f/9f/d6/mzi.vtnaewef.jpg/600x600bb.jpg',
        uncover_order: [8, 5, 3, 2],
        artist: 'Miles Davis',
    },
    '47': {
        content: '',
        submitted_by: '',
        answers: ["Put Yourself Back Together"],
        affiliate_links: [],
        franchise: 'Real Friends',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/a9/d9/e4/a9d9e4a1-a6cc-e11c-a7ee-998ab487b7c0/881034186560.png/600x600bb.jpg',
        uncover_order: [4, 2, 1, 8],
        artist: 'Real Friends',
    },
    '48': {
        content: '',
        submitted_by: '',
        answers: ["Honky Ch\u00e2teau"],
        affiliate_links: [],
        franchise: 'Elton John',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/75/b6/da/75b6da76-a3e0-6a77-cbe5-ad6c42cb0692/06UMGIM48050.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 3, 5, 2],
        artist: 'Elton John',
    },
    '49': {
        content: '',
        submitted_by: '',
        answers: ["Smiley Smile"],
        affiliate_links: [],
        franchise: 'The Beach Boys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/ef/b9/c5/efb9c577-61e6-b381-ca38-b1615081d677/13UABIM03827.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 2],
        artist: 'The Beach Boys',
    },
    '50': {
        content: '',
        submitted_by: '',
        answers: ["Babel"],
        affiliate_links: [],
        franchise: 'Mumford & Sons',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/80/13/0c/80130c3f-36bc-f526-8835-8dddbeec493d/602537110490.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 4, 7],
        artist: 'Mumford & Sons',
    },
    '51': {
        content: '',
        submitted_by: '',
        answers: ["Get Rich or Die Tryin'"],
        affiliate_links: [],
        franchise: '50 Cent',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/51/a6/c9/51a6c989-f81d-42b3-c94c-e889a7c07885/06UMGIM15592.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 2, 1],
        artist: '50 Cent',
    },
    '52': {
        content: '',
        submitted_by: '',
        answers: ["Good Girl Gone Bad"],
        affiliate_links: [],
        franchise: 'Rihanna',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/6f/ab/10/6fab1076-6c3c-7346-5003-fe902066e2d5/07UMGIM07720.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 2, 5],
        artist: 'Rihanna',
    },
    '53': {
        content: '',
        submitted_by: '',
        answers: ["Takk..."],
        affiliate_links: [],
        franchise: 'Sigur Rós',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/ce/3e/b8/ce3eb805-fb13-0200-5856-081ed0014e13/190296926952.jpg/600x600bb.jpg',
        uncover_order: [0, 2, 4, 7],
        artist: 'Sigur Rós',
    },
    '54': {
        content: '',
        submitted_by: '',
        answers: ["Birds In The Trap Sing McKnight"],
        affiliate_links: [],
        franchise: 'Travis Scott',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/50/f7/a3/50f7a39d-3bd5-28e9-0264-532f08b5b810/886446074726.jpg/600x600bb.jpg',
        uncover_order: [6, 0, 4, 3],
        artist: 'Travis Scott',
    },
    '55': {
        content: '',
        submitted_by: '',
        answers: ["Baby One More Time"],
        affiliate_links: [],
        franchise: 'Britney Spears',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music116/v4/ad/4b/ee/ad4beee5-800d-d66a-759a-b4f4dc2d4874/888880333476.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 0, 1],
        artist: 'Britney Spears',
    },
    '56': {
        content: '',
        submitted_by: '',
        answers: ["Music Has the Right to Children"],
        affiliate_links: [],
        franchise: 'Boards Of Canada',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features125/v4/b5/4c/c2/b54cc20d-03f5-f2c4-4a0d-9b51ad65af89/dj.txuslqgv.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 1, 7],
        artist: 'Boards of Canada',
    },
    '57': {
        content: '',
        submitted_by: '',
        answers: ["Songs About Jane"],
        affiliate_links: [],
        franchise: 'Maroon 5',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/d0/3e/25/d03e255d-e205-0e66-20f6-01e251896c25/14UMGIM27076.rgb.jpg/600x600bb.jpg',
        uncover_order: [8, 4, 0, 2],
        artist: 'Maroon 5',
    },
    '58': {
        content: '',
        submitted_by: '',
        answers: ["Millennium"],
        affiliate_links: [],
        franchise: 'Backstreet Boys',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/5f/6b/e9/5f6be919-1b9e-30ef-45b7-cc27fc428fd5/012414167224.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 1, 4],
        artist: 'Backstreet Boys',
    },
    '59': {
        content: '',
        submitted_by: '',
        answers: ["Yeezus"],
        affiliate_links: [],
        franchise: 'Kanye West',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/3c/56/e7/3c56e717-06a0-b67d-e694-9b6e6e43a5a8/13UAAIM08444.rgb.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 1, 7],
        artist: 'Kanye West',
    },
    '60': {
        content: '',
        submitted_by: '',
        answers: ["Purple Rain"],
        affiliate_links: [],
        franchise: 'Prince',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music211/v4/00/17/f2/0017f24f-e580-b77a-71a8-1bc7b75881bf/603497822065.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 1],
        artist: 'Prince',
    },
    '61': {
        content: '',
        submitted_by: '',
        answers: ["All Killer, No Filler"],
        affiliate_links: [],
        franchise: 'Sum 41',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/bd/32/bb/bd32bb0b-0bb4-88bb-f32a-9ff383579d54/06UMGIM15497.rgb.jpg/600x600bb.jpg',
        uncover_order: [7, 2, 3, 5],
        artist: 'Sum 41',
    },
    '62': {
        content: '',
        submitted_by: '',
        answers: ["Slippery When Wet"],
        affiliate_links: [],
        franchise: 'Bon Jovi',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/40/16/3e/40163e24-6985-b785-d4ea-cbae07d74812/06UMGIM05422.rgb.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 0, 5],
        artist: 'Bon Jovi',
    },
    '63': {
        content: '',
        submitted_by: '',
        answers: ["Hotel California"],
        affiliate_links: [],
        franchise: 'Eagles',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/88/16/2c/88162c3d-46db-8321-61f3-3a47404cfe76/075596050920.jpg/600x600bb.jpg',
        uncover_order: [6, 2, 0, 3],
        artist: 'Eagles',
    },
    '64': {
        content: '',
        submitted_by: '',
        answers: ["Grace"],
        affiliate_links: [],
        franchise: 'Jeff Buckley',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/26/d6/e3/26d6e339-a7a9-d61e-1b5f-0852a5515a55/886445517880.jpg/600x600bb.jpg',
        uncover_order: [8, 7, 0, 2],
        artist: 'Jeff Buckley',
    },
    '65': {
        content: '',
        submitted_by: '',
        answers: ["Fear of God II: Let Us Pray"],
        affiliate_links: [],
        franchise: 'Pusha T',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/5b/82/fe/5b82fef0-522e-4625-4c0b-c373d446d2d6/812814020750.jpg/600x600bb.jpg',
        uncover_order: [3, 2, 1, 7],
        artist: 'Pusha T',
    },
    '66': {
        content: '',
        submitted_by: '',
        answers: ["A Rush of Blood to the Head"],
        affiliate_links: [],
        franchise: 'Coldplay',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music115/v4/b9/b4/2a/b9b42ad1-1e25-5096-da43-497a247e69a3/190295978051.jpg/600x600bb.jpg',
        uncover_order: [6, 8, 0, 1],
        artist: 'Coldplay',
    },
    '67': {
        content: '',
        submitted_by: '',
        answers: ["Vampire Weekend"],
        affiliate_links: [],
        franchise: 'Vampire Weekend',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/ba/01/17/ba01176d-e46f-aac5-e1be-4256c26c173e/634904831868.png/600x600bb.jpg',
        uncover_order: [8, 2, 4, 5],
        artist: 'Vampire Weekend',
    },
    '68': {
        content: '',
        submitted_by: '',
        answers: ["Her Loss"],
        affiliate_links: [],
        franchise: 'Drake & 21 Savage',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music112/v4/c7/00/3f/c7003f83-3a43-1201-4aec-41be71ba64c5/22UM1IM29131.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 3, 4, 1],
        artist: 'Drake & 21 Savage',
    },
    '69': {
        content: '',
        submitted_by: '',
        answers: ["FutureSex/LoveSounds"],
        affiliate_links: [],
        franchise: 'Justin Timberlake',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/d5/7a/a5/d57aa570-6a18-5f37-e80f-152a5e8ff1e0/888880017444.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 2, 4],
        artist: 'Justin Timberlake',
    },
    '70': {
        content: '',
        submitted_by: '',
        answers: ["Talking Book"],
        affiliate_links: [],
        franchise: 'Stevie Wonder',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music114/v4/62/61/61/626161c0-f4d7-e6ff-8586-768340ef278f/00602537002382.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 5, 0, 8],
        artist: 'Stevie Wonder',
    },
    '71': {
        content: '',
        submitted_by: '',
        answers: ["Led Zeppelin IV"],
        affiliate_links: [],
        franchise: 'Led Zeppelin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music5/v4/16/de/e3/16dee335-173b-57ce-80b5-dadf684c0b33/dj.oblporhs.jpg/600x600bb.jpg',
        uncover_order: [0, 8, 1, 4],
        artist: 'Led Zeppelin',
    },
    '72': {
        content: '',
        submitted_by: '',
        answers: ["Oral Fixation, Vol. 2"],
        affiliate_links: [],
        franchise: 'Shakira',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Features114/v4/2a/33/9a/2a339a01-28f3-3ddd-f9e7-30c837f046cc/dj.bvmymfoa.jpg/600x600bb.jpg',
        uncover_order: [8, 3, 4, 0],
        artist: 'Shakira',
    },
    '73': {
        content: '',
        submitted_by: '',
        answers: ["Mule Variations"],
        affiliate_links: [],
        franchise: 'Tom Waits',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music124/v4/b9/c8/ea/b9c8eac9-0f83-f194-7de6-f68d21b5ed1f/0045778654734.png/600x600bb.jpg',
        uncover_order: [6, 5, 2, 1],
        artist: 'Tom Waits',
    },
    '74': {
        content: '',
        submitted_by: '',
        answers: ["Boys & Girls"],
        affiliate_links: [],
        franchise: 'Alabama Shakes',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music125/v4/f2/c0/c6/f2c0c63f-9956-bbc6-dc8d-c1ad94caf194/new_front_cover_digital_rh3_1500.jpg/600x600bb.jpg',
        uncover_order: [8, 6, 4, 5],
        artist: 'Alabama Shakes',
    },
    '75': {
        content: '',
        submitted_by: '',
        answers: ["Windowlicker - EP"],
        affiliate_links: [],
        franchise: 'Aphex Twin',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music123/v4/81/20/73/812073f2-c437-fd1d-636d-6be5a4432747/0801061910532.png/600x600bb.jpg',
        uncover_order: [6, 5, 1, 2],
        artist: 'Aphex Twin',
    },
    '76': {
        content: '',
        submitted_by: '',
        answers: ["Nothing But the Best"],
        affiliate_links: [],
        franchise: 'Frank Sinatra',
        source: 'https://is1-ssl.mzstatic.com/image/thumb/Music122/v4/0e/3c/5f/0e3c5fa9-b0f4-3643-9ad5-02b1d2d35f1f/13UAEIM06265.rgb.jpg/600x600bb.jpg',
        uncover_order: [2, 6, 4, 8],
        artist: 'Frank Sinatra',
    },
};
