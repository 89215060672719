// @flow

import type { AnswerUnion } from './settings/game_settings';

export const populateGameList = (answers: AnswerUnion): Array<string> => {
    let gameList = [];
    // Add all answers from answers.js to gameList, avoiding duplicates. In case the server goes down people can still guess
    for (const gameID in answers) {
        for (const answer of answers[gameID].answers) {
            if (!gameList.some((game) => game.toLowerCase() === answer.toLowerCase())) {
                gameList.unshift(answer);
            }
        }
    }
    return gameList;
};
