/* eslint-disable no-useless-escape */
// @flow

export type TGTLAnswer = {
    content: string,
    submitted_by: string,
    answers: Array<string>,
    affiliate_links: Array<string>,
    franchise: string,
    source: string,
    uncover_order: Array<string>,
};

type TGTLAnswers = {
    [string]: TGTLAnswer
};

export const answers: TGTLAnswers = {
    '1': {
        content: '',
        submitted_by: '',
        answers: ["Nike"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/NKE-0c8add60.png?t=1632522146&download=true',
        uncover_order: [0, 2, 4, 6],
    },
    '2': {
        content: '',
        submitted_by: '',
        answers: ["Facebook", "Meta"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/FB-2d2223ad.png?t=1633076955&download=true',
        uncover_order: [0, 2, 4, 6],
    },
    '3': {
        content: '',
        submitted_by: '',
        answers: ["McDonald's"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MCD-4fd8e96f.png?t=1647435923',
        uncover_order: [7, 5, 1, 0],
    },
    '4': {
        content: '',
        submitted_by: '',
        answers: ["Shell"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/SHEL-a71e2d12.png?t=1633506726',
        uncover_order: [2, 8, 4, 3],
    },
    '5': {
        content: '',
        submitted_by: '',
        answers: ["Coca-Cola"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/KO-b23a2a5e.png?t=1720244492',
        uncover_order: [8, 0, 4, 5],
    },
    '6': {
        content: '',
        submitted_by: '',
        answers: ["Volkswagen"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/VOW3.DE-672bac7c.png?t=1720244494',
        uncover_order: [6, 0, 4, 8],
    },
    '7': {
        content: '',
        submitted_by: '',
        answers: ["Opera"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/OPRA-305c175c.png?t=1720244493',
        uncover_order: [6, 5, 0, 1],
    },
    '8': {
        content: '',
        submitted_by: '',
        answers: ["Ferrari"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/RACE-acd268af.png?t=1720244493',
        uncover_order: [0, 5, 1, 3],
    },
    '9': {
        content: '',
        submitted_by: '',
        answers: ["Herm\u00e8s"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/RMS.PA_BIG-10278b0f.png?t=1720244493',
        uncover_order: [0, 8, 1, 4],
    },
    '10': {
        content: '',
        submitted_by: '',
        answers: ["Amazon"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/AMZN_BIG-accd00da.png?t=1720244490',
        uncover_order: [8, 7, 0, 2],
    },
    '11': {
        content: '',
        submitted_by: '',
        answers: ["LG Energy Solution"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/373220.KS-93d78350.png?t=1720244490',
        uncover_order: [2, 6, 0, 4],
    },
    '12': {
        content: '',
        submitted_by: '',
        answers: ["Netflix"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/NFLX-7466b597.png?t=1720244493',
        uncover_order: [6, 5, 4, 2],
    },
    '13': {
        content: '',
        submitted_by: '',
        answers: ["Procter & Gamble"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PG-b828a7df.png?t=1720244493',
        uncover_order: [7, 0, 3, 5],
    },
    '14': {
        content: '',
        submitted_by: '',
        answers: ["Visa"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/V-05214186.png?t=1720244494',
        uncover_order: [6, 3, 4, 2],
    },
    '15': {
        content: '',
        submitted_by: '',
        answers: ["Dell"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/DELL-f7f7f0be.png?t=1720244491',
        uncover_order: [6, 1, 2, 5],
    },
    '16': {
        content: '',
        submitted_by: '',
        answers: ["Jollibee"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/JBFCF_BIG-6f9dd9a2.png?t=1720244492',
        uncover_order: [0, 3, 2, 8],
    },
    '17': {
        content: '',
        submitted_by: '',
        answers: ["Yelp"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/YELP-d704c977.png?t=1720244494',
        uncover_order: [2, 6, 4, 3],
    },
    '18': {
        content: '',
        submitted_by: '',
        answers: ["Salesforce"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/CRM-74b365dd.png?t=1720244491',
        uncover_order: [8, 7, 3, 5],
    },
    '19': {
        content: '',
        submitted_by: '',
        answers: ["Microsoft"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MSFT-a203b22d.png?t=1720244493',
        uncover_order: [0, 3, 4, 8],
    },
    '20': {
        content: '',
        submitted_by: '',
        answers: ["FedEx"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/FDX-35c19ad4.png?t=1720244491',
        uncover_order: [2, 7, 1, 8],
    },
    '21': {
        content: '',
        submitted_by: '',
        answers: ["Unilever"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/UL-1dae83e7.png?t=1720244494',
        uncover_order: [8, 1, 3, 4],
    },
    '22': {
        content: '',
        submitted_by: '',
        answers: ["ING"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ING-8c32bac2.png?t=1720244492',
        uncover_order: [2, 3, 8, 0],
    },
    '23': {
        content: '',
        submitted_by: '',
        answers: ["Zoom"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ZM-b326e02f.png?t=1720244494',
        uncover_order: [6, 0, 2, 3],
    },
    '24': {
        content: '',
        submitted_by: '',
        answers: ["eBay"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/EBAY-a442b3a2.png?t=1720244491',
        uncover_order: [1, 7, 5, 4],
    },
    '25': {
        content: '',
        submitted_by: '',
        answers: ["L'Or\u00e9al"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/OR.PA-99c0d360.png?t=1720244493',
        uncover_order: [1, 4, 5, 6],
    },
    '26': {
        content: '',
        submitted_by: '',
        answers: ["PUMA"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PUM.DE-73e7228d.png?t=1720244493',
        uncover_order: [6, 8, 1, 0],
    },
    '27': {
        content: '',
        submitted_by: '',
        answers: ["T-Mobile"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/TMUS-c281226a.png?t=1720244494',
        uncover_order: [6, 5, 4, 2],
    },
    '28': {
        content: '',
        submitted_by: '',
        answers: ["Adobe"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ADBE-fb158b30.png?t=1720244490',
        uncover_order: [5, 6, 4, 1],
    },
    '29': {
        content: '',
        submitted_by: '',
        answers: ["LinkedIn"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/linkedin-2c3012a9.png?t=1720244494',
        uncover_order: [2, 6, 4, 8],
    },
    '30': {
        content: '',
        submitted_by: '',
        answers: ["BMW"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BMW.DE-56e4d00d.png?t=1720244491',
        uncover_order: [6, 8, 4, 2],
    },
    '31': {
        content: '',
        submitted_by: '',
        answers: ["Forbes"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/forbes_BIG-09cc476a.png?t=1720244494',
        uncover_order: [6, 1, 4, 8],
    },
    '32': {
        content: '',
        submitted_by: '',
        answers: ["Alibaba"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BABA-2884ac04.png?t=1720244490',
        uncover_order: [0, 8, 4, 2],
    },
    '33': {
        content: '',
        submitted_by: '',
        answers: ["Walmart"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/WMT-0d8ecd74.png?t=1720244494',
        uncover_order: [6, 0, 4, 2],
    },
    '34': {
        content: '',
        submitted_by: '',
        answers: ["PayPal"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/PYPL-3570673e.png?t=1720244493',
        uncover_order: [8, 3, 4, 2],
    },
    '35': {
        content: '',
        submitted_by: '',
        answers: ["Bank Mandiri"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BMRI.JK-9759531a.png?t=1720244491',
        uncover_order: [0, 2, 4, 7],
    },
    '36': {
        content: '',
        submitted_by: '',
        answers: ["Accenture"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/ACN_BIG-3a6289fb.png?t=1720244490',
        uncover_order: [0, 2, 4, 5],
    },
    '37': {
        content: '',
        submitted_by: '',
        answers: ["Brembo"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/BRE.MI_BIG-42d83cc8.png?t=1720244491',
        uncover_order: [2, 6, 4, 8],
    },
    '38': {
        content: '',
        submitted_by: '',
        answers: ["HP"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/HPQ-30e5d607.png?t=1720244492',
        uncover_order: [8, 0, 4, 2],
    },
    '39': {
        content: '',
        submitted_by: '',
        answers: ["Ralph Lauren"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/RL-fe27c7e6.png?t=1720244493',
        uncover_order: [0, 5, 3, 1],
    },
    '40': {
        content: '',
        submitted_by: '',
        answers: ["Mastercard"],
        affiliate_links: [],
        franchise: 'None',
        source: 'https://companieslogo.com/img/orig/MA-c8ce4564.png?t=1720244492',
        uncover_order: [2, 5, 4, 7],
    },
};
